'use strict';

(function () {

  angular.module('brokerAdmin.ProposalTxn', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.ProposalTxn module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.ProposalTxn
   *
   * @author     snehilmodani
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('proposal-transaction', {
      url: '/proposal-transaction',
      parent: 'parent-state',
      templateUrl: 'dev/proposal-txn/templates/proposal-txn.tpl.html',
      controller: 'ProposalTxnCtrl',
      controllerAs: 'ViewModel'
    });

    // //////////////////////////////////////////////////////
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

(function () {

  angular.module('brokerAdmin.Renewals', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.Renewals module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.Renewals
   *
   * @author     beerpratap
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('renewals-noquote', {
      url: '/renewals/norenewalquote',
      parent: 'parent-state',
      templateUrl: 'dev/renewals/templates/renewals.tpl.html',
      controller: 'RenewalsCtrl',
      controllerAs: 'ViewModel',
      pageType: 'NO_RENEWAL_QUOTE'
    }).state('renewals-ready', {
      url: '/renewals/renewalready',
      parent: 'parent-state',
      templateUrl: 'dev/renewals/templates/renewals.tpl.html',
      controller: 'RenewalsCtrl',
      controllerAs: 'ViewModel',
      pageType: 'RENEWAL_READY'
    }).state('renewals-all', {
      url: '/renewals/allrenewals',
      parent: 'parent-state',
      templateUrl: 'dev/renewals/templates/renewals.tpl.html',
      controller: 'RenewalsCtrl',
      controllerAs: 'ViewModel',
      pageType: 'ALL_RENEWALS'
    }).state('renewals-detail', {
      url: '/renewal-detail/:renewalId',
      parent: 'parent-state',
      templateUrl: 'dev/renewals/templates/renewal-detail.tpl.html',
      controller: 'renewalsDetailCtrl',
      controllerAs: 'ViewModel',
      resolve: {
        entry: ['$q', 'RenewalsService', '$stateParams', function ($q, RenewalsService, $stateParams) {
          var deferred = $q.defer();
          var renewalId = $stateParams.renewalId;

          RenewalsService.fetchEntryByRenewalId(renewalId).then(function (result) {
            deferred.resolve(result);
          });
          return deferred.promise;
        }]
      }
    });

    // //////////////////////////////////////////////////////
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

(function () {

  angular.module('brokerAdmin.notifications', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin. module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.notifications
   *
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */

  // TODO: Add another route as conversation home for redirect incase of no selected conversation

  function RouterConfig($stateProvider) {
    $stateProvider.state('conversations', {
      url: '/conversations',
      parent: 'parent-state',
      templateUrl: 'dev/notifications/templates/conversation.tpl.html',
      controller: 'ConversationCtrl',
      controllerAs: 'ViewModel',
      params: {
        id: undefined,
        metaInfo: undefined
      },
      authenticate: true
    });
  }

  /*=====  End of Config Blocks  ======*/
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.mis', ['chart.js'])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.mis module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.mis
   *
   * @author     snehilmodani
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('mis-incomplete-list', {
      url: '/mis-home/incomplete',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/mis-home.tpl.html',
      controller: 'misHomeCtrl',
      authenticate: true,
      pageType: 'INCOMPLETE' //PENDING
    }).state('mis-unassigned-list', {
      url: '/mis-home/unassigned',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/mis-home.tpl.html',
      controller: 'misHomeCtrl',
      authenticate: true,
      pageType: 'UNASSIGNED'
    }).state('mis-all-list', {
      url: '/mis-home/allrecords',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/mis-home.tpl.html',
      controller: 'misHomeCtrl',
      authenticate: true,
      pageType: 'ALLRECORDS'
    }).state('payouts-record', {
      url: '/mis-home/payoutrecords',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/mis-home.tpl.html',
      controller: 'misHomeCtrl',
      authenticate: true,
      pageType: 'PAYOUTREADYRECORDS'
    }).state('mis-create', {
      url: '/add-new-sale',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/add-new-sale.tpl.html',
      controller: 'addNewSaleCtrl',
      authenticate: true,
      // pageType : '',
      resolve: {
        sale: function sale() {
          return null;
        }
      }
    }).state('mis-create-auto', {
      url: '/add-new-sale/auto',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/add-new-auto-sale.tpl.html',
      controller: 'addNewAutoSaleCtrl',
      authenticate: true,
      // pageType : '',
      resolve: {
        sale: ['$q', 'AutoSaleService', function ($q, AutoSaleService) {
          var deferred = $q.defer();
          AutoSaleService.generateFolderNo().then(function (res) {
            deferred.resolve(res);
          });
          return deferred.promise;
        }]
      }
    }).state('mis-detail', {
      url: '/edit-sale/:id',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/add-new-sale.tpl.html',
      controller: 'addNewSaleCtrl',
      authenticate: true,
      resolve: {
        sale: ['$q', 'SaleService', '$stateParams', function ($q, SaleService, $stateParams) {
          var deferred = $q.defer();
          var policyId = $stateParams.id;

          SaleService.getPolicyById(policyId).then(function (result) {
            deferred.resolve(result);
          });
          return deferred.promise;
        }]
      }
    }).state('mis-payment-schedule', {
      url: '/payment-schedule/:id',
      parent: 'parent-state',
      templateUrl: 'dev/mis/templates/payment-schedule.tpl.html',
      controller: 'PaymentScheduleCtrl',
      authenticate: true,
      resolve: {
        ninjaCommonData: ['ninjaCommonDataService', function (ninjaCommonDataService) {
          return ninjaCommonDataService.getNinjaCommonData();
        }],
        schedule: ['paymentScheduleService', '$stateParams', '$q', function (paymentScheduleService, $stateParams, $q) {
          var deferred = $q.defer();
          var policyDetailId = $stateParams.id;
          paymentScheduleService.getPaymentSchedule(policyDetailId).then(function (schedule) {
            deferred.resolve(schedule);
          });
          return deferred.promise;
        }]
      }
    });
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin. module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.policyIssuance
   *
   * @author     snehilmodani
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('ci-list', {
      url: '/policy-issuance',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/policy-issuance-list.tpl.html',
      controller: 'PolicyIssuanceListCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        pageType: function pageType() {
          return 'ISSUANCE';
        }
      }
    }).state('ci-detail', {
      url: '/policy-issuance/:id',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/policy-issuance-add-update.tpl.html',
      controller: 'PolicyIssuanceAddUpdateCtrl',
      authenticate: true,
      resolve: {
        issuance: ['$q', '$stateParams', 'PolicyIssuanceService', function ($q, $stateParams, PolicyIssuanceService) {

          var deferred = $q.defer();

          var policyIssuanceId = $stateParams.id;
          PolicyIssuanceService.getById(policyIssuanceId).then(function (res) {
            deferred.resolve(res);
          });
          return deferred.promise;
        }],
        pageType: function pageType() {
          return 'ISSUANCE';
        }
      }
    }).state('bi-list', {
      url: '/branch-issuance',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/policy-issuance-list.tpl.html',
      controller: 'PolicyIssuanceListCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        pageType: function pageType() {
          return 'BRANCH_ISSUANCE';
        }
      }
    }).state('bi-detail', {
      url: '/branch-issuance/:id',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/policy-issuance-add-update.tpl.html',
      controller: 'PolicyIssuanceAddUpdateCtrl',
      authenticate: true,
      resolve: {
        issuance: ['$q', '$stateParams', 'PolicyIssuanceService', function ($q, $stateParams, PolicyIssuanceService) {

          var deferred = $q.defer();

          var policyIssuanceId = $stateParams.id;
          PolicyIssuanceService.getById(policyIssuanceId).then(function (res) {
            deferred.resolve(res);
          });
          return deferred.promise;
        }],
        pageType: function pageType() {
          return 'BRANCH_ISSUANCE';
        }
      }
    }).state('bi-create', {
      url: '/add-branch-issuance',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/policy-issuance-add-update.tpl.html',
      controller: 'PolicyIssuanceAddUpdateCtrl',
      authenticate: true,
      resolve: {
        issuance: ['$q', 'PolicyIssuanceService', function ($q, PolicyIssuanceService) {

          var deferred = $q.defer();

          PolicyIssuanceService.getDefaultBranchIssuance().then(function (res) {
            deferred.resolve(res);
          });
          return deferred.promise;
        }],
        pageType: function pageType() {
          return 'BRANCH_ISSUANCE';
        }
      }
    }).state('bi-create-auto', {
      url: '/add-branch-issuance/auto',
      parent: 'parent-state',
      templateUrl: 'dev/policy-issuance/templates/pi-create.tpl.html',
      controller: 'PolicyIssuanceAutoCreateCtrl',
      authenticate: true,
      resolve: {
        issuance: ['$q', 'AutoPolicyIssuanceService', function ($q, AutoPolicyIssuanceService) {
          var deferred = $q.defer();
          AutoPolicyIssuanceService.getIssuance().then(function (res) {
            deferred.resolve(res);
          });
          return deferred.promise;
        }]
      }
    });
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  angular.module('brokerAdmin.policyIssuance').filter('displayDate', displayDateFilter).filter('safeConcat', safeConcatFilter);

  displayDateFilter.$inject = [];

  /**
   * Parse Date to now filter
   *
   * @memberof filters
   *
   * @author snehilmodani
   *
   */
  function displayDateFilter() {
    return displayDate;

    /**
     * Parse Date to now filter function
     *
     * @memberof filters.filter
     *
     * @param    {String}           timestamp   Timestamp in the form of iso string
     * @param    {String}           format      format in which the date is to be displayed
     *
     * @author snehilmodani
     *
     */
    function displayDate(timestamp, format) {
      if (timestamp === undefined) {
        return '---';
      }

      if (!format) {
        format = 'Do MMM YYYY H:mm A';
      }

      if (!timestamp) {
        return '---';
      }

      if (format && format === 'fromNow') {
        return moment(timestamp).fromNow();
      }

      if (format && format === 'fromNow-short') {
        return moment(timestamp).fromNow(true);
      }

      return moment(timestamp).format(format);
    }
  }

  safeConcatFilter.$inject = [];

  var separator = ' ';

  return safeConcatFilter;

  /**
   * Filter to render item name
   *
   * @memberof filters
   *
   * @author   snehilmodani
   *
   */
  function safeConcatFilter() {
    return safeConcat;

    // //////////////////////////////////////////////////////

    /**
     * Filter to render item name function
     *
     * @memberof filters.filter
     *
     * @author   snehilmodani
     *
     */
    function safeConcat() {
      var args = Array.from(arguments);
      return args.join(separator).trim();
    }

    // //////////////////////////////////////////////////////
  }

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

(function () {

  angular.module('brokerAdmin.Upload', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.Upload module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.Upload
   *
   * @author     pushpakumari
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('ninja-upload', {
      url: '/internal',
      parent: 'parent-state',
      templateUrl: 'dev/upload/templates/upload.tpl.html',
      controller: 'UploadCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        ninjaCommonData: ['ninjaCommonDataService', function (ninjaCommonDataService) {
          return ninjaCommonDataService.getNinjaCommonData();
        }]
      }
    });

    // //////////////////////////////////////////////////////
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

(function () {

  angular.module('brokerAdmin.qis', []).config(RouterConfig);

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin. module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.qis
   *
   * @author     sagarmulchandani
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('qis-list', {
      url: '/qis',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/qis-list.tpl.html',
      controller: 'QisListCtrl',
      authenticate: true,
      resolve: {
        pageType: function pageType() {
          return 'QUOTE_REQUEST';
        }
      }
    }).state('quotes-list', {
      url: '/quotes',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/qis-list.tpl.html',
      controller: 'QisListCtrl',
      authenticate: true,
      resolve: {
        pageType: function pageType() {
          return 'QUOTES';
        }
      }
    }).state('qis-assign-tag', {
      url: '/qis/assignTag/:tenant/:id',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/assign-tag.tpl.html',
      controller: 'QisAssignTagCtrl',
      authenticate: true,
      resolve: {
        lead: ['$q', '$stateParams', 'leadService', function ($q, $stateParams, leadService) {
          var deferred = $q.defer();
          leadService.getLeadByRequestId($stateParams.id, $stateParams.tenant).then(function (res) {
            deferred.resolve(res.data);
          });
          return deferred.promise;
        }]
      }
    }).state('qis-review', {
      url: '/qis-review/:tenant/:id',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/qis-review.tpl.html',
      controller: 'QisReviewCtrl',
      authenticate: true,
      resolve: {
        lead: ['$q', '$stateParams', 'leadService', function ($q, $stateParams, leadService) {
          var deferred = $q.defer();
          leadService.getLeadByRequestId($stateParams.id, $stateParams.tenant).then(function (res) {
            deferred.resolve(res.data);
          });
          return deferred.promise;
        }]
      }
    }).state('qis-result', {
      url: '/qis/result/:id',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/qis-result.tpl.html',
      controller: 'QisResultCtrl',
      authenticate: true,
      resolve: {
        lead: ['$q', '$stateParams', 'leadService', function ($q, $stateParams, leadService) {
          var deferred = $q.defer();
          var tenant = sessionStorage.getItem('tenant');
          leadService.getLeadByRequestId($stateParams.id, tenant).then(function (res) {
            deferred.resolve(res.data);
          });
          return deferred.promise;
        }]
      }
    }).state('quotes-form', {
      url: '/quotes/create',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/quotes-form.tpl.html',
      controller: 'QuotesFormCtrl',
      authenticate: true
    }).state('quotes-profile', {
      url: '/quotes/:vertical',
      parent: 'parent-state',
      templateUrl: 'dev/qis/templates/quotes-profile.tpl.html',
      controller: 'QuotesProfileCtrl',
      authenticate: true
    });
  }
  /*=====  End of Config Blocks  ======*/
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('templates', []);
  var brokerAdmin = angular.module('brokerAdmin', ['ngCookies', 'ui.router', 'directive.g+signin', 'angularFileUpload', 'angular-pdf-thumbnail', 'ui.bootstrap', 'ngCsv', 'ngclipboard', 'CanvasViewer', 'ngMaterial', 'md.data.table', 'duScroll', 'mdformComponent', 'infinite-scroll', 'fixed.table.header',
  // Custom Sub-modules
  'config', 'constants', 'templates', 'brokerAdmin.ProposalTxn', 'brokerAdmin.mis', 'brokerAdmin.policyIssuance', 'brokerAdmin.Renewals', 'brokerAdmin.partner', 'brokerAdmin.Auth', 'brokerAdmin.notifications', 'brokerAdmin.Upload', 'brokerAdmin.lifeOps', 'brokerAdmin.qis', 'brokerAdmin.quotesReport']);

  brokerAdmin.constant('_', window._);

  brokerAdmin.config(['$sceDelegateProvider', '$sceProvider', function ($sceDelegateProvider, $sceProvider) {
    // $sceDelegateProvider.resourceUrlWhitelist([
    //     'self',
    //     'http://d2gpoit8ch6b3b.cloudfront.net/**',
    //     'https://ninja-static.turtlemint.com/**',
    //     'http://admin.turtlemint.com.s3.amazonaws.com/**',
    //     'https://admin.turtlemint.com.s3.amazonaws.com/**'
    // ]);
    $sceProvider.enabled(false);
  }]);

  brokerAdmin.factory('httpInterceptor', ['$q', '$window', '$injector', 'CONSTANTS_LIST', 'GENERAL_CONFIG', '$rootScope', function ($q, $window, $injector, CONSTANTS_LIST, GENERAL_CONFIG, $rootScope) {
    var $cookies = $injector.get('$cookies');
    var $location = $injector.get('$location');

    return {
      request: function request(config) {
        if (config.url.match('agentpro') || config.url.match('mintpro') || config.url.match('/search/lead') || config.url.match('/api') || config.url.match('selectassure')) {
          var jwtToken;
          try {
            jwtToken = atob($window.localStorage.getItem('jwtToken'));
          } catch (err) {
            jwtToken = $window.localStorage.getItem('jwtToken');
          }

          if (jwtToken != null && jwtToken !== '') {
            config.headers.Authorization = jwtToken;
          }
          if (!config.url.match('chat')) {
            config.params = config.params || {};
            if (!config.params.broker) {
              config.params.broker = sessionStorage.getItem('broker');
            }
            if (!config.params.tenant) {
              config.params.tenant = sessionStorage.getItem('tenant');
            }
          }
          // const platform = $cookies.get(CONSTANTS_LIST.PLATFORM_COOKIE);
          // config.params.platform = platform;
        }
        return config || $q.when(config);
      },
      response: function response(_response) {
        if (_response.data.responseCode === 5) {
          $window.location.href = _response.data.responseMessage;
        }
        return _response || $q.when(_response);
      },
      responseError: function responseError(response) {
        switch (response.status) {
          case 400:
            // $window.alert('some error from ourside: ' + (response.data && response.data.meta && response.data.meta.msg));
            break;
          case 404:
            break;
          case 600:
          case 601:
            $window.console.log('Logging out user as recieved ' + response.status + ' from a api request');
            if ($window.localStorage.getItem('jwtToken') != null) {
              $window.localStorage.removeItem('jwtToken');
            }
            $window.localStorage.clear();
            var cookies = $cookies.getAll();
            angular.forEach(cookies, function (v, k) {
              if (!CONSTANTS_LIST.EXCLUED_COOKIES.includes(k)) {
                $cookies.remove(k, { 'domain': GENERAL_CONFIG.cookieDomain });
                $cookies.remove(k, { 'domain': location.hostname });
              }
            });
            $location.path('/login');
            break;
          default:
            break;
        }
        var features = sessionStorage.getItem('features');
        if (features) {
          features = JSON.parse(features);
        }

        if (response.status && (response.status === 401 || response.status === 403)) {
          var mxpObj = {
            url: response.config.url
          };
          pushToGTM('ERROR_' + response.status, 'track_event', mxpObj);
        }

        if (features && features.common && features.common.showErrorPage && response.status && (response.status === 401 || response.status === 403)) {
          $window.location.href = '/errorPage.html';
        } else {
          if (response.status && (response.status === 401 || response.status === 403)) {
            var _mxpObj = {
              url: response.config.url,
              responseStatus: response.status
            };
            pushToGTM('LOGOUT_AUTO', 'track_event', _mxpObj);
            logout();
          }
        }
        return $q.reject(response);
      }
    };

    function pushToGTM(eventName, tag, jsonObject) {
      jsonObject.ipAddress = $rootScope.ipAddress;
      try {
        var user = JSON.parse(atob(localStorage.getItem('mxpUser')));
        jsonObject = _extends({}, jsonObject, user);
        jsonObject.event = tag;
        jsonObject.type = eventName;
        jsonObject.broker = sessionStorage.getItem('broker');
        jsonObject.tenant = sessionStorage.getItem('tenant');
        jsonObject.platform = $cookies.get(CONSTANTS_LIST.PLATFORM_COOKIE);
        $window.dataLayer.push(jsonObject);
      } catch (err) {
        $window.console.error(err.message);
      }
    }

    function isApp() {
      var platform = getNinjaPlatform();
      return platform === CONSTANTS_LIST.PLATFORM.APP;
    }

    function getNinjaPlatform() {
      return $cookies.get(CONSTANTS_LIST.PLATFORM_COOKIE);
    }

    function logout() {
      var cookies = $cookies.getAll();
      var broker = sessionStorage.getItem('broker');
      var tenant = sessionStorage.getItem('tenant');
      $window.localStorage.clear();
      $window.sessionStorage.clear();
      angular.forEach(cookies, function (v, k) {
        if (!CONSTANTS_LIST.EXCLUED_COOKIES.includes(k)) {
          $cookies.remove(k, { 'domain': GENERAL_CONFIG.cookieDomain });
          $cookies.remove(k, { 'domain': location.hostname });
        }
      });
      sessionStorage.setItem('broker', broker);
      sessionStorage.setItem('tenant', tenant);
      if ($window.Android && isApp()) {
        Android.logout(true);
      } else {
        $location.path('/login');
      }
    }
  }]);

  // Config
  brokerAdmin.config(['$stateProvider', '$locationProvider', '$httpProvider', function ($stateProvider, $locationProvider, $httpProvider) {
    $httpProvider.interceptors.push('httpInterceptor');
    $stateProvider.state('parent-state', {
      abstract: true,
      template: '<ui-view></ui-view>',
      resolve: {
        brokerConfig: ['UtilService', function (UtilService) {
          return UtilService.getBrokerConfig();
        }]
      }
    });

    $locationProvider.html5Mode(true);
  }]);

  // Run
  brokerAdmin.run(['$rootScope', '$state', 'AuthService', 'commonService', 'GENERAL_CONFIG', '$window', 'CONSTANTS_LIST', function ($rootScope, $state, AuthService, commonService, GENERAL_CONFIG, $window, CONSTANTS_LIST) {
    $rootScope.$state = $state;
    commonService.setPlainCookie('view', 'admin', GENERAL_CONFIG.cookieDomain, undefined);
    if (!AuthService.isLoggedIn() && location.pathname !== '/login' && notAuthorisedPath()) {
      var redirectLink = location.href;
      commonService.setCookie('redirectLink', redirectLink, GENERAL_CONFIG.cookieDomain, undefined);
    }

    function notAuthorisedPath() {
      var pathnameArray = location.pathname.split('/');
      if (pathnameArray.includes('appAuthenticator')) {
        return false;
      }
      return true;
    }

    // Called any time state is being changed
    $rootScope.$on('$stateChangeStart', function (event, toState /*, toParams, fromState, fromParams*/) {
      if (sessionStorage.getItem('broker') === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var gaObj = {
          'page_path': toState.url,
          'page_title': toState.name,
          'send_to': 'UA-217584544-1'
        };
        $window.gtag('event', 'page_view', gaObj);
      }

      var unprotectedStates = ['login', 'appAuthenticator'];
      if (toState.authenticate && !AuthService.isLoggedIn()) {
        if ($window.Android && commonService.isApp()) {
          Android.logout(true);
        } else {
          $state.transitionTo('login');
          event.preventDefault();
        }
      }
      if (unprotectedStates.includes(toState.name) && AuthService.isLoggedIn()) {
        $state.transitionTo('/');
        event.preventDefault();
      }
      if (toState.authenticate && !AuthService.hasViewPermission(toState.name) && toState.name !== 'login' && toState.name !== '/' && toState.name !== 'oauth') {
        var ERR_MSG = !AuthService.isLoggedIn() ? 'Please login to continue.' : 'You are not authorized.';
        $window.alert(ERR_MSG);
        if ($state.current.name === '') {
          $state.transitionTo('/');
        } else {
          $state.transitionTo($state.current.name);
        }
        event.preventDefault();
      }
    });
  }]);

  brokerAdmin.directive('clickOff', ['$parse', '$document', function ($parse, $document) {
    var dir = {
      compile: function compile($element, attr) {
        // Parse the expression to be executed
        // whenever someone clicks _off_ this element.
        var fn = $parse(attr.clickOff);
        return function (scope, element /*, attr*/) {
          // add a click handler to the element that
          // stops the event propagation.
          element.bind('click', function (event) {
            event.stopPropagation();
          });
          angular.element($document[0].body).bind('click', function (event) {
            scope.$apply(function () {
              fn(scope, {
                $event: event
              });
            });
          });
        };
      }
    };
    return dir;
  }]);

  brokerAdmin.config(['$mdDateLocaleProvider', function ($mdDateLocaleProvider) {
    // FORMAT THE DATE FOR THE DATEPICKER
    $mdDateLocaleProvider.formatDate = function (date) {
      if (date) {
        return moment(date).format('DD-MMM-YYYY');
      }

      return '';
    };
  }]);

  brokerAdmin.directive('ngEnter', function () {
    return function (scope, element, attrs) {
      element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter, {
              'event': event
            });
          });

          event.preventDefault();
        }
      });
    };
  });

  brokerAdmin.directive('clickOff', ['$parse', '$document', function ($parse, $document) {
    var dir = {
      compile: function compile($element, attr) {
        // Parse the expression to be executed
        // whenever someone clicks _off_ this element.
        var fn = $parse(attr.clickOff);
        return function (scope, element /*, attr*/) {
          // add a click handler to the element that
          // stops the event propagation.
          element.bind('click', function (event) {
            event.stopPropagation();
          });
          angular.element($document[0].body).bind('click', function (event) {
            scope.$apply(function () {
              fn(scope, {
                $event: event
              });
            });
          });
        };
      }
    };
    return dir;
  }]);
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.ProposalTxn').controller('ProposalTxnCtrl', controller);

  controller.$inject = ['commonService', '$q', 'ProposalTxnService', 'CONSTANTS', '$window'];

  /**
   * ProposalTxnCtrl
   *
   * @author snehilmodani
   *
   * @param    {!Object}           commonService    commonService
   * @param    {!Object}           $q               $q
   *
   */
  function controller(commonService, $q, ProposalTxnService, CONSTANTS, $window) {
    var ViewModel = this;

    ViewModel.verticals = CONSTANTS.verticals;
    ViewModel.message = 0;
    ViewModel.jsonResult = {};

    ViewModel.buttonDisabled = buttonDisabled;
    ViewModel.getProposalInfo = getProposalInfo;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    function buttonDisabled() {
      if (ViewModel.searchText === undefined || ViewModel.searchText.trim() === '' || ViewModel.vertical === undefined || ViewModel.vertical === null) {
        return true;
      }return false;
    }

    function getProposalInfo() {
      var jsonData = {
        _id: ViewModel.searchText.trim(),
        vertical: ViewModel.vertical
      };
      ViewModel.jsonResult = {};
      ProposalTxnService.getProposalInfo(jsonData).then(function (data) {
        ViewModel.jsonResult = data.data;
        ViewModel.message = 1;
      }).catch(function (err) {
        ViewModel.message = err.message;
        $window.console.error(err.message);
      });
    }

    /**
     * init function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function init() {
      ViewModel.navbarConfig = {
        vertical: 'mis',
        title: 'Proposal Transaction Status'
      };
    }

    /**
     * Function to select tag for upload
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
  }
})();
'use strict';

(function () {
  var constants = {};

  constants.proposalTxnUrl = '/getProposalTxnInfo/';

  constants.ERROR_400_MESSAGE = 'Bad request!';

  constants.verticals = [{
    name: 'Two wheeler',
    val: 'TW'
  }, {
    name: 'Four wheeler',
    val: 'FW'
  }, {
    name: 'Commercial vehicle',
    val: 'CV'
  }];

  angular.module('brokerAdmin.ProposalTxn').constant('CONSTANTS', constants);
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.Renewals').controller('RenewalsCtrl', controller);

  controller.$inject = ['$scope', 'commonService', '$q', 'RenewalsService', '$mdDialog', '$mdToast', 'FileUploader', '$window', '$state', 'GENERAL_CONFIG', '$timeout', 'RENEWAL_CONSTANTS', 'MIS_CONSTANTS', '$mdSelect', 'AuthService', 'TrackingService'];

  /**
   * Renewals Controller
   *
   * @memberof Controllers.DateRangeModalController
   *
   * @author beerpratapsingh
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           commonService    commonService
   * @param    {!Object}           misService       misService
   * @param    {!Object}           $q               $q
   * @param    {!Object}           RenewalsService  RenewalsService
   * @param    {!Object}           mdDialog         mdDialog
   * @param    {!Object}           GENERAL_CONFIG   GENERAL_CONFIG
   *
   */
  function controller($scope, commonService, $q, RenewalsService, $mdDialog, $mdToast, FileUploader, $window, $state, GENERAL_CONFIG, $timeout, RENEWAL_CONSTANTS, MIS_CONSTANTS, $mdSelect, AuthService, TrackingService) {

    var ViewModel = this;
    ViewModel.sortedFields = {};
    ViewModel.showAdvanced = showAdvanced;
    ViewModel.goTo = goTo;
    ViewModel.uploadFile = uploadFile;
    ViewModel.updateRenewalEntryModel = updateRenewalEntryModel;
    ViewModel.fetchRenewalEntriesData = fetchRenewalEntriesData;
    ViewModel.fetchRenewalEntriesDataByDateRange = fetchRenewalEntriesDataByDateRange;
    ViewModel.detailPage = detailPage;
    ViewModel.triggerSorting = triggerSorting;
    ViewModel.applyFilter = applyFilter;
    ViewModel.clearFilter = clearFilter;
    // ViewModel.applyChannelFilter = applyChannelFilter;
    ViewModel.applyMonthFilter = applyMonthFilter;
    ViewModel.applyDaysLeftFilter = applyDaysLeftFilter;

    ViewModel.channelDisplay = channelDisplay;
    ViewModel.productCategoryDisplay = productCategoryDisplay;
    ViewModel.statusDisplay = statusDisplay;
    ViewModel.statusInsightsDisplay = statusInsightsDisplay;
    // ViewModel.insurersDisplay = insurersDisplay;
    ViewModel.isDayFilterApplied = isDayFilterApplied;
    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.hasPermission = AuthService.hasPermission;

    ViewModel.pageTabs = RENEWAL_CONSTANTS.pageTabs;

    ViewModel.params = {
      limit: 10,
      page: 1,
      activePage: $state.current.pageType
    };

    ViewModel.options = {
      pageLimits: [5, 10, 15, 20, 25, 50]
    };

    ViewModel.dropdowns = {
      // insurers: MIS_CONSTANTS.insurers,
      categories: RENEWAL_CONSTANTS.categories,
      productNames: MIS_CONSTANTS.productNames,
      statuses: RENEWAL_CONSTANTS.renewalStatuses,
      months: RenewalsService.fetchMonthsDropdown(),
      channels: MIS_CONSTANTS.channelTypes
    };

    ViewModel.columns = RENEWAL_CONSTANTS.tableColumns;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      /*=== Nav Congiguration===*/
      ViewModel.navbarConfig = {
        vertical: 'renewals',
        title: 'Renewals',
        search: {
          enabled: true,
          searchFn: searchRenewalEntries,
          placeholder: 'Search by policy no, reg no, DP name, DP id...'
        },
        actions: [{
          type: 'icon',
          class: '',
          action: _goOnAddNewSale,
          name: '',
          iconSrc: '/images/common/plus-icon.svg',
          permissionCode: 'renewals-create',
          dataAuto: 'new-sale-button'
        }, {
          type: 'icon',
          class: '',
          action: _exportRenewalEntryData,
          name: '',
          iconSrc: '/images/common/download-icon.svg',
          permissionCode: 'renewals-bulkexport',
          dataAuto: 'bulkExport-renewals'
        }]
      };

      function _goOnAddNewSale() {
        //$state.go('mis-create');
        $window.open($state.href('mis-create'), '_blank');
      }

      initializeFilter();

      if ($state.current) {
        var currentPageType = $state.current.pageType;

        // Initialized status filters
        //ViewModel.columns[6].filters = [];

        if (currentPageType === 'NO_RENEWAL_QUOTE') {
          ViewModel.pageShortName = 'NRQ';
          ViewModel.tableTitle = 'NO RENEWAL QUOTE';
          ViewModel.showGraphChart = true;
          ViewModel.currentNavItem = 'NO RENEWAL QUOTE';
          //ViewModel.columns[6].filters.push('NO_RENEWAL_QUOTE');
          ViewModel.dropdowns.daysLeft = RenewalsService.fetchDaysLeftDropdown(-90, 45);
        } else if (currentPageType === 'RENEWAL_READY') {
          ViewModel.pageShortName = 'READY';
          ViewModel.tableTitle = 'RENEWAL READY';
          ViewModel.showGraphChart = true;
          ViewModel.currentNavItem = 'RENEWAL READY';
          //ViewModel.columns[6].filters.push('RENEWAL_READY');
          ViewModel.dropdowns.daysLeft = RenewalsService.fetchDaysLeftDropdown(-90, 45);
        } else if (currentPageType === 'ALL_RENEWALS') {
          ViewModel.pageShortName = 'ALL';
          ViewModel.tableTitle = 'ALL RENEWALS';
          ViewModel.showGraphChart = false;
          ViewModel.currentNavItem = 'ALL RENEWALS';
          ViewModel.dropdowns.daysLeft = RenewalsService.fetchDaysLeftDropdown(-90, 45);
        }
      }

      ViewModel.chartOptions = {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              stacked: true,
              beginAtZero: true
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onClick: chartOnClickFn
      };

      // fetchRenewalEntriesData();
    }

    function channelDisplay(channelTypes) {
      return (ViewModel.dropdowns.channels.find(function (elm) {
        return elm.value === channelTypes;
      }) || {}).text || channelTypes;
    }

    function productCategoryDisplay(productCategory) {
      return (RENEWAL_CONSTANTS.categories.find(function (elm) {
        return elm.value === productCategory;
      }) || {}).text || productCategory;
    }

    function statusDisplay(status) {
      var length = RENEWAL_CONSTANTS.renewalStatuses.length;
      for (var i = 0; i < length; i++) {
        if (RENEWAL_CONSTANTS.renewalStatuses[i].value === status) {
          return RENEWAL_CONSTANTS.renewalStatuses[i].shortname;
        }
      }

      return status;
    }

    function statusInsightsDisplay(status) {
      var length = RENEWAL_CONSTANTS.renewalStatuses.length;
      for (var i = 0; i < length; i++) {
        if (RENEWAL_CONSTANTS.renewalStatuses[i].value === status) {
          return RENEWAL_CONSTANTS.renewalStatuses[i].label;
        }
      }

      return status;
    }

    // function insurersDisplay(insurer){
    //   var length = MIS_CONSTANTS.insurers.length;
    //   for(var i = 0; i < length; i++) {
    //     if(MIS_CONSTANTS.insurers[i].value === insurer) {
    //       return MIS_CONSTANTS.insurers[i].label;
    //     }
    //   }

    //   return insurer;
    // }


    function chartOnClickFn(event, item) {
      // Reset month selected if any
      if (ViewModel.filters) {
        ViewModel.filters.month = undefined;
      }

      var preInitialized = false;
      if (ViewModel.startDate && ViewModel.endDate) {
        preInitialized = true;
      }

      ViewModel.startDate = undefined;
      ViewModel.endDate = undefined;

      if (item[0] && item[0]._model && item[0]._model.label) {
        var label = item[0]._model.label;
        ViewModel.startDate = ViewModel.daysLeftInsights.rangeDates[label].startDate;
        ViewModel.endDate = ViewModel.daysLeftInsights.rangeDates[label].endDate;
      }

      var postInitialized = false;
      if (ViewModel.startDate && ViewModel.endDate) {
        postInitialized = true;
      }

      if (preInitialized || postInitialized) {
        fetchRenewalEntriesData();
      }
    }

    function goTo(page) {
      $state.go(page);
    }

    function triggerSorting(column) {
      var order = column.indexOf('-') === 0 ? 'DESC' : 'ASC';
      var column = order === 'DESC' ? column.substring(1) : column;
      ViewModel.sortedFields[column] = order;
      fetchRenewalEntriesData();
    }

    function uploadFile() {
      angular.element('.fileInputElement').click();
    }

    function showAdvanced() {
      $mdDialog.show({});
    }

    function applyFilter() {
      fetchRenewalEntriesData();
      $mdSelect.hide();
    }

    function clearFilter(columnNo) {
      var currentPageType = $state.current.pageType;
      sessionStorage.removeItem(currentPageType + 'localFilters');
      ViewModel.columns[columnNo].filters = [];
      fetchRenewalEntriesData();
      $mdSelect.hide();
    }

    function initializeFilter(daysLeftSelected) {
      //Get Insure List for filter
      commonService.getInsurerList().then(function (insurersList) {
        ViewModel.insurersList = insurersList;
      });
      //var jsonData = {};
      ViewModel.filters = {};
      var currentPageType = $state.current.pageType;
      var columnsSize = ViewModel.columns.length;
      var getFilter, daysLeftSelected, startDateSelected, endDateSelected;
      for (var i = 0; i < columnsSize; i++) {
        if (sessionStorage.getItem(currentPageType + 'localFilters') || sessionStorage.getItem(currentPageType + 'daysLeft') || sessionStorage.getItem(currentPageType + 'startDate') || sessionStorage.getItem(currentPageType + 'endDate')) {
          getFilter = JSON.parse(sessionStorage.getItem(currentPageType + 'localFilters'));
          daysLeftSelected = JSON.parse(sessionStorage.getItem(currentPageType + 'daysLeft'));

          startDateSelected = JSON.parse(sessionStorage.getItem(currentPageType + 'startDate'));
          endDateSelected = JSON.parse(sessionStorage.getItem(currentPageType + 'endDate'));
          //jsonData.filter = getFilter;
          ViewModel.columns[5].filters = getFilter.productCategory;
          ViewModel.columns[6].filters = getFilter.productName;
          ViewModel.columns[7].filters = getFilter.insurer;
          ViewModel.columns[8].filters = getFilter.status;

          ViewModel.columns[9].filters = daysLeftSelected;

          ViewModel.columns[13].filters = getFilter.channelType;

          ViewModel.startDate = startDateSelected;
          ViewModel.endDate = endDateSelected;

          ViewModel.filters = {
            month: {
              value: moment(endDateSelected).format('MMM, YYYY'),
              startDate: moment(startDateSelected).toISOString(),
              endDate: moment(endDateSelected).toISOString()
            }
          };
        } else {
          ViewModel.columns[i].filters = [];
        }
      }
      applyDaysLeftFilter(daysLeftSelected);
    }

    function updateRenewalEntryModel(rEntry) {
      $mdDialog.show({
        controller: 'RenewalsUpdateDialogCtrl',
        controllerAs: 'ViewModel',
        templateUrl: 'dev/renewals/templates/update-dialog.tpl.html',
        clickOutsideToClose: true,
        locals: { renewalEntry: rEntry }
      }).then(function () {
        fetchRenewalEntriesData();
      }).catch(function () {});
    }

    function searchRenewalEntries(searchKey) {
      ViewModel.searchString = searchKey;
      var deferred = $q.defer();
      fetchRenewalEntriesData();
      deferred.reject(true);
      return deferred.promise;
    }

    function fetchRenewalEntriesData() {
      var jsonData = createJsonDataForRenewalEntries();
      RenewalsService.fetchRenewalEntries(jsonData).then(function (response) {
        updateEntriesTable(response);
        ViewModel.sortedFields = {};
      });
      // .catch(err => console.log('err==>', err));
    }

    function fetchRenewalEntriesDataByDateRange() {
      if (ViewModel.startDate && ViewModel.endDate && ViewModel.startDate.getTime() <= ViewModel.endDate.getTime()) {
        ViewModel.filters.month = [];
        fetchRenewalEntriesData();
      } else {
        showToast('Incorrect date range specified', 3000);
      }
    }

    function _exportRenewalEntryData() {
      var jsonData = createJsonDataForRenewalEntries();
      RenewalsService.exportRenewalData(jsonData).then(function (response) {
        if (angular.equals(response.status, 200)) {
          var headers = response.headers();
          var contentType = headers['content-type'];
          var blob = new Blob([response.data], { type: contentType });

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'renewals_' + ViewModel.pageShortName + '.' + 'renewals' + '.csv';
          document.body.appendChild(link);
          link.click();

          $timeout(function () {
            document.body.removeChild(link);
          }, 100);
        }
      }).catch(function () {
        showToast('Couldn\'t export the renewal entries data', 3000);
      });
    }

    // function applyChannelFilter(channelSelected, columnNo) {
    //  if(channelSelected) {
    //     ViewModel.columns[columnNo].filters = channelSelected;
    //   } else {
    //     ViewModel.columns[columnNo].filters = [];
    //   }

    //   fetchRenewalEntriesData();
    //   $mdSelect.hide();
    // }

    function applyMonthFilter(monthSelected) {
      var jsonData = createJsonDataForRenewalEntries();
      var mxpMonthUserDetails = {
        filterData: jsonData
      };
      var currentPageType = $state.current.pageType;
      sessionStorage.removeItem(currentPageType + 'startDate');
      sessionStorage.removeItem(currentPageType + 'endDate');
      ViewModel.startDate = monthSelected.startDate;
      ViewModel.endDate = monthSelected.endDate;
      fetchRenewalEntriesData();
      if ($state.current.pageType === 'ALL_RENEWALS') {
        TrackingService.pushToGTMDatalayer('month_click', 'track_event', mxpMonthUserDetails);
      }
    }

    function applyDaysLeftFilter(daysLeftSelected) {
      var currentPageType = $state.current.pageType;
      sessionStorage.removeItem(currentPageType + 'daysLeft');
      ViewModel.daysLeft = daysLeftSelected;
      fetchRenewalEntriesData();
    }

    function isDayFilterApplied() {
      return typeof ViewModel.columns[7].filters === 'number';
    }

    function updateEntriesTable(response) {
      ViewModel.data = response;
      ViewModel.renewalEntriesList = response.data.renewalEntries;
      ViewModel.totalCount = response.data.totalCount;
      ViewModel.statusInsights = response.data.insights.status;
      ViewModel.daysLeftInsights = RenewalsService.organizeDaysLeftForInsights(response.data.insights.daysLeft);

      ViewModel.chartData = {
        labels: ViewModel.daysLeftInsights.rangeLabels,
        data: ViewModel.daysLeftInsights.rangeCounts
      };
    }

    function createJsonDataForRenewalEntries() {
      var jsonData = {};
      captureFiltersApplied(jsonData);
      captureSortingApplied(jsonData);
      captureSearchString(jsonData);
      captureOtherCriterias(jsonData);
      return jsonData;
    }

    function captureFiltersApplied(jsonData) {
      var currentPageType = $state.current.pageType;
      var filterData = {};
      var columnsSize = ViewModel.columns.length;
      for (var i = 0; i < columnsSize; i++) {
        if (ViewModel.columns[i].filters && ViewModel.columns[i].filters.length > 0) {
          filterData[ViewModel.columns[i].value] = ViewModel.columns[i].filters;
        } else if (ViewModel.columns[i].filters !== undefined) {
          ViewModel.filters.daysLeft = ViewModel.daysLeft;
        }
      }

      jsonData.filter = filterData;

      var startDate = ViewModel.startDate;
      var endDate = ViewModel.endDate;

      if (startDate && endDate) {
        sessionStorage.setItem(currentPageType + 'startDate', JSON.stringify(new Date(startDate).getTime()));
        sessionStorage.setItem(currentPageType + 'endDate', JSON.stringify(new Date(endDate).getTime()));
      }

      if (ViewModel.daysLeft) {
        sessionStorage.setItem(currentPageType + 'daysLeft', JSON.stringify(ViewModel.daysLeft));
      }

      //Store filter in session
      if (filterData) {
        sessionStorage.setItem(currentPageType + 'localFilters', JSON.stringify(filterData));
      }
    }

    function captureSortingApplied(jsonData) {
      var sortData = [ViewModel.sortedFields];
      jsonData.sort = sortData;
    }

    function captureSearchString(jsonData) {
      jsonData.searchString = ViewModel.searchString;
    }

    function captureOtherCriterias(jsonData) {
      jsonData.page = ViewModel.params.page;
      jsonData.limit = ViewModel.params.limit;
      jsonData.activePage = ViewModel.params.activePage;

      var startDate = ViewModel.startDate;
      if (startDate) {
        jsonData.startDate = new Date(startDate).getTime();
      }

      var endDate = ViewModel.endDate;
      if (endDate) {
        jsonData.endDate = new Date(endDate).getTime();
      }

      var daysLeft = ViewModel.daysLeft;
      jsonData.daysLeft = daysLeft;
    }

    function detailPage(renewalEntry) {
      $state.go('renewals-detail', { 'renewalId': renewalEntry.renewalId });
    }

    function showToast(message, duration) {
      RenewalsService.showToast($mdToast, message, duration);
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin.Renewals').controller('RenewalsUpdateDialogCtrl', controller);

  controller.$inject = ['commonService', '$q', 'MIS_CONSTANTS', 'RenewalsService', '$mdDialog', '$scope', '$timeout', 'renewalEntry'];

  /**
  * Renewals Update Dialog Controller
  *
  * @memberof Controllers.DateRangeModalController
  *
  * @author beerpratapsingh
  *
  * @param    {!Object}           $scope           $scope
  * @param    {!Object}           commonService    commonService
  * @param    {!Object}           misService       misService
  * @param    {!Object}           $q               $q
  * @param    {!Object}           RenewalsService   RenewalsService
  * @param    {!Object}           $mdDialog        $mdDialog
  * @param    {!Object}           $timeout         $timeout
  *
  */

  function controller(commonService, $q, MIS_CONSTANTS, RenewalsService, $mdDialog, $scope, $timeout, renewalEntry) {

    var ViewModel = this;

    ViewModel.closeDialog = closeDialog;

    ViewModel.updateRenewalEntry = updateRenewalEntry;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.models = {
        policyNumber: renewalEntry.policyNumber,
        noClaimBonus: renewalEntry.ncb,
        premAmount: renewalEntry.renewalPremium,
        renewalNotic: ''
      };

      ViewModel.renewalUpdateModel = [{
        name: 'Policy Number',
        code: 'policyNumber',
        type: 'text',
        required: true
      }, {
        name: 'No Claim bonus %',
        code: 'noClaimBonus',
        type: 'text',
        required: true
      }, {
        name: 'Premium Amount',
        code: 'premAmount',
        type: 'text',
        required: true
      }, {
        name: 'Renewal Notice',
        code: 'renewalNotic',
        type: 'text',
        required: true
      }];
    }

    ViewModel.currentRenewal = {
      $$attachedDocuments: []
    };

    ViewModel.documentListUploadOptions = {
      $$viewMode: false
    };

    ViewModel.identifiers = {
      requestId: renewalEntry.requestId
    };

    function closeDialog() {
      $mdDialog.cancel();
    }

    function updateRenewalEntry() {
      renewalEntry.ncb = ViewModel.models.noClaimBonus;
      renewalEntry.renewalPremium = ViewModel.models.premAmount;

      // RenewalsService.updateRenewalEntry(renewalEntry)
      // .then(response => {
      //   $mdDialog.hide();
      // });
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.Renewals').controller('renewalsDetailCtrl', controller);

  controller.$inject = ['$window', '$scope', 'commonService', '$q', '$stateParams', 'RenewalsService', '$mdDialog', '$mdToast', '$state', 'GENERAL_CONFIG', '$timeout', 'entry', 'RENEWAL_CONSTANTS', 'MIS_CONSTANTS', 'MasterFileService', 'SaleService', 'AuthService'];

  /**
   * renewalsDetailCtrl Controller
   *
   * @memberof Controllers.DateRangeModalController
   *
   * @author beerpratapsingh
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           commonService    commonService
   * @param    {!Object}           misService       misService
   * @param    {!Object}           $q               $q
   * @param    {!Object}           RenewalsService  RenewalsService
   * @param    {!Object}           mdDialog         mdDialog
   * @param    {!Object}           GENERAL_CONFIG   GENERAL_CONFIG
   *
   */
  function controller($window, $scope, commonService, $q, $stateParams, RenewalsService, $mdDialog, $mdToast, $state, GENERAL_CONFIG, $timeout, entry, RENEWAL_CONSTANTS, MIS_CONSTANTS, MasterFileService, SaleService, AuthService) {

    var ViewModel = this;
    ViewModel.renewalEntry = entry.data;
    ViewModel.renewalEntry.members = ViewModel.renewalEntry.members || [];
    ViewModel.fetchOtherQuotes = fetchOtherQuotes;
    ViewModel.removeMember = removeMember;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.communicationData = [];
    ViewModel.sendReminderPopup = sendReminderPopup;

    ViewModel.renewalsStatus = angular.copy(RENEWAL_CONSTANTS.renewalStatuses);

    init();
    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      updateStatusFn();
      backToDashboard();
      function backToDashboard() {
        ViewModel.navbarConfig = {
          goBackState: 'renewals-noquote',
          vertical: 'renewals',
          mode: 'VIEW',
          subtitle: '(View Mode)'
        };
        if (ViewModel.renewalEntry.status === 'NO_RENEWAL_QUOTE') {
          ViewModel.navbarConfig.goBackState = ViewModel.hasPermission('renewals-noquote') ? 'renewals-noquote' : 'renewals-all';
        } else if (ViewModel.renewalEntry.status === 'RENEWAL_READY') {
          ViewModel.navbarConfig.goBackState = ViewModel.hasPermission('renewals-ready') ? 'renewals-ready' : 'renewals-all';
        } else {
          ViewModel.navbarConfig.goBackState = 'renewals-all';
        }
      }

      ViewModel.renewalsDetails = [{
        name: 'Due Date',
        code: 'riskEndDate',
        placeholder: 'dd/mm/yy',
        type: 'date'
        // hide: ViewModel.renewalEntry.vertical == 'tw'
        // required: true
      }, {
        name: 'Net Renewal Premium',
        code: 'netRenewalPremium',
        placeholder: '',
        type: 'number'
      }, {
        name: 'Taxes',
        code: 'taxAmount',
        placeholder: 'Tax amount',
        type: 'number'
      }, {
        name: 'Total Renewal Premium',
        code: 'renewalPremium',
        placeholder: 'Amount',
        type: 'number',
        required: true
      }, {
        name: 'IDV',
        code: 'idv',
        placeholder: 'Idv amount',
        type: 'number',
        hide: hideShowField('idv', ViewModel.renewalEntry.category)
      }, {
        name: 'NCB (%)',
        code: 'ncb',
        placeholder: 'No Claim bonus %',
        type: 'number'
      },
      // {
      //   name: 'Insured members',
      //   code: 'insuredMember',
      //   placeholder: 'Self, mother',
      //   type: 'select',
      //   hide: hideShowField('insuredMember', ViewModel.renewalEntry.category)
      // },
      {
        name: 'Plan name',
        code: 'planName',
        placeholder: 'Plan Name',
        type: 'text',
        hide: hideShowField('planName', ViewModel.renewalEntry.category)
      }, {
        name: 'Plan type',
        code: 'planType',
        placeholder: '',
        type: 'select',
        hide: hideShowField('planType', ViewModel.renewalEntry.category),
        selectConfig: {
          options: MIS_CONSTANTS.productNames,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Cover type',
        code: 'coverType',
        placeholder: '',
        type: 'select',
        hide: hideShowField('coverType', ViewModel.renewalEntry.category),
        selectConfig: {
          options: MIS_CONSTANTS.coverTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Renewal Premium Payment Link',
        code: 'renewalPremiumPaymentLink',
        type: 'text'
      }, {
        name: 'Renewal Premium Paid',
        code: 'isRenewalPremiumPaid',
        type: 'checkbox'
      }];

      ViewModel.statusUpdate = [{
        name: 'Renewal Status',
        code: 'status',
        placeholder: '',
        type: 'select',
        disabled: !(ViewModel.renewalEntry.status === 'NO_RENEWAL_QUOTE' || ViewModel.renewalEntry.status === 'RENEWAL_READY'),
        changeFn: updateStatusFn,
        selectConfig: {
          textKey: 'label',
          valueKey: 'value',
          options: ViewModel.renewalsStatus
        }
      }, {
        name: 'Renewal Date',
        code: 'renewalDate',
        placeholder: 'Date',
        type: 'date'
      }, {
        name: 'New policy number',
        code: 'newPolicyNumber',
        placeholder: 'Policy number',
        type: 'text'
      }, {
        name: 'Customer Response',
        code: 'customerResponse',
        placeholder: 'Customer response',
        type: 'text'
      }];
      //function idvDisplay(idv){
      // for(var i = 0; i < ViewModel.renewalsDetails.length; i++) {
      //   if(ViewModel.renewalEntry.category === 'health' && ViewModel.renewalsDetails[i].name === 'IDV'){
      //     ViewModel.renewalsDetails[i].hide = true;
      //     console.log(ViewModel.renewalsDetails[i].hide = true);
      //   }
      // }
      //}

      ViewModel.renewedPolicyDetails = [{
        name: 'Insurer',
        code: 'renewedPolicyInsurer',
        placeholder: 'Renewed Policy Insurer',
        type: 'text'
      }, {
        name: 'Risk Start Date',
        code: 'renewedPolicyRiskStartDate',
        type: 'date'
      }, {
        name: 'Risk End Date',
        code: 'renewedPolicyRiskEndDate',
        type: 'date'
      }, {
        name: 'Issuance Date',
        code: 'renewedPolicyIssuanceDate',
        type: 'date'
      }, {
        name: 'Net Premium',
        code: 'renewedPolicyNetPremium',
        type: 'number'
      }, {
        name: 'Gross Premium',
        code: 'renewedPolicyGrossPremium',
        type: 'number'
      }];

      function hideShowField(fieldName, categoryName) {
        var showField = false;

        // TW and FW
        if (categoryName === 'tw' || categoryName === 'fw' || categoryName === 'gcv' || categoryName === 'pcv' || categoryName === 'miscd') {
          // Hidden fields
          if (fieldName === 'sumInsured' || fieldName === 'insuredMember' || fieldName === 'planName' || fieldName === 'planType' || fieldName === 'coverType') {
            showField = true;
          } else {
            showField = false;
          }
        }

        // health
        if (categoryName === 'health') {
          // Hidden fields
          if (fieldName === 'idv') {
            showField = true;
          } else {
            showField = false;
          }
        }

        if (categoryName === 'life') {
          // Hidden fields
          if (fieldName === 'idv' || fieldName === 'ncb' || fieldName === 'newPolicyNumber' || fieldName === 'insuredMember') {
            showField = true;
          } else {
            showField = false;
          }
        }
        return showField;
      }

      _setNavbarConfig('VIEW');

      ViewModel.renewalNotices = {
        $$attachedDocuments: []
      };

      ViewModel.documentListUploadOptions = {
        $$viewMode: false,
        filePermissions: {
          view: ViewModel.hasPermission('renewals-file-view'),
          upload: ViewModel.hasPermission('renewals-file-upload'),
          download: ViewModel.hasPermission('renewals-file-download'),
          delete: ViewModel.hasPermission('renewals-file-delete'),
          options: ViewModel.hasPermission('renewals-file-view') || ViewModel.hasPermission('renewals-file-download') || ViewModel.hasPermission('renewals-file-delete')
        }
      };

      ViewModel.tagOptions = {
        tagging: false,
        tag: {
          name: 'Renewal Notice',
          code: 'RENEWAL_NOTICE'
        }
      };

      ViewModel.identifiers = {
        renewalId: ViewModel.renewalEntry.renewalId,
        requestId: ViewModel.renewalEntry.requestIdFromPlatform,
        clientUid: ViewModel.renewalEntry.requestIdFromPlatform
      };

      ViewModel.eventTrackingData = {
        module: 'RENEWALS',
        mxpData: {
          renewalId: ViewModel.renewalEntry.renewalId,
          requestId: ViewModel.renewalEntry.requestIdFromPlatform
        }
      };
      SaleService.getPolicyById(ViewModel.renewalEntry.misId).then(function (result) {
        ViewModel.misData = result;
        _initFormConfigs();
      });

      RenewalsService.getCommunicationDetails(ViewModel.renewalEntry.renewalId).then(function (result) {
        ViewModel.communicationData = result && result.data;
        if (!ViewModel.renewalEntry.smsEnabled && !ViewModel.renewalEntry.emailEnabled) {
          var smsStop = ViewModel.renewalEntry.smsLatestStopTime;
          var emailStop = ViewModel.renewalEntry.emailLatestStopTime;
          var stopTime = smsStop < emailStop ? emailStop : smsStop;

          var stopObj = {
            disableStatus: true,
            disableMsg: 'SMS & Email disabled',
            when: stopTime
          };
          ViewModel.communicationData.push(stopObj);
        }
      });

      ViewModel.reminderObj = {
        renewalId: ViewModel.renewalEntry.renewalId,
        isSms: ViewModel.renewalEntry.smsEnabled,
        isEmail: ViewModel.renewalEntry.emailEnabled,
        emailId: ViewModel.renewalEntry.emailId,
        phoneNumber: ViewModel.renewalEntry.phoneNumber
      };

      getNotices(ViewModel.identifiers);

      // console.log("ViewModel.renewalEntry-->>", ViewModel.renewalEntry);
      // if(ViewModel.renewalEntry.category){
      //   console.log(ViewModel.renewalEntry.category);
      // } else {
      //
      // }
    }

    function _initFormConfigs() {

      ViewModel.basicDetailsConfig = SaleService.basicDetailsConfig;
      ViewModel.saleFieldsConfig = SaleService.saleFieldsConfig;
      ViewModel.policyFields = SaleService.policyFields;
      ViewModel.paymentFields = SaleService.paymentFields;
      ViewModel.marketingFields = SaleService.marketingFields;
      ViewModel.otherFields = SaleService.otherFields;
      ViewModel.commissionFields = SaleService.commissionFields;
    }

    function _onEditAction() {
      startEditMode();
      ViewModel.editMode = true;
    }

    function _onCancelAction() {
      stopEditMode();
      $window.history.back();
    }

    function _setNavbarConfig(mode) {
      if (mode === 'EDIT') {
        ViewModel.navbarConfig.mode = 'EDIT';
        ViewModel.navbarConfig.subtitle = '(Edit Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'button',
          class: '',
          action: _onCancelAction,
          permissionCode: 'renewals-detail-update',
          name: 'Discard Changes',
          dataAuto: 'discard-renewals-button'
        }, {
          type: 'button',
          class: '',
          action: saveRenewalDetails,
          permissionCode: 'renewals-detail-update',
          name: 'Save Changes',
          dataAuto: 'save-renewals-button'
        }];
      } else if (mode === 'VIEW') {
        ViewModel.navbarConfig.mode = 'VIEW';
        ViewModel.navbarConfig.title = 'Renewals Information';
        ViewModel.navbarConfig.subtitle = '(View Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'icon',
          class: '',
          action: _onEditAction,
          permissionCode: 'renewals-detail-update',
          name: 'Edit',
          iconSrc: '/images/common/edit.svg',
          dataAuto: 'edit-renewals-button'
        }];
      }
    }

    function showToast(message, duration) {
      RenewalsService.showToast($mdToast, message, duration);
    }

    function startEditMode() {
      ViewModel.editMode = true;
      _setNavbarConfig('EDIT');
    }

    function stopEditMode() {
      ViewModel.editMode = false;
      _setNavbarConfig('VIEW');
    }

    function saveRenewalDetails() {
      stopEditMode();
      ViewModel.renewalEntry.noticesCount = ViewModel.renewalNotices.$$attachedDocuments.length;
      RenewalsService.updateRenewalEntry(ViewModel.renewalEntry).then(function () {
        stopEditMode();
        showToast('Success', 5000);
        $state.reload();
      });
    }

    function updateStatusFn() {
      if (ViewModel.renewalEntry.status === 'NO_RENEWAL_QUOTE') {
        ViewModel.renewalsStatus = [{ 'value': 'NO_RENEWAL_QUOTE', 'label': 'No Renewal Quote' }, { 'value': 'RENEWAL_PENDING_VERIFICATION', 'label': 'Success (Pending)' }];
      } else if (ViewModel.renewalEntry.status === 'RENEWAL_READY') {
        ViewModel.renewalsStatus = [{ 'value': 'RENEWAL_READY', 'label': 'Renewal Ready' }, { 'value': 'RENEWAL_PENDING_VERIFICATION', 'label': 'Success (Pending)' }];
      } else {}
    }

    function getNotices(identifiers) {
      getDocuments(identifiers).then(function (result) {
        ViewModel.renewalNotices.$$attachedDocuments = result;
      }).catch(function () {
        showToast('Insufficient data to generate other quotes', 3000);
      });
    }

    function getDocuments(identifiers) {
      var reqObj = {};
      if (identifiers.requestId) {
        reqObj.requestId = identifiers.requestId;
      } else {
        reqObj.renewalId = identifiers.renewalId;
      }
      return MasterFileService.getDocuments(reqObj);
    }

    function fetchOtherQuotes() {
      var requestIdFromPlatform = ViewModel.renewalEntry.requestIdFromPlatform;
      var stage = 'profile';
      //if(ViewModel.renewalEntry.validForRolloverQuotes || (ViewModel.renewalEntry.netRenewalPremium != null)){
      if (ViewModel.renewalEntry.validForRolloverQuotes) {
        stage = 'quote';
      }
      RenewalsService.getRedirectUrl(requestIdFromPlatform, ViewModel.renewalEntry.vertical, stage, false).then(function (url) {
        $window.open(url, '_blank');
      }).catch(function () {
        showToast('Insufficient data to generate other quotes', 5000);
      }).catch(function () {
        showToast('Insufficient data to generate other quotes', 5000);
      });
    }

    function removeMember(index) {
      ViewModel.renewalEntry.members.splice(index, 1);
    }

    function sendReminderPopup() {
      $mdDialog.show({
        templateUrl: 'dev/renewals/templates/dialogs/send-reminder.tpl.html',
        clickOutsideToClose: true,
        locals: {
          reminder: ViewModel.reminderObj
        },
        controller: SendReminderController
      });
      SendReminderController.$inject = ['$scope', 'reminder', '$mdDialog', 'RenewalsService', 'commonService'];

      function SendReminderController($scope, reminder, $mdDialog, RenewalsService, commonService) {
        $scope.reminder = reminder;
        $scope.communicationFields = [{
          name: 'SMS',
          code: 'isSms',
          type: 'checkbox'
        }, {
          name: 'Email',
          code: 'isEmail',
          type: 'checkbox'
        }];

        $scope.sendReminder = function () {
          RenewalsService.sendReminder($scope.reminder).then(function () {
            commonService.showToast('Reminder sent successfully.');
            $mdDialog.cancel();
          }).catch(function () {
            commonService.showToast('Send reminder failed.');
          });
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.Renewals').provider('RenewalsService', RenewalsService);

  RenewalsService.$inject = [];

  return RenewalsService;

  // //////////////////////////////////////////////////////

  /**
   * RenewalsService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function RenewalsService() {

    RenewalsService.$inject = ['$q', 'commonService', '$window'];

    return {
      $get: RenewalsService
    };
    // //////////////////////////////////////////////////////
    /**
     * RenewalsService
     * @public
     * @memberof   module:tutlemintAdmin.Payouts#
     * @author     snehilmodani
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     *
     * @return     {Object}    Instance of the Service
     */
    function RenewalsService($q, commonService) {

      return {
        //Functions
        fetchPolicyDetails: fetchPolicyDetails,
        fetchRenewalEntries: fetchRenewalEntries,
        exportRenewalData: exportRenewalData,
        updateRenewalEntry: updateRenewalEntry,
        fetchEntryByRenewalId: fetchEntryByRenewalId,
        fetchDaysLeftDropdown: fetchDaysLeftDropdown,
        fetchMonthsDropdown: fetchMonthsDropdown,
        organizeDaysLeftForInsights: organizeDaysLeftForInsights,
        fetchOtherQuotes: fetchOtherQuotes,
        showToast: showToast,
        getRedirectUrl: getRedirectUrl,
        getCommunicationDetails: getCommunicationDetails,
        sendReminder: sendReminder
      };

      function fetchPolicyDetails(misId) {
        return commonService.makeHttpCall('/policydetails/' + misId, undefined, 'GET', false, false, true);
      }

      function fetchRenewalEntries(jsonData) {
        return commonService.makeHttpCall('/renewal/fetch/renewalentries', jsonData, 'POST', false, false, true);
      }

      function exportRenewalData(jsonData) {
        return commonService.makeHttpCall('/renewal/export/data', jsonData, 'POST', false, false, true);
      }

      function updateRenewalEntry(jsonData) {
        return commonService.makeHttpCall('/renewal/update/renewalentry', jsonData, 'POST', false, false, true);
      }

      function fetchEntryByRenewalId(renewalId) {
        return commonService.makeHttpCall('/renewal/fetch/renewalentry/' + renewalId, undefined, 'GET', false, false, true);
      }

      function fetchDaysLeftDropdown(start, end) {
        var daysLeftArray = [];
        daysLeftArray.push(undefined);
        for (var i = start; i <= end; i++) {
          daysLeftArray.push(i);
        }

        return daysLeftArray;
      }

      function fetchMonthsDropdown() {
        var currentDate = moment();
        var helperArray = [2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9];
        var monthsDropdown = [];
        //monthsDropdown.push({'value':'Clear', 'startDate':undefined, 'endDate':undefined});
        for (var i = 0; i < helperArray.length; i++) {
          var date = currentDate.clone().add(helperArray[i], 'month');
          var firstDayOfTheMonth = date.clone().startOf('month');
          var lastDayOfTheMonth = date.clone().endOf('month');
          var value = firstDayOfTheMonth.format('MMM, YYYY');
          var startDate = new Date(firstDayOfTheMonth.valueOf());
          var endDate = new Date(lastDayOfTheMonth.valueOf());
          monthsDropdown.push({ 'value': value, 'startDate': startDate, 'endDate': endDate });
        }

        return monthsDropdown;
      }

      function organizeDaysLeftForInsights(daysLeftInsights) {
        var rangeLabels = [];
        var rangeCounts = [];
        var rangeDates = {};
        for (var i = 0; i < daysLeftInsights.length; i++) {
          var daysLeftInsight = daysLeftInsights[i];
          rangeLabels.push(daysLeftInsight.label);
          rangeCounts.push(daysLeftInsight.count);
          rangeDates[daysLeftInsight.label] = {
            'startDate': new Date(daysLeftInsight.rangeStartDate),
            'endDate': new Date(daysLeftInsight.rangeEndDate) };
        }

        var organizedObj = {
          'rangeLabels': rangeLabels,
          'rangeCounts': rangeCounts,
          'rangeDates': rangeDates
        };

        return organizedObj;
      }

      function fetchOtherQuotes(renewalId) {
        return commonService.makeHttpCall('/renewal/fetch/otherquotes/' + renewalId, undefined, 'GET', false, false, true);
      }

      function showToast(mdToast, message, duration) {
        mdToast.show(mdToast.simple().textContent(message).hideDelay(duration).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
          if (response === 'ok') {
            //$window.console.log('You clicked the \'DISMISS\' action.');
          }
        });
      }

      function getRedirectUrl(requestId, vertical, stage, proMode) {
        var returnPromise = $q.defer();
        var reqType = 'GET';
        var reqUrl = '/url';

        var reqObj = {
          requestId: requestId,
          vertical: vertical,
          stage: stage // quotes/checkout
        };

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function (res) {
          var data = res.data;
          if (proMode) {
            returnPromise.resolve(data.proUrl);
          } else {
            returnPromise.resolve(data.wwwUrl);
          }
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getCommunicationDetails(id) {
        var url = '/renewal/getRenewalCommunication/' + id;
        return commonService.makeHttpCall(url, undefined, 'GET', false, false, true);
      }

      function sendReminder(reminder) {
        var url = '/renewal/reminders';
        return commonService.makeHttpCall(url, reminder, 'POST', false, false, true);
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('UtilService', UtilService);

  // //////////////////////////////////////////////////////

  /**
   * UtilService
   *
   * @public
   *
   * @author    praveenkamath
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function UtilService() {

    UtilService.$inject = ['$q', '$rootScope', '$cookies', 'CONSTANTS', 'MIS_CONSTANTS', 'commonService', 'GENERAL_CONFIG', 'CONSTANTS_LIST'];

    return {
      $get: UtilService
    };

    // //////////////////////////////////////////////////////

    /**
     * UtilService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin.ProposalTxn
     *
     * @author     praveenkamath
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     *
     * @return     {Object}    Instance of the Service
     */
    function UtilService($q, $rootScope, $cookies, CONSTANTS, MIS_CONSTANTS, commonService, GENERAL_CONFIG, CONSTANTS_LIST) {

      var tags = MIS_CONSTANTS.filetags;
      var cookieConfig = {
        secure: true,
        domain: GENERAL_CONFIG.cookieDomain
      };

      return {
        //Functions
        getErrorResponse: getErrorResponse,
        findTag: findTag,
        fileCanBePreviewed: fileCanBePreviewed,
        groupDocuments: groupDocuments,
        getBrokerConfig: getBrokerConfig
      };

      // //////////////////////////////////////////////////////

      /**
       * Converts error json to a generic error json with statusCode and message fields
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.ProposalTxn#
       *
       * @author     praveenkamath
       *
       * @param      {Object}  err  Error object
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getErrorResponse(err) {
        var statusCode;
        var message;
        if (err.data.errorCode) {
          statusCode = err.data.errorCode;
          if (statusCode === 400) {
            message = CONSTANTS.ERROR_400_MESSAGE;
          } else {
            message = err.data.errorMessage;
          }
        } else {
          statusCode = err.data.statusCode;
          message = err.data.message;
        }
        return {
          statusCode: statusCode,
          message: message
        };
      }

      /**
       * Function for finding tag by tag code
       *
       * @private
       *
       * @function
       *
       * @param    {!String}           code       Tag code to be searched
       *
       * @author snehilmodani
       *
       * @return   {Object}            Found tag Object
       *
       */
      function findTag(code) {
        for (var idx = tags.length - 1; idx >= 0; idx--) {
          var tag = tags[idx];
          if (tag.code === code) {
            return tag;
          }
        }
      }

      /**
       * Function to Can Be Viewed
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           file         file
       *
       * @author snehilmodani
       *
       */
      function fileCanBePreviewed(file, allowedFileViewTypes) {
        var re = /(?:\.([^.]+))?$/;
        var fileName = file.name || file.fileName || file.documentName;
        var ext = re.exec(fileName.toLowerCase())[1];
        file.$$ext = ext;
        if (allowedFileViewTypes.indexOf(ext) !== -1) {
          return true;
        }

        return false;
      }

      /**
       * Group Documents based on Tags
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           file         file
       *
       * @author snehilmodani
       *
       */
      function groupDocuments(documents) {
        var attachmentGroupedDocs = {};

        for (var idx = 0; idx < documents.length; idx++) {
          var doc = documents[idx];
          if (doc.tag) {
            var foundTag = findTag(doc.tag);
            if (!foundTag) {
              foundTag = {
                name: 'Other',
                code: 'OTHER'
              };
            }

            doc.tagName = foundTag.name;

            if (!attachmentGroupedDocs[foundTag.name]) {
              attachmentGroupedDocs[foundTag.name] = [];
            }

            attachmentGroupedDocs[foundTag.name].push(doc);
          }
        }

        return attachmentGroupedDocs;
      }

      function getBrokerConfig() {
        var reqUrl = '/getBrokerConfig?source=adminFrontendConfig';
        var returnPromise = $q.defer();
        commonService.makeHttpCall(reqUrl, {}, 'GET').then(function (res) {
          sessionStorage.setItem('broker', res.data.broker);
          sessionStorage.setItem('tenant', res.data.defaultTenant);
          sessionStorage.setItem('compliantEnabled', res.data.isCompliantEnabled);
          commonService.setItemSession('common', 'hostUrl', res.data.hostUrl);
          commonService.setItemSession('common', 'documentUrl', res.data.documentUrl);
          var features = res.data && JSON.stringify(res.data.features);
          if (features) {
            sessionStorage.setItem('features', features);
          }
          var records = res.data && JSON.stringify(res.data.records);
          if (records) {
            sessionStorage.setItem('records', records);
          }
          $cookies.put('broker', res.data.broker, cookieConfig);
          $cookies.put('emailDomain', btoa(res.data.emailDomain), cookieConfig);
          $cookies.put('loginType', btoa(res.data.loginType), cookieConfig);
          var tenant = res.data.tenant || res.data.defaultTenant;
          $cookies.put('organisation', btoa(tenant), cookieConfig);
          if (res.data && res.data.gtmKey && res.data.gtmKey.wildcard) {
            var gtmKey = res.data.gtmKey.wildcard;
            gtmPixel(gtmKey);
          }
          if (!sessionStorage.getItem('fileBucket')) {
            getFileServiceConfig();
          }
          _initNinjaPlatform();
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getFileServiceConfig() {
        var reqUrl = '/getBrokerConfig?source=fileServiceConfig';
        commonService.makeHttpCall(reqUrl, {}, 'GET').then(function (res) {
          if (res.status && res.status === 200) {
            sessionStorage.setItem('fileBucket', res.data.bucket);
          }
        });
      }

      function _initNinjaPlatform() {
        var ninjaPlatform = commonService.getNinjaPlatform();
        if (!ninjaPlatform) {
          $cookies.put(CONSTANTS_LIST.PLATFORM_COOKIE, CONSTANTS_LIST.PLATFORM.WEB_APP, cookieConfig);
        }
      }
      // Google Tag Manager
      function gtmPixel(gtmKey) {
        // GTM key - GTM-MRV63XG
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmKey);
      }
      //End Google Tag Manager
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('DocumentMasterService', DocumentMasterService);

  // //////////////////////////////////////////////////////

  /**
   * DocumentMasterService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function DocumentMasterService() {

    DocumentMasterService.$inject = ['$q', '$http', 'GENERAL_CONFIG', '$window', '$timeout', 'commonService'];

    return {
      $get: DocumentMasterService
    };

    // //////////////////////////////////////////////////////

    /**
     * DocumentMasterService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    $http                          Angular http service
     *
     * @return     {Object}    Instance of the Service
     */
    function DocumentMasterService($q, $http, GENERAL_CONFIG, $window, $timeout, commonService) {

      var documentMasterAPI = getReqUrl() + '/platform/v0/file';

      return {
        //Functions
        getDocuments: getDocuments,
        getUploadUrl: getUploadUrl,
        getDownloadFileUrl: getDownloadFileUrl,
        getViewFileUrl: getDownloadFileUrl,
        updateFile: updateFile,
        getDeleteFileUrl: getDeleteFileUrl,
        deleteFile: deleteFile,
        getDisplayFileUrl: getDisplayFileUrl,
        downloadFile: downloadFile,
        getReqUrl: getReqUrl
      };

      // //////////////////////////////////////////////////////

      function getReqUrl() {
        return commonService.getItemSession('common', 'documentUrl') + '/api';
      }

      /**
       * Converts error json to a generic error json with statusCode and message fields
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  identifiers      Identifiers Object
        * @return     {Object}  Promise, which resolves to boolean
       */
      function getDocuments(identifiers) {
        var returnPromise = $q.defer();
        var reqUrl = documentMasterAPI;

        var isDeletedRequest = {
          isDeleted: false
        };

        angular.extend(identifiers, isDeletedRequest);

        var reqObj = {
          url: reqUrl,
          method: 'POST',
          data: identifiers
        };

        $http(reqObj).then(function (res) {
          res = res.data;
          if (res.data && res.data.uploadFileInfoList) {
            var response = res.data.uploadFileInfoList;
            returnPromise.resolve(response);
          } else {
            returnPromise.resolve([]);
          }
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getUploadUrl() {
        return documentMasterAPI + '/upload';
      }
      function getDisplayFileUrl(fileId) {
        return documentMasterAPI + ('/download/' + fileId) + '?token=1RbQAPrKJnc1e7jFzKQB3X4e9wzxNIgYSOTGC7SZRm35g';
      }
      function getDownloadFileUrl(id) {
        return documentMasterAPI + '/download/' + id + '?token=1RbQAPrKJnc1e7jFzKQB3X4e9wzxNIgYSOTGC7SZRm35g';
      }

      function getDeleteFileUrl(id) {
        return documentMasterAPI + '/' + id;
      }

      function getUpdateFileUrl(id) {
        return documentMasterAPI + '/' + id;
      }

      function updateFile(fileId, file) {
        var returnPromise = $q.defer();
        var reqUrl = getUpdateFileUrl(fileId);

        var reqObj = {
          url: reqUrl,
          method: 'PUT',
          data: file
        };

        $http(reqObj).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function deleteFile(fileId) {
        var returnPromise = $q.defer();
        var reqUrl = getDeleteFileUrl(fileId);

        var reqObj = {
          url: reqUrl,
          method: 'DELETE'
        };

        $http(reqObj).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function downloadFile(s3Id, fileName) {
        var reqUrl = getDownloadFileUrl(s3Id);

        var reqObj = {
          url: reqUrl,
          headers: { 'Caller': 'localhost', 'token': '1RbQAPrKJnc1e7jFzKQB3X4e9wzxNIgYSOTGC7SZRm35g' },
          method: 'GET',
          responseType: 'blob',
          params: { download: true }
        };

        return $http(reqObj).then(function (response) {
          if (angular.equals(response.status, 200)) {
            var headers = response.headers();
            var contentType = '';
            if (headers['content-type'] === 'application/octet-stream') {
              contentType = 'application/pdf';
            } else {
              contentType = headers['content-type'];
            }
            var blob = new Blob([response.data], { type: contentType });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            $timeout(function () {
              document.body.removeChild(link);
            }, 100);
          }
        }).catch(function (err) {
          $window.console.log(err);
        });
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('FormHelperService', FormHelperService);

  FormHelperService.$inject = [];

  return FormHelperService;

  // //////////////////////////////////////////////////////

  /**
   * FormHelperService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function FormHelperService() {

    FormHelperService.$inject = ['$q', 'commonService', 'MIS_CONSTANTS', '$filter', '$timeout'];

    return {
      $get: FormHelperService
    };

    // //////////////////////////////////////////////////////

    /**
     * FormHelperService
     *
     * @public
     *
     * @memberof   module:titan.Main.Catalog.FormHelperService#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     * @param      {Object}    MIS_CONSTANTS                  MIS Constants
     *
     * @return     {Object}    Instance of the Service
     */
    function FormHelperService($q, commonService, MIS_CONSTANTS, $filter, $timeout) {
      var tags = MIS_CONSTANTS.filetags;

      var assignedVertical;
      var TM_URL = commonService.getUrlTM();

      return {

        tags: tags,
        //Functions
        setAssignedVertical: setAssignedVertical,
        getAssignedVertical: getAssignedVertical,
        findTag: findTag,
        searchInsurerFn: searchInsurerFn,
        searchIntermediariesFn: searchIntermediariesFn,
        searchRTOFn: searchRTOFn,
        searchMakemodelFn: searchMakemodelFn,
        selectedTLChangeFn: selectedTLChangeFn,
        selectedSalesExecutiveChangeFn: selectedSalesExecutiveChangeFn,
        selectedRTOChangeFn: selectedRTOChangeFn,
        selectedPreviousInsurerChangeFn: selectedPreviousInsurerChangeFn,
        prepareCsvData: prepareCsvData,
        isACertifedPartner: isACertifedPartner
      };

      // //////////////////////////////////////////////////////

      function getAssignedVertical() {
        return assignedVertical;
      }

      function setAssignedVertical(value, model) {
        if (model) {
          model.$$assignedVertical = value;
        }
        assignedVertical = value;
      }

      /**
       * Function for dowload selected file
       *
       * @private
       *
       * @function
       *
       * @param    {!String}           code       Tag code tobe searched
       *
       * @author snehilmodani
       *
       * @return   {Object}            Found tag Object
       *
       */
      function findTag(code) {
        for (var idx = tags.length - 1; idx >= 0; idx--) {
          var tag = tags[idx];
          if (tag.code === code) {
            return tag;
          }
        }
      }

      /**
       * Function to search Insurers
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchInsurerFn(searchKey) {
        var deferred = $q.defer();
        var insurers = angular.copy(MIS_CONSTANTS.insurers);
        if (searchKey) {
          var results = $filter('filter')(insurers, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(insurers);
        }

        return deferred.promise;
      }

      /**
       * Function to search Intermediaries
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchIntermediariesFn(searchKey) {
        var deferred = $q.defer();

        if (searchKey) {
          var promise = commonService.makeHttpCall('/getpartnersinfo?searchKey=' + searchKey, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.status === 'SUCCESS') {
              var intermediaries = res.data.data || [];
              deferred.resolve(intermediaries);
            } else {
              deferred.reject(true);
            }
          });
        } else {
          deferred.reject(true);
        }

        return deferred.promise;
      }

      /**
       * Function to search RTOs
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchRTOFn(searchKey, model, config) {
        var deferred = $q.defer();
        if (searchKey) {
          searchKey = searchKey.replace('-', '');
          var promise = commonService.makeThirdPartyApiCall(TM_URL, '/masters/getnewrto?searchKey=' + searchKey, {}, 'GET', false, false);
          promise.then(function (res) {
            var rtoLocations = _reverseSortArray(res.data);
            if (!rtoLocations || rtoLocations && rtoLocations.length < 1) {
              $timeout(function () {
                if (config) {
                  delete model.rtoLocation;
                }
              }, 2000);
            }
            deferred.resolve(rtoLocations);
          });
        } else {
          delete model.rtoLocation;
          deferred.reject(true);
        }

        return deferred.promise;
      }

      /**
       * Function to search makemodel
       *
       * @public
       *
       * @function
       *
       * @param    {!Array}           data          Array of RTO search Results
       *
       * @author   snehilmodani
       *
       * @return   {Array}           Sorted Array
       *
       */
      function _reverseSortArray(data) {
        data.sort();
        return data.reverse();
      }

      /**
       * Function to search makemodel
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author   snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchMakemodelFn(searchKey, vehicleType, createdAt) {
        var deferred = $q.defer();
        if (searchKey) {
          searchKey = searchKey.replace('-', '');

          createdAt = createdAt ? moment(createdAt).format('x') : moment().format('x');
          var url = '/masters/v1/getnewmakemodel?searchKey=' + searchKey + '&vertical=' + getAssignedVertical() + '&vehicleClass=' + vehicleType + '&createdAt=' + createdAt;
          var promise = commonService.makeThirdPartyApiCall(TM_URL, url, {}, 'GET', false, false);

          promise.then(function (res) {
            var makeModel = _reverseSortArray(res.data);
            deferred.resolve(makeModel);
          });
        } else {
          deferred.reject(true);
        }

        return deferred.promise;
      }

      /**
       * Function called on change of TL
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedTLChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.tlName = selectedItem;
        } else {
          delete model.tlName;
        }
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedSalesExecutiveChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.salesExecutive = selectedItem;
        } else {
          delete model.salesExecutive;
        }
      }

      /**
       * Function called on change of Make Model
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedRTOChangeFn(selectedItem, sale) {
        if (selectedItem) {
          sale.rtoLocation = selectedItem.rtoPlateLntLoc;
          sale.rtoCode = selectedItem.rtoCode;
        } else {
          delete sale.rtoLocation;
          delete sale.rtoCode;
        }
      }

      /**
       * Function called on change of previous Insurer
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedPreviousInsurerChangeFn(selectedItem, sale) {
        if (selectedItem) {
          sale.prevInsurer = selectedItem.value;
        } else {
          delete sale.prevInsurer;
        }
      }

      /**
       * Function for check get polict term in year
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           policyTerm          Policy Term
       *
       * @author snehilmodani
       *
       */
      function _getPolicyTermInYears(policyTerm) {
        if (policyTerm) {
          return Math.round(policyTerm / 12 * 100) / 100;
        } else {
          return '-';
        }
      }

      /**
       * Function for prepare CSV date
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           sale          sale
       *
       * @author snehilmodani
       *
       */
      function prepareCsvData(sale) {

        var data = {
          'policyDetailID': sale.policyDetailsId,
          'Request ID': sale.requestId,
          'MIS / Data entry owner': sale.misOwner,
          'Folder No.': sale.folderNo,
          'Sale Closed Date': moment(sale.saleClosedDate).format('DD/MM/YYYY'),
          'Sale Closed Month': sale.salesClosedDateMonth,
          'Booking/Issued Date': moment(sale.issuanceDate).format('DD/MM/YYYY'),
          'Business Vertical': sale.businessVertical,
          'Product category': productCategoryDisplay(sale.productCategory),
          'Vehicle type': vehicleTypeDisplay(sale.vehicleType),
          'Carrier type': carrierTypeDisplay(sale.carrierType),
          'Channel Type': channelTypeDisplay(sale.channelType),
          'Intermediary Name': sale.intermediaryName,
          'Intermediary User ID': sale.intermediaryLoginId,
          'DP No': sale.intermediaryInternalId,
          'Tenant': sale.tenant,
          'Website source': sale.utmSource,
          'Website medium': sale.utmMedium,
          'utm campaign': sale.utmCampaign,
          'utm term': sale.utmTerm,
          'utm content': sale.utmContent,
          'Business Type': sale.businessType,
          'TL Name': sale.tlName,
          'Sales executive': sale.salesExecutive,
          'Facilitator Name': sale.facilitatorName,
          'Family ID': sale.familyId,
          'Customer First Name': sale.policyProposerFName,
          'Customer Last Name': sale.policyProposerLName,
          'Customer Mobile': sale.policyProposerMobile,
          'Customer Alt. Mobile': sale.policyProposerAlternateMobile,
          'Customer E-mail': sale.policyProposerEmail,
          'Customer age': sale.age,
          'Customer Gender': sale.policyProposerGender,
          'Customer DOB': moment(sale.policyProposerDob).format('DD/MM/YYYY'),
          'Insured First Name': sale.insuredFName,
          'Insured Last Name': sale.insuredLName,
          'Insured Mobile': sale.insuredMobile,
          'Insured Alt. Mobile': sale.insuredAltMobile,
          'Insured E-mail': sale.insuredEmail,
          'Current Address': sale.communicationAddress,
          'Current City': sale.communicationCity,
          'Current State': sale.communicationState,
          'Current Pincode': sale.communicationPincode,
          'Permanent Address': sale.registrationAddress,
          'Permanent City': sale.registrationCity,
          'Permanent State': sale.registrationState,
          'Permanent Pincode': sale.registrationPincode,
          'Nominee first name': sale.nomineeFName,
          'Nominee last name': sale.nomineeLName,
          'Nominee age': sale.nomineeAge,
          'Nominee dob': moment(sale.nomineeDob).format('DD/MM/YYYY'),
          'Nominee relationship': sale.nomineeRelationship,
          'Registration no.': sale.registrationNo,
          'Make': sale.make,
          'Model': sale.model,
          'Variant': sale.variant,
          'Vehicle Id': sale.vehicleId,
          'Cubic Capacity': sale.cc,
          'Fuel Type': sale.fuel,
          'Mfg. Year': sale.manufactureYear,
          'Engine No.': sale.engineNo,
          'Chassis No.': sale.chassisNo,
          'GVW / Tonnage': sale.gvw,
          'Seating capacity': sale.seatingCapacity,
          'No. of Insured (Health)': sale.noOfInsured,
          'Highest Insured DOB (Health)': moment(sale.highestInsuredDOB).format('DD/MM/YYYY'),
          'P.P. Request Type (Inspection/Medical)': sale.ppRequestType,
          'PP _Request Date': sale.ppOpendate,
          'PP_Request Close Date': sale.ppCloseDate,
          'PP_Request Status (Pass/Fail)': sale.ppStatus,
          'PP_ TAT': sale.ppTat,
          'PP_Remarks': sale.ppRemarks,
          'Documents Receipt Date': moment(sale.documentReceiptDate).format('DD/MM/YYYY'),
          'Documents Receipt Month': sale.documentReceiptMonth,
          'Ops Exec.': sale.opsExecutive,
          'Case Status': caseStatusDisplay(sale.policyStatus),
          'Case Remarks / Discrepancy': sale.caseRemarksDiscrepancy,
          'RM Welcome Call': sale.rmWelcomeCall,
          'Endorsement': sale.endorsement,
          'Lead Close to Renewal': sale.leadCloseToRenewal,
          'Days Left For Renewal': sale.daysLeftForRenewal,
          'Previous Insurer': sale.prevInsurer,
          'Insurer': insurerDisplay(sale.insurer),
          'Cover type': planTypeDisplay(sale.planType),
          'Plan name': sale.planName,
          'product name': productNameDisplay(sale.productName),
          'Branch Location': sale.branchLocation,
          'Payment Type (Website Gateway/Payment Link)': sale.paymentType,
          'Payment mode (NEFT / Cash / payment gateway)': sale.paymentMode,
          'Replenishment mode': sale.replenishmentMode,
          'Payment Amount': sale.paymentAmount,
          'Payment Status (pending / completed)': sale.paymentStatus,
          'Refund Status': sale.refundStatus,
          'Refund Date': moment(sale.refundDate).format('DD/MM/YYYY'),
          'Remarks': sale.remarks,
          'QC': sale.qc,
          'Policy sent to customer date': moment(sale.policySentToCustomerDate).format('DD/MM/YYYY'),
          'Policy Issuance TAT': sale.policyIssuanceTAT,
          'Courier Sent (HC)': sale.courierSent,
          'Courier Company': sale.courierCompany,
          'POD No. of Courier': sale.podNoOfCourier,
          'Saiba Entry': sale.saibaEntry,
          'RSA Status': sale.rsaStatus,
          'RSA Policy Sent': sale.rsaPolicySent,
          'Bank Name': sale.bankName,
          'A/c No.': sale.acNo,
          'Cheque Date': sale.chequeDate,
          'Cheque No.': sale.chequeNo,
          'Previous Policy No.': sale.prevPolicyNumber ? '\'' + sale.prevPolicyNumber : '',
          'Policy No.': sale.policyNumber ? '\'' + sale.policyNumber : '',
          'Covernote No. (If CN Issued)': sale.coverNoteNo,
          'Covernote issue date': moment(sale.covernoteIssuanceDate).format('DD/MM/YYYY'),
          'Risk Start Date': moment(sale.startDate).format('DD/MM/YYYY'),
          'Risk End Date': moment(sale.endDate).format('DD/MM/YYYY'),
          'Policy Term': _getPolicyTermInYears(sale.$$policyTerm),
          'Policy Premium Term': sale.policyPremiumTerm,
          'IDV/SA': sale.idv,
          'Elec accessories': sale.electricalValue,
          'Non-Elec accessories': sale.nonElecValue,
          'Bifuel Kit': sale.biFuelKitValue,
          'NCB (%)': sale.ncb,
          'Policy Discount Grid': sale.policyDiscountGrid,
          'Basic OD Premium': sale.odPremium,
          'Net OD Premium': sale.netOdPremium,
          'TP Premium': sale.tpPremium,
          'Net Premium': sale.netPremium,
          'Ser. Tax': sale.serviceTax,
          'Gross Premium (Rs)': sale.grossPremium,
          //'Commissionable Premium': sale.commissionablePremium,
          'Reconciliation status': sale.reconcilationStatus,
          //'Facilitator Payable%': sale.facilitatorPayable,
          //'% IRDA Comm': sale.IRDACommPercent,
          //'% Fintech Revenue': sale.fintechRevenue,
          //'% Payout': sale.payout,
          //'%Special comm1': sale.specialComm1,
          //'%Special comm2': sale.specialComm2
          'Already Paid': sale.alreadyPaid,
          'Commission channel': sale.commissionChannel,
          'isSmartZone': sale.smartZone,
          'creation source': sale.creationSource,
          'Review Status': sale.reviewStatus,
          'Record Status': sale.recordStatus
        };

        return data;
      }

      function productCategoryDisplay(productCategory) {
        for (var key in MIS_CONSTANTS.productCategories) {
          if (MIS_CONSTANTS.productCategories[key].value === productCategory) {
            productCategory = MIS_CONSTANTS.productCategories[key].text;
            break;
          }
        }
        return productCategory;
      }

      function productNameDisplay(productName) {
        for (var key in MIS_CONSTANTS.productNames) {
          if (MIS_CONSTANTS.productNames[key].value === productName) {
            productName = MIS_CONSTANTS.productNames[key].text;
            break;
          }
        }
        return productName;
      }

      function planTypeDisplay(planType) {
        for (var key in MIS_CONSTANTS.planTypes) {
          if (MIS_CONSTANTS.planTypes[key].value === planType) {
            planType = MIS_CONSTANTS.planTypes[key].text;
            break;
          }
        }
        return planType;
      }

      function vehicleTypeDisplay(vehicleType) {
        for (var key in MIS_CONSTANTS.vehicleTypesAll) {
          if (MIS_CONSTANTS.vehicleTypesAll[key].value === vehicleType) {
            vehicleType = MIS_CONSTANTS.vehicleTypesAll[key].text;
            break;
          }
        }
        return vehicleType;
      }

      function carrierTypeDisplay(carrierType) {
        for (var key in MIS_CONSTANTS.carrierTypes) {
          if (MIS_CONSTANTS.carrierTypes[key].value === carrierType) {
            carrierType = MIS_CONSTANTS.carrierTypes[key].text;
            break;
          }
        }
        return carrierType;
      }

      function channelTypeDisplay(channelType) {
        for (var key in MIS_CONSTANTS.channelTypes) {
          if (MIS_CONSTANTS.channelTypes[key].value === channelType) {
            channelType = MIS_CONSTANTS.channelTypes[key].text;
            break;
          }
        }
        return channelType;
      }

      function caseStatusDisplay(caseStatus) {
        for (var key in MIS_CONSTANTS.policyStatuses) {
          if (MIS_CONSTANTS.policyStatuses[key].value === caseStatus) {
            caseStatus = MIS_CONSTANTS.policyStatuses[key].text;
            break;
          }
        }
        return caseStatus;
      }

      function insurerDisplay(insurer) {
        for (var key in MIS_CONSTANTS.insurers) {
          if (MIS_CONSTANTS.insurers[key].value === insurer) {
            insurer = MIS_CONSTANTS.insurers[key].label;
            break;
          }
        }

        return insurer;
      }

      function isACertifedPartner(formData) {
        var reqUrl = '/mintpro-be/partner';
        return commonService.makeHttpCall(reqUrl, formData, 'GET', false, false, false);
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

	angular.module('brokerAdmin').provider('CommentService', CommentService);

	CommentService.$inject = [];

	return CommentService;

	// //////////////////////////////////////////////////////

	/**
  * CommentService
  *
  * @public
  *
  * @author    snehilmodani
  *
  * @class
  *
  * @return    {Object} Instance of the Service (Provider)
  *
  */
	function CommentService() {

		CommentService.$inject = ['$q', 'commonService', 'CONSTANTS_LIST'];

		return {
			$get: CommentService
		};

		// //////////////////////////////////////////////////////

		/**
   * CommentService
   *
   * @public
   *
   * @memberof   module:turtlemintAdmin.policyIssuance.CommentService#
   *
   * @author     snehilmodani
   *
   * @class
   * @param      {Object}    $q                             Angular's $q promise library
   * @param      {Object}    commonService                  common Service
   * @param      {Object}    MIS_CONSTANTS                  MIS Constants
   *
   * @return     {Object}    Instance of the Service
   */
		function CommentService($q, commonService, CONSTANTS_LIST) {

			var sessionTenant = sessionStorage.getItem('tenant');
			var sessionBroker = sessionStorage.getItem('broker');

			return {
				// Api Fns
				fetchAllConversations: fetchAllConversations,
				sendRejectComment: sendRejectComment,
				addNewMember: addNewMember,
				searchConversations: searchConversations
			};

			// //////////////////////////////////////////////////////

			function fetchAllConversations(id) {
				var returnPromise = $q.defer();
				var reqType = 'GET';
				var reqUrl = '/api/helpcentre/v1/chat/meta/ninja-user?id=' + id;

				commonService.callHelpCentreApi(reqType, reqUrl, {}).then(function (res) {
					returnPromise.resolve(res.data);
				}).catch(function (err) {
					returnPromise.reject(err.data);
				});

				return returnPromise.promise;
			}

			function sendRejectComment(lead, text, sender) {
				var returnPromise = $q.defer();
				var reqType = 'POST';
				var reqUrl = '/api/helpcentre/v1/chat/upsert';

				var opsDetails = {
					name: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_FULLNAME) || commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_USERNAME),
					email: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL)
				};

				var body = {
					insurer: lead.insurer,
					broker: sessionBroker,
					tenant: lead.tenant,
					vertical: lead.vertical,
					registrationNumber: lead.registrationNumber,
					regno: lead.regNo,
					make: lead.make,
					model: lead.model,
					leadStatus: lead.leadStatus,
					leadStage: lead.leadStage,
					requestId: lead.requestId,
					dp: {
						id: lead.partnerId,
						name: lead.partnerName,
						mobileNumber: lead.partnerMobile,
						emailId: lead.partnerEmail,
						dpId: lead.partnerDpNo
					},
					hasAttachments: false,
					type: 'QUOTES_AND_ISSUANCE',
					frontendKey: 'CHAT',
					chats: [{
						sender: sender,
						text: text,
						timestamp: new Date().getTime(),
						origin: 'NINJA-FE',
						source: 'ADMIN_QIS_REJECT',
						senderDetails: {
							id: opsDetails.email,
							name: opsDetails.name,
							email: opsDetails.email,
							type: 'admin'
						}
					}]
				};

				commonService.callHelpCentreApi(reqType, reqUrl, body).then(function (res) {
					returnPromise.resolve(res.data);
				}).catch(function (err) {
					returnPromise.reject(err.data);
				});

				return returnPromise.promise;
			}

			function addNewMember(lead, currentUser) {
				var returnPromise = $q.defer();
				var reqType = 'POST';
				var reqUrl = '/api/helpcentre/v1/chat/upsert';

				var body = {
					insurer: lead.insurer,
					broker: sessionBroker,
					tenant: lead.tenant,
					vertical: lead.vertical,
					registrationNumber: lead.registrationNumber,
					regno: lead.regNo,
					make: lead.make,
					model: lead.model,
					leadStatus: lead.leadStatus,
					leadStage: lead.leadStage,
					policyIssuanceId: lead.policyIssuanceId,
					policyIssuanceStatus: lead.status,
					status: lead.status,
					requestId: lead.requestId,
					dp: {
						id: lead.partnerId,
						name: lead.partnerName,
						mobileNumber: lead.partnerMobile,
						emailId: lead.partnerEmail,
						dpId: lead.partnerDpNo
					},
					hasAttachments: false,
					type: 'QUOTES_AND_ISSUANCE',
					frontendKey: 'CHAT',
					members: [{
						active: true,
						emailId: currentUser.email,
						type: currentUser.category,
						name: currentUser.fullName,
						id: currentUser.email
					}]
				};

				commonService.callHelpCentreApi(reqType, reqUrl, body).then(function (res) {
					returnPromise.resolve(res.data);
				}).catch(function (err) {
					returnPromise.reject(err.data);
				});

				return returnPromise.promise;
			}

			function searchConversations(reqObj) {
				var returnPromise = $q.defer();
				var reqType = 'GET';
				var reqUrl = '/conversations/search?tenant=' + sessionTenant + '&broker=' + sessionBroker;

				commonService.callChatServer(reqType, reqUrl, reqObj).then(function (res) {
					returnPromise.resolve(res.data);
				}).catch(function (err) {
					returnPromise.reject(err.data);
				});

				return returnPromise.promise;
			}
		}
	}
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('DecentralizationService', DecentralizationService);

  // //////////////////////////////////////////////////////

  /**
   * DecentralizationService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function DecentralizationService() {

    DecentralizationService.$inject = ['$mdDialog', 'commonService'];

    return {
      $get: DecentralizationService
    };

    // //////////////////////////////////////////////////////

    /**
     * DecentralizationService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin
     *
     * @author     beerpratap
     */
    function DecentralizationService($mdDialog, commonService) {
      var transferOpsBranchFields = [{
        name: 'Current Branch',
        code: 'currentBranch',
        placeholder: 'Current Branch',
        type: 'text',
        hide: true,
        disabled: true
      }, {
        name: 'New Branch',
        code: 'newBranch',
        placeholder: 'New Branch',
        type: 'select',
        selectConfig: {
          valueKey: '_id',
          textKey: 'name'
        },
        required: true
      }, {
        name: 'Comments',
        code: 'comments',
        placeholder: 'Enter your comments',
        type: 'textarea',
        className: 'maxHeight',
        textareaConfig: {
          rows: 2
        },
        required: true
      }];

      return {
        //var
        transferOpsBranchFields: transferOpsBranchFields,

        //Functions
        getTransferLog: getTransferLog,
        transferBranch: transferBranch,
        getNewBranchList: getNewBranchList,
        initValues: initValues,
        openTransferOpsDialog: openTransferOpsDialog,
        openTransferLogDialog: openTransferLogDialog
      };

      // //////////////////////////////////////////////////////

      function getTransferLog(referenceId, module) {
        var reqUrl = '/v1/operation-location/transfer-logs';
        var reqObj = {
          referenceId: referenceId,
          module: module
        };
        return commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, false);
      }

      function transferBranch(reqObj) {
        var reqUrl = '/v1/operation-location/transfer';
        return commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, false);
      }

      function getNewBranchList(referenceId, module) {
        var reqUrl = '/v1/eligible-operation-location';
        var reqObj = {
          referenceId: referenceId,
          module: module
        };
        return commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, false);
      }

      function initValues() {
        getNewBranchList().then(function (res) {
          var newBranchConfig = _getConfigObjectFromCode('newBranch');
          newBranchConfig.selectConfig.options = res.data;
        });
      }

      function openTransferOpsDialog(referenceId, module, onTransfer) {
        $mdDialog.show({
          templateUrl: 'dev/decentralized/templates/transfer-ops-branch-dialog.tpl.html',
          clickOutsideToClose: true,
          controller: 'TransferOpsBranchCtrl',
          locals: {
            referenceId: referenceId,
            module: module,
            onTransfer: onTransfer
          }
        });
      }

      function openTransferLogDialog(logs) {
        $mdDialog.show({
          templateUrl: 'dev/decentralized/templates/view-transfer-log-dialog.tpl.html',
          clickOutsideToClose: true,
          controller: 'ViewTransferLogCtrl',
          locals: {
            logs: logs
          }
        });
      }

      function _getConfigObjectFromCode(code) {
        for (var idx = transferOpsBranchFields.length - 1; idx >= 0; idx--) {
          var config = transferOpsBranchFields[idx];
          if (config.code === code) {
            return config;
          }
        }
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.quotesReport', ['chart.js'])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.reports module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.policy
   *
   * @author     aniketadake
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('quotes-report', {
      url: '/quotes-report/export',
      parent: 'parent-state',
      templateUrl: 'dev/quotes-report/templates/quotes-report-home.tpl.html',
      controller: 'quotesHomeCtrl',
      authenticate: true,
      pageType: 'EXPORT' //PENDING
    });
  }

  /*=====  End of Config Blocks  ======*/

  /*==================================
  =            Run Blocks            =
  ==================================*/

  /*=====  End of Run Blocks  ======*/
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.quotesReport').controller('quotesHomeCtrl', controller);

  controller.$inject = ['$scope', 'commonService', '$state', 'PartnerService', '$q', 'MasterFileService', 'CONSTANTS_LIST'];

  function controller($scope, commonService, $state, PartnerService, $q, MasterFileService, CONSTANTS_LIST) {
    var ViewModel = $scope;

    init();
    setFilterLists();

    ViewModel.bulkExport = bulkExport;
    function init() {
      getReportConfigData().then(function (data) {
        ViewModel.exportData = data;
        ViewModel.reportDate = moment(ViewModel.exportData.generatedAt).format('MMMM Do YYYY, h:mm:ss a');
      });
      ViewModel.navbarConfig = {
        vertical: 'mis',
        title: 'Policy'
      };
    }

    function getReportConfigData() {
      var deferred = $q.defer();
      PartnerService.getReportConfig(CONSTANTS_LIST.BROKER.MASHREQ).then(function (data) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });
      return deferred.promise;
    }

    function setFilterLists() {
      //Filter Data for Filter By
      ViewModel.filterByList = [{ text: 'QUOTES REPORT', value: 'quotes_report' }];
    }

    function bulkExport() {
      var pid = void 0;
      if (ViewModel.exportData.json !== undefined && ViewModel.exportData.json[0].label === 'LEADS') {
        pid = ViewModel.exportData.json[0].value;
      }

      MasterFileService.downloadFile(pid);
    }
  }
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {

  angular.module('brokerAdmin.quotesReport').provider('quotesReportService', quotesReportService);

  quotesReportService.$inject = [];

  return quotesReportService;

  function quotesReportService() {

    return {
      $get: quotesReportService
    };

    function quotesReportService() {

      return {
        //Functions
        parseAndGetInteger: parseAndGetInteger
      };

      function parseAndGetInteger(data) {
        if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) !== undefined && data !== null) {
          return parseInt(data);
        } else {
          return '-';
        }
      }
    }
  }
})();
"use strict";

(function () {
   var hostname = location.hostname;
   var feature = hostname.split(".")[1];
   var broker = hostname.split(".")[3];
   if (['turtlemint', 'stage'].indexOf(feature) != -1) {
      feature = 'broker';
   }

   var domainName = hostname.split('.');
   domainName.splice(0, 1);
   domainName = domainName.join('.');
   var iFrameDomain = "//" + domainName;
   var cookieDomain = "." + domainName;
   var mintprodomain;
   if (hostname.indexOf('tataaig') != -1) {
      mintprodomain = location.protocol + '//stage.posp.tataaig.com/api';
      cookieDomain = ".stage.posp.tataaig.com";
      iFrameDomain = "//pro.stage.posp.tataaig.com";
      feature = "tataaig";
   } else {
      mintprodomain = 'https://sales.jioinsurancebroking.com/api';
   }
   var config_data = {
      'GENERAL_CONFIG': {
         'serviceURL': location.protocol + '//' + hostname + '/api',
         'mintproAPI': mintprodomain,
         'cookieDomain': cookieDomain,
         'iframeDomain': iFrameDomain,
         'subdomain1': undefined,
         'STATIC_URL1': location.protocol + '//' + hostname + '/api',
         'STATIC_SERVICE_URL': location.protocol + '//' + hostname + '/api',
         'HEALTH_BACKEND': location.protocol + '//' + hostname + '/api',
         'GOOGLE_DOCS_IFRAME_URL': '//docs.google.com/viewer?url=',
         'DOCUMENT_MASTER_API': location.protocol + '//' + hostname + '/api/platform',
         'CHAT_API': 'https://chat.jioinsurancebroking.com/api',
         'MQTT_URL': 'wss://vernemq.turtle-feature.com:8080/mqtt',
         'EXPORT_BACKEND': 'https://sales.jioinsurancebroking.com/v1/export-policy-details',
         'MASTER_FILE_SERVICE': location.protocol + '//' + hostname + '/api/files',
         'HELP_CENTER_BASE_URL': 'https://help.jioinsurancebroking.com',
         'CMS_URL': 'https://cms.mintpro.in/',
         'ACTIVE_POLICIY_HOST_URL': 'https://turtlemint.com/',
         'MINTACADEMY_BRANCH_KEY': '6227709558521858',
         'RPA_SERVICE_API_KEY': '26f4535b-0c0a-4251-8697-4919ce7b58c7'

      }
   };

   console.log(config_data);

   angular.forEach(config_data, function (key, value) {
      // turtlemintAdmin.constant(value,key);
      angular.module('config', []).constant(value, key);
   });
})();
'use strict';

(function () {

  angular.module('brokerAdmin.Auth', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin.Auth module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.Auth
   *
   * @author     beerpratap
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('/', {
      url: '/',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/home.html',
      controller: 'HomeCtrl',
      controllerAs: 'ViewModel',
      resolve: {
        brokerConfig: ['UtilService', function (UtilService) {
          return UtilService.getBrokerConfig();
        }]
      },
      authenticate: true
    }).state('login', {
      url: '/login',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/auth.html',
      controller: 'AuthCtrl',
      controllerAs: 'ViewModel',
      authenticate: false
    }).state('oauth', {
      url: '/loginOAuth',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/loginOAuth.html',
      controller: 'LoginCtrl',
      controllerAs: 'ViewModel',
      authenticate: false
    }).state('contests', {
      url: '/contests',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/contests.html',
      controller: 'ContestsCtrl',
      controllerAs: 'ViewModel',
      authenticate: false
    }).state('appAuthenticator', {
      url: '/appAuthenticator/:token',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/app-authenticator.html',
      controller: 'AppAuthCtrl',
      controllerAs: 'ViewModel',
      authenticate: false
    }).state('privacy-policy', {
      url: '/privacy-policy',
      parent: 'parent-state',
      templateUrl: 'dev/auth/templates/privacy-policy.tpl.html',
      controller: 'PrivacyPolicyCtrl',
      controllerAs: 'ViewModel',
      authenticate: false
    });
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('tmFileUploadController', fileUploadController).directive('tmFileUpload', tmFileUploadDirective);

  function tmFileUploadDirective() {
    var directive = {
      restrict: 'EA',
      // template: '<input ng-hide="true" class="fileInputElement" type="file" nv-file-select uploader="FileUploader" />',
      template: '\n        <div ng-show="FileUploader.isHTML5" class="hide">\n          <div nv-file-drop="" uploader="FileUploader" class="text-center mg-top-150 cr-pt" >\n              <div nv-file-over="" uploader="FileUploader" over-class="another-file-over-class" class="well my-drop-zone" ng-click="triggerFileInput()" >\n                <div class="pdfUploaderWrap text-center" >\n                  <img src="/images/mis/pdf-uploader.svg" alt="PDF Uploader" />\n                  <div class="mg-top-20" >\n                    <p>Drop files here</p>\n                    <p>or click to browse</p>\n                  </div>\n                </div>\n              </div>\n          </div>\n          <input ng-if="multiple" class="{{className}}" type="file" nv-file-select uploader="FileUploader" multiple/>\n          <input ng-if="!multiple" class="{{className}}" type="file" nv-file-select uploader="FileUploader"/>\n        </div>\n      ',
      controller: fileUploadController,
      scope: {
        identifiers: '=', //object
        onSuccess: '=',
        onError: '=',
        onProgress: '=',
        source: '=',
        allowedExtentions: '=',
        tag: '=',
        isTaggable: '=',
        multiple: '=',
        tagConfig: '=?'
      }
    };
    return directive;
  }

  fileUploadController.$inject = ['$scope', '$mdDialog', 'MIS_CONSTANTS', 'FileUploader', '$window', '$q', 'MasterFileService', 'CONSTANTS_LIST'];

  /**
   * tmFileUpload
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           $mdDialog        $mdDialog
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   *
   */
  function fileUploadController($scope, $mdDialog, MIS_CONSTANTS, FileUploader, $window, $q, MasterFileService, CONSTANTS_LIST) {

    var ViewModel = $scope;

    var broker = sessionStorage.getItem('broker');
    var fileBucket = sessionStorage.getItem('fileBucket');
    var FILE = CONSTANTS_LIST.FILE;

    ViewModel.cancelDialog = cancelDialog;
    ViewModel.tags = MIS_CONSTANTS.filetags;

    var uploader = ViewModel.FileUploader = new FileUploader({
      url: MasterFileService.getUploadUrl()
      // url: '/api/misfileupload'
    });
    //'7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'tar', 'gz', 'z', 'zip', 'csv', 'dat', 'db', 'dbf', 'log', 'mdb', 'sav', 'sql', 'tar', 'xml', 'tif', 'tiff', 'doc', 'docx', 'odt', 'pdf', 'rtf', 'tex', 'txt', 'wks', 'wps', 'wpd', 'xlsx', 'xls', 'jpg', 'jpeg', 'png', 'gif'
    var fileExtentions = ['7z', 'rar', 'tar', 'gz', 'zip', 'csv', 'log', 'tar', 'tif', 'tiff', 'doc', 'docx', 'odt', 'pdf', 'rtf', 'txt', 'xlsx', 'xls', 'jpg', 'jpeg', 'png', 'gif'];

    // var imageExtentions = ['jpg', 'png', 'jpeg', 'bmp'];

    _init();

    $scope.className = 'tm-file-upload-' + $scope.source;
    var inputClass = '.' + $scope.className;
    var eventName = $scope.className + '-click';
    var preUploadValidator = void 0;

    $scope.$on(eventName, function (event, eventData) {
      event.preventDefault();
      if (eventData.$$preUploadValidator && typeof eventData.$$preUploadValidator === 'function') {
        preUploadValidator = eventData.$$preUploadValidator;
      }
      angular.element(inputClass).click();
      _init();
    });

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {
      _initUploader();

      if (ViewModel.allowedExtentions) {
        fileExtentions = ViewModel.allowedExtentions;
      }

      if (ViewModel.tag) {
        selectTagForUpload(ViewModel.tag);
      }

      if (!ViewModel.tagConfig) {
        ViewModel.tagConfig = {
          tagging: true
        };
      }
    }

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _initUploader() {
      // Filter for file type restriction
      var toMatch = '';
      for (var idx = 0; idx < fileExtentions.length; idx++) {
        var ext = fileExtentions[idx];
        if (toMatch !== '') {
          toMatch += '|';
        }

        toMatch = toMatch + ext;
      }

      toMatch = '(' + toMatch + ')';
      var extMatcher = RegExp(toMatch, 'i');

      uploader.filters.push({
        name: 'fileExtensionFilter',
        fn: function fn(item) {
          if (item.name.match(extMatcher)) {
            return true;
          } else {
            return false;
          }
        }
      });

      // Modified from https://stackoverflow.com/a/32490603, cc by-sa 3.0
      // -2 = not jpeg, -1 = no data, 1..8 = orientations
      function getExifOrientation(file, callback) {
        // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
        if (file.slice) {
          file = file.slice(0, 131072);
        } else if (file.webkitSlice) {
          file = file.webkitSlice(0, 131072);
        }

        var reader = new FileReader();
        reader.onload = function (e) {
          var view = new DataView(e.target.result);
          if (view.getUint16(0, false) !== 0xFFD8) {
            callback(-2);
            return;
          }
          var length = view.byteLength;
          var offset = 2;
          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) {
              if (view.getUint32(offset += 2, false) !== 0x45786966) {
                callback(-1);
                return;
              }
              var little = view.getUint16(offset += 6, false) === 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;
              for (var i = 0; i < tags; i++) {
                if (view.getUint16(offset + i * 12, little) === 0x0112) {
                  callback(view.getUint16(offset + i * 12 + 8, little));
                  return;
                }
              }
            } else if ((marker & 0xFF00) !== 0xFF00) {
              break;
            } else {
              offset += view.getUint16(offset, false);
            }
          }
          callback(-1);
        };
        reader.readAsArrayBuffer(file);
      }

      // Derived from https://stackoverflow.com/a/40867559, cc by-sa
      function imgToCanvasWithOrientation(img, rawWidth, rawHeight, orientation) {
        var canvas = document.createElement('canvas');
        if (orientation > 4) {
          canvas.width = rawHeight;
          canvas.height = rawWidth;
        } else {
          canvas.width = rawWidth;
          canvas.height = rawHeight;
        }

        if (orientation > 1) {
          $window.console.log('EXIF orientation = ' + orientation + ', rotating picture');
        }

        var ctx = canvas.getContext('2d');
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, rawWidth, 0);break;
          case 3:
            ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, rawHeight);break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);break;
          case 6:
            ctx.transform(0, 1, -1, 0, rawHeight, 0);break;
          case 7:
            ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, rawWidth);break;
          default:
            $window.console.log('dummy');
        }
        ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
        return canvas;
      }

      function reduceFileSize(file, acceptFileSize, maxWidth, maxHeight, quality, callback) {
        var img = new Image();
        img.onerror = function () {
          URL.revokeObjectURL(this.src);
          callback(file);
        };
        img.onload = function () {
          URL.revokeObjectURL(this.src);
          getExifOrientation(file, function (orientation) {
            var w = img.width;
            var h = img.height;
            var scale = orientation > 4 ? Math.min(maxHeight / w, maxWidth / h, 1) : Math.min(maxWidth / w, maxHeight / h, 1);
            h = Math.round(h * scale);
            w = Math.round(w * scale);

            var canvas = imgToCanvasWithOrientation(img, w, h, orientation);
            canvas.toBlob(function (blob) {
              $window.console.log('Resized image to ' + w + 'x' + h + ', ' + (blob.size >> 10) + 'kB');
              var name = file.name,
                  type = file.type;

              if (type.indexOf('image') > -1) {
                blob.name = name;
                blob.lastModified = new Date();
              }
              callback(blob);
            }, 'image/jpeg', quality);
          });
        };
        img.src = URL.createObjectURL(file);
      }

      uploader.onWhenAddingFileFailed = function (item, filter) {
        if (filter.name === 'fileExtensionFilter') {
          $window.alert('File type not supported.');
        }
      };

      function tagFile(_fileItem) {
        if (ViewModel.tagConfig.tagging) {
          return $mdDialog.show({
            controller: 'tagSelectorCtrl',
            templateUrl: 'dev/directives/templates/tag-selector.dialog.tpl.html',
            clickOutsideToClose: false,

            resolve: {
              selectedTag: function selectedTag() {
                return ViewModel.selectedTag;
              },
              fileItem: function fileItem() {
                return _fileItem;
              }
            }
          });
        } else {
          if (!ViewModel.tagConfig.tag) {
            ViewModel.tagConfig.tag = {
              name: 'Other',
              code: 'OTHER'
            };
          }

          return $q.resolve(ViewModel.tagConfig.tag);
        }
      }

      uploader.onAfterAddingFile = function (fileItem) {
        tagFile(fileItem).then(function (res) {
          var selectedTag = res;
          var formDataObj = {
            bucket: fileBucket,
            path: FILE.PATH,
            cloudSource: FILE.CLOUD_SOURCE,
            broker: broker,
            public: false,
            clientUid: ViewModel.identifiers.clientUid,
            tag: selectedTag.code,
            meta: JSON.stringify(_extends({
              source: ViewModel.source
            }, ViewModel.identifiers))
          };
          fileItem.formData.push(formDataObj);
          if (preUploadValidator && typeof preUploadValidator === 'function') {
            var validUpload = preUploadValidator({ file: fileItem.file, formDataObj: formDataObj });
            if (!validUpload) {
              return;
            }
          }
          if (fileItem._file.size > 1000000) {
            reduceFileSize(fileItem._file, 500 * 1024, 1200, Infinity, 0.75, function (blob) {
              $window.console.log('Uploader.file.added', fileItem, blob);
              fileItem._file = blob;
              fileItem.upload();
            });
          } else {
            fileItem.upload();
          }
        });
      };

      uploader.onSuccessItem = function (fileItem, response, status) {
        uploader.clearQueue();

        $($scope.inputClass).val(null);
        if (status === 200) {
          ViewModel.onSuccess(fileItem, response, status);
        } else {
          ViewModel.onError(fileItem, response, status);
        }
      };

      uploader.onErrorItem = function (fileItem, response, status) {
        // if(!ViewModel.multiple) {
        uploader.clearQueue();
        // }

        $($scope.inputClass).val(null);
        ViewModel.onError(fileItem, response, status);
      };

      uploader.onProgressItem = function (item, progress) {
        ViewModel.onProgress(item, progress);
      };
    }

    /**
     * Function to select tag for upload
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           tag         Tag object
     *
     * @author snehilmodani
     *
     */
    function selectTagForUpload(tag) {
      ViewModel.selectedTag = tag;
    }

    /**
    * Function for Close the Model
    *
    * @public
    *
    * @memberof  module:turtlemintAdmin.mis.tmFileUpload#
    *
    * @author snehilmodani
    *
    */
    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('DocumentListUploaderController', DocumentListUploaderController).directive('tmDocumentListUploader', tmDocumentListUploader);

  function tmDocumentListUploader() {
    var directive = {
      restrict: 'E',
      replace: true,
      templateUrl: '/dev/directives/templates/document-list-upload.tpl.html',
      controller: DocumentListUploaderController,
      scope: {
        identifiers: '=',
        documents: '=',
        options: '=',
        source: '=',
        tagConfig: '=',
        eventTrackingData: '='
      }
    };
    return directive;
  }

  DocumentListUploaderController.$inject = ['$rootScope', '$scope', '$window', '$mdDialog', '$q', '$sce', 'MasterFileService', 'UtilService', 'AuthService', 'TrackingService', 'commonService'];

  function DocumentListUploaderController($rootScope, $scope, $window, $mdDialog, $q, $sce, MasterFileService, UtilService, AuthService, TrackingService, commonService) {

    var ViewModel = $scope;

    ViewModel.isApp = commonService.isApp();
    ViewModel.onSuccessUpload = onSuccessUpload;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.uploadFile = uploadFile;
    ViewModel.onErrorUpload = onErrorUpload;
    ViewModel.onProgressUpload = onProgressUpload;
    ViewModel.canBeViewed = canBeViewed;
    ViewModel.viewFile = viewFile;
    ViewModel.getViewLink = getViewLink;
    ViewModel.downloadFile = downloadFile;
    ViewModel.selectTag = selectTag;
    ViewModel.deleteFile = deleteFile;
    ViewModel.closeViewMode = closeViewMode;
    ViewModel.openAccordian = openAccordian;
    ViewModel.parseDocument = parseDocument;
    var eventName = 'tm-file-upload-' + ViewModel.source + '-click';

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {
      $scope.$watch('documents', function (newValue) {
        if (newValue) {
          handleUploadedDocuments(newValue);
        }
      });

      if (!ViewModel.tagConfig) {
        ViewModel.tagConfig = {
          tagging: true
        };
      }
      ViewModel.fileCanvasViewOptions = {
        zoom: {
          step: 0.25,
          value: 1,
          min: 0.40
        },
        rotate: {
          value: 90
        },
        controls: {
          fit: 'width'
        }
      };

      if (ViewModel.options.$$viewMode && ViewModel.options.$$selectedFile) {
        ViewModel.selectedFile = ViewModel.options.$$selectedFile;
      } else {
        ViewModel.options.$$viewMode = false;
      }
    }

    /**
     * Function to Can Be Viewed
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           file         file
     *
     * @author snehilmodani
     *
     */
    function canBeViewed(file) {
      var pdfFileExtentions = ['pdf'];
      var imageFileExtentions = ['jpg', 'png', 'jpeg'];
      file.isImage = UtilService.fileCanBePreviewed(file, imageFileExtentions);
      file.isPDF = UtilService.fileCanBePreviewed(file, pdfFileExtentions);

      return file.isImage || file.isPDF;
    }

    /**
     * Function to open accordian
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           group         Group Object
     *
     * @author snehilmodani
     *
     */
    function openAccordian(group) {
      group.$$show = !group.$$show;
    }

    /**
     * Function to view file
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           file         file
     *
     * @author snehilmodani
     *
     */

    function viewFile(file) {
      if (canBeViewed(file)) {
        ViewModel.options.$$viewMode = true;
        ViewModel.options.$$selectedFile = angular.copy(file);
        ViewModel.selectedFile = ViewModel.options.$$selectedFile;
        if (ViewModel.options.viewFileCallBackFn) {
          ViewModel.options.viewFileCallBackFn();
        }
      } else {
        commonService.showToast('Can\'t view this file');
      }
      TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_VIEW', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData, {
        tag: file.tag,
        fileId: file.processInfo.pid
      }));
    }

    /**
     * Function to close View Mode
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function closeViewMode() {
      ViewModel.options.$$viewMode = false;
      delete ViewModel.selectedFile;
      if (ViewModel.options.closeFileCallBackFn) {
        ViewModel.options.closeFileCallBackFn();
      }
      TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_VIEW_CLOSE', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData));
    }

    /**
     * Function to get view link
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           file         file
     *
     * @author snehilmodani
     *
     */
    function getViewLink(file) {
      var url = MasterFileService.getViewFileUrl(file.processInfo.pid); //+ '#view=FitV';
      if (file.isImage && ViewModel.isApp) {
        url = MasterFileService.getAppImageViewUrl(url);
      }
      $sce.trustAsResourceUrl(url);
      return url;
    }

    /**
     * Function for dowload selected file
     *
     * @public
     *
     * @function
     *
     * @param    {!String}           selectedTag      Selected tag Code
     * @param    {!Object}           fileItem         File item object
     *
     * @author snehilmodani
     *
     */
    function selectTag(_selectedTag, _fileItem) {
      _selectedTag = UtilService.findTag(_selectedTag);
      $mdDialog.show({
        controller: 'tagSelectorCtrl',
        templateUrl: 'dev/directives/templates/tag-selector.dialog.tpl.html',
        clickOutsideToClose: false,

        resolve: {
          selectedTag: function selectedTag() {
            return _selectedTag;
          },
          fileItem: function fileItem() {
            return _fileItem;
          }
        }
      }).then(function (res) {
        if (_selectedTag && res) {
          if (_selectedTag.code !== res.code) {
            return changeExistingTag(res, _fileItem);
          }
        }

        return $q.reject();
      }).then(function () {
        handleUploadedDocuments(ViewModel.documents);
      });
    }

    /**
     * Function for Change Existing Tag
     *
     * @public
     *
     * @function
     *
     * @param    {Object}         tag      Change Selected tag
     *
     * @author snehilmodani
     *
     */
    function changeExistingTag(tag, fileItem) {
      var returnPromise = $q.defer();
      var reqObj = angular.copy(fileItem);
      var fileId = fileItem.processInfo.pid;
      var oldTag = angular.copy(fileItem.tag);
      reqObj.tag = tag.code;
      MasterFileService.updateFile(fileId, reqObj).then(function () {
        fileItem.tag = tag.code;
        fileItem.tagName = tag.name;
        if (ViewModel.options.changeTagCallBackFn) {
          ViewModel.options.changeTagCallBackFn(fileItem, oldTag);
        }
        TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_CHANGE_TAG', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData, {
          oldTag: oldTag,
          newTag: fileItem.tag
        }));
        returnPromise.resolve(fileItem);
      }).catch(function (err) {
        returnPromise.reject(err);
      });

      return returnPromise.promise;
    }

    function deleteFile(file) {
      var returnPromise = $q.defer();
      var reqObj = file.processInfo.pid;

      MasterFileService.deleteFile(reqObj).then(function (res) {
        returnPromise.resolve(res);
        for (var idx = ViewModel.documents.length - 1; idx >= 0; idx--) {
          var doc = ViewModel.documents[idx];
          if (doc.processInfo.pid === file.processInfo.pid) {
            ViewModel.documents.splice(idx, 1);
          }
        }

        handleUploadedDocuments(ViewModel.documents);
        if (ViewModel.options.deleteCallBackFn) {
          ViewModel.options.deleteCallBackFn(file);
        }
        TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_DELETE', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData, {
          tag: file.tag
        }));
      }).catch(function (err) {
        returnPromise.reject(err);
      });

      return returnPromise.promise;
    }

    function downloadFile(file) {
      MasterFileService.downloadFile(file.processInfo.pid);
      TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_DOWNLOAD', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData, {
        tag: file.tag
      }));
    }

    function uploadFile(event, tag) {
      ViewModel.selectedTag = tag;
      if (ViewModel.options.preUploadValidator && typeof ViewModel.options.preUploadValidator === 'function') {
        event.$$preUploadValidator = ViewModel.options.preUploadValidator;
      }
      $rootScope.$broadcast(eventName, event);
    }

    function onSuccessUpload(fileItem, response) {
      if (response && response.processInfo) {
        var newFile = {
          tag: fileItem.formData[0].tag,
          isActive: response.active,
          fileName: fileItem.file.name,
          processInfo: response.processInfo
        };
        if (ViewModel.documents && ViewModel.documents.length > 0) {
          ViewModel.documents.push(newFile);
        } else {
          ViewModel.documents = [newFile];
        }
        handleUploadedDocuments(ViewModel.documents);
        if (ViewModel.options.uploadCallBackFn) {
          ViewModel.options.uploadCallBackFn(newFile);
        }
        TrackingService.pushToGTMDatalayer(ViewModel.eventTrackingData.module + '_FILE_UPLOAD', 'track_event', _extends({}, ViewModel.eventTrackingData.mxpData, {
          tag: newFile.tag
        }));
      }
    }

    function onErrorUpload(fileItem, response, status) {
      $window.console.log(fileItem, response, status);
    }

    function onProgressUpload(item, progress) {
      $window.console.log(item, progress);
    }

    function handleUploadedDocuments(documents) {
      ViewModel.attachedGroupedDocuments = UtilService.groupDocuments(documents);
    }

    function parseDocument(file) {
      if (ViewModel.options.parseCallBackFn) {
        ViewModel.options.parseCallBackFn(file);
      }
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('tmMembersController', membersController).directive('tmMembers', tmFileUploadDirective);

  function tmFileUploadDirective() {
    var directive = {
      restrict: 'EA',
      // template: '<input ng-hide="true" class="fileInputElement" type="file" nv-file-select uploader="FileUploader" />',
      templateUrl: 'dev/directives/templates/members.tpl.html',
      controller: membersController,
      scope: {
        list: '=',
        editable: '=',
        removeMember: '=',
        memberList: '=',
        memberInitConfig: '=',
        title: '=',
        recordType: '=?'
      }
    };
    return directive;
  }

  membersController.$inject = ['$scope', '$mdDialog', 'MIS_CONSTANTS', '$window'];

  /**
   * tmFileUpload
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           $mdDialog        $mdDialog
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   *
   */
  function membersController($scope, $mdDialog, MIS_CONSTANTS, $window) {

    var ViewModel = $scope;
    var genderList = angular.copy(MIS_CONSTANTS.genderList);
    ViewModel.memberMap = {};
    ViewModel.memberTypes = MIS_CONSTANTS.memberTypes;
    ViewModel.addUpdateMember = addUpdateMember;
    var _memberObj = {
      fname: '',
      lname: '',
      age: '',
      dob: '',
      relationship: ''
    };

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {
      $window.console.log('_init tmMembersController');
      ViewModel.memberTitle = ViewModel.title ? ViewModel.title : 'Insured';

      ViewModel.memberConfig = [{
        name: 'First Name',
        code: 'fname',
        type: 'text',
        required: true
      }, {
        name: 'Last Name',
        code: 'lname',
        type: 'text',
        required: true
      },
      // {
      //   name: 'Age',
      //   code: 'age',
      //   type: 'text',
      //   required: true
      // },
      {
        name: 'DOB',
        code: 'dob',
        type: 'date'
      }, {
        name: 'Gender',
        code: 'gender',
        type: 'select',
        selectConfig: {
          options: genderList,
          valueKey: 'code',
          textKey: 'name'
        },
        required: true
      }];
      if (ViewModel.memberList) {
        ViewModel.memberConfig = ViewModel.memberList.generalFields;
      }

      if (ViewModel.memberInitConfig) {
        _memberObj = ViewModel.memberInitConfig;
      }

      if (ViewModel.list) {
        _generateMemberMap(ViewModel.list);
      }
    }

    function _createMemberMap() {
      ViewModel.memberMap = {};
      for (var idx = 0; idx < ViewModel.memberTypes.length; idx++) {
        var memberConfig = ViewModel.memberTypes[idx];
        if (!ViewModel.memberMap[memberConfig.code]) {
          var memberObj = {
            checked: false,
            value: 0,
            members: []
          };

          angular.extend(memberObj, memberConfig);

          ViewModel.memberMap[memberConfig.code] = memberObj;
        }
      }
    }

    function findMemberConfigByRelationship(relationshipCode) {
      for (var idx = ViewModel.memberTypes.length - 1; idx >= 0; idx--) {
        var memberConfig = ViewModel.memberTypes[idx];

        if (memberConfig.multiple && relationshipCode.indexOf(memberConfig.code) !== -1) {
          return memberConfig;
        } else if (relationshipCode === memberConfig.code) {
          return memberConfig;
        }
      }
    }

    function _generateMemberMap(list) {
      _createMemberMap();
      for (var idx = 0; idx < list.length; idx++) {
        var member = list[idx];
        var relationshipObj = findMemberConfigByRelationship(member.relationship);
        var relationshipCode = relationshipObj.code;
        if (ViewModel.memberMap[relationshipCode]) {
          ViewModel.memberMap[relationshipCode].checked = true;
          ViewModel.memberMap[relationshipCode].value += 1;
          ViewModel.memberMap[relationshipCode].members.push(member);
        }
      }

      $window.console.log(ViewModel.memberMap);
    }

    function addUpdateMember() {
      $mdDialog.show({
        controller: 'AddUpdateMemberCtrl',
        templateUrl: 'dev/directives/templates/add-update-member.dialog.tpl.html',
        clickOutsideToClose: false,

        resolve: {
          memberMap: function memberMap() {
            return ViewModel.memberMap;
          },
          list: function list() {
            return ViewModel.list;
          },
          memberObj: function memberObj() {
            return _memberObj;
          },
          title: function title() {
            return ViewModel.memberTitle;
          }
        }
      }).then(function (res) {
        if (res && res.list) {
          ViewModel.list = res.list;
        }

        if (res && res.memberMap) {
          ViewModel.memberMap = res.memberMap;
        }
      });
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('AddUpdateMemberCtrl', addUpdateMemberCtrl);

  addUpdateMemberCtrl.$inject = ['$scope', '$mdDialog', '$window', 'list', 'memberMap', 'memberObj', 'title'];

  /**
   * tmFileUpload
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           $mdDialog        $mdDialog
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   *
   */
  function addUpdateMemberCtrl($scope, $mdDialog, $window, list, memberMap, memberObj, title) {

    var ViewModel = $scope;

    ViewModel.cancelDialog = cancelDialog;
    ViewModel.subtractMember = subtractMember;
    ViewModel.addMember = addMember;
    ViewModel.changeMemberTicked = changeMemberTicked;
    ViewModel.changeMultipleMemberTicked = changeMultipleMemberTicked;
    ViewModel.doneFn = doneFn;

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {
      $window.console.log('_init AddUpdateMemberCtrl');
      ViewModel.list = angular.copy(list);
      ViewModel.memberMap = angular.copy(memberMap);
      ViewModel.memberObj = angular.copy(memberObj);
      ViewModel.title = angular.copy(title);
    }

    function changeMemberTicked(memberConfig) {
      if (memberConfig.checked) {
        addMember(memberConfig);
      } else {
        subtractMember(memberConfig);
      }
    }

    function changeMultipleMemberTicked(memberConfig) {
      if (memberConfig.checked) {
        addMember(memberConfig);
      } else {
        removeAllMembers(memberConfig);
      }
    }

    function subtractMember(memberConfig) {
      memberConfig.value -= 1;
      if (memberConfig.value > 0) {
        memberConfig.checked = true;
      } else {
        memberConfig.checked = false;
      }

      memberConfig.members.pop();
    }

    function removeAllMembers(memberConfig) {
      memberConfig.value = 0;
      memberConfig.members = [];
    }

    function addMember(memberConfig) {
      memberConfig.value += 1;
      if (memberConfig.value > 0) {
        memberConfig.checked = true;
      } else {
        memberConfig.checked = false;
      }

      var memberObj = angular.copy(ViewModel.memberObj);
      memberObj.relationship = memberConfig.multiple ? memberConfig.code + memberConfig.value : memberConfig.code;

      if (memberConfig.gender) {
        memberObj.gender = memberConfig.gender;
      }

      memberConfig.members.push(memberObj);
    }

    function doneFn() {
      ViewModel.list = [];
      for (var key in ViewModel.memberMap) {
        var memberConfig = ViewModel.memberMap[key];
        if (memberConfig.members && memberConfig.members.length > 0) {
          ViewModel.list = ViewModel.list.concat(memberConfig.members);
        }
      }

      var res = {
        list: ViewModel.list,
        memberMap: ViewModel.memberMap
      };

      $mdDialog.hide(res);
    }

    /**
    * Function for Close the Model
    *
    * @public
    *
    * @memberof  module:turtlemintAdmin.mis.tmFileUpload#
    *
    * @author snehilmodani
    *
    */
    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('tmAddonsController', addonsController).directive('tmAddons', addonsDirective);

  function addonsDirective() {
    var directive = {
      restrict: 'EA',
      // template: '<input ng-hide="true" class="fileInputElement" type="file" nv-file-select uploader="FileUploader" />',
      templateUrl: 'dev/directives/templates/addons.tpl.html',
      controller: addonsController,
      scope: {
        map: '=',
        editable: '=',
        removeAddon: '=',
        vertical: '='
      }
    };
    return directive;
  }

  addonsController.$inject = ['$scope', '$mdDialog', 'MIS_CONSTANTS', '$window', 'PolicyIssuanceService'];

  /**
   * tmFileUpload
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           $mdDialog        $mdDialog
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   *
   */
  function addonsController($scope, $mdDialog, MIS_CONSTANTS, $window, PolicyIssuanceService) {

    var ViewModel = $scope;

    ViewModel.addonsMap = ViewModel.map;
    ViewModel.addUpdateAddons = addUpdateAddons;

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {

      $window.console.log('_init tmAddonsController');

      PolicyIssuanceService.getAddonsList(ViewModel.vertical).then(function (res) {
        ViewModel.addonsList = res;
        findSelectedAddons(ViewModel.addonsMap);
      });
    }

    function findSelectedAddons(addonsMap) {
      ViewModel.addonsConfig = [];

      for (var key in addonsMap) {
        // var addon = ViewModel.addonsMap[key];
        ViewModel.addonsConfig.push({
          name: findAddonName(key),
          code: key,
          type: 'number',
          required: true
        });
      }
    }

    function findAddonName(addonKey) {
      for (var idx = ViewModel.addonsList.length - 1; idx >= 0; idx--) {
        var addon = ViewModel.addonsList[idx];
        if (addonKey === addon.value) {
          return addon.text;
        }
      }

      // Return the key itself in case of no match
      return addonKey;
    }

    function addUpdateAddons() {
      $mdDialog.show({
        controller: 'AddUpdateAddonsCtrl',
        templateUrl: 'dev/directives/templates/add-update-addon.dialog.tpl.html',
        clickOutsideToClose: false,

        resolve: {
          addonMap: function addonMap() {
            return ViewModel.addonsMap;
          },
          list: function list() {
            return ViewModel.addonsList;
          }
        }
      }).then(function (res) {
        if (res && res.addonMap) {
          ViewModel.addonsMap = res.addonMap;
          findSelectedAddons(ViewModel.addonsMap);
        }
      });
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('AddUpdateAddonsCtrl', addUpdateAddonCtrl);

  addUpdateAddonCtrl.$inject = ['$scope', '$mdDialog', '$window', 'list', 'addonMap'];

  /**
   * tmFileUpload
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           $mdDialog        $mdDialog
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   *
   */
  function addUpdateAddonCtrl($scope, $mdDialog, $window, list, addonMap) {

    var ViewModel = $scope;

    ViewModel.cancelDialog = cancelDialog;
    ViewModel.changeAddonChecked = changeAddonChecked;
    ViewModel.doneFn = doneFn;

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _init() {
      $window.console.log('_init AddUpdateAddonCtrl');
      ViewModel.list = angular.copy(list);
      ViewModel.addonMap = angular.copy(addonMap);

      for (var idx = ViewModel.list.length - 1; idx >= 0; idx--) {
        var addon = ViewModel.list[idx];
        if (ViewModel.addonMap[addon.value] !== undefined && ViewModel.addonMap[addon.value] !== null) {
          addon.checked = true;
        }
      }
    }

    function changeAddonChecked(addon) {
      if (addon.checked) {
        addAddons(addon);
      } else {
        subtractAddon(addon);
      }
    }

    function subtractAddon(addon) {
      if (ViewModel.addonMap[addon.value] !== undefined && ViewModel.addonMap[addon.value] !== null) {
        delete ViewModel.addonMap[addon.value];
      }
    }

    function addAddons(addon) {
      if (!ViewModel.addonMap[addon.value]) {
        ViewModel.addonMap[addon.value] = 0;
      }
    }

    function doneFn() {
      var res = {
        addonMap: ViewModel.addonMap
      };

      $mdDialog.hide(res);
    }

    /**
    * Function for Close the Model
    *
    * @public
    *
    * @memberof  module:turtlemintAdmin.mis.tmFileUpload#
    *
    * @author snehilmodani
    *
    */
    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('exportCtrl', exportCtrl).directive('exportDownload', exportDownload);

  function exportDownload() {
    var directive = {
      restrict: 'EA',
      replace: true,
      templateUrl: '/dev/directives/templates/export.tpl.html',
      controller: exportCtrl,
      scope: {
        config: '=',
        tenant: '='
      }
    };
    return directive;
  }

  // exportCtrl
  exportCtrl.$inject = ['$scope', 'MasterFileService', 'PartnerService', 'AuthService'];

  function exportCtrl($scope, MasterFileService, PartnerService, AuthService) {

    $scope.exportPartnerData = exportPartnerData;
    $scope.partnerExportList = {};

    /**
     * Get Report config data for Partner Dump service exports
     */
    function getDumps() {
      $scope.exportData = 'select'; //reset on change tenant
      var roles = AuthService.getRoles();
      PartnerService.getReportConfig($scope.tenant).then(function (data) {
        data.json = data.json.filter(function (dump) {
          return roles.includes(dump.role);
        });
        $scope.partnerExportList = data;
      });
    }

    getDumps(); //Call ReportService for 1st time

    /**
     * Change when tenant changes fetch from report config
     */
    $scope.$watch('tenant', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        getDumps();
      }
    });

    /**
     * returns url to be attached to export button
     */
    function exportPartnerData() {
      if ($scope.exportData === 'select') {
        return null;
      }
      var item = $scope.partnerExportList.json.find(function (dump) {
        return dump.value === $scope.exportData;
      });
      if (item) {
        MasterFileService.downloadFile($scope.exportData);
      }
      return null;
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').directive('adminNavbar', adminNavbar);

  function adminNavbar() {
    var directive = {
      restrict: 'E',
      templateUrl: 'custom/html/partials/admin-navbar.html',
      replace: true,
      controller: adminNavbarCtrl,
      scope: {
        config: '='
      }
    };
    return directive;
  }

  adminNavbarCtrl.$inject = ['$rootScope', '$scope', 'AuthService', '$window', '$timeout', '$mdSidenav', '$state', 'commonService', 'TrackingService'];
  function adminNavbarCtrl($rootScope, $scope, AuthService, $window, $timeout, $mdSidenav, $state, commonService, TrackingService) {
    $scope.isApp = commonService.isApp();
    $scope.isLoggedIn = AuthService.isLoggedIn();
    $scope.showRequestAccess = AuthService.showRequestAccess();
    $scope.hideImage = ['reliancejio'].includes(sessionStorage.getItem('broker'));
    $timeout(function () {
      $scope.broker = sessionStorage.getItem('broker');
    }, 200);

    if ($scope.config.title) {
      $scope.title = $scope.config.title;
    }

    if ($scope.config.subtitle) {
      $scope.subtitle = $scope.config.subtitle;
    }

    if ($scope.config.search && $scope.config.search.placeholder) {
      $scope.searchPlaceholder = $scope.config.search.placeholder;
    }

    if ($scope.isApp) {
      $scope.hidenoNotification = true;
    } else {
      if ($scope.config.noNotification) {
        $scope.hidenoNotification = true;
      } else {
        $scope.hidenoNotification = false;
      }
    }

    $scope.hasPermission = AuthService.hasPermission;
    $scope.hasViewPermission = AuthService.hasViewPermission;

    $scope.renewals = {};

    // $scope.onSearch = $scope.config.search.searchFn;
    $scope.onSearch = function (searchKey) {
      $scope.config.search.searchFn(searchKey).then(function (res) {
        $window.console.log(res);
      }).catch(function (err) {
        $window.console.log(err);
        searchKey = '';
      });
    };
    $scope.closeSearch = function () {
      $scope.config.search.searchKey = undefined;
      $scope.onSearch($scope.config.search.searchKey);
    };

    $scope.onSearchKeyRemove = function (searchKey) {
      if (searchKey.length === 0) {
        $scope.config.search.searchKey = undefined;
        $scope.onSearch($scope.config.search.searchKey);
      }
    };

    $scope.logout = function () {
      AuthService.logoutUser();
    };

    //Side Nav Code
    $scope.toggleLeft = buildToggler('left');
    $scope.toggleRight = buildToggler('right');
    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }

    $scope.moduleConfig = AuthService.getModuleConfig();

    //CHAT VIEW TOGGLE
    $scope.message = {
      new: false,
      commentCount: 0
    };
    $scope.toggleNotification = buildToggler('notificationNav');
    function buildToggler(componentId) {
      return function () {
        $rootScope.$broadcast('CONVO_READ', {});
        $mdSidenav(componentId).toggle();
      };
    }

    $scope.closeNotification = closeNotification;
    function closeNotification() {
      $mdSidenav('notificationNav').close().then(function () {
        // FUNCTION TO RUN AFTER CLOSE OF SIDE NAV
        $window.console.log('closed noti');
      });
    }

    $scope.onSelectConversation = onSelectConversation;
    function onSelectConversation(conversation) {
      if ($scope.hasViewPermission('conversations')) {
        $state.go('conversations', {
          id: conversation.reference,
          metaInfo: conversation.metaInfo
        });
      }
    }

    $scope.goToMintAcademy = goToMintAcademy;
    function goToMintAcademy() {
      AuthService.goToMintAcademy();
      TrackingService.pushToGTMDatalayer('NAV_NINJA_ACADEMY_MODULE_CLICK', 'track_event', {});
    }

    $scope.$on('NOTIFICATION_UPDATE', function (event, data) {
      $scope.message.commentCount = data.commentCount;
      $scope.message.new = $scope.message.commentCount > 0;
    });
    //CHAT VIEW TOGGLE END
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');
  // only number
  brokerAdmin.directive('numericOnly', mobilenumberonly);
  function mobilenumberonly() {
    var directive = {
      require: 'ngModel',
      link: numberFunction
    };
    return directive;
    function numberFunction(scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = inputValue ? inputValue.replace(/[^\d.-]/g, '') : null;

        if (transformedInput !== inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }
        //clear beginning 0
        if (transformedInput === 0) {
          modelCtrl.$setViewValue(null);
          modelCtrl.$render();
        }
        return transformedInput;
      });
    }
  }
})();
'use strict';

/**
 * Created by user on 04/01/18.
 */
(function () {
    var brokerAdmin = angular.module('brokerAdmin');
    brokerAdmin.directive('alphaNumeric', alphaNumericOnly);
    function alphaNumericOnly() {
        var directive = {
            require: 'ngModel',
            link: alphaNumericFun
        };
        return directive;
        function alphaNumericFun(scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                var transformedInput = inputValue ? inputValue.replace(/[^a-z0-9]/gi, '') : null;

                if (transformedInput !== inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }
                //clear beginning 0
                if (transformedInput === 0) {
                    modelCtrl.$setViewValue(null);
                    modelCtrl.$render();
                }
                return transformedInput;
            });
        }
    }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.ProposalTxn').provider('ProposalTxnService', ProposalTxnService);

  // //////////////////////////////////////////////////////

  /**
   * ProposalTxnService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function ProposalTxnService() {

    ProposalTxnService.$inject = ['$q', 'commonService', 'CONSTANTS', 'UtilService'];

    return {
      $get: ProposalTxnService
    };

    // //////////////////////////////////////////////////////

    /**
     * ProposalTxnService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin.Payouts#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     *
     * @return     {Object}    Instance of the Service
     */
    function ProposalTxnService($q, commonService, CONSTANTS, UtilService) {

      return {
        //Functions
        getProposalInfo: getProposalInfo
      };

      // //////////////////////////////////////////////////////

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.ProposalTxn#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getProposalInfo(jsonData) {
        var returnPromise = $q.defer();
        var reqUrl = CONSTANTS.proposalTxnUrl + jsonData._id + '/' + jsonData.vertical;

        commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, false).then(function (response) {
          var successObj = {
            statusCode: response.status,
            data: response.data.data
          };
          returnPromise.resolve(successObj);
        }).catch(function (err) {
          var errorObj = UtilService.getErrorResponse(err);
          returnPromise.reject(errorObj);
        });
        return returnPromise.promise;
      }
    }
  }
})();
'use strict';

(function () {
  var constants = {};

  constants.monthLookup = {
    '0': 'Jan',
    '1': 'Feb',
    '2': 'Mar',
    '3': 'Apr',
    '4': 'May',
    '5': 'Jun',
    '6': 'Jul',
    '7': 'Aug',
    '8': 'Sep',
    '9': 'Oct',
    '10': 'Nov',
    '11': 'Dec'
  };

  constants.payoutexporttype = [
  //  {
  //    value: 'RECON_EXPORT',
  //    label: 'Recon Export'
  //  }, {
  //    value: 'PAYMENT_EXPORT',
  //    label: 'Payment Export'
  //  }, {
  //    value: 'PAYMENT_CORRECTION_EXPORT',
  //    label: 'Payment Correction Export'
  //  }, {
  //    'value': 'REFUND_EXPORT',
  //    'label': 'Payment Refund Export'
  //  },
  {
    value: 'PAYOUT_LEDGER_EXPORT',
    label: 'Payout Ledger Export'
  }];

  constants.insurersPayoutsExport = [
  // {'value': '*', 'label': 'All'},
  { 'value': 'ADITYABIRLAHEALTH', 'label': 'Aditya Birla General Insurance' }, { 'value': 'AEGONLI', 'label': 'Aegon' }, { 'value': 'APOLLO', 'label': 'Apollo Munich' }, { 'value': 'BAJAJ', 'label': 'Bajaj Allianz' }, { 'value': 'BHARTIAXA', 'label': 'Bharti Axa' }, { 'value': 'CIGNA', 'label': 'Cigna' }, { 'value': 'EDELWISSTOKIOLI', 'label': 'Edelweiss' }, { 'value': 'FGGI', 'label': 'Future Generali' }, { 'value': 'FGLI', 'label': 'Future LI' }, { 'value': 'HDFC', 'label': 'HDFC Ergo' }, { 'value': 'HDFCLI', 'label': 'HDFC Life' }, { 'value': 'HDFCGI', 'label': 'HDFC General Insurance' }, { 'value': 'IDBIFLI', 'label': 'IDBI Federal' }, { 'value': 'ICICILOMBARD', 'label': 'ICICI Lombard' }, { 'value': 'ICICIPRULI', 'label': 'ICICI Prudential' }, { 'value': 'IFFTO', 'label': 'Iffco-Tokio' }, { 'value': 'KOTAK', 'label': 'Kotak' }, { 'value': 'KOTAKLI', 'label': 'Kotak' }, { 'value': 'LICLI', 'label': 'LIC' }, { 'value': 'LNT', 'label': 'L&T' }, { 'value': 'MGMA', 'label': 'Magma' }, { 'value': 'NTNL', 'label': 'National' }, { 'value': 'NINA', 'label': 'New India' }, { 'value': 'ORNT', 'label': 'Oriental' }, { 'value': 'RELI', 'label': 'Reliance' }, { 'value': 'RELIGARE', 'label': 'Religare' }, { 'value': 'ROYALSUNDARAM', 'label': 'Royal Sundaram' }, { 'value': 'SBIG', 'label': 'SBI' }, { 'value': 'SHRI', 'label': 'Shriram' }, { 'value': 'STAR', 'label': 'Star Health' }, { 'value': 'TATA', 'label': 'Tata Aig' }, { 'value': 'UNTD', 'label': 'United' }, { 'value': 'UNISOMPO', 'label': 'Universal Sompo' }, { 'value': 'VIDEOCON', 'label': 'Liberty Videocon' }];

  constants.insurers = [{ 'value': 'HDFC', 'label': 'HDFC Ergo' }, { 'value': 'HDFCERGOHEALTH', 'label': 'HDFC Ergo Health' }, { 'value': 'BHARTIAXA', 'label': 'Bharti Axa' }, { 'value': 'BAJAJ', 'label': 'Bajaj Allianz' }, { 'value': 'RELI', 'label': 'Reliance' }, { 'value': 'FGGI', 'label': 'Future Generali' }, { 'value': 'NINA', 'label': 'New India' }, { 'value': 'LNT', 'label': 'L&T' }, { 'value': 'TATA', 'label': 'Tata Aig' }, { 'value': 'ORNT', 'label': 'Oriental' }, { 'value': 'IFTO', 'label': 'Iffco-Tokio' }, { 'value': 'UNISOMPO', 'label': 'Universal Sompo' }, { 'value': 'VIDEOCON', 'label': 'Liberty' }, { 'value': 'CIGNA', 'label': 'Cigna' }, { 'value': 'RELIGARE', 'label': 'Religare' }, { 'value': 'STAR', 'label': 'Star Health' }, { 'value': 'APOLLO', 'label': 'Apollo Munich' }, { 'value': 'SHRI', 'label': 'Shriram' }, { 'value': 'SBIG', 'label': 'SBI' }, { 'value': 'MGMA', 'label': 'Magma' }, { 'value': 'KOTAK', 'label': 'Kotak' }, { 'value': 'UNTD', 'label': 'United' }, { 'value': 'ROYALSUNDARAM', 'label': 'Royal Sundaram' }, { 'value': 'NTNL', 'label': 'National' }, { 'value': 'ICICILOMBARD', 'label': 'ICICI Lombard' }, { 'value': 'ADITYABIRLAHEALTH', 'label': 'Aditya Birla General Insurance' }, { 'value': 'CHOL', 'label': 'Chola MS' }, { 'value': 'EXPORTCREDIT', 'label': 'Export Credit Guarantee' }, { 'value': 'AGRICULTUREINSURANCE', 'label': 'Agricultural Insurance' }, { 'value': 'RHJA', 'label': 'Raheja QBE' }, { 'value': 'MAXBUPAHEALTH', 'label': 'Max Bupa' }, { 'value': 'DHFLGENERAL', 'label': 'DHFL General' }, { 'value': 'ACKOGENERAL', 'label': 'Acko General' }, { 'value': 'DIGIT', 'label': 'Go Digit' }, { 'value': 'EDELWEISS', 'label': 'Edelweiss' }, { 'value': 'ONE_ASSIST', 'label': 'ONE_ASSIST' }, { value: 'NAVIGI', label: 'NAVI General Insurance' }];

  constants.lifeInsurers = [{ 'value': 'BAJAJLI', 'label': 'Bajaj Allianz LI' }, { 'value': 'BIRLASUNLI', 'label': 'Birla Sun LI' }, { 'value': 'HDFCLI', 'label': 'HDFC LI' }, { 'value': 'ICICIPRULI', 'label': 'ICICI Prudential LI' }, { 'value': 'EXIDELIFELI', 'label': 'Exide LI' }, { 'value': 'LICLI', 'label': 'LIC LI' }, { 'value': 'MAXLIFELI', 'label': 'Max LI' }, { 'value': 'PNBMETLIFELI', 'label': 'PNB LI' }, { 'value': 'KOTAKLI', 'label': 'Kotak LI' }, { 'value': 'SBILI', 'label': 'SBI LI' }, { 'value': 'TATAAIALI', 'label': 'TATA LI' }, { 'value': 'RELIANCELI', 'label': 'Reliance LI' }, { 'value': 'AVIVALIFELI', 'label': 'Aviva LI' }, { 'value': 'SAHARALIFELI', 'label': 'Sahara LI' }, { 'value': 'SHRIRAMLIFELI', 'label': 'Shriram LI' }, { 'value': 'BHARTIAXALI', 'label': 'Bharati Axa LI' }, { 'value': 'FGLI', 'label': 'Future LI' }, { 'value': 'IDBIFLI', 'label': 'IDBI LI' }, { 'value': 'CANARAHSBCLI', 'label': 'Canara HSBC Oriental LI' }, { 'value': 'AEGONLI', 'label': 'Aegon LI' }, { 'value': 'DHFLLI', 'label': 'DHFL LI' }, { 'value': 'STARUNIONDAIICHILI', 'label': 'Star Union Dai-ichi LI' }, { 'value': 'INDIAFIRSTLI', 'label': 'IndiaFirst LI' }, { 'value': 'EDELWISSTOKIOLI', 'label': 'Edelweiss LI' }, { 'value': 'STARFIRSTLI', 'label': 'StarFirst LI' }];

  constants.productCategoriesIssuance = [{ 'value': 'TW', 'text': 'TW' }, { 'value': 'FW', 'text': 'Motor' }, { 'value': 'CV', 'text': 'Commercial' }, { 'value': 'HEALTH', 'text': 'Health' }, { 'value': 'TERM', 'text': 'Term' }, { 'value': 'LIFE', 'text': 'Life' }, { 'value': 'BURGLARY', 'text': 'Burglary' }, { 'value': 'CPM', 'text': 'CPM' }, { 'value': 'FIRE', 'text': 'Fire' }, { 'value': 'GMC', 'text': 'GMC' }, { 'value': 'HOUSEHOLDER', 'text': 'Householder\'s' }, { 'value': 'LIABILITY', 'text': 'Liability' }, { 'value': 'MARINE', 'text': 'Marine' }, { 'value': 'MI', 'text': 'Money Insurance' }, { 'value': 'PERSONAL_ACCIDENT', 'text': 'PA' }, { 'value': 'STANDARD_FIRE_AND_SPECIAL_PERILS', 'text': 'Standard Fire & Special Perils' }, { 'value': 'TRAVEL', 'text': 'Travel' }, { 'value': 'WC', 'text': 'WC' }, { 'value': 'GPA', 'text': 'GPA' }];

  constants.lifeCategories = [{ 'value': 'investment', 'text': 'Investment' }, { 'value': 'child', 'text': 'Child' }, { 'value': 'pension', 'text': 'Pension' }, { 'value': 'retirement', 'text': 'Retirement' }, { 'value': 'term', 'text': 'Term-Life' }];

  constants.productCategories = [{ 'value': 'TW', 'text': 'TW' }, { 'value': 'FW', 'text': 'Motor' }, { 'value': 'HEALTH', 'text': 'Health' }, { 'value': 'LIFE', 'text': 'Life' }, { 'value': 'BURGLARY', 'text': 'Burglary' }, { 'value': 'CPM', 'text': 'CPM' }, { 'value': 'FIRE', 'text': 'Fire' }, { 'value': 'GMC', 'text': 'GMC' }, { 'value': 'HOUSEHOLDER', 'text': 'Householder\'s' }, { 'value': 'LIABILITY', 'text': 'Liability' }, { 'value': 'MARINE', 'text': 'Marine' }, { 'value': 'MI', 'text': 'Money Insurance' }, { 'value': 'PERSONAL_ACCIDENT', 'text': 'PA' }, { 'value': 'STANDARD_FIRE_AND_SPECIAL_PERILS', 'text': 'Standard Fire & Special Perils' }, { 'value': 'TRAVEL', 'text': 'Travel' }, { 'value': 'WC', 'text': 'WC' }, { 'value': 'GPA', 'text': 'GPA' }, { 'value': 'VECTOR_BORNE', 'text': 'Vector Borne' }];

  constants.vehicleTypesTw = [{ value: 'tw', text: 'TW', code: 'TWO_WHEELER' }];

  constants.vehicleTypesCar = [{ value: 'car', text: 'Car', code: 'CAR' }, { value: 'gcv', text: 'GCV', code: 'GCV' }, { value: 'pcv', text: 'PCV', code: 'PCV' }, { value: 'miscd', text: 'MISC D', code: 'MISCD' }];

  constants.vehicleTypesAll = [{
    value: 'tw',
    text: 'TW',
    vertical: 'TW',
    productCategory: 'TW',
    code: 'TWO_WHEELER'
  }, {
    value: 'car',
    text: 'Car',
    vertical: 'FW',
    productCategory: 'FW',
    code: 'CAR'
  }, {
    value: 'gcv',
    text: 'GCV',
    vertical: 'CV',
    productCategory: 'FW',
    code: 'GCV'
  }, {
    value: 'pcv',
    text: 'PCV',
    vertical: 'CV',
    productCategory: 'FW',
    code: 'PCV'
  }, {
    value: 'miscd',
    text: 'MISC D',
    vertical: 'CV',
    productCategory: 'FW',
    code: 'MISCD'
  }];

  constants.vehicleSubTypes = [{ value: 'others', text: 'Others', code: 'OTHERS' }, { value: 'auto', text: 'Auto', code: 'AUTO' }, { value: 'taxi', text: 'Taxi', code: 'TAXI' }, { value: 'schoolbus', text: 'School bus', code: 'SCHOOL_BUS' }, { value: 'corporatebus', text: 'Corporate bus', code: 'CORPORATE_BUS' }, { value: 'tractor', text: 'Tractor', code: 'TRACTOR' }, { value: '3w', text: '3W', code: '3W' }, { value: 'erickshaw', text: 'E-Rickshaw', code: 'E_RICKSHAW' }, { value: '4W', text: '4W', code: '4W' }, { value: 'TRAILER', text: 'Trailer', code: 'TRAILER' }];

  constants.carrierTypes = [{
    value: 'PUBLIC',
    text: 'Public'
  }, {
    value: 'PRIVATE',
    text: 'Private'
  }];

  constants.fuelTypes = [{
    code: 'petrol',
    name: 'Petrol'
  }, {
    code: 'diesel',
    name: 'Diesel'
  }, {
    code: 'cng-lpg',
    name: 'CNG/LPG'
  }, {
    code: 'electric',
    name: 'Electric'
  }, {
    code: 'biofuel',
    name: 'Bio Fuel'
  }, {
    code: 'other',
    name: 'Other'
  }];

  var BUSINESS_TYPE_CODES = {
    NEW: 'NEW',
    RENEWAL: 'RENEWAL',
    ROLLOVER: 'ROLLOVER',
    PORTABILITY: 'PORTABILITY',
    CROSS_SELL: 'CROSS-SELL',
    BROKER_CODE_CHANGE: 'BROKER_CODE_CHANGE'
  };

  constants.BUSINESS_TYPE_CODES = BUSINESS_TYPE_CODES;

  constants.businessTypes = [{
    name: 'New',
    code: BUSINESS_TYPE_CODES.NEW
  }, {
    name: 'Portability',
    code: BUSINESS_TYPE_CODES.PORTABILITY
  }, {
    name: 'Rollover',
    code: BUSINESS_TYPE_CODES.ROLLOVER
  }, {
    name: 'Renewal',
    code: BUSINESS_TYPE_CODES.RENEWAL
  }, {
    name: 'Cross-sell',
    code: BUSINESS_TYPE_CODES.CROSS_SELL
  }, {
    name: 'Broker code change',
    code: BUSINESS_TYPE_CODES.BROKER_CODE_CHANGE
  }];

  constants.commissionChannelTypes = [{ value: 'Online', text: 'Online' }, { value: 'Offline', text: 'Offline' }];

  var POLICY_STATUS_CODES = {
    'ISSUED': 'ACTIVE',
    'COVERNOTE_ISSUED': 'COVERNOTE_ISSUED',
    'CANCELLED': 'CANCELLED',
    'EXPIRED': 'EXPIRED',
    'PENDING_INSURER': 'PENDING_INSURER',
    'PENDING_TM': 'PENDING_TM',
    'ENDORSED': 'ENDORSED',
    'REFUND': 'REFUND',
    'JUNK': 'junk',
    'POTENTIAL_DUPLICATE': 'POTENTIAL_DUPLICATE'
  };

  constants.policyStatuses = [{ 'value': POLICY_STATUS_CODES.ISSUED, text: 'Issued' }, { 'value': POLICY_STATUS_CODES.COVERNOTE_ISSUED, text: 'Covernote Issued' }, { 'value': POLICY_STATUS_CODES.CANCELLED, text: 'Cancelled' }, { 'value': POLICY_STATUS_CODES.EXPIRED, text: 'Expired' }, { 'value': POLICY_STATUS_CODES.PENDING_INSURER, text: 'Pending from Insurer' }, { 'value': POLICY_STATUS_CODES.PENDING_TM, text: 'Pending from TM' }, { 'value': POLICY_STATUS_CODES.ENDORSED, text: 'Endorsed' }, { 'value': POLICY_STATUS_CODES.REFUND, text: 'Refund' }, { 'value': POLICY_STATUS_CODES.JUNK, text: 'Junk' }, { 'value': POLICY_STATUS_CODES.POTENTIAL_DUPLICATE, text: 'Potential Duplicate' }];

  constants.POLICY_STATUS = {};

  constants.POLICY_STATUS.JUNK = POLICY_STATUS_CODES.JUNK;
  constants.POLICY_STATUS.COVERNOTE_ISSUED = POLICY_STATUS_CODES.COVERNOTE_ISSUED;
  constants.POLICY_STATUS.CANCELLED = POLICY_STATUS_CODES.CANCELLED;

  constants.channelTypes = [{ value: 'website', text: 'Website' }, { value: 'websitechat', text: 'Website+Chat' }, { value: 'chat', text: 'Chat' }, { value: 'sms', text: 'SMS' }, { value: 'partner', text: 'Partner' }, { value: 'dealer', text: 'Dealer' }, { value: 'referral', text: 'Referral' }, { value: 'subbroker', text: 'Sub-Broker' }, { value: 'leadbuy', text: 'Lead Buy' }, { value: 'inboundcall', text: 'Inbound Call' }, { value: 'coldcall', text: 'Cold Call' }, { value: 'petrolpump', text: 'Petrol Pump' }, { value: 'strategicalliance', text: 'Strategic Alliance' }, { value: 'employee', text: 'Employee' }];

  constants.creationSource = [{ 'value': 'onlineissuance', 'text': 'Online Issuance' }, { 'value': 'smartzone', 'text': 'Smartzone' }, { 'value': 'opsupload', 'text': 'opsupload' }, { 'value': 'emailpoller', 'text': 'Emailpoller' }, { 'value': 'opsmanual', 'text': 'Opsmanual' }, { 'value': 'csvupload', 'text': 'Csvupload' }, { 'value': 'pi-central', 'text': 'Pi-Central' }, { 'value': 'pi-branch', 'text': 'Pi-Branch' }, { 'value': 'rpa', 'text': 'RPA' }];

  constants.filterByDate = [{ 'value': 'sale_closed_date', 'text': 'Sales Close Date', fullAccess: true }, { 'value': 'issuance_date', 'text': 'Policy Issuance Date', fullAccess: false }, { 'value': 'start_date', 'text': 'Risk Start Date', fullAccess: false }, { 'value': 'creation_date', 'text': 'Creation Date', fullAccess: false }];

  constants.productNames = [{ value: 'comprehensive', text: 'Comprehensive' }, { value: 'tp', text: 'Third party' }, { value: 'od', text: 'OD' }];

  constants.cancellationRemarks = [{ value: 'checkbounce', text: 'Check bounce', code: 'CHECK_BOUNCE' }, { value: 'insurerrejected', text: 'Insurer rejected', code: 'INSURER_REJECTED' }, { value: 'canceledasperinsurer', text: 'Cancelled as per insurer statement', code: 'CANCELLED_AS_PER_INSURER' }, { value: 'customerdidnotpay', text: 'Customer did not pay', code: 'CUSTOMER_DID_NOT_PAY' }, { value: 'customerrequested', text: 'Customer requested', code: 'CUSTOMER_REQUESTED' }, { value: 'salesrequested', text: 'Sales requested', code: 'SALES_REQUESTED' }];

  constants.coverTypes = [{ value: 'individual', code: 'INDIVIDUAL', text: 'Individual' }, { value: 'familyfloater', code: 'FAMILY_FLOATER', text: 'Family floater' }];

  constants.healthPlanTypes = [{ 'value': 'basic', code: 'BASIC', text: 'Basic' }, { 'value': 'topup', code: 'TOP_UP', text: 'Top-up' }, { 'value': 'supertopup', code: 'SUPER_TOP_UP', text: 'Super top-up' }, { 'value': 'criticalillness', code: 'CRITICAL_ILLNESS', text: 'Critical illness' }, { 'value': 'cancer', code: 'CANCER', text: 'Cancer' }, { 'value': 'diabetes', code: 'DIABETES', text: 'Diabetes' }, { 'value': 'seniorcitizen', code: 'SENIOR_CITIZEN', text: 'Senior citizen' }, { 'value': 'hypertension', code: 'HYPERTENSION', text: 'Hyper Tension' }, { 'value': 'dengue', code: 'DENGUE', text: 'Dengue' }, { 'value': 'maternity', code: 'MATERNITY', text: 'Maternity' }, { 'value': 'cardiac', code: 'CARDIAC', text: 'Cardiac' }];

  constants.lifePlanTypes = [{ 'value': 'term', code: 'TERM', 'text': 'Term' }, { 'value': 'ulip', code: 'ULIP', 'text': 'ULIP' }, { 'value': 'traditional', code: 'TRADITIONAL', 'text': 'Traditional' }];

  constants.subPlanTypes = [{ 'value': 'pureterm', code: 'pureterm', 'text': 'Pure term', planTypes: ['TERM'] }, { 'value': 'endowment', code: 'endowment', 'text': 'Endowment', planTypes: ['TRADITIONAL'] }, { 'value': 'returnofpremium', code: 'returnofpremium', 'text': 'Return of premium', planTypes: ['TRADITIONAL', 'TERM'] }, { 'value': 'wholelife', code: 'wholelife', 'text': 'WholeLife', planTypes: ['TRADITIONAL', 'TERM', 'ULIP'] }, { 'value': 'annuity', code: 'annuity', 'text': 'Annuity(Pension)', planTypes: ['TRADITIONAL', 'ULIP'] }, { 'value': 'child', code: 'child', 'text': 'Child', planTypes: ['TRADITIONAL', 'ULIP'] }, { 'value': 'moneyback', code: 'moneyback', 'text': 'Money Back', planTypes: ['TRADITIONAL'] }];

  constants.paymentFrequencyTypes = [{ 'value': 'single', 'text': 'Single' }, { 'value': 'monthly', 'text': 'Monthly' }, { 'value': 'quarterly', 'text': 'Quarterly' }, { 'value': 'halfyearly', 'text': 'Half yearly' }, { 'value': 'yearly', 'text': 'Yearly' }];

  constants.paymentTypes = [{ 'value': 'single', 'text': 'Single Pay' }, { 'value': 'regular', 'text': 'Regular Pay' }, { 'value': 'limited', 'text': 'Limited Pay' }];

  constants.benefitPayoutTypes = [{ 'value': 'onetime', 'text': 'One Time' }, { 'value': 'fixedmonthly', 'text': 'Fixed Monthly' }, { 'value': 'monthlyannualincrease', 'text': 'Monthly Annual Increase' }, { 'value': 'onetimeplusincome', 'text': 'One Time + Monthly Income' }, { 'value': 'LUMPSUM', 'text': 'Lumpsum' }, { 'value': 'LUMPSUM_PLUS_LEVEL_INCOME', 'text': 'Lumpsum + Level Income' }, { 'value': 'LUMPSUM_PLUS_INCREASING_INCOME', 'text': 'Lumpsum + Increasing Income' }, { 'value': 'INCREASING_INCOME', 'text': 'Increasing Income' }, { 'value': 'LEVEL_INCOME', 'text': 'Level Income' }];

  constants.subProductTypes = [{ value: 'term', text: 'Term' }, { value: 'ulip', text: 'Ulip' }, { value: 'endowment', text: 'Endowment' }, { value: 'traditional', text: 'Traditional' }, { value: 'combo', text: 'Combo' }, { value: 'wholelife', text: 'WholeLife' }, { value: 'annuity', text: 'Annuity(Pension)' }];

  constants.filetags = [{
    name: 'Policy',
    code: 'POLICY'
  }, {
    name: 'Previous Policy',
    code: 'PREVIOUS_POLICY'
  }, {
    name: 'RC Copy',
    code: 'RC_COPY'
  }, {
    name: 'Invoice',
    code: 'INVOICE'
  }, {
    name: 'Form 29/30',
    code: 'FORM_29_30'
  }, {
    name: 'ID Proof',
    code: 'ID_PROOF'
  }, {
    name: 'ACR',
    code: 'ACR'
  }, {
    name: 'Counter Offer',
    code: 'COUNTER_OFFER'
  }, {
    name: 'Internal Approvals',
    code: 'INTERNAL_APPROVALS'
  }, {
    name: 'Important Confirmation Communications',
    code: 'IMPORTANT_CONFIRMATION_COMMUNICATIONS'
  }, {
    name: 'Photo',
    code: 'PHOTO'
  }, {
    name: 'Cheque',
    code: 'CHEQUE'
  }, {
    name: 'Proposal Form',
    code: 'PROPOSAL_FORM'
  }, {
    name: 'Medical Report',
    code: 'MEDICAL_REPORT'
  }, {
    name: 'Income Proof',
    code: 'INCOME_PROOF'
  }, {
    name: 'Address Proof',
    code: 'ADDRESS_PROOF'
  }, {
    name: 'Qualification Proof',
    code: 'QUALIFICATION_PROOF'
  }, {
    name: 'Endorsement',
    code: 'ENDORSEMENT'
  }, {
    name: 'Age Proof',
    code: 'AGE_PROOF'
  }, {
    name: 'Payment Proof',
    code: 'PAYMENT_PROOF'
  }, {
    name: 'Rejected Document',
    code: 'REJECTED_DOCUMENT'
  }, {
    name: 'Manual Quote',
    code: 'MANUAL_QUOTE_DOCUMENT'
  }, {
    name: 'Benefit Illustration',
    code: 'BENEFIT_ILLUSTRATION'
  }, {
    name: 'PAN Copy',
    code: 'PAN_COPY'
  }, {
    name: 'Passport Photo',
    code: 'PASSPORT_PHOTO'
  }, {
    name: 'Inspection Report',
    code: 'INSPECTION_REPORT'
  }, {
    name: 'Comment Partner File',
    code: 'COMMENT_DOCUMENT'
  }, {
    name: 'Renewal Notice',
    code: 'RENEWAL_NOTICE'
  }, {
    name: 'Cover Note',
    code: 'COVER_NOTE'
  }, {
    name: 'Comment Admin File',
    code: 'COMMENT_DOCUMENT_ADMIN'
  }, {
    name: 'Call Recording',
    code: 'CALLRECORDING'
  }, {
    name: 'Quality Audit',
    code: 'QUALITY_AUDIT'
  }, {
    name: 'Permit',
    code: 'PERMIT'
  }, {
    name: 'Current Policy',
    code: 'CURRENT_POLICY'
  }, {
    name: 'CPA Cover',
    code: 'CPA_COVER'
  }, {
    name: 'GST Declaration',
    code: 'GST_DECLARATION'
  }, {
    name: 'Aadhar',
    code: 'AADHAR'
  }, {
    name: 'PUC',
    code: 'PUC'
  }, {
    name: 'Private Carrier',
    code: 'PRIVATE_CARRIER'
  }, {
    name: 'Fitness Copy',
    code: 'FITNESS_COPY'
  }, {
    name: 'Motor Proposal',
    code: 'MOTOR_PROPOSAL'
  }, {
    name: 'NCB Declaration',
    code: 'NCB_DECLARATION'
  }, {
    name: 'Broker Mandate',
    code: 'BROKER_MANDATE'
  }, {
    name: 'Other',
    code: 'OTHER'
  }];

  constants.tenants = [{
    code: 'turtlemint',
    name: 'Turtlemint'
  }, {
    code: 'weizmann',
    name: 'Weizmann'
  }, {
    code: 'moneyonmobile',
    name: 'Money on mobile'
  }, {
    code: 'bharatseva',
    name: 'Bharat Seva'
  }, {
    code: 'wheelsemi',
    name: 'Wheels EMI'
  }, {
    code: 'uaex',
    name: 'UAE-Xchange'
  }, {
    code: 'octopus',
    name: 'Octopus13'
  }, {
    code: 'synapse',
    name: 'Synapse'
  }, {
    code: 'creditmatedealer',
    name: 'CreditMate (Dealer)'
  }, {
    code: 'creditmatenondealer',
    name: 'CreditMate (Non-dealer)'
  }, {
    code: 'unassigned',
    name: 'Unassigned'
  }];

  constants.turtlemintTenant = [{
    code: 'turtlemint',
    name: 'Turtlemint'
  }];

  constants.allTenants = [{
    code: 'weizmann',
    name: 'Weizmann'
  }, {
    code: 'moneyonmobile',
    name: 'Money on mobile'
  }, {
    code: 'bharatseva',
    name: 'Bharat Seva'
  }, {
    code: 'wheelsemi',
    name: 'Wheels EMI'
  }, {
    code: 'uaex',
    name: 'UAE-Xchange'
  }, {
    code: 'octopus',
    name: 'Octopus13'
  }, {
    code: 'synapse',
    name: 'Synapse'
  }, {
    code: 'creditmatedealer',
    name: 'CreditMate (Dealer)'
  }, {
    code: 'creditmatenondealer',
    name: 'CreditMate (Non-dealer)'
  }, {
    code: 'unassigned',
    name: 'Unassigned'
  }];

  constants.memberTypes = [{
    code: 'SELF',
    name: 'Self'
  }, {
    code: 'SPOUSE',
    name: 'Spouse'
  }, {
    code: 'WIFE',
    name: 'Wife',
    hide: true
  }, {
    code: 'HUSBAND',
    name: 'Husband',
    hide: true
  }, {
    code: 'MOTHER',
    name: 'Mother',
    gender: 'Female'
  }, {
    code: 'FATHER',
    name: 'Father',
    gender: 'Male'
  }, {
    code: 'SON',
    name: 'Son',
    multiple: true,
    gender: 'Male'
  }, {
    code: 'DAUGHTER',
    name: 'Daughter',
    multiple: true,
    gender: 'Female'
  }, {
    code: 'FATHER_IN_LAW',
    name: 'Father in Law',
    gender: 'Male'
  }, {
    code: 'MOTHER_IN_LAW',
    name: 'Mother in Law',
    gender: 'Female'
  }, {
    code: 'OTHER',
    name: 'Others'
  }];

  constants.genderList = [{
    name: 'Male',
    code: 'Male'
  }, {
    name: 'Female',
    code: 'Female'
  }, {
    name: 'Other',
    code: 'Other'
  }];

  constants.paymentModeRadioConf = [{
    text: 'Cheque',
    value: 'CHEQUE'
  }, {
    text: 'Online',
    value: 'ONLINE'
  }, {
    text: 'NEFT',
    value: 'NEFT'
  }];

  constants.ewProductCategory = { 'value': 'DEVICE_PROTECTION', 'text': 'Device Protection' };

  constants.dukandaarProductCategory = { value: 'DUKANDAAR', text: 'Dukandaar' };

  constants.ewPlanTypes = [{
    text: 'EW',
    value: 'EW'
  }, {
    text: 'ADLD',
    value: 'ADLD'
  }, {
    text: 'EW + ADLD',
    value: 'EW_PLUS_ADLD'
  }];

  constants.productPaymentModeList = [{
    text: 'Credit Card',
    value: 'CREDIT_CARD'
  }, {
    text: 'Debit Card',
    value: 'DEBIT_CARD'
  }, {
    text: 'CD Loan',
    value: 'CD_LOAN'
  }];

  constants.inspectionPreferenceList = [{
    code: 'SELF_INSPECTION',
    text: 'Self Inspection'
  }, {
    code: 'MANUAL_INSPECTION',
    text: 'Manual Inspection'
  }];

  constants.turtlemintProductCategories = [{
    value: 'CONTRACTORS_ALL_RISK', text: 'Contractors All Risk'
  }, {
    value: 'ERACTION_ALL_RISK', text: 'Erection All Risk'
  }, {
    value: 'MACHINERY_BREAKDOWN', text: 'Machinery Breakdown'
  }, {
    value: 'ELECTRONIC_EQUIPMENT', text: 'Electronic Equipment'
  }, {
    value: 'BOILER_PRESSURE_PLANT', text: 'Boiler Pressure Plant'
  }, {
    value: 'PACKAGE', text: 'Package'
  }, {
    value: 'SHOPKEEPERS', text: "Shopkeeper's"
  }, {
    value: 'OFFICE_PACKAGE"', text: 'Office Package'
  }, {
    value: 'DIRECTION_AND_OFFICERS_LIABILITY', text: 'Directors & Officers liability'
  }, {
    value: 'PUBLIC_LIABILITY', text: 'Public liability'
  }, {
    value: 'PROFESSIONAL_INDEMNITY', text: 'Professional Indemnity'
  }, {
    value: 'CYBER_SECURITY', text: 'Cyber security'
  }, {
    value: 'DOCTORS_LIABILITY', text: 'Doctors Liability'
  }, {
    value: 'INDUSTRIAL_ALL_RISK', text: 'Industrial All Risk'
  }, {
    value: 'MARINE_OPEN', text: 'Marine Open'
  }, {
    value: 'MARINE_SALES_TURNOVER', text: 'Marine Sales Turnover'
  }, {
    value: 'MISCELLANEOUS', text: 'Miscellaneous'
  }, {
    value: 'PORTAABLE_ELECTRONIC_EQUIPMENT', text: 'Portable Electronic Equipment'
  }, {
    value: 'MARINE_CUM_ERECTION', text: 'Marine-Cum-Erection'
  }, {
    value: 'GTL', text: 'GTL'
  }];

  // constants.turtlemintTenant = [{
  //    code: 'turtlemint',
  //    name: 'Turtlemint'
  //  }];
  constants.turtlemintStrategicAllianceRM = 'virendra ghuge';

  angular.module('brokerAdmin').constant('MIS_CONSTANTS', constants);
  constants.dataTypeValidAll = [{
    value: 'valid',
    label: 'Valid'
  }, {
    value: 'all',
    label: 'All'
  }];
})();
'use strict';

(function () {
  var constantsList = {};

  constantsList.ACTIVE = 'active';
  constantsList.SUCCESS = 'success';
  constantsList.REJECT = 'reject';
  constantsList.PENDING = 'pending';
  constantsList.QUOTE = 'quote';
  constantsList.CHECKOUT = 'checkout';
  constantsList.COMPLETE = 'complete';
  constantsList.REJECTED = 'Rejected';
  constantsList.YES = 'Yes';
  constantsList.NO = 'No';
  constantsList.NEW_QUOTE = 'new_quote';
  constantsList.OPEN_QUOTE = 'open_quote';
  constantsList.REOPENED_QUOTE = 'reopened_quote';
  constantsList.CLOSED_QUOTE = 'closed_quote';
  constantsList.CLOSED = 'closed';
  constantsList.SELECT_BY = 'Select By';
  constantsList.RIS = 'RIS_';
  constantsList.COMPLETED_QIS = 'completed qis';
  constantsList.REJECTED_QIS = 'rejected qis';
  constantsList.PENDING_QIS = 'pending qis';
  constantsList.CLOSED_WON = 'closed_won';
  constantsList.PAYMENT_COMPLETE = 'payment_complete';
  constantsList.RESUBMITTED_QIS = 'resubmitted qis';
  constantsList.REOPENED = 'reopened';
  constantsList.COMPLETED = 'completed';
  constantsList.COMPLETED_STATUS = 'Completed';
  constantsList.COMPLETED_QIS_STATUS = 'Completed QIS';
  // constantsList.UNASSIGNED = 'null' ;
  constantsList.LEAD_STAGE = {};
  constantsList.LEAD_STAGE.PENDING_QUOTE = 'pending_quote';
  constantsList.LEAD_STAGE.QUOTE = 'quote';
  constantsList.PAGE_TYPE = {
    QUOTES: 'QUOTES',
    QUOTE_REQUEST: 'QUOTE_REQUEST'
  };
  // lead status
  constantsList.LEAD_STATUS = {};
  constantsList.LEAD_STATUS.ACTIVE = 'Active';
  constantsList.LEAD_STATUS.REOPENED = 'Reopened';
  constantsList.LEAD_STATUS.CLOSED_WON = 'Closed Won';
  constantsList.LEAD_STATUS.CLOSED_LOST = 'Closed Lost';

  constantsList.COOKIE_DEALER_USER_NAME = 'dealerUserName';
  constantsList.COOKIE_POSP_USER_NAME = 'pospUserName';
  constantsList.COOKIE_TENANT = 'tenant';
  constantsList.COOKIE_QIS_LEAD_ID = 'qisLeadId';
  constantsList.COOKIE_REQUEST_ID = 'requestId';
  constantsList.COOKIE_QIS_CUSTOMER_MOBILE = 'qisCustomerMobile';
  constantsList.COOKIE_QIS_CUSTOMER_EMAIL = 'qisCustomerEmail';
  constantsList.COOKIE_QIS_ADMIN_IFRAME_URL = 'qisAdminIframeUrl';
  constantsList.COOKIE_FILED_CLAIM_LAST_YEAR = 'filedClaimLastYear';

  constantsList.SESSION_LEAD = 'lead';
  constantsList.SESSION_REQUEST_ID = 'requestId';
  constantsList.SESSION_IFRAME_URL = 'iframeUrl';
  constantsList.SESSION_QIS_ADMIN_IFRAME_URL = 'qisAdminIframeUrl';

  constantsList.LOCAL_STORAGE_EMAIL = 'email';
  constantsList.LOCAL_STORAGE_FULLNAME = 'fullName';
  constantsList.LOCAL_STORAGE_USERNAME = 'username';
  constantsList.LOCAL_STORAGE_CATEGORY = 'category';
  constantsList.LOCAL_STORAGE_INSUREROPS = 'insurerOPS';
  constantsList.VERTICAL_CAR = {
    LABEL: 'Car',
    VALUE: 'car',
    VALUE_FOR_GET_PREMIUM_API: 'FW',
    VALUE_FOR_MASTER_API: 'FW',
    VALUE_FOR_IFRAME_URL: 'car',
    IFRAME_URL: '/car-insurance/car-profile'
  };

  constantsList.VERTICAL_CV = {
    LABEL: 'Commercial Vehicle',
    VALUE: 'commercial-vehicle',
    VALUE_FOR_GET_PREMIUM_API: 'CV',
    VALUE_FOR_MASTER_API: 'CV',
    VALUE_FOR_IFRAME_URL: 'commercial-vehicle',
    IFRAME_URL: '/commercial-vehicle-insurance/commercial-vehicle-profile'
  };
  constantsList.VERTICAL_TW = {
    LABEL: 'Two Wheeler',
    VALUE: 'two-wheeler',
    VALUE_FOR_GET_PREMIUM_API: 'TW',
    VALUE_FOR_MASTER_API: 'TW',
    VALUE_FOR_IFRAME_URL: 'two-wheeler',
    IFRAME_URL: '/two-wheeler-insurance/two-wheeler-profile'
  };
  constantsList.VERTICAL_HEALTH = {
    IFRAME_URL: '/insurance/health'
  };
  constantsList.VERTICAL_LIFE = {
    IFRAME_URL: '/life-insurance/profile/term/'
  };

  constantsList.VEHICLE_TYPE_CV = 'CV';
  constantsList.VEHICLE_TYPE_GCV = 'GCV';
  constantsList.VEHICLE_TYPE_PCV = 'PCV';

  constantsList.EXPIRY_LIST = {
    EXPIRING_TODAY: 'Expiring today or tomorrow',
    EXPIRING_IN_FEW_DAYS: 'Expiring in a few days',
    EXPIRED_LESS_THAN_90: 'Expired, less than 90 days ago',
    EXPIRED_MORE_THAN_90: 'Expired, more than 90 days ago'
  };

  constantsList.POLICY = {
    NEW: 'New Policy',
    RENEW: 'Renew Policy'
  };

  constantsList.QUOTE_SENT_MSG = 'Quote Sent.';
  constantsList.QUOTE_SENT_FAILED_MSG = 'Sending Quote Failed.';

  constantsList.QIS_STATUS_MAP = {
    new_quote: 'New',
    open_quote: 'Open',
    closed_quote: 'Closed',
    reopened_quote: 'Reopened',
    inspection_pending: 'Inspection Pending'
  };

  constantsList.BROKER = {
    HDFCBANK: 'hdfcbank',
    TURTLEMINT: 'turtlemint',
    AXISBANK: 'axisbank',
    MASHREQ: 'mashreq',
    IPPB: 'ippb'
  };

  constantsList.ROLES = {
    SALES: 'SALES'
  };

  constantsList.FILE = {
    PATH: 'ninja/',
    CLOUD_SOURCE: 'AWS_S3'
  };

  constantsList.PLATFORM_COOKIE = 'PLATFORM';
  constantsList.PLATFORM = {
    APP: 'APP',
    WEB_APP: 'WEB_APP'
  };
  constantsList.QUOTES_VERTICALS = [{
    code: 'TW',
    name: 'TW'
  }, {
    code: 'FW',
    name: 'FW'
  }, {
    code: 'CV',
    name: 'CV'
  }, {
    code: 'HEALTH',
    name: 'Health'
  }, {
    code: 'LIFE',
    name: 'Life'
  }];

  constantsList.INSURANCE_HEAD = 'InsuranceHead';

  constantsList.DBS_PARTNER_LEVEL_LIST = [{
    level: 'Banca Central Team',
    value: 'BancaCentralTeam'
  }, {
    level: 'Cluster Head',
    value: 'ClusterHead'
  }, {
    level: 'City Head',
    value: 'CityHead'
  }, {
    level: 'Branch Head',
    value: 'BranchHead'
  }, {
    level: 'Team Lead/IS',
    value: 'TeamLead'
  }, {
    level: 'Treasure Relationship Manager',
    value: 'TRM'
  }];

  constantsList.DBS_SP_TYPES_LIST = [{
    level: 'SP GI License',
    value: 'spgi'
  }, {
    level: 'SP LI License',
    value: 'spli'
  }, {
    level: 'SP Composite',
    value: 'spcomposite'
  }];

  constantsList.autoParsingStatuses = [{
    code: 'PENDING',
    name: 'Processing'
  }, {
    code: 'SUCCESS',
    name: 'Success'
  }, {
    code: 'FAILURE',
    name: 'Failure'
  }, {
    code: 'SUCCESS_DUPLICATE',
    name: 'Auto - Junk'
  }];

  constantsList.addOns = {
    CPA: 'Compulsory PA Cover For Owner Driver'
  };

  constantsList.renewalPaymentMode = [{
    name: 'Online',
    code: 'ONLINE'
  }, {
    name: 'Cheque',
    code: 'CHEQUE'
  }, {
    name: 'Cash Paid At Insurer',
    code: 'CASH_PAID_AT_INSURER'
  }];

  constantsList.proposerTitle = [{
    name: 'Mr',
    code: 'mr'
  }, {
    name: 'Ms',
    code: 'ms'
  }, {
    name: 'Mrs',
    code: 'mrs'
  }];

  constantsList.proposerTitleTurtlemintBroker = [{
    name: 'Non Individual',
    code: 'nonIndividual'
  }];

  //NOTIFICATIONS
  constantsList.OWNER_CHANGE = 'OWNER_CHANGE';
  constantsList.CONVERSATION_STATE = 'conversations';

  constantsList.INSIGHTS_URL = 'http://insights.turtlemint.com/';

  constantsList.EXCLUED_COOKIES = ['emailDomain', 'loginType', constantsList.PLATFORM_COOKIE, 'FIREBASE_TOKEN'];

  var constantsList_data = {
    'CONSTANTS_LIST': constantsList
  };
  angular.forEach(constantsList_data, function (key, value) {
    angular.module('constants', []).constant(value, key);
  });
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('commonService', CommonService);

  CommonService.$inject = [];

  return CommonService;

  ////////////////////////////////////////////////////////
  /**
   * CommonService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function CommonService() {

    CommonService.$inject = ['$rootScope', '$http', '$cookies', 'GENERAL_CONFIG', '$location', '$timeout', '$window', '$mdToast', '$q', 'CONSTANTS_LIST'];

    return {
      $get: CommonService
    };
    ////////////////////////////////////////////////////////

    /**
     * CommonService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.CommonService#
     *
     * @author     beerpratap
     *
     */
    function CommonService($rootScope, $http, $cookies, GENERAL_CONFIG, $location, $timeout, $window, $mdToast, $q, CONSTANTS_LIST) {

      return {
        //VARIABLES

        //FUNCTION
        redirectTo404: redirectTo404,
        log404: log404,
        makeHttpCall: makeHttpCall,
        makeThirdPartyApiCall: makeThirdPartyApiCall,
        makeMultiPartDataApiCall: makeMultiPartDataApiCall,
        callChatServer: callChatServer,
        callHelpCentreApi: callHelpCentreApi,
        getUrlTM: getUrlTM,

        setItemSession: setItemSession,
        getItemSession: getItemSession,
        removeItemSession: removeItemSession,
        getItemLocalStorage: getItemLocalStorage,
        getCookie: getCookie,
        setCookie: setCookie,
        getPlainCookie: getPlainCookie,
        setPlainCookie: setPlainCookie,

        getInsurerList: getInsurerList,
        setCityState: setCityState,

        isImageOrPDF: isImageOrPDF,
        validateFile: validateFile,
        checkEmailValidity: checkEmailValidity,
        checkMobileValidity: checkMobileValidity,
        monthDiff: monthDiff,
        addMonths: addMonths,
        convertDateFromDDMMYYYFormat: convertDateFromDDMMYYYFormat,
        showToast: showToast,
        stopEventPropagation: stopEventPropagation,
        getNinjaPlatform: getNinjaPlatform,
        isApp: isApp,
        isMobile: isMobile,
        isIdfcBroker: isIdfcBroker,
        getBaseDomain: getBaseDomain,
        isEmptyValue: isEmptyValue
      };

      function redirectTo404(status) {
        if (status && (status >= 400 || status <= 600)) {
          $location.path('/404');
        } else {
          $rootScope.isShowFlashMsg = true;
          $timeout(function () {
            $rootScope.isShowFlashMsg = false;
          }, 3000);
        }
      }

      function log404(data, status, headers, config, requestType, domain) {
        var apiParams = requestType === 'POST' ? config.data : config.params;
        var jsonData = {
          url: location.href,
          api: config.url,
          apiParams: JSON.stringify(apiParams),
          errorMessage: data && data.errorMessage ? data.errorMessage : '',
          errorCode: data && data.errorCode ? data.errorCode : '',
          httpCode: status
        };

        $http({
          url: domain + '/log404',
          params: jsonData,
          headers: { 'Content-Type': 'application/json' },
          method: 'GET'
        });
      }

      function makeHttpCall(api, jsonData, method, cache, cdnCache, showLoader, postCallParams) {
        var deferred = $q.defer();
        var requestType = method ? method : 'GET';
        var domain = GENERAL_CONFIG.serviceURL;
        var isWithCredentials = true;

        if (cdnCache) {
          domain = GENERAL_CONFIG.STATIC_SERVICE_URL;
          isWithCredentials = false;
        }

        if (api.indexOf('/health') > -1) {
          domain = GENERAL_CONFIG.HEALTH_BACKEND;
        }

        if ((typeof showLoader === 'undefined' ? 'undefined' : _typeof(showLoader)) === undefined || !showLoader) {
          angular.element('#loaderDiv').show();
        }

        if (requestType === 'POST') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            headers: { 'Content-Type': 'application/json' },
            method: requestType,
            withCredentials: isWithCredentials
          }).then(function (data, status, headers, config) {
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
            deferred.resolve(data, status, headers, config);
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            angular.element('#loaderDiv').hide();
            log404(data, status, headers, config, requestType, domain);
            redirectTo404(status);
          });
        } else if (requestType === 'PUT') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            headers: { 'Content-Type': 'application/json' },
            method: requestType,
            withCredentials: isWithCredentials
          }).then(function (data, status, headers, config) {
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
            deferred.resolve(data, status, headers, config);
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            angular.element('#loaderDiv').hide();
            log404(data, status, headers, config, requestType, domain);
            redirectTo404(status);
          });
        } else {
          if (cache) {
            $http({
              url: domain + api,
              cache: true,
              'Cache-Control': 'max-age=3600',
              params: jsonData,
              headers: { 'Content-Type': 'application/json' },
              method: requestType,
              withCredentials: isWithCredentials
            }).then(function (data, status, headers, config) {
              deferred.resolve(data, status, headers, config);
              if (!showLoader) {
                angular.element('#loaderDiv').hide();
              }
            }).catch(function (data, status, headers, config) {
              deferred.reject(data, status, headers, config);
              angular.element('#loaderDiv').hide();
              log404(data, status, headers, config, requestType, domain);
              redirectTo404(status);
            });
          }
          $http({
            url: domain + api,
            params: jsonData,
            headers: { 'Content-Type': 'application/json' },
            method: requestType,
            withCredentials: isWithCredentials
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            angular.element('#loaderDiv').hide();
            log404(data, status, headers, config, requestType, domain);
            redirectTo404(status);
          });
        }
        return deferred.promise;
      }

      function makeThirdPartyApiCall(domain, api, jsonData, methodType, showLoader, postCallParams, timeout) {
        // we need to send some query parameters like tenant and broker even if its a post call
        // so postCallParams will be going as query params
        // the dependency is in http interceptor.
        var deferred = $q.defer();
        if ((typeof showLoader === 'undefined' ? 'undefined' : _typeof(showLoader)) === undefined || !showLoader) {
          angular.element('#loaderDiv').show();
        }
        if (!timeout || timeout && typeof timeout !== 'number') {
          timeout = 100000;
        }
        if (methodType === 'GET') {
          $http({
            url: domain + api,
            params: jsonData,
            headers: { 'Content-Type': 'application/json' },
            method: methodType,
            timeout: timeout
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if ((typeof showLoader === 'undefined' ? 'undefined' : _typeof(showLoader)) === undefined || !showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if ((typeof showLoader === 'undefined' ? 'undefined' : _typeof(showLoader)) === undefined || !showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        } else if (methodType === 'POST') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            headers: { 'Content-Type': 'application/json' },
            method: methodType,
            timeout: timeout
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        } else if (methodType === 'PUT') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            headers: { 'Content-Type': 'application/json' },
            method: methodType,
            timeout: timeout
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        } else if (methodType === 'PATCH') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            headers: { 'Content-Type': 'application/json' },
            method: methodType,
            timeout: timeout
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        } else if (methodType === 'DELETE') {
          $http({
            url: domain + api,
            params: jsonData,
            method: methodType,
            timeout: timeout
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        }

        return deferred.promise;
      }

      function makeMultiPartDataApiCall(domain, api, jsonData, methodType, showLoader, postCallParams) {
        // we need to send some query parameters like tenant and broker even if its a post call
        // so postCallParams will be going as query params
        // the dependency is in http interceptor.
        var deferred = $q.defer();
        if ((typeof showLoader === 'undefined' ? 'undefined' : _typeof(showLoader)) === undefined || !showLoader) {
          angular.element('#loaderDiv').show();
        }
        if (methodType === 'POST') {
          $http({
            url: domain + api,
            data: jsonData,
            params: postCallParams || {},
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined },
            method: methodType
          }).then(function (data, status, headers, config) {
            deferred.resolve(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          }).catch(function (data, status, headers, config) {
            deferred.reject(data, status, headers, config);
            if (!showLoader) {
              angular.element('#loaderDiv').hide();
            }
          });
        }

        return deferred.promise;
      }

      function callChatServer(type, url, obj) {
        var deferred = $q.defer();
        var domain = GENERAL_CONFIG.CHAT_API;
        var data = obj,
            params = {};

        if (type.toLowerCase() === 'post') {
          data = obj;
        }

        if (type.toLowerCase() === 'get') {
          params = obj;
        }

        $http({
          url: domain + url,
          data: data,
          params: params,
          headers: { 'Content-Type': 'application/json' },
          method: type
        }).then(function (data, status, headers, config) {
          deferred.resolve(data, status, headers, config);
        }).catch(function (data, status, headers, config) {
          deferred.reject(data, status, headers, config);
        });
        return deferred.promise;
      }

      function callHelpCentreApi(type, url, obj) {
        var deferred = $q.defer();
        var domain = GENERAL_CONFIG.HELP_CENTER_BASE_URL;
        var data = obj,
            params = {};

        if (type.toLowerCase() === 'post') {
          data = obj;
        }

        if (type.toLowerCase() === 'get') {
          params = obj;
        }

        $http({
          url: domain + url,
          data: data,
          params: params,
          headers: { 'Content-Type': 'application/json' },
          method: type
        }).then(function (data, status, headers, config) {
          deferred.resolve(data, status, headers, config);
        }).catch(function (data, status, headers, config) {
          deferred.reject(data, status, headers, config);
        });
        return deferred.promise;
      }

      function getUrlTM() {
        return getItemSession('common', 'hostUrl') + '/api';
      }

      function setItemSession(vertical, item, value) {
        var lsMap = $window.sessionStorage.getItem(vertical);
        var objMap = lsMap ? JSON.parse(lsMap) : {};
        objMap[item] = value;
        var stringifiedMap = JSON.stringify(objMap);
        $window.sessionStorage.setItem(vertical, stringifiedMap);
      }

      function getItemSession(vertical, item) {
        var lsMap = $window.sessionStorage.getItem(vertical);
        if (!lsMap) {
          return undefined;
        }

        var objMap = JSON.parse(lsMap);
        if (objMap[item] || objMap[item] === 0) {
          return objMap[item];
        } else {
          return undefined;
        }
      }

      function removeItemSession(vertical, item) {
        if (!item) {
          $window.sessionStorage.removeItem(vertical);
          return undefined;
        } else {
          var lsMap = $window.sessionStorage.getItem(vertical);
          if (!lsMap) {
            return undefined;
          }
          var objMap = JSON.parse(lsMap);
          if (objMap.hasOwnProperty(item)) {
            delete objMap.item;
            setItemSession(vertical, item);
          }
          return undefined;
        }
      }

      function getItemLocalStorage(itemKey, valueKey) {
        var lsMap = void 0;
        try {
          lsMap = atob($window.localStorage.getItem(itemKey));
          if (!JSON.parse(lsMap)) {
            return undefined;
          }
        } catch (err) {
          lsMap = $window.localStorage.getItem(itemKey);
          if (!lsMap) {
            return undefined;
          }
        }

        var objMap = JSON.parse(lsMap);
        if (valueKey) {
          if (objMap[valueKey] || objMap[valueKey] === 0) {
            return objMap[valueKey];
          } else {
            return undefined;
          }
        } else {
          return objMap;
        }
      }

      function getCookie(cname) {
        var name = cname + '=';
        var allCookies = document.cookie.split(';');
        for (var idx = 0; idx < allCookies.length; idx++) {
          var cookie = allCookies[idx];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            try {
              return atob(decodeURIComponent(cookie.substring(name.length, cookie.length)));
            } catch (err) {
              return cookie.substring(name.length, cookie.length);
            }
          }
        }
        return '';
      }

      function setCookie(cname, cvalue, domain, exDays) {
        var d = new Date();
        if (!exDays) {
          exDays = 1;
        }
        d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        if (cvalue) {
          document.cookie = cname + '=' + btoa(cvalue) + ';' + expires + ';path=/;Domain=' + domain + ';secure';
        }
      }

      function getPlainCookie(cname) {
        var name = cname + '=';
        var allCookies = document.cookie.split(';');
        for (var idx = 0; idx < allCookies.length; idx++) {
          var cookie = allCookies[idx];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
        }
        return '';
      }

      function setPlainCookie(cname, cvalue, domain, exDays) {
        var d = new Date();
        if (!exDays) {
          exDays = 1;
        }
        d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        if (cvalue) {
          document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;Domain=' + domain + ';secure';
        }
      }

      function getInsurerList() {
        var defer = $q.defer();
        var insurerList = [];
        var sessionInsurerList = JSON.parse(sessionStorage.getItem('insurerList'));
        if (sessionInsurerList) {
          defer.resolve(sessionInsurerList);
        }

        makeHttpCall('/getinsurerslist', {}, 'GET').then(function (res) {
          insurerList = res.data;
          sessionStorage.setItem('insurerList', JSON.stringify(insurerList));
          defer.resolve(insurerList);
        }).catch(function () {
          defer.resolve(insurerList);
        });
        return defer.promise;
      }

      function setCityState(pincode, cityModel, stateModel, model) {
        var reqUrl = '/citystatebypincode';
        var reqObj = {
          pincode: pincode
        };

        makeHttpCall(reqUrl, reqObj, 'GET', false).then(function (res) {
          if (res.data.status && res.data.status === 'ERROR') {
            showToast('No city, state found for this pincode');
            model[cityModel] = '';
            model[stateModel] = '';
          } else {
            model[cityModel] = res.data.city;
            model[stateModel] = res.data.state;
          }
        }).catch(function () {});
      }

      function isImageOrPDF(item) {
        var type = '|' + item.slice(item.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|pdf|'.indexOf(type) !== -1;
      }

      function validateFile(file) {
        var result = { 'isValid': true, 'uploadimageErrMsg': '' };
        var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        var MAX_SIZE_IMAGE_UPLOAD = 5;

        if (!isImageOrPDF(file.type)) {
          result.uploadimageErrMsg = 'Only accepts .jpeg, .jpg, .png, .gif, .pdf files';
          result.isValid = false;
        } else if (sizeInMB > MAX_SIZE_IMAGE_UPLOAD) {
          result.isValid = false;
          result.uploadimageErrMsg = 'Max allowed file size is ' + MAX_SIZE_IMAGE_UPLOAD + 'MB';
        }

        return result;
      }

      function checkEmailValidity(email) {
        var emailPattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailPattern.test(email);
      }

      function checkMobileValidity(mobile) {
        if (mobile.length < 10) {
          return false;
        }

        if (mobile.length > 10) {
          return false;
        }

        if (mobile.length === 10) {
          var n = Number(mobile);
          if (isNaN(n)) {
            return false;
          }
        }
        var numbers = new RegExp(/^[0-9]+$/);
        if (!numbers.test(mobile)) {
          return false;
        }
        return true;
      }

      function monthDiff(date2, date1) {
        var diff = (date2.getTime() - date1.getTime()) / 1000;
        diff /= 60 * 60 * 24 * 7 * 4;
        return Math.abs(Math.round(diff)) > 6 ? true : false;
      }

      function addMonths(date, months) {
        date.setDate(1);
        date.setMonth(date.getMonth() + months);
        return date;
      }

      function isEmptyValue(value) {
        return value === undefined || value === null || value.hasOwnProperty('length') && value.length === 0;
      }

      function convertDateFromDDMMYYYFormat(dateString) {
        var date = void 0;
        dateString = dateString + '';
        if ((typeof dateString === 'undefined' ? 'undefined' : _typeof(dateString)) !== undefined && dateString !== null) {
          var parts = dateString.split('-');
          if (_typeof(parts[0]) !== undefined && _typeof(parts[1]) !== undefined && _typeof(parts[2]) !== undefined && parts[0] !== null && parts[1] !== null && parts[2] !== null) {
            date = new Date(parts[2], parts[1], parts[0]);
            return date;
          } else {
            return 'Invalid date';
          }
        }
        return date;
      }

      function showToast(msg) {
        $mdToast.show($mdToast.simple().textContent(msg).position('bottom right').hideDelay(3000));
      }

      function stopEventPropagation(event) {
        event.stopPropagation();
      }

      function getNinjaPlatform() {
        return $cookies.get(CONSTANTS_LIST.PLATFORM_COOKIE);
      }

      function isApp() {
        var platform = getNinjaPlatform();
        return platform === CONSTANTS_LIST.PLATFORM.APP;
      }

      function isMobile() {
        return window.innerWidth <= 767;
      }

      function isIdfcBroker() {
        return sessionStorage.getItem('broker') === 'idfcfirstbank';
      }

      function getBaseDomain() {
        var hostname = location.hostname;
        var domainName = hostname.split('.');
        domainName.splice(0, 1);
        domainName = domainName.join('.');
        return domainName;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('ninjaCommonDataService', ninjaCommonDataService);

  // //////////////////////////////////////////////////////

  /**
   * ninjaCommonDataService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function ninjaCommonDataService() {

    ninjaCommonDataService.$inject = ['commonService', '$q'];

    return {
      $get: ninjaCommonDataService
    };

    // //////////////////////////////////////////////////////

    /**
     * misService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin
     *
     * @author     beerpratap
     *
     * @class
     * @param      {Object}
     * @return     {Object}    Instance of the Service
     */
    function ninjaCommonDataService(commonService, $q) {
      var ninjaFilterList;
      return {
        //Functions
        getNinjaCommonData: getNinjaCommonData,
        getVehicleTypeListByVertical: getVehicleTypeListByVertical
      };

      //Get Ninja's common filters
      function getNinjaCommonData() {
        var defer = $q.defer();
        var ninjaFilters = [];
        var sessionInsurerList = commonService.getItemSession('ninjaFilters', 'ninjaFilters');
        if (sessionInsurerList && Object.keys(sessionInsurerList).length > 0) {
          ninjaFilterList = sessionInsurerList;
          defer.resolve(sessionInsurerList);
        } else {
          commonService.makeHttpCall('/commons', {}, 'GET').then(function (res) {
            ninjaFilters = res.data;
            ninjaFilterList = ninjaFilters;
            commonService.setItemSession('ninjaFilters', 'ninjaFilters', ninjaFilters);
            defer.resolve(ninjaFilters);
          }).catch(function (err) {
            defer.reject(err);
          });
        }

        return defer.promise;
      }

      function getVehicleTypeListByVertical(list) {
        var vehicleTypeList = ninjaFilterList.vehicleType && ninjaFilterList.vehicleType.filter(function (ele) {
          if (list.includes(ele.verticalCode)) {
            return ele;
          }
        });
        return vehicleTypeList;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  'use-strict';

  angular.module('brokerAdmin').service('fileService', fileService);
  fileService.$inject = ['GENERAL_CONFIG', '$http', '$window', '$timeout', 'commonService'];
  function fileService(GENERAL_CONFIG, $http, $window, $timeout, commonService) {
    var download = function download(url, params, docId) {
      return $http({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params
      }).then(function (response) {
        if (angular.equals(response.status, 200)) {
          var headers = response.headers();
          var contentType = '';
          if (headers['content-type'] !== 'binary/octet-stream') {
            if (headers['content-type'] === 'application/octet-stream') {
              contentType = 'application/pdf';
            } else {
              contentType = headers['content-type'];
            }
            var blob = new Blob([response.data], { type: contentType });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = docId;
            document.body.appendChild(link);
            link.click();
            $timeout(function () {
              document.body.removeChild(link);
            }, 100);
          } else {

            // var headers = res.headers();
            var blob = new Blob([response.data], { type: 'text/csv' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exportData_' + new Date().getTime() + '.csv';
            document.body.appendChild(link);
            link.click();
            $timeout(function () {
              document.body.removeChild(link);
            }, 100);
          }
        }
      }).catch(function (err) {
        $window.console.log(err);
      }).finally(function () {
        angular.element('#loaderDiv').hide();
      });
    };
    var deleteFile = function deleteFile(url, params) {
      return commonService.makeThirdPartyApiCall(url, '', params, 'DELETE');
    };

    return {
      download: download,
      deleteFile: deleteFile
    };
  }
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
    angular.module('brokerAdmin').provider('formConfigService', formConfigService);

    formConfigService.$inject = [];
    return formConfigService;

    /**
    * Form Config Service for Configurable Forms
    * @memberof   app
    *
    * @author     sauminkirve
    * 
    * @public
    * @class
    * 
    * @param      {Object}
    * 
    * @return    {Object}              Instance of the Service (Provider)
    */
    function formConfigService() {
        formConfigService.$inject = ['$q', '$window', 'commonService'];
        return {
            $get: formConfigService
        };

        function formConfigService($q, $window, commonService) {
            var isEmptyValue = commonService.isEmptyValue;

            var finalConfigs = {};
            var currentConfigKeyName = '';
            var defaultRequestObj = {
                broker: '*',
                tenant: '*',
                vertical: '*',
                insurer: '*',
                productCode: '*',
                subProductCode: '*',
                paymentFlow: '*',
                paymentMode: '*',
                formIntent: '*',
                businessModel: '*'
            };

            return {
                getFormConfig: getFormConfig,
                mapDynamicPropsToConfig: mapDynamicPropsToConfig,
                findSlideConfig: findSlideConfig,
                findSectionConfig: findSectionConfig,
                findFieldConfig: findFieldConfig,
                getCurrentConfigKeyName: getCurrentConfigKeyName
            };

            function getCurrentConfigKeyName(reqObj) {
                currentConfigKeyName = reqObj.broker + '-' + reqObj.tenant + '-' + reqObj.vertical + '-' + reqObj.insurer + '-' + reqObj.productCode + '-' + reqObj.subProductCode + '-' + reqObj.paymentFlow + '-' + reqObj.paymentMode + '-' + reqObj.formIntent + '-' + reqObj.businessModel;
                return currentConfigKeyName;
            }

            function getFormConfig() {
                var reqObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultRequestObj;
                var checkForNewConfig = arguments[1];

                var defer = $q.defer();

                var checkoutConfigEndpoint = '/form-config/v0/getconfig';
                currentConfigKeyName = getCurrentConfigKeyName(reqObj);
                if (finalConfigs[currentConfigKeyName] && !checkForNewConfig) {
                    defer.resolve(finalConfigs[currentConfigKeyName]);
                } else {
                    commonService.makeHttpCall(checkoutConfigEndpoint, reqObj, 'POST', false, false, false, false, true).then(function (res) {
                        defer.resolve(res.data);
                    }).catch(function (data) {
                        defer.reject(data);
                    });
                }

                return defer.promise;
            }

            function mapDynamicPropsToConfig(checkoutConfig, dynamicPropsMap) {

                var slideKeysToBeMapped = ['initFn', 'nextFn', 'isCompleteFn', 'validateFn'];
                var sectionKeysToBeMapped = ['initFn'];
                var fieldKeysToBeMapped = ['initFn', 'changeFn', 'refreshFn', 'blurFn'];
                var configKeysToBeMapped = ['onUploadComplete'];
                var nestedKeysToBeMapped = ['multipleTextFieldConfig', 'datepickerConfig', 'checkboxConfig', 'checkboxFieldConfig', 'fileHandlerConfig', 'fields'];
                var propsNotMapped = [];

                // REUSABLE FUNCTION MAPPER FOR ANY CONFIG
                function mapValueToConfig(configObj, keyToBeMapped) {
                    if (configObj[keyToBeMapped] && typeof configObj[keyToBeMapped] === 'string') {
                        var propName = configObj[keyToBeMapped];
                        if (dynamicPropsMap[propName] && typeof dynamicPropsMap[propName] === 'function') {
                            // $window.console.info('binding ', propName, ' function to ', keyToBeMapped, ' key');
                            configObj[keyToBeMapped] = dynamicPropsMap[propName];
                        } else {
                            propsNotMapped.push(propName);
                        }
                    }
                }

                // SLIDE, SECTION, FIELD RECURSIVE MAPPERS
                function mapSlideDynamicProps(checkoutConfig) {
                    checkoutConfig.slides.forEach(function (slideConfig) {
                        // logic to map slide props
                        slideKeysToBeMapped.forEach(function (slideKeyToBeMapped) {
                            mapValueToConfig(slideConfig, slideKeyToBeMapped);
                        });

                        // pass config to section level mapper
                        mapSectionDynamicProps(slideConfig.sections);
                    });
                }

                function mapSectionDynamicProps(sectionsConfig) {
                    sectionsConfig.forEach(function (sectionConfig) {
                        // logic to map section props
                        sectionKeysToBeMapped.forEach(function (sectionKeyToBeMapped) {
                            mapValueToConfig(sectionConfig, sectionKeyToBeMapped);
                        });

                        // pass config to field level mapper
                        mapFieldDynamicProps(sectionConfig.fields);
                    });
                }

                function mapFieldDynamicProps(fieldsConfig) {
                    fieldsConfig.forEach(function (fieldConfig) {
                        if (fieldConfig) {
                            fieldKeysToBeMapped.forEach(function (keyToBeMapped) {
                                mapValueToConfig(fieldConfig, keyToBeMapped);

                                if (fieldConfig.assetQuestion) {
                                    mapValueToConfig(fieldConfig.assetQuestion, keyToBeMapped);
                                }
                            });

                            nestedKeysToBeMapped.forEach(function (nestedKey) {
                                if (fieldConfig[nestedKey] && _typeof(fieldConfig[nestedKey]) === 'object') {

                                    // logic for mapping props at nested key level

                                    // pass config to nested fields mapper
                                    if (fieldConfig[nestedKey].hasOwnProperty('fields')) {
                                        mapFieldDynamicProps(fieldConfig[nestedKey].fields);
                                    }

                                    //for nested medical question fields in health
                                    else if (Array.isArray(fieldConfig[nestedKey])) {
                                            mapFieldDynamicProps(fieldConfig[nestedKey]);
                                        }

                                    // pass config to field config level mapper
                                    mapConfigDynamicProps(fieldConfig[nestedKey]);
                                }
                            });
                        }
                    });
                }

                function mapConfigDynamicProps(config) {
                    configKeysToBeMapped.forEach(function (keyToBeMapped) {
                        mapValueToConfig(config, keyToBeMapped);
                    });
                }

                mapSlideDynamicProps(checkoutConfig);
                finalConfigs[currentConfigKeyName] = checkoutConfig;
                if (!isEmptyValue(propsNotMapped)) {
                    $window.console.warn('props not found in map : ', propsNotMapped);
                }
                return finalConfigs[currentConfigKeyName];
            }

            function findSlideConfig(slideCode) {
                var foundSlideConfig = void 0;

                function getSlideConfigIfFound(configObj) {
                    foundSlideConfig = configObj.slides.find(function (slideConfig) {
                        return !isEmptyValue(slideConfig.code) && slideConfig.code === slideCode;
                    });
                }

                if (finalConfigs[currentConfigKeyName]) {
                    getSlideConfigIfFound(finalConfigs[currentConfigKeyName]);
                }

                return foundSlideConfig;
            }

            function findSectionConfig(sectionCode) {
                var foundSectionConfig = void 0;

                function getSectionConfigIfFound(configObj) {
                    foundSectionConfig = configObj.sections.find(function (sectionConfig) {
                        return !isEmptyValue(sectionConfig.code) && sectionConfig.code === sectionCode;
                    });
                }

                for (var slideIndex in finalConfigs[currentConfigKeyName].slides) {
                    var slideConfig = finalConfigs[currentConfigKeyName].slides[slideIndex];
                    if (slideConfig.hasOwnProperty('sections')) {
                        getSectionConfigIfFound(slideConfig);
                        if (foundSectionConfig) {
                            break;
                        }
                    }
                }

                return foundSectionConfig;
            }

            function findFieldConfig(fieldCode) {
                var foundFieldConfig = void 0;

                function getFieldConfigIfFound(configObj) {
                    foundFieldConfig = configObj.fields.find(function (field) {
                        return !isEmptyValue(field.code) && field.code === fieldCode;
                    });
                }

                /* possible cases handled below - 
                1. passed config > sections > fields
                2. passed config > fields
                3. passed array of configs > fields
                */

                for (var slideIndex in finalConfigs[currentConfigKeyName].slides) {
                    var slideConfig = finalConfigs[currentConfigKeyName].slides[slideIndex];
                    if (slideConfig.hasOwnProperty('sections')) {
                        for (var sectionIndex in slideConfig.sections) {
                            var sectionConfig = slideConfig.sections[sectionIndex];
                            if (sectionConfig.hasOwnProperty('fields')) {
                                getFieldConfigIfFound(sectionConfig);
                                if (foundFieldConfig) {
                                    break;
                                }
                            }
                        }
                    } else if (slideConfig.hasOwnProperty('fields')) {
                        getFieldConfigIfFound(slideConfig);
                        if (foundFieldConfig) {
                            break;
                        }
                    } else if (angular.isArray(slideConfig) && slideConfig.every(function (slide) {
                        return slide.hasOwnProperty('fields');
                    })) {
                        for (var innerSlideIndex in slideConfig) {
                            var innerSlideConfig = slideConfig[innerSlideIndex];
                            getFieldConfigIfFound(innerSlideConfig);
                            if (foundFieldConfig) {
                                break;
                            }
                        }
                    }

                    if (foundFieldConfig) {
                        break;
                    }
                }

                return foundFieldConfig;
            }
        }
    }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('TrackingService', TrackingService);

  TrackingService.$inject = [];

  return TrackingService;

  // //////////////////////////////////////////////////////

  /**
   * TrackingService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function TrackingService() {

    TrackingService.$inject = ['$window', '$rootScope', 'commonService'];

    return {
      $get: TrackingService
    };

    // //////////////////////////////////////////////////////

    /**
     * TrackingService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.TrackingService#
     *
     * @author     sagarmulchandani
     *
     */
    function TrackingService($window, $rootScope, commonService) {
      var user = {};
      var broker = sessionStorage.getItem('broker');
      var tenant = sessionStorage.getItem('tenant');
      var platform = commonService.getNinjaPlatform();
      return {
        //FUNCTION
        identifyUser: identifyUser,
        pushToGTMDatalayer: pushToGTMDatalayer
      };

      function identifyUser(mxpUser) {
        user = mxpUser;
        user.platform = platform;
        localStorage.setItem('mxpUser', btoa(JSON.stringify(mxpUser)));
        pushToGTMDatalayer('identify_user', 'identify_user', user);
      }

      function pushToGTMDatalayer(eventName, tag, jsonObject) {
        jsonObject.ipAddress = $rootScope.ipAddress;
        try {
          if (user && angular.equals(user, {})) {
            user = JSON.parse(atob(localStorage.getItem('mxpUser')));
          }
          jsonObject = _extends({}, jsonObject, user);
          jsonObject.event = tag;
          jsonObject.type = eventName;
          jsonObject.broker = broker;
          jsonObject.tenant = tenant;
          jsonObject.platform = platform;
          $window.dataLayer.push(jsonObject);
        } catch (err) {
          $window.console.error(err.message);
        }
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('MasterFileService', MasterFileService);

  // //////////////////////////////////////////////////////

  /**
   * MasterFileService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function MasterFileService() {

    MasterFileService.$inject = ['$q', '$http', 'GENERAL_CONFIG', '$window', '$timeout', 'commonService', '$mdToast'];

    return {
      $get: MasterFileService
    };

    // //////////////////////////////////////////////////////

    /**
     * MasterFileService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin
     *
     * @author     beerpratap
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    $http                          Angular http service
     *
     * @return     {Object}    Instance of the Service
     */
    function MasterFileService($q, $http, GENERAL_CONFIG, $window, $timeout, commonService, $mdToast) {
      var broker = sessionStorage.getItem('broker');
      var masterFileAPI = GENERAL_CONFIG.MASTER_FILE_SERVICE;

      return {
        //Functions
        getDownloadFileUrl: getDownloadFileUrl,
        getViewFileUrl: getViewFileUrl,
        getAppImageViewUrl: getAppImageViewUrl,
        getDocuments: getDocuments,
        downloadFile: downloadFile,
        getUploadUrl: getUploadUrl,
        updateFile: updateFile,
        deleteFile: deleteFile,
        downloadFileFromUrl: downloadFileFromUrl
      };

      function getViewFileUrl(id) {
        return masterFileAPI + '/v1/view/' + id + '?broker=' + broker;
      }

      function getAppImageViewUrl(viewUrl) {
        return location.origin + '/crm/img-viewer?fileLink=' + viewUrl;
      }

      function getDownloadFileUrl(id) {
        return masterFileAPI + '/v1/download/' + id + '?broker=' + broker;
      }

      function downloadFileFromUrl(reqUrl) {
        var link = document.createElement('a');
        link.download = true;
        link.target = '_blank';
        link.href = reqUrl;
        document.body.appendChild(link);
        link.click();
        $mdToast.show($mdToast.simple().textContent('File download in progress. Do not refresh the page.').position('bottom right').actionKey('z').actionHint('Press the Control-"z" key combination to ').action('Dismiss').dismissHint('Activate the Escape key to dismiss this toast.').highlightAction(true).highlightClass('md-accent').hideDelay(5000));
        $timeout(function () {
          document.body.removeChild(link);
        }, 1000);
      }

      function downloadFile(id) {
        var reqUrl = getDownloadFileUrl(id);
        downloadFileFromUrl(reqUrl);
      }

      function getDocuments(params) {
        var returnPromise = $q.defer();
        var reqUrl = '/v2/filesInfo';

        commonService.makeThirdPartyApiCall(masterFileAPI, reqUrl, params, 'GET', false, false).then(function (res) {
          res = res.data;
          if (res && res.files) {
            var response = res.files.filter(function (ele) {
              return ele.isActive === true;
            });
            returnPromise.resolve(response);
          } else {
            returnPromise.resolve([]);
          }
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getUploadUrl() {
        return masterFileAPI + '/v1/upload';
      }

      function updateFile(fileId, file) {
        var returnPromise = $q.defer();
        var reqUrl = '/v1/' + fileId;
        var reqObj = {
          tag: file.tag
        };

        commonService.makeThirdPartyApiCall(masterFileAPI, reqUrl, reqObj, 'PATCH', false, false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function deleteFile(fileId) {
        var reqUrl = '/v1/' + fileId;
        return commonService.makeThirdPartyApiCall(masterFileAPI, reqUrl, {}, 'DELETE', false, false);
      }
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin').provider('CompliantService', CompliantService);

  CompliantService.$inject = [];

  return CompliantService;

  ////////////////////////////////////////////////////////
  /**
   * CompliantService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function CompliantService() {

    CompliantService.$inject = ['$q', 'commonService'];

    return {
      $get: CompliantService
    };
    ////////////////////////////////////////////////////////

    /**
     * CompliantService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.CompliantService#
     *
     * @author     beerpratap
     *
     */
    function CompliantService($q, commonService) {
      return {
        //FUNCTION
        getBQPList: getBQPList,
        saveBQPDetails: saveBQPDetails
      };

      function getBQPList(reqObj) {
        var reqUrl = '/fetchbqpdetails';
        return commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false);
      }

      function saveBQPDetails(reqData) {
        var url = '/bqp/save';
        return commonService.makeHttpCall(url, reqData, 'POST', false, false, true);
      }

      // TODO: This will be used in the future if needed.
      // function isAfterComplianceDate(creationDate) {
      //   const COMPLIANCE_DATE = '';
      //   return moment(creationDate).isAfter(COMPLIANCE_DATE, 'day');
      // }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  /**
   * 'displayValueFilter' used to get display value from a list of objects
   *
   * @public
   *
   * @filter
   *
   * @author sagarmulchandani
   *
   * 'value' : input value selected
   * 'list' : the list of objects to filter from
   * 'valueKey' : the key of the input
   * 'displayKey' : the key to be displayed
   *
   * returns the value to be displayed
   *
   */
  function displayValue() {
    return function (value, list, valueKey, displayKey) {
      var displayValue = list && list.find(function (x) {
        return x[valueKey] === value;
      });
      if (displayValue) {
        displayValue = displayValue[displayKey];
      }
      return displayValue;
    };
  }

  brokerAdmin.filter('displayValue', displayValue);
})();
'use strict';

//jshint esversion:6
(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  /**
   * 'filterINR' used to get display value from a list of objects
   *
   * @public
   *
   * @filter
   *
   * @author sagarmulchandani
   *
   * 'item' : the amount to transform
   * 'decimalPlaces' : decimal places in the amount
   *
   * returns the value to be displayed
   *
   */
  function filterINR() {
    //Indian Rupee formatter
    return function (item, decimalPlaces) {

      var x = String(item);
      var afterPoint = '';
      if (x.indexOf('.') > 0) {
        afterPoint = x.substring(x.indexOf('.'), x.length);
      }
      x = Math.floor(x);
      if (isNaN(x)) {
        return '';
      }
      x = String(x);
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== '' && otherNumbers !== '-') {
        lastThree = ',' + lastThree;
      }
      if (isFinite(decimalPlaces)) {
        if (decimalPlaces === 0) {
          afterPoint = '';
        } else {
          afterPoint = afterPoint.substring(0, decimalPlaces + 1);
        }
      }
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
      return res;
    };
  }

  brokerAdmin.filter('filterINR', filterINR);
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {

  angular.module('brokerAdmin.mis').provider('misService', misService);

  // //////////////////////////////////////////////////////

  /**
   * misService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function misService() {

    misService.$inject = ['MIS_CONSTANTS', 'CONSTANTS_LIST', 'DocumentMasterService', 'MasterFileService', 'commonService', 'TrackingService', 'AuthService', '$q'];

    return {
      $get: misService
    };

    // //////////////////////////////////////////////////////

    /**
     * misService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin.mis#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    MIS_CONSTANTS                      MIS_CONSTANTS
     * @return     {Object}    Instance of the Service
     */
    function misService(MIS_CONSTANTS, CONSTANTS_LIST, DocumentMasterService, MasterFileService, commonService, TrackingService, AuthService, $q) {
      var broker = sessionStorage.getItem('broker');
      var locationHostUrl = location.protocol + '//' + location.hostname;

      return {
        //Functions
        parseAndGetInteger: parseAndGetInteger,
        makeCsvDateFormat: makeCsvDateFormat,
        getExportUrl: getExportUrl,
        downloadExportFile: downloadExportFile,
        exportMISData: exportMISData
      };

      // //////////////////////////////////////////////////////

      /**
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.misService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  data  data
       * @return     {Number|String}  return integer if input is parsable otherwise return '-'
       */
      function parseAndGetInteger(data) {
        if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) !== undefined && data !== null) {
          return parseInt(data);
        } else {
          return '-';
        }
      }

      /**
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.misService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  date  date
       * @return     {Number|String}  return formatted date if input is date otherwise return '-'
      */
      function makeCsvDateFormat(date) {
        if ((typeof date === 'undefined' ? 'undefined' : _typeof(date)) !== undefined && date !== null && '-' !== date) {
          var formattedDate = new Date(date);
          return formattedDate.getDate() + '-' + MIS_CONSTANTS.monthLookup[formattedDate.getMonth()] + '-' + formattedDate.getFullYear();
        }

        return '-';
      }

      function getExportUrl() {
        var version = 'v1';
        if (broker === CONSTANTS_LIST.BROKER.AXISBANK) {
          version = 'v2';
        }
        return locationHostUrl + '/' + version + '/export-policy-details';
      }

      function exportMISData(mode, data) {
        var returnPromise = $q.defer();
        var mxpData = {
          data: data
        };
        if (AuthService.isMISChecker()) {
          data.role = 'MIS-CHECKER';
        }
        if (AuthService.isMISChecker2()) {
          data.role = 'MIS-CHECKER-2';
        }
        var promise = commonService.makeThirdPartyApiCall(getExportUrl(), '?mode=' + mode, data, 'POST', true, false, 300000);
        commonService.showToast('Export in progress. File will be downloaded.');
        promise.then(function (res) {
          if (res.status === 200) {
            var resData = res.data;
            if (resData) {
              downloadExportFile(resData);
              if (mode === 'SINGLE_EXPORT') {
                var reqUrl = '/saveActivityLog?verb=single_export&target=policy_detail';
                commonService.makeHttpCall(reqUrl, data, 'POST', false, false, true);
              }
            }
            TrackingService.pushToGTMDatalayer('MIS_BULK_EXPORT', 'track_event', mxpData);
          }
          returnPromise.resolve(true);
        }).catch(function (error) {
          if (error.status === 423) {
            commonService.showToast('We are currently processing other download requests. Please try again in 10 mins.');
          } else {
            commonService.showToast('Export Failed. Please try again');
          }
          returnPromise.reject(true);
        });

        return returnPromise.promise;
      }

      function downloadExportFile(resData) {
        if (broker === CONSTANTS_LIST.BROKER.AXISBANK) {
          var s3Id = resData.data.uploadFileInfo.fileId;
          var fileName = resData.data.uploadFileInfo.fileKey;
          DocumentMasterService.downloadFile(s3Id, fileName);
        } else {
          var pid = resData.processInfo.pid;
          MasterFileService.downloadFile(pid);
        }
      }
    }
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  brokerAdmin.service('AuthService', ['commonService', '$state', '$window', '$cookies', 'GENERAL_CONFIG', 'CONSTANTS_LIST', 'ninjaCommonDataService', 'TrackingService', function (commonService, $state, $window, $cookies, GENERAL_CONFIG, CONSTANTS_LIST, ninjaCommonDataService, TrackingService) {
    var broker = sessionStorage.getItem('broker');
    var actionPermissions = void 0;
    var viewPermissions = void 0;

    return {
      authenticateUser: authenticateUser,
      authenticateUserByOTP: authenticateUserByOTP,
      appAuthenticateUser: appAuthenticateUser,
      setUser: setUser,
      getUser: getUser,
      isLoggedIn: isLoggedIn,
      logoutUser: logoutUser,
      getRoles: getRoles,
      setViewPermissions: setViewPermissions,
      getViewPermissions: getViewPermissions,
      hasViewPermission: hasViewPermission,
      setActionPermissions: setActionPermissions,
      getActionPermissions: getActionPermissions,
      hasPermission: hasPermission,
      canShowPartnerDetails: canShowPartnerDetails,
      isSalesUser: isSalesUser,
      showRequestAccess: showRequestAccess,
      authenticateOAuthUser: authenticateOAuthUser,
      isMISChecker: isMISChecker,
      isMISChecker2: isMISChecker2,
      getModuleConfig: getModuleConfig,
      getFirebaseToken: getFirebaseToken,
      isRestrictedSalesAccess: isRestrictedSalesAccess,
      redirectPostLogin: redirectPostLogin,
      goToMintAcademy: goToMintAcademy
    };

    function authenticateUser(GoogleUser) {
      var user = GoogleUser.getBasicProfile();
      var authResponse = GoogleUser.getAuthResponse();

      if (user.getEmail().indexOf(commonService.getCookie('emailDomain')) !== -1) {
        var promise = commonService.makeHttpCall('/ninjagooglesignin', { id_token: authResponse.id_token }, 'POST', false, false, false);
        promise.then(function (res /*, status, headers, config*/) {
          _processAuthenticateUser(res, user, authResponse);
        });
      } else {
        $window.alert('Couldn\'t sign you in!');
      }
    }
    function authenticateOAuthUser(code) {
      if (code && !isLoggedIn()) {
        var promise = commonService.makeHttpCall('/ninjamicrosoftsignin', { code: code }, 'POST', false, false, false);
        promise.then(function (res /*, status, headers, config*/) {
          _processAuthenticateOAuthUser(res);
          $window.location.assign('/');
        });
      } else if (isLoggedIn()) {
        window.console.log('User is already logged in ');
      } else {
        $window.alert('Couldn\'t sign you in!');
      }
    }

    function authenticateUserByOTP(res) {
      var user = {
        category: res.data.category,
        email: res.data.email,
        mobileNo: res.data.mobileNo,
        username: res.data.username,
        userId: res.data.userId,
        insurerOPS: res.data.insurerOPS,
        fullName: res.data.username
      };
      _processAuthenticateUser(res, user);
    }

    function appAuthenticateUser(token) {
      var reqType = 'GET';
      var reqUrl = '/ninja/app/authorization';
      $window.localStorage.setItem('jwtToken', btoa(token + ''));
      var promise = commonService.makeHttpCall(reqUrl, {}, reqType, false, false, true);
      promise.then(function (res /*, status, headers, config*/) {
        _authenticateAppUser(res);
      }).catch(function (err) {
        commonService.showToast('Couldn\'t verify you!');
        if ($window.Android && commonService.isApp()) {
          Android.logout(true);
        } else {
          logoutUser();
        }
      });
    }

    function _authenticateAppUser(res) {
      var user = {
        category: res.data.category,
        email: res.data.email,
        mobileNo: res.data.mobileNo,
        username: res.data.username,
        userId: res.data.userId,
        insurerOPS: res.data.insurerOPS,
        fullName: res.data.username
      };
      _processAuthenticateUser(res, user);
    }

    function _processAuthenticateUser(res, user, authResponse) {
      if (res && res.data && res.data.status && res.data.status === 'ERROR') {
        $window.alert('Couldn\'t sign you in!');
      }
      if (res && res.data && res.data.status && res.data.status === 'SUCCESS') {
        var authorizationHeader = res.headers('Authorization');
        if (authorizationHeader != null) {
          $window.localStorage.setItem('jwtToken', btoa(authorizationHeader + ''));
          $window.localStorage.setItem('roles', btoa(res.data.roles));
        }
        if (res.data.allowedActions) {
          setActionPermissions(res.data.allowedActions);
        }
        if (res.data.allowedViews) {
          setViewPermissions(res.data.allowedViews);
        }
        if (res.data.encEmail) {
          $window.localStorage.setItem('encEmail', btoa(res.data.encEmail));
          commonService.setCookie('encEmail', encodeURIComponent(res.data.encEmail), GENERAL_CONFIG.cookieDomain, 1);
        }
        if (res.data.decentralisationEnable) {
          var decentralization = {
            decentralisationEnable: res.data.decentralisationEnable
          };
          $window.localStorage.setItem('decentralization', btoa(JSON.stringify(decentralization)));
        }

        if (isSalesUser()) {
          commonService.setItemSession('common', 'restrictedSales', res.data.restrictedSales || false);
        }

        ninjaCommonDataService.getNinjaCommonData();
        setUser(user, authResponse, res.data);
        redirectPostLogin();
      }
    }

    function redirectPostLogin() {
      var redirectLink = commonService.getCookie('redirectLink');
      if (redirectLink) {
        $cookies.remove('redirectLink', { 'domain': GENERAL_CONFIG.cookieDomain });
        location.replace(redirectLink);
      } else {
        var url = location.origin + '/';
        location.replace(url);
      }
    }

    function _processAuthenticateOAuthUser(res) {
      if (res && res.data && res.data.status && res.data.status === 'ERROR') {
        $window.alert('Couldn\'t sign you in!');
      }
      if (res && res.data && res.data.status && res.data.status === 'SUCCESS') {
        var authorizationHeader = res.headers('Authorization');
        if (authorizationHeader != null) {
          $window.localStorage.setItem('jwtToken', btoa(authorizationHeader + ''));
          $window.localStorage.setItem('roles', btoa(res.data.roles));
        }
        if (res.data.allowedActions) {
          setActionPermissions(res.data.allowedActions);
        }
        if (res.data.allowedViews) {
          setViewPermissions(res.data.allowedViews);
        }
        if (res.data.encEmail) {
          $window.localStorage.setItem('encEmail', btoa(res.data.encEmail));
          commonService.setCookie('encEmail', encodeURIComponent(res.data.encEmail), GENERAL_CONFIG.cookieDomain, 1);
        }
        if (res.data.decentralisationEnable) {
          var decentralization = {
            decentralisationEnable: res.data.decentralisationEnable
          };
          $window.localStorage.setItem('decentralization', btoa(JSON.stringify(decentralization)));
        }

        ninjaCommonDataService.getNinjaCommonData();
        setOAuthUser(res.data);

        var redirectLink = commonService.getCookie('redirectLink');
        if (redirectLink) {
          $cookies.remove('redirectLink', { 'domain': GENERAL_CONFIG.cookieDomain });
          location.assign(redirectLink);
        } else {
          $state.go('/');
        }
      }
    }

    function setUser(User, AuthResponse, responseData) {
      var user = {};

      if (AuthResponse) {
        user = {
          email: User.getEmail(),
          fullName: User.getName(),
          dp: User.getImageUrl(),
          turtlemint_google_id_token: AuthResponse.id_token,
          expires_at: AuthResponse.expires_at,
          category: responseData.category,
          insurerOPS: responseData.insurerOPS,
          mobileNo: responseData.mobileNo || ''
        };
      } else {
        user = User;
      }

      var mxpUserData = {
        name: responseData.username,
        email: responseData.email,
        roles: responseData.roles
      };

      localStorage.setItem('user', btoa(JSON.stringify(user)));
      TrackingService.identifyUser(mxpUserData);
      commonService.setCookie('ninjauser', JSON.stringify(user), GENERAL_CONFIG.cookieDomain, 30);
      commonService.setPlainCookie('userId', user.userId, GENERAL_CONFIG.cookieDomain, 1);
    }

    function getUser() {
      var user = void 0,
          userSession = localStorage.getItem('user');
      if (userSession) {
        try {
          user = atob(userSession);
        } catch (err) {
          user = userSession;
        }
        return JSON.parse(user);
      }
    }

    function isLoggedIn() {
      var user = getUser();
      if (user && user.userId) {
        return true;
      } else if (user && user.email && user.email.indexOf(commonService.getCookie('emailDomain')) !== -1 && user.turtlemint_google_id_token && new Date(user.expires_at) > new Date()) {
        return true;
      } else {
        return false;
      }
    }

    function logoutUser() {
      TrackingService.pushToGTMDatalayer('LOGOUT_MANUAL', 'track_event', {});
      var promise = commonService.makeHttpCall('/ninja/logout', {}, 'GET', false, false, false);
      promise.then(function () /*res, status, headers, config*/{
        _clearUserData();
      }).catch(function () {
        _clearUserData();
      });
    }

    function _clearUserData() {
      var broker = sessionStorage.getItem('broker');
      var tenant = sessionStorage.getItem('tenant');
      $window.localStorage.clear();
      $window.sessionStorage.clear();
      var cookies = $cookies.getAll();
      angular.forEach(cookies, function (v, k) {
        if (!CONSTANTS_LIST.EXCLUED_COOKIES.includes(k)) {
          $cookies.remove(k, { 'domain': GENERAL_CONFIG.cookieDomain });
          $cookies.remove(k, { 'domain': location.hostname });
        }
      });
      sessionStorage.setItem('broker', broker);
      sessionStorage.setItem('tenant', tenant);
      if ($window.Android && commonService.isApp()) {
        Android.logout(true);
      } else {
        $state.go('login', {}, {
          reload: true
        });
      }
    }

    function getRoles() {
      var encryptedRoles = $window.localStorage.getItem('roles');
      return atob(encryptedRoles);
    }

    function setViewPermissions(permissionArray) {
      viewPermissions = permissionArray;
      localStorage.setItem('viewPermissions', btoa(JSON.stringify(permissionArray)));
    }

    function getViewPermissions() {
      if (viewPermissions && viewPermissions !== undefined) {
        return viewPermissions;
      } else if (localStorage.getItem('viewPermissions')) {
        try {
          viewPermissions = JSON.parse(atob(localStorage.getItem('viewPermissions')));
        } catch (err) {
          viewPermissions = JSON.parse(localStorage.getItem('viewPermissions'));
        }
        return viewPermissions;
      } else {
        return undefined;
      }
    }

    function hasViewPermission(action) {
      var permissions = getViewPermissions();
      if (permissions && permissions[action] === action) {
        return true;
      }
      return false;
    }

    function setActionPermissions(permissionArray) {
      actionPermissions = permissionArray;
      localStorage.setItem('actionPermissions', btoa(JSON.stringify(permissionArray)));
    }

    function getActionPermissions() {
      if (actionPermissions && actionPermissions !== undefined) {
        return actionPermissions;
      } else if (localStorage.getItem('actionPermissions')) {
        try {
          actionPermissions = JSON.parse(atob(localStorage.getItem('actionPermissions')));
        } catch (err) {
          actionPermissions = JSON.parse(localStorage.getItem('actionPermissions'));
        }
        return actionPermissions;
      } else {
        return undefined;
      }
    }

    function hasPermission(action) {
      var permissions = getActionPermissions();
      if (permissions && permissions[action] === action) {
        return true;
      }
      return false;
    }

    function canShowPartnerDetails() {
      var roles = getRoles().split(',');
      return !roles.includes('TP-OPS');
    }

    function isSalesUser() {
      var roles = getRoles().split(',');
      return roles.includes(CONSTANTS_LIST.ROLES.SALES);
    }
    function setOAuthUser(responseData) {
      var user = {};
      if (responseData) {
        var jwt_token = atob(localStorage.getItem('jwtToken'));
        var token_data = jwt_token.split('.')[1];
        var data_dict = JSON.parse(atob(token_data));
        user = {
          email: responseData.email,
          fullName: responseData.username,
          // dp: responseData.getImageUrl(),
          turtlemint_google_id_token: 'dummy_value',
          expires_at: data_dict['iat'] + GENERAL_CONFIG.TOKEN_EXPIRY,
          userId: data_dict['UI'],
          category: responseData.category,
          insurerOPS: responseData.insurerOPS
        };
      } else {
        user = responseData;
      }

      var mxpUserData = {
        name: responseData.username,
        email: responseData.email,
        roles: responseData.roles
      };

      localStorage.setItem('user', btoa(JSON.stringify(user)));
      TrackingService.identifyUser(mxpUserData);
      commonService.setCookie('ninjauser', JSON.stringify(user), GENERAL_CONFIG.cookieDomain, 1);
      commonService.setPlainCookie('userId', user.userId, GENERAL_CONFIG.cookieDomain, 1);
    }

    function isMISChecker() {
      var roles = getRoles().split(',');
      return roles.includes('MIS-CHECKER') || roles.includes('MIS-CHECKER-2');
    }

    function isMISChecker2() {
      var roles = getRoles().split(',');
      return roles.includes('MIS-CHECKER-2');
    }

    function showRequestAccess() {
      if (commonService.isApp()) {
        var roles = getRoles().split(',');
        if (roles.includes('SALES') || roles.includes('NINJA-APP-TECH')) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function getModuleConfig() {
      var config = {};
      config.mis = {
        link: hasPermission('mis-incomplete-list-read') ? 'mis-incomplete-list' : 'mis-all-list'
      };
      config.renewals = {
        link: hasPermission('renewals-noquote-list-read') ? 'renewals-noquote' : 'renewals-all'
      };
      config.crm = {
        link: location.origin + '/crm'
      };
      config.insights = _getInsightsConfig();
      config.payouts = {
        link: location.origin + '/payouts-dashboard'
      };
      config.qis = {
        link: commonService.isApp() && hasPermission('quotes-list-read') ? 'quotes-list' : 'qis-list',
        show: !isRestrictedSalesAccess()
      };
      config.pi = {
        show: !isRestrictedSalesAccess()
      };
      config.contests = {
        show: commonService.isApp() && broker === CONSTANTS_LIST.BROKER.TURTLEMINT
      };
      config.plm = {
        show: true
      };
      config.mintAcademy = {
        show: hasPermission('module-mint-academy') && commonService.isApp() ? true : false
      };
      return config;
    }

    function _getInsightsConfig() {
      var baseDomain = commonService.getBaseDomain();
      var insightsUrl = location.protocol + '//insights.' + baseDomain;
      return {
        link: insightsUrl,
        target: commonService.isMobile() ? '_self' : '_blank'
      };
    }

    function isRestrictedSalesAccess() {
      var enabled = false;
      if (!enabled) {
        return false;
      }
      if (isSalesUser()) {
        return commonService.getItemSession('common', 'restrictedSales');
      }
      return false;
    }

    function getFirebaseToken() {
      return commonService.getPlainCookie('FIREBASE_TOKEN');
    }

    function goToMintAcademy() {
      var reqUrl = '/mintacademy/users/magiclogin';
      var ninjaUser = getUser();
      var reqObj = {
        branch: GENERAL_CONFIG.MINTACADEMY_BRANCH_KEY,
        action: 'request_magic_link',
        roles: ['user'],
        user_type: 'Employee',
        external_id: ninjaUser.email,
        username: ninjaUser.email,
        email: ninjaUser.email,
        phone_no: ninjaUser.mobileNo,
        first_name: ninjaUser.fullName,
        last_name: ''
      };

      commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true).then(function (res) {
        if (res.data && res.data.success) {
          TrackingService.pushToGTMDatalayer('NINJA_ACADEMY_MODULE_SUCCESS', 'track_event', {});
          location.assign(res.data.link);
        } else {
          var msg = 'Oops. Failed to redirect. Try again later';
          commonService.showToast(msg);
        }
      }).catch(function (err) {
        var msg = 'Oops. Failed to redirect. Try again later';
        if (err.status && err.statusText) {
          msg = err.status + ' : ' + err.statusText;
        } else if (err.status && err.data && err.data.message) {
          msg = err.status + ' : ' + err.data.message;
        } else if (err.status) {
          msg = err.status + ' : ' + msg;
        }
        commonService.showToast(msg);
        TrackingService.pushToGTMDatalayer('NINJA_ACADEMY_MODULE_FAIL', 'track_event', {
          errorStatus: err.status
        });
      });
    }
  }]);
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.partner', [])

  // Config Blocks
  .config(RouterConfig);

  // Run Blocks
  RouterConfig.inject = ['$stateProvider'];

  /*=====================================
  =            Config Blocks            =
  =====================================*/
  /**
   * Configure the turtlemintAdmin. module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.partner
   *
   * @author     sumantaparida
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {
    $stateProvider.state('partner-list', {
      url: '/partner',
      parent: 'parent-state',
      templateUrl: 'dev/partner/templates/partner-management-list.ctrl.html',
      controller: 'PartnerManagementListCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        config: ['PartnerService', function (PartnerService) {
          return PartnerService.getConfig().then(function (res) {
            return res;
          });
        }],
        pageType: function pageType() {
          return 'PARTNER';
        }
      }
    }).state('partner-lead-list', {
      url: '/partner-lead',
      templateUrl: 'dev/partner/templates/lead-management-list.ctrl.html',
      controller: 'PartnerManagementListCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        config: ['PartnerService', function (PartnerService) {
          return PartnerService.getConfig().then(function (res) {
            return res;
          });
        }],
        pageType: function pageType() {
          return 'PARTNER_LEAD';
        }
      }
    }).state('partner-lead-detail', {
      url: '/lead-review/:tenant/:id',
      templateUrl: 'dev/partner/templates/partner-add-update.tpl.html',
      controller: 'PartnerAddUpdateCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        config: ['$q', '$rootScope', 'PartnerService', 'commonService', '$state', function ($q, $rootScope, PartnerService) {
          try {
            return PartnerService.getConfig('PARTNER_LEAD_REVIEW').then(function (res) {
              return res;
            });
          } catch (err) {}
        }],
        partner: ['$q', '$stateParams', '$state', '$window', 'PartnerService', 'commonService', function ($q, $stateParams, $state, $window, PartnerService, commonService) {
          var deferred = $q.defer();
          var partnerId = $stateParams.id;
          var tenant = $stateParams.tenant;
          PartnerService.getPartnerById(partnerId, tenant).then(function (res) {
            deferred.resolve(res);
          }).catch(function (err) {
            $window.console.log(err);
            if (err.status === 404) {
              $state.go('partner', {}, { reload: true });
            } else {
              commonService.showToast('Some Error Ocurred');
              deferred.reject(err);
            }
          });
          return deferred.promise;
        }],
        pageType: function pageType() {
          return 'PARTNER_LEAD_REVIEW';
        }
      }
    }).state('partner-detail', {
      url: '/partner-review/:tenant/:id',
      parent: 'parent-state',
      templateUrl: 'dev/partner/templates/partner-add-update.tpl.html',
      controller: 'PartnerAddUpdateCtrl',
      controllerAs: 'ViewModel',
      authenticate: true,
      resolve: {
        config: ['$q', '$rootScope', 'PartnerService', 'commonService', '$state', function ($q, $rootScope, PartnerService) {
          try {
            return PartnerService.getConfig('PARTNER_REVIEW').then(function (res) {
              return res;
            });
          } catch (err) {}
        }],
        partner: ['$q', '$stateParams', '$state', '$window', 'PartnerService', 'commonService', function ($q, $stateParams, $state, $window, PartnerService, commonService) {
          var deferred = $q.defer();
          var partnerId = $stateParams.id;
          var tenant = $stateParams.tenant;
          PartnerService.getPartnerById(partnerId, tenant).then(function (res) {
            deferred.resolve(res);
          }).catch(function (err) {
            $window.console.log(err);
            if (err.status === 404) {
              $state.go('partner', {}, { reload: true });
            } else {
              commonService.showToast('Some Error Ocurred');
              deferred.reject(err);
            }
          });
          return deferred.promise;
        }],
        pageType: function pageType() {
          return 'PARTNER_REVIEW';
        }
      }
    });
  }
})();
"use strict";

(function () {
  var leadConstants = {};

  var tableColumns = [{ value: "data", label: "Date & time", filter: false }, { value: "dpNo", label: "DP Number", filter: false }, { value: "name", label: "Name", filter: false },
  // { value: "mobile", label: "Mobile", filter: false },
  { value: "insuranceExperienceInYears", label: "Experience", filter: false }, { value: "verificationDate", label: "Verification Date", filter: false }, { value: "salesStatus", label: "Sales Status", filter: true }, { value: "profileStatus", label: "Profile Status", filter: true }, { value: "owner", label: "Owner", filter: false }, { value: "qcStatusState", label: "QC Status", filter: true }];

  leadConstants.tableColumns = tableColumns;
  var salesStatus = [{
    value: "new,inprogress,agreedtopartner,notinterested,notdplead",
    label: "All"
  }, { value: "new", label: "New prospect" }, { value: "inprogress", label: "In progress" }, { value: "agreedtopartner", label: "Agreed to Partner" }, { value: "notinterested", label: "Not interested" }, { value: "notdplead", label: "NOT DP Lead" }];
  leadConstants.salesStatus = salesStatus;

  var leadStatus = [
  // {
  //   name: "All",
  //   value: "not_verified,to_verify,verified,rejected",
  //   id: 3,
  //   completed: undefined,
  //   label: "All Records"
  // },
  {
    name: "Verified",
    value: "verified",
    completed: true,
    id: 0,
    label: "Verified Records"
  }, {
    name: "Incomplete",
    value: "not_verified",
    completed: false,
    id: 1,
    label: "Non verified Records"
  }, {
    name: "To verify",
    value: "to_verify",
    completed: true,
    id: 2,
    label: "To Verify Records"
  }, {
    name: "Rejected",
    value: "rejected",
    completed: true,
    id: 4,
    label: "Rejected Records"
  }];
  leadConstants.profileStatus = leadStatus;

  var supervisorStatus = [{
    name: "All",
    value: null
  }, {
    name: "Assigned",
    value: true
  }, {
    name: "Not Assigned",
    value: false
  }];
  leadConstants.supervisorName = supervisorStatus;

  var eligible = [{
    value: null,
    text: "All"
  }, {
    value: "true",
    text: "Eligible"
  }, {
    value: "false",
    text: "Not Eligible"
  }];
  leadConstants.eligible = eligible;

  var query = {
    limit: 15,
    page: 1,
    totalCount: 0
  };
  leadConstants.query = query;

  var search = {
    limit: 20,
    page: 1,
    totalCount: 0
  };
  leadConstants.search = search;

  var limitOptions = [15, 30, 50];
  leadConstants.limitOptions = limitOptions;

  var activityColumn = ["Activity Type", "Date & Time", "Type of Interaction", "Disposition / Remarks"];
  leadConstants.activityColumn = activityColumn;

  var newActivityForm = [{
    name: "Activity type",
    code: "activityType",
    placeholder: "",
    type: "radio",
    required: true,
    radioConfig: {
      options: [{
        value: "incoming",
        text: "Incoming"
      }, {
        value: "outgoing",
        text: "Outgoing"
      }],
      valueKey: "value",
      textKey: "text"
    }
  }, {
    name: "Type of Interaction",
    code: "interactionType",
    placeholder: "",
    type: "select",
    required: true,
    selectConfig: {
      options: [{
        value: "call",
        displayName: "Call"
      }, {
        value: "sms",
        displayName: "SMS"
      }, {
        value: "email",
        displayName: "Email"
      }, {
        value: "whatsapp",
        displayName: "Whatsapp"
      }],
      valueKey: "value",
      textKey: "displayName"
    }
  }, {
    name: "Disposition / Remarks",
    code: "callRemark",
    placeholder: "Disposition / Remarks",
    type: "textarea",
    required: true,
    textareaConfig: {
      rows: 3
    }
  }];
  leadConstants.newActivityForm = newActivityForm;

  var activeInactive = [{
    value: null,
    text: "All"
  }, {
    value: "true",
    text: "Active"
  }, {
    value: "false",
    text: "Inactive"
  }];
  leadConstants.activeInactive = activeInactive;

  var profileCompleteStatus = [{
    value: null,
    name: "All"
  }, {
    value: "true",
    name: "Completed"
  }, {
    value: "false",
    name: "Not Completed"
  }];
  leadConstants.profileCompleteStatus = profileCompleteStatus;
  // TASK(M30-4457) - QC Status for QC Verification of a Profile 
  var qcStatusState = [{
    value: null,
    name: "All"
  }, {
    name: "-",
    value: "-",
    id: 1
  }, {
    name: "QC Verified",
    value: "qc_verified",
    id: 2
  }, {
    name: "QC Rejected",
    value: "qc_rejected",
    id: 3
  }];
  leadConstants.qcStatusState = qcStatusState;

  angular.module("brokerAdmin.partner").constant("LEAD_CONSTANTS", leadConstants);
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.partner').controller('PartnerManagementListCtrl', controller);

  controller.$inject = ['$scope', '$rootScope', 'commonService', 'AuthService', 'TrackingService', '$mdBottomSheet', '$mdDialog', '$state', '$q', '$window', 'PartnerService', '$cookies', 'config', 'pageType', 'LEAD_CONSTANTS', 'GENERAL_CONFIG', '$mdSelect', '$mdMenu'];
  /**
   *
   * Partner List Controller
   *
   * @public
   *
   * @author    sumantaparida
   *
   * @param     {Object}    $scope                       Angular scope
   * @param     {Object}    $state                       Angular State service
   * @param     {Object}    commonService                commonService instance
   *
   */
  function controller($scope, $rootScope, commonService, AuthService, TrackingService, $mdBottomSheet, $mdDialog, $state, $q, $window, PartnerService, $cookies, config, pageType, LEAD_CONSTANTS, GENERAL_CONFIG, $mdSelect) {
    var ViewModel = this;
    var CONSTANTS = angular.copy(LEAD_CONSTANTS);
    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.someErrorOccured = 'Some Error Ocurred';
    ViewModel.downloadSuccess = 'Download successfully';
    ViewModel.exportPartnerData = exportPartnerData;
    // ViewModel.initialParams = {};
    var initialParams = {};
    ViewModel.query = CONSTANTS.query;
    ViewModel.search = CONSTANTS.search;
    ViewModel.limitOptions = CONSTANTS.limitOptions;
    ViewModel.eligible = CONSTANTS.eligible;
    ViewModel.profileStatus = [];
    ViewModel.salesStatus = [];
    ViewModel.qcStatus = CONSTANTS.qcStatusState; // TASK(M30-4457) - fetched constants
    ViewModel.getPartnerType = getPartnerType;
    ViewModel.profileStatusLabel = LEAD_CONSTANTS.profileStatus[0].label;
    ViewModel.profileStatusChange = profileStatusChange;
    ViewModel.qcStatusChange = qcStatusChange; // TASK(M30-4457) - Qc onchange function
    ViewModel.salesExecutiveFilter = salesExecutiveFilterChange;
    ViewModel.onOrganisationSelection = onOrganisationSelection;
    ViewModel.salesStatusChange = salesStatusChange;
    ViewModel.turtlemint = commonService.getCookie('organisation');
    ViewModel.partnerLead = ['PARTNER_LEAD'].includes(pageType);
    ViewModel.broker = $window.sessionStorage.getItem('broker');
    ViewModel.isApp = commonService.isApp();
    ViewModel.callPartner = callPartner;
    ViewModel.openFilterSheet = openFilterSheet;
    ViewModel.closeFilterSheet = closeFilterSheet;
    ViewModel.applyMobileFilter = applyMobileFilter;
    ViewModel.loadMorePartners = loadMorePartners;
    ViewModel.loadMore = {
      show: true,
      loading: false
    };
    ViewModel.navbarConfig = {
      vertical: 'partner',
      title: 'Partner Management',
      search: {
        enabled: searchPermissionFn(),
        searchFn: searchFn,
        searchKey: ViewModel.searchKey,
        placeholder: ViewModel.partnerLead ? 'Search by mobile' : 'Search by name, mobile or email'
      },
      actions: [{
        type: 'menu',
        class: '',
        name: '',
        iconSrc: '/images/common/plus-icon.svg',
        permissionCode: ViewModel.broker === 'yesbank' && ViewModel.partnerRoles.includes(['PARTNER-MAKER']) ? 'partner-bulk-update' : 'partner-create',
        viewOnly: !ViewModel.partnerLead,
        dataAuto: 'new-sale-button',
        options: [{
          name: 'Add Partner',
          action: addPartner,
          permissionCode: 'partner-create',
          hide: ViewModel.broker === 'hdfcbank'
        }, {
          name: 'Add Partner-Bulk Upload',
          action: function action() {
            return addBulkUpload('add');
          },
          permissionCode: 'partner-bulk-create'
        }, {
          name: 'Update Partner-Bulk Upload',
          action: function action() {
            return addBulkUpload('update');
          },
          permissionCode: 'partner-bulk-update'
        }]
      }],
      mode: false
    };
    ViewModel.addPartner = addPartner;
    ViewModel.addBulkUpload = addBulkUpload;
    ViewModel.getPartner = getPartner;
    ViewModel.toggleLimitOptions = toggleLimitOptions;
    ViewModel.goStatePartnerReview = goStatePartnerReview;
    // For Lead Management Start
    ViewModel.partnerProfileState = LEAD_CONSTANTS.profileStatus;
    ViewModel.partnerSupervisorName = LEAD_CONSTANTS.supervisorName;
    ViewModel.columns = LEAD_CONSTANTS.tableColumns;
    ViewModel.salesStatusList = CONSTANTS.salesStatus;
    ViewModel.activeInactive = CONSTANTS.activeInactive;

    ViewModel.LSroles = $window.localStorage.getItem('roles');
    ViewModel.partnerRoles = window.atob(ViewModel.LSroles) || null;
    // For Lead Management End
    ViewModel.applyFilterEligible = applyFilterEligible;
    ViewModel.applyFilterActive = applyFilterActive;
    ViewModel.applyFilterCompleted = applyFilterCompleted;
    ViewModel.branchDataList = [];
    ViewModel.regionDataList = [];
    ViewModel.stopEventPropagation = commonService.stopEventPropagation;
    ViewModel.applyBranchFilter = applyBranchFilter;
    ViewModel.clearBranchFilter = clearBranchFilter;
    ViewModel.applyRegionFilter = applyRegionFilter;
    ViewModel.clearRegionFilter = clearRegionFilter;
    ViewModel.clearSearchBranch = clearSearchBranch;

    if (['PARTNER-MAKER'].includes(ViewModel.partnerRoles)) {
      ViewModel.partnerProfileState = ViewModel.partnerProfileState.filter(function (value, index, arr) {
        return value.value === 'verified' || value.value === 'not_verified' || value.value === 'rejected';
      });
    }

    if (['PARTNER-CHECKER'].includes(ViewModel.partnerRoles) && ViewModel.broker !== 'yesbank') {
      ViewModel.profileStatusLabel = LEAD_CONSTANTS.profileStatus[2].label;
      ViewModel.partnerProfileState = ViewModel.partnerProfileState.filter(function (value, index, arr) {
        return value.value === 'to_verify';
      });
    }

    $scope.$on('$locationChangeStart', function (event) {
      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }

      if (angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
        event.preventDefault();
      }
    });

    init(config);

    /*=============================================
    =            Implementation                  =
    =============================================*/
    /**
     * onOrganisationSelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onOrganisationSelection() {
      ViewModel.profileStatusLabel = ViewModel.profileStatus.label;
      initialParams.profileStatus = ViewModel.profileStatus.value;
      ViewModel.tenant = ViewModel.organisation.value;
      initialParams.tenant = ViewModel.tenant;
      commonService.setCookie('organisation', ViewModel.tenant, GENERAL_CONFIG.cookieDomain, 1);
      getPartner();
    }
    /**
     * addPartner function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function addPartner(event) {
      var dataObj = {
        addpartner: true,
        addedpartner: false,
        unableToAdd: false,
        tenant: ViewModel.tenant,
        broker: ViewModel.broker,
        associateNewPartner: ViewModel.associateNewPartner,
        roles: ViewModel.partnerRoles,
        organisationsList: ViewModel.organisationsList,
        businessvertical: ViewModel.businessvertical,
        modalInfo: {}
      };
      if (ViewModel.partnerLead) {
        dataObj.modalInfo = {
          title: 'Add new lead',
          type: 'partnerLead'
        };
      } else {
        dataObj.modalInfo = {
          title: 'Add new partner',
          type: 'nonassociate'
        };
      }
      TrackingService.pushToGTMDatalayer('PARTNER_ADD_LEAD_CLICK', 'track_event', {});
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/add-partner.tpl.html',
        clickOutsideToClose: true,
        controller: 'partnerAddCtrl',
        targetEvent: event,
        locals: {
          data: dataObj,
          branchDataList: ViewModel.branchDataList
        }
      }).then(function (res) {
        if (res.action === 'REDIRECT') {
          TrackingService.pushToGTMDatalayer(res.eventName, 'track_event', {});
          $state.go(res.state, res.params);
        }
      });
    }

    function addBulkUpload(event) {
      var obj = {
        organisationsList: ViewModel.organisationsList,
        tenant: ViewModel.tenant,
        templateInfo: {}
      };
      if (event === 'add') {
        obj.templateInfo = {
          title: 'Add Partners - Bulk Upload',
          subTitle: 'A set of partners can be uploaded to the database using these simple steps.',
          action: 'create',
          buttonName: 'add partners'
        };
      } else {
        obj.templateInfo = {
          title: 'Update Partners - Bulk Upload',
          subTitle: 'A set of partners can be updated in the database using these simple steps.',
          action: 'update',
          buttonName: 'update Partners'
        };
      }

      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/add-bulk-upload.tpl.html',
        clickOutsideToClose: true,
        controller: 'addBulkUploadCtrl',
        targetEvent: event,
        locals: {
          data: obj
        }
      });
    }
    // NOTE: Apply Branch Filter
    function applyBranchFilter() {
      console.log('Filter', ViewModel.branchFilter);
      var branchArr = ViewModel.branchFilter;
      if (branchArr && branchArr.length > 0) {
        initialParams.branch = JSON.stringify(branchArr);
      } else {
        initialParams.branch = null;
      }
      getPartner();
      $mdSelect.hide();
    }
    function clearBranchFilter() {
      ViewModel.branchFilter = [];
      initialParams.branch = null;
      getPartner();
      $mdSelect.hide();
    }
    function clearRegionFilter() {
      ViewModel.regionFilter = [];
      initialParams.region = null;
      getPartner();
      $mdSelect.hide();
    }
    // NOTE: Apply Region Filter
    function applyRegionFilter() {
      console.log('Filter', ViewModel.regionFilter);
      var regionArr = ViewModel.regionFilter;
      if (regionArr && regionArr.length > 0) {
        initialParams.region = JSON.stringify(regionArr);
      } else {
        initialParams.region = null;
      }
      getPartner();
      $mdSelect.hide();
    }
    function clearSearchBranch() {
      ViewModel.searchBranchList = '';
    }
    /**
     * profileStatusChange function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function profileStatusChange() {
      $mdSelect.hide();
      initialParams.tenant = ViewModel.tenant;
      ViewModel.profileStatusLabel = ViewModel.profileStatus;
      initialParams.profileStatus = ViewModel.profileStatus.value;
      $window.sessionStorage.setItem('profileStatus', ViewModel.profileStatus);
      getPartner();
    }

    function salesExecutiveFilterChange() {
      $mdSelect.hide();
      initialParams.supervisorName = ViewModel.supervisorNameStatus;
      getPartner();
    }

    /**
     * salesStatusChange function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function salesStatusChange() {
      $mdSelect.hide();
      initialParams.tenant = ViewModel.tenant;
      ViewModel.profileStatusLabel = ViewModel.salesStatus.label;
      initialParams.salesStatus = ViewModel.salesStatus.value;
      getPartner();
    }

    /** // TASK(M30-4457) - QC Status on change Function
     *qcStatusChange function
    *
    * @public
    *
    * @function
    *
    * @author ankitasangodkar
    *
    */
    function qcStatusChange() {
      $mdSelect.hide();
      initialParams.tenant = ViewModel.tenant;
      ViewModel.qcStatusLabel = ViewModel.qcStatus;
      //initialParams.qcStatus = ViewModel.qcStatus.value;
      $window.sessionStorage.setItem('qcStatus', ViewModel.qcStatus);
      getPartner();
    }
    /**
     * getPartner function
     *
     * @public Partner and Lead_Management
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getPartner() {
      getTitle();
      var deferred = $q.defer();
      ViewModel.loadingPromise = deferred.promise;
      ViewModel.loadMore.loading = true;
      var jsonData = getPartnerJSON();
      if (ViewModel.partnerLead) {
        $window.sessionStorage.setItem('profileStatus', jsonData.profileStatus);
        PartnerService.getPLM(jsonData).then(function (response) {
          ViewModel.searchPartnerList = undefined;
          var partner = response.data && response.data.data;
          ViewModel.partnerList = partner.data;
          ViewModel.loadMore.loading = false;
          if (ViewModel.partnerList && ViewModel.partnerList.length >= ViewModel.query.limit) {
            ViewModel.loadMore.show = true;
          } else {
            ViewModel.loadMore.show = false;
          }
          ViewModel.query.totalCount = partner.count;
          deferred.resolve(ViewModel.partnerList);
        }).catch(function (err) {
          ViewModel.loadMore.loading = false;
          deferred.reject(err);
        });
      } else {
        PartnerService.getPartner(jsonData).then(function (response) {
          ViewModel.searchPartnerList = undefined;
          var partner = response.data && response.data.data;
          ViewModel.partnerList = partner.data;
          ViewModel.loadMore.loading = false;
          if (ViewModel.partnerList && ViewModel.partnerList.length >= ViewModel.query.limit) {
            ViewModel.loadMore.show = true;
          } else {
            ViewModel.loadMore.show = false;
          }
          ViewModel.query.totalCount = partner.count;
          deferred.resolve(ViewModel.partnerList);
        }).catch(function (err) {
          ViewModel.loadMore.loading = false;
          deferred.reject(err);
        });
        PartnerService.searchBranch().then(function (res) {
          var _ref = res || {},
              data = _ref.data;

          ViewModel.branchDataList = data;
          // regionDataList
          // ViewModel.regionDataList
          var setRegionList = new Set();
          data.map(function (ele) {
            setRegionList.add(ele.region);
          });
          ViewModel.regionDataList = [].concat(_toConsumableArray(setRegionList));
        }).catch(function (err) {
          deferred.reject(err);
        });
      }
      return deferred.promise;
    }

    function getPartnerJSON() {
      var currentUser = AuthService.getUser();
      var eligible = $window.sessionStorage.getItem('eligible');
      var accessAllowed = $window.sessionStorage.getItem('accessAllowed');
      var profileCompleted = $window.sessionStorage.getItem('profileCompleted');
      var qcStatusProfile = $window.sessionStorage.getItem('qcStatus'); // TASK(M30-4457)
      var jsonData = angular.copy(initialParams);
      if (eligible !== 'null') {
        jsonData.eligible = eligible;
      }
      if (accessAllowed !== 'null') {
        jsonData.accessAllowed = accessAllowed;
      }
      if (profileCompleted !== 'null') {
        jsonData.profileCompleted = profileCompleted;
      }
      jsonData.profileStatus = $window.sessionStorage.getItem('profileStatus') || 'verified';

      // TASK(M30-4457) - check if qc status is not null
      if (qcStatusProfile !== 'null') {
        jsonData.qcStatus = qcStatusProfile;
      }

      if (['PARTNER-CHECKER'].includes(ViewModel.partnerRoles)) jsonData.profileStatus = $window.sessionStorage.getItem('profileStatus') || 'to_verify';

      jsonData.limit = ViewModel.query.limit;
      jsonData.skip = (ViewModel.query.page - 1) * ViewModel.query.limit;
      jsonData.salesEmail = ViewModel.partnerLead ? currentUser.email : null;
      if (['SALES'].includes(ViewModel.partnerRoles) && ViewModel.partnerLead) {
        jsonData.role = 'SALES';
      } else {
        jsonData.role = null;
      }
      return jsonData;
    }
    /**
     * initalize function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function init(config) {
      $window.localStorage.setItem('partner', btoa(JSON.stringify({})));
      setConfigGlobal(config);
      intializeForm(config);
      initialParams.tenant = ViewModel.tenant;
      filterRetention();
      getPartner();
    }
    /**
     * setConfigGlobal function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function setConfigGlobal(config) {
      $rootScope.partnerConfig = config.data;
    }
    /**
     * intializeForm function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function intializeForm(data) {
      var organisations = data.organisations,
          dropDowns = data.dropDowns;

      var _ref2 = dropDowns || {},
          businessvertical = _ref2.businessvertical,
          partnerFilterConfig = _ref2.partnerFilterConfig;

      ViewModel.organisationsList = organisations || [];
      ViewModel.businessvertical = businessvertical || [];
      ViewModel.partnerFilterConfig = partnerFilterConfig;
      ViewModel.organisation = getIndexFromObj(ViewModel.turtlemint, ViewModel.organisationsList);
      ViewModel.tenant = ViewModel.organisation && ViewModel.organisation.value;
      ViewModel.partnerProfileState = getPartnerProfileStatus(ViewModel.partnerFilterConfig);
      ViewModel.profileCompleteStatus = getPartnerProfileCompleteStatus(ViewModel.partnerFilterConfig);
      ViewModel.qcStatusState = getPartnerQcStatus(); // TASK(M30-4457)
    }
    /**
     * toggleLimitOptions function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function toggleLimitOptions() {
      ViewModel.limitOptions = ViewModel.limitOptions ? undefined : [15, 30, 50];
    }
    /**
     * goStatePartnerReview function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function goStatePartnerReview(partner) {
      var currentUser = AuthService.getUser();
      var leadOwner = partner.leadOwner || {};
      // Add a flag for Role & State based
      if (ViewModel.partnerLead) {
        if (ViewModel.hasPermission('partner-lead-detail-read') || ViewModel.hasPermission('partner-lead-update')) {
          if (currentUser.email !== leadOwner.email && !ViewModel.hasPermission('partner-lead-direct-update')) {
            $mdDialog.show({
              templateUrl: 'dev/partner/templates/dialogs/set-owner-dialog.tpl.html',
              clickOutsideToClose: true,
              controller: 'setOwnerUpdateCtrl',
              locals: {
                data: {
                  lead: partner,
                  tenant: ViewModel.tenant
                }
              }
            });
          } else {
            $state.go('partner-lead-detail', {
              tenant: ViewModel.tenant,
              id: partner.id
            });
          }
        }
      } else {
        if (ViewModel.hasPermission('partner-detail-read') || ViewModel.hasPermission('partner-update')) {
          $state.go('partner-detail', {
            tenant: ViewModel.tenant,
            id: partner.id
          });
        }
      }
    }
    /**
     * searchFn function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    // function searchFn(searchKey) {
    //   if (searchKey) {
    //     if (searchKey.length > 2) {
    //       return PartnerService.searchPartner(
    //         searchKey,
    //         true,
    //         ViewModel.tenant,
    //         true
    //       ).then(response => {
    //         ViewModel.partnerList = undefined;
    //         ViewModel.searchPartnerList =
    //           (response && response.data && response.data.data) || [];
    //       });
    //     }
    //   } else {
    //     return getPartner();
    //   }
    // }


    // function searchFn(searchKey) {
    //   if (searchKey && searchKey.length > 2) {
    //     const source = ViewModel.partnerLead ? 'PLM' : 'PM';
    //     return PartnerService.searchPartner(
    //       searchKey,
    //       true,
    //       ViewModel.tenant,
    //       true,
    //       null,
    //       source
    //     ).then(response => {
    //       ViewModel.partnerList = undefined;
    //       ViewModel.searchPartnerList =
    //         (response && response.data && response.data.data) || [];
    //     });
    //   } else {
    //     return getPartner();
    //   }
    // }

    function searchFn(searchKey) {
      var numberRgx = new RegExp(/^(0|[+91]{3})?[6-9][0-9]{9}$/);
      var matchNumber = numberRgx.test(searchKey);
      if (searchKey) {
        if (ViewModel.partnerLead && matchNumber) {
          return PartnerService.searchPartner(searchKey, true, ViewModel.tenant, true).then(function (response) {
            ViewModel.partnerList = undefined;
            ViewModel.searchPartnerList = response && response.data && response.data.data || [];
          });
        } else if (!ViewModel.partnerLead && searchKey.length > 2) {
          return PartnerService.searchPartner(searchKey, true, ViewModel.tenant, true).then(function (response) {
            ViewModel.partnerList = undefined;
            ViewModel.searchPartnerList = response && response.data && response.data.data || [];
          });
        }
      } else {
        return getPartner();
      }
    }

    /**
     * searchPermissionFn Permission
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function searchPermissionFn() {
      var leadSearch = ViewModel.hasPermission('partner-lead-search');
      var data = leadSearch ? leadSearch : false;
      return data;
    }
    /**
     * getIndexFromObj function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getIndexFromObj(value, arr) {
      // let arryOfValue;
      // arr.forEach(function(ele) {
      //   if (ele.value === value) {
      //     arryOfValue = ele;
      //     return arryOfValue;
      //   }
      // });
      // return arryOfValue;
      var arryOfValue = arr.find(function (ele) {
        return ele.value === value;
      });
      return arryOfValue;
    }
    /**
     * getPartnerType function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getPartnerType(partnerType, pospType) {
      if (partnerType === 'employee') {
        return 'Employee';
      }
      if (pospType === 'nonPosp') {
        return 'NON POSP';
      } else if (pospType === 'posp') {
        return 'POSP';
      } else {
        return 'NA';
      }
    }
    /**
     * exportPartnerData function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function exportPartnerData() {
      angular.element('#loaderDiv').show();
      PartnerService.bulkExport(ViewModel.tenant).then(function () {
        commonService.showToast(ViewModel.downloadSuccess);
      }).catch(function () {
        commonService.showToast(ViewModel.someErrorOccured);
      });
    }

    function applyFilterEligible() {
      initialParams.eligible = ViewModel.enrollmentStatus;
      $window.sessionStorage.setItem('eligible', ViewModel.enrollmentStatus);
      getPartner();
      $mdSelect.hide();
    }
    function applyFilterActive() {
      initialParams.accessAllowed = ViewModel.accessAllowed;
      $window.sessionStorage.setItem('accessAllowed', ViewModel.accessAllowed);
      getPartner();
      $mdSelect.hide();
    }

    function applyFilterCompleted() {
      initialParams.profileCompleted = ViewModel.profileCompleted;
      $window.sessionStorage.setItem('profileCompleted', ViewModel.profileCompleted);
      getPartner();
      $mdSelect.hide();
    }

    //  filter retention

    function filterRetention() {
      var accessAllowedFlag = $window.sessionStorage.getItem('accessAllowed') || null;
      var profileCompleted = $window.sessionStorage.getItem('profileCompleted') || null;
      var enrollmentStatusFlag = $window.sessionStorage.getItem('eligible') || null;
      var profileStatusFlag = $window.sessionStorage.getItem('profileStatus') || null;
      var qcStatusFlag = $window.sessionStorage.getItem('qcStatus') || null; // TASK(M30-4457)
      if (accessAllowedFlag !== null) {
        ViewModel.accessAllowed = $window.sessionStorage.getItem('accessAllowed');
      }
      if (profileCompleted !== null) {
        ViewModel.profileCompleted = $window.sessionStorage.getItem('profileCompleted');
      }
      if (enrollmentStatusFlag !== null) {
        ViewModel.enrollmentStatus = $window.sessionStorage.getItem('eligible');
      }
      if (profileStatusFlag !== null) {
        ViewModel.profileStatus = $window.sessionStorage.getItem('profileStatus');
      }
      // TASK(M30-4457) - QC Verification of a Profile
      if (qcStatusFlag !== null) {
        ViewModel.qcStatus = $window.sessionStorage.getItem('qcStatus');
      }
      // getTitle();
    }

    // titleFilter

    function getTitle() {
      LEAD_CONSTANTS.profileStatus.map(function (ele) {
        if (ViewModel.profileStatus === ele.value) {
          ViewModel.profileStatusLabel = ele.label;
        }
      });
    }

    function getPartnerProfileStatus(partnerFilterConfig) {
      if (partnerFilterConfig) {
        var role;
        for (role in partnerFilterConfig) {
          if (ViewModel.partnerRoles.includes(role)) {
            var profileStatus = partnerFilterConfig[role].profileStatus;
            for (status in profileStatus) {
              if (profileStatus[status].default) {
                $window.sessionStorage.setItem('profileStatus', profileStatus[status].value);
              }
            }
            return profileStatus;
          }
        }
      } else {
        return CONSTANTS.profileStatus;
      }
    }

    function getPartnerProfileCompleteStatus(partnerFilterConfig) {
      if (partnerFilterConfig) {
        var role;
        for (role in partnerFilterConfig) {
          if (ViewModel.partnerRoles.includes(role)) {
            var profileCompleted = partnerFilterConfig[role].profileCompleted;
            for (status in profileCompleted) {
              if (profileCompleted[status].default) {
                $window.sessionStorage.setItem('profileCompleted', profileCompleted[status].value);
              }
            }
            return profileCompleted;
          }
        }
      } else {
        return CONSTANTS.profileCompleteStatus;
      }
    }

    function callPartner($event, partner) {
      $event.stopPropagation();
      var eventData = {
        partnerName: partner.name,
        partnerMobile: partner.mobile,
        partnerEmail: partner.email,
        dpNo: partner.dpNo,
        dpId: partner.dpId
      };
      if (partner.leadOwner && partner.leadOwner.email) {
        eventData.ownerEmail = partner.leadOwner.email;
      }
      if (partner.mobile) {
        TrackingService.pushToGTMDatalayer('PARTNER_LEAD_CALL_CLICK', 'track_event', eventData);
        window.location.href = 'tel:+91-' + partner.mobile;
      }
    }

    function openFilterSheet() {
      TrackingService.pushToGTMDatalayer('PARTNER_LEAD_FILTER_MOBILE', 'track_event', {});
      $mdBottomSheet.show({
        templateUrl: 'dev/partner/templates/partner-lead-filter-mob.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false
      }).then(function () {}).catch(function () {});
    }

    function applyMobileFilter() {
      initialParams.tenant = ViewModel.tenant;
      initialParams.salesStatus = ViewModel.salesStatus.value;
      ViewModel.profileStatusLabel = ViewModel.profileStatus;
      initialParams.profileStatus = ViewModel.profileStatus.value;
      ViewModel.query.page = 1;
      $window.sessionStorage.setItem('profileStatus', ViewModel.profileStatus);
      getPartner();
      $mdBottomSheet.cancel();
    }

    function closeFilterSheet() {
      TrackingService.pushToGTMDatalayer('PARTNER_LEAD_FILTER_MOBILE_CLOSE', 'track_event', {});
      $mdBottomSheet.cancel();
    }

    function loadMorePartners() {
      ViewModel.query.page = ViewModel.query.page + 1;
      var deferred = $q.defer();
      ViewModel.loadingPromise = deferred.promise;
      ViewModel.loadMore.loading = true;
      var jsonData = getPartnerJSON();
      if (ViewModel.partnerLead) {
        $window.sessionStorage.setItem('profileStatus', jsonData.profileStatus);
        PartnerService.getPLM(jsonData).then(function (response) {
          ViewModel.searchPartnerList = undefined;
          var partner = response.data && response.data.data;
          if (!ViewModel.partnerList || ViewModel.partnerList && ViewModel.partnerList.length <= 0) {
            ViewModel.partnerList = partner.data;
          } else {
            var _ViewModel$partnerLis;

            (_ViewModel$partnerLis = ViewModel.partnerList).push.apply(_ViewModel$partnerLis, _toConsumableArray(partner.data));
          }
          ViewModel.loadMore.loading = false;
          if (ViewModel.partnerList && ViewModel.partnerList.length >= ViewModel.query.limit) {
            ViewModel.loadMore.show = true;
          } else {
            ViewModel.loadMore.show = false;
          }
          ViewModel.query.totalCount = partner.count;
          deferred.resolve(ViewModel.partnerList);
        }).catch(function (err) {
          ViewModel.loadMore.loading = false;
          deferred.reject(err);
        });
      } else {
        PartnerService.getPartner(jsonData).then(function (response) {
          ViewModel.searchPartnerList = undefined;
          var partner = response.data && response.data.data;
          if (!ViewModel.partnerList || ViewModel.partnerList && ViewModel.partnerList.length <= 0) {
            ViewModel.partnerList = partner.data;
          } else {
            var _ViewModel$partnerLis2;

            (_ViewModel$partnerLis2 = ViewModel.partnerList).push.apply(_ViewModel$partnerLis2, _toConsumableArray(partner.data));
          }
          ViewModel.loadMore.loading = false;
          if (ViewModel.partnerList && ViewModel.partnerList.length >= ViewModel.query.limit) {
            ViewModel.loadMore.show = true;
          } else {
            ViewModel.loadMore.show = false;
          }
          ViewModel.query.totalCount = partner.count;
          deferred.resolve(ViewModel.partnerList);
        }).catch(function (err) {
          ViewModel.loadMore.loading = false;
          deferred.reject(err);
        });
        PartnerService.searchBranch().then(function (res) {
          var _ref3 = res || {},
              data = _ref3.data;

          ViewModel.branchDataList = data;
          var setRegionList = new Set();
          data.map(function (ele) {
            setRegionList.add(ele.region);
          });
          ViewModel.regionDataList = [].concat(_toConsumableArray(setRegionList));
        }).catch(function (err) {
          deferred.reject(err);
        });
      }
      return deferred.promise;
    }

    // TASK(M30-4457) - display all the values in Dropdown
    function getPartnerQcStatus() {
      return CONSTANTS.qcStatusState;
    }
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  // Controller
  brokerAdmin.controller('partnerAddCtrl', partnerAddCtrl);

  // Dependency
  partnerAddCtrl.$inject = ['$scope', '$mdDialog', '$state', '$window', 'TrackingService', 'PartnerService', 'AuthService', 'data', 'branchDataList', 'CONSTANTS_LIST'];

  //Function
  function partnerAddCtrl($scope, $mdDialog, $state, $window, TrackingService, PartnerService, AuthService, data, branchDataList, CONSTANTS_LIST) {
    // var
    var LMS = ['partnerLead'].includes(data.modalInfo.type);
    $scope.addpartner = data.addpartner;
    $scope.addedpartner = data.addedpartner;
    $scope.unableToAdd = data.unableToAdd;
    $scope.modalInfo = data.modalInfo;
    $scope.organisationSelectionFn = onOrganisationSelection;
    $scope.mashreqlevelSelectionFn = onLevelSelection;
    $scope.designationSelectionFn = onDesignationSelectionFn;
    $scope.spTypeSelectionFn = onSPTypeSelectionFn;
    $scope.licenseEDChangeFn = onlicenseEDChangeFn;
    $scope.licenseSDChangeFn = onlicenseSDChangeFn;

    $scope.organisationsList = data.organisationsList;
    $scope.businessVerticalList = data.businessvertical;
    $scope.changeTitle = changeTitle;
    $scope.branchDataList = branchDataList;
    $scope.broker = sessionStorage.getItem('broker');
    $scope.partnerlevelList = [{
      level: 'Insurance Specialist',
      value: 'InsuranceSpecialist'
    }, {
      level: 'Area Manager',
      value: 'AreaManager'
    }, {
      level: 'Product Manager',
      value: 'ProductManager'
    }, {
      level: 'Insurance Head',
      value: 'InsuranceHead'
    }];
    $scope.mashreqpartnerlevel = undefined;
    $scope.dbsPartnerlevelList = CONSTANTS_LIST.DBS_PARTNER_LEVEL_LIST;
    $scope.dbspartnerlevel = undefined;

    $scope.dbsSPTypeList = CONSTANTS_LIST.DBS_SP_TYPES_LIST;
    // Cencle dialog
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    // Add Partner Form
    $scope.partner = {
      pospType: null,
      organisation: {
        value: data.tenant
      },
      partnerType: 'partner_level_1',
      broker: data.broker
    };
    if ($scope.broker === 'dbs') {
      $scope.partner.pospDetail = {};
    }

    function changeTitle() {
      if ($scope.partner.partnerType === 'employee') {
        $scope.modalInfo.title = 'Add new Employee';
        // PartnerService.searchBranch().then(res => {
        //   const { data } = res || {};
        //   console.log('RES VALUE', data);
        //   $scope.branchDataList = data;
        // });
        // console.log('Branch Data', $scope.branchData);
        // const { $$state } = $scope.branchData || {};
        // const { value } = $$state || {};
        // $scope.branchDataList = value;
        // console.log('Branch Data Value', $scope.branchDataList);
      } else {
        $scope.modalInfo.title = 'Add new Partner';
      }
    }
    function checkValidRoleForLeadMgmt(roles) {
      var rolesArray = [];
      if (roles && typeof roles === 'string') {
        rolesArray = roles.split(',');
      } else if (Array.isArray(roles)) {
        rolesArray = roles;
      }
      return rolesArray.includes('SALES') ? 'SALES' : null;
    }

    function CheckPartnerFormMashreq() {

      if ($scope.partner.userId === undefined) {
        var _ref = $scope.partnerForm || {},
            userId = _ref.userId;

        userId.$invalid = true;
        userId.$setDirty();
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($scope.partner.email)) {
        var _ref2 = $scope.partnerForm || {},
            email = _ref2.email;

        email.$invalid = true;
        email.$setDirty();
      }

      if ($scope.partner.name === undefined) {
        var _ref3 = $scope.partnerForm || {},
            name = _ref3.name;

        name.$invalid = true;
        name.$setDirty();
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($scope.partner.authEmailId) && $scope.partner.authEmailId !== undefined && $scope.partner.authEmailId !== '') {
        var _ref4 = $scope.partnerForm || {},
            authEmailId = _ref4.authEmailId;

        authEmailId.$invalid = true;
        authEmailId.$setDirty();
      }

      if ($scope.partner.authEmployeeName !== undefined && $scope.partner.authEmployeeName !== '') {
        if ($scope.partner.authEmailId === undefined && $scope.partner.authId === undefined || $scope.partner.authEmailId === '' && $scope.partner.authId === '' || $scope.partner.authEmailId === undefined && $scope.partner.authId === '' || $scope.partner.authEmailId === '' && $scope.partner.authId === undefined) {
          var _ref5 = $scope.partnerForm || {},
              _authEmailId = _ref5.authEmailId,
              authId = _ref5.authId;

          authId.$invalid = true;
          authId.$setDirty();
          $scope.partnerForm.$invalid = true;
          $scope.partnerForm.$valid = false;
        }
      }
    }
    $scope.addUpdatePartner = function () {
      var currentUser = AuthService.getUser();
      if ($scope.broker === 'mashreq') {
        CheckPartnerFormMashreq();
      } else if ($scope.broker === 'dbs') {
        if ($scope.dbspartnerlevel !== 'BancaCentralTeam') {
          var _ref6 = $scope.partnerForm || {},
              authId = _ref6.authId,
              authEmailId = _ref6.authEmailId,
              authEmployeeName = _ref6.authEmployeeName;

          if ($scope.partner.authId === undefined || $scope.partner.authId === '') {
            authId.$invalid = true;
            authId.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
          if ($scope.partner.authEmailId === undefined || $scope.partner.authEmailId === '') {
            authEmailId.$invalid = true;
            authEmailId.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
          if ($scope.partner.authEmployeeName === undefined || $scope.partner.authEmployeeName === '') {
            authEmployeeName.$invalid = true;
            authEmployeeName.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
        } else {
          var _ref7 = $scope.partnerForm || {},
              _authId = _ref7.authId,
              _authEmployeeName = _ref7.authEmployeeName,
              _authEmailId2 = _ref7.authEmailId;

          _authEmployeeName.$invalid = false;
          _authEmailId2.$invalid = false;
          _authId.$invalid = false;
        }

        if ($scope.dbspartnerlevel === 'TRM') {
          var _ref8 = $scope.partnerForm || {},
              licenseNumber = _ref8.licenseNumber,
              licenseStartDate = _ref8.licenseStartDate,
              dbsPOSPType = _ref8.dbsPOSPType,
              expiryDate = _ref8.expiryDate;

          window.console.log('partner:', $scope.partner);
          if ($scope.partner.pospDetail.licenseNumber === undefined || $scope.partner.pospDetail.licenseNumber === '') {
            licenseNumber.$invalid = true;
            licenseNumber.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
          window.console.log('partner pospDetails :', $scope.partner.pospDetail);
          window.console.log('partner pospDetails.licenseStartDate:', $scope.partner.pospDetail.licenseStartDate);
          if (!$scope.partner.pospDetail.licenseStartDate || $scope.partner.pospDetail.licenseStartDate === '') {
            licenseStartDate.$invalid = true;
            licenseStartDate.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
          if (!$scope.partner.pospDetail.pospType || $scope.partner.pospDetail.pospType === '') {
            dbsPOSPType.$invalid = true;
            dbsPOSPType.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
          window.console.log($scope.partner.pospDetail.expiryDate);
          if (!$scope.partner.pospDetail.expiryDate || $scope.partner.pospDetail.expiryDate === '') {
            expiryDate.$invalid = true;
            expiryDate.$setDirty();
            $scope.partnerForm.$invalid = true;
            $scope.partnerForm.$valid = false;
          }
        } else {
          var _ref9 = $scope.partnerForm || {},
              _licenseNumber = _ref9.licenseNumber,
              _licenseStartDate = _ref9.licenseStartDate,
              _dbsPOSPType = _ref9.dbsPOSPType,
              _expiryDate = _ref9.expiryDate;

          _licenseNumber.$invalid = false;
          _licenseStartDate.$invalid = false;
          _dbsPOSPType.$invalid = false;
          _expiryDate.$invalid = false;
        }
      } else {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test($scope.partner.email)) {
          var _ref10 = $scope.partnerForm || {},
              email = _ref10.email;

          email.$invalid = true;
          email.$setDirty();
          $scope.partnerForm.$invalid = true;
        }
      }
      $scope.partnerJSON = {
        tenantUniqueCode: $scope.partner.userId,
        name: $scope.partner.name,
        email: $scope.partner.email,
        mobile: $scope.partner.phone,
        organisation: data.tenant,
        authorityEmailId: $scope.partner.authEmailId,
        authorityId: $scope.partner.authId,
        authorityName: $scope.partner.authEmployeeName,
        partnerType: sessionStorage.getItem('broker') === 'mashreq' ? $scope.mashreqpartnerType : $scope.partner.partnerType,
        role: checkValidRoleForLeadMgmt(data.roles)
      };
      if (['dbs'].includes(sessionStorage.getItem('broker'))) {
        if ($scope.dbspartnerlevel === 'TRM' || ![null, '', undefined].includes($scope.partner.pospDetail.licenseNumber)) {
          $scope.partnerJSON.pospDetail = $scope.partner.pospDetail || null;
          $scope.partnerJSON.pospDetail.licenseStartDate = $scope.partner.licenseStartDate || null;
          $scope.partnerJSON.pospDetail.expiryDate = $scope.partner.expiryDate || null;
          $scope.partnerJSON.pospType = $scope.partner.pospType || null;
          if ($scope.partnerJSON.pospDetail) {
            $scope.partnerJSON.pospDetail.licenseStartDate = $scope.partner.pospDetail.licenseStartDate ? moment($scope.partner.pospDetail.licenseStartDate).format('x') : null;
            $scope.partnerJSON.pospDetail.expiryDate = $scope.partner.pospDetail.expiryDate ? moment($scope.partner.pospDetail.expiryDate).format('x') : null;
          }
        } else {
          $scope.partnerJSON.pospDetail = null;
        }
      }

      if ($scope.partner.partnerType === 'employee') {
        $scope.partnerJSON.businessVertical = $scope.partner.businessVertical && $scope.partner.businessVertical.value;
        // NOTE: Branch Data

        var _ref11 = $scope.partner.branchLocation || {},
            city = _ref11.city,
            _id = _ref11._id,
            state = _ref11.state,
            region = _ref11.region,
            name = _ref11.name;

        $window.console.log('Branch Location', $scope.partner.branchLocation);
        $scope.partnerJSON.branch = city;
        $scope.partnerJSON.branchCode = _id;
        $scope.partnerJSON.branchState = state;
        $scope.partnerJSON.region = region;
        $scope.partnerJSON.branchName = name;
      } else if (['dbs'].includes(sessionStorage.getItem('broker'))) {
        $scope.partnerJSON.branchCode = $scope.partner.branchCode;
      }
      $scope.partnerJSON.acquisitionOwner = {
        name: currentUser.username,
        email: currentUser.email,
        mobile: currentUser.mobileNo
      };
      if ($scope.partnerForm.$invalid) {
        var _ref12 = $scope.partnerForm || {},
            _email = _ref12.email,
            phone = _ref12.phone,
            _name = _ref12.name,
            vertical = _ref12.vertical,
            branch = _ref12.branch;

        _email ? _email.$setDirty() : null;
        phone ? phone.$setDirty() : null;
        _name ? _name.$setDirty() : null;
        vertical ? vertical.$setDirty() : null;
        vertical ? vertical.$setTouched() : null;
        branch ? branch.$setDirty() : null;
        branch ? branch.$setTouched() : null;

        // $scope.partnerForm.email.$setDirty();
        // $scope.partnerForm.phone.$setDirty();
        // $scope.partnerForm.name.$setDirty();
        // $scope.partnerForm.vertical.$setDirty();
        // $scope.partnerForm.vertical.$setTouched();
      } else {
        if (LMS) {
          $scope.partnerJSON.source = 'partnerlead';
          $scope.partnerJSON.leadOwner = {
            name: currentUser.username,
            email: currentUser.email,
            mobile: currentUser.mobileNo
          };
        } else {
          $scope.partnerJSON.source = 'admin';
          $scope.partnerJSON.associatePartnerId = data.associateNewPartner;
          $scope.partnerJSON.documentNews = [{
            type: 'pan_card',
            documentNumber: $scope.partner.pan ? $scope.partner.pan : null
          }];
        }
        PartnerService.addPartner($scope.partnerJSON, { tenant: data.tenant }).then(function (response) {
          var data = response;
          if (data.status === 200 && data.data.meta.statusCode !== 409) {
            $scope.partnerJSON = data.data.data;
            $scope.addpartner = false;
            $scope.addedpartner = true;
            if ($scope.modalInfo.type === 'associate' && !LMS) {
              $scope.modalInfo.title = 'Associate partner added';
            } else if (LMS && $scope.modalInfo.type !== 'associate') {
              $scope.modalInfo.title = 'Lead added';
            } else if ($scope.partner.partnerType === 'employee') {
              $scope.modalInfo.title = 'Employee added';
            } else {
              $scope.modalInfo.title = 'Partner added';
            }
            TrackingService.pushToGTMDatalayer('PARTNER_LEAD_CREATE_SAVE', 'track_event', {});
          } else if (data.data.meta.statusCode === 409) {
            $scope.partner409JSON = response.data.data[0];
            $scope.partnerJSON.id = $scope.partner409JSON.id;
            if ($scope.partner409JSON.mobile === $scope.partnerJSON.mobile) {
              $scope.errorName = 'Mobile';
            } else if ($scope.partner409JSON.email === $scope.partnerJSON.email) {
              $scope.errorName = 'Email';
            } else if ($scope.partner409JSON.PAGENUMBER === $scope.partnerJSON.PAGENUMBER) {
              $scope.errorName = 'PAN';
            } else {
              $window.console.log('Else');
            }
            $scope.addpartner = false;
            $scope.addedpartner = false;
            $scope.unableToAdd = true;
            $scope.modalInfo = {
              title: 'Unable to add partner'
            };
          } else {
            $window.console.log('Error');
          }
        }).catch(function (response) {
          var _ref13 = response.data || {},
              data = _ref13.data,
              meta = _ref13.meta;

          var _ref14 = meta || {},
              error = _ref14.error,
              msg = _ref14.msg;

          $scope.resError = error;
          $scope.resMsg = msg;
          $scope.partner409JSON = data[0];
          $scope.partnerJSON.id = $scope.partner409JSON.id;
          if ($scope.partner409JSON.mobile === $scope.partnerJSON.mobile) {
            $scope.errorName = 'Mobile';
          } else if ($scope.partner409JSON.email === $scope.partnerJSON.email) {
            $scope.errorName = 'Email';
          } else if ($scope.partner409JSON.PAGENUMBER === $scope.partnerJSON.PAGENUMBER) {
            $scope.errorName = 'PAN';
          } else {
            $window.console.log('Else');
          }
          $scope.addpartner = false;
          $scope.addedpartner = false;
          $scope.unableToAdd = true;
          $scope.modalInfo = {
            title: $scope.partner.partnerType === 'employee' ? 'Unable to add Employee' : 'Unable to add Partner'
          };
        });
      }
    };

    // Go To Details page each ID
    $scope.goToPage = function () {
      var res = {};
      if (LMS) {
        res.state = 'partner-lead-detail';
        res.eventName = 'PARTNER_LEAD_CREATE_CONTINUE_PROFILE';
        res.params = { tenant: data.tenant, id: $scope.partnerJSON.id };
        res.action = 'REDIRECT';
      } else {
        res.state = 'partner-detail';
        res.eventName = 'PARTNER_CREATE_CONTINUE_PROFILE';
        res.params = { tenant: data.tenant, id: $scope.partnerJSON.id };
        res.action = 'REDIRECT';
      }
      $mdDialog.hide(res);
    };
    // onOrganisationSelection
    function onOrganisationSelection() {
      data.tenant = $scope.partner.organisation.value;
    }

    //For mashreq broker level selection
    function onLevelSelection() {
      $scope.mashreqpartnerType = this.mashreqpartnerlevel;
    }

    function onDesignationSelectionFn() {
      $scope.partner.partnerType = this.dbspartnerlevel;
      $scope.dbspartnerlevel = this.dbspartnerlevel;
    }

    function onSPTypeSelectionFn() {
      $scope.partner.pospDetail.pospType = this.dbsPOSPType;
      $scope.partner.pospType = this.dbsPOSPType;
    }
    function onlicenseEDChangeFn() {
      $scope.partner.expiryDate = $scope.partner.pospDetail.expiryDate;
    }
    function onlicenseSDChangeFn() {
      $scope.partner.licenseStartDate = $scope.partner.pospDetail.licenseStartDate;
    }
    // Back to home
    $scope.backToHome = function () {
      TrackingService.pushToGTMDatalayer('PARTNER_LEAD_CREATE_BACK_HOME', 'track_event', {});
      $state.reload();
      $mdDialog.cancel();
    };
    $scope.close = function () {
      TrackingService.pushToGTMDatalayer('PARTNER_LEAD_CREATE_CANCEL', 'track_event', {});
      $mdDialog.cancel();
    };
  }
})();
'use strict';

//jshint esversion:6
(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  // Controller
  brokerAdmin.controller('addBulkUploadCtrl', addBulkUploadCtrl);

  // Dependency
  addBulkUploadCtrl.$inject = ['$scope', '$rootScope', '$window', 'GENERAL_CONFIG', '$mdDialog', 'FileUploader', 'commonService', '$cookies', 'data'];

  function addBulkUploadCtrl($scope, $rootScope, $window, GENERAL_CONFIG, $mdDialog, FileUploader, commonService, $cookies, data) {
    $scope.templateInfo = data.templateInfo;
    $scope.tenant = data.tenant || sessionStorage.getItem('tenant');
    $scope.broker = sessionStorage.getItem('broker');
    $scope.organisationsList = data.organisationsList, $rootScope.cardData = data.cardData;
    $rootScope.showProgressCard = false;
    $scope.uploadModelType = $scope.templateInfo && $scope.templateInfo.action === 'create' ? 'create' : 'update';
    $scope.organisation = getOrganisation(data);

    function getOrganisation(data) {
      if (data.organisationsList.length === 1) {
        return data.organisationsList[0];
      } else {
        return null;
      }
    }
    // $rootScope.setUserDetail = data.setUserDetail;
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    $scope.cancelFile = function (item) {
      $scope.selectFile = false;
      item.remove();
      $scope.name = '';
    };
    $scope.organisationBulkFn = function () {
      $scope.tenant = $scope.organisation.value;
    };

    $scope.downloadTemplate = function (modelType) {
      var partnerObj = {};
      if (modelType === 'create') {
        partnerObj.process = 'create';
        partnerObj.tenant = $scope.tenant;
      } else {
        partnerObj.process = 'update';
        partnerObj.tenant = $scope.tenant;
      }
      var postParams = {
        broker: sessionStorage.getItem('broker')
      };

      var url = '/mintpro/download';
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + url, '', partnerObj, 'POST', false, postParams).then(function (response) {
        if (response.status === 200) {
          var headers = response.headers();
          var content = headers['content-disposition'];
          var fileContent = content.substring(content.lastIndexOf('=') + 2, content.lastIndexOf('.') + 4);
          var blob = new Blob([response.data], { type: 'text/csv' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileContent;
          document.body.appendChild(link);
          link.click();
          commonService.showToast('File downloaded Successfully');
        }
      }).catch(function (error) {
        commonService.showToast(error.data && error.data.message);
      });
    };

    $scope.downloadInstructions = function () {
      var partnerObj = {};
      partnerObj.tenant = $scope.tenant;
      var postParams = {
        broker: sessionStorage.getItem('broker')
      };

      var url = '/mintpro/downloadInstructions';
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + url, '', partnerObj, 'POST', false, postParams).then(function (response) {
        if (response.status === 200) {
          var headers = response.headers();
          var content = headers['content-disposition'];
          var fileContent = content.substring(content.lastIndexOf('=') + 2, content.lastIndexOf('.') + 4);
          var blob = new Blob([response.data], { type: 'text/csv' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileContent;
          document.body.appendChild(link);
          link.click();
          commonService.showToast('File downloaded Successfully');
        }
      }).catch(function (error) {
        commonService.showToast(error.data && error.data.message);
      });
    };

    $scope.addBulkPartners = function () {
      uploader.queue[0].url = GENERAL_CONFIG.serviceURL + '/mintproform/v1/partners/bulk/' + $scope.uploadModelType + '?broker=' + sessionStorage.getItem('broker') + '&tenant=' + $scope.tenant;
      uploader.queue[0].formData.push({
        file: $scope.name,
        ninjaUserEmail: atob(localStorage.getItem('encEmail'))
      });

      $scope.uploader.queue[0].headers = {
        Authorization: atob(localStorage.getItem('jwtToken'))
      };

      uploader.queue[0].upload();
      $mdDialog.cancel();
    };

    /*=== Reset Import File Queue ===*/
    var uploader = $scope.uploader = new FileUploader({
      queueLimit: 1,
      removeAfterUpload: true
    });

    /*=== Validate CSV File ===*/
    uploader.filters.push({
      name: 'syncFilter',
      fn: function fn(item) {
        var fileName = item.name;
        var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (ext === 'CSV' || ext === 'csv') {
          $scope.selectFile = true;
          $scope.name = item.name;
          return true;
        } else {
          $window.alert('Please upload the file in csv format');
          return false;
        }
        //return this.queue.length < 1;
      }
    });
    uploader.onErrorItem = function (fileItem, response) {
      commonService.showToast(response.message);
    };
    uploader.onSuccessItem = function (fileItem, response) {
      commonService.showToast(response.message);
    };
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.partner').controller('PartnerAddUpdateCtrl', controller);
  controller.$inject = ['$scope', '$stateParams', 'partner', 'config', 'pageType', 'commonService', 'GENERAL_CONFIG', '$state', '$window', 'FileUploader', 'PartnerService', '$mdDialog', '_', 'PartnerAddUpdateService', 'UtilService', '$sce', 'MasterFileService', '$q', 'AuthService', 'LEAD_CONSTANTS', 'CONSTANTS_LIST', 'TrackingService', 'PartnerFormConfigService', '$mdBottomSheet'];
  /**
   *
   * PartnerAddUpdateCtrl Controller
   *
   * @public
   *
   * @author    sumantaparida
   *
   * @param     {Object}    $scope                       Angular scope
   * @param     {Object}    $state                       Angular State service
   * @param     {Object}    commonService                commonService instance
   * @param     {Object}    FileUploader                fileUploader instance
   * @param     {Object}    PartnerService             PartnerService instance
   *
   */
  function controller($scope, $stateParams, partner, config, pageType, commonService, GENERAL_CONFIG, $state, $window, FileUploader, PartnerService, $mdDialog, _, PartnerAddUpdateService, UtilService, $sce, MasterFileService, $q, AuthService, LEAD_CONSTANTS, CONSTANTS_LIST, TrackingService, PartnerFormConfigService, $mdBottomSheet) {
    var ViewModel = this;
    var CONSTANTS = angular.copy(LEAD_CONSTANTS);
    var tenant = $stateParams.tenant;
    var getPartnerFormConfig = PartnerFormConfigService.getPartnerFormConfig;
    var getFormConfigReqObj = PartnerAddUpdateService.getFormConfigReqObj,
        updateCheckoutConfig = PartnerAddUpdateService.updateCheckoutConfig;

    ViewModel.compliantEnabled = sessionStorage.getItem('compliantEnabled');
    ViewModel.flex = 100;
    ViewModel.showDocuments = false;
    ViewModel.pdData = {};
    ViewModel.pospInsurerDetails = [];
    ViewModel.pdData.pospDetail = {};
    ViewModel.certificateDetails = [];
    // POSP Related
    ViewModel.getPospGIList = {};
    ViewModel.getPospLIList = {};
    ViewModel.typeOfPospList = {};
    ViewModel.pospSP = {};
    ViewModel.pospBQP = {};
    ViewModel.posp = {};
    ViewModel.spLI = {};
    ViewModel.spCOMPOSITE = {};
    ViewModel.spGI = {};
    ViewModel.adhChecked, ViewModel.panChecked,
    // POSP Related End
    ViewModel.pdData.pospDetail.assignAsParent = false, ViewModel.deActivateProfileBtn = false;
    ViewModel.skipValidation = false;
    ViewModel.prepaidExpiry = new Date();
    ViewModel.lastWorkingDate = moment(ViewModel.lastWorkingDate).subtract(18, 'years').toDate();
    ViewModel.dateOfBirth = moment(ViewModel.dateOfBirth).subtract(18, 'years').toDate();
    ViewModel.maxDate = moment(new Date()).subtract(18, 'years').toDate();
    var uploader = ViewModel.uploader = new FileUploader({
      url: GENERAL_CONFIG.serviceURL + '/mintprodoc/v1/partners/' + $stateParams.id + '/document?broker=' + sessionStorage.getItem('broker') + '&tenant=' + tenant
    });
    uploader.queueLimit = 8;
    ViewModel.viewDocuments = false;
    ViewModel.broker = sessionStorage.getItem('broker');
    ViewModel.isMashreq = ViewModel.broker === CONSTANTS_LIST.BROKER.MASHREQ;
    ViewModel.isInsuranceHead = false;
    ViewModel.partnerLead = ['PARTNER_LEAD_REVIEW'].includes(pageType);
    ViewModel.searchPartner = searchPartner;
    ViewModel.uploadFile = uploadFile;
    ViewModel.deleteThisFile = deleteThisFile;
    ViewModel.downloadThisFile = downloadThisFile;
    ViewModel.viewFile = viewFile;
    ViewModel.closeViewMode = closeViewMode;
    ViewModel.rotateImg = rotateImg;
    ViewModel.onReportToSelection = onReportToSelection;
    ViewModel.onAssignToSelection = onAssignToSelection;
    ViewModel.searchEmployee = searchEmployee;
    ViewModel.partnerTypeFun = partnerTypeFun;
    ViewModel.businessChannelFun = businessChannelFun;
    // ViewModel.pospSelectionChange = pospSelectionChange;
    ViewModel.elitePlanChange = elitePlanChange;
    ViewModel.splitPayoutChange = splitPayoutChange;
    ViewModel.getPartnerDetails = getPartnerDetails;
    ViewModel.savePartner = savePartner;
    ViewModel.onAssignToL1Selection = onAssignToL1Selection;
    // ViewModel.onCalenderSelection = onCalenderSelection;
    ViewModel.onDOBSelection = onDOBSelection;
    ViewModel.onLastWorkingDaySelection = onLastWorkingDaySelection;
    ViewModel.onPrepaidExpirySelection = onPrepaidExpirySelection;
    ViewModel.onAssociatedPOSPSelection = onAssociatedPOSPSelection;
    // ViewModel.assignAsParentSelectionChange = assignAsParentSelectionChange;
    ViewModel.onChangeBranchlocation = onChangeBranchlocation;
    ViewModel.searchBranch = searchBranch;
    ViewModel.addPospInsurar = addPospInsurar;
    ViewModel.changeAssociate = changeAssociate;
    ViewModel.addAssociate = addAssociate;
    ViewModel.deletePospPrompt = deletePospPrompt;
    ViewModel.someErrorOccured = 'Some Error Ocurred';
    ViewModel.successSaved = 'successfully saved';
    ViewModel.downloadSuccess = 'Download successfully';
    ViewModel.deleteSuccess = 'Deleted successfully';
    ViewModel.uploadSuccess = 'Uploaded successfully';
    ViewModel.generateNOCPopUp = generateNOCPopUp;
    ViewModel.changeState = false;
    ViewModel.getIndexFromObj = getIndexFromObj;
    ViewModel.checkIfRequired = checkIfRequired;
    ViewModel.partnerRoleFlag = true;
    ViewModel.personalInfo = PartnerAddUpdateService.getPersonalInfo();
    ViewModel.address = PartnerAddUpdateService.getAddress();
    ViewModel.bankdetails = PartnerAddUpdateService.getBankDetails();
    ViewModel.getbankVerifier = PartnerAddUpdateService.getBankVerifier();
    ViewModel.pospDocuments = PartnerAddUpdateService.getDocuments();
    ViewModel.nomineeBankDetails = PartnerAddUpdateService.getNomineeBankDetails();
    ViewModel.nomineeDocuments = PartnerAddUpdateService.getNomineeDocuments();
    ViewModel.additionalprofile = PartnerAddUpdateService.getAdditionalInfo();
    ViewModel.licenceHolding = PartnerAddUpdateService.getLicHolding();
    ViewModel.getassociatedIRDAI = PartnerAddUpdateService.getAssociatedIRDAI();
    ViewModel.enrollmentProcess = PartnerAddUpdateService.getEnrollmentProcess();
    ViewModel.eligibleFor = PartnerAddUpdateService.getEligibleFor();
    ViewModel.deactivateProfile = PartnerAddUpdateService.getDeactivateProfile();
    ViewModel.partnerQc = PartnerAddUpdateService.getPartnerQc(); // TASK(M30-4457)
    ViewModel.getLicensingDetails = PartnerAddUpdateService.getLicensingDetails(ViewModel.partnerLead);
    ViewModel.getTypeOfPosp = PartnerAddUpdateService.getTypeOfPosp();
    ViewModel.getPospGI = PartnerAddUpdateService.getPospGI();
    ViewModel.getPospLI = PartnerAddUpdateService.getPospLI();
    ViewModel.getSpLI = PartnerAddUpdateService.getSpLI();
    ViewModel.getSpCOMPOSITE = PartnerAddUpdateService.getSpCOMPOSITE();
    ViewModel.getSpGI = PartnerAddUpdateService.getSpGI();
    ViewModel.getPospSP = PartnerAddUpdateService.getPospSP();
    ViewModel.getPospBQP = PartnerAddUpdateService.getPospBQP();
    ViewModel.getSpLI = PartnerAddUpdateService.getSpLI();
    ViewModel.getSpCOMPOSITE = PartnerAddUpdateService.getSpCOMPOSITE();
    ViewModel.getSpGI = PartnerAddUpdateService.getSpGI();
    ViewModel.getPosp = PartnerAddUpdateService.getPosp();
    ViewModel.associateFor = PartnerAddUpdateService.getAssociate();
    ViewModel.selectAssociate = PartnerAddUpdateService.getSelectAssociate();
    ViewModel.leadTracking = PartnerAddUpdateService.getLeadTracking();
    ViewModel.Remarks = PartnerAddUpdateService.getRemarks();
    ViewModel.partnerVerifier = PartnerAddUpdateService.getPartnerVerifier();
    ViewModel.getTrainingDetails = PartnerAddUpdateService.getTrainingDetails();
    ViewModel.documentOwner = PartnerAddUpdateService.getDocumentOwner();
    ViewModel.addNewActivity = addNewActivity;
    ViewModel.showActivity = showActivity;
    ViewModel.gotoPartnerReview = gotoPartnerReview;
    ViewModel.fieldFocus = fieldFocus;
    ViewModel.viLoader = false;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.hasIRDA = false;
    var partnerRoles;
    try {
      partnerRoles = atob($window.localStorage.getItem('roles'));
    } catch (err) {
      partnerRoles = $window.localStorage.getItem('roles');
    }

    ViewModel.partnerRoles = partnerRoles || null;
    var initialParams = {};
    ViewModel.editable = {};
    CONSTANTS.query.limit = 5;
    ViewModel.query = CONSTANTS.query;
    init(partner, config);
    // NOTE: Bank Details Constant => Added by sumanta
    ViewModel.validateBankDetails = _validateBankDetailsFun;
    ViewModel.activityLogOrder = _getActivityLogOrderFn();
    ViewModel.pospCodeOrder = _getpospCodeOrderFn();
    ViewModel.pospDetailsOrder = _getpospDetailsOrderFn();
    ViewModel.configRole = _getConfigRole();
    ViewModel.isPartnerLead = ViewModel.configRole === 'USER';
    ViewModel.isRoleChecker = _getCheckerRole();
    ViewModel._pincodeValidate = _pincodeValidate;
    ViewModel.ippb = ['ippb'].includes(ViewModel.broker);
    ViewModel.openSectionNav = openSectionNav;
    ViewModel.closeBottomSheet = closeBottomSheet;
    ViewModel.toggleShowDocuments = toggleShowDocuments;
    /*=============================================
    =            Implementation                  =
    =============================================*/

    $scope.$on('duScrollspy:becameActive', function () {
      if (angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
      }
    });

    // NOTE: pincode implementation
    function _pincodeValidate() {
      var pincode = ViewModel.pdData.address.pincode;
      if (pincode) {
        var promise = commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + '/pinCode', '/' + pincode, {}, 'GET');
        promise.then(function (res) {
          var _ref = res.data || {},
              data = _ref.data;

          var _ref2 = data || {},
              city = _ref2.city,
              state = _ref2.state;

          ViewModel.pdData.address.city = city;
          ViewModel.pdData.address.state = state;
        }).catch(function (err) {
          var element = document.getElementById('pincode');
          element.classList.add('ng-invalid');
        });
      }
    }

    /**
     * setDirtyField function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function setDirtyField() {
      // angular.forEach(ViewModel.partnerDetailsForm.$error.required, function(field) {
      //   field.$setDirty();
      // });
      angular.element('.ng-invalid').parent().addClass('md-input-invalid');
      angular.element('md-datepicker.ng-invalid').addClass('ng-dirty');
    }
    /**
     * checkIfRequired function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function checkIfRequired(arrObj) {
      arrObj = arrObj || {};
      var required = false;
      _.forIn(arrObj, function (value) {
        if (value) {
          required = true;
        }
      });
      return required;
    }
    /**
     * hideLoader function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function hideLoader() {
      angular.element('#loaderDiv').hide();
    }
    /**
     * showLoader function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function showLoader() {
      angular.element('#loaderDiv').show();
    }
    /**
     * initializeDocumentNews function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function initializeDocumentNews() {
      var requiredInputField = ViewModel.documentsData.filter(function (ele) {
        return ele.display;
      }).map(function (ele) {
        return ele.name;
      });
      requiredInputField.forEach(function (ele) {
        var found = false;
        for (var i = 0; i < ViewModel.pdData.documentNews.length; i++) {
          if (ele === ViewModel.pdData.documentNews[i].type) {
            found = true;
          }
        }
        if (!found) {
          ViewModel.pdData.documentNews.push({ type: ele });
        }
      });
    }
    /**
     * intializeForm function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     * // ANCHOR: Intialize Form
     *
     */
    function intializeForm(partner, data) {
      var partnerObj = angular.copy(data);
      var documentArr = [].concat(_toConsumableArray(partnerObj.documents));
      var uploadDocFlag = 0,
          indexOfPass = -1,
          indexOfCheq = -1;
      for (var i = 0; i < documentArr.length; i++) {
        var obj = documentArr[i];
        if (obj.type === 'bank_proof' && obj.name === 'passbook' && obj.displayName !== 'Cancelled cheque/passbook') {
          documentArr.splice(i, 1);
          indexOfPass = i;
        }
        if (obj.type === 'bank_proof' && obj.name === 'cancelled_cheque') {
          obj.displayName = 'Cancelled cheque/passbook';
          obj.name = 'passbook';
          if (!obj.uploadedDoc) {
            uploadDocFlag = 1;
            indexOfCheq = i;
          }
        }
      }
      if (uploadDocFlag) {
        documentArr[indexOfCheq].uploadedDoc = documentArr[indexOfPass].uploadedDoc;
      }
      partnerObj.documents = supplyDocsWRTConfig(documentArr);
      var configData = partnerObj;
      var dropDowns = partnerObj.dropDowns;
      var businesschannel = dropDowns.businesschannel;

      if (ViewModel.broker === 'hdfcbank') {
        var hdfcDoc = angular.copy(configData.documents);
        for (var i = 0; i < hdfcDoc.length; i++) {
          if (hdfcDoc[i].name === 'adhaar_card') {
            hdfcDoc.splice(i, 1);
          }
          if (hdfcDoc[i].name === 'back_adhaar_card') {
            hdfcDoc.splice(i, 1);
          }
          if (hdfcDoc[i].name === 'passbook') {
            hdfcDoc.splice(i, 1);
          }
        }
        ViewModel.documentsData = hdfcDoc;
      } else {
        ViewModel.documentsData = configData.documents;
        // console.log('ViewModel.documentsData', ViewModel.documentsData);
      }
      ViewModel.today = new Date();
      ViewModel.validationFlag = false;
      ViewModel.editable = true;
      // ViewModel.documentsData = configData.documents;
      ViewModel.designationsList = configData.designations;
      ViewModel.genderList = configData.genders;
      ViewModel.pospTypesList = configData.pospTypes;
      ViewModel.partnerTypesList = configData.partnerTypes;
      ViewModel.organisationsList = configData.organisations;
      ViewModel.businessTurnover = configData.dropDowns && configData.dropDowns.businessTurnover;
      ViewModel.educationQualifications = configData.dropDowns && configData.dropDowns.educationQualifications;
      ViewModel.insuranceExperience = configData.dropDowns && configData.dropDowns.insuranceExperience;
      ViewModel.occupations = configData.dropDowns && configData.dropDowns.occupations;
      ViewModel.officeSpace = configData.dropDowns && configData.dropDowns.officeSpace;
      ViewModel.relationship = configData.dropDowns && configData.dropDowns.relationship;
      ViewModel.monthlyHealthPremium = configData.dropDowns && configData.dropDowns.monthlyHealthPremium;
      ViewModel.monthlyLifePremium = configData.dropDowns && configData.dropDowns.monthlyLifePremium;
      ViewModel.monthlyMotorPremium = configData.dropDowns && configData.dropDowns.monthlyMotorPremium;
      ViewModel.primarySourceOfIncome = configData.dropDowns && configData.dropDowns.primarySourceOfIncome;
      ViewModel.life_insurers_list = configData.dropDowns && configData.dropDowns.life_insurers_list;
      ViewModel.general_insurers_list = configData.dropDowns && configData.dropDowns.general_insurers_list;
      // ViewModel.branchData =
      //   configData.dropDowns && configData.dropDowns.branchData;
      ViewModel.branchData = PartnerService.searchBranch().then(function (res) {
        var _ref3 = res || {},
            data = _ref3.data;

        return data;
      });
      ViewModel.rejectionReason = configData.dropDowns && configData.dropDowns.rejectionReason;
      ViewModel.dpType = configData.dpTypes;
      ViewModel.businessChannelListConfig = businesschannel || [];
      ViewModel.assignAsParentList = [{
        displayName: 'No',
        value: false,
        rank: 1
      }, {
        displayName: 'Yes',
        value: true,
        rank: 2
      }];
      ViewModel.elitePlanList = [{ displayName: 'Regular', value: 'REGULAR', rank: 1 }, { displayName: 'Elite', value: 'ELITE', rank: 2 }];
      ViewModel.splitPayoutList = [{ vertical: 'tw', displayName: '0 %', value: 0, rank: 1 }, { vertical: 'tw', displayName: '15 %', value: 15, rank: 2 }];
      ViewModel.allowAssociatePartner = configData.allowAssociatePartner;
      ViewModel.allowSubscriptionPlan = configData.allowSubscriptionPlan;
      // TODO: POSP Freez || Unfreez
      var SALES_ROLE = partnerRoles ? partnerRoles.split(',').includes('SALES') : null;
      var pospUpdate = ViewModel.hasPermission('partner-lead-posp-update') && partner.status !== 'verified';
      ViewModel.hiddenPOSP = SALES_ROLE ? pospUpdate : true;
      // For Check Owner Then permision for edit form
      var currentUser = AuthService.getUser();
      var leadOwner = partner.leadOwner || {};
      var owner = currentUser.email === leadOwner.email;
      if (ViewModel.partnerLead) {
        if (owner || ViewModel.hasPermission('partner-lead-direct-update')) {
          ViewModel.editable = true;
        } else {
          ViewModel.editable = false;
          // ViewModel.hiddenPOSP = false
          ViewModel.deActivateProfileBtn = true;
        }
        ViewModel.updatePermission = ViewModel.hasPermission('partner-lead-direct-update') ? 'partner-lead-direct-update' : 'partner-lead-update';
        ViewModel.filePermissions = {
          upload: ViewModel.hasPermission('partner-lead-file-upload'),
          download: ViewModel.hasPermission('partner-lead-file-download'),
          view: ViewModel.hasPermission('partner-lead-file-view'),
          delete: ViewModel.hasPermission('partner-lead-file-delete')
        };
      } else {
        if (ViewModel.hasPermission('partner-update')) {
          ViewModel.editable = true;
        } else {
          ViewModel.editable = false;
        }
        ViewModel.updatePermission = 'partner-update';
        ViewModel.filePermissions = {
          upload: ViewModel.hasPermission('partner-file-upload'),
          download: ViewModel.hasPermission('partner-file-download'),
          view: ViewModel.hasPermission('partner-file-view'),
          delete: ViewModel.hasPermission('partner-file-delete')
        };
      }
      // Task(M30-4577): disable input fields post verification in PLM
      if (ViewModel.partnerLead && partner.status === 'verified' && ViewModel.broker === 'turtlemint') {
        ViewModel.editable = false;
      }

      ViewModel.filePermissions.options = ViewModel.filePermissions.view || ViewModel.filePermissions.download || ViewModel.filePermissions.upload || ViewModel.filePermissions.delete;
    }
    /**
     * searchPartner function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function searchPartner(val, isPOSP) {
      var value = ViewModel.partnerTypeSelection.value;

      var isBrokerTurtlemint = ['turtlemint'].includes(ViewModel.broker);
      var partnerType = !isBrokerTurtlemint ? ['partner_level_2'].includes(value) ? 'partner_level_1' : undefined : 'all_verified';
      if (val && val.length > 2) {
        return PartnerService.searchPartner(val, isPOSP, tenant, undefined, partnerType).then(extractPartnersFromResponse).catch(function (err) {
          return $window.console.error(err);
        });
      }
    }

    function checkForConfigService(pdData, config) {
      var configRole = _getConfigRole();
      var reqObj = {
        broker: ViewModel.broker,
        tenant: ViewModel.tenant,
        formIntent: configRole,
        vertical: 'NINJA'
      };
      var reqFormConfigObj = getFormConfigReqObj(reqObj);
      var promise = getPartnerFormConfig(reqFormConfigObj);
      promise.then(function (data) {
        if (data) {
          updateCheckoutConfig(data, config, partnerRoles, ViewModel.broker, configRole);
        }
      }).catch(function (err) {
        return window.console.error(err);
      });
    }

    function _getActivityLogOrderFn() {
      if (ViewModel.partnerLead) {
        return 1;
      } else {
        return 20;
      }
    }

    function _getpospCodeOrderFn() {
      if (ViewModel.broker === 'hdfcbank' || ViewModel.tenant === 'bandhanbank') {
        return 2;
      } else if (ViewModel.tenant === 'tataaig' || ViewModel.tenant === 'axisbank') {
        return 3;
      } else {
        return 5;
      }
    }

    function _getpospDetailsOrderFn() {
      if (ViewModel.partnerLead) {
        return 1;
      } else {
        return 0;
      }
    }

    function _getConfigRole() {
      var configRole = ['PARTNER-MAKER', 'PARTNER-CHECKER', 'UAM-ADMIN', 'MASHREQ-ADMIN', 'FEDERAL-ADMIN'];
      if (configRole.some(function (el) {
        return ViewModel.partnerRoles.includes(el);
      })) {
        return 'PARTNER';
      } else {
        return 'USER';
      }
    }

    function _getCheckerRole() {
      var checkerRole = ['PARTNER-CHECKER', 'USER-CHECKER'];

      if (checkerRole.some(function (el) {
        return partnerRoles.includes(el);
      })) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function init(partner, config) {
      intializeForm(partner, config);
      getPartnerDetails(partner);
      getActivity();
      _checkVerifiedLabelFun(ViewModel.pdData.bankDetails);
      if (angular.equals(ViewModel.pdData.bankDetails, {})) {
        ViewModel.pdData.bankDetails.relationship = 'self';
      }

      if (['mashreq', 'dbs'].includes(ViewModel.broker)) {
        checkForConfigService(ViewModel.pdData, config);
      }
      ViewModel.isInsuranceHead = ViewModel.pdData.designation === CONSTANTS_LIST.INSURANCE_HEAD;
      //Task(M30-4680): Added a checker to find pospType : pospgi or pospli
      if (ViewModel.pdData.certificateDetails !== null) {
        ViewModel.pospGiCertificate = ViewModel.pdData.certificateDetails.some(function (e) {
          return e.pospType === 'pospgi';
        });
        ViewModel.pospLiCertificate = ViewModel.pdData.certificateDetails.some(function (e) {
          return e.pospType === 'pospli';
        });
      }
    }
    /**
     * checkForValuesPartner function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparidaeatils
     *
     */
    function checkForValuesPartner(partner) {
      var maritalStatus = partner.maritalStatus,
          officeSpace = partner.officeSpace,
          eligibleForLMS = partner.eligibleForLMS,
          documentOwner = partner.documentOwner;

      ViewModel.pdData.maritalStatus = maritalStatus !== null ? maritalStatus ? 'true' : 'false' : null;
      ViewModel.pdData.officeSpace = officeSpace !== null ? officeSpace ? 'true' : 'false' : null;
      ViewModel.pdData.eligibleForLMS = eligibleForLMS !== null ? [true].includes(eligibleForLMS) ? 'true' : 'false' : 'notSet';
      ViewModel.pdData.documentOwner = documentOwner !== null ? documentOwner ? 'true' : 'false' : null;
    }
    /**
     * downloadThisFile function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function downloadThisFile(document) {
      showLoader();
      PartnerService.downloadDocument(ViewModel.pdData.id, ViewModel.pdData.organisation, document.documentId).then(function () {
        commonService.showToast(ViewModel.downloadSuccess);
      }).catch(function () {
        commonService.showToast(ViewModel.someErrorOccured);
      }).finally(hideLoader);
    }
    /**
     * Function to Can Be Viewed
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           file         file
     *
     * @author sumantaparida
     *
     */
    function canBeViewed(file) {
      var pdfFileExtentions = ['pdf'];
      var imageFileExtentions = ['jpg', 'png', 'jpeg'];
      file.isImage = UtilService.fileCanBePreviewed(file, imageFileExtentions);
      file.isPDF = UtilService.fileCanBePreviewed(file, pdfFileExtentions);

      return file.isImage || file.isPDF;
    }
    /**
     * viewFile function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function viewFile(file) {
      if (canBeViewed(file)) {
        ViewModel.viewDocuments = true;
        ViewModel.selectedFile = file;
        var url = MasterFileService.getViewFileUrl(file.documentId); //+ '#view=FitV';
        $sce.trustAsResourceUrl(url);
        ViewModel.documentURL = url;

        // Task(M30-4577): disable rightclick in PLM post verification
        if (ViewModel.viewDocuments && ViewModel.partnerLead && partner.status === 'verified' && ViewModel.broker === 'turtlemint') {
          setTimeout(function () {
            var fileContainer = document.getElementsByClassName('policyPdfWrap');
            fileContainer[0].addEventListener('contextmenu', function (event) {
              event.preventDefault();
            }, false);
          }, 200);
        }
      } else {
        $window.console.log('cannot view this file');
      }
    }
    /**
     * Function to close View Mode
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function closeViewMode() {
      ViewModel.viewDocuments = false;
      delete ViewModel.selectedFile;
    }
    /**
     * Function to rotate image
     *
     * @public
     *
     * @function
     *
     * @author Rani
     *
     */
    function rotateImg(file) {
      var deg = void 0;
      var count = 90;
      deg = file.angle === 360 ? 0 : file.angle;
      var angle = deg + count;
      ViewModel.selectedFile.angle = angle;
    }
    /**
     * deleteThisFile function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function deleteThisFile(document) {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'dev/partner/templates/dialogs/delete-document-dialog.tpl.html',
        clickOutsideToClose: true,
        controller: confirmDeleteDocument
      });
      confirmDeleteDocument.$inject = ['$scope', '$mdDialog'];
      function confirmDeleteDocument($scope, $mdDialog) {
        $scope.deletePosp = function () {
          $mdDialog.hide();
          PartnerService.deleteDocument(ViewModel.pdData.id, ViewModel.pdData.organisation, document.type).then(function () {
            showLoader();
            ViewModel.documentsData.map(function (ele) {
              if (document.type === ele.name) {
                delete ele.uploadedDoc;
              }
            });
            ViewModel.pdData.documentNews.map(function (ele) {
              if (document.type === ele.type && document.documentId === ele.documentId) {
                delete ele.documentId;
                delete ele.documentName;
                delete ele.documentNumber;
                delete ele.mimeType;
                delete ele.angle;
              }
            });
            commonService.showToast(ViewModel.deleteSuccess);
            // TASK(M30-4576):  NOC generation process - added reload to hide/show generate NOC button based post deletion
            $state.reload();
          }).catch(function () {
            commonService.showToast(ViewModel.someErrorOccured);
          }).finally(hideLoader);
        };
        $scope.close = function () {
          $mdDialog.cancel();
        };
      }
    }
    /**
     * uploadFile function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function uploadFile(index, file) {

      if (file.name === 'profile_picture' || file.name === 'business_profile_picture') {
        document.getElementById(file.name).setAttribute('accept', 'image/png, image/jpeg'); // Task(M30-4656) : Remove .pdf and add .png/.jpg in ninja for business profile
      }
      //  if (file.uploadedDoc && file.uploadedDoc[0].documentId) {
      //    return;
      //  }
      if (ViewModel.filePermissions.upload) {
        //angular.element('#' + file.name).trigger('click');
        ViewModel.uploader.formData = [{ type: file.name }];

        var jwtToken;
        try {
          jwtToken = atob($window.localStorage.getItem('jwtToken'));
        } catch (err) {
          jwtToken = $window.localStorage.getItem('jwtToken');
        }

        ViewModel.uploader.headers = {
          Authorization: jwtToken
        };
      }
    }
    /**
     * searchEmployee function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function searchEmployee(val) {
      var designation = ViewModel.pdData.designation;
      if (val && val.length > 2) {
        return PartnerService.searchEmployee(val, tenant, designation).then(extractPartnersFromResponse).catch(function (err) {
          return $window.console.error(err);
        });
      }
    }
    /**
     * extractPartnersFromResponse function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function extractPartnersFromResponse(response) {
      return response && response.data && response.data.data || [];
    }
    /**
     * onReportToSelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onReportToSelection(item) {
      var id = item.id;

      ViewModel.pdData.supervisorId = id;
      getHierarchyOfManagers(ViewModel.pdData.supervisorId);
    }
    /**
     * searchBranch function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function searchBranch(val) {
      var branchData = ViewModel.branchData;

      var _ref4 = branchData || {},
          $$state = _ref4.$$state;

      var _ref5 = $$state || {},
          value = _ref5.value;

      return value.filter(function (ele) {
        return ele.city.indexOf(val) > -1 || ele.state.indexOf(val) > -1;
      });
      // if (val && val.length > 2) {
      //   return PartnerService.searchBranch(val)
      //     .then(extractPartnersFromResponse)
      //     .catch(err => $window.console.error(err));
      // }
    }
    /**
     * onChangeBranchlocation function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onChangeBranchlocation(item) {
      ViewModel.pdData.branch = item.city;
      ViewModel.pdData.branchState = item.state;
      ViewModel.pdData.region = item.region;
      ViewModel.pdData.branchCode = item._id;
    }
    /**
     * onAssignToSelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onAssignToSelection(item) {
      var id = item.id,
          businessVertical = item.businessVertical,
          supervisor = item.supervisor;

      ViewModel.pdData.supervisorId = id;
      getHierarchyOfManagers(ViewModel.pdData.supervisorId);
      ViewModel.pdData.teamLead = supervisor && supervisor.name;
      ViewModel.pdData.businessVertical = businessVertical;
    }
    /**
     * getHierarchyOfManagers function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getHierarchyOfManagers(id) {
      return PartnerService.getHierarchyOfManagers(id, tenant).then(function success(response) {
        ViewModel.allManagersList = response.data.data;
      }, function error() {});
    }
    /**
     * onAssignToL1Selection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onAssignToL1Selection(item) {
      var id = item.id,
          businessVertical = item.businessVertical,
          supervisor = item.supervisor;

      ViewModel.pdData.parentId = id;
      getHierarchyOfManagers(ViewModel.pdData.parentId); //Task(M30-4689): To capture national head for Pl2
      ViewModel.pdData.um = supervisor && supervisor.name;
      ViewModel.pdData.businessVertical = businessVertical;
    }
    /**
     * onAssociatedPOSPSelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onAssociatedPOSPSelection(item) {
      ViewModel.pdData.associatedPOSPId = item.id;
    }
    /**
     * partnerTypeFun function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function partnerTypeFun() {
      ViewModel.partnerType = ViewModel.partnerTypeSelection && ViewModel.partnerTypeSelection.value || ViewModel.pdData.partnerType;
      ViewModel.pdData.partnerType = ViewModel.partnerType;
      ViewModel.partnerTypeFlag = ViewModel.partnerType;
      var supervisor = void 0;
      switch (ViewModel.pdData.partnerType) {
        case 'partner_level_1':
          supervisor = ViewModel.pdData.supervisor;
          ViewModel.pdData.assignTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          ViewModel.pdData.teamLead = supervisor && supervisor.supervisor && supervisor.supervisor.name;
          if (!['mashreq', 'dbs'].includes(ViewModel.broker)) {
            ViewModel.pdData.designation = null;
          }
          ViewModel.allManagersList = [];
          break;
        case 'small_business':
          supervisor = ViewModel.pdData.supervisor;
          ViewModel.pdData.assignTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          ViewModel.pdData.teamLead = supervisor && supervisor.supervisor && supervisor.supervisor.name;
          if (!['mashreq', 'dbs'].includes(ViewModel.broker)) {
            ViewModel.pdData.designation = null;
          }
          ViewModel.allManagersList = [];
          break;
        case 'dealership_partner':
          supervisor = ViewModel.pdData.supervisor;
          ViewModel.pdData.assignTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          ViewModel.pdData.teamLead = supervisor && supervisor.supervisor && supervisor.supervisor.name;
          if (!['mashreq', 'dbs'].includes(ViewModel.broker)) {
            ViewModel.pdData.designation = null;
          }
          ViewModel.allManagersList = [];
          break;
        case 'partner_level_2':
          ViewModel.pdData.assignToL1 = ViewModel.pdData.parent && ViewModel.pdData.parent.name;
          ViewModel.pdData.um = ViewModel.pdData.parent && ViewModel.pdData.parent.supervisor && ViewModel.pdData.parent.supervisor.name;
          if (!['mashreq', 'dbs'].includes(ViewModel.broker)) {
            ViewModel.pdData.designation = null;
          }break;
        case 'employee':
          ViewModel.pdData.reportingTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          break;
        case 'telemarketing_employees':
          ViewModel.pdData.reportingTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          break;
        case 'franchise_partner':
          supervisor = ViewModel.pdData.supervisor;
          ViewModel.pdData.assignTo = ViewModel.pdData.supervisor && ViewModel.pdData.supervisor.name;
          ViewModel.pdData.teamLead = supervisor && supervisor.supervisor && supervisor.supervisor.name;
          if (!['mashreq', 'dbs'].includes(ViewModel.broker)) {
            ViewModel.pdData.designation = null;
          }
          ViewModel.allManagersList = [];
          break;
        default:
          return null;
      }
    }
    /**
     * businessChannelFun function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function businessChannelFun() {
      if (ViewModel.businessChannelModel) {
        var value = ViewModel.businessChannelModel.value;

        ViewModel.pdData.businessChannel = value;
      }
    }
    /**
     * onDOBSelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onDOBSelection() {
      ViewModel.pdData.dateOfBirth = new Date(ViewModel.dateOfBirth).getTime();
    }

    function onLastWorkingDaySelection() {
      ViewModel.pdData.lastWorkingDate = new Date(ViewModel.lastWorkingDate).getTime();
    }
    /**
     * onPrepaidExpirySelection function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onPrepaidExpirySelection() {
      ViewModel.pdData.prepaidCardExpiry = ViewModel.prepaidExpiry;
    }
    /**
     * onAfterAddingFile function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    uploader.onAfterAddingFile = function (fileItem) {
      showLoader();
      $scope.uploadimageErrMsg = '';
      $scope.uploadimageErr = false;
      var file = fileItem._file;
      var index = -1;
      var items = uploader.queue;
      var count = 0;
      var indexPos = [];
      for (var i = items.length - 1; i >= 0; i--) {
        if (items[i]._file.name === file.name) {
          index = i;
          count++;
          if (count > 1) {
            indexPos.push(i);
          }
        }
      }
      fileUpload(fileItem);
    };
    /**
     * onSuccessItem function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    uploader.onSuccessItem = function (item, response) {
      delete response.message;
      delete response.status;
      $scope.active = $scope.currentIndex;
      // $scope.flex = 85;
      var re = /(?:\/([^/]+))?$/;
      var ext = re.exec(response.data.mimeType)[1];
      // if (response.data.documentId && response.data.mimeType) {
      //   response.data.documentName = response.data.documentId + '.' + ext;
      //   // response.data.documentName = response.data.documentId + '.' + ext;
      // }
      if (response.data && response.data.documentId) {
        if (ViewModel.pdData.documentNews[getIndexFromObj('type', response.data.type, ViewModel.pdData.documentNews)]) {
          ViewModel.pdData.documentNews[getIndexFromObj('type', response.data.type, ViewModel.pdData.documentNews)].documentId = response.data.documentId;
          ViewModel.pdData.documentNews[getIndexFromObj('type', response.data.type, ViewModel.pdData.documentNews)].documentName = response.data.documentId + '.' + ext;
          ViewModel.pdData.documentNews[getIndexFromObj('type', response.data.type, ViewModel.pdData.documentNews)].mimeType = response.data.mimeType;
        } else {
          ViewModel.pdData.documentNews.push(response.data);
        }
      }
      updateDocumentsMapWithDoc(response.data);
    };

    uploader.filters.push({
      name: 'imageFilter',
      fn: function fn(item /*{File|FileLikeObject}*/, options) {
        var tag = uploader.formData[0].type;
        if (tag && (tag === 'profile_picture' || tag === 'business_profile_picture')) {
          var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
          var isImg = '|jpg|png|jpeg|'.indexOf(type) !== -1;
          if (!isImg) {
            commonService.showToast('Only images are allowed');
          }
          return isImg;
        }
        return true;
      }
    });
    /**
     * fileUpload function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function fieldFocus() {
      ViewModel.pdData.documentNews.forEach(function (item) {
        if (item.type === 'pan_card') {
          item.documentNumber = ViewModel.documentModel['panNumber'];
          item.visionDocumentNumber = ViewModel.documentModel['panVisionNumber'];
        }
        if (item.type === 'adhaar_card') {
          item.documentNumber = ViewModel.documentModel['aadhaarNumber'];
          item.visionDocumentNumber = ViewModel.documentModel['adhaarVisionNumber'];
        }
      });
    }

    function verifyVision(res, selectedType) {
      var _res$data = res.data,
          visionNumber = _res$data.documentNumber,
          doc_type = _res$data.doc_type;

      var postParams = {};
      var partnerId = ViewModel.pdData.id;
      if (selectedType === 'adhaar_card' && doc_type === 'adhaar_card') {
        postParams.adhaar_card = visionNumber;
        ViewModel.documentModel['aadhaarNumber'] = visionNumber;
        ViewModel.documentModel['adhaarVisionNumber'] = visionNumber;
        ViewModel.adhChecked = true;
      }
      if (selectedType === 'pan_card' && doc_type === 'pan_card') {
        postParams.pan_card = visionNumber;
        ViewModel.documentModel['panNumber'] = visionNumber;
        ViewModel.documentModel['panVisionNumber'] = visionNumber;
        ViewModel.panChecked = true;
      }
      fieldFocus();
      PartnerService.updateVisionNumber(partnerId, postParams);
    }

    function callFRomVision(fileItem) {
      var fd = new FormData();
      fd.append('filename', fileItem._file);
      var selectedType = fileItem.formData[0].type;
      ViewModel.selectedType = selectedType;
      if (ViewModel.broker === 'turtlemint' && selectedType === 'pan_card' || selectedType === 'adhaar_card') {
        ViewModel.viLoader = true;
        PartnerService.visionApi(fd).then(function (res) {
          ViewModel.viLoader = false;
          if (res.status === 200 && res.data.doc_type !== null && res.data.doc_type !== 'invalid_document' && res.data.doc_type !== '' && res.data.documentNumber !== null && res.data.documentNumber !== '') {
            verifyVision(res, selectedType);
          }
        }).catch(function (err) {
          $window.console.log(err);
          ViewModel.viLoader = false;
        });
      }
    }

    function fileUpload(fileItem) {
      // TASK(M30-4634) and  Task(M30-4656) : Custom Poster to allow upload in PNG / JPG format across all platform
      var profilePicture = fileItem.formData[0].type;
      ViewModel.profilePicture = profilePicture;
      if (profilePicture === 'profile_picture' || profilePicture === 'business_profile_picture') {
        var fileExtension = fileItem.file.type;
        var allowedExt = ['image/png', 'image/jpeg', 'image/jpg'];
        if (fileExtension && allowedExt.includes(fileExtension)) {
          //check extension
        } else {
          commonService.showToast('Invalid image format. Allowed types are PNG/JPG');
          hideLoader();
          return false;
        }
      }
      fileItem.upload();
      fileItem.onSuccess = function () {
        if (!ViewModel.partnerLead) {
          callFRomVision(fileItem);
        }
        commonService.showToast(ViewModel.uploadSuccess);
      };
      fileItem.onError = function () {
        commonService.showToast(ViewModel.someErrorOccured);
      };
      fileItem.onComplete = function () {
        hideLoader();
      };
    }
    /**
     * onSaveAction function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onSaveAction(document, fromBackBtn) {
      var _ViewModel$pdData = ViewModel.pdData,
          status = _ViewModel$pdData.status,
          partnerType = _ViewModel$pdData.partnerType,
          bankDetails = _ViewModel$pdData.bankDetails,
          completed = _ViewModel$pdData.completed,
          address = _ViewModel$pdData.address;
      var verified = bankDetails.verified;
      var currentPincode = address.currentPincode;

      if (['turtlemint'].includes(ViewModel.broker) && currentPincode === '') {
        ViewModel.pdData.address.currentPincode = null;
      }
      var showPopup = false;
      _.forIn(ViewModel.pdData, function (value) {
        if (value) {
          showPopup = true;
        }
      });
      if (['turtlemint'].includes(ViewModel.broker) && ['to_verify'].includes(status) && ['notSet'].includes(document.eligibleForLMS)) {
        var _ref6 = ViewModel.partnerDetailsForm || {},
            eligibleForLMS = _ref6.eligibleForLMS;

        eligibleForLMS.$invalid = true;
        eligibleForLMS.$touched = true;
        ViewModel.partnerDetailsForm.$invalid = true;
      }
      if (['dbs'].includes(ViewModel.broker) && ['BancaCentralTeam'].includes(document.designation) && document.branchCode != null) {
        ViewModel.partnerDetailsForm.$invalid = false;
      }
      if (['dbs'].includes(ViewModel.broker)) {
        if (islicenseValid(document)) {
          ViewModel.partnerDetailsForm.$invalid = false;
        } else {
          ViewModel.partnerDetailsForm.$invalid = true;
        }
      }

      if (ViewModel.partnerDetailsForm.$invalid && !fromBackBtn) {
        $window.alert('Please check form \'some fields\' are required');
        setDirtyField();
        return;
      } else if (['turtlemint'].includes(ViewModel.broker) && ['verified'].includes(status) && !verified && !['employee', 'telemarketing_employees'].includes(partnerType)) {
        _bankObjNotBlank();
        return;
      } else if (!ViewModel.partnerDetailsForm.$pristine || showPopup) {
        $mdDialog.show({
          templateUrl: 'dev/partner/templates/dialogs/update-partner-dialog.tpl.html',
          clickOutsideToClose: true,
          controller: 'updatePartnerCtrl',
          locals: {
            items: ViewModel.pdData.status,
            rejectionReason: ViewModel.rejectionReason,
            completed: completed
          }
        }).then(function (res) {
          if (res.save) {
            savePartner(document, fromBackBtn);
          }
        }, function () {
          ViewModel.pdData = {};
          if (ViewModel.partnerLead) {
            $state.go('partner-lead-list', {}, { reload: true });
          } else {
            $state.go('partner-list', {}, { reload: true });
          }
        });
      } else {
        if (ViewModel.partnerLead) {
          $state.go('partner-lead-list', {}, { reload: true });
        } else {
          $state.go('partner-list', {}, { reload: true });
        }
      }
    }
    /**
     * isFormValid function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function isFormValid() {
      if (ViewModel.partnerDetailsForm.$invalid && !ViewModel.partnerDetailsForm.$pristine) {
        $window.alert('Please check form \'some fields\' are required');
        setDirtyField();
        return true;
      }
    }

    /**
     * islicenseValid function
     *
     * @public
     *
     * @function to validate license fields.
     *
     */
    function islicenseValid(document) {
      if (document.licenseNumber && document.licenseStartDate && document.pospType && document.expiryDate || !document.licenseNumber && !document.licenseStartDate && !document.pospType && !document.expiryDate) {
        return true;
      } else {
        return false;
      }
    }
    /**
     * savePartner function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function savePartner(document) {
      var _ref7 = document || {},
          leadAcquisition = _ref7.leadAcquisition,
          createdAt = _ref7.createdAt,
          dateOfBirth = _ref7.dateOfBirth,
          nocSentDate = _ref7.nocSentDate,
          eligibleForLMS = _ref7.eligibleForLMS,
          id = _ref7.id,
          lastWorkingDate = _ref7.lastWorkingDate;

      if (isFormValid()) {
        return;
      }
      if (Object.keys(leadAcquisition).length === 0) {
        document.leadAcquisition = null;
      }
      document.createdAt = parseInt(moment(createdAt).format('x'));
      document.dateOfBirth = parseInt(moment(dateOfBirth).format('x')) || null;
      document.nocSentDate = parseInt(moment(nocSentDate).format('x')) || null;
      //document.lastWorkingDate =
      //  parseInt(moment(createdAt).format('x')) || null;
      //document.lastWorkingDate = document.createdAt
      document.lastWorkingDate = document.lastWorkingDate ? moment(document.lastWorkingDate).format('x') : document.lastWorkingDate;

      // POSP
      if (ViewModel._LDeditable || ['idfcfirstbank', 'federal'].includes(ViewModel.broker)) {
        updateCertificateDetails(document);
      }
      if (['dbs'].includes(ViewModel.broker)) {
        if (document.partnerType === 'partner_level_2' || document.licenseNumber != null) {
          document.pospDetail = document.pospDetail || {};
          document.pospDetail.licenseNumber = document.licenseNumber || document.pospDetail.licenseNumber;
          document.pospDetail.expiryDate = document.expiryDate ? moment(document.expiryDate).format('x') : document.pospDetail.expiryDate;
          document.pospDetail.licenseStartDate = document.licenseStartDate ? moment(document.licenseStartDate).format('x') : document.pospDetail.licenseStartDate;
          document.pospDetail.pospType = document.pospType || document.pospDetail.pospType;
        }
      }
      // saveImageAngle(document);
      // updateNestedObject();
      deleteUnusedKey();
      // console.log('eligibleForLMS', eligibleForLMS, typeof eligibleForLMS);
      document.eligibleForLMS = ['notSet'].includes(eligibleForLMS) ? null : eligibleForLMS;
      PartnerService.updatePartner(id, tenant, document).then(function (response) {
        commonService.showToast(ViewModel.successSaved);
        var status = response && response.data && response.data.data && response.data.data.status;
        ViewModel.pdData = response && response.data && response.data.data;
        if (ViewModel.broker !== 'yesbank' && !ViewModel.partnerLead && status === 'to_verify' && !['PARTNER-MAKER'].includes(ViewModel.partnerRoles) || (ViewModel.broker === 'yesbank' || ViewModel.broker === 'mashreq') && status === 'to_verify' && ['PARTNER-CHECKER', 'UAM-ADMIN', 'MASHREQ-ADMIN'].includes(ViewModel.partnerRoles)) {
          showCompletePopUp();
        }
        if (status === 'to_verify' && ViewModel.hasPermission('partner-maker-role-view')) {
          location.href = '/partner';
        } else {
          $state.reload();
        }
        PartnerService.getConfig(pageType, status).then(function (res) {
          return res;
        });
      }).catch(function (err) {
        $window.console.log(err);
        if (err.data && err.data.meta && err.data.meta.msg) {
          commonService.showToast(err.data.meta.msg);
        } else {
          commonService.showToast(ViewModel.someErrorOccured);
        }
      });
    }
    /**
     * onDeactiveAction function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onDeactiveAction(reason) {
      var promise = '';
      if (ViewModel.isPartnerActivated) {
        promise = PartnerService.deActivate(ViewModel.partnerId, tenant, reason);
      } else {
        promise = PartnerService.activate(ViewModel.partnerId, tenant);
      }
      promise.then(function (res) {
        commonService.showToast('Action successfull');
        $state.reload();
        $window.console.log(res);
      }).catch(function (err) {
        $window.console.log(err);
        commonService.showToast(ViewModel.someErrorOccured);
      });
    }
    /**
     * onVerifyAction function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function onVerifyAction(status, remarks) {
      var P_ID = ViewModel.partnerId;
      var _ViewModel$pdData2 = ViewModel.pdData,
          SUP = _ViewModel$pdData2.supervisor,
          PARENT = _ViewModel$pdData2.parent,
          DP_NO = _ViewModel$pdData2.dpNo,
          partnerType = _ViewModel$pdData2.partnerType;

      var level_2 = ['partner_level_2'].includes(partnerType);
      var jsondata = {
        remarks: remarks,
        partnerId: P_ID
      };
      if (status === 'verified') {
        // TODO: EVENT FOR LAVEL_1 / LAVEL_2
        var eventData = {
          dpNo: DP_NO,
          email_relationship_manager: '' + (level_2 && PARENT ? PARENT.email : SUP ? SUP.email : ''),
          relationship_manager_name: '' + (level_2 && PARENT ? PARENT.name : SUP ? SUP.name : ''),
          rm_mobile: '' + (level_2 && PARENT ? PARENT.mobile : SUP ? SUP.mobile : '')
        };
        PartnerService.verify(P_ID, tenant).then(function (res) {
          if (['turtlemint'].includes(ViewModel.broker)) {
            TrackingService.pushToGTMDatalayer('partnerProfileVerified', 'webengage_track_event', eventData);
            TrackingService.pushToGTMDatalayer('partnerProfileVerified', 'track_event', eventData);
          }
          commonService.showToast('Profile verified successfully');
          if (ViewModel.hasPermission('partner-checker-role-view')) {
            location.href = '/partner';
          } else {
            $state.reload();
          }
          $window.console.log(res);
        }).catch(function (err) {
          $window.console.log(err);
          commonService.showToast(ViewModel.someErrorOccured);
        });
      } else if (status === 'rejected' && ViewModel.pdData.status !== 'rejected') {
        PartnerService.reject(jsondata, tenant).then(function (res) {
          // TrackingService.pushToGTMDatalayer(
          //   'profileVerified',
          //   'webengage_track_event',
          //   ViewModel.pdData
          // );
          commonService.showToast('Profile rejected successfully');
          if (ViewModel.hasPermission('partner-checker-role-view')) {
            location.href = '/partner';
          } else {
            $state.reload();
          }
          $window.console.log(res);
        }).catch(function (err) {
          $window.console.log(err);
          commonService.showToast(ViewModel.someErrorOccured);
        });
      }
    }
    /**
     * makeDocumentsMap function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function makeDocumentsMap(arr) {
      for (var idx = 0; idx < arr.length; idx++) {
        var doc = arr[idx];
        updateDocumentsMapWithDoc(doc);
      }
    }
    /**
     * updateDocumentsMapWithDoc function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function updateDocumentsMapWithDoc(doc) {
      for (var fidx = 0; fidx < ViewModel.documentsData.length; fidx++) {
        var file = ViewModel.documentsData[fidx];
        if (file.name === doc.type) {
          file.uploadedDoc = [];
          file.uploadedDoc.push(doc);
        }
      }
    }
    /**
     * deActivateAction function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function deActivateAction(data) {
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/alert-dialog.tpl.html',
        clickOutsideToClose: true,
        controller: 'alertDialogCtrl',
        locals: { data: data, hasNocCertificate: ViewModel.pdData.hasNocCertificate }
      }).then(function (save) {
        if (save.save) {
          onDeactiveAction(save.reasonValue);
        }
      });
    }
    /**
     * setNavConfig function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function setNavConfig() {
      // Task(M30-4577): disable save button post verification in PLM
      var disableSaveBtn = ViewModel.partnerLead && ViewModel.pdData.status === 'verified' && ViewModel.broker === 'turtlemint';
      // Task(M30-4880): Remove Delete Patner button if user is not having role as "NINJA-ADMIN"
      var adminRoleName = ['NINJA-ADMIN'];
      var navbarActions = [{
        type: 'button',
        class: '',
        action: onSaveAction,
        name: 'Save Changes',
        actionableItem: ViewModel.pdData,
        disabled: ViewModel.deActivateProfileBtn || disableSaveBtn,
        permissionCode: ViewModel.updatePermission
      }, {
        type: 'button',
        class: '',
        action: showCompletePopUp,
        name: 'Verify / Reject Partner',
        actionableItem: ViewModel.pdData,
        permissionCode: 'partner-checker-role-view'
      }, {
        type: 'button',
        class: '',
        action: deActivateAction,
        name: ViewModel.isPartnerActivated ? 'Deactivate profile' : 'Activate profile',
        actionableItem: ViewModel.isPartnerActivated,
        disabled: ViewModel.deActivateProfileBtn,
        permissionCode: ViewModel.partnerLead ? false : ViewModel.updatePermission
      }];
      var deleteObj = {
        type: 'button',
        class: '',
        action: deleteAction,
        name: 'Delete Partner',
        actionableItem: ViewModel.isPartnerActivated,
        disabled: ['hdfcbank'].includes(ViewModel.broker) ? true : ViewModel.deActivateProfileBtn,
        permissionCode: ViewModel.updatePermission
      };
      if (ViewModel.pdData.status === 'not_verified') {
        if (['turtlemint'].includes(ViewModel.broker)) {
          if (adminRoleName.some(function (el) {
            return ViewModel.partnerRoles.includes(el);
          })) {
            navbarActions.push(deleteObj);
          }
        } else {
          navbarActions.push(deleteObj);
        }
      }
      var goBackState = null;
      if (ViewModel.partnerLead) {
        goBackState = 'partner-lead-list';
      } else {
        goBackState = 'partner-list';
      }
      ViewModel.navbarConfig = {
        vertical: 'partner',
        title: 'DP - ' + ViewModel.pdData.dpNo,
        subtitle: ViewModel.pdData.status === 'rejected' ? '( Rejected )' : ViewModel.pdData.status === 'not_verified' ? '( Non verified )' : ViewModel.pdData.status === 'to_verify' ? '( To verify )' : '( Verified )',
        search: {
          enabled: false
        },
        mode: true,
        actions: navbarActions,
        goBackState: goBackState
      };
    }
    /**
     * deleteAction function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function deleteAction() {
      var confirm = $mdDialog.confirm().title('').textContent('Are you sure you want to delete?').ok('OK').cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        PartnerService.deleteProfile(ViewModel.partnerId).then(function (response) {
          if (response.status === 200) {
            var state = ViewModel.partnerLead ? 'partner-lead-list' : 'partner-list';
            $state.go(state);
          }
        });
      });
    }
    /**
     * autoFillDropDowns function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function autoFillDropDowns() {
      ViewModel.educationQualificationModel = ViewModel.educationQualifications[getIndexFromObj('value', ViewModel.pdData.educationQualification, ViewModel.educationQualifications)];
      ViewModel.insuranceExperienceInYearsModel = ViewModel.insuranceExperience[getIndexFromObj('value', ViewModel.pdData.insuranceExperienceInYears, ViewModel.insuranceExperience)];
      ViewModel.primaryOccupationModel = ViewModel.occupations[getIndexFromObj('value', ViewModel.pdData.primaryOccupation, ViewModel.occupations)];
      ViewModel.officeSpaceModel = ViewModel.officeSpace[getIndexFromObj('value', ViewModel.pdData.officeSpace, ViewModel.officeSpace)];
      ViewModel.existingBusinessTurnoverModel = ViewModel.businessTurnover[getIndexFromObj('value', ViewModel.pdData.existingBusinessTurnover, ViewModel.businessTurnover)];
      if (ViewModel.pdData.bankDetails) {
        ViewModel.relationshipModel = ViewModel.relationship[getIndexFromObj('value', ViewModel.pdData.bankDetails.relationship, ViewModel.relationship)];
      }
      ViewModel.maritalStatusModel = ViewModel.officeSpace[getIndexFromObj('value', ViewModel.pdData.maritalStatus, ViewModel.officeSpace)];
      ViewModel.dpTypeModel = ViewModel.dpType[getIndexFromObj('value', ViewModel.pdData.dpType, ViewModel.dpType)];

      ViewModel.primarySourceOfIncomeModel = ViewModel.primarySourceOfIncome[getIndexFromObj('value', ViewModel.pdData.primarySourceOfIncome, ViewModel.primarySourceOfIncome)];
      ViewModel.monthlyHealthPremiumModel = ViewModel.monthlyHealthPremium[getIndexFromObj('value', ViewModel.pdData.monthlyHealthPremium, ViewModel.monthlyHealthPremium)];
      ViewModel.monthlyLifePremiumModel = ViewModel.monthlyLifePremium[getIndexFromObj('value', ViewModel.pdData.monthlyLifePremium, ViewModel.monthlyLifePremium)];
      ViewModel.monthlyMotorPremiumModel = ViewModel.monthlyMotorPremium[getIndexFromObj('value', ViewModel.pdData.monthlyMotorPremium, ViewModel.monthlyMotorPremium)];
    }
    /**
     * gotoPartnerReview function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function gotoPartnerReview() {
      var pathname = window.location.pathname.split('/');
      var associateID = ViewModel.pdData.associatePartnerId || ViewModel.pdData.associatedPOSPId;
      var url = pathname[1] + '/' + ViewModel.tenant + '/' + associateID;
      if (ViewModel.pdData.associatePartnerId || ViewModel.pdData.associatedPOSPId) {
        window.open(url, '_blank');
      } else {
        window.alert('associatePartnerId and associatedPOSPId is null');
      }
    }

    function getPartnerModel(partner) {
      var partnerModel = _extends({}, partner);
      partnerModel.displayAddress = partner.address && partner.address.displayAddress;
      partnerModel.pincode = partner.address && partner.address.pincode;
      partnerModel.city = partner.address && partner.address.city;
      partnerModel.state = partner.address && partner.address.state;

      partnerModel.accHolderName = partner.bankDetails && partner.bankDetails.accHolderName;
      partnerModel.accNo = partner.bankDetails && partner.bankDetails.accNo;
      partnerModel.bankName = partner.bankDetails && partner.bankDetails.bankName;
      partnerModel.branch = partner.bankDetails && partner.bankDetails.branch;
      partnerModel.ifsccode = partner.bankDetails && partner.bankDetails.ifsccode;
      partnerModel.relationship = partner.bankDetails && partner.bankDetails.relationship;
      partnerModel.validatedName = partner.bankDetails && partner.bankDetails.validatedName;
      partnerModel.verificationMode = partner.bankDetails && partner.bankDetails.verificationMode;
      partnerModel.verified = partner.bankDetails && partner.bankDetails.verified;
      partnerModel.validationRetries = partner.bankDetails && partner.bankDetails.validationRetries;
      partnerModel.nomineeAccHolderName = partner.nomineeBankDetails && partner.nomineeBankDetails.accHolderName;
      partnerModel.nomineeAccNo = partner.nomineeBankDetails && partner.nomineeBankDetails.accNo;
      partnerModel.nomineeBankName = partner.nomineeBankDetails && partner.nomineeBankDetails.bankName;
      partnerModel.nomineeBranch = partner.nomineeBankDetails && partner.nomineeBankDetails.branch;
      partnerModel.nomineeIfsccode = partner.nomineeBankDetails && partner.nomineeBankDetails.ifsccode;
      if (['idfcfirstbank', 'federal', 'dbs'].includes(ViewModel.broker)) partnerModel.pospType = partner.pospType;else partnerModel.pospType = partner.certificateDetails && partner.certificateDetails.length > 0 && partner.certificateDetails[0].pospType || partner.pospDetail && partner.pospDetail.pospType;
      partnerModel.pospLicenseNumber = partner.pospDetail && partner.pospDetail.licenseNumber;
      partnerModel.pospExpiryDate = partner.pospDetail && partner.pospDetail.expiryDate;

      partnerModel.salesStatus = partner.leadAcquisition && partner.leadAcquisition.saleStatus;
      partnerModel.acquisitionSource = partner.leadAcquisition && partner.leadAcquisition.acquisitionSource;
      partnerModel.utmDetails = partner.leadAcquisition && partner.leadAcquisition.utmDetails;
      partnerModel.mintproAccountExist = partner.leadAcquisition && partner.leadAcquisition.mintproAccountExist;

      partnerModel.verifierName = partner.partnerVerifier && partner.partnerVerifier.verifierName;
      partnerModel.verifierDpNo = partner.partnerVerifier && partner.partnerVerifier.verifierDpNo;
      // TASK(M30-4457): verified by and verification date
      partnerModel.qcVerifiedByName = partner.partnerQc && partner.partnerQc.qcVerifiedByName;
      partnerModel.qcVerificationDate = partner.partnerQc && partner.partnerQc.qcVerificationDate;
      // TASK(M30-4457): rejected by and rejection date
      partnerModel.qcRejectedByName = partner.partnerQc && partner.partnerQc.qcRejectedByName;
      partnerModel.qcRejectionDate = partner.partnerQc && partner.partnerQc.qcRejectionDate;

      partnerModel.employeeNo = partner.tenantUniqueCode;
      partnerModel.externalEmployeeNo = partner.externalUniqueId;

      partner.documentNews.map(function (ele) {
        if (['pan_card'].includes(ele.type)) {
          partnerModel.panNumber = ele.documentNumber;
        } else if (['adhaar_card'].includes(ele.type)) {
          partnerModel.aadhaarNumber = ele.documentNumber;
        } else if (['passbook'].includes(ele.type)) {
          partnerModel.cancelChequeNumber = ele.documentNumber;
        } else if (['nominee_pan_card'].includes(ele.type)) {
          partnerModel.nomineePanNumber = ele.documentNumber;
        } else if (['nominee_cancelled_cheque'].includes(ele.type)) {
          partnerModel.nomineecancelChequeNumber = ele.documentNumber;
        } else if (['CERTIFICATE'].includes(ele.type)) {}
      });

      if (!!partner.thirdPartyAssociation) {
        partner.thirdPartyAssociation.map(function (ele) {
          if (['AGENCY_HEALTH'].includes(ele.type)) {
            partnerModel.agency_health = ele.thirdParty;
          } else if (['AGENCY_LIFE'].includes(ele.type)) {
            partnerModel.agency_life = ele.thirdParty;
          } else if (['AGENCY_GENERAL'].includes(ele.type)) {
            partnerModel.agency_general = ele.thirdParty;
          } else if (['POS_LIFE'].includes(ele.type)) {
            partnerModel.pos_life = ele.thirdParty;
          } else if (['POS_GENERAL'].includes(ele.type)) {
            partnerModel.pos_general = ele.thirdParty;
          } else if (['SURVEYOR'].includes(ele.type)) {
            partnerModel.surveyor = ele.thirdParty;
          }
        });

        if (partner.thirdPartyAssociation.length > 0) {
          var noneOf = partner.thirdPartyAssociation[0].type;
          if (noneOf === 'NONE') {
            partnerModel.NONE = true;
          }
        }
      }

      return partnerModel;
    }

    /**
     * getPartnerDetails function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getPartnerDetails(partner) {
      if (partner) {
        if (['mashreq', 'dbs'].includes(ViewModel.broker)) {
          var partnerData = JSON.parse(JSON.stringify(partner));
          ViewModel.pdData = getPartnerModel(partnerData);
        } else {
          ViewModel.pdData = partner;
        }
        ViewModel.tenant = ViewModel.pdData.organisation;
        ViewModel.associateNewPartner = ViewModel.pdData.id;
        ViewModel.partnerId = ViewModel.pdData.id;
        ViewModel.isPartnerActivated = ViewModel.pdData.allowAccess;
        ViewModel.isPartnerVerified = ViewModel.pdData.status === 'verified';
        ViewModel.pospInsurerDetails = ViewModel.pdData.pospInsurerDetails;
        ViewModel.pdData.bankDetailsVerifier = ViewModel.pdData.bankDetailsVerifier || {};
        ViewModel.pdData.partnerVerifier = ViewModel.pdData.partnerVerifier || {};
        ViewModel.pdData.partnerQc = ViewModel.pdData.partnerQc || {}; // TASK(M30-4457)

        if (['dbs'].includes(ViewModel.broker) && partnerData.pospDetail) {
          ViewModel.pdData.licenseNumber = partnerData.pospDetail.licenseNumber || {};
          ViewModel.pdData.expiryDate = partnerData.pospDetail.expiryDate || {};
          ViewModel.pdData.licenseStartDate = partnerData.pospDetail.licenseStartDate || {};
        }

        if (ViewModel.pdData.prepaidCardExpiry) {
          ViewModel.prepaidExpiry = new Date(ViewModel.pdData.prepaidCardExpiry);
        } else {
          ViewModel.prepaidExpiry = new Date();
        }
        if (ViewModel.pdData.dateOfBirth) {
          ViewModel.dateOfBirth = new Date(ViewModel.pdData.dateOfBirth);
        }
        if (ViewModel.pdData.lastWorkingDate) {
          ViewModel.lastWorkingDate = new Date(ViewModel.pdData.lastWorkingDate);
        }
        var subscriptionType = ViewModel.pdData.subscriptionType;
        ViewModel.elitePlanSelection = ViewModel.elitePlanList[getIndexFromObj('value', subscriptionType, ViewModel.elitePlanList)];
        if (ViewModel.pdData.subscriptionType === 'ELITE') {
          ViewModel.elitePlanFlag = 'Elite';
        }
        ViewModel.splitPayoutArr = ViewModel.pdData && ViewModel.pdData.splitPayouts || [];
        if (ViewModel.splitPayoutArr.length) {
          ViewModel.splitPayoutSelection = ViewModel.splitPayoutList[getIndexFromObj('value', ViewModel.splitPayoutArr[0].splitPercentage, ViewModel.splitPayoutList)];
        } else {
          ViewModel.splitPayoutSelection = ViewModel.splitPayoutList[getIndexFromObj('value', 0, ViewModel.splitPayoutList)];
        }
        setNavConfig();
        var partnerType = ViewModel.pdData.partnerType;
        ViewModel.partnerTypeFlag = ViewModel.pdData.partnerType;
        ViewModel.partnerList = ViewModel.partnerTypesList;
        ViewModel.businessChannelList = ViewModel.businessChannelListConfig;
        ViewModel.businessChannelModel = ViewModel.businessChannelList.find(function (ele) {
          return ele.value === ViewModel.pdData.businessChannel;
        });
        // ViewModel.businessChannelModel = {
        //   displayName: 'DRM',
        //   value: 'DRM',
        //   rank: 3
        // };
        // Remove 'employee' only partner lead ##start
        if (ViewModel.partnerLead) {
          ViewModel.partnerTypesList.map(function (partnerlist, idx) {
            if (partnerlist.value === 'employee') {
              ViewModel.partnerTypesList.splice(idx, partnerlist.rank);
            }
          });
          ViewModel.pdData.thirdPartyAssociation = [{
            type: 'NONE',
            thirdParty: ''
          }];
        }
        // Remove 'employee' only partner lead ##End
        if (['employee', 'telemarketing_employees'].includes(partnerType)) {
          ViewModel.partnerRoleFlag = false;
          ViewModel.partnerTypeSelection = ViewModel.partnerTypesList[getIndexFromObj('value', partnerType, ViewModel.partnerTypesList)];
          ViewModel.partnerTypeSelectionDisable = true;
        } else {
          ViewModel.partnerList = [].concat(_toConsumableArray(ViewModel.partnerTypesList));
          ViewModel.partnerTypeSelection = ViewModel.partnerTypesList[getIndexFromObj('value', partnerType, ViewModel.partnerTypesList)];
        }
        partnerTypeFun();
        autoFillDropDowns();
        makeDocumentsMap(ViewModel.pdData.documentNews);
        getHierarchyOfManagers(ViewModel.partnerId);
        initializeDocumentNews();
        checkForValuesPartner(ViewModel.pdData);
        getCertificateDetails(ViewModel.pdData);

        // Replace Object when GET data
        ViewModel.documentModel = {};
        ViewModel.nomineeDocumentsDetails = {};
        ViewModel.thirdPartyAssociation = [];
        if (!ViewModel.pdData.address) {
          ViewModel.pdData.address = {};
        }
        if (!ViewModel.pdData.bankDetails) {
          ViewModel.pdData.bankDetails = {};
        }
        if (!ViewModel.pdData.leadAcquisition) {
          ViewModel.pdData.leadAcquisition = {};
        }
        if (!ViewModel.pdData.nomineeBankDetails) {
          ViewModel.pdData.nomineeBankDetails = {};
        }

        ViewModel.pdData.documentNews.map(function (ele) {
          var re = /(?:\/([^/]+))?$/;
          var ext = re.exec(ele.mimeType)[1];
          if (!ele.documentName && ele.documentId) {
            ele.documentName = ele.documentId + '.' + ext;
          }

          if (['pan_card'].includes(ele.type)) {
            ViewModel.documentModel['panNumber'] = ele.documentNumber;
            ViewModel.documentModel['panVisionNumber'] = ele.visionDocumentNumber;
          } else if (['adhaar_card'].includes(ele.type)) {
            ViewModel.documentModel['aadhaarNumber'] = ele.documentNumber;
            ViewModel.documentModel['adhaarVisionNumber'] = ele.visionDocumentNumber;
          } else if (['passbook'].includes(ele.type)) {
            ViewModel.documentModel['cancelChequeNumber'] = ele.documentNumber;
          } else if (['nominee_pan_card'].includes(ele.type)) {
            ViewModel.nomineeDocumentsDetails['nomineePanNumber'] = ele.documentNumber;
          } else if (['nominee_cancelled_cheque'].includes(ele.type)) {
            ViewModel.nomineeDocumentsDetails['nomineecancelChequeNumber'] = ele.documentNumber;
          } else if (['CERTIFICATE'].includes(ele.type)) {
            var documentId = ele.documentId;
            // ViewModel.editable = false;

            ViewModel._LDeditable = ViewModel.editable && documentId ? !documentId : ViewModel.editable && !documentId;
            // console.log('LD', ViewModel._LDeditable);
          }
        });
        if (!!ViewModel.pdData.thirdPartyAssociation) {
          ViewModel.pdData.thirdPartyAssociation.map(function (ele) {
            if (ele.type === 'AGENCY_HEALTH') {
              ViewModel.thirdPartyAssociation['agency_health'] = ele.thirdParty;
            } else if (ele.type === 'AGENCY_LIFE') {
              ViewModel.thirdPartyAssociation['agency_life'] = ele.thirdParty;
            } else if (ele.type === 'AGENCY_GENERAL') {
              ViewModel.thirdPartyAssociation['agency_general'] = ele.thirdParty;
            } else if (ele.type === 'POS_LIFE') {
              ViewModel.thirdPartyAssociation['pos_life'] = ele.thirdParty;
            } else if (ele.type === 'POS_GENERAL') {
              ViewModel.thirdPartyAssociation['pos_general'] = ele.thirdParty;
            } else if (ele.type === 'SURVEYOR') {
              ViewModel.thirdPartyAssociation['surveyor'] = ele.thirdParty;
            }
          });
        }
        // if (ViewModel.pdData.eligibleForLMS === false) {
        //   ViewModel.pdData.eligibleForLMS = 'Not eligible';
        // }
        if (ViewModel.pdData.thirdPartyAssociation && ViewModel.pdData.thirdPartyAssociation.length > 0) {
          var noneOf = ViewModel.pdData.thirdPartyAssociation[0].type;
          if (noneOf === 'NONE') {
            ViewModel.thirdPartyAssociation['NONE'] = true;
          }
        }
        if (ViewModel.pdData.nocSentDate) {
          var date = ViewModel.pdData.nocSentDate;
          ViewModel.pdData.nocSentDate = moment(date).format('LL');
        }
        if (ViewModel.pdData.associateName) {
          ViewModel.pdData.selectAssociateName = ViewModel.pdData.associateName;
        }

        if (ViewModel.pdData.rmChangeAudit) {
          var _date = ViewModel.pdData.rmChangeAudit.updatedAt;
          ViewModel.pdData.rmChangeAudit.updateAt = moment(_date).format('lll');
        }

        // if (ViewModel.pdData.pospDetail === null) {
        //   ViewModel.pdData.pospDetail = {};
        // }
      }
    }

    /**
     * getActivity function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getActivity() {
      var deferred = $q.defer();
      ViewModel.loadingPromise = deferred.promise;
      var jsonData = angular.copy(initialParams);
      jsonData.limit = ViewModel.query.limit;
      jsonData.skip = (ViewModel.query.page - 1) * ViewModel.query.limit;
      PartnerService.getActivity(jsonData, ViewModel.partnerId).then(function (res) {
        var lmsActivity = res.data;
        ViewModel.lmsActivity = lmsActivity.data.data;
        deferred.resolve(ViewModel.lmsActivity);
      }).catch(function (err) {
        deferred.reject(err);
      });
    }

    /**
     * AddNewActivity function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function addNewActivity() {
      // console.log('source', source);
      TrackingService.pushToGTMDatalayer('PLM_ADD_NEW_ACTIVITY_LOG', 'track_event', {});
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/add-new-activity.tpl.html',
        clickOutsideToClose: true,
        controller: 'addNewActivityCtrl',
        locals: {
          parentModel: ViewModel.pdData
        }
      });
    }
    /**
     * ShowActivity function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function showActivity() {
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/show-activity.tpl.html',
        clickOutsideToClose: true,
        controller: 'showActivityCtrl',
        locals: {
          partnerID: ViewModel.pdData.id
        }
      });
    }
    /**
     * elitePlanChange function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function elitePlanChange(value) {
      ViewModel.elitePlanFlag = value.displayName;
      ViewModel.partnerLevel = ViewModel.elitePlanSelection && ViewModel.elitePlanSelection.value || ViewModel.pdData.partnerLevel;
      ViewModel.pdData.partnerLevel = ViewModel.partnerLevel;
      ViewModel.pdData.subscriptionType = ViewModel.partnerLevel;
    }
    /**
     * splitPayoutChange function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function splitPayoutChange(splitPayout) {
      ViewModel.pdData.splitPayoutPercentage = splitPayout.value;
      ViewModel.pdData.splitPayouts = [{ vertical: splitPayout.vertical, splitPercentage: splitPayout.value }];
    }
    /**
     * getIndexFromObj function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function getIndexFromObj(key, value, arr) {
      var find = false;
      var find_idx = void 0;
      arr.forEach(function (ele, idx) {
        if (ele[key] === value) {
          find = true;
          find_idx = idx;
        }
      });
      return find_idx;
    }
    /**
     * $locationChangeStart on function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    $scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
      if (oldUrl.indexOf('partner-review') > -1) {
        onSaveAction(ViewModel.pdData, true);
        event.preventDefault();
      }

      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }

      if (angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
        event.preventDefault();
      }
    });

    /**
     * deleteUnusedKey function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function deleteUnusedKey() {
      if (ViewModel.pdData.partnerType === 'partner_level_2') {
        delete ViewModel.pdData.reportingTo;
        delete ViewModel.pdData.assignTo;
        delete ViewModel.pdData.teamLead;
        // delete ViewModel.pdData.designation;
        delete ViewModel.pdData.supervisor;
        delete ViewModel.pdData.supervisorId;
      } else if (['partner_level_1', 'small_business', 'dealership_partner', 'franchise_partner'].includes(ViewModel.pdData.partnerType)) {
        delete ViewModel.pdData.assignToL1;
        delete ViewModel.pdData.um;
        delete ViewModel.pdData.parent;
        delete ViewModel.pdData.parentId;
        // delete ViewModel.pdData.designation;
      } else if (['employee', 'telemarketing_employees'].includes(ViewModel.pdData.partnerType)) {
        delete ViewModel.pdData.assignTo;
        delete ViewModel.pdData.teamLead;
        delete ViewModel.pdData.assignToL1;
        delete ViewModel.pdData.um;
        delete ViewModel.pdData.parent;
        delete ViewModel.pdData.parentId;
        // delete ViewModel.pdData.pospType;
        // delete ViewModel.pdData.pospDetail;
        delete ViewModel.pdData.branchLocation;
      } else {
        delete ViewModel.pdData.parent;
        delete ViewModel.pdData.parentId;
        delete ViewModel.pdData.reportingTo;
        delete ViewModel.pdData.assignTo;
        delete ViewModel.pdData.teamLead;
        delete ViewModel.pdData.assignToL1;
        delete ViewModel.pdData.um;
        delete ViewModel.pdData.supervisor;
        delete ViewModel.pdData.supervisorId;
      }
      if (ViewModel.pdData.pospType === 'posp') {
        delete ViewModel.pdData.associatedPOSP;
        delete ViewModel.pdData.associatedPOSPId;
      } else if (ViewModel.pdData.pospType === 'nonPosp') {
        delete ViewModel.pdData.pospDetail;
      }
    }
    /**
     * showCompletePopUp function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function showCompletePopUp() {
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/complete-dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          items: ViewModel.pdData.status,
          completed: ViewModel.pdData.completed,
          rejectionReason: ViewModel.rejectionReason
        },
        controller: 'updatePartnerCtrl'
      }).then(function (save) {
        if (save.save) {
          onVerifyAction(save.status, save.remarks);
        }
      });
    }
    /**
     * addPospInsurar function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function addPospInsurar() {
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/add-insurer.tpl.html',
        clickOutsideToClose: true,
        controller: addPospInsurerDialog,
        locals: {
          life_insurers_list: ViewModel.life_insurers_list,
          general_insurers_list: ViewModel.general_insurers_list
        }
      }).then(function (answer) {
        if (answer) {
          ViewModel.pospInsurerDetails.push(answer);
        }
      }, function () {});

      addPospInsurerDialog.$inject = ['$scope', '$mdDialog', 'general_insurers_list', 'life_insurers_list'];
      function addPospInsurerDialog($scope, $mdDialog, general_insurers_list, life_insurers_list) {
        $scope.posButtonDisabled = true;
        $scope.insurerPOSPCode = {};
        $scope.insurersList = [];
        $scope.pospFields = [{
          name: 'Vertical',
          code: 'vertical',
          placeholder: 'Select Vertical',
          type: 'select',
          changeFn: _verticalChangeFn,
          required: true,
          selectConfig: {
            options: [{
              value: 'motor',
              text: 'Motor'
            }, {
              value: 'health',
              text: 'Health'
            }, {
              value: 'life',
              text: 'Life'
            }],
            valueKey: 'value',
            textKey: 'text'
          }
        }, {
          name: 'Insurer',
          code: 'insurerCode',
          placeholder: 'Select Insurer',
          type: 'select',
          changeFn: _insurerChangeFn,
          required: true,
          selectConfig: {
            options: $scope.insurersList,
            valueKey: 'code',
            textKey: 'name'
          }
        }, {
          name: 'PoSP code',
          code: 'pospCode',
          type: 'text',
          placeholder: 'poSP Code',
          required: true
        }];
        function _verticalChangeFn(selectedItem) {
          if (selectedItem === 'motor') {
            var insurersList = general_insurers_list;
            spliceFn(insurersList);
          } else if (selectedItem === 'health') {
            var insurersList = general_insurers_list;
            spliceFn(insurersList);
          } else if (selectedItem === 'life') {
            var insurersList = life_insurers_list;
            spliceFn(insurersList);
          }
        }

        function _insurerChangeFn(selectedItem, model) {
          var insurersListRes = $scope.insurersList;
          for (var idx = 0; idx < insurersListRes.length; idx++) {
            if (insurersListRes[idx].code === selectedItem) {
              model.insurerName = insurersListRes[idx].name;
            }
          }
        }

        function spliceFn(res) {
          $scope.insurersList.splice(0, $scope.insurersList.length);
          for (var idx = 0; idx < res.length; idx++) {
            $scope.insurersList.push(res[idx]);
          }
        }

        $scope.closeDialog = function () {
          $mdDialog.cancel();
        };

        $scope.addInsurer = function () {
          $mdDialog.hide($scope.insurerPOSPCode);
        };
        $scope.$watch(function ($scope) {
          var _ref8 = $scope.insurerPOSPCode || {},
              vertical = _ref8.vertical,
              insurerName = _ref8.insurerName,
              insurerCode = _ref8.insurerCode,
              pospCode = _ref8.pospCode;

          if (vertical && insurerName && insurerCode) {
            if (ViewModel.pospInsurerDetails.length > 0) {
              for (var i = 0; i < ViewModel.pospInsurerDetails.length; i++) {
                var _ref9 = ViewModel.pospInsurerDetails[i] || {},
                    _insurerName = _ref9.insurerName,
                    _vertical = _ref9.vertical;

                if ($scope.insurerPOSPCode && _insurerName === $scope.insurerPOSPCode.insurerName && _vertical === $scope.insurerPOSPCode.vertical) {
                  $scope.posButtonDisabled = true;
                  $scope.pospError = 'POSP Code is already created';
                  break;
                } else if (pospCode && pospCode.length > 0) {
                  $scope.posButtonDisabled = false;
                  $scope.pospError = '';
                } else {
                  $scope.pospError = '';
                  $scope.posButtonDisabled = true;
                }
              }
            } else if (pospCode && pospCode.length > 0) {
              $scope.posButtonDisabled = false;
            } else {
              $scope.posButtonDisabled = true;
            }
          } else {
            $scope.posButtonDisabled = true;
          }
        });
      }
    }

    function changeAssociate() {
      ViewModel.changeState = !ViewModel.changeState;
      // console.log('change', ViewModel.changeState);
    }

    function addAssociate(event) {
      // const parentScope = $scope.$new();
      // $scope.addpartner = true;
      // $scope.addedpartner = false;
      // $scope.unableToAdd = false;
      // $scope.modalInfo = {
      //   title: 'Add new associate',
      //   type: 'associate'
      // };
      var dataObj = {
        addpartner: true,
        addedpartner: false,
        unableToAdd: false,
        tenant: tenant,
        modalInfo: {
          title: 'Add new associate',
          type: 'associate'
        }
      };
      if (ViewModel.partnerDetailsForm.$invalid) {
        $window.alert('Please check form \'some fields\' are required');
        setDirtyField();
        return;
      } else {
        ViewModel.pdData.createdAt = parseInt(moment(document.createdAt).format('x')) || null;
        ViewModel.pdData.dateOfBirth = parseInt(moment(document.dateOfBirth).format('x')) || null;
        ViewModel.pdData.lastWorkingDate = parseInt(moment(document.lastWorkingDate).format('x')) || null;
        PartnerService.updatePartner(ViewModel.pdData.id, tenant, ViewModel.pdData).then(function (res) {
          if (res.status === 200) {
            $mdDialog.show({
              templateUrl: 'dev/partner/templates/dialogs/add-partner.tpl.html',
              clickOutsideToClose: true,
              controller: 'partnerAddCtrl',
              targetEvent: event,
              // scope: parentScope,
              locals: {
                data: dataObj
              }
            });
          }
        }).catch(function (err) {
          if (err.data && err.data.meta && err.data.meta.msg) {
            commonService.showToast(err.data.meta.msg);
          } else {
            commonService.showToast(ViewModel.someErrorOccured);
          }
        });
      }
    }
    /**
     * deletePospPrompt function
     *
     * @public
     *
     * @function
     *
     * @author sumantaparida
     *
     */
    function deletePospPrompt(index) {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'dev/partner/templates/dialogs/delete-posp-dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          index: index
        },
        controller: confirmDeletePospCode
      }).then(function (index) {
        ViewModel.pospInsurerDetails.splice(index, 1);
      }, function () {});
      confirmDeletePospCode.$inject = ['$scope', '$mdDialog', 'index'];
      function confirmDeletePospCode($scope, $mdDialog, index) {
        $scope.deletePosp = function () {
          $mdDialog.hide(index);
        };
        $scope.close = function () {
          $mdDialog.cancel();
        };
      }
    }

    /**
     * Hardcoded function to allow/disallow docs on the basis of tenant
     */
    function supplyDocsWRTConfig(documentArr) {
      var showList = ['CERTIFICATE', 'certificate_li'];
      //Ignore below docs for all Tenants except TATA
      var ignore = ['unsigned_franked_doc', 'signed_franked_doc'];
      // console.log('======'+tenant+'======');
      if (['reliancejio'].includes(sessionStorage.getItem('tenant'))) {
        return documentArr.filter(function (ele) {
          return showList.includes(ele.name);
        });
      }
      if (tenant !== 'tataaig') {
        //remove docs from object
        return documentArr.filter(function (ele) {
          return !ignore.includes(ele.name);
        });
      }
      //Show all docs
      return documentArr;
    }

    // CertificateDetails itteration

    function getCertificateDetails(data) {
      ViewModel.typeOfPospList.typeOfPosp = [];
      if (data.certificateDetails && data.certificateDetails !== null) {
        data.certificateDetails.forEach(function (ele) {
          if (ele.pospType === 'posp') {
            ViewModel.posp.pospLicenseNumber = ele.licenseNumber;
            ViewModel.posp.pospExpiryDate = ele.expiryDate;
            ViewModel.pospSP.assignAsParent = ele.assignAsParent;
          } else if (ele.pospType === 'bqp') {
            ViewModel.pospBQP.bqpLicenseNumber = ele.licenseNumber;
            ViewModel.pospBQP.bqpExpiryDate = ele.expiryDate;
          } else if (ele.pospType === 'bqpgi') {
            ViewModel.pospBQP.bqpgiLicenseNumber = ele.licenseNumber;
            ViewModel.pospBQP.bqpgiExpiryDate = ele.expiryDate;
          } else if (ele.pospType === 'bqpli') {
            ViewModel.pospBQP.bqpliLicenseNumber = ele.licenseNumber;
            ViewModel.pospBQP.bqpliExpiryDate = ele.expiryDate;
          } else if (ele.pospType === 'bqpcomposite') {
            ViewModel.pospBQP.bqpCompositeLicenseNumber = ele.licenseNumber;
            ViewModel.pospBQP.bqpCompositeExpiryDate = ele.expiryDate;
          } else if (ele.pospType === 'pospgi') {
            ViewModel.typeOfPospList.typeOfPosp.push(ele.pospType);
            ViewModel.getPospGIList.PoSPGI_licenseNumber = ele.licenseNumber;
            ViewModel.getPospGIList.PoSPGI_expiryDate = ele.expiryDate;
            ViewModel.getPospGIList.PoSP_GI_iib = ele.iibUploadDate;
            ViewModel.pospSP.assignAsParent = ele.assignAsParent;
            ViewModel.registeredAtIrda = ViewModel.pdData.registeredAtIrda;
            ViewModel.hasIRDA = true;
          } else if (ele.pospType === 'pospli') {
            ViewModel.typeOfPospList.typeOfPosp.push(ele.pospType);
            ViewModel.getPospLIList.PoSPLI_licenseNumber = ele.licenseNumber;
            ViewModel.getPospLIList.PoSPLI_expiryDate = ele.expiryDate;
            ViewModel.getPospLIList.PoSP_LI_iib = ele.iibUploadDate;
            ViewModel.pospSP.assignAsParent = ele.assignAsParent;
            ViewModel.registeredAtIrda = ViewModel.pdData.registeredAtIrda;
            ViewModel.hasIRDA = true;
          } else if (ele.pospType === 'spli') {
            ViewModel.typeOfPospList.typeOfSp = ele.pospType;
            ViewModel.spLI.SPLI_licenseNumber = ele.licenseNumber;
            ViewModel.spLI.SPLI_expiryDate = ele.expiryDate;
            ViewModel.spLI.SP_LI_iib = ele.iibUploadDate;
          } else if (ele.pospType === 'spgi') {
            ViewModel.typeOfPospList.typeOfSp = ele.pospType;
            ViewModel.spGI.SPGI_licenseNumber = ele.licenseNumber;
            ViewModel.spGI.SPGI_expiryDate = ele.expiryDate;
            ViewModel.spGI.SP_GI_iib = ele.iibUploadDate;
          } else if (ele.pospType === 'spcomposite') {
            ViewModel.typeOfPospList.typeOfSp = ele.pospType;
            ViewModel.spCOMPOSITE.SPCOMPOSITE_licenseNumber = ele.licenseNumber;
            ViewModel.spCOMPOSITE.SPCOMPOSITE_expiryDate = ele.expiryDate;
            ViewModel.spCOMPOSITE.SP_COMPOSITE_iib = ele.iibUploadDate;
          } else if (['nonPosp', 'na', 'sp'].includes(ele.pospType)) {
            ViewModel.pospSP = ele;
          }
          if (data.pospType === 'sp') {
            ViewModel.pdData.pospType = 'sp';
            if (ele.pospType === 'spli') {
              data.typeOfSp = ele.pospType;
              data.SPLI_licenseNumber = ele.licenseNumber;
              data.SPLI_expiryDate = ele.expiryDate;
              data.SP_LI_iib = ele.iibUploadDate;
            } else if (ele.pospType === 'spgi') {
              data.typeOfSp = ele.pospType;
              data.SPGI_licenseNumber = ele.licenseNumber;
              data.SPGI_expiryDate = ele.expiryDate;
              data.SP_GI_iib = ele.iibUploadDate;
            } else if (ele.pospType === 'spcomposite') {
              data.typeOfSp = ele.pospType;
              data.SPCOMPOSITE_licenseNumber = ele.licenseNumber;
              data.SPCOMPOSITE_expiryDate = ele.expiryDate;
              data.SP_COMPOSITE_iib = ele.iibUploadDate;
            }
          }
        });
      }
    }

    // function validateCertificationDetails(){
    //
    // }

    function updateCertificateDetails(data) {
      var compliantStatus = sessionStorage.getItem('compliantEnabled');
      data.certificateDetails = [];
      if (data.pospType === 'sp') {
        if (['idfcfirstbank', 'federal'].includes(ViewModel.broker)) {
          var spGIObj = {};
          var spLIObj = {};
          var spCOMPOSITEObj = {};
          var typeOfSp = ViewModel.typeOfPospList.typeOfSp;
          if (typeOfSp === 'spgi') {
            spGIObj.pospType = 'spgi';
            spGIObj.expiryDate = parseInt(moment(ViewModel.spGI.SPGI_expiryDate).format('x')) || null;
            spGIObj.licenseNumber = ViewModel.spGI.SPGI_licenseNumber;
            spGIObj.iibUploadDate = parseInt(moment(ViewModel.spGI.SP_GI_iib).format('x')) || null;
            data.certificateDetails.push(spGIObj);
          } else if (typeOfSp === 'spli') {
            spLIObj.pospType = 'spli';
            spLIObj.expiryDate = parseInt(moment(ViewModel.spLI.SPLI_expiryDate).format('x')) || null;
            spLIObj.licenseNumber = ViewModel.spLI.SPLI_licenseNumber && ViewModel.spLI.SPLI_licenseNumber;
            spLIObj.iibUploadDate = parseInt(moment(ViewModel.spLI.SP_LI_iib).format('x')) || null;
            data.certificateDetails.push(spLIObj);
          } else if (typeOfSp === 'spcomposite') {
            spCOMPOSITEObj.pospType = 'spcomposite';
            spCOMPOSITEObj.expiryDate = parseInt(moment(ViewModel.spCOMPOSITE.SPCOMPOSITE_expiryDate).format('x')) || null;
            spCOMPOSITEObj.licenseNumber = ViewModel.spCOMPOSITE.SPCOMPOSITE_licenseNumber && ViewModel.spCOMPOSITE.SPCOMPOSITE_licenseNumber;
            spCOMPOSITEObj.iibUploadDate = parseInt(moment(ViewModel.spCOMPOSITE.SP_COMPOSITE_iib).format('x')) || null;
            data.certificateDetails.push(spCOMPOSITEObj);
          }
        } else {
          data.pospDetail = ViewModel.pospSP;
          ViewModel.pospSP.expiryDate = parseInt(moment(ViewModel.pospSP.expiryDate).format('x')) || null;
          ViewModel.pospSP.pospType = ViewModel.pdData.pospType;
          data.certificateDetails.push(ViewModel.pospSP);
        }
      } else if (['nonPosp', 'na'].includes(data.pospType)) {
        data.certificateDetails.push({ pospType: data.pospType });
      } else if (data.pospType === 'bqp') {
        var obj = {};
        obj.licenseNumber = ViewModel.pospBQP.bqpLicenseNumber;
        obj.expiryDate = parseInt(moment(ViewModel.pospBQP.bqpExpiryDate).format('x')) || null;
        obj.pospType = ViewModel.pdData.pospType;
        data.certificateDetails.push(obj);
      } else if (data.pospType === 'posp' && compliantStatus !== 'false') {
        var pospStr = ViewModel.typeOfPospList.typeOfPosp;
        pospStr.forEach(function (ele) {
          if (ele === 'pospgi') {
            var _obj = {};
            _obj.pospType = ele;
            _obj.expiryDate = parseInt(moment(ViewModel.getPospGIList.PoSPGI_expiryDate).format('x')) || null;
            _obj.licenseNumber = ViewModel.getPospGIList.PoSPGI_licenseNumber;
            _obj.iibUploadDate = parseInt(moment(ViewModel.getPospGIList.PoSP_GI_iib).format('x')) || null;
            _obj.assignAsParent = ViewModel.pospSP.assignAsParent;
            data.certificateDetails.push(_obj);
          }
          if (ele === 'pospli') {
            var _obj2 = {};
            _obj2.pospType = ele;
            _obj2.expiryDate = parseInt(moment(ViewModel.getPospLIList.PoSPLI_expiryDate).format('x')) || null;
            _obj2.licenseNumber = ViewModel.getPospLIList.PoSPLI_licenseNumber;
            _obj2.iibUploadDate = parseInt(moment(ViewModel.getPospLIList.PoSP_LI_iib).format('x')) || null;
            _obj2.assignAsParent = ViewModel.pospSP.assignAsParent;
            data.certificateDetails.push(_obj2);
          }
        });
      } else if (data.pospType === 'posp' && compliantStatus !== 'true') {
        var _obj3 = {};
        _obj3.licenseNumber = ViewModel.posp.pospLicenseNumber;
        _obj3.expiryDate = parseInt(moment(ViewModel.posp.pospExpiryDate).format('x')) || null;
        _obj3.pospType = ViewModel.pdData.pospType;
        data.certificateDetails.push(_obj3);
      } else if (ViewModel.broker === 'yesbank' && ViewModel.configRole === 'PARTNER') {
        data.certificateDetails.push({ pospType: data.pospType });
      }
    }

    // ANCHOR: Validate Bank Details With CashFree
    var bankDetails = ViewModel.pdData.bankDetails;
    var verified = bankDetails.verified,
        validationRetries = bankDetails.validationRetries,
        verificationMode = bankDetails.verificationMode;

    ViewModel.accountVerified = ['turtlemint'].includes(ViewModel.broker) ? verified : false;
    ViewModel.buttonText = ViewModel.accountVerified ? 'Add bank details' : 'Validate Bank Details';
    ViewModel.verifybuttonDisabled = ViewModel.deActivateProfileBtn || [0, -1].includes(validationRetries);
    // ANCHOR: Bank manual mode
    ViewModel.manualButton = ['manual', 'cashfree'].includes(verificationMode) && !verified;

    // Task(M30-4577): for disabling bank details fields in PLM post verification
    // case: if bank details are not verified and profile is in verified state
    if (ViewModel.partnerLead && partner.status === 'verified' && ViewModel.broker === 'turtlemint' && !ViewModel.pdData.bankDetails.verified) {
      ViewModel.accountVerified = !ViewModel.accountVerified;
    }

    function _validateBankDetailsFun(param) {
      // TODO: for reset
      if (ViewModel.accountVerified) {
        ViewModel.accountVerified = !ViewModel.accountVerified;
        ViewModel.buttonText = 'Validate Bank Details';
        ViewModel.verifiedBy = 'Not Verified';
        ViewModel.pdData.bankDetails = {
          relationship: 'self',
          validationRetries: ViewModel.pdData.bankDetails && ViewModel.pdData.bankDetails.validationRetries
        };
        ViewModel.manualButton = true;
      } else {
        _bankObjNotBlank(param);
      }
    }

    // ANCHOR: Check Bank OBJ Cannot Blank
    function _bankObjNotBlank(param) {
      //_updateBankDetails();
      var bankDetails = ViewModel.pdData.bankDetails;

      var _ref10 = bankDetails || {},
          accHolderName = _ref10.accHolderName,
          accNo = _ref10.accNo,
          bankName = _ref10.bankName,
          branch = _ref10.branch,
          ifsccode = _ref10.ifsccode;

      var bankObjfilled = accHolderName && accNo && bankName && branch && ifsccode;
      if (bankObjfilled) {
        _accountValiodationCashFreeFun(param);
      } else {
        alert('Bank details cannot be blank');
      }
    }

    function _updateBankDetails() {
      var bankDetails = ViewModel.pdData.bankDetails;

      var _ref11 = ViewModel.pdData || {},
          accHolderName = _ref11.accHolderName,
          accNo = _ref11.accNo,
          bankName = _ref11.bankName,
          branch = _ref11.branch,
          ifsccode = _ref11.ifsccode;

      bankDetails.accHolderName = accHolderName;
      bankDetails.accNo = accNo;
      bankDetails.bankName = bankName;
      bankDetails.branch = branch;
      bankDetails.ifsccode = ifsccode;

      ViewModel.pdData.bankDetails = bankDetails;
    }

    // ANCHOR: Cashfree account validateor
    function _accountValiodationCashFreeFun(param) {
      var _ViewModel$pdData3 = ViewModel.pdData,
          bankDetails = _ViewModel$pdData3.bankDetails,
          id = _ViewModel$pdData3.id;
      var accHolderName = bankDetails.accHolderName,
          accNo = bankDetails.accNo,
          bankName = bankDetails.bankName,
          branch = bankDetails.branch,
          ifsccode = bankDetails.ifsccode,
          relationship = bankDetails.relationship,
          validationRetries = bankDetails.validationRetries,
          verified = bankDetails.verified,
          validatedName = bankDetails.validatedName;

      var bankObj = {
        id: id,
        accHolderName: accHolderName,
        accNo: accNo,
        bankName: bankName,
        branch: branch,
        ifsccode: ifsccode,
        relationship: relationship,
        validationRetries: validationRetries,
        verified: verified,

        verificationMode: param
      };
      PartnerService.validateBankDetails(bankObj).then(function (res) {
        var _res$data$data = res.data.data,
            bankDetails = _res$data$data.bankDetails,
            bankDetailsVerifier = _res$data$data.bankDetailsVerifier;
        var verified = bankDetails.verified,
            validatedName = bankDetails.validatedName,
            accHolderName = bankDetails.accHolderName,
            validationRetries = bankDetails.validationRetries,
            verificationMode = bankDetails.verificationMode;
        var msg = res.data.meta.msg;

        ViewModel.accountVerified = verified;
        ViewModel.pdData.bankDetails = res.data.data.bankDetails;
        ViewModel.pdData.bankDetailsVerifier = bankDetailsVerifier || {};
        ViewModel.verifybuttonDisabled = [0, -1].includes(validationRetries);
        ViewModel.manualButton = ['manual', 'cashfree'].includes(verificationMode) && !verified;
        if (!['manual'].includes(param) && validatedName !== accHolderName) {
          $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: 'dev/partner/templates/dialogs/alert-bankaccount-dialog.tpl.html',
            controller: _confirmReplaceBankDetails,
            locals: {
              bankDetails: _extends({}, bankDetails, { id: id })
            }
          }).then(function () {}, function () {
            ViewModel.manualButton = true;
          });
        } else {
          _checkVerifiedLabelFun(bankDetails);
          ViewModel.buttonText = 'Add Bank Details';
          commonService.showToast(msg);
        }
      }).catch(function (err) {
        var data = err.data;
        var meta = data.meta;
        var msg = meta.msg;
        var _data$data$bankDetail = data.data.bankDetails,
            validationRetries = _data$data$bankDetail.validationRetries,
            verificationMode = _data$data$bankDetail.verificationMode,
            verified = _data$data$bankDetail.verified;

        ViewModel.accountVerified = verified;
        bankDetails.validationRetries = validationRetries;
        ViewModel.manualButton = ['manual', 'cashfree'].includes(verificationMode) && !verified;
        _checkVerifiedLabelFun(data.data.bankDetails);
        ViewModel.pdData.bankDetails = data.data.bankDetails;
        ViewModel.pdData.bankDetailsVerifier = data.data.bankDetailsVerifier;
        ViewModel.verifybuttonDisabled = [0, -1].includes(bankDetails.validationRetries);
        commonService.showToast(msg);
      });
    }

    // ANCHOR: Controller for confirm Dialog
    _confirmReplaceBankDetails.$inject = ['$scope', '$mdDialog', 'bankDetails'];
    function _confirmReplaceBankDetails($scope, $mdDialog, bankDetails) {
      var accHolderName = bankDetails.accHolderName,
          validatedName = bankDetails.validatedName;

      $scope.accHolderName = accHolderName;
      $scope.validatedName = validatedName;
      $scope._confirmReplace = function () {
        bankDetails.accHolderName = bankDetails.validatedName;
        var upBankObj = bankDetails;
        upBankObj.verified = true;
        // ANCHOR: Update call for bank details
        PartnerService.validateBankDetails(upBankObj).then(function (res) {
          var data = res.data.data;

          ViewModel.pdData.bankDetails = data;
          ViewModel.accountVerified = data.verified;
          ViewModel.buttonText = 'Add Bank Details';
          $mdDialog.hide();
          $state.reload();
        }).catch(function (err) {
          return commonService.showToast(err.data.meta.msg);
        });
      };
      $scope.close = function () {
        PartnerService.validateBankDetails(bankDetails).then(function () {
          $mdDialog.hide();
        }).catch(function (err) {
          return commonService.showToast(err.data.meta.msg);
        });
        $mdDialog.cancel();
      };
    }
    // ANCHOR: verified/nonVerified/manualVerified function
    function _checkVerifiedLabelFun(bankDetails) {
      var verified = bankDetails.verified,
          verificationMode = bankDetails.verificationMode;

      var manual = ['manual'].includes(verificationMode);
      if (verified && manual) {
        ViewModel.verifiedBy = 'Manually Verified';
      } else if (verified) {
        ViewModel.verifiedBy = 'Verified';
      } else {
        ViewModel.verifiedBy = 'Not Verified';
      }
    }

    function openSectionNav() {
      TrackingService.pushToGTMDatalayer('PLM_SECTION_NAV_OPEN', 'track_event', {});
      $mdBottomSheet.show({
        templateUrl: 'dev/partner/templates/partner-section-nav-mob.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true
      }).then(function () {}).catch(function () {});
    }

    function closeBottomSheet() {
      $mdBottomSheet.cancel();
    }

    function toggleShowDocuments() {
      ViewModel.showDocuments = !ViewModel.showDocuments;
      var action = ViewModel.showDocuments ? 'FILES' : 'FORM';
      TrackingService.pushToGTMDatalayer('PLM_DETAIL_GOTO_' + action, 'track_event', {});
    }

    /**
     * // TASK(M30-4576): Automating the NOC generation process on PM
     * generateNOCPopUp() function
     *
     * @public
     *
     * @function
     *
     * @author ankitsangodkar
     *
     */
    function generateNOCPopUp() {
      $mdDialog.show({
        templateUrl: 'dev/partner/templates/dialogs/alert-generate-noc-dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          partnerId: ViewModel.pdData.id,
          tenant: ViewModel.pdData.organisation
        },
        controller: 'GenerateNocDialog'
      });
    }
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');
  // Controller
  brokerAdmin.controller('updatePartnerCtrl', updatePartnerCtrl);
  // Dependancy
  updatePartnerCtrl.$inject = ['$scope', '$stateParams', '$mdDialog', 'items', 'rejectionReason', 'completed'];
  // Function
  function updatePartnerCtrl($scope, $stateParams, $mdDialog, items, rejectionReason, completed) {
    $scope.profileStatus = items === 'rejected' ? 'rejected' : 'save';
    $scope.rejectionReason = rejectionReason;
    $scope.verifiedActionHide = !(!['verified'].includes(items) && completed === false);
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    $scope.checkedArr = [];

    $scope.saveChangesPartner = function () {
      $scope.remarks = $scope.checkedArr.join('\n');
      $mdDialog.hide({ save: true, status: $scope.profileStatus, remarks: $scope.remarks });
    };

    $scope.checkedVal = function (reason) {
      if (reason.checked) {
        $scope.checkedArr.push(reason.value);
      } else {
        $scope.checkedArr.splice($scope.checkedArr.indexOf(reason.value), 1);
      }
    };
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');
  // Controller
  brokerAdmin.controller('alertDialogCtrl', alertDialogCtrl);
  // Dependancy
  alertDialogCtrl.$inject = ['$scope', '$stateParams', '$mdDialog', 'data', 'hasNocCertificate'];
  // Function
  function alertDialogCtrl($scope, $stateParams, $mdDialog, data, hasNocCertificate) {
    // Reason list
    $scope.selectedItemList = [{
      level: "NOC Required",
      value: "NOC Required"
    }, {
      level: "Fraudulent Activity",
      value: "Fraudulent Activity"
    }, {
      level: "Pan Number is Linked with another DP code",
      value: "Pan Number is Linked with another DP code"
    }, {
      level: "Partner Requested",
      value: "Partner Requested"
    }, {
      level: "Sales team requested",
      value: "Sales team requested"
    }, {
      level: "New ID created against old ID",
      value: "New ID created against old ID"
    }, {
      level: "Partner not interested to work",
      value: "Partner not interested to work"
    }, {
      level: "Partner joined as Employee",
      value: "Partner joined as Employee"
    }, {
      level: "Two IDs created with Same documents",
      value: "Two IDs created with Same documents"
    }, {
      level: "Deactivated for QC Reason",
      value: "Deactivated for QC Reason"
    }, {
      level: "Other",
      value: "Other"
    }];

    $scope.data = data;
    //TASK(M30-4576): Automating the NOC generation process on PM
    $scope.hasNocCertificate = hasNocCertificate;
    // Cencle dialog
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    //pass value to request body
    $scope.onClickingYes = function () {
      $scope.optionValue = $scope.selectedItemListlevel === "Other" ? $scope.reasonValue : $scope.selectedItemListlevel;
      $mdDialog.hide({ save: true, reasonValue: $scope.optionValue });
    };
  }
})();
"use strict";

//jshint esversion:6
(function () {
  angular.module("brokerAdmin.partner").controller("setOwnerUpdateCtrl", controller);
  controller.$inject = ["$scope", "$mdDialog", "data", "AuthService", "$state", "PartnerService"];

  function controller($scope, $mdDialog, data, AuthService, $state, PartnerService) {
    $scope.setOwner = function () {
      var currentUser = AuthService.getUser();
      data.lead.leadOwner = {
        name: currentUser.fullName,
        email: currentUser.email,
        mobile: currentUser.mobile
      };
      var jsonData = data.lead;
      var params = {};
      PartnerService.setOwnerLMS(jsonData, params).then(function () {
        $mdDialog.hide();
        $state.go("partner-lead-detail", {
          tenant: data.tenant,
          id: data.lead.id
        });
      }).catch(function () {
        $mdDialog.hide();
      });
    };
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    $scope.viewOnly = function () {
      // let currentUser = AuthService.getUser();
      // let qisUser = $scope.lead.leadOwner;
      $mdDialog.hide();
      $state.go("partner-lead-detail", {
        tenant: data.tenant,
        id: data.lead.id
      });
    };
  }
})();
'use strict';

//jshint esversion:6
(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  // Controller 
  brokerAdmin.controller('addNewActivityCtrl', addNewActivityCtrl);

  // Dependancy
  addNewActivityCtrl.$inject = ['$scope', '$mdDialog', 'PartnerService', 'commonService', 'parentModel', 'LEAD_CONSTANTS'];

  // Function
  function addNewActivityCtrl($scope, $mdDialog, PartnerService, commonService, parentModel, LEAD_CONSTANTS) {
    var CONSTANTS = angular.copy(LEAD_CONSTANTS);
    $scope.currentDate = new Date();
    $scope.newActivity = {};
    $scope.newActivity.activityType = 'incoming';
    $scope.close = function () {
      $mdDialog.hide();
    };
    $scope.updateActivity = function () {
      var jsondata = $scope.newActivity;
      // Extra Params
      jsondata.callPhoneNumber = parentModel.mobile;
      jsondata.partnerId = parentModel.username;
      var params = { tenant: parentModel.tenant };
      if ($scope.activityForm.$invalid) {
        angular.forEach($scope.activityForm.$error.required, function (field) {
          field.$setDirty();
        });
        return;
      } else {
        PartnerService.addNewActivity(jsondata, params).then(function (res) {
          if (res) {
            $mdDialog.hide();
            var msg = res.data.data.activityType + ' activity log updated successfully';
            commonService.showToast(msg);
          }
        }).catch(function (err) {
          $mdDialog.hide();
          commonService.showToast(err);
        });
      }
    };
    $scope.newActivityForm = CONSTANTS.newActivityForm;
  }
})();
'use strict';

(function () {
  var brokerAdmin = angular.module('brokerAdmin');

  // Controller
  brokerAdmin.controller('showActivityCtrl', showActivityCtrl);

  // Dependancy
  showActivityCtrl.$inject = ['$scope', '$mdDialog', '$q', 'PartnerService', 'partnerID', 'LEAD_CONSTANTS'];

  // Function
  function showActivityCtrl($scope, $mdDialog, $q, PartnerService, partnerID, LEAD_CONSTANTS) {
    var CONSTANTS = angular.copy(LEAD_CONSTANTS);
    $scope.getActivity = getActivity;
    $scope.activityColumn = CONSTANTS.activityColumn;
    $scope.query = CONSTANTS.query;
    $scope.search = CONSTANTS.search;
    $scope.limitOptions = CONSTANTS.limitOptions;
    $scope.initialParams = {};
    getActivity();

    // Functions
    function getActivity() {
      var deferred = $q.defer();
      $scope.loadingPromise = deferred.promise;
      var jsonData = angular.copy($scope.initialParams);
      jsonData.limit = $scope.query.limit;
      jsonData.skip = ($scope.query.page - 1) * $scope.query.limit;
      PartnerService.getActivity(jsonData, partnerID).then(function (res) {
        var activityList = res.data;
        $scope.activityList = activityList.data.data;
        $scope.query.totalCount = activityList.data.count;
        deferred.resolve($scope.activityList);
      }).catch(function (err) {
        deferred.reject(err);
      });
    }

    $scope.close = function () {
      $mdDialog.hide();
    };
  }
})();
'use strict';

//jshint esversion:6
(function () {
    var brokerAdmin = angular.module('brokerAdmin');

    // Controller 
    brokerAdmin.controller('GenerateNocDialog', GenerateNocDialog);

    // Dependancy
    GenerateNocDialog.$inject = ['$scope', '$mdDialog', 'PartnerService', 'commonService', '$state', '$window', 'partnerId', 'tenant'];

    // Function
    function GenerateNocDialog($scope, $mdDialog, PartnerService, commonService, $state, $window, partnerId, tenant) {
        $scope.onNocGeneration = function () {
            $mdDialog.hide();
            PartnerService.addNocDocument(partnerId, tenant).then(function (res) {
                var data = res.data.data;

                PartnerService.downloadDocument(partnerId, tenant, data);
                $state.reload();
            }).catch(function (err) {
                $window.console.log(err);
                if (err.data && err.data.meta && err.data.meta.msg) {
                    commonService.showToast(err.data.meta.msg);
                } else {
                    commonService.showToast("Some Error Ocurred");
                }
            });
        };
        $scope.close = function () {
            $mdDialog.cancel();
        };
    }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.partner').provider('PartnerService', PartnerService);

  PartnerService.$inject = [];
  return PartnerService;
  /**
   * PartnerService
   *
   * @public
   *
   * @author    sumantaparida
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function PartnerService() {
    PartnerService.$inject = ['$q', 'GENERAL_CONFIG', 'commonService', 'CONSTANTS_LIST', 'MIS_CONSTANTS', 'fileService', '$cookies', '$rootScope', '$state', '$mdDialog', 'PartnerAddUpdateService', 'AuthService'];
    return {
      $get: PartnerService
    };
    /**
     * PartnerService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.PartnerService#
     *
     * @author     sumantaparida
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     * @param      {Object}    GENERAL_CONFIG                 general config
     * @param      {Object}    CONSTANTS_LIST                 constants list
     *
     * @return     {Object}    Instance of the Service
     */
    function PartnerService($q, GENERAL_CONFIG, commonService, CONSTANTS_LIST, MIS_CONSTANTS, fileService, $cookies, $rootScope, $state, $mdDialog, PartnerAddUpdateService, AuthService) {

      var apiVersion = '/v1';
      var partnerURL = apiVersion + '/partners';
      var PLM_URL = apiVersion + '/partnerleads/';
      var cities = void 0,
          branchMaster = void 0;
      var currentUser = AuthService.getUser();

      return {
        // Fun
        getConfig: getConfig,
        getTenants: getTenants,
        getCities: getCities,
        getBranchMaster: getBranchMaster,
        getPartner: getPartner,
        addPartner: addPartner,
        downloadDocument: downloadDocument,
        viewDocument: viewDocument,
        deleteDocument: deleteDocument,
        updatePartner: updatePartner,
        searchPartner: searchPartner,
        searchEmployee: searchEmployee,
        getPartnerById: getPartnerById,
        getPartnerSupervisorsById: getPartnerSupervisorsById,
        activate: activate,
        getDropDowns: getDropDowns,
        deActivate: deActivate,
        bulkExport: bulkExport,
        verify: verify,
        reject: reject,
        notVerify: notVerify,
        getHierarchyOfManagers: getHierarchyOfManagers,
        deleteProfile: deleteProfile,
        getPLM: getPLM,
        setOwnerLMS: setOwnerLMS,
        getActivity: getActivity,
        addNewActivity: addNewActivity,
        getReportConfig: getReportConfig,
        visionApi: visionApi,
        updateVisionNumber: updateVisionNumber,
        validateBankDetails: validateBankDetails,
        searchBranch: searchBranch,
        addNocDocument: addNocDocument
      };
      /**
       * Gets the Report config for Partner dump service
       *
       * @returns Promise<Object> all fileIds for particular tenant
       */
      function getReportConfig(tenant) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/reportConfig', { tenant: tenant }, 'GET').then(function (config) {
          return config.data;
        }).catch(function (err) {
          commonService.showToast(err);
          $state.go('/', {}, { reload: true });
        });
      }

      function bulkExport(tenant) {
        var docUrl = '' + GENERAL_CONFIG.serviceURL + partnerURL + '/export';
        return fileService.download(docUrl, { tenant: tenant }, tenant);
      }
      function getConfig(pageType, status) {
        var deferred = $q.defer();
        $rootScope.config = $rootScope.config || {};
        if (!Object.keys($rootScope.config).length) {
          return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + apiVersion, '/config', {}, 'GET').then(function (data) {
            var config = data.data.data;
            $rootScope.config = config;
            PartnerAddUpdateService.populateConfig(config, pageType, status);
            return config;
          }).catch(function (err) {
            commonService.showToast(err);
            $state.go('/', {}, { reload: true });
          });
        } else {
          PartnerAddUpdateService.populateConfig($rootScope.config, pageType, status);
        }
        deferred.resolve($rootScope.config);
        return deferred.promise;
      }

      function getPartnerById(partnerId, tenant) {
        var params = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId, params, 'GET', false).then(function (res) {
          var partnerObj = res.data.data;

          var _ref = partnerObj || {},
              documentNews = _ref.documentNews;

          partnerObj.documentNews = documentNews.filter(function (ele) {
            return ele;
          });
          PartnerAddUpdateService.populatePartner(partnerObj);
          return partnerObj;
        });
      }

      function getTenants() {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/tenants', {}, 'GET');
      }

      function getCities() {
        var returnPromise = $q.defer();
        if (cities) {
          returnPromise.resolve(cities);
        } else {
          getConfig().then(function (res) {
            if (res.dropDowns) {
              cities = res.dropDowns.cities;
              returnPromise.resolve(cities);
            }
            returnPromise.reject(res);
          }).catch(function (err) {
            returnPromise.reject(err.data);
          });
        }

        return returnPromise.promise;
      }

      function getBranchMaster() {
        var returnPromise = $q.defer();
        if (branchMaster) {
          returnPromise.resolve(branchMaster);
        } else {
          getConfig().then(function (res) {
            if (res.dropDowns) {
              branchMaster = res.dropDowns.branchMaster;
              returnPromise.resolve(branchMaster);
            }
            returnPromise.reject(res);
          }).catch(function (err) {
            returnPromise.reject(err.data);
          });
        }
        return returnPromise.promise;
      }

      function getDropDowns() {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + apiVersion, '/dropdowns', {}, 'GET');
      }
      function downloadDocument(partnerId, tenant, docId) {
        var docUrl = GENERAL_CONFIG.mintproAPI + partnerURL + ('/' + partnerId + '/document/' + docId);
        var params = {
          tenant: tenant
        };
        return fileService.download(docUrl, params, docId);
      }
      function viewDocument(partnerId, tenant, docId) {
        var docUrl = GENERAL_CONFIG.mintproAPI + partnerURL + ('/' + partnerId + '/document/' + docId);
        var params = {
          tenant: tenant
        };
        return fileService.viewFile(docUrl, params, docId);
      }

      function deleteDocument(partnerId, tenant, type) {
        var docUrl = GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL + ('/' + partnerId + '/documentType/' + type);
        var params = {
          tenant: tenant
        };
        return fileService.deleteFile(docUrl, params);
      }

      function searchPartner(searchkey, isPOSP, tenant, all, partnerType) {
        var params = {
          tenant: tenant,
          name: searchkey,
          isPOSP: isPOSP,
          all: all,
          partnerType: partnerType
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/search', params, 'GET');
      }

      function searchEmployee(searchkey, tenant, designation) {
        var params = {
          tenant: tenant,
          name: searchkey,
          designation: designation
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + apiVersion + '/employees', '/search', params, 'GET');
      }
      function getHierarchyOfManagers(partnerId, tenant) {
        var deferred = $q.defer();
        if (partnerId) {
          partnerId = partnerId.trim();
        }

        if (!partnerId) {
          deferred.reject();
          return deferred.promise;
        }

        var params = {
          broker: $cookies.get('broker'),
          tenant: tenant,
          saleDate: 1505251803000
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/supervisors', params, 'GET', false);
      }
      function updatePartner(partnerId, tenant, partnerDetails) {
        var postParams = {
          broker: $cookies.get('broker'),
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId, partnerDetails, 'PUT', false, postParams);
      }

      function getPartnerSupervisorsById(partnerId, tenant, saleDate) {
        var deferred = $q.defer();
        if (partnerId) {
          partnerId = partnerId.trim();
        }

        if (!partnerId) {
          deferred.reject();
          return deferred.promise;
        }

        var params = {
          tenant: tenant,
          saleDate: saleDate
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/supervisors', params, 'GET', false);
      }

      function deActivate(partnerId, tenant, reason) {
        var postParams = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/deactivate', { 'deactivationReason': reason }, 'PATCH', false, postParams);
      }
      function activate(partnerId, tenant) {
        var postParams = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/activate', {}, 'PATCH', false, postParams);
      }
      function notVerify(partnerId, tenant) {
        var postParams = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/notVerified', {}, 'PATCH', false, postParams);
      }
      function verify(partnerId, tenant) {
        var postParams = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId + '/verified', {}, 'PATCH', false, postParams);
      }
      function reject(data, tenant) {
        var postParams = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + data.partnerId + '/rejected', data, 'PATCH', false, postParams);
      }

      function deleteProfile(partnerId) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + partnerId, {}, 'DELETE', false);
      }

      function getPartner(params) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '', params, 'GET', false);
      }

      function addPartner(partnerObj, postParams) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '', partnerObj, 'POST', false, postParams);
      }
      // PLM
      function getPLM(params) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + PLM_URL + 'partner', '', params, 'GET', false);
      }

      function setOwnerLMS(LMSObject, postParams) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + PLM_URL + 'setOwner', '', LMSObject, 'POST', false, postParams);
      }
      function getActivity(params, partnerId) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + PLM_URL + (partnerId + '/activity'), '', params, 'GET', false);
      }
      function addNewActivity(LMSObject, postParams) {
        LMSObject.callAgentName = currentUser.fullName;
        LMSObject.agentEmail = currentUser.email;
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + PLM_URL + 'createUpdateLeadActivity', '', LMSObject, 'POST', false, postParams);
      }
      ///partnerleads/activity

      function visionApi(fileItem) {
        return commonService.makeMultiPartDataApiCall(GENERAL_CONFIG.VISION_URL, '', fileItem, 'POST', false);
      }

      function updateVisionNumber(id, objParam) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL + ('/' + id + '/document'), '', objParam, 'PATCH', false);
      }

      function validateBankDetails(data) {
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + partnerURL, '/' + data.id + '/validateBankDetails', data, 'PATCH', false);
      }

      function searchBranch(searchkey) {
        var params = {
          name: searchkey
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/v1', '/dp-branch-location', params, 'GET', true);
      }

      // TASK(M30-4576): Automating the NOC generation process on PM
      function addNocDocument(partnerId, tenant) {
        var params = {
          tenant: tenant
        };
        return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro' + apiVersion, '/generateNoc/' + partnerId, {}, 'GET', false, params);
      }
    }
  }
})();
"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// jshint ignore: start
(function () {
  angular.module("brokerAdmin.partner").provider("PartnerAddUpdateService", PartnerAddUpdateService);

  PartnerAddUpdateService.$inject = [];
  return PartnerAddUpdateService;

  function PartnerAddUpdateService() {
    PartnerAddUpdateService.$inject = ["$mdDialog", "$q", "$rootScope", "commonService", "formConfigService", "GENERAL_CONFIG"];
    return {
      $get: PartnerAddUpdateService
    };

    function PartnerAddUpdateService($mdDialog, $q, $rootScope, commonService, formConfigService, GENERAL_CONFIG) {
      var _ref = {},
          config = _ref.config,
          partner = _ref.partner,
          bankDetails = _ref.bankDetails,
          bankVerifier = _ref.bankVerifier,
          documents = _ref.documents,
          additionalInfo = _ref.additionalInfo,
          personalInfo = _ref.personalInfo,
          address = _ref.address,
          licHolding = _ref.licHolding,
          associatedIRDAI = _ref.associatedIRDAI,
          enrollmentProcess = _ref.enrollmentProcess,
          eligibleFor = _ref.eligibleFor,
          associate = _ref.associate,
          selectAssociate = _ref.selectAssociate,
          leadtracking = _ref.leadtracking,
          documentOwner = _ref.documentOwner,
          remarks = _ref.remarks,
          partnerVerifier = _ref.partnerVerifier,
          licensingDetails = _ref.licensingDetails,
          pospSP = _ref.pospSP,
          trainingDetails = _ref.trainingDetails,
          typeOfPospList = _ref.typeOfPospList,
          pospGI = _ref.pospGI,
          pospLI = _ref.pospLI,
          spGI = _ref.spGI,
          spLI = _ref.spLI,
          spCOMPOSITE = _ref.spCOMPOSITE,
          pospBQP = _ref.pospBQP,
          posp = _ref.posp,
          spLI = _ref.spLI,
          spCOMPOSITE = _ref.spCOMPOSITE,
          spGI = _ref.spGI,
          nomineeBankDetails = _ref.nomineeBankDetails,
          nomineeDocuments = _ref.nomineeDocuments,
          deactivateProfile = _ref.deactivateProfile,
          partnerQc = _ref.partnerQc;
      // Services Variables

      var apiVersion = "/v1";
      var partnerURL = apiVersion + "/partners";
      var pageType = {};
      // Common Var
      var LICENSE_NUMBER = "licenseNumber";
      var EXPIRY_DATE = "expiryDate";
      var GI_LICENSE_NUMBER = "PoSPGI_licenseNumber";
      var GI_EXPIRY_DATE = "PoSPGI_expiryDate";
      var LI_LICENSE_NUMBER = "PoSPLI_licenseNumber";
      var LI_EXPIRY_DATE = "PoSPLI_expiryDate";
      var SP_GI_LICENSE_NUMBER = "SPGI_licenseNumber";
      var SP_GI_EXPIRY_DATE = "SPGI_expiryDate";
      var SP_LI_LICENSE_NUMBER = "SPLI_licenseNumber";
      var SP_LI_EXPIRY_DATE = "SPLI_expiryDate";
      var SP_COMPOSITE_LICENSE_NUMBER = "SPCOMPOSITE_licenseNumber";
      var SP_COMPOSITE_EXPIRY_DATE = "SPCOMPOSITE_expiryDate";
      var ASSIGN_AS_PARENT = "assignAsParent";
      var LI_IIB_DATE = "PoSP_LI_iib";
      var GI_IIB_DATE = "PoSP_GI_iib";
      var SP_LI_IIB_DATE = "SP_LI_iib";
      var SP_GI_IIB_DATE = "SP_GI_iib";
      var SP_COMPOSITE_IIB_DATE = "SP_COMPOSITE_iib";
      var TYPE_OF_POSP = "typeOfPosp";
      var TYPE_OF_SP = "typeOfSp";
      // const BQP_LICENSE_NUMBER = "bqpLicenseNumber";
      // const BQP_EXPIRY_DATE = "bqpExpiryDate";
      var POSP_EXPIRY_DATE = "pospExpiryDate";
      var POSP_LICENSE_NUMBER = "pospLicenseNumber";
      var SP_EXPIRY_DATE = "spExpiryDate";
      var SP_LICENSE_NUMBER = "spLicenseNumber";
      return {
        getFormConfigReqObj: getFormConfigReqObj,
        populateConfig: populateConfig,
        populatePartner: populatePartner,
        getBankDetails: getBankDetails,
        getBankVerifier: getBankVerifier,
        getDocuments: getDocuments,
        getAdditionalInfo: getAdditionalInfo,
        getPersonalInfo: getPersonalInfo,
        getAddress: getAddress,
        getLicHolding: getLicHolding,
        getAssociatedIRDAI: getAssociatedIRDAI,
        getEnrollmentProcess: getEnrollmentProcess,
        getEligibleFor: getEligibleFor,
        getAssociate: getAssociate,
        getSelectAssociate: getSelectAssociate,
        getLeadTracking: getLeadTracking,
        getDocumentOwner: getDocumentOwner,
        getRemarks: getRemarks,
        getPartnerVerifier: getPartnerVerifier,
        getLicensingDetails: getLicensingDetails,
        getPospSP: getPospSP,
        getPospGI: getPospGI,
        getPospLI: getPospLI,
        getSpLI: getSpLI,
        getSpGI: getSpGI,
        getSpCOMPOSITE: getSpCOMPOSITE,
        getTrainingDetails: getTrainingDetails,
        getTypeOfPosp: getTypeOfPosp,
        getPospBQP: getPospBQP,
        getPosp: getPosp,
        getNomineeBankDetails: getNomineeBankDetails,
        getNomineeDocuments: getNomineeDocuments,
        getDeactivateProfile: getDeactivateProfile,
        getPartnerQc: getPartnerQc,
        getDynamicPropsMap: getDynamicPropsMap,
        updateCheckoutConfig: updateCheckoutConfig
      };

      function getFormConfigReqObj(reqObj) {
        return {
          "broker": reqObj.broker,
          "tenant": reqObj.tenant,
          "vertical": reqObj.vertical,
          "insurer": "*",
          "productCode": "*",
          "subProductCode": "*",
          "paymentFlow": "*",
          "paymentMode": "*",
          "formSchemaKey": "*",
          "formIntent": reqObj.formIntent,
          "businessModel": "*"
        };
      }

      function getDynamicPropsMap() {
        var personalInfoSlideFnMap = {
          _alternativeMobileTypeChange: _alternativeMobileTypeChange,
          _addressTypeChange: _addressTypeChange,
          _cityTypeChange: _cityTypeChange,
          _stateTypeChange: _stateTypeChange,
          _pincodeTypeChange: _pincodeTypeChange,
          _deactivationDateInitFn: _deactivationDateInitFn,
          _deactivationReasonInitFn: _deactivationReasonInitFn,
          _deactivatedByInitFn: _deactivatedByInitFn
        };

        var licensingDetailsSlideFnMap = {
          _licensingDetailsInitFn: _licensingDetailsInitFn,
          _licensingDetailsChangeFn: _licensingDetailsChangeFn,
          _typeOfPOSPInitFn: _typeOfPOSPInitFn,
          _typeOfPOSPChangeFn: _typeOfPOSPChangeFn,
          _typeOfSPInitFn: _typeOfSPInitFn,
          _typeOfSPChangeFn: _typeOfSPChangeFn
        };

        var bankDetailsSlideFnMap = {
          _accHolderNameTypeChange: _accHolderNameTypeChange,
          _accNoTypeChange: _accNoTypeChange,
          _bankNameTypeChange: _bankNameTypeChange,
          _ifsccodeTypeChange: _ifsccodeTypeChange,
          _branchTypeChange: _branchTypeChange
        };

        var documentsSlideFnMap = {
          _panTypeInitFn: _panTypeInitFn,
          _aadhaarTypeInitFn: _aadhaarTypeInitFn,
          _chequeTypeInitFn: _chequeTypeInitFn
        };

        var nomineeBankDetailsSlideFnMap = {
          _nomineeAccHolderNameTypeChange: _nomineeAccHolderNameTypeChange,
          _nomineeAccNoTypeChange: _nomineeAccNoTypeChange,
          _nomineeBankNameTypeChange: _nomineeBankNameTypeChange,
          _nomineeIfsccodeTypeChange: _nomineeIfsccodeTypeChange,
          _nomineeBranchTypeChange: _nomineeBranchTypeChange
        };

        var nomineeDocumentsSlideFnMap = {
          _nomineePanTypeInitFn: _nomineePanTypeInitFn,
          _nomineeChequeTypeInitFn: _nomineeChequeTypeInitFn
        };

        var businessInformationSlideFnMap = {
          _selectFresherFn: _selectFresherFn
        };

        var holdingLicenceSlideFnMap = {
          _agencyHealthTypeChangeFn: _agencyHealthTypeChangeFn,
          _agencyLifeTypeInitFn: _agencyLifeTypeInitFn,
          _agencyGeneralTypeInitFn: _agencyGeneralTypeInitFn,
          _pospLifeTypeInitFn: _pospLifeTypeInitFn,
          _pospGeneralTypeInitFn: _pospGeneralTypeInitFn,
          _surveyorTypeInitFn: _surveyorTypeInitFn,
          _nonOfThese: _nonOfThese
        };
        var enrollmentProcessSlideFnMap = {
          _sendNOCStatusChangeFn: _sendNOCStatusChangeFn
        };

        var dynamicPropsMap = _extends({
          _commonEmptyFun: _commonEmptyFun

        }, personalInfoSlideFnMap, licensingDetailsSlideFnMap, bankDetailsSlideFnMap, documentsSlideFnMap, nomineeBankDetailsSlideFnMap, nomineeDocumentsSlideFnMap, businessInformationSlideFnMap, holdingLicenceSlideFnMap, enrollmentProcessSlideFnMap);
        return dynamicPropsMap;
      }

      function updateCheckoutConfig(fieldsConfig, config, partnerRoles, broker, configRole) {
        var slides = fieldsConfig.slides;

        slides.forEach(function (slides) {

          if (slides.sections) {

            slides.sections.forEach(function (sections) {

              if (sections.code === 'personalInfo') {
                personalInfo = sections.fields;
              } else if (sections.code === 'licensingDetails') {
                licensingDetails = sections.fields;
              } else if (sections.code === 'typeOfPospList') {
                typeOfPospList = sections.fields;
              } else if (sections.code === 'pospBQP') {
                pospBQP = sections.fields;
              } else if (sections.code === 'posp') {
                posp = sections.fields;
              } else if (sections.code === 'pospGI') {
                pospGI = sections.fields;
              } else if (sections.code === 'pospLI') {
                pospLI = sections.fields;
              } else if (sections.code === 'spGI') {
                spGI = sections.fields;
              } else if (sections.code === 'spLI') {
                spLI = sections.fields;
              } else if (sections.code === 'spCOMPOSITE') {
                spCOMPOSITE = sections.fields;
              } else if (sections.code === 'bankDetails') {
                bankDetails = sections.fields;
              } else if (sections.code === 'bankVerifier') {
                bankVerifier = sections.fields;
              } else if (sections.code === 'documents') {
                documents = sections.fields;
              } else if (sections.code === 'nomineeBankDetails') {
                nomineeBankDetails = sections.fields;
              } else if (sections.code === 'nomineeDocuments') {
                nomineeDocuments = sections.fields;
              } else if (sections.code === 'additionalProfile') {
                additionalInfo = sections.fields;
              } else if (sections.code === 'documentOwner') {
                documentOwner = sections.fields;
              } else if (sections.code === 'leadTracking') {
                leadtracking = sections.fields;
              }

              if (sections.fields) {

                slides.sections.forEach(function (fields) {

                  if (fields.fields) {

                    fields.fields.forEach(function (field) {

                      //init function execute
                      if (field && field.initFn && typeof field.initFn === 'function') {
                        field.initFn(field.code, fieldsConfig, field);
                      }

                      var checkerRole = ['PARTNER-CHECKER', 'USER-CHECKER'];

                      if (checkerRole.some(function (el) {
                        return partnerRoles.includes(el);
                      })) {
                        field.disabled = true;
                      }

                      if (field.code === 'relationship') {
                        field.selectConfig.options = config.dropDowns.relationship;
                      } else if (field.code === 'salesStatus') {
                        field.selectConfig.options = config.dropDowns.salesStatus;
                      } else if (field.code === 'acquisitionSource') {
                        field.selectConfig.options = config.dropDowns.acquisitionSource;
                      } else if (field.code === 'pospType') {
                        field.selectConfig.options = config.pospTypes;
                      } else if (field.code === 'educationQualification') {
                        field.selectConfig.options = config.dropDowns.educationQualifications;
                      } else if (field.code === 'insuranceExperienceInYears') {
                        field.selectConfig.options = config.dropDowns.noOfYearsOfExperience;
                      } else if (field.code === 'existingBusinessTurnover') {
                        field.selectConfig.options = config.dropDowns.businessTurnover;
                      } else if (field.code === 'primarySourceOfIncome') {
                        field.selectConfig.options = config.dropDowns.primarySourceOfIncome;
                      } else if (field.code === 'monthlyMotorPremium') {
                        field.selectConfig.options = config.dropDowns.monthlyMotorPremium;
                      } else if (field.code === 'monthlyHealthPremium') {
                        field.selectConfig.options = config.dropDowns.monthlyHealthPremium;
                      } else if (field.code === 'monthlyLifePremium') {
                        field.selectConfig.options = config.dropDowns.monthlyLifePremium;
                      } else if (field.code === 'agency_health') {
                        field.selectConfig.options = config.dropDowns.health_insurers;
                      } else if (field.code === 'agency_life') {
                        field.selectConfig.options = config.dropDowns.life_insurers;
                      } else if (field.code === 'agency_general') {
                        field.selectConfig.options = config.dropDowns.health_insurers;
                      } else if (field.code === 'surveyor') {
                        field.selectConfig.options = config.dropDowns.health_insurers;
                      } else if (field.code === 'selectAssociateName') {
                        field.autocompleteConfig.selectedItemChange = _associateChangeFn;
                        field.autocompleteConfig.querySearch = searchFn;
                      }

                      if (broker === 'yesbank') {
                        if (field.code === 'pospType') {
                          field.name = 'User Category';
                        } else if (field.code === 'pospLicenseNumber') {
                          field.name = 'SP License Number';
                        } else if (field.code === 'name') {
                          field.name = 'SP / AV Name';
                        } else if (field.code === 'typeOfPosp') {
                          field.name = 'Type of License';
                        } else if (field.code === 'PoSP_GI_iib') {
                          field.name = 'IRDA Transfer Approval Date';
                        } else if (field.code === 'PoSP_LI_iib') {
                          field.name = 'IRDA License Issue Date';
                        } else if (field.code === 'pospExpiryDate') {
                          field.name = 'IRDA License Expiry Date';
                        } else if (field.code === 'PoSPGI_expiryDate') {
                          field.name = 'IRDA GI License Expiry Date';
                        } else if (field.code === 'PoSPLI_expiryDate') {
                          field.name = 'IRDA LI License Expiry Date';
                        }

                        if (configRole === 'PARTNER') {
                          _markFieldRequired("displayAddress", true);
                          _markFieldRequired("pincode", true);
                          _markFieldRequired("city", true);
                          _markFieldRequired("state", true);
                          // _markFieldRequired("panNumber", true);
                          _markFieldRequired("dateOfBirth", true);
                          _markFieldRequired("gender", true);
                        }
                        //  _markFieldRequired("pospType", true);
                        // _markFieldRequired("PoSP_GI_iib", true);
                        //_markFieldRequired("PoSP_LI_iib", true);
                      }
                    });
                  }
                });
              }
            });
          }
        });
        $rootScope.slideConfig = fieldsConfig;
      }

      function populateConfig(configObj, pageType, status, slideConfig) {
        var turtlemint = sessionStorage.getItem("broker") !== "turtlemint";
        var hideField = ['idfcfirstbank', 'federal', 'unisure', 'reliancejio'].includes(sessionStorage.getItem("broker"));
        config = configObj;
        var hide = false;
        if (sessionStorage.getItem("broker") === "hdfcbank") {
          hide = true;
        }
        var profileStatus = sessionStorage.getItem("profileStatus");

        if (!['mashreq', 'dbs'].includes(sessionStorage.getItem("broker"))) {
          bankDetails = [{
            name: "Relationship With Partner *",
            code: "relationship",
            placeholder: "Relationship With Partner",
            type: "select",
            selectConfig: {
              options: config.dropDowns.relationship,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Bank A/C Holder Name *",
            code: "accHolderName",
            placeholder: "Bank A/C Holder Name",
            type: "text",
            pattern: /[A-Za-z](\s?[A-Za-z/,&\Q().-\E]+)+[A-Za-z\.]/,
            blurFn: function blurFn(v) {
              return _commonEmptyFun("bankDetails.accHolderName", v);
            }
          }, {
            name: "Bank A/C Number *",
            code: "accNo",
            placeholder: "Bank A/C Number",
            type: "text",
            minLength: 8,
            maxLength: 18,
            pattern: "[0-9]{8,18}",
            blurFn: function blurFn(v) {
              return _commonEmptyFun("bankDetails.accNo", v);
            }
          }, {
            name: "Bank Name *",
            code: "bankName",
            placeholder: "Bank Name",
            type: "text",
            pattern: /[A-Za-z](\s?[A-Za-z&\Q.-\E]+)+[A-Za-z\.]/,
            blurFn: function blurFn(v) {
              return _commonEmptyFun("bankDetails.bankName", v);
            }
          }, {
            name: "IFSC *",
            code: "ifsccode",
            placeholder: "IFSC",
            type: "text",
            pattern: "^[A-Z]{4}0[0-9A-Z]{6}$",
            blurFn: function blurFn(v) {
              return _commonEmptyFun("bankDetails.ifsccode", v);
            }
          }, {
            name: "Bank Branch *",
            code: "branch",
            placeholder: "Bank Branch",
            type: "text",
            blurFn: function blurFn(v) {
              return _commonEmptyFun("bankDetails.branch", v);
            }
          }];
          bankVerifier = [{
            name: "Verified By *",
            code: "verifierName",
            placeholder: "Verifier Name",
            type: "text",
            disabled: true
          }];
          documents = [{
            name: "PAN Number " + (pageType === "PARTNER_REVIEW" ? '*' : ''),
            code: "panNumber",
            typename: "pan_card",
            placeholder: "PAN Number",
            pattern: "^[A-Za-z]{5}\\d{4}[A-Za-z]{1}$",
            type: "text",
            initFn: _panTypeInitFunc,
            blurFn: _panTypeInitFn
          }, {
            name: "Aadhaar Number " + (pageType === "PARTNER_REVIEW" ? '*' : ''),
            code: "aadhaarNumber",
            typename: "adhaar_card",
            placeholder: "Aadhaar Number",
            pattern: "^\\d{4}\\d{4}\\d{4}$",
            type: "text",
            hide: hide,
            initFn: _aadhaarTypeInitFunc,
            blurFn: _aadhaarTypeInitFn
          }, {
            name: "Cancelled Cheque No",
            code: "cancelChequeNumber",
            placeholder: "Cancelled Cheque No",
            type: "text",
            blurFn: _chequeTypeInitFn
          }, {
            name: "panVisionNumber",
            code: "panVisionNumber",
            placeholder: "panVisionNumber",
            type: "text",
            hide: true
          }, {
            name: "adhaarVisionNumber",
            code: "adhaarVisionNumber",
            placeholder: "adhaarVisionNumber",
            type: "text",
            hide: true
          }];
          additionalInfo = [{
            name: "Creation Source",
            code: "source",
            placeholder: "Source",
            type: "text",
            disabled: true
          }, {
            name: "Educational Qualification *",
            code: "educationQualification",
            placeholder: "Educational Qualification",
            type: "select",
            selectConfig: {
              options: config.dropDowns.educationQualifications,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Years of insurance business *",
            code: "insuranceExperienceInYears",
            placeholder: "Years of insurance business",
            type: "select",
            changeFn: _selectFresherFn,
            selectConfig: {
              options: config.dropDowns.noOfYearsOfExperience,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Office Space *",
            code: "officeSpace",
            placeholder: "Office Space",
            type: "radio",
            radioConfig: {
              options: [{
                value: "true",
                text: "Yes"
              }, {
                value: "false",
                text: "No"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "Existing Business Turnover *",
            code: "existingBusinessTurnover",
            placeholder: "Existing Business Turnover",
            type: "select",
            selectConfig: {
              options: config.dropDowns.businessTurnover,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Primary Source of Income *",
            code: "primarySourceOfIncome",
            placeholder: "Primary Source of Income",
            type: "select",
            selectConfig: {
              options: config.dropDowns.primarySourceOfIncome,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Monthly Motor Premium *",
            code: "monthlyMotorPremium",
            placeholder: "Monthly Motor Premium",
            type: "select",
            selectConfig: {
              options: config.dropDowns.monthlyMotorPremium,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Monthly Health Premium *",
            code: "monthlyHealthPremium",
            placeholder: "Monthly Health Premium",
            type: "select",
            selectConfig: {
              options: config.dropDowns.monthlyHealthPremium,
              valueKey: "value",
              textKey: "displayName"
            }
          }, {
            name: "Monthly Life Premium *",
            code: "monthlyLifePremium",
            placeholder: "Monthly Life Premium",
            type: "select",
            selectConfig: {
              options: config.dropDowns.monthlyLifePremium,
              valueKey: "value",
              textKey: "displayName"
            }
          }];
          remarks = [{
            name: "Remarks",
            code: "remarks",
            placeholder: "Remarks",
            type: "textarea"
          }];
          partnerVerifier = [{
            name: "Verified By",
            code: "verifierName",
            placeholder: "Verifier Name",
            type: "text",
            disabled: true
          }];
          personalInfo = [{
            name: "User Type",
            code: "userType",
            placeholder: "User Type",
            type: "text",
            disabled: true
          }, {
            name: "DP Number",
            code: "dpNo",
            placeholder: "DP Number",
            type: "text",
            required: true,
            disabled: true
          }, {
            name: "Branch Name",
            code: "branchName",
            placeholder: "Branch Name",
            type: "text",
            hide: !hideField,
            required: true
          }, {
            name: "Branch Code",
            code: "branchCode",
            placeholder: "Branch Code",
            type: "text",
            hide: !hideField,
            required: true
          }, {
            name: "User Id",
            code: "tenantUniqueCode",
            placeholder: "User Id",
            type: "text",
            hide: !hideField,
            required: true
          }, {
            name: "Employee Id",
            code: "externalUniqueId",
            placeholder: "Employee Id",
            type: "text",
            hide: !hideField,
            required: true
          }, {
            name: "Organisation",
            code: "organisation",
            placeholder: "Organisation",
            type: "text",
            disabled: true
          }, {
            name: "Creation Date",
            code: "createdAt",
            type: "date",
            disabled: true
          }, {
            name: "Verification Date",
            code: "verificationDate",
            type: "date",
            disabled: true
          }, {
            name: "Full Name",
            code: "name",
            placeholder: "Full Name",
            required: true,
            type: "text"
          }, {
            name: "Name Alias 1",
            code: "nameAlias1",
            placeholder: "Name Alias 1",
            type: "text",
            hide: hideField
          }, {
            name: "Name Alias 2",
            code: "nameAlias2",
            placeholder: "Name Alias 2",
            type: "text",
            hide: hideField
          }, {
            name: "Email",
            code: "email",
            placeholder: "Email",
            required: true,
            type: "email"
          }, {
            name: "Mobile",
            code: "mobile",
            placeholder: "Mobile",
            required: true,
            type: "text",
            pattern: "[0-9]{10}"
          }, {
            name: "Alternate Mobile",
            code: "alternativeMobile",
            placeholder: "Alternate Mobile",
            type: "text",
            pattern: "[0-9]{10}",
            hide: hideField,
            blurFn: function blurFn(v) {
              return _commonEmptyFun("personalInfo.alternativeMobile", v);
            }
          }, {
            name: "DOB *",
            code: "dateOfBirth",
            placeholder: "DD/MM/YYYY",
            type: "date",
            hide: hideField
          }, {
            name: "Gender *",
            code: "gender",
            type: "radio",
            hide: hideField,
            radioConfig: {
              options: [{
                value: "male",
                text: "Male"
              }, {
                value: "female",
                text: "Female"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "Marital Status *",
            code: "maritalStatus",
            type: "radio",
            class: "1",
            hide: hideField,
            radioConfig: {
              options: [{
                text: "Married",
                value: "true"
              }, {
                text: "Unmarried",
                value: "false"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "Referral Code",
            code: "referralCode",
            placeholder: "",
            disabled: true,
            hide: turtlemint,
            type: "text"
          }, {
            name: "Referred By",
            code: "referredBy",
            placeholder: "",
            disabled: true,
            hide: turtlemint,
            type: "text"
          }, {
            name: "Last Working Day",
            code: "lastWorkingDate",
            placeholder: "DD/MM/YYYY",
            type: "date",
            hide: !hideField
          }];
          address = [{
            name: "Address",
            code: "displayAddress",
            placeholder: "Address",
            type: "text",
            hide: true,
            blurFn: _addressTypeChange
          }, {
            name: "City",
            code: "city",
            placeholder: "City",
            disabled: true,
            type: "text",
            hide: hideField,
            blurFn: _cityTypeChange
          }, {
            name: "State",
            code: "state",
            placeholder: "State",
            disabled: true,
            type: "text",
            hide: hideField,
            blurFn: _stateTypeChange
          }, {
            name: "Current Pincode",
            code: "currentPincode",
            placeholder: "Current Pincode",
            type: "text",
            hide: turtlemint,
            minLength: 6,
            maxLength: 6,
            pattern: "^[1-9][0-9]{5}$",
            blurFn: _pincodeTypeChange
          }];
          licHolding = [{
            name: "Agency health",
            code: "agency_health",
            placeholder: "Agency health",
            changeFn: _agencyHealthTypeChangeFn,
            type: "select",
            selectConfig: {
              options: config.dropDowns.health_insurers
            }
          }, {
            name: "Agency life",
            code: "agency_life",
            placeholder: "Agency life",
            changeFn: _agencyLifeTypeInitFn,
            type: "select",
            selectConfig: {
              options: config.dropDowns.life_insurers
            }
          }, {
            name: "Agency general",
            code: "agency_general",
            placeholder: "Agency general",
            changeFn: _agencyGeneralTypeInitFn,
            type: "select",
            selectConfig: {
              options: config.dropDowns.health_insurers
            }
          }, {
            name: "Pos life",
            code: "pos_life",
            placeholder: "Pos life",
            blurFn: _pospLifeTypeInitFn,
            type: "text"
          }, {
            name: "Pos general",
            code: "pos_general",
            placeholder: "Pos general",
            blurFn: _pospGeneralTypeInitFn,
            type: "text"
          }, {
            name: "Surveyor",
            code: "surveyor",
            placeholder: "Surveyor",
            type: "select",
            blurFn: _surveyorTypeInitFn,
            selectConfig: {
              options: config.dropDowns.health_insurers
            }
          }, {
            name: "None",
            code: "NONE",
            type: "checkbox",
            initFn: _nonOfThese,
            changeFn: _nonOfThese
          }];
          associatedIRDAI = [{
            name: "Associated with another insurer or broker in IRDAI database? *",
            code: "irdaiStatus",
            placeholder: "",
            type: "select",
            selectConfig: {
              options: [{
                value: "associated",
                text: "Associated"
              }, {
                value: "notassociated",
                text: "Not Associated"
              }, {
                value: "not_checked",
                text: "Not Checked"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }];
          enrollmentProcess = [{
            name: "What you would like to do? *",
            code: "enrollmentStatusForPOSP",
            placeholder: "",
            type: "radio",
            initFn: _sendNOCStatusChangeFn,
            changeFn: _sendNOCStatusChangeFn,
            radioConfig: {
              options: [{
                value: "true",
                text: "Surrender prior certificate"
              }, {
                value: "false",
                text: "Associate via family / acquaintance"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "NOC sent on",
            code: "nocSentDate",
            disabled: true,
            type: "date"
          }, {
            name: "Signed NOC *",
            code: "nocReceivedDate",
            type: "date"
          }];
          eligibleFor = [{
            name: "Enrollment status for POSP training " + (pageType === "PARTNER_REVIEW" ? profileStatus === 'to_verify' || status === 'to_verify' ? '' : '*' : ''),
            code: "eligibleForLMS",
            placeholder: "Eligible",
            type: "select",
            initFn: _enrollmentIntFn,
            selectConfig: {
              options: [{
                value: 'notSet',
                text: 'Not Set'
              }, {
                value: "true",
                text: "Eligible"
              }, {
                value: "false",
                text: "Not eligible"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }];
          associate = [{
            name: "Associate Name",
            code: "associateName",
            placeholder: "Associate Name",
            type: "text"
          }];
          selectAssociate = [{
            name: "Select associate",
            code: "selectAssociateName",
            placeholder: "Select associate",
            hide: true,
            type: "autocomplete",
            autocompleteConfig: {
              disabled: false,
              noCache: false,
              selectedItemChange: _associateChangeFn,
              querySearch: searchFn,
              displayKey: "name",
              valueKey: "name"
            }
          }];
          documentOwner = [{
            name: "Is the person doing the business same as the person for which documents have been uploaded?",
            code: "documentOwner",
            type: "radio",
            class: "1",
            radioConfig: {
              options: [{
                value: "true",
                text: "Yes"
              }, {
                value: "false",
                text: "No"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }];
          leadtracking = [{
            name: "Sale Status",
            code: "saleStatus",
            placeholder: "sale Status",
            type: "select",
            selectConfig: {
              options: config.dropDowns.salesStatus,
              valueKey: "text",
              textKey: "displayName"
            }
          }, {
            name: "Acquisition source",
            code: "acquisitionSource",
            placeholder: "Acquisition",
            type: "select",
            selectConfig: {
              options: config.dropDowns.acquisitionSource,
              valueKey: "text",
              textKey: "displayName"
            }
          }, {
            name: "UTM Params",
            code: "utmDetails",
            placeholder: "",
            type: "text",
            disabled: true
          }, {
            name: "Mintpro Account Created",
            code: "mintproAccountExist",
            placeholder: "",
            type: "text",
            disabled: true
          }];
          licensingDetails = [{
            name: "License type",
            code: "pospType",
            placeholder: "License type",
            type: "select",
            initFn: _licensingDetailsInitFn,
            changeFn: _licensingDetailsChangeFn,
            selectConfig: {
              options: config.pospTypes,
              valueKey: "value",
              textKey: "displayName"
            }
          }];
          typeOfPospList = [{
            name: "Type of POSP",
            code: TYPE_OF_POSP,
            placeholder: "select options",
            type: "select",
            required: true,
            hide: true,
            initFn: _typeOfPOSPInitFn,
            changeFn: _typeOfPOSPChangeFn,
            selectConfig: {
              multiple: true,
              options: [{
                text: "Posp GI",
                value: "pospgi"
              }, {
                text: "Posp LI",
                value: "pospli"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "Type of SP",
            code: TYPE_OF_SP,
            placeholder: "select options",
            type: "select",
            required: true,
            hide: true,
            initFn: _typeOfSPInitFn,
            changeFn: _typeOfSPChangeFn,
            selectConfig: {
              multiple: false,
              options: [{
                text: "Sp GI",
                value: "spgi"
              }, {
                text: "Sp LI",
                value: "spli"
              }, {
                text: "Sp COMPOSITE",
                value: "spcomposite"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }];
          pospSP = [{
            name: "POSP License Number",
            code: LICENSE_NUMBER,
            placeholder: "License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "POSP License expiry date",
            code: EXPIRY_DATE,
            placeholder: "License expiry date",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "Allow associate to non-POSP",
            code: ASSIGN_AS_PARENT,
            placeholder: "Allow associate to non-POSP",
            type: "select",
            hide: true,
            selectConfig: {
              options: [{
                text: "No",
                value: "false"
              }, {
                text: "Yes",
                value: "true"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }];
          pospBQP = [{
            name: "BQP-GI License Number",
            code: 'bqpgiLicenseNumber',
            placeholder: "BQP-GI License Number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "BQP-GI License expiry date",
            code: 'bqpgiExpiryDate',
            placeholder: "BPQ-GI License expiry date",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "BQP-lI License Number",
            code: 'bqpliLicenseNumber',
            placeholder: "BQP-LI License Number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "BQP-LI License expiry date",
            code: 'bqpliExpiryDate',
            placeholder: "BPQ-LI License expiry date",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "BQP-Composite License Number",
            code: 'bqpCompositeLicenseNumber',
            placeholder: "BQP-Composite License Number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "BQP-Composite License expiry date",
            code: 'bqpCompositeExpiryDate',
            placeholder: "License expiry date",
            required: true,
            hide: true,
            type: "date"
          }];
          posp = [{
            name: "POSP License Number",
            code: POSP_LICENSE_NUMBER,
            placeholder: "License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "POSP License expiry date",
            code: POSP_EXPIRY_DATE,
            placeholder: "License expiry date",
            required: true,
            hide: true,
            type: "date"
          }];
          pospGI = [{
            name: "PoSP GI License Number",
            code: GI_LICENSE_NUMBER,
            placeholder: "PoSP GI License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "PoSP GI License expiry date",
            code: GI_EXPIRY_DATE,
            placeholder: "License expiry date",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "PoSP GI IIB upload date",
            code: GI_IIB_DATE,
            placeholder: "Enter date",
            hide: true,
            type: "date"
          }];
          pospLI = [{
            name: "PoSP LI License Number",
            code: LI_LICENSE_NUMBER,
            placeholder: "PoSP LI License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "PoSP LI License expiry date",
            code: LI_EXPIRY_DATE,
            placeholder: "",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "PoSP LI IIB upload date",
            code: LI_IIB_DATE,
            placeholder: "Enter date",
            hide: true,
            type: "date"
          }];
          spGI = [{
            name: "SP GI License Number",
            code: "SPGI_licenseNumber",
            placeholder: "SP GI License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "SP GI License expiry date",
            code: "SPGI_expiryDate",
            placeholder: "License expiry date",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "SP GI IIB upload date",
            code: "SP_GI_iib",
            placeholder: "Enter date",
            hide: true,
            type: "date"
          }];
          spLI = [{
            name: "SP LI License Number",
            code: "SPLI_licenseNumber",
            placeholder: "SP LI License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "SP LI License expiry date",
            code: "SPLI_expiryDate",
            placeholder: "",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "SP LI IIB upload date",
            code: "SP_LI_iib",
            placeholder: "Enter date",
            hide: true,
            type: "date"
          }];
          spCOMPOSITE = [{
            name: "SP COMPOSITE License Number",
            code: "SPCOMPOSITE_licenseNumber",
            placeholder: "SP COMPOSITE License number",
            required: true,
            hide: true,
            type: "text"
          }, {
            name: "SP COMPOSITE License expiry date",
            code: "SPCOMPOSITE_expiryDate",
            placeholder: "",
            required: true,
            hide: true,
            type: "date"
          }, {
            name: "SP COMPOSITE IIB upload date",
            code: "SP_COMPOSITE_iib",
            placeholder: "Enter date",
            hide: true,
            type: "date"
          }];

          // nomineeBankDetails = [
          //   {
          //     name: "Bank A/C Holder Name",
          //     code: "accHolderName",
          //     placeholder: "Bank A/C Holder Name",
          //     type: "text",
          //     pattern: /[A-Za-z](\s?[A-Za-z/,&\Q().-\E]+)+[A-Za-z\.]/,
          //     blurFn: v => _commonEmptyFun("nomineeBankDetails.accHolderName", v)
          //   },
          //   {
          //     name: "Bank Name",
          //     code: "bankName",
          //     placeholder: "Bank Name",
          //     type: "text",
          //     pattern: /[A-Za-z](\s?[A-Za-z&\Q.-\E]+)+[A-Za-z\.]/,
          //     blurFn: v => _commonEmptyFun("nomineeBankDetails.bankName", v)
          //   },
          //   {
          //     name: "IFSC",
          //     code: "ifsccode",
          //     placeholder: "IFSC",
          //     type: "text",
          //     pattern: "^[A-Z]{4}0[0-9A-Z]{6}$",
          //     blurFn: v => _commonEmptyFun("nomineeBankDetails.ifsccode", v)
          //   },
          //   {
          //     name: "Bank A/C Number",
          //     code: "accNo",
          //     placeholder: "Bank A/C Number",
          //     type: "text",
          //     minLength: 9,
          //     maxLength: 18,
          //     pattern: "[0-9]{9,18}",
          //     blurFn: v => _commonEmptyFun("nomineeBankDetails.accNo", v)
          //   },
          //   {
          //     name: "Bank Branch",
          //     code: "branch",
          //     placeholder: "Bank Branch",
          //     type: "text",
          //     blurFn: v => _commonEmptyFun("nomineeBankDetails.branch", v)
          //   }
          // ];
          // nomineeDocuments = [
          //   {
          //     name: "PAN Number",
          //     code: "nomineePanNumber",
          //     placeholder: "PAN Number",
          //     pattern: "^[A-Za-z]{5}\\d{4}[A-Za-z]{1}$",
          //     type: "text",
          //     blurFn: _nomineePanTypeInitFn
          //   },
          //   {
          //     name: "Cancelled Cheque No",
          //     code: "nomineecancelChequeNumber",
          //     placeholder: "Cancelled Cheque No",
          //     type: "text",
          //     hide: hide,
          //     blurFn: _nomineeChequeTypeInitFn
          //   }
          // ];
          deactivateProfile = [{
            name: "Deactivation Reason",
            code: "deactivationReason",
            placeholder: "Deactivation Reason",
            type: "textarea",
            disabled: true
          }, {
            name: "Deactivated By",
            code: "deactivatedBy",
            placeholder: "Deactivated By",
            type: "text",
            disabled: true
          }, {
            name: "Deactivation Date",
            code: "deactivationDate",
            placeholder: "Deactivation Date",
            type: "date",
            disabled: true
          }];
          partnerQc = [{
            name: "QC Status",
            code: "qcStatus",
            type: "radio",
            initFn: _qcStatusInitFn,
            changeFn: _qcStatusChangeFn,
            radioConfig: {
              options: [{
                value: "qc_verified",
                text: "QC Verified"
              }, {
                value: "qc_rejected",
                text: "QC Rejected"
              }],
              valueKey: "value",
              textKey: "text"
            }
          }, {
            name: "QCed by",
            code: "qcVerifiedByName",
            placeholder: "QCed By",
            type: "text",
            disabled: true
          }, {
            name: "QC Verification Date",
            code: "qcVerificationDate",
            placeholder: "QCed Date",
            type: "date",
            disabled: true
          }, {
            name: "QC Remarks",
            code: "qcRemarks",
            placeholder: "QC Remarks",
            type: "textarea",
            required: true
          }, {
            name: "QCed by",
            code: "qcRejectedByName",
            placeholder: "QCed By",
            type: "text",
            disabled: true
          }, {
            name: "QC Rejection Date",
            code: "qcRejectionDate",
            placeholder: "QCed Date",
            type: "date",
            disabled: true
          }];
        }

        // trainingDetails = [
        //   {
        //     name: 'POSP GI Training Start Date',
        //     code: 'giTrainingStartDate',
        //     placeholder: 'DD/MM/YYYY',
        //     type: 'date',
        //     disabled: true
        //   },
        //   {
        //     name: 'POSP GI Training Completion Date',
        //     code: 'giTrainingCompDate',
        //     placeholder: 'DD/MM/YYYY',
        //     type: 'date',
        //     disabled: true
        //   },
        //   {
        //     name: 'POSP GI Test Taken Date',
        //     code: 'giTestTakenDate',
        //     placeholder: 'DD/MM/YYYY',
        //     disabled: true,
        //     type: 'date'
        //   },
        //   {
        //     name: 'POSP GI Test Result',
        //     code: 'giTestResult',
        //     placeholder: 'POSP GI Test Result',
        //     disabled: true,
        //     type: 'text'
        //   },
        //   {
        //     name: 'POSP GI Marks Obtained',
        //     code: 'giTestMarks',
        //     placeholder: 'POSP GI Marks Obtained',
        //     disabled: true,
        //     type: 'text'
        //   },
        //   {
        //     name: 'POSP LI Training Start Date',
        //     code: 'liTrainingStartDate',
        //     placeholder: 'DD/MM/YYYY',
        //     type: 'date',
        //     disabled: true
        //   },
        //   {
        //     name: 'POSP LI Training Completion Date',
        //     code: 'liTrainingCompDate',
        //     placeholder: 'DD/MM/YYYY',
        //     type: 'date',
        //     disabled: true
        //   },
        //   {
        //     name: 'POSP LI Test Taken Date',
        //     code: 'liTestTakenDate',
        //     placeholder: 'DD/MM/YYYY',
        //     disabled: true,
        //     type: 'date'
        //   },
        //   {
        //     name: 'POSP LI Test Result',
        //     code: 'liTestResult',
        //     placeholder: 'POSP LI Test Result',
        //     disabled: true,
        //     type: 'text'
        //   },
        //   {
        //     name: 'POSP LI Marks Obtained',
        //     code: 'liTestMarks',
        //     placeholder: 'POSP LI Marks Obtained',
        //     disabled: true,
        //     type: 'text'
        //   }
        // ];
      }

      function populatePartner(partnerObj) {
        partner = partnerObj;
      }
      // From Component function
      function _nonOfThese(selectedItem) {
        if (selectedItem) {
          _markFieldDisabled("agency_health", true);
          _markFieldDisabled("agency_life", true);
          _markFieldDisabled("agency_general", true);
          _markFieldDisabled("pos_life", true);
          _markFieldDisabled("pos_general", true);
          _markFieldDisabled("surveyor", true);
          partner.thirdPartyAssociation = [{
            type: "NONE",
            thirdParty: ""
          }];
        } else {
          _markFieldDisabled("agency_health", false);
          _markFieldDisabled("agency_life", false);
          _markFieldDisabled("agency_general", false);
          _markFieldDisabled("pos_life", false);
          _markFieldDisabled("pos_general", false);
          _markFieldDisabled("surveyor", false);
          partner.thirdPartyAssociation = [];
        }
      }
      function _addressTypeChange(selectedItem) {
        if (!["", null, undefined].includes(selectedItem)) {
          _markFieldRequired("pincode", true);
          _markFieldRequired("city", true);
          _markFieldRequired("state", true);
        } else {
          _markFieldRequired("pincode", false);
          _markFieldRequired("city", false);
          _markFieldRequired("state", false);
        }
      }

      function _deactivationDateInitFn() {
        if (['dbs'].includes(sessionStorage.getItem("broker")) || !partner.allowAccess && !["", null, undefined].includes(partner.deactivationDate)) {
          _showHideField("deactivationDate", false);
        } else {
          _showHideField("deactivationDate", true);
        }
      }

      function _deactivatedByInitFn() {
        if (['dbs'].includes(sessionStorage.getItem("broker")) || !partner.allowAccess && !["", null, undefined].includes(partner.deactivatedBy)) {
          _showHideField("deactivatedBy", false);
        } else {
          _showHideField("deactivatedBy", true);
        }
      }

      function _deactivationReasonInitFn() {
        if (['dbs'].includes(sessionStorage.getItem("broker")) || !partner.allowAccess && !["", null, undefined].includes(partner.deactivationReason)) {
          _showHideField("deactivationReason", false);
        } else {
          _showHideField("deactivationReason", true);
        }
      }

      function _alternativeMobileTypeChange(selectedItem) {
        _commonEmptyFun("personalInfo.alternativeMobile", selectedItem);
      }

      function _accHolderNameTypeChange(selectedItem) {
        _commonEmptyFun("bankDetails.accHolderName", selectedItem);
      }

      function _accNoTypeChange(selectedItem) {
        _commonEmptyFun("bankDetails.accNo", selectedItem);
      }

      function _bankNameTypeChange(selectedItem) {
        _commonEmptyFun("bankDetails.bankName", selectedItem);
      }

      function _ifsccodeTypeChange(selectedItem) {
        _commonEmptyFun("bankDetails.ifsccode", selectedItem);
      }

      function _branchTypeChange(selectedItem) {
        _commonEmptyFun("bankDetails.branch", selectedItem);
      }

      function _nomineeAccHolderNameTypeChange(selectedItem) {
        _commonEmptyFun("nomineeBankDetails.accHolderName", selectedItem);
      }

      function _nomineeAccNoTypeChange(selectedItem) {
        _commonEmptyFun("nomineeBankDetails.accNo", selectedItem);
      }

      function _nomineeBankNameTypeChange(selectedItem) {
        _commonEmptyFun("nomineeBankDetails.bankName", selectedItem);
      }

      function _nomineeIfsccodeTypeChange(selectedItem) {
        _commonEmptyFun("nomineeBankDetails.ifsccode", selectedItem);
      }

      function _nomineeBranchTypeChange(selectedItem) {
        _commonEmptyFun("nomineeBankDetails.branch", selectedItem);
      }

      function _pincodeTypeChange(selectedItem) {
        var pincode = selectedItem;
        if (pincode) {
          var promise = commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + "/mintpro" + "/pinCode", "/" + pincode, {}, "GET");
          promise.then(function (res) {}).catch(function (err) {
            var element = document.getElementById('currentPincode');
            element.classList.add("ng-invalid");
          });;
        }
      }
      function _cityTypeChange(selectedItem) {
        if (!["", null, undefined].includes(selectedItem)) {
          _markFieldRequired("displayAddress", true);
          _markFieldRequired("pincode", true);
          _markFieldRequired("state", true);
        } else {
          _markFieldRequired("displayAddress", false);
          _markFieldRequired("pincode", false);
          _markFieldRequired("state", false);
        }
      }
      function _stateTypeChange(selectedItem) {
        if (!["", null, undefined].includes(selectedItem)) {
          _markFieldRequired("displayAddress", true);
          _markFieldRequired("pincode", true);
          _markFieldRequired("city", true);
        } else {
          _markFieldRequired("displayAddress", false);
          _markFieldRequired("pincode", false);
          _markFieldRequired("city", false);
        }
      }
      function _sendNOCStatusChangeFn(selectedItem) {
        if (selectedItem === "default") {
          _showHideField("selectAssociateName", true);
          _showHideField("nocSentDate", true);
          _showHideField("nocReceivedDate", true);
        } else if (selectedItem === "true" && !partner.nocSentDate) {
          $mdDialog.show({
            templateUrl: "dev/partner/templates/dialogs/send-NOC.tpl.html",
            controller: sendNOCDialog
          });
        } else if (selectedItem === "false") {
          _showHideField("selectAssociateName", false);
          _showHideField("nocSentDate", true);
          _showHideField("nocReceivedDate", true);
        }
        sendNOCDialog.$inject = ["$scope", "$mdDialog"];
        function sendNOCDialog($scope, $mdDialog) {
          $scope.closeDialog = function () {
            $mdDialog.cancel();
            _showHideField("selectAssociateName", true);
            partner.enrollmentStatusForPOSP = "default";
          };
          $scope.sendnoc = function () {
            var deferred = $q.defer();
            $mdDialog.cancel();
            _showHideField("nocSentDate", false);
            _showHideField("nocReceivedDate", false);
            _showHideField("selectAssociateName", true);
            // Send API
            var params = {};
            var partnerId = partner.id;
            var promise = commonService.makeThirdPartyApiCall(GENERAL_CONFIG.mintproAPI + "/v1/sendNoc/" + partnerId, "", params, "POST", false);
            promise.then(function (res) {
              deferred.resolve();
              var data = res.data.data;
              // console.log('data', data.data);
              partner.nocSentDate = moment(data).format("LL");
            }).catch(function (err) {
              deferred.reject(err);
              // console.log('Err', err);
            });
            return deferred.promise;
          };
        }
      }
      // TASK(M30-4457) - Reset QC Remark field on selection in frontend incase of QC Rejection
      // Also hide remark field when selected option is Qc Rejected
      function _qcStatusChangeFn(selectedItem) {
        if (selectedItem === "qc_rejected") {
          _commonEmptyFun("partnerQc.qcRemarks", '');
          _showHideField("qcRemarks", false);
        } else {
          _showHideField("qcRemarks", true);
        }
      }

      function _enrollmentIntFn() {
        var _partner = partner,
            status = _partner.status,
            eligibleForLMS = _partner.eligibleForLMS;

        var turtlemint = ['turtlemint'].includes(sessionStorage.getItem("broker"));
        var selectConfig = eligibleFor[0].selectConfig;
        var options = selectConfig.options;

        var notSetObj = options.find(function (ele) {
          return ele.value === 'notSet';
        });

        var _ref2 = notSetObj || {},
            value = _ref2.value;

        if (['true', 'false'].includes(eligibleForLMS)) {
          ['notSet'].includes(value) ? options.shift() : null;
        } else {
          if (!notSetObj) {
            var json = {
              value: 'notSet',
              text: 'Not Set'
            };
            options.push(json);
          }
        }
        if (['to_verify'].includes(status) && turtlemint) {
          eligibleFor[0].required = true;
        } else {
          delete eligibleFor[0].required;
        }
      }

      function qcStatusFieldCheck() {

        //added broker 
        var isBrokerTurtlemint = ['turtlemint'].includes(sessionStorage.getItem("broker"));
        //Task(M30-4711): When partnerOc object  is null hide all the fields except for radio buttons
        if ((partner.partnerQc === undefined || partner.partnerQc === null) && isBrokerTurtlemint) {
          _showHideField("qcVerifiedByName", true);
          _showHideField("qcVerificationDate", true);
          _showHideField("qcRejectedByName", true);
          _showHideField("qcRejectionDate", true);
          _showHideField("qcRemarks", true);
        } else if (partner.partnerQc !== null && isBrokerTurtlemint) {
          if (["", null, undefined].includes(partner.partnerQc.qcStatus)) {
            _showHideField("qcVerifiedByName", true);
            _showHideField("qcVerificationDate", true);
            _showHideField("qcRejectedByName", true);
            _showHideField("qcRejectionDate", true);
            _showHideField("qcRemarks", true);
          }
        }
      }

      function _qcStatusInitFn(selectedItem) {
        // TASK(M30-4457) - disabled qc options incase of qc verification and or qc rejection
        if (['qc_verified', 'qc_rejected'].includes(selectedItem)) {
          _markFieldDisabled("qcStatus", true);
        } else {
          _markFieldDisabled("qcStatus", false);
        }
        // TASK(M30-4457) - Verified by - if condition to check if value is null or undefined and if field is not empty hide/show the field
        if (!["", null, undefined].includes(partner.partnerQc.qcVerifiedByName)) {
          _showHideField("qcVerifiedByName", false);
        } else {
          _showHideField("qcVerifiedByName", true);
        }
        // TASK(M30-4457) - Verification Date -  if condition to check if value is null or undefined and if field is not empty hide/show the field
        if (!["", null, undefined].includes(partner.partnerQc.qcVerificationDate)) {
          _showHideField("qcVerificationDate", false);
        } else {
          _showHideField("qcVerificationDate", true);
        }
        // TASK(M30-4457) - Rejected By - if condition to check if value is null or undefined and if field is not empty hide/show the field
        if (!["", null, undefined].includes(partner.partnerQc.qcRejectedByName) && ['qc_rejected'].includes(partner.partnerQc.qcStatus)) {
          _showHideField("qcRejectedByName", false);
        } else {
          _showHideField("qcRejectedByName", true);
        }
        // TASK(M30-4457) - Rejection Date -  if condition to check if value is null or undefined and if field is not empty hide/show the field
        if (!["", null, undefined].includes(partner.partnerQc.qcRejectionDate) && ['qc_rejected'].includes(partner.partnerQc.qcStatus)) {
          _showHideField("qcRejectionDate", false);
        } else {
          _showHideField("qcRejectionDate", true);
        }
        // TASK(M30-4457) - Remark Field - if condition to check if value is null or undefined and if field is not empty hide/show the field
        if (!["", null, undefined].includes(partner.partnerQc.qcRemarks) && ['-', 'qc_rejected'].includes(partner.partnerQc.qcStatus)) {
          _showHideField("qcRemarks", false);
        } else {
          _showHideField("qcRemarks", true);
        }
      }

      function _panTypeInitFunc(panNumberConfig) {
        panNumberConfig = _getConfigObjectFromCode('panNumber');
      }
      function _panTypeInitFn(selectedItem) {
        partner.documentNews.map(function (ele) {
          if (ele.type === "pan_card") {
            ele.documentNumber = _empStrToNull(selectedItem);
          }
        });
      }
      function _aadhaarTypeInitFunc(aadharNumberConfig) {
        aadharNumberConfig = _getConfigObjectFromCode('aadhaarNumber');
      }
      function _aadhaarTypeInitFn(selectedItem) {
        partner.documentNews.map(function (ele) {
          if (ele.type === "adhaar_card") {
            ele.documentNumber = _empStrToNull(selectedItem);
          }
        });
      }

      function _chequeTypeInitFn(selectedItem) {
        partner.documentNews.map(function (ele) {
          if (ele.type === "passbook") {
            ele.documentNumber = _empStrToNull(selectedItem);
          }
        });
      }
      function _nomineePanTypeInitFn(selectedItem) {
        partner.documentNews.map(function (ele) {
          if (ele.type === "nominee_pan_card") {
            ele.documentNumber = _empStrToNull(selectedItem);
          }
        });
      }
      function _nomineeChequeTypeInitFn(selectedItem) {
        partner.documentNews.map(function (ele) {
          if (ele.type === "nominee_cancelled_cheque") {
            ele.documentNumber = _empStrToNull(selectedItem);
          }
        });
      }
      function _agencyHealthTypeChangeFn(selectedItem) {
        var list0 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list0.map(function (ele, i) {
              if (ele.type === "AGENCY_HEALTH") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "AGENCY_HEALTH",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "AGENCY_HEALTH", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      function _agencyLifeTypeInitFn(selectedItem) {
        var list1 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list1.map(function (ele, i) {
              if (ele.type === "AGENCY_LIFE") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "AGENCY_LIFE",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "AGENCY_LIFE", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      function _agencyGeneralTypeInitFn(selectedItem) {
        var list2 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list2.map(function (ele, i) {
              if (ele.type === "AGENCY_GENERAL") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "AGENCY_GENERAL",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "AGENCY_GENERAL", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      function _pospLifeTypeInitFn(selectedItem) {
        var list3 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list3.map(function (ele, i) {
              if (ele.type === "POS_LIFE") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "POS_LIFE",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "POS_LIFE", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      function _pospGeneralTypeInitFn(selectedItem) {
        var list4 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list4.map(function (ele, i) {
              if (ele.type === "POS_GENERAL") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "POS_GENERAL",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "POS_GENERAL", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      function _surveyorTypeInitFn(selectedItem) {
        var list5 = angular.copy(partner.thirdPartyAssociation);
        if (selectedItem) {
          if (partner.thirdPartyAssociation && partner.thirdPartyAssociation !== "null") {
            var flag = 0;
            list5.map(function (ele, i) {
              if (ele.type === "SURVEYOR") {
                var obj = Object.assign({}, ele, { thirdParty: selectedItem });
                partner.thirdPartyAssociation[i] = obj;
                flag = 1;
              }
            });
            if (!flag) {
              partner.thirdPartyAssociation.push({
                type: "SURVEYOR",
                thirdParty: selectedItem
              });
            }
          } else {
            var arr = [];
            arr.push({ type: "SURVEYOR", thirdParty: selectedItem });
            partner.thirdPartyAssociation = arr;
          }
        }
      }
      // Search Function / Autosuggestion Function
      function _associateChangeFn(selectedItem) {
        if (selectedItem) {
          // partner.associatedPOSPId = null;
          // partner.associatePOSP = null;
          partner.associateName = selectedItem.name;
          partner.associatePartnerId = selectedItem.id;
        } else {
          partner.associatedPOSPId = null;
          partner.associatePOSP = null;
        }
      }
      // licensing Details ChangeFn
      function _licensingDetailsInitFn(selectedItem) {
        var _showHideConfig;

        var compliantEnabled = sessionStorage.getItem("compliantEnabled");
        var broker = sessionStorage.getItem("broker");
        var showHideConfig = (_showHideConfig = {}, _defineProperty(_showHideConfig, LICENSE_NUMBER, true), _defineProperty(_showHideConfig, EXPIRY_DATE, true), _defineProperty(_showHideConfig, ASSIGN_AS_PARENT, true), _defineProperty(_showHideConfig, TYPE_OF_POSP, true), _defineProperty(_showHideConfig, GI_LICENSE_NUMBER, true), _defineProperty(_showHideConfig, GI_EXPIRY_DATE, true), _defineProperty(_showHideConfig, GI_IIB_DATE, true), _defineProperty(_showHideConfig, LI_LICENSE_NUMBER, true), _defineProperty(_showHideConfig, LI_EXPIRY_DATE, true), _defineProperty(_showHideConfig, LI_IIB_DATE, true), _defineProperty(_showHideConfig, 'bqpgiLicenseNumber', true), _defineProperty(_showHideConfig, 'bqpgiExpiryDate', true), _defineProperty(_showHideConfig, 'bqpliLicenseNumber', true), _defineProperty(_showHideConfig, 'bqpliExpiryDate', true), _defineProperty(_showHideConfig, 'bqpCompositeLicenseNumber', true), _defineProperty(_showHideConfig, 'bqpCompositeExpiryDate', true), _defineProperty(_showHideConfig, POSP_EXPIRY_DATE, true), _defineProperty(_showHideConfig, POSP_LICENSE_NUMBER, true), _defineProperty(_showHideConfig, TYPE_OF_SP, true), _showHideConfig);

        if (broker === 'dbs') {
          showHideConfig = {};
        }

        if (selectedItem === "posp" && compliantEnabled !== "false") {
          showHideConfig = _extends({}, showHideConfig, _defineProperty({}, TYPE_OF_POSP, false));
        } else if (selectedItem === "posp" && compliantEnabled !== "true") {
          var _extends3;

          showHideConfig = _extends({}, showHideConfig, (_extends3 = {}, _defineProperty(_extends3, POSP_EXPIRY_DATE, false), _defineProperty(_extends3, POSP_LICENSE_NUMBER, false), _defineProperty(_extends3, ASSIGN_AS_PARENT, false), _extends3));
        } else if (selectedItem === "sp") {
          if (['idfcfirstbank', 'federal'].includes(broker)) {
            if (compliantEnabled !== "false") {
              showHideConfig = _extends({}, showHideConfig, _defineProperty({}, TYPE_OF_SP, false));
            } else {
              var _extends5;

              showHideConfig = _extends({}, showHideConfig, (_extends5 = {}, _defineProperty(_extends5, SP_EXPIRY_DATE, false), _defineProperty(_extends5, SP_LICENSE_NUMBER, false), _defineProperty(_extends5, ASSIGN_AS_PARENT, false), _extends5));
            }
          } else {
            var _extends6;

            showHideConfig = _extends({}, showHideConfig, (_extends6 = {}, _defineProperty(_extends6, LICENSE_NUMBER, false), _defineProperty(_extends6, EXPIRY_DATE, false), _extends6));
          }
        } else if (['bqp'].includes(selectedItem)) {
          var _partner2 = partner,
              certificateDetails = _partner2.certificateDetails;

          certificateDetails.map(function (e) {
            var pospType = e.pospType;

            if (["bqpgi"].includes(pospType)) {
              var _extends7;

              showHideConfig = _extends({}, showHideConfig, (_extends7 = {}, _defineProperty(_extends7, 'bqpgiLicenseNumber', false), _defineProperty(_extends7, 'bqpgiExpiryDate', false), _extends7));
            } else if (["bqpli"].includes(pospType)) {
              var _extends8;

              showHideConfig = _extends({}, showHideConfig, (_extends8 = {}, _defineProperty(_extends8, 'bqpliLicenseNumber', false), _defineProperty(_extends8, 'bqpliExpiryDate', false), _extends8));
            } else if (["bqpcomposite"].includes(pospType)) {
              var _extends9;

              showHideConfig = _extends({}, showHideConfig, (_extends9 = {}, _defineProperty(_extends9, 'bqpCompositeLicenseNumber', false), _defineProperty(_extends9, 'bqpCompositeExpiryDate', false), _extends9));
            }
          });
        }

        Object.keys(showHideConfig).forEach(function (ele) {
          _showHideField(ele, showHideConfig[ele]);
        });
      }
      function _selectFresherFn(selectedItem) {
        var _partner3 = partner,
            existingBusinessTurnover = _partner3.existingBusinessTurnover,
            monthlyMotorPremium = _partner3.monthlyMotorPremium,
            monthlyHealthPremium = _partner3.monthlyHealthPremium,
            monthlyLifePremium = _partner3.monthlyLifePremium;

        var turtlemint = sessionStorage.getItem("broker") === "turtlemint";
        if (turtlemint && selectedItem === "0") {
          partner.existingBusinessTurnover = existingBusinessTurnover || "0-1 lakh";
          partner.monthlyMotorPremium = monthlyMotorPremium || 0;
          partner.monthlyHealthPremium = monthlyHealthPremium || 0;
          partner.monthlyLifePremium = monthlyLifePremium || 0;
          // console.log("partner", partner);
        }
      }

      function _licensingDetailsChangeFn(selectedItem) {
        _licensingDetailsInitFn(selectedItem);
        // if(selectedItem) {
        //   if (['posp', 'sp', 'nonPosp', 'na'].includes(selectedItem)) {
        //     if (Object.keys(partner.pospDetail).length !== 0) {
        //       partner.pospDetail = {};
        //     }
        //   }
        // }
      }
      function _typeOfPOSPInitFn(selectedItem) {
        var _config;

        // if(compliantStatus) {
        var config = (_config = {}, _defineProperty(_config, GI_LICENSE_NUMBER, true), _defineProperty(_config, GI_EXPIRY_DATE, true), _defineProperty(_config, GI_IIB_DATE, true), _defineProperty(_config, ASSIGN_AS_PARENT, true), _defineProperty(_config, LI_LICENSE_NUMBER, true), _defineProperty(_config, LI_EXPIRY_DATE, true), _defineProperty(_config, LI_IIB_DATE, true), _config);
        if (selectedItem.length && selectedItem[0] === "pospgi" && selectedItem[1] !== "pospli") {
          var _extends10;

          config = _extends({}, config, (_extends10 = {}, _defineProperty(_extends10, GI_LICENSE_NUMBER, false), _defineProperty(_extends10, GI_EXPIRY_DATE, false), _defineProperty(_extends10, GI_IIB_DATE, false), _defineProperty(_extends10, ASSIGN_AS_PARENT, false), _extends10));
        }
        if (selectedItem.length && (selectedItem[0] === "pospgi" && selectedItem[1] === "pospli" || selectedItem[1] === "pospgi" && selectedItem[0] === "pospli")) {
          var _extends11;

          config = _extends({}, config, (_extends11 = {}, _defineProperty(_extends11, GI_LICENSE_NUMBER, false), _defineProperty(_extends11, GI_EXPIRY_DATE, false), _defineProperty(_extends11, GI_IIB_DATE, false), _defineProperty(_extends11, ASSIGN_AS_PARENT, false), _defineProperty(_extends11, LI_LICENSE_NUMBER, false), _defineProperty(_extends11, LI_EXPIRY_DATE, false), _defineProperty(_extends11, LI_IIB_DATE, false), _extends11));
        }
        if (selectedItem.length && selectedItem[0] === "pospli" && selectedItem[1] !== "pospgi") {
          var _extends12;

          config = _extends({}, config, (_extends12 = {}, _defineProperty(_extends12, ASSIGN_AS_PARENT, false), _defineProperty(_extends12, LI_LICENSE_NUMBER, false), _defineProperty(_extends12, LI_EXPIRY_DATE, false), _defineProperty(_extends12, LI_IIB_DATE, false), _extends12));
        }

        Object.keys(config).forEach(function (ele) {
          _showHideField(ele, config[ele]);
        });
        // }
      }

      function _typeOfSPInitFn(selectedItem) {
        var _config2;

        var config = (_config2 = {}, _defineProperty(_config2, SP_GI_LICENSE_NUMBER, true), _defineProperty(_config2, SP_GI_EXPIRY_DATE, true), _defineProperty(_config2, SP_GI_IIB_DATE, true), _defineProperty(_config2, SP_LI_LICENSE_NUMBER, true), _defineProperty(_config2, SP_LI_EXPIRY_DATE, true), _defineProperty(_config2, SP_LI_IIB_DATE, true), _defineProperty(_config2, SP_COMPOSITE_LICENSE_NUMBER, true), _defineProperty(_config2, SP_COMPOSITE_EXPIRY_DATE, true), _defineProperty(_config2, SP_COMPOSITE_IIB_DATE, true), _config2);
        if (['dbs'].includes(sessionStorage.getItem("broker"))) {
          config = {};
        } else {
          config = _showTypeOfSPFn(selectedItem, config);
        }

        Object.keys(config).forEach(function (ele) {
          _showHideField(ele, config[ele]);
        });
      }

      function _showTypeOfSPFn(spType, config) {
        if (spType === "spgi") {
          var _extends13;

          config = _extends({}, config, (_extends13 = {}, _defineProperty(_extends13, SP_GI_LICENSE_NUMBER, false), _defineProperty(_extends13, SP_GI_EXPIRY_DATE, false), _defineProperty(_extends13, SP_GI_IIB_DATE, false), _extends13));
        } else if (spType === "spli") {
          var _extends14;

          config = _extends({}, config, (_extends14 = {}, _defineProperty(_extends14, SP_LI_LICENSE_NUMBER, false), _defineProperty(_extends14, SP_LI_EXPIRY_DATE, false), _defineProperty(_extends14, SP_LI_IIB_DATE, false), _extends14));
        } else if (spType === "spcomposite") {
          var _extends15;

          config = _extends({}, config, (_extends15 = {}, _defineProperty(_extends15, SP_COMPOSITE_LICENSE_NUMBER, false), _defineProperty(_extends15, SP_COMPOSITE_EXPIRY_DATE, false), _defineProperty(_extends15, SP_COMPOSITE_IIB_DATE, false), _extends15));
        }
        return config;
      }
      function _typeOfPOSPChangeFn(selectedItem) {
        _typeOfPOSPInitFn(selectedItem);
      }
      function _typeOfSPChangeFn(selectedItem) {
        _typeOfSPInitFn(selectedItem);
      }
      function searchFn(searchKey) {
        var deferred = $q.defer();
        if (searchKey && searchKey.length > 3) {
          var params = {
            tenant: "turtlemint",
            name: searchKey,
            isPOSP: true
          };
          var promise = commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + "/mintpro" + partnerURL, "/search", params, "GET");
          promise.then(function (res) {
            var data = _reverseSortArray(res.data.data);
            deferred.resolve(data);
          });
        } else {
          deferred.reject(true);
        }
        return deferred.promise;
      }
      function _reverseSortArray(data) {
        data.sort();
        return data.reverse();
      }

      function _showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = ['mashreq', 'dbs'].includes(sessionStorage.getItem("broker")) ? formConfigService.findFieldConfig(fieldCode) : _getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        fieldConfig.hide = toHide;
        if (toHide && !dontDelete) {
          delete partner[fieldConfig.code];
        }
      }

      function _markFieldRequired(fieldCode, required) {
        var fieldConfig = ['mashreq', 'dbs'].includes(sessionStorage.getItem("broker")) ? formConfigService.findFieldConfig(fieldCode) : _getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;

        fieldConfig.required = toRequired;
      }
      function _markFieldDisabled(fieldCode, disabled) {
        var fieldConfig = ['mashreq', 'dbs'].includes(sessionStorage.getItem("broker")) ? formConfigService.findFieldConfig(fieldCode) : _getConfigObjectFromCode(fieldCode);
        var toDisabled = disabled ? true : false;

        fieldConfig.disabled = toDisabled;
        fieldConfig.readonly = toDisabled;
      }

      function _getConfigObjectFromCode(code) {
        var processes = [enrollmentProcess, selectAssociate, associate, address, licHolding, licensingDetails, pospSP, typeOfPospList, pospGI, pospLI, pospBQP, posp, spLI, spCOMPOSITE, spGI, documents, partnerQc];
        var returnVal = null;
        for (var i in processes) {
          returnVal = processes[i] && showHideMaster(processes[i], code);
          if (returnVal) {
            return returnVal;
          }
        }
        return {};
      }
      function showHideMaster(param, code) {
        for (var idx = param.length - 1; idx >= 0; idx--) {
          var config = param[idx];
          if (config.code === code) {
            return config;
          }
        }
        return null;
      }
      function _empStrToNull(selectedItem) {
        return selectedItem !== "" ? selectedItem : null;
      }
      function _commonEmptyFun(key, val) {
        var attrs = key.split(".");
        var attr = null;
        var objRef = partner;
        while (attr = attrs.shift()) {
          if (_typeof(objRef[attr]) === "object") {
            objRef = objRef[attr];
          } else break;
        }
        if (objRef && typeof objRef[attr] === "string") {
          objRef[attr] = val || null;
        }
      }
      function _deactivateIntFn(selectedItem) {
        var element = document.getElementById("deactivationRemarks");
        if (selectedItem === "Other") {
          element.removeAttribute("disabled");
        } else {
          element.setAttribute("disabled", "");
        }
      }
      function getPersonalInfo() {
        return personalInfo;
      }
      function getAddress() {
        return address;
      }
      function getBankDetails() {
        return bankDetails;
      }
      function getBankVerifier() {
        return bankVerifier;
      }
      function getDocuments() {
        return documents;
      }
      function getAdditionalInfo() {
        return additionalInfo;
      }
      function getLicHolding() {
        return licHolding;
      }
      function getAssociatedIRDAI() {
        return associatedIRDAI;
      }
      function getEnrollmentProcess() {
        return enrollmentProcess;
      }
      function getEligibleFor() {
        return eligibleFor;
      }
      function getAssociate() {
        return associate;
      }
      function getSelectAssociate() {
        return selectAssociate;
      }
      function getLeadTracking() {
        return leadtracking;
      }
      function getDocumentOwner() {
        return documentOwner;
      }
      function getRemarks() {
        return remarks;
      }
      function getPartnerVerifier() {
        return partnerVerifier;
      }
      function getLicensingDetails(partnerLead) {
        pageType.partnerLead = partnerLead;
        return licensingDetails;
      }
      function getPospSP() {
        return pospSP;
      }
      function getPospGI() {
        return pospGI;
      }
      function getPospLI() {
        return pospLI;
      }
      function getSpLI() {
        return spLI;
      }
      function getSpCOMPOSITE() {
        return spCOMPOSITE;
      }
      function getSpGI() {
        return spGI;
      }
      function getTrainingDetails() {
        return trainingDetails;
      }
      function getTypeOfPosp() {
        return typeOfPospList;
      }
      function getPospBQP() {
        return pospBQP;
      }
      function getPosp() {
        return posp;
      }
      function getNomineeBankDetails() {
        return nomineeBankDetails;
      }
      function getNomineeDocuments() {
        return nomineeDocuments;
      }
      function getDeactivateProfile() {
        return deactivateProfile;
      }
      function getPartnerQc() {
        qcStatusFieldCheck(); //Task(M30-4711): check if partnerQc object or qcStatus object is null or have value.
        return partnerQc;
      }
    }
  }
})();
"use strict";

(function () {
  angular.module("brokerAdmin.partner").provider("PartnerFormConfigService", PartnerFormConfigService);

  PartnerFormConfigService.$inject = [];
  return PartnerFormConfigService;

  function PartnerFormConfigService() {
    PartnerFormConfigService.$inject = ['$q', 'formConfigService', 'PartnerAddUpdateService'];
    return {
      $get: PartnerFormConfigService
    };

    function PartnerFormConfigService($q, formConfigService, PartnerAddUpdateService) {
      var getFormConfig = formConfigService.getFormConfig,
          mapDynamicPropsToConfig = formConfigService.mapDynamicPropsToConfig;

      var dynamicPropsMap = PartnerAddUpdateService.getDynamicPropsMap();

      return {
        // Fun
        getPartnerFormConfig: getPartnerFormConfig
      };

      function getPartnerFormConfig(reqObj) {
        var defer = $q.defer();

        getFormConfig(reqObj).then(function (res) {
          var response = res.data ? res.data : res;
          mapDynamicPropsToConfig(response, dynamicPropsMap);
          /* finalConfigs[currentConfigKeyName] = res.data;  */
          defer.resolve(response);
        }).catch(function (data) {
          defer.reject(data);
        });

        return defer.promise;
      }

      /* return {
        getFormConfig: getPartnerFormConfig,
      }; */
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin.Auth').controller('AuthCtrl', controller);

  controller.$inject = ['$scope', 'AuthService', '$window', '$cookies', '$state', '$q', 'commonService'];

  function controller($scope, AuthService, $window, $cookies, $state, $q, commonService, GENERAL_CONFIG) {
    $scope.broker = sessionStorage.getItem('broker');
    var getLoginType = commonService.getCookie('loginType');
    $scope.validateNumber = validateNumber;
    $scope.validateEmail = validateEmail;
    $scope.validateOtp = validateOtp;
    $scope.validateEmailOtp = validateEmailOtp;
    $scope.loginWindow = loginWindow;
    $scope.isApp = commonService.isApp();
    $scope.hideImage = ['reliancejio'].includes($scope.broker);

    init();

    // Google success and failure events
    $scope.$on('event:google-plus-signin-success', function (event, GoogleUser) {
      AuthService.authenticateUser(GoogleUser);
    });

    $scope.$on('event:google-plus-signin-failure', function () {
      $window.alert('Google login failed!');
    });

    var receiveMessage = function receiveMessage(event) {
      var code = event.data;
      code = code.split('&state')[0];
      AuthService.authenticateOAuthUser(code);
    };

    function init() {
      if (!$scope.isApp) {
        switch (getLoginType) {
          case 'microsoftLogin':
            $scope.showLoginType = 'microsoftLogin';
            break;
          case 'googleLogin':
          case 'google':
            $scope.showLoginType = 'googleLogin';
            break;
          case 'emailOtpLogin':
            $scope.showLoginType = 'emailOtpLogin';
            break;
          case 'otp':
            $scope.showLoginType = 'otp';
            break;
        }
      } else {
        $scope.showLoginType = 'otp';
      }
    }

    function validateEmail() {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test($scope.email) === false) {
        $window.alert('Invalid Email Address');

        return false;
      }
      _ninjaemailotpsignin();

      return true;
    }

    function validateNumber() {
      if ($scope.phone !== undefined && $scope.phone.length === 10) {
        _ninjaotpsignin();
      } else {
        $scope.showErrorField = true;
      }
    }

    function loginWindow() {
      $window.removeEventListener('message', receiveMessage);
      var url = 'https://login.microsoftonline.com/' + GENERAL_CONFIG.MICROSOFT_TENANT_ID + '/oauth2/v2.0/authorize?response_type=code&client_id=' + GENERAL_CONFIG.MICROSOFT_CLIENT_ID + '&scope=' + GENERAL_CONFIG.MICROSOFT_SCOPE + '&state=' + GENERAL_CONFIG.MICROSOFT_STATE + '&redirect_uri=' + GENERAL_CONFIG.MICROSOFT_REDIRECT_URL;
      var name = 'OAuthWindow';
      var strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
      var windowObjectReference = $window;
      var previousUrl = null;
      if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = $window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        windowObjectReference = $window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }
      $window.addEventListener('message', function (event) {
        return receiveMessage(event);
      }, false);
      previousUrl = url;
    }

    function _ninjaemailotpsignin(jsonData) {

      $scope.otp = null;
      var reqUrl = '/ninjaotpsignin';
      var jsonData = {
        email: $scope.email
      };
      commonService.makeHttpCall(reqUrl, jsonData, 'POST', false, false, true).then(function (res) {
        $scope.res = res.data;
        if (!$scope.res.error_message) {
          $scope.showOtpField = true;
          $scope.hideNumField = true;
          $window.localStorage.setItem('s', btoa(res.data.sessionId));
        } else {
          $window.alert('Couldn\'t sign you in!');
        }
      });
    }

    function _ninjaotpsignin(jsonData) {
      var reqUrl = '/ninjaotpsignin';
      var jsonData = {
        mobileNo: $scope.phone
      };
      commonService.makeHttpCall(reqUrl, jsonData, 'POST', false, false, true).then(function (res) {
        $scope.res = res.data;
        if (!$scope.res.error_message) {
          $scope.showOtpField = true;
          $scope.hideNumField = true;
          $window.localStorage.setItem('s', btoa(res.data.sessionId));
        } else {
          $window.alert('Couldn\'t sign you in!');
        }
      });
    }

    function validateEmailOtp(jsonData) {
      var reqUrl = '/ninjaotpverify';
      var jsonData = {
        otp: $scope.otp,
        email: $scope.email,
        sessionId: $scope.res.sessionId
      };
      commonService.makeHttpCall(reqUrl, jsonData, 'POST', false, false, true).then(function (res) {
        if (!res.data.error_message) {
          var sessionId = atob($window.localStorage.getItem('s'));
          AuthService.authenticateUserByOTP(res);
        } else if (res.data.error_message === 'No more Attempt') {
          $scope.res.data = 'Number of attempts exceeded';
        } else {
          $scope.showErrorField = true;
          $scope.res.data = res.data.error_message;
        }
      });
    }

    function validateOtp(jsonData) {
      var reqUrl = '/ninjaotpverify';
      var jsonData = {
        otp: $scope.otp,
        mobileNo: $scope.phone,
        sessionId: $scope.res.sessionId
      };
      if ($scope.isApp) {
        var FIREBASE_TOKEN = AuthService.getFirebaseToken();
        if (FIREBASE_TOKEN) {
          jsonData.firebaseToken = FIREBASE_TOKEN;
        }
      }
      commonService.makeHttpCall(reqUrl, jsonData, 'POST', false, false, true).then(function (res) {
        if (!res.data.error_message) {
          var sessionId = atob($window.localStorage.getItem('s'));
          if (sessionId !== res.data.sid) {
            $scope.showErrorField = true;
            $scope.res.data = 'Invalid login';
            return;
          }
          AuthService.authenticateUserByOTP(res);
        } else if (res.data.error_message === 'No more Attempt') {
          $scope.res.data = 'Number of attempts exceeded';
        } else {
          $scope.showErrorField = true;
          $scope.res.data = res.data.error_message;
        }
      });
    }
  }
})();
'use strict';

(function () {
    angular.module('brokerAdmin.Auth').controller('AppAuthCtrl', controller);

    controller.$inject = ['AuthService', '$stateParams'];

    function controller(AuthService, $stateParams) {
        init();
        function init() {
            var token = $stateParams.token ? atob($stateParams.token) : null;
            if (!AuthService.isLoggedIn() && token) {
                AuthService.appAuthenticateUser(token);
            } else {
                AuthService.redirectPostLogin();
            }
        }
    }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.Auth').controller('HomeCtrl', controller);

  controller.$inject = ['$scope', 'AuthService', 'commonService', 'TrackingService', '$mdToast', 'brokerConfig'];

  function controller($scope, AuthService, commonService, TrackingService, $mdToast, brokerConfig) {
    $scope.hasPermission = AuthService.hasPermission;
    $scope.isApp = commonService.isApp();
    $scope.requestAccess = requestAccess;
    $scope.pushToGTMDatalayer = pushToGTMDatalayer;
    $scope.goToMintAcademy = goToMintAcademy;
    init();

    function init() {
      $scope.broker = sessionStorage.getItem('broker') || brokerConfig.broker;
      $scope.home = {
        logout: AuthService.logoutUser,
        user: AuthService.getUser()
      };
      $scope.navbarConfig = {
        vertical: 'home',
        title: 'Ninja Home'
      };
      $scope.showRequestAccess = AuthService.showRequestAccess();
      initModuleLinks();
    }

    function initModuleLinks() {
      $scope.home = _extends({}, $scope.home, AuthService.getModuleConfig());
    }

    function pushToGTMDatalayer(tag, eventData) {
      TrackingService.pushToGTMDatalayer(tag, 'track_event', eventData);
    }

    function requestAccess() {
      var msg = 'Please raise your query at pospsupport@turtlemint.com';
      TrackingService.pushToGTMDatalayer('APP_REQUEST_ACCESS', 'track_event', {});

      $mdToast.show($mdToast.simple().textContent(msg).position('bottom right').hideDelay(10000));
    }

    function goToMintAcademy() {
      AuthService.goToMintAcademy();
      TrackingService.pushToGTMDatalayer('HOME_NINJA_ACADEMY_MODULE_CLICK', 'track_event', {});
    }
  }
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.Auth').controller('ContestsCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'commonService', 'TrackingService', 'GENERAL_CONFIG'];

  function controller($scope, $mdDialog, commonService, TrackingService, GENERAL_CONFIG) {

    var ViewModel = $scope;
    ViewModel.selectCard = selectCard;
    ViewModel.loadMoreBrochure = loadMoreBrochure;
    init();

    function init() {
      ViewModel.brochures = [];
      ViewModel.query = {
        page: 0,
        size: 10
      };
      ViewModel.loadMore = {
        show: true,
        loading: false
      };
      ViewModel.navbarConfig = {
        vertical: 'qis',
        title: 'Contests'
      };

      _getBrochures();
    }

    function _getBrochures() {

      var domain = GENERAL_CONFIG.CMS_URL;
      var api = 'api/cms/contents/promotionCarousel';
      var params = {
        tenant: 'turtlemint',
        location: 'NinjaContest',
        page: ViewModel.query.page,
        size: ViewModel.query.size
      };
      ViewModel.loadMore.loading = true;
      // API FOR GETTING POSTERS
      commonService.makeThirdPartyApiCall(domain, api, params, 'GET', true).then(function (data) {
        ViewModel.loadMore.loading = false;
        var resData = data.data;
        if (ViewModel.brochures.length <= 0) {
          ViewModel.brochures = resData;
          if (ViewModel.query.page === 0) {
            loadMoreBrochure();
          }
        } else {
          var _ViewModel$brochures;

          (_ViewModel$brochures = ViewModel.brochures).push.apply(_ViewModel$brochures, _toConsumableArray(resData));
        }
        if (resData && resData.length >= ViewModel.query.size) {
          ViewModel.loadMore.show = true;
        } else {
          ViewModel.loadMore.show = false;
        }
      }).catch(function (err) {
        ViewModel.loadMore.loading = false;
        commonService.showToast('Error while fetching Posters');
      });
    }

    function loadMoreBrochure() {
      ViewModel.query.page = ViewModel.query.page + 1;
      _getBrochures();
    }

    function selectCard(card) {
      var eventData = {
        title: card.title
      };
      TrackingService.pushToGTMDatalayer('CONTEST_POSTER_CLICK', 'track_event', eventData);
      $mdDialog.show({
        templateUrl: 'dev/auth/templates/contests-dialog.tpl.html',
        controller: ContestDialogController,
        clickOutsideToClose: true,
        locals: {
          card: card
        }
      });

      ContestDialogController.$inject = ['$scope', '$mdDialog', 'card', 'MasterFileService'];
      function ContestDialogController($scope, $mdDialog, card, MasterFileService) {
        $scope.card = card;
        $scope.card.imgLink = MasterFileService.getAppImageViewUrl($scope.card.image);
        $scope.cancel = $mdDialog.cancel;
      }
    }
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('SaleService', SaleService);

  SaleService.$inject = [];

  return SaleService;

  // //////////////////////////////////////////////////////

  /**
   * SaleService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function SaleService() {
    SaleService.$inject = ['$q', 'commonService', 'MIS_CONSTANTS', '$filter', '$mdDialog', '$state', '$window', '$timeout', 'FormHelperService', '$mdToast', 'MasterFileService', 'CONSTANTS_LIST', 'ninjaCommonDataService', 'CompliantService', 'misService', 'AuthService', '$cookies'];

    return {
      $get: SaleService
    };

    // //////////////////////////////////////////////////////

    /**
     * SaleService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.SaleService#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     * @param      {Object}    MIS_CONSTANTS                  MIS Constants
     *
     * @return     {Object}    Instance of the Service
     */
    function SaleService($q, commonService, MIS_CONSTANTS, $filter, $mdDialog, $state, $window, $timeout, FormHelperService, $mdToast, MasterFileService, CONSTANTS_LIST, ninjaCommonDataService, CompliantService, misService, AuthService, $cookies) {

      var currentSale = {};
      var qcErrors = [];
      var assignedVertical;
      var saleConfig = {};
      var TM_URL = commonService.getUrlTM();

      //Get Ninja Common data api list
      ninjaCommonDataService.getNinjaCommonData().then(function (ninjaFiltersList) {
        saleConfig.manualQCStatuses = angular.copy(ninjaFiltersList.manualQCStatus);
        var manualQCStatusConfig = getConfigObjectFromCode('manualQCStatus');
        manualQCStatusConfig.selectConfig.options = saleConfig.manualQCStatuses;

        saleConfig.vehicleTypes = angular.copy(ninjaFiltersList.vehicleType);
        saleConfig.vehicleTypesAll = angular.copy(ninjaFiltersList.vehicleType);

        saleConfig.policyStatuses = angular.copy(ninjaFiltersList.caseStatus);
        var policyStatusConfig = getConfigObjectFromCode('policyStatus');
        policyStatusConfig.selectConfig.options = saleConfig.policyStatuses;

        saleConfig.paymentFrequencyTypes = angular.copy(ninjaFiltersList.paymentFrequency);
        var paymentFrequencyConfig = getConfigObjectFromCode('paymentFrequency');
        paymentFrequencyConfig.selectConfig.options = saleConfig.paymentFrequencyTypes;

        saleConfig.paymentModes = angular.copy(ninjaFiltersList.paymentModes);
        var paymentModeConfig = getConfigObjectFromCode('paymentMode');
        paymentModeConfig.selectConfig.options = saleConfig.paymentModes;

        saleConfig.captureReason = angular.copy(ninjaFiltersList.reason);
        var reasonConfig = getConfigObjectFromCode('reason');
        reasonConfig.selectConfig.options = saleConfig.captureReason;

        saleConfig.sourceOfSaleStatus = angular.copy(ninjaFiltersList.sourceOfSaleStatus);

        saleConfig.sourceOfSaleType = angular.copy(ninjaFiltersList.sourceOfSaleType);
        var typeOfSaleConfig = getConfigObjectFromCode('typeOfSale');
        typeOfSaleConfig.selectConfig.options = saleConfig.sourceOfSaleType;

        saleConfig.preSaleVerificationStatus = angular.copy(ninjaFiltersList.preSaleVerificationStatus);
        var callStatusConfig = getConfigObjectFromCode('preSaleVerificationStatus');
        callStatusConfig.selectConfig.options = saleConfig.preSaleVerificationStatus;

        saleConfig.renewalPaymentMode = CONSTANTS_LIST.renewalPaymentMode;
        var renewalPaymentModeConfig = getConfigObjectFromCode('renewalPaymentMode');
        renewalPaymentModeConfig.selectConfig.options = saleConfig.renewalPaymentMode;

        saleConfig.healthInsurers = ninjaFiltersList.healthInsurer;
      });
      saleConfig.broker = sessionStorage.getItem('broker');
      saleConfig.productNames = angular.copy(MIS_CONSTANTS.productNames);
      saleConfig.coverTypes = angular.copy(MIS_CONSTANTS.coverTypes);
      saleConfig.healthPlanTypes = angular.copy(MIS_CONSTANTS.healthPlanTypes);
      saleConfig.lifePlanTypes = angular.copy(MIS_CONSTANTS.lifePlanTypes);
      saleConfig.subPlanTypes = angular.copy(MIS_CONSTANTS.subPlanTypes);
      saleConfig.paymentTypes = angular.copy(MIS_CONSTANTS.paymentTypes);
      saleConfig.benefitPayoutTypes = angular.copy(MIS_CONSTANTS.benefitPayoutTypes);
      saleConfig.businessVerticals = ['Retail', 'Corporate'];
      saleConfig.productCategories = angular.copy(MIS_CONSTANTS.productCategories);
      saleConfig.lifeCategories = angular.copy(MIS_CONSTANTS.lifeCategories);
      saleConfig.carrierTypes = angular.copy(MIS_CONSTANTS.carrierTypes);
      saleConfig.businessTypes = angular.copy(MIS_CONSTANTS.businessTypes);
      saleConfig.channelTypes = angular.copy(MIS_CONSTANTS.channelTypes);
      saleConfig.commissionChannelTypes = angular.copy(MIS_CONSTANTS.commissionChannelTypes);
      saleConfig.ewPlanTypes = angular.copy(MIS_CONSTANTS.ewPlanTypes);
      saleConfig.productPaymentModeList = angular.copy(MIS_CONSTANTS.productPaymentModeList);
      saleConfig.paymentStatuses = ['Pending', 'SUCCESS', 'COMPLETED'];
      saleConfig.ppRequestTypes = ['Inspection', 'Medical'];
      saleConfig.ppStatuses = ['Pass', 'Fail'];
      saleConfig.tlNames = ['Unassisted', 'Rahul Brahmne', 'Shuvamay C', 'Yagnesh Bhatt'];
      saleConfig.salesExecs = ['Abhishek More', 'Abishek More', 'Ali Asgar', 'Altaf Narpali', 'Ameya Vaidya', 'Anoop Kumar', 'Anoop Pandhi', 'Avinash Sawant', 'B S Patil', 'Bhim Kamble', 'Chitranjan Sharma', 'Damodar', 'Damodar Yadav', 'Dattatray Virkar', 'Dipeeka Tawde', 'Firdos', 'Gina P', 'Girish', 'Girish Kumar Jha', 'Goyal Associates', 'Gulfirdos S', 'Gulfirdos Shaikh', 'Gulfirdosh', 'Hardik Panchal', 'Hasmukh Rathod', 'Hiten Mistry', 'Idrish Baig', 'Jagdish Panda', 'Kavita', 'Kiran Bakshi', 'Kiran Tiwari', 'Krishna Mody', 'Nelson Kale', 'Pankaj Kumar', 'Pawan C', 'Pawan Chaudhary', 'Pawan Choudhary', 'Pratibha', 'Pratibha S', 'Praveen Marepnoor', 'Pravin Bhosale', 'Pravin Bhosle', 'Pravind Bhosle', 'Rahul Nemade', 'Rajesh Tiwari', 'Raju Kumar', 'Sabir Mohammed', 'Sandeep Kamble', 'Sanjay Kesarkar', 'Shankar Bedi', 'Shashank Ambadkar', 'Shivaji Kamble', 'Shivbhushan Mishra', 'Shrikant Shetty', 'Sufiyan Shaikh', 'Sukhdev Patil', 'Suresh Maurya', 'Suresh Murtadkar', 'Veena', 'Vijay Jadhav', 'Vinayak Shinde'];
      saleConfig.manufacturerWarrantyTermList = [1, 2, 3, 4, 5];
      //saleConfig.allTenants = MIS_CONSTANTS.allTenants;
      saleConfig.turtlemintTenant = [];
      saleConfig.fuelTypes = angular.copy(MIS_CONSTANTS.fuelTypes);
      saleConfig.variants = [];
      saleConfig.unitManagers = [];
      saleConfig.planNames = [];
      saleConfig.turtlemintStrategicAllianceRM = MIS_CONSTANTS.turtlemintStrategicAllianceRM;
      saleConfig.cancellationRemarks = angular.copy(MIS_CONSTANTS.cancellationRemarks);
      var ewProductCategory = angular.copy(MIS_CONSTANTS.ewProductCategory);
      var dukandaarProductCategory = angular.copy(MIS_CONSTANTS.dukandaarProductCategory);
      var memberConfig = {};
      if (saleConfig.broker === CONSTANTS_LIST.BROKER.HDFCBANK) {
        saleConfig.productCategories.push(ewProductCategory);
        saleConfig.productCategories.push(dukandaarProductCategory);
      }
      if (saleConfig.broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _saleConfig$productCa;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_saleConfig$productCa = saleConfig.productCategories).push.apply(_saleConfig$productCa, _toConsumableArray(turtlemintProductCategories));
      }
      saleConfig.genderList = angular.copy(MIS_CONSTANTS.genderList);
      saleConfig.addOns = angular.copy(CONSTANTS_LIST.addOns);
      saleConfig.proposerTitle = angular.copy(CONSTANTS_LIST.proposerTitle);
      if (saleConfig.broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _saleConfig$proposerT;

        (_saleConfig$proposerT = saleConfig.proposerTitle).push.apply(_saleConfig$proposerT, _toConsumableArray(CONSTANTS_LIST.proposerTitleTurtlemintBroker));
      }

      // Called getTenants Function As bellow
      getTenants();
      var sourceOfSaleFields = [{
        name: 'Type of Sale',
        code: 'typeOfSale',
        placeholder: '',
        type: 'select',
        disabled: true,
        selectConfig: {
          optionType: 'OBJECT'
        },
        changeFn: _typeOfSaleChangeFn,
        initFn: _typeOfSaleInitFn,
        required: true
      }, {
        name: 'BQP',
        code: 'bqp',
        placeholder: 'Select BQP',
        hide: false,
        type: 'autocomplete',
        initFn: _selectedBQPInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedBQPChangeFn,
          querySearch: _searchBQPFn,
          displayKey: 'name',
          valueKey: 'clientId',
          resultTitleKey: 'title',
          resultLabelKey: 'label'
        },
        required: false
      }];

      var basicDetailsConfig = [{
        name: 'MIS ID',
        code: 'folderNo',
        placeholder: '',
        type: 'text',
        disabled: true,
        required: true
      }, {
        name: 'Policy Number',
        code: 'policyNumber',
        placeholder: '',
        type: 'text',
        required: true,
        blurFn: _policyNumberBlurFn,
        initFn: _policyNumberInitFn,
        changeFn: _policyNumberInitFn
      }, {
        name: 'Application Number',
        code: 'applicationNumber',
        placeholder: 'Application Number',
        type: 'text',
        required: false,
        initFn: _applicationNumberInitFn,
        changeFn: _applicationNumberInitFn
      }, {
        name: 'Covernote No.',
        code: 'covernoteNo',
        placeholder: '',
        type: 'text',
        blurFn: _covernoteNumberBlurFn
      }, {
        name: 'Covernote Issuance Date',
        code: 'covernoteIssuanceDate',
        placeholder: '',
        type: 'date',
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Business Type',
        code: 'businessType',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: _businessTypeChangeFn,
        initFn: _businessTypeInitFn,
        selectConfig: {
          options: saleConfig.businessTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Business Vertical',
        code: 'businessVertical',
        placeholder: '',
        type: 'select',
        required: true,
        selectConfig: {
          options: saleConfig.businessVerticals
        }
      }, {
        name: 'Channel Type',
        code: 'channelType',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: channelTypeChangeFn,
        initFn: channelTypeInitFn,
        selectConfig: {
          options: saleConfig.channelTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Policy Issuance Date',
        code: 'issuanceDate',
        placeholder: '',
        type: 'date',
        initFn: _issuanceDateChangeFn,
        changeFn: _issuanceDateChangeFn,
        dateConfig: {
          maxDate: moment().toDate()
        }
      }, {
        name: 'Policy Status',
        code: 'policyStatus',
        placeholder: '',
        type: 'select',
        initFn: _policyStatusInitFn,
        changeFn: _policyStatusChangeFn,
        required: true,
        selectConfig: {
          options: saleConfig.policyStatuses,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: '',
        code: 'allowStatusOveride',
        type: 'checkbox',
        hide: true
      }, {
        name: 'First Tagged Issued/Active',
        code: 'firstTimeTaggedAsIssuedDate',
        placeholder: ' ',
        type: 'date',
        disabled: true,
        hide: true
      }, {
        name: 'Sub Status',
        code: 'subStatus',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: saleConfig.policyStatuses,
          valueKey: 'code',
          textKey: 'name'
        },
        hide: true,
        required: true
      }, {
        name: 'Product Category',
        code: 'productCategory',
        placeholder: '',
        type: 'select',
        required: true,
        initFn: _productCategoryInItFn,
        changeFn: _productCategoryChangeFn,
        selectConfig: {
          options: saleConfig.productCategories,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Category',
        code: 'category',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: saleConfig.lifeCategories,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true
      }, {
        name: 'Product name',
        code: 'productName',
        placeholder: '',
        type: 'select',
        required: false,
        changeFn: selectedProductNameChangeFn,
        initFn: selectedProductNameInitFn,
        selectConfig: {
          options: saleConfig.productNames,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Vehicle Type',
        code: 'vehicleType',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: vehicleTypeChangeFn,
        initFn: vehicleTypeInitFn,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Vehicle Subtype',
        code: 'vehicleSubType',
        placeholder: '',
        type: 'select',
        required: true,
        hide: true,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Carrier Type',
        code: 'carrierType',
        placeholder: '',
        type: 'select',
        required: true,
        selectConfig: {
          options: saleConfig.carrierTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Registration Number',
        code: 'registrationNo',
        // Validation - pattern
        placeholder: 'MH-03-ZZ-1234 or MH-03-ZZZ-1234',
        type: 'text',
        required: true,
        blurFn: _registrationNumberBlurFn
      }, {
        name: 'RTO Location',
        code: 'rtoLocation',
        type: 'autocomplete',
        hide: false,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: FormHelperService.selectedRTOChangeFn,
          querySearch: FormHelperService.searchRTOFn,
          displayKey: 'rtoPlateLntLoc',
          minLength: 1
        }
      }, {
        name: 'Product Type',
        code: 'productType',
        placeholder: 'Select a product type',
        type: 'select',
        selectConfig: {
          options: saleConfig.carrierTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Insurer',
        code: 'insurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        required: true,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }, {
        name: 'Cover type',
        code: 'coverType',
        placeholder: '',
        type: 'select',
        hide: true,
        selectConfig: {
          options: saleConfig.coverTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Plan type',
        code: 'planType',
        placeholder: '',
        type: 'select',
        hide: false,
        changeFn: _planTypeChangeFn,
        initFn: _planTypeChangeFn,
        required: true,
        selectConfig: {
          options: saleConfig.healthPlanTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Sub-plan type',
        code: 'subPlanType',
        hide: false,
        placeholder: 'Sub-plan type',
        type: 'select',
        selectConfig: {
          options: saleConfig.subPlanTypes,
          valueKey: 'code',
          textKey: 'text'
        }
      }, {
        name: 'Plan name',
        code: 'tmPlanId',
        placeholder: 'Plan Name',
        initFn: _planNameInitFn,
        hide: true,
        required: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: selectedPlanNameChangeFn,
          querySearch: searchPlanNames,
          displayKey: 'planName',
          valueKey: 'tmPlanId'
        }
      }, {
        name: 'Plan name (custom)',
        code: 'planName',
        placeholder: 'Plan name',
        type: 'text',
        readonly: false,
        hide: true
      }, {
        name: 'Option name',
        code: 'optionName',
        placeholder: 'Option name',
        type: 'text',
        hide: false
      },
      // {
      //   name: 'Policy PDF Present',
      //   code: 'policyPDFPresent',
      //   placeholder: '',
      //   type: 'checkbox'
      // },
      {
        name: 'Comments',
        code: 'comments',
        placeholder: '',
        type: 'text'
      }, {
        name: 'Creation Date',
        code: 'createdAt',
        type: 'date',
        disabled: true
      }, {
        name: 'Creation Source',
        code: 'creationSource',
        type: 'text',
        initFn: _creationSourceFn,
        disabled: true
      }, {
        name: 'Why did you choose to issue this policy at branch?',
        code: 'reason',
        type: 'select',
        initFn: _reasonInitFn,
        selectConfig: {
          options: saleConfig.captureReason,
          valueKey: 'code',
          textKey: 'name'
        },
        disabled: true,
        hide: true
      }, {
        name: 'Reason Options',
        code: 'reasonOptions',
        placeholder: '',
        type: 'text',
        disabled: true,
        hide: true
      }, {
        name: 'Cancellation Date',
        code: 'cancellationDate',
        placeholder: '',
        hide: true,
        type: 'date',
        dateConfig: {
          maxDate: moment().toDate()
        }
      }, {
        name: 'Cancellation Remarks',
        code: 'remarks',
        placeholder: '',
        hide: true,
        type: 'select',
        selectConfig: {
          options: saleConfig.cancellationRemarks,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Reason For Cancellation',
        code: 'reasonForCancellation',
        type: 'textarea',
        hide: true,
        required: true,
        textareaConfig: {
          rows: 2
        }
      }, {
        name: 'Junk Date',
        code: 'junkedDate',
        type: 'date',
        disabled: true,
        hide: true
      }, {
        name: 'Data QC',
        code: 'manualQCStatus',
        type: 'select',
        initFn: _dataQCInitFn,
        changeFn: _dataQCChangeFn,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Data QC Done Date',
        code: 'dataQCDoneDate',
        type: 'date',
        disabled: true
      }, {
        name: 'Last Modified Date',
        code: 'updatedAt',
        type: 'date',
        disabled: true,
        hide: sessionStorage.getItem('broker') !== CONSTANTS_LIST.BROKER.TURTLEMINT
      }, {
        name: 'Last Modified By',
        code: 'lastUpdatedByEmail',
        type: 'text',
        disabled: true,
        hide: sessionStorage.getItem('broker') !== CONSTANTS_LIST.BROKER.TURTLEMINT
      }];

      var saleFieldsConfig = [{
        // fill by default using cookies (displayName)
        name: 'MIS Owner',
        code: 'misOwner',
        placeholder: '',
        type: 'text',
        readonly: true,
        disabled: true
      }, {
        name: 'PI Branch Location',
        code: 'piBranchLocation',
        placeholder: '',
        type: 'text',
        hide: false,
        readonly: true,
        disabled: false
      }, {
        name: 'PI CreatedBy',
        code: 'piCreatedBy',
        placeholder: '',
        type: 'text',
        hide: false,
        readonly: true,
        disabled: false
      }, {
        name: 'Tenant',
        code: 'tenant',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: fetchUnitManagersFn,
        initFn: tenantInItFn,
        selectConfig: {
          options: saleConfig.turtlemintTenant,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        // PolicyIssunce date(pid) is present then scd <= pid
        name: 'Sale Closed Date',
        code: 'saleClosedDate',
        placeholder: '',
        required: true,
        type: 'date',
        initFn: _saleClosedDateChangeFn,
        changeFn: _saleClosedDateChangeFn
      }, {
        name: 'DP Name',
        code: 'intermediaryName',
        placeholder: 'Select an Intermediary',
        type: 'autocomplete',
        hide: true,
        initFn: selectedIntermediaryInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItem: saleConfig.selectedIntermediary,
          selectedItemChange: selectedIntermediaryChangeFn,
          querySearch: searchIntermediariesFn,
          displayKey: 'name',
          valueKey: 'name',
          resultTitleKey: 'title',
          resultLabelKey: 'title',
          minLength: 2
        }
      }, {
        name: 'DP Login ID',
        code: 'intermediaryLoginId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'DP No',
        code: 'intermediaryInternalId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'External Unique Id',
        code: 'externalUniqueId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'Business Channel',
        code: 'businessChannel',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'Sales executive',
        code: 'salesExecutive',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Unit manager',
        code: 'um',
        placeholder: '',
        type: 'autocomplete',
        hide: true,
        readonly: true,
        disabled: false,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _unitManagerChangeFn,
          querySearch: searchUnitManagersFn,
          displayKey: 'dpName',
          valueKey: 'dpName',
          minLength: 2
        }
      }, {
        name: 'Senior unit manager',
        code: 'sum',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Team leader',
        code: 'tl',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Mint planner',
        code: 'mp',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Development manager',
        code: 'dm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Sales manager',
        code: 'sm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Senior sales manager',
        code: 'ssm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Area manager',
        code: 'am',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Area head',
        code: 'ah',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Senior area head',
        code: 'sah',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Regional Manager',
        code: 'rm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Senior regional manager',
        code: 'srm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Zone Manager',
        code: 'zm',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'National Head',
        code: 'nationalHead',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'DP Level',
        code: 'intermediaryLevel',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Parent DP Name',
        code: 'parentIntermediaryName',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Parent DP Login ID',
        code: 'parentIntermediaryLoginId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Parent DP NO',
        code: 'parentIntermediaryInternalId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Parent DP Level',
        code: 'parentIntermediaryLevel',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Branch Location',
        code: 'dpBranchLocation',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'State',
        code: 'dpState',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'Region',
        code: 'dpRegion',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }, {
        name: 'TL Name',
        code: 'tlName',
        placeholder: 'Select an TL',
        type: 'autocomplete',
        required: false,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: FormHelperService.selectedTLChangeFn,
          querySearch: searchTLFn
        }
      }, {
        name: 'Facilitator Name',
        code: 'facilitatorName',
        placeholder: '',
        type: 'text'
      }, {
        name: 'Commission Channel',
        code: 'commissionChannel',
        type: 'select',
        selectConfig: {
          options: saleConfig.commissionChannelTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Request ID',
        code: 'requestId',
        placeholder: '',
        type: 'text',
        disabled: true
      }, {
        name: 'Family ID',
        code: 'familyId',
        placeholder: '',
        type: 'text'
      }];

      var policyFields = {};
      policyFields.customerDetails = [{
        name: 'Proposer Title',
        code: 'policyProposerTitle',
        type: 'select',
        required: sessionStorage.getItem('broker') === CONSTANTS_LIST.BROKER.TURTLEMINT,
        selectConfig: {
          options: saleConfig.proposerTitle,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Customer First Name',
        code: 'policyProposerFName',
        required: true,
        type: 'text'
      }, {
        name: 'Customer Last Name',
        code: 'policyProposerLName',
        required: true,
        type: 'text'
      }, {
        name: 'Customer Phone',
        code: 'policyProposerMobile',
        required: true,
        type: 'text'
      }, {
        name: 'Customer Phone No N/A',
        code: 'policyProposerMobileNotAvailable',
        type: 'checkbox',
        initFn: _customePhoneNoChangeFn,
        changeFn: _customePhoneNoChangeFn
      }, {
        name: 'Customer Alternate Mobile',
        code: 'policyProposerAlternateMobile',
        type: 'text'
      }, {
        name: 'Customer Email',
        code: 'policyProposerEmail',
        required: true,
        type: 'email'
      }, {
        name: 'Customer Email N/A',
        code: 'policyProposerEmailNotAvailable',
        type: 'checkbox',
        initFn: _customeEmailChangeFn,
        changeFn: _customeEmailChangeFn
      }, {
        name: 'Registration Address',
        code: 'registrationAddress',
        type: 'textarea',
        textareaConfig: {
          rows: 2
        }
      }, {
        name: 'Registration Pincode',
        code: 'registrationPincode',
        type: 'text',
        maxLength: 6,
        minLength: 6,
        changeFn: _checkRegistrationPincodeFn
      }, {
        name: 'Registration City',
        code: 'registrationCity',
        type: 'text'
      }, {
        name: 'Registration State',
        code: 'registrationState',
        type: 'text'
      }, {
        name: 'Current Address same as Registration Address',
        code: 'sameAsRegistrationAddress',
        type: 'checkbox',
        initFn: _sameAddressChange,
        changeFn: _sameAddressChange
      }, {
        name: 'Current Address',
        code: 'communicationAddress',
        type: 'textarea',
        textareaConfig: {
          rows: 2
        }
      }, {
        name: 'Current Pincode',
        code: 'communicationPincode',
        type: 'text',
        maxLength: 6,
        minLength: 6,
        changeFn: _checkCommunicationPincodeFn
      }, {
        name: 'Current City',
        code: 'communicationCity',
        type: 'text'
      }, {
        name: 'Current State',
        code: 'communicationState',
        type: 'text'
      }, {
        name: 'Date of Birth',
        code: 'policyProposerDob',
        type: 'date'
      }, {
        name: 'Gender',
        code: 'policyProposerGender',
        type: 'select',
        selectConfig: {
          options: [{
            name: 'Male',
            code: 'male'
          }, {
            name: 'Female',
            code: 'female'
          }, {
            name: 'Other',
            code: 'other'
          }],
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'GSTIN',
        code: 'gstin',
        type: 'text'
      }, {
        name: 'Reason for no PA cover',
        code: 'reasonForNoPACover',
        type: 'text',
        hide: true
      }, {
        name: 'Policy URL QC',
        code: 'policyUrlQC',
        type: 'text',
        hide: true
      }];

      var minDoc = [{
        name: 'Proposal Date',
        code: 'proposalDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Premium Received Date',
        code: 'premiumReceivedDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Premium Paid To Insurer Date',
        code: 'premiumPaidToInsurerDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Proposal Form Submitted To Insurer Date',
        code: 'proposalFormSubmittedToInsurerDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Renewal Premium due date',
        code: 'renewalPremiumDueDate',
        type: 'date',
        hide: true
      }, {
        name: 'Renewal Premium Received Date',
        code: 'renewalPremiumReceivedDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }, {
        name: 'Renewal Payment Mode',
        code: 'renewalPaymentMode',
        type: 'select',
        hide: true,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Renewal Premium Paid to Insurer Date',
        code: 'renewalPremiumPaidToInsurerDate',
        type: 'date',
        hide: true,
        dateConfig: {
          maxDate: moment().startOf('day').toDate()
        }
      }];

      function _typeOfSaleChangeFn(selectedItem) {
        if (selectedItem === 'BQP') {
          var fieldConfig = getConfigObjectFromCode('bqp');
          delete fieldConfig.autocompleteConfig.searchText;
          showHideField('bqp', false);
        } else {
          showHideField('bqp', true);
        }
      }

      function _typeOfSaleInitFn(selectedItem) {
        _typeOfSaleChangeFn(selectedItem);
      }

      function _policyNumberInitFn(selectedItem, sale) {
        _trimWhiteSpace(selectedItem, sale, 'policyNumber');
      }

      function _applicationNumberInitFn(selectedItem, sale) {
        _trimWhiteSpace(selectedItem, sale, 'applicationNumber');
      }

      function _searchBQPFn(searchKey, model) {
        var deferred = $q.defer();
        var bqpList = angular.copy(saleConfig.bqpList) || [];
        if (bqpList.length < 1) {
          $timeout(function () {
            _clearDataBQP(model);
          }, 1000);
        }
        if (searchKey) {
          var results = $filter('filter')(bqpList, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(bqpList);
        }
        return deferred.promise;
      }

      function _clearDataBQP(model) {
        if (model.bqp) {
          delete model.bqp;
          delete model.bqpId;
          delete model.bqpBranch;
          delete model.bqpInternalId;
          delete model.bqpName;
        }
      }

      function _selectedBQPInitFn(selectedItem, sale) {
        if (selectedItem) {
          sale.bqp = selectedItem;
        } else {
          _clearDataBQP(sale);
        }
      }

      function _selectedBQPChangeFn(selectedItem, sale) {
        if (selectedItem) {
          sale.bqp = selectedItem;
          sale.bqpId = selectedItem.clientId;
          sale.bqpBranch = selectedItem.branch;
          sale.bqpInternalId = selectedItem.internalId;
          sale.bqpName = selectedItem.name;
        } else {
          _clearDataBQP(sale);
        }
      }

      function _reasonInitFn(selectedItem) {
        if (selectedItem) {
          showHideField('reasonOptions', true, true);
          var selectedObj = saleConfig.captureReason.find(function (ele) {
            if (ele.code === selectedItem) {
              return ele;
            }
          });
          var reasonOptions = selectedObj.reasonOptions;
          var reasonOptionsConfig = getConfigObjectFromCode('reasonOptions');
          delete reasonOptionsConfig.type;
          delete reasonOptionsConfig.selectConfig;
          reasonOptionsConfig.type = reasonOptions.type;
          reasonOptionsConfig.name = selectedObj.reasonOptions.type !== '' ? selectedObj.name : '';
          if (reasonOptions.type === 'select') {
            reasonOptionsConfig.selectConfig = {
              textKey: 'name',
              valueKey: 'code',
              options: reasonOptions.options
            };
          } else if (reasonOptionsConfig.type === 'number') {
            reasonOptionsConfig.type = 'text';
            reasonOptionsConfig.placeholder = 'Discount in %';
          } else if (reasonOptionsConfig.type === 'textarea') {
            reasonOptionsConfig.placeholder = 'Enter reason min 15 characters';
          }
          showHideField('reasonOptions', false);
        } else {
          showHideField('reasonOptions', true);
        }
      }

      function _checkRegistrationPincodeFn(selectedItem, model) {
        if (selectedItem) {
          commonService.setCityState(selectedItem, 'registrationCity', 'registrationState', model);
        }
      }

      function _checkCommunicationPincodeFn(selectedItem, model) {
        if (selectedItem) {
          commonService.setCityState(selectedItem, 'communicationCity', 'communicationState', model);
        }
      }

      function _creationSourceFn(selectedItem) {
        if (selectedItem) {
          if (selectedItem === 'pi-branch') {
            showHideField('reason', false);
          } else {
            showHideField('reason', true);
          }
        }
      }

      function _dataQCInitFn(selectedItem) {
        if (selectedItem && selectedItem === 'DONE') {
          _markFieldDisabled('manualQCStatus', true);
          showHideBrokerSpecificField('dataQCDoneDate');
        } else {
          _markFieldDisabled('manualQCStatus', false);
          showHideField('dataQCDoneDate', true);
        }
      }

      function _dataQCChangeFn(selectedItem, sale) {
        if (selectedItem && selectedItem === 'DONE') {
          _dataQCConfirmationModel(selectedItem, sale);
        } else {
          sale.dataQCDoneDate = undefined;
          showHideField('dataQCDoneDate', true);
        }
      }

      // Data QC Modal
      function _dataQCConfirmationModel(selectedItem, sale) {
        $mdDialog.show({
          templateUrl: 'dev/mis/templates/data-qc-done-confirmation.dialog.tpl.html',
          clickOutsideToClose: false,
          locals: {
            sale: sale
          },
          controller: dataQCDoneConfirmModelController
        });
        dataQCDoneConfirmModelController.$inject = ['$scope', '$mdDialog', 'sale'];

        function dataQCDoneConfirmModelController($scope, $mdDialog, sale) {
          $scope.sale = sale;

          $scope.save = function () {
            $mdDialog.hide();
            setDateQCDoneDate(sale);
            showHideBrokerSpecificField('dataQCDoneDate');
          };

          $scope.cancel = function () {
            $scope.sale.manualQCStatus = 'NOT_DONE';
            $mdDialog.cancel();
            sale.dataQCDoneDate = undefined;
            showHideField('dataQCDoneDate', true);
          };
        }
      }
      policyFields.nomineeDetails = [{
        name: 'Nominee First Name',
        code: 'nomineeFName',
        type: 'text'
      }, {
        name: 'Nominee Last Name',
        code: 'nomineeLName',
        type: 'text'
      }, {
        name: 'Nominee DOB',
        code: 'nomineeDob',
        type: 'date'
      }, {
        name: 'Nominee Relationship',
        code: 'nomineeRelationship',
        type: 'text'
      }, {
        name: 'Nominee Contact',
        code: 'nomineeContact',
        type: 'text'
      }];

      policyFields.policyDetails = [{
        name: 'Multi-year',
        code: 'multiyear',
        placeholder: '',
        type: 'checkbox',
        hide: true,
        initFn: _multiYearPolicyChangeFn,
        changeFn: _multiYearPolicyChangeFn
      }, {
        name: 'Inception Date',
        code: 'inceptionDate',
        type: 'date',
        initFn: _inceptionDateChangeFn,
        changeFn: _inceptionDateChangeFn
      }, {
        name: 'Risk Start Date',
        code: 'startDate',
        type: 'date',
        initFn: _startDateInitFn,
        changeFn: _startDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        // Validation - end > start
        name: 'Risk end date',
        code: 'endDate',
        type: 'date',
        initFn: _endDateInitFn,
        changeFn: _endDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'OD Risk Start Date',
        code: 'odRiskStartDate',
        type: 'date',
        hide: true,
        initFn: _odRiskStartDateChangeFn,
        changeFn: _odRiskStartDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'OD Risk End Date',
        code: 'odRiskEndDate',
        type: 'date',
        hide: true,
        initFn: _odRiskEndDateChangeFn,
        changeFn: _odRiskEndDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'TP Risk Start Date',
        code: 'tpRiskStartDate',
        type: 'date',
        hide: true,
        initFn: _tpRiskStartDateChangeFn,
        changeFn: _tpRiskStartDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'TP Risk End Date',
        code: 'tpRiskEndDate',
        type: 'date',
        hide: true,
        initFn: _tpRiskEndDateChangeFn,
        changeFn: _tpRiskEndDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'Policy Term (in years)',
        code: 'policyTerm',
        type: 'number',
        hide: true,
        required: true,
        blurFn: _policyTermBlurFn
      }, {
        name: 'Payment Frequency',
        code: 'paymentFrequency',
        placeholder: '',
        type: 'select',
        hide: true,
        required: true,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        },
        initFn: _paymentFrequencyChangeFn,
        changeFn: _paymentFrequencyChangeFn
      }, {
        name: 'Premium Payment Term (in years)',
        code: 'policyPremiumTerm',
        type: 'number',
        min: 0,
        hide: true,
        required: true
      }, {
        name: 'Payment Type',
        code: 'policyPaymentType',
        type: 'select',
        selectConfig: {
          options: saleConfig.paymentTypes,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true
      }, {
        name: 'Benefit Payout Mode',
        code: 'benefitPayoutMode',
        placeholder: '',
        type: 'select',
        hide: true,
        selectConfig: {
          options: saleConfig.benefitPayoutTypes,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true
      }, {
        name: 'Benefit Payout Term',
        code: 'benefitPayoutTerm',
        type: 'text',
        required: true
      }, {
        name: 'NCB',
        code: 'ncb',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Smoker',
        code: 'smoker',
        // type: 'checkbox'
        type: 'radio',
        radioConfig: {
          options: [{
            value: true,
            text: 'Yes'
          }, {
            value: false,
            text: 'No'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Proposal No',
        code: 'proposalNo',
        type: 'text'
      }, {
        name: 'Policy Issuing Branch',
        code: 'policyIssueingBranch',
        type: 'text'
      }, {
        name: 'Make',
        code: 'make',
        type: 'select',
        hide: true,
        selectConfig: {
          options: saleConfig.ewPlanTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Serial Number',
        code: 'serialNumber',
        type: 'text',
        hide: true
      }, {
        name: 'Merchant ID',
        code: 'merchantId',
        type: 'text',
        hide: true
      }, {
        name: 'Store ID',
        code: 'storeId',
        type: 'text',
        hide: true
      }, {
        name: 'Manufacturer Warranty',
        code: 'manufacturerWarrantyTerm',
        hide: true,
        type: 'select',
        selectConfig: {
          options: saleConfig.manufacturerWarrantyTermList
        }
      }];

      policyFields.premiumDetails = [{
        name: 'CPA',
        code: 'cpa',
        type: 'number',
        initFn: _cpaInitFn,
        changeFn: _cpaInitFn,
        min: 0
      }, {
        name: 'IDV',
        code: 'idv',
        type: 'number',
        min: 0
      }, {
        name: 'Basic OD Premium',
        code: 'odPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Total OD Premium',
        code: 'netOdPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Basic TP Premium',
        code: 'basicTpPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Total TP Premium',
        code: 'tpPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Net Premium',
        code: 'netPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Annualized Net Premium',
        code: 'annualisedNetPremium',
        type: 'number',
        min: 0,
        required: true
      }, {
        name: 'Service Tax / GST',
        code: 'serviceTax',
        type: 'number',
        min: 0
      }, {
        name: 'Gross Premium',
        code: 'grossPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Swachh Bharat Tax',
        code: 'swachhBharatTax',
        type: 'number',
        min: 0
      }, {
        name: 'Krishi Kalyan Tax',
        code: 'krishiKalyanTax',
        type: 'number',
        min: 0
      }, {
        name: 'Discount Percentage',
        code: 'discountPercentage',
        type: 'number',
        min: 0,
        max: 100
      }];

      policyFields.previousPolicyDetails = [{
        name: 'Previous Policy Number',
        code: 'prevPolicyNumber',
        type: 'text'
      }, {
        name: 'Previous Insurer',
        code: 'prevInsurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: FormHelperService.selectedPreviousInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }, {
        name: 'Previous NCB',
        code: 'prevNCB',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Previous Claim',
        code: 'prevClaim',
        type: 'checkbox'
      }];

      policyFields.vehicleDetails = [{
        name: 'Is Vehicle financed',
        code: 'isVehicleFinanced',
        type: 'checkbox',
        initFn: _isVehicleFinancedInitFn,
        changeFn: _isVehicleFinancedChangeFn
      }, {
        name: 'Financing Institute',
        code: 'financingInstitute',
        type: 'text',
        hide: true
      }, {
        name: 'RTO Code',
        code: 'rto',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'Make Model',
        code: 'makemodel',
        type: 'autocomplete',
        hide: true,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: selectedMakemodelChangeFn,
          querySearch: searchMakeModel,
          displayKey: 'makemodel',
          minLength: 1
        }
      }, {
        name: 'Variant',
        code: 'variant',
        type: 'select',
        hide: true,
        changeFn: onSelectVariantFn,
        selectConfig: {
          options: saleConfig.variants,
          valueKey: 'displayVariant',
          textKey: 'displayVariant'
        }
      }, {
        name: 'Cubic Capacity',
        code: 'cc',
        type: 'number'
      }, {
        name: 'Fuel Type',
        code: 'fuel',
        type: 'text'
      }, {
        name: 'Vehicle Name',
        code: 'vehicleName',
        type: 'text'
      }, {
        name: 'Vehicle Registration Date',
        code: 'vehicleRegDate',
        type: 'date',
        hide: true
      }, {
        name: 'Manufacture Year',
        code: 'manufactureYear',
        min: 0,
        max: 3000,
        type: 'number'
      }, {
        name: 'Engine No',
        code: 'engineNo',
        type: 'text'
      }, {
        name: 'Chassis No',
        code: 'chassisNo',
        type: 'text'
      }, {
        name: 'GVW / Tonnage in kgs',
        code: 'gvw',
        type: 'number',
        min: 1
      }, {
        name: 'Seating Capacity',
        code: 'seatingCapacity',
        type: 'number',
        min: 1
      }, {
        name: 'Engine Protect',
        code: 'engProtect',
        type: 'checkbox'
      }, {
        name: 'NCB Protect',
        code: 'ncbProtect',
        type: 'checkbox'
      }, {
        name: 'Deductible',
        code: 'deductible',
        type: 'number'
      }, {
        name: 'RSA',
        code: 'rsa',
        type: 'checkbox'
      }, {
        name: 'Zero Depreciation',
        code: 'zeroDep',
        type: 'checkbox'
      }, {
        name: 'Bi Fuel Kit Value',
        code: 'biFuelKitValue',
        type: 'number',
        min: 0
      }, {
        name: 'Anti Theft',
        code: 'antiTheft',
        type: 'text'
      }, {
        name: 'LL Cleaner',
        code: 'llCleaner',
        type: 'checkbox'
      }, {
        name: 'LL Driver',
        code: 'llDriver',
        type: 'checkbox'
      }, {
        name: 'PA Unnamed',
        code: 'paUnnamed',
        type: 'checkbox'
      }, {
        name: 'PA Unnamed SI',
        code: 'paUnnamedsi',
        type: 'text'
      }, {
        name: 'Non Electrical Value',
        code: 'nonElecValue',
        type: 'number'
      }, {
        name: 'Electrical Value',
        code: 'electricalValue',
        type: 'number'
      }, {
        name: 'Sum Insured',
        code: 'sumInsured',
        type: 'number',
        min: 0,
        hide: true
      }];

      policyFields.odPolicyFields = [{
        name: 'Previous OD Policy Number',
        code: 'previousODPolicyNumber',
        type: 'text',
        hide: true
      }, {
        name: 'Previous OD Risk Start Date',
        code: 'previousODRiskStartDate',
        type: 'date',
        hide: true
      }, {
        name: 'Previous OD Risk End Date',
        code: 'previousODRiskEndDate',
        type: 'date',
        hide: true
      }, {
        name: 'Previous OD Insurer',
        code: 'previousODInsurer',
        hide: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedPreviousODInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }, {
        name: 'Active TP Policy Number',
        code: 'activeTPPolicyNumber',
        type: 'text',
        hide: true
      }, {
        name: 'Active TP Risk Start Date',
        code: 'activeTPRiskStartDate',
        type: 'date',
        hide: true
      }, {
        name: 'Active TP Risk End Date',
        code: 'activeTPRiskEndDate',
        type: 'date',
        hide: true,
        initFn: _activeTpRiskEndDateChangeFn,
        changeFn: _activeTpRiskEndDateChangeFn
      }, {
        name: 'Active TP Insurer',
        code: 'activeTPInsurer',
        hide: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedActiveTPInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }];

      var paymentFields = [{
        name: 'Payment Status',
        code: 'paymentStatus',
        type: 'select',
        selectConfig: {
          options: saleConfig.paymentStatuses
        }
      }, {
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'select',
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Payment Amount',
        code: 'paymentAmount',
        type: 'number',
        min: 0
      }, {
        name: 'Bank Name',
        code: 'bankName',
        type: 'text'
      }, {
        name: 'A/c No.',
        code: 'paymentAccountNo',
        type: 'text'
      }, {
        name: 'Cheque Date',
        code: 'chequeDate',
        type: 'text'
      }, {
        name: 'Cheque No',
        code: 'chequeNo',
        type: 'text'
      }, {
        name: 'Device Payment Mode',
        code: 'productPaymentMode',
        type: 'select',
        selectConfig: {
          options: saleConfig.productPaymentModeList,
          textKey: 'text',
          valueKey: 'value'
        }
      }, {
        name: 'Details Device Payment Mode',
        code: 'productPaymentDetails',
        type: 'text'
      }, {
        name: 'Cheque Recieved by Insurer',
        code: 'chequeReceivedByInsurer',
        type: 'checkbox'
      }, {
        name: 'Cheque Received On',
        code: 'chequeReceivedDate',
        type: 'date',
        initFn: _chequeReceivedDateChangeFn,
        changeFn: _chequeReceivedDateChangeFn,
        dateConfig: {
          minDate: new Date()
        }
      }, {
        name: 'Transaction ID',
        code: 'paymentTransactionId',
        type: 'text'
      }, {
        name: 'Bank UTR',
        code: 'externalPaymentTransactionId',
        type: 'text',
        hide: sessionStorage.getItem('broker') !== CONSTANTS_LIST.BROKER.IPPB
      }, {
        name: 'Deviation on Net (%)',
        code: 'deviationNet',
        type: 'number',
        min: 0,
        max: 100,
        initFn: _deviationNetChangeFn,
        changeFn: _deviationNetChangeFn,
        blurFn: _deviationNetBlurFn
      }, {
        name: 'Deviation on OD (%)',
        code: 'deviationOd',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Deviation on TP (%)',
        code: 'deviationTp',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Deviation Flat',
        code: 'deviationFlat',
        type: 'number'
      }, {
        name: 'Deviation Remarks',
        code: 'deviationRemarks',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Deviation Approved By',
        code: 'deviationApprovedBy',
        type: 'text'
      }];

      var marketingFields = [{
        name: 'Source',
        code: 'utmSource',
        type: 'text'
      }, {
        name: 'Medium',
        code: 'utmMedium',
        type: 'text'
      }, {
        name: 'Campaign',
        code: 'utmCampaign',
        type: 'text'
      }, {
        name: 'Term',
        code: 'utmTerm',
        type: 'text'
      }, {
        name: 'Content',
        code: 'utmContent',
        type: 'text'
      }];

      var preSaleVerification = [{
        name: 'Call Status',
        code: 'preSaleVerificationStatus',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: saleConfig.preSaleVerificationStatus
        }
      }, {
        name: 'Remarks',
        code: 'preSaleVerificationRemarks',
        placeholder: '',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }];

      var otherFields = [{
        name: 'RSA Status',
        code: 'rsaStatus',
        type: 'text'
      }, {
        name: 'Facilitator Payable %',
        code: 'facilitatorPayblePercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Highest Insured DOB',
        code: 'highestInsuredDOB',
        type: 'text'
      }, {
        name: 'P.P. Request Type',
        code: 'ppRequestType',
        type: 'select',
        selectConfig: {
          options: saleConfig.ppRequestTypes
        }
      }, {
        // Change in BE and DB to date
        name: 'P.P. Open Date',
        code: 'ppOpendate',
        type: 'date'
      }, {
        // Change in BE and DB to date
        name: 'P.P. Close Date',
        code: 'ppCloseDate',
        type: 'date'
      }, {
        name: 'P.P. Status',
        code: 'ppStatus',
        type: 'select',
        selectConfig: {
          options: saleConfig.ppStatuses
        }
      }, {
        name: 'P.P. Remarks',
        code: 'ppRemarks',
        type: 'text'
      }, {
        name: 'Documents Receipt Date',
        code: 'documentReceiptDate',
        type: 'date'
      }, {
        name: 'Ops Exec.',
        code: 'opsExecutive',
        // Required or nor??
        type: 'text'
      }, {
        name: 'Case Remarks / Discrepancy',
        code: 'caseRemarksDiscrepancy',
        type: 'text'
      }, {
        name: 'RM Welcome Call',
        code: 'rmWelcomeCall',
        type: 'text'
      }, {
        name: 'Branch Location',
        code: 'branchLocation',
        type: 'text'
      }, {
        name: 'Refund Status',
        code: 'refundStatus',
        // Can be converted to select (ask MIS team)
        type: 'text'
      }, {
        name: 'Refund Date',
        code: 'refundDate',
        type: 'date'
      }, {
        name: 'QC',
        code: 'qc',
        type: 'text'
      }, {
        name: 'Policy sent to customer date',
        code: 'policySentToCustomerDate',
        type: 'date'
      }, {
        name: 'Policy Issuance TAT',
        code: 'policyIssuanceTAT',
        type: 'text'
      }, {
        name: 'Courier Sent (HC)',
        code: 'courierSent',
        type: 'text'
      }, {
        name: 'Courier Company',
        code: 'courierCompany',
        type: 'text'
      }, {
        name: 'POD No. of Courier',
        code: 'podNoOfCourier',
        type: 'text'
      }, {
        name: 'RSA Policy Sent',
        code: 'rsaPolicySent',
        type: 'text'
      }, {
        name: 'Reconciliation Status',
        code: 'reconcilationStatus',
        type: 'text'
      }, {
        name: 'Saiba Entry',
        code: 'saibaEntry',
        type: 'checkbox'
      }];

      var commissionFields = [{
        name: 'IRDA Commission %',
        code: 'irdaCommPercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Fintech Revenue %',
        code: 'fintechRevenuePercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Special Comm 1 %',
        code: 'specialCommOnePercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Special Comm 2 %',
        code: 'specialCommTwoPercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Facilitator Payable %',
        code: 'facilitatorPayblePercent',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'DP Payout %',
        code: 'payoutPercent',
        type: 'number',
        min: 0,
        max: 100
      }];

      // NOTE: THESE FIELDS ARE ONLY ADDED FOR MIS-CHECKER ROLE. THEY ARE NOT USED FOR ANY FUNCTIONALITY.
      policyFields.checkerFields = [{
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'select',
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Transaction ID',
        code: 'paymentTransactionId',
        type: 'text'
      }, {
        name: 'Bank UTR',
        code: 'externalPaymentTransactionId',
        type: 'text',
        hide: sessionStorage.getItem('broker') !== CONSTANTS_LIST.BROKER.IPPB
      }, {
        name: 'Payment Amount',
        code: 'paymentAmount',
        type: 'number',
        min: 0
      }];

      policyFields.checkerSOS = [{
        name: 'Type of Sale',
        code: 'typeOfSale',
        type: 'text',
        disabled: true,
        initFn: _typeOfSourceOfSaleInitFn
      }, {
        name: 'BQP',
        code: 'bqpName',
        type: 'text',
        hide: true
      }, {
        name: 'DP No',
        code: '$$dpNo',
        type: 'text',
        hide: true
      }, {
        name: 'DP Name',
        code: '$$dpName',
        type: 'text',
        hide: true
      }];

      function _typeOfSourceOfSaleInitFn(selectedItem) {
        if (selectedItem === 'BQP') {
          showHideField('bqpName', false);
          showHideField('$$dpNo', true, true);
          showHideField('$$dpName', true, true);
        } else if (selectedItem === 'POSP') {
          showHideField('bqpName', true, true);
          showHideField('$$dpNo', false);
          showHideField('$$dpName', false);
        } else {
          showHideField('bqpName', true, true);
          showHideField('$$dpNo', true, true);
          showHideField('$$dpName', true, true);
        }
      }

      return {
        //Vars
        currentSale: currentSale,
        sourceOfSaleFields: sourceOfSaleFields,
        basicDetailsConfig: basicDetailsConfig,
        saleFieldsConfig: saleFieldsConfig,
        policyFields: policyFields,
        paymentFields: paymentFields,
        marketingFields: marketingFields,
        preSaleVerification: preSaleVerification,
        otherFields: otherFields,
        commissionFields: commissionFields,
        saleConfig: saleConfig,
        memberConfig: memberConfig,
        minDoc: minDoc,
        //Functions
        getList: getList,
        addUpdate: addUpdate,
        getPolicyById: getPolicyById,
        acquirePolicyLock: acquirePolicyLock,
        releasePolicyLock: releasePolicyLock,
        updatePolicyUrl: updatePolicyUrl,
        handleUploadedDocuments: handleUploadedDocuments,
        getConfigObjectFromCode: getConfigObjectFromCode,
        clearCurrentSale: clearCurrentSale,
        getCurrentSale: getCurrentSale,
        bulkExport: bulkExport,
        trackExport: trackExport,
        addressCopyFn: addressCopyFn,
        isPolicyActive: isPolicyActive,
        getBQPList: getBQPList,
        saveBQPDetails: CompliantService.saveBQPDetails,
        clearAutoconfig: clearAutoconfig,
        openDuplicatePolicyModal: openDuplicatePolicyModal,
        initPotentialDuplicate: initPotentialDuplicate,
        initCPA: initCPA,
        getHealthInsurer: getHealthInsurer
      };

      function searchMakeModel(searchKey, model) {
        var promise = $q.defer();
        FormHelperService.searchMakemodelFn(searchKey, model.vehicleType, model.createdAt).then(function (res) {
          // if (!res || (res && res.length < 1)) {
          //   $timeout(() => {
          //     clearVehicleFields(model);
          //   }, 2000);
          // }
          promise.resolve(res);
        }).catch(function (err) {
          clearVehicleFields(model);
          promise.reject(err);
        });
        return promise.promise;
      }

      function _saleClosedDateChangeFn(selectedItem, model) {
        fetchUnitManagersFn();
        _checkDateValidation(model);
      }

      function _odRiskStartDateChangeFn(selectedItem, model) {
        _checkDateValidation(model);
        _checkPolicyDuplicateCheck(model);
        _checkPolicyDuplicateOnRegistrationNumber(model);
        if (model.productName === 'od') {
          _activeTpRiskEndDateChangeFn(model.activeTPRiskEndDate, model);
        }
      }

      function _odRiskEndDateChangeFn(selectedItem, model) {
        _checkDateValidation(model);
        _checkPolicyDuplicateCheck(model);
        _checkPolicyDuplicateOnRegistrationNumber(model);
      }

      function _tpRiskStartDateChangeFn(selectedItem, model) {
        _checkDateValidation(model);
        _checkPolicyDuplicateCheck(model);
        _checkPolicyDuplicateOnRegistrationNumber(model);
      }

      function _tpRiskEndDateChangeFn(selectedItem, model) {
        _checkDateValidation(model);
        _checkPolicyDuplicateCheck(model);
        _checkPolicyDuplicateOnRegistrationNumber(model);
      }

      function _chequeReceivedDateChangeFn(selectedItem, model) {
        _checkDateValidation(model);
      }

      //TODO - We will be use this code in future for validation
      // function _previousOdRiskStartDateChangeFn(selectedItem, model){
      //   _checkDateValidation(model);
      // }
      // function _previousOdRiskEndDateChangeFn(selectedItem, model) {
      //   _checkDateValidation(model);
      // }

      // function _activeTpRiskStartDateChangeFn(selectedItem, model) {
      //   _checkDateValidation(model);
      // }

      // function _activeTpRiskEndDateChangeFn(selectedItem, model) {
      //   _checkDateValidation(model);
      // }
      //TODO END!

      function _activeTpRiskEndDateChangeFn(selectedItem, model) {
        if (model.odRiskStartDate && model.activeTPRiskEndDate && moment(model.odRiskStartDate).isAfter(model.activeTPRiskEndDate, 'day')) {
          model.activeTPRiskEndDate = undefined;
          commonService.showToast('Active TP Risk End Date cannot be before OD Risk Start Date');
        }
      }

      function _checkDateValidation(model) {
        var startDateConfig = getConfigObjectFromCode('startDate');
        var endDateConfig = getConfigObjectFromCode('endDate');
        var odRiskStartDateConfig = getConfigObjectFromCode('odRiskStartDate');
        var odRiskEndDateConfig = getConfigObjectFromCode('odRiskEndDate');
        var tpRiskStartDateConfig = getConfigObjectFromCode('tpRiskStartDate');
        var tpRiskEndDateConfig = getConfigObjectFromCode('tpRiskEndDate');
        var chequeReceivedDateConfig = getConfigObjectFromCode('chequeReceivedDate');

        startDateConfig.dateConfig.minDate = model.saleClosedDate && model.productCategory !== 'LIFE' ? new Date(model.saleClosedDate) : startDateConfig.dateConfig.minDate;
        if (model.endDate) {
          startDateConfig.dateConfig.maxDate = new Date(model.endDate);
        }

        endDateConfig.dateConfig.minDate = model.startDate ? new Date(model.startDate) : endDateConfig.dateConfig.minDate;

        odRiskStartDateConfig.dateConfig.minDate = model.saleClosedDate ? model.saleClosedDate : odRiskStartDateConfig.dateConfig.minDate;
        if (model.odRiskEndDate) {
          odRiskStartDateConfig.dateConfig.maxDate = model.odRiskEndDate;
        }
        odRiskEndDateConfig.dateConfig.minDate = model.odRiskStartDate ? model.odRiskStartDate : odRiskEndDateConfig.dateConfig.minDate;

        tpRiskStartDateConfig.dateConfig.minDate = model.saleClosedDate ? model.saleClosedDate : tpRiskStartDateConfig.dateConfig.minDate;
        if (model.tpRiskEndDate) {
          tpRiskStartDateConfig.dateConfig.maxDate = model.tpRiskEndDate;
        }
        tpRiskEndDateConfig.dateConfig.minDate = model.tpRiskStartDate ? model.tpRiskStartDate : tpRiskEndDateConfig.dateConfig.minDate;

        chequeReceivedDateConfig.dateConfig.minDate = model.saleClosedDate ? new Date(model.saleClosedDate) : chequeReceivedDateConfig.dateConfig.minDate;

        var startDate = model.startDate;
        if (startDate && moment(startDate).isBefore(startDateConfig.dateConfig.minDate, 'day') && model.productCategory !== 'LIFE') {
          model.startDate = undefined;
        }

        var odRiskStartDate = model.odRiskStartDate;
        if (odRiskStartDate && moment(odRiskStartDate).isBefore(odRiskStartDateConfig.dateConfig.minDate, 'day')) {
          model.odRiskStartDate = undefined;
        }

        var tpRiskStartDate = model.tpRiskStartDate;
        if (tpRiskStartDate && moment(tpRiskStartDate).isBefore(tpRiskStartDateConfig.dateConfig.minDate, 'day')) {
          model.tpRiskStartDate = undefined;
        }
      }

      function _deviationNetChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.deviationOd = '';
          model.deviationTp = '';
          _markFieldDisabled('deviationOd', true);
          _markFieldDisabled('deviationTp', true);
        } else {
          _markFieldDisabled('deviationOd', false);
          _markFieldDisabled('deviationTp', false);
        }
      }

      function _deviationNetBlurFn(selectedItem, model) {
        if (selectedItem) {
          if (['TW', 'CV', 'FW'].includes(model.vertical)) {
            commonService.showToast('Deviation can be entered either on Net or on OD/TP');
          }
        }
      }

      // //////////////////////////////////////////////////////
      /**
       * Function called on Tanants
       *
       * @public
       *
       * @function
       *
       * @param    {!Object} selectedItem Selected Item
       *
       * @author SumantaParida
       *
       */
      function getTenants(selectedItem) {
        var returnPromise = $q.defer();
        var reqUrl = '/getmistenants';
        var reqObj = {
          broker: sessionStorage.getItem('broker'),
          channelType: selectedItem
        };
        commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, true).then(function (res) {
          // saleConfig.turtlemintTenant = res.data;
          saleConfig.turtlemintTenant.splice(0, saleConfig.turtlemintTenant.length);
          for (var idx = res.data.length - 1; idx >= 0; idx--) {
            saleConfig.turtlemintTenant.push(res.data[idx]);
          }
          if (saleConfig.turtlemintTenant.length <= 0) {
            showHideField('tenant', true);
          } else {
            showHideField('tenant', false);
          }
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }

      function getProductUINMasters() {
        var returnPromise = $q.defer();
        var reqUrl = '/getplannames';
        var reqObj = {
          insurer: currentSale.insurer,
          productCategory: currentSale.productCategory
        };
        if (currentSale.productCategory === 'LIFE') {
          reqObj.plantype = currentSale.planType;
        }
        commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, true).then(function (res) {
          returnPromise.resolve(res.data);
          // saleConfig.turtlemintTenant = res.data;
          saleConfig.planNames.splice(0, saleConfig.planNames.length);
          for (var idx = res.data.length - 1; idx >= 0; idx--) {
            saleConfig.planNames.push(res.data[idx]);
          }
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }

      function _paymentFrequencyChangeFn(selectedItem, model) {
        if (selectedItem) {
          if (selectedItem === 'SINGLE') {
            model.policyPremiumTerm = 1;
            _markFieldDisabled('policyPremiumTerm', true);
          } else {
            _markFieldDisabled('policyPremiumTerm', false);
          }
        }
      }

      /**
       * Function called on selection of Variant
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function onSelectVariantFn(selectedItem, sale) {
        if (selectedItem) {
          if (saleConfig.variants) {
            for (var idx = saleConfig.variants.length - 1; idx >= 0; idx--) {
              var variant = saleConfig.variants[idx];
              if (variant.displayVariant === selectedItem) {
                sale.fuel = variant.fuel;
                sale.cc = typeof variant.cc === 'string' ? parseInt(variant.cc) : variant.cc;
                sale.vehicleName = variant.make + ' ' + variant.model + ' ' + variant.displayVariant;
                sale.make = variant.make;
                sale.model = variant.model;
                sale.vehicleId = variant._id;
                break;
              }
            }
          }
        } else {
          delete sale.variant;
        }
      }
      /**
       * Get Staff List
       *
       * @public
       *
       * @memberof module:turtlemintAdmin.SaleService#
       *
       * @author   snehilmodani
       *
       * @return   {Object}   Promise, resolving to staff array
       */
      function getList() {

        var returnPromise = $q.defer();

        var promise = commonService.makeHttpCall();

        promise.then(function (res) {
          var staff = res.data.results;

          staff.forEach(function (employee) {
            if (employee.joiningDate) {
              employee.joiningDate = new Date(employee.joiningDate);
            }
          });

          returnPromise.resolve(res.data.results);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      /**
       * Get track export
       *
       * @public
       *
       * @memberof module:titan.Main.Staff.StaffService#
       *
       * @author   snehilmodani
       *
       * @param      {Object}   params      params
       * @return     {Object}   promise,   promise
       */
      function trackExport(params) {
        var loginUser = AuthService.getUser();
        var reqObj = {
          _id: params.policyDetailsId,
          broker: saleConfig.broker,
          misOwnerId: $cookies.get('userId'),
          misownerEmail: loginUser.email
        };
        misService.exportMISData('SINGLE_EXPORT', reqObj);
      }

      /**
       * Get bulk export
       *
       * @public
       *
       * @memberof module:titan.Main.Staff.StaffService#
       *
       * @author   snehilmodani
       *
       * @param      {Object}   params      params
       * @return     {Object}   promise,   promise
       */
      function bulkExport(params) {
        var returnPromise = $q.defer();

        var reqObj = {
          'dateField': params.filterBy,
          'startDateTimestamp': moment(params.startDate).format('x'),
          'endDateTimestamp': moment(params.endDate).format('x')
        };

        var reqUrl = '/getpolicydetailsbetweendates';

        commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true).then(function (res) {
          if (res && res.status === 200) {
            returnPromise.resolve(res);
          } else {
            returnPromise.reject(res.data);
          }
        }).catch(function (err) {
          var returnObj = err;
          if (err.data) {
            returnObj = err.data;
          }

          returnPromise.reject(returnObj);
        });

        return returnPromise.promise;
      }

      /**
       * Add/Update Employee
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.SaleService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  saleObj  Sale Object
       *
       * @return     {Object}  Promise, which resolves to boolean
       */
      function addUpdate(saleObj) {
        var sale = angular.copy(saleObj);

        var returnPromise = $q.defer();
        var reqUrl = '/createUpdatePolicyDetails';
        delete sale.attachedDocuments;
        delete sale.qcErrors;

        sale.creationSource = sale.creationSource ? sale.creationSource : 'opsmanual';
        sale.manualQCStatus = sale.manualQCStatus ? sale.manualQCStatus : 'NOT_DONE';
        sale.caller = 'MIS_EDIT_FE';

        commonService.makeHttpCall(reqUrl, sale, 'POST', false, false, false).then(function (res) {
          if (res.data && res.data.statusCode && res.data.statusCode === 204) {
            returnPromise.reject(res.data);
          } else {
            returnPromise.resolve(res.data);
          }
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      /**
       * Function for mark qc error
       *
       * @private
       *
       * @function
       *
       * @param    {!String}           field       field
       *
       * @author snehilmodani
       *
       */
      function _markAsQCError(field) {
        var configObj = getConfigObjectFromCode(field);
        configObj.warning = true;
      }

      /**
       * Function for set min sale close date
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           date       field
       *
       * @author snehilmodani
       *
       */
      function _setMinSaleCloseDate(date) {
        var today = moment();
        if (date) {
          today = moment(date);
        }
        var config = getConfigObjectFromCode('saleClosedDate');
        config.dateConfig = {
          // maxDate: moment(today).startOf('day').toDate(),
          // minDate: moment(today).startOf('day').subtract(3, 'months').toDate()
        };
      }

      /**
       * Function for set max insurance date
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           date       field
       *
       * @author snehilmodani
       *
       */
      function _setMaxIssuanceDate(date) {
        _setMaxDate('issuanceDate', date);
      }

      /**
       * Function for set covernote insurance date
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           date       field
       *
       * @author snehilmodani
       *
       */
      function _setCovernoteIssuanceDate(date) {
        _setMaxDate('covernoteIssuanceDate', date);
      }

      /**
       * Function for set max date
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           fieldCode       fieldCode
       * @param    {!Object}           date            field
       *
       * @author snehilmodani
       *
       */
      function _setMaxDate(fieldCode, date) {
        var today = moment();
        if (date) {
          today = moment(date);
        }
        var config = getConfigObjectFromCode(fieldCode);

        if (!config.dateConfig) {
          config.dateConfig = {};
        }
        config.dateConfig.maxDate = moment(today).startOf('day').toDate();
      }

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.SaleService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getPolicyById(policyId) {
        var returnPromise = $q.defer();
        var reqUrl = '/policydetails/' + policyId;

        commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, true).then(function (res) {
          if (res.data && res.data.statusCode && res.data.statusCode === 200) {
            currentSale = res.data.result;
            currentSale.$$fullAccess = !AuthService.isMISChecker();
            getDocuments(currentSale);
            if (['TW', 'FW'].includes(currentSale.productCategory) && currentSale.make && currentSale.model) {
              currentSale.makemodel = currentSale.make + ' ' + currentSale.model;
              var reqObj = {
                make: currentSale.make,
                model: currentSale.model
              };
              vehicleTypeInitFn(currentSale.vehicleType, currentSale);
              selectedMakemodelChangeFn(reqObj, currentSale);
            }
            _toggleTpAndOdDateFn(currentSale.multiyear, currentSale);

            _setMinSaleCloseDate(currentSale.createdAt);
            _setMaxIssuanceDate();
            _setCovernoteIssuanceDate(currentSale.createdAt);

            if (saleConfig.broker === CONSTANTS_LIST.BROKER.HDFCBANK) {
              showHideField('businessChannel', false);
            }
            qcErrors.splice(0, qcErrors.length);
            _initBQPData(currentSale);
            // Important as warnings are reset upon initialization
            // TODO: find a workaround
            $timeout(function () {
              if (currentSale.qcErrors) {
                for (var idx = 0; idx < currentSale.qcErrors.length; idx++) {
                  var error = currentSale.qcErrors[idx];
                  _markAsQCError(error.field);
                  qcErrors.push(error);
                }

                currentSale.$$qcErrors = qcErrors;
              }
            }, 10);
            if (!currentSale.$$fullAccess) {
              $timeout(function () {
                currentSale.$$dpNo = angular.copy(currentSale.intermediaryInternalId);
                currentSale.$$dpName = angular.copy(currentSale.intermediaryName);
                if (isAuditDPDetailsPresent(currentSale)) {
                  currentSale.$$dpNo = angular.copy(currentSale.auditDPDetails.dpNumber);
                  currentSale.$$dpName = angular.copy(currentSale.auditDPDetails.dpName);
                }
                _initForChecker();
              }, 300);
            }
            returnPromise.resolve(currentSale);
          } else {
            returnPromise.reject(res.data);
          }
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getDocuments(sale) {
        var reqObj = {};
        if (sale.requestId) {
          reqObj.requestId = sale.requestId;
        } else {
          reqObj.policyDetailsId = sale.policyDetailsId;
        }
        MasterFileService.getDocuments(reqObj).then(function (res) {
          if (res) {
            currentSale.attachedDocuments = res;
            handleUploadedDocuments(currentSale.attachedDocuments, currentSale);
          }
        });
      }

      function updatePolicyUrl(policyDetailId, isPolicy, fileId) {
        var reqUrl = '/updatePolicyUrl?id=' + policyDetailId + '&set=' + isPolicy + '&url=' + fileId;
        return commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, false);
      }

      function isAuditDPDetailsPresent(sale) {
        if (sale.auditDPDetails && Object.keys(sale.auditDPDetails).length > 0 && sale.auditDPDetails !== null && sale.auditDPDetails !== undefined) {
          return true;
        }
        return false;
      }

      function _initForChecker() {
        var fields = ['vehicleType', 'vehicleSubType', 'carrierType', 'channelType', 'remarks', 'creationSource', 'createdAt', 'policyProposerAlternateMobile'];
        fields.forEach(function (field) {
          showHideField(field, true, true);
        });
      }

      /**
       * Acquire Edit Lock for policy
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.SaleService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function acquirePolicyLock(policyId) {
        var returnPromise = $q.defer();
        var reqUrl = '/acquirepolicylock?policyId=' + policyId;
        commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, true).then(function (res) {
          if (res.data && res.data.statusCode === 200) {
            returnPromise.resolve();
          }

          if (res.data.message) {
            $window.alert(res.data.message);
          }

          returnPromise.reject();
        }).catch(function () {
          returnPromise.reject();
        });

        return returnPromise.promise;
      }

      /**
       * Release Edit Lock for policy
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.SaleService#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function releasePolicyLock(policyId) {
        var returnPromise = $q.defer();
        var reqUrl = '/releasepolicylock?policyId=' + policyId;
        commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, true).then(function () {
          returnPromise.resolve();
        }).catch(function () {
          returnPromise.reject();
        });

        return returnPromise.promise;
      }

      /**
       * Function to handle uploaded documents
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           attachedDocuments       Attched Documents
       *
       * @author snehilmodani
       *
       */
      function handleUploadedDocuments(attachedDocuments, sale) {
        sale.$$attachedDocuments = attachedDocuments && attachedDocuments.length > 0 ? attachedDocuments : [];
      }

      /**
       * Returns config of form field by using code (TODO: change from array approach)
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           code          Code of the form field
       *
       * @author snehilmodani
       *
       * @return   {Object}           Config object of form field
       *
       */
      function getConfigObjectFromCode(code) {
        for (var idx = sourceOfSaleFields.length - 1; idx >= 0; idx--) {
          var config = sourceOfSaleFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = basicDetailsConfig.length - 1; idx >= 0; idx--) {
          var config = basicDetailsConfig[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = saleFieldsConfig.length - 1; idx >= 0; idx--) {
          var config = saleFieldsConfig[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.customerDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.customerDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.nomineeDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.nomineeDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.policyDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.policyDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.premiumDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.premiumDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.previousPolicyDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.previousPolicyDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.vehicleDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.vehicleDetails[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.odPolicyFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.odPolicyFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = paymentFields.length - 1; idx >= 0; idx--) {
          var config = paymentFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = marketingFields.length - 1; idx >= 0; idx--) {
          var config = marketingFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = preSaleVerification.length - 1; idx >= 0; idx--) {
          var config = preSaleVerification[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = otherFields.length - 1; idx >= 0; idx--) {
          var config = otherFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.checkerSOS.length - 1; idx >= 0; idx--) {
          var config = policyFields.checkerSOS[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = minDoc.length - 1; idx >= 0; idx--) {
          var config = minDoc[idx];
          if (config.code === code) {
            return config;
          }
        }
      }

      /**
       * Function to search Insurers
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchInsurerFn(searchKey, model, config) {
        var deferred = $q.defer();
        var insurers = [];
        if (currentSale.productCategory && currentSale.productCategory === 'HEALTH') {
          insurers = angular.copy(getHealthInsurer());
        } else {
          insurers = angular.copy(MIS_CONSTANTS.insurers);
          if (currentSale.productCategory && currentSale.productCategory === 'LIFE') {
            insurers = angular.copy(MIS_CONSTANTS.lifeInsurers);
          }
        }
        if (!insurers || insurers && insurers.length < 1) {
          $timeout(function () {
            delete config.autocompleteConfig.searchText;
          }, 2000);
        }
        if (searchKey) {
          var results = $filter('filter')(insurers, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(insurers);
        }
        return deferred.promise;
      }

      /**
       * Function to search Intermediaries
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchIntermediariesFn(searchKey, model) {
        var deferred = $q.defer();
        if (searchKey && currentSale.tenant && currentSale.channelType && currentSale.saleClosedDate && currentSale.channelType !== 'strategicalliance') {
          var reqUrl = '/mintpro/v1/partners/search';
          var reqObj = {
            all: true,
            isPOSP: true,
            name: searchKey,
            tenant: currentSale.tenant,
            active: true
          };
          var promise = commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.meta && res.data.meta.statusCode === 200) {
              saleConfig.intermediaries = res.data.data || [];
              saleConfig.intermediaries.forEach(function (partner) {
                partner.dpNumber = 'DP - ' + partner.dpNo;
                partner.title = partner.name + ' - [' + partner.dpNumber + ']';
              });
              deferred.resolve(saleConfig.intermediaries);
            } else {
              clearPartnerData(model);
              deferred.reject(true);
            }
          });
        } else {
          clearPartnerData(model);
          deferred.reject(true);
        }

        return deferred.promise;
      }

      function clearPartnerData(model) {
        if (model.intermediaryInternalId) {
          delete model.intermediaryInternalId;
          delete model.intermediaryLoginId;
          delete model.tlName;
          delete model.intermediaryName;
          delete model.salesExecutive;
          delete model.um;
          delete model.sum;
          delete model.tl;
          delete model.mp;
          delete model.dm;
          delete model.sm;
          delete model.ssm;
          delete model.am;
          delete model.ah;
          delete model.sah;
          delete model.rm;
          delete model.srm;
          delete model.zm;
          delete model.nationalHead;
          delete model.dpBranchLocation;
          delete model.dpState;
          delete model.dpRegion;
          delete model.intermediaryLevel;
          delete model.parentIntermediaryName;
          delete model.parentIntermediaryLoginId;
          delete model.parentIntermediaryInternalId;
          delete model.parentIntermediaryLevel;
          delete model.umId;
          delete model.sumId;
          delete model.tlId;
          delete model.mpId;
          delete model.smId;
          delete model.ssmId;
          delete model.amId;
          delete model.ahId;
          delete model.sahId;
          delete model.rmId;
          delete model.srmId;
          delete model.zmId;
          delete model.nationalHeadId;
        }
      }

      /**
       * Function to search Unit managers
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author sanketapte
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function fetchUnitManagersFn() {
        var deferred = $q.defer();
        if (currentSale.tenant && currentSale.channelType && currentSale.saleClosedDate && 'partner' !== currentSale.channelType && currentSale.channelType !== 'strategicalliance') {
          saleConfig.unitManagers.splice(0, saleConfig.unitManagers);
          var reqUrl = '/mintpro/v1/partners/search';
          var reqObj = {
            all: true,
            isPOSP: true,
            name: 'anuja',
            tenant: currentSale.tenant
          };
          var promise = commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.meta && res.data.meta.statusCode === 200) {
              saleConfig.unitManagers = [];

              for (var idx = 0; idx < res.data.data.length; idx++) {
                var data = res.data.data[idx];
                saleConfig.unitManagers.push(data);
              }

              //deferred.resolve(saleConfig.unitManagers);
            } else {
              deferred.reject(true);
            }
          });
        }
        return deferred.promise;
      }

      /**
       * Function to search TLs
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchTLFn(searchKey, currentSale) {
        var deferred = $q.defer();
        var tlNames = angular.copy(saleConfig.tlNames);
        if (searchKey) {
          var results = $filter('filter')(tlNames, searchKey);
          $timeout(function () {
            delete currentSale.tlName;
          }, 1000);
          deferred.resolve(results);
        } else {
          delete currentSale.tlName;
          deferred.resolve(tlNames);
        }

        return deferred.promise;
      }

      /**
       * Function to search plan names
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchPlanNames(searchKey, model) {
        var deferred = $q.defer();
        var planNames = angular.copy(saleConfig.planNames);

        if (searchKey) {
          var results = $filter('filter')(planNames, searchKey);
          // if (!results || (results && results.length <1)) {
          //   $timeout(() => {
          //       _clearPlanNames(model);
          //   }, 2000);
          // }
          deferred.resolve(results);
        } else {
          if (['HEALTH', 'LIFE'].includes(model.productCategory)) {
            _clearPlanNames(model);
          }
          deferred.resolve(planNames);
        }

        return deferred.promise;
      }

      function _clearPlanNames(model) {
        delete model.planName;
        delete model.tmPlanId;
        delete model.productUIN;
      }

      /**
       * Function called on change of planName
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedPlanNameChangeFn(selectedItem, model) {
        if (selectedItem) {
          var planName = getConfigObjectFromCode('planName');
          if (selectedItem.tmPlanId === 'others') {
            delete model.planName;
            delete model.productUIN;
            planName.hide = false;
            model.tmPlanId = selectedItem.tmPlanId;
          } else {
            planName.hide = true;
            model.planName = selectedItem.planName;
            model.tmPlanId = selectedItem.tmPlanId;
            model.productUIN = selectedItem.productUIN;
          }
        } else {
          _clearPlanNames(model);
        }
      }

      /**
       * Function to search TLs
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchUnitManagersFn(searchKey, currentSale) {
        var deferred = $q.defer();
        var umNames = angular.copy(saleConfig.unitManagers);
        if (searchKey) {
          if (currentSale.tenant && currentSale.channelType && currentSale.saleClosedDate && 'partner' !== currentSale.channelType) {
            var results = $filter('filter')(umNames, searchKey);
            if (!results || results && results.length < 1) {
              $timeout(function () {
                delete currentSale.um;
              }, 1000);
            }
            deferred.resolve(results);
          } else {
            deferred.resolve(umNames);
            $mdToast.show($mdToast.simple().textContent('Tenant, channel type and sales closed date is required to search intermediary').action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
              if (response === 'ok') {
                $window.console.log('You clicked the \'DISMISS\' action.');
              }
            });
          }
        } else {
          delete currentSale.um;
          deferred.resolve(umNames);
        }
        return deferred.promise;
      }

      function selectedProductNameChangeFn(selectedItem, sale) {
        if (selectedItem) {
          delete sale.vehicleSubType;
        }
        _checkPolicyDuplicateCheck(sale);
        selectedProductNameInitFn(selectedItem, sale);
      }

      /**
       * Function called on change of Product Name
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedProductNameInitFn(selectedItem, sale) {
        if (selectedItem) {
          var showOdPolicyFields = false;
          var odPolicyFields = ['previousODPolicyNumber', 'previousODRiskStartDate', 'previousODRiskEndDate', 'previousODInsurer', 'activeTPPolicyNumber', 'activeTPRiskStartDate', 'activeTPRiskEndDate', 'activeTPInsurer'];
          if (selectedItem === 'tp') {
            showOdPolicyFields = false;
            if (sale.productCategory === 'FW') {
              _filterVehicleTypes('FW', 'CV');
            }
            showHideField('tpPremium', false);
            showHideField('basicTpPremium', false);
            showHideField('odPremium', true);
            showHideField('netOdPremium', true);
            showHideField('multiyear', false);
            showHideField('startDate', true);
            showHideField('endDate', true);
            showHideField('odRiskStartDate', true);
            showHideField('odRiskEndDate', true);
            showHideField('tpRiskStartDate', false);
            showHideField('tpRiskEndDate', false);
            markFieldRequired('ncb', false);
          } else if (selectedItem === 'od') {
            showOdPolicyFields = true;
            if (sale.productCategory === 'FW') {
              _filterVehicleTypes('FW');
            }
            showHideField('tpPremium', true);
            showHideField('basicTpPremium', true);
            showHideField('odPremium', false);
            showHideField('netOdPremium', false);
            showHideField('multiyear', false);
            showHideField('startDate', true);
            showHideField('endDate', true);
            showHideField('tpRiskStartDate', true);
            showHideField('tpRiskEndDate', true);
            showHideField('odRiskStartDate', false);
            showHideField('odRiskEndDate', false);
            validateNCB(sale);
          } else {
            showOdPolicyFields = false;
            if (sale.productCategory !== 'TW') {
              _filterVehicleTypes('FW', 'CV');
            }
            showHideField('odPremium', false);
            showHideField('netOdPremium', false);
            showHideField('tpPremium', false);
            showHideField('basicTpPremium', false);
            showHideField('multiyear', false);
            if (sale.multiyear) {
              showHideField('startDate', true);
              showHideField('endDate', true);
              showHideField('odRiskStartDate', false);
              showHideField('odRiskEndDate', false);
              showHideField('tpRiskStartDate', false);
              showHideField('tpRiskEndDate', false);
            } else {
              showHideField('odRiskStartDate', true, true);
              showHideField('odRiskEndDate', true, true);
              showHideField('tpRiskStartDate', true, true);
              showHideField('tpRiskEndDate', true, true);
              showHideField('startDate', false);
              showHideField('endDate', false);
            }
            validateNCB(sale);
          }
          odPolicyFields.forEach(function (ele) {
            showHideField(ele, !showOdPolicyFields);
            // markFieldRequired(ele, showOdPolicyFields);
          });

          if (sale.productCategory === 'FW' && sale.vehicleType === 'CAR') {
            sale.vehicleSubType = 'CAR';
          }
        }
      }

      function _planTypeChangeFn(selectedItem) {
        getProductUINMasters();
        _filterSubPlanType(selectedItem);
      }

      function selectedIntermediaryInitFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: model.intermediaryLoginId,
            vertical: model.productCategory
          };
          FormHelperService.isACertifedPartner(formData).then(function (res) {
            var isCertified = res.data.data.allowedToDoBusiness;
            if (isCertified === false) {
              clearPartnerData(currentSale);
              commonService.showToast('Entered partner detail is not a certified partner. Please select or add certified partner');
            }
          }).catch(function () /*err*/{
            clearPartnerData(currentSale);
            commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
          });
        } else {
          clearPartnerData(currentSale);
        }
      }

      /**
       * Function called on change of Intermediary
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedIntermediaryChangeFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: selectedItem.id,
            vertical: model.productCategory
          };
          var tp = moment(currentSale.saleClosedDate).format('x');
          var getPartnersInfoUrl = '/getpartnersinfo?query=' + selectedItem.id + '&tenant=' + model.tenant + '&channel=partner&saleDate=' + tp + '&username=true';
          var promise = commonService.makeHttpCall(getPartnersInfoUrl, {}, 'GET', false, false, false);

          promise.then(function (res /*, status, headers, config*/) {
            if (res.data.data && res.data.status === 'SUCCESS') {
              var responseArray = res.data.data || [];
              var userDetails = responseArray.find(function (user) {
                return user.dpLoginId === selectedItem.id;
              });
              selectedItem.am = userDetails.am || null;
              selectedItem.salesExec = userDetails.salesExec || null;
              selectedItem.am = userDetails.am || null;
              selectedItem.um = userDetails.um || null;
              selectedItem.sum = userDetails.sum || null;
              selectedItem.tl = userDetails.tl || null;
              selectedItem.ssm = userDetails.ssm || null;
              selectedItem.rm = userDetails.rm || null;
              selectedItem.mp = userDetails.mp || null;
              selectedItem.dm = userDetails.dm || null;
              selectedItem.sm = userDetails.sm || null;
              selectedItem.ah = userDetails.ah || null;
              selectedItem.shm = userDetails.shm || null;
              selectedItem.rh = userDetails.rh || null;
              selectedItem.srm = userDetails.srm || null;
              selectedItem.zm = userDetails.zm || null;
              selectedItem.nationalHead = userDetails.nationalHead || null;
              if (selectedItem.partnerType === 'employee' || selectedItem.partnerType === 'small_business') {
                setPartnerDetails(selectedItem);
              } else {
                FormHelperService.isACertifedPartner(formData).then(function (res) {
                  var isCertified = res.data.data.allowedToDoBusiness;
                  if (isCertified === true) {
                    setPartnerDetails(selectedItem);
                  } else {
                    clearPartnerData(currentSale);
                    window.alert('Entered partner detail is not a certified partner. please select or add certified partner');
                  }
                }).catch(function () /*err*/{
                  clearPartnerData(currentSale);
                  commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
                });
              }
            } else {
              clearPartnerData(currentSale);
            }
          });
        }
      }

      function setPartnerDetails(selectedItem) {
        currentSale.intermediaryInternalId = selectedItem.dpNumber;
        currentSale.intermediaryLoginId = selectedItem.id;
        currentSale.tlName = selectedItem.teamLeader;
        currentSale.intermediaryName = selectedItem.name;
        currentSale.intermediaryEmail = selectedItem.email;
        currentSale.intermediaryMobile = selectedItem.mobile;

        if (selectedItem.salesExec) {
          currentSale.salesExecutive = selectedItem.salesExec.name;
          currentSale.salesExecutiveId = selectedItem.salesExec.id;
        }

        if (selectedItem.um) {
          currentSale.um = selectedItem.um.name;
          currentSale.umId = selectedItem.um.id;
        }

        if (selectedItem.sum) {
          currentSale.sum = selectedItem.sum.name;
          currentSale.sumId = selectedItem.sum.id;
        }

        if (selectedItem.tl) {
          currentSale.tl = selectedItem.tl.name;
          currentSale.tlId = selectedItem.tl.id;
        }

        if (selectedItem.mp) {
          currentSale.mp = selectedItem.mp.name;
          currentSale.mpId = selectedItem.mp.id;
        }

        if (selectedItem.dm) {
          currentSale.dm = selectedItem.dm.name;
          currentSale.dmId = selectedItem.dm.id;
        }

        if (selectedItem.sm) {
          currentSale.sm = selectedItem.sm.name;
          currentSale.smId = selectedItem.sm.id;
        }

        if (selectedItem.ssm) {
          currentSale.ssm = selectedItem.ssm.name;
          currentSale.ssmId = selectedItem.ssm.id;
        }

        if (selectedItem.am) {
          currentSale.am = selectedItem.am.name;
          currentSale.amId = selectedItem.am.id;
        }

        if (selectedItem.ah) {
          currentSale.ah = selectedItem.ah.name;
          currentSale.ahId = selectedItem.ah.id;
        }

        if (selectedItem.sah) {
          currentSale.sah = selectedItem.sah.name;
          currentSale.sahId = selectedItem.sah.id;
        }

        if (selectedItem.rm) {
          currentSale.rm = selectedItem.rm.name;
          currentSale.rmId = selectedItem.rm.id;
        }

        if (selectedItem.srm) {
          currentSale.srm = selectedItem.srm.name;
          currentSale.srmId = selectedItem.srm.id;
        }

        if (selectedItem.zm) {
          currentSale.zm = selectedItem.zm.name;
          currentSale.zmId = selectedItem.zm.id;
        }

        if (selectedItem.nationalHead) {
          currentSale.nationalHead = selectedItem.nationalHead.name;
          currentSale.nationalHeadId = selectedItem.nationalHead.id;
        }
        currentSale.dpBranchLocation = selectedItem.branchLocation;
        currentSale.dpState = selectedItem.state;
        currentSale.dpRegion = selectedItem.region;
        currentSale.intermediaryLevel = selectedItem.intermediaryLevel;
        currentSale.parentIntermediaryName = selectedItem.parentIntermediaryName;
        currentSale.parentIntermediaryLoginId = selectedItem.parentIntermediaryLoginId;
        currentSale.parentIntermediaryInternalId = selectedItem.parentIntermediaryInternalId;
        currentSale.parentIntermediaryLevel = selectedItem.parentIntermediaryLevel;
      }

      function _unitManagerChangeFn(selectedItem) {
        if (selectedItem) {
          currentSale.um = selectedItem.dpName;
          currentSale.rm = selectedItem.rm.name;
        }
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedMakemodelChangeFn(selectedItem, model) {
        if (selectedItem) {
          showHideField('variant', false);
          model.make = selectedItem.make;
          model.model = selectedItem.model;
          _getVariants(model);
        } else {
          clearVehicleFields(model);
          showHideField('variant', true);
        }
      }

      function clearVehicleFields(model) {
        delete model.make;
        delete model.model;
        delete model.variant;
        delete model.vehicleName;
        delete model.fuel;
        delete model.cc;
        delete model.vehicleId;
        delete model.makemodel;

        var fieldConfig = getConfigObjectFromCode('makemodel');
        delete fieldConfig.autocompleteConfig.searchText;
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function vehicleTypeChangeFn(selectedItem, model) {
        if (selectedItem) {
          clearVehicleFields(model);
        }
        vehicleTypeInitFn(selectedItem, model);
        _checkPolicyDuplicateCheck(model);
        _checkPolicyDuplicateOnRegistrationNumber(model);
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function vehicleTypeInitFn(selectedItem, sale) {
        if (selectedItem) {
          var vehicleSubTypeConfig;
          showHideField('makemodel', false);
          for (var idx = saleConfig.vehicleTypes.length - 1; idx >= 0; idx--) {
            var vehicleType = saleConfig.vehicleTypes[idx];
            if (vehicleType.code === selectedItem) {
              assignedVertical = vehicleType.verticalCode;
              sale.$$assignedVertical = assignedVertical;
              FormHelperService.setAssignedVertical(assignedVertical);
              vehicleSubTypeConfig = getConfigObjectFromCode('vehicleSubType');
              vehicleSubTypeConfig.selectConfig.options = vehicleType.vehicleSubType;
              break;
            }
          }
          if (selectedItem === 'CAR') {
            showHideField('gvw', true);
            showHideField('carrierType', true);
            showHideField('seatingCapacity', true);
            showHideField('vehicleSubType', false);
            sale.vehicleSubType = 'CAR';
            _multiYearPolicyChangeFn(sale.multiyear, sale);
          } else if (selectedItem === 'MISCD') {
            showHideField('gvw', true);
            showHideField('carrierType', true);
            showHideField('seatingCapacity', true);
            showHideField('vehicleSubType', false);
          } else if (selectedItem === 'GCV') {
            showHideField('gvw', false);
            showHideField('carrierType', false);
            showHideField('seatingCapacity', true);
            showHideField('vehicleSubType', false);
          } else if (selectedItem === 'PCV') {
            showHideField('gvw', true);
            showHideField('carrierType', false);
            showHideField('seatingCapacity', false);
            showHideField('vehicleSubType', false);
          } else if (selectedItem === 'TW') {
            showHideField('gvw', true);
            showHideField('carrierType', true);
            showHideField('seatingCapacity', false);
            showHideField('vehicleSubType', false);
            _multiYearPolicyChangeFn(sale.multiyear, sale);
          } else {
            showHideField('gvw', true);
            showHideField('carrierType', true);
            showHideField('seatingCapacity', false);
          }
        } else {
          showHideField('makemodel', true);
        }
      }

      /**
       * Function called on change of Business Type
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function _businessTypeChangeFn(selectedItem, sale) {
        initMinimumDocFields(sale);
        _businessTypeInitFn(selectedItem, sale);
      }

      function _businessTypeInitFn(selectedItem, sale) {
        if (selectedItem) {
          if (selectedItem === 'Renewal') {
            markFieldRequired('prevPolicyNumber', true);
          } else {
            markFieldRequired('prevPolicyNumber', false);
          }
          _setInceptionAndStartDate(sale);
        }
      }

      function _toggleTpAndOdDateFn(selectedValue, sale) {
        if (selectedValue) {
          showHideField('startDate', true, true);
          showHideField('endDate', true, true);
          if (sale.productName === 'tp') {
            showHideField('odRiskStartDate', true);
            showHideField('odRiskEndDate', true);
            showHideField('tpRiskStartDate', false);
            showHideField('tpRiskEndDate', false);
          } else if (sale.productName === 'od') {
            showHideField('odRiskStartDate', false);
            showHideField('odRiskEndDate', false);
            showHideField('tpRiskStartDate', true);
            showHideField('tpRiskEndDate', true);
          } else {
            showHideField('odRiskStartDate', false);
            showHideField('odRiskEndDate', false);
            showHideField('tpRiskStartDate', false);
            showHideField('tpRiskEndDate', false);
          }
        } else if (sale.productName === 'tp') {
          showHideField('startDate', true, true);
          showHideField('endDate', true, true);
          showHideField('odRiskStartDate', true);
          showHideField('odRiskEndDate', true);
          showHideField('tpRiskStartDate', false);
          showHideField('tpRiskEndDate', false);
        } else if (sale.productName === 'od') {
          showHideField('startDate', true, true);
          showHideField('endDate', true, true);
          showHideField('tpRiskStartDate', true);
          showHideField('tpRiskEndDate', true);
          showHideField('odRiskStartDate', false);
          showHideField('odRiskEndDate', false);
        } else {
          showHideField('odRiskStartDate', true);
          showHideField('odRiskEndDate', true);
          showHideField('tpRiskStartDate', true);
          showHideField('tpRiskEndDate', true);
          showHideField('startDate', false);
          showHideField('endDate', false);
        }
      }

      function _isVehicleFinancedInitFn(selectedItem) {
        if (selectedItem) {
          showHideField('financingInstitute', false);
        } else {
          showHideField('financingInstitute', true);
        }
      }

      function _isVehicleFinancedChangeFn(selectedItem, sale) {
        _isVehicleFinancedInitFn(selectedItem, sale);
      }

      function _registrationNumberBlurFn(selectedItem, sale) {
        _checkPolicyDuplicateCheck(sale);
        _checkPolicyDuplicateOnRegistrationNumber(sale);
        getRegistrationResult(selectedItem, sale);
      }

      function _multiYearPolicyChangeFn(selectedValue, sale) {
        if (selectedValue === undefined) {
          sale.multiyear = false;
        }
        _toggleTpAndOdDateFn(selectedValue, sale);
      }

      function _startDateInitFn(selectedItem, sale) {
        _checkDateValidation(sale);
      }
      function _startDateChangeFn(selectedItem, sale) {
        _checkDateValidation(sale);
        _autoCalculateEndDate(sale);
        _checkPolicyDuplicateCheck(sale);
        _checkPolicyDuplicateOnRegistrationNumber(sale);
        _setInceptionDate(sale);
      }

      function _endDateInitFn(selectedItem, sale) {
        _checkDateValidation(sale);
      }
      function _endDateChangeFn(selectedItem, sale) {
        _checkDateValidation(sale);
        _checkPolicyDuplicateCheck(sale);
        _checkPolicyDuplicateOnRegistrationNumber(sale);
      }

      function _disableStartDate(sale) {
        var date = void 0,
            today = moment();
        var issuanceDate = moment(sale.issuanceDate);
        var createdDate = moment(sale.createdAt);
        var disable = false;

        if (issuanceDate && createdDate) {
          if (issuanceDate > createdDate) {
            date = issuanceDate;
          } else {
            date = createdDate;
          }
          var date2month = moment(date).add(2, 'M');
          disable = moment(today).isAfter(date2month, 'day');
        }
        _markFieldDisabled('startDate', disable);
      }

      function umAndRmResetForChannelChangeFn(selectedItem) {
        var umConfig = getConfigObjectFromCode('um');
        umConfig.readonly = false;
        if (selectedItem === 'website' || selectedItem === 'referral') {
          delete umConfig.type;
          $timeout(function () {
            umConfig.type = 'autocomplete';
            umConfig.autocompleteConfig = {
              disabled: false,
              noCache: true,
              //selectedItem: saleConfig.selectedUnitManager,
              selectedItemChange: _unitManagerChangeFn,
              querySearch: searchUnitManagersFn,
              displayKey: 'dpName',
              valueKey: 'dpName',
              minLength: 2
            };
          });
        } else {
          if (selectedItem === 'partner' || selectedItem === 'employee') {
            umConfig.readonly = true;
          }
          delete umConfig.autocompleteConfig;
          delete umConfig.type;
          $timeout(function () {
            umConfig.type = 'text';
            if (selectedItem === 'strategicalliance') {
              currentSale.nationalHead = saleConfig.turtlemintStrategicAllianceRM;
            }
          });
        }
      }

      function tenantInItForChannelChangeFn(selectedItem) {
        if (saleConfig.turtlemintTenant.length <= 0) {
          showHideField('tenant', true);
        } else if (selectedItem === 'strategicalliance') {
          delete currentSale.tenant;
        } else {
          currentSale.tenant = saleConfig.turtlemintTenant[0].code;
        }
        tenantInItFn(selectedItem);
      }

      function tenantInItFn() {
        var tenantTypeConfig = getConfigObjectFromCode('tenant');
        var tenantList = saleConfig.turtlemintTenant;
        tenantTypeConfig.selectConfig = {
          options: tenantList,
          valueKey: 'code',
          textKey: 'name'
        };
      }

      /**
        * Function called on change of Channel Type
        *
        * @public
        *
        * @function
        *
        * @param    {!Object}           selectedItem          Selected Item
        *
        * @author snehilmodani
        *
        */
      function channelTypeChangeFn(selectedItem, model) {
        getTenants(selectedItem);
        if (selectedItem) {
          tenantInItForChannelChangeFn(selectedItem);
          umAndRmResetForChannelChangeFn(selectedItem);

          clearSalesFields();
          if (selectedItem === 'partner' || selectedItem === 'employee') {
            showHideField('intermediaryName', false);
            showHideField('intermediaryLoginId', false);
            showHideField('intermediaryInternalId', false);
            showHideField('salesExecutive', false);
            showHideField('sum', false);
            showHideField('mp', false);
            showHideField('tl', false);
            showHideField('dm', false);
            showHideField('ssm', false);
            showHideField('ah', false);
            showHideField('sah', false);
            showHideField('srm', false);
            showHideField('um', false);
            showHideField('sm', false);
            showHideField('am', false);
            showHideField('rm', false);
            showHideField('zm', false);
            showHideField('nationalHead', false);
            showHideField('dpBranchLocation', false);
            showHideField('dpState', false);
            showHideField('dpRegion', false);
            showHideField('intermediaryLevel', false);
            showHideField('parentIntermediaryName', false);
            showHideField('parentIntermediaryLoginId', false);
            showHideField('parentIntermediaryInternalId', false);
            showHideField('parentIntermediaryLevel', false);
            markFieldRequired('intermediaryName', true);
            markFieldRequired('intermediaryLoginId', true);
            markFieldRequired('intermediaryInternalId', true);

            $timeout(function () {
              var intermediaryName = getConfigObjectFromCode('intermediaryName');
              intermediaryName.type = 'autocomplete';
              intermediaryName.readonly = false;
            }, 10);
          } else if (selectedItem === 'strategicalliance') {
            showHideField('salesExecutive', true);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);
            showHideField('rm', true);
            showHideField('um', false);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', true);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);
          } else if (selectedItem === 'website' || selectedItem === 'referral') {
            showHideField('rm', false);
            showHideField('um', false);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('salesExecutive', false);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', true);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);

            if (model.tenant) {
              fetchUnitManagersFn();
            }
          } else {
            showHideField('salesExecutive', true);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);

            showHideField('rm', true);
            showHideField('um', true);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', true);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);
          }
        }
      }

      /**
        * Function called on change of Channel Type
        *
        * @public
        *
        * @function
        *
        * @param    {!Object}           selectedItem          Selected Item
        *
        * @author snehilmodani
        *
        */
      function channelTypeInitFn(selectedItem, model) {
        getTenants(selectedItem);
        if (selectedItem) {
          if (selectedItem === 'partner' || selectedItem === 'employee') {
            showHideField('intermediaryName', false);
            showHideField('intermediaryLoginId', false);
            showHideField('intermediaryInternalId', false);
            showHideField('salesExecutive', false);
            showHideField('sum', false);
            showHideField('mp', false);
            showHideField('tl', false);
            showHideField('dm', false);
            showHideField('ssm', false);
            showHideField('ah', false);
            showHideField('sah', false);
            showHideField('srm', false);
            showHideField('um', false);
            showHideField('sm', false);
            showHideField('am', false);
            showHideField('rm', false);
            showHideField('zm', false);
            showHideField('nationalHead', false);
            showHideField('dpBranchLocation', false);
            showHideField('dpState', false);
            showHideField('dpRegion', false);
            showHideField('intermediaryLevel', false);
            showHideField('parentIntermediaryName', false);
            showHideField('parentIntermediaryLoginId', false);
            showHideField('parentIntermediaryInternalId', false);
            showHideField('parentIntermediaryLevel', false);

            markFieldRequired('intermediaryName', true);
            markFieldRequired('intermediaryLoginId', true);
            markFieldRequired('intermediaryInternalId', true);

            $timeout(function () {
              var intermediaryName = getConfigObjectFromCode('intermediaryName');
              var intermediaryLoginId = getConfigObjectFromCode('intermediaryLoginId');
              var dpNo = getConfigObjectFromCode('intermediaryInternalId');
              intermediaryName.type = 'autocomplete';
              intermediaryName.readonly = false;
              intermediaryLoginId.readonly = true;
              dpNo.readonly = true;
            }, 10);
          } else if (selectedItem === 'strategicalliance') {
            showHideField('salesExecutive', true);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);
            showHideField('rm', true);
            showHideField('um', false);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', false);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);
          } else if (selectedItem === 'website' || selectedItem === 'referral') {
            showHideField('rm', false);
            showHideField('um', false);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('salesExecutive', false);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', true);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);

            if (model.tenant) {
              fetchUnitManagersFn();
            }
          } else {
            showHideField('salesExecutive', true);
            showHideField('sum', true);
            showHideField('mp', true);
            showHideField('tl', true);
            showHideField('dm', true);
            showHideField('ssm', true);
            showHideField('ah', true);
            showHideField('sah', true);
            showHideField('srm', true);

            showHideField('rm', true);
            showHideField('um', true);
            showHideField('intermediaryName', true);
            showHideField('intermediaryLoginId', true);
            showHideField('intermediaryInternalId', true);
            showHideField('sm', true);
            showHideField('am', true);
            showHideField('zm', true);
            showHideField('nationalHead', true);
            showHideField('dpBranchLocation', true);
            showHideField('dpState', true);
            showHideField('dpRegion', true);
            showHideField('intermediaryLevel', true);
            showHideField('parentIntermediaryName', true);
            showHideField('parentIntermediaryLoginId', true);
            showHideField('parentIntermediaryInternalId', true);
            showHideField('parentIntermediaryLevel', true);

            markFieldRequired('intermediaryName', false);
            markFieldRequired('intermediaryLoginId', false);
            markFieldRequired('intermediaryInternalId', false);
          }
        }
      }

      function isPolicyActive(status) {
        return ['ACTIVE', 'ENDORSED'].includes(status);
      }

      function _customeEmailChangeFn(selectedItem, sale) {
        if (selectedItem) {
          if (isPolicyActive(sale.policyStatus)) {
            markFieldRequired('policyProposerEmail', false);
          }
          sale.policyProposerEmail = '';
        } else {
          if (isPolicyActive(sale.policyStatus)) {
            markFieldRequired('policyProposerEmail', true);
          }
        }
      }

      function _sameAddressChange(selectedItem) {
        if (selectedItem) {
          showHideField('communicationAddress', true);
          showHideField('communicationCity', true);
          showHideField('communicationState', true);
          showHideField('communicationPincode', true);
        } else {
          showHideField('communicationAddress', false);
          showHideField('communicationCity', false);
          showHideField('communicationState', false);
          showHideField('communicationPincode', false);
        }
      }

      function addressCopyFn(sale) {
        if (sale.sameAsRegistrationAddress === true) {
          sale.communicationAddress = sale.registrationAddress;
          sale.communicationCity = sale.registrationCity;
          sale.communicationState = sale.registrationState;
          sale.communicationPincode = sale.registrationPincode;
        }
      }

      function _customePhoneNoChangeFn(selectedItem, sale) {
        if (selectedItem) {
          if (isPolicyActive(sale.policyStatus)) {
            markFieldRequired('policyProposerMobile', false);
          }
          sale.policyProposerMobile = '';
        } else {
          if (isPolicyActive(sale.policyStatus)) {
            markFieldRequired('policyProposerMobile', true);
          }
        }
      }

      function clearSalesFields() {
        var intermediaryNameFieldConfig = getConfigObjectFromCode('intermediaryName');
        if (intermediaryNameFieldConfig.autocompleteConfig) {
          delete intermediaryNameFieldConfig.autocompleteConfig.searchText;
          delete intermediaryNameFieldConfig.autocompleteConfig.selectedItem;
        }

        delete currentSale.intermediaryName;
        delete currentSale.intermediaryLoginId;
        delete currentSale.intermediaryInternalId;
        var umFieldConfig = getConfigObjectFromCode('um');
        if (umFieldConfig.autocompleteConfig) {
          delete umFieldConfig.autocompleteConfig.searchText;
        }
        delete currentSale.salesExecutive;
        delete currentSale.um;
        delete currentSale.sum;
        delete currentSale.mp;
        delete currentSale.tl;
        delete currentSale.dm;
        delete currentSale.sm;
        delete currentSale.ssm;
        delete currentSale.am;
        delete currentSale.ah;
        delete currentSale.sah;
        delete currentSale.rm;
        delete currentSale.srm;
        delete currentSale.zm;
        delete currentSale.nationalHead;
        delete currentSale.dpBranchLocation;
        delete currentSale.dpState;
        delete currentSale.dpRegion;
        delete currentSale.intermediaryLevel;
        delete currentSale.parentIntermediaryName;
        delete currentSale.parentIntermediaryLoginId;
        delete currentSale.parentIntermediaryInternalId;
        delete currentSale.parentIntermediaryLevel;
      }
      /**
       * Function called to Hide Vehicle Fields
       *
       * @public
       *
       * @function
       *
       * @param    {!Boolean}           hide          to hide fields or not
       *
       * @author snehilmodani
       *
       */
      function showHideVehicleFields(hide) {

        var vehicleInfoFields = ['registrationNo', /*'rto',*/'rtoLocation', 'makemodel', 'variant', 'cc', 'fuel', 'vehicleName', 'manufactureYear', 'engineNo', 'chassisNo', 'engProtect', 'ncbProtect', 'deductible', 'rsa', 'zeroDep', 'biFuelKitValue', 'antiTheft', 'llCleaner', 'llDriver', 'paUnnamed', 'paUnnamedsi', 'nonElecValue', 'electricalValue', 'multiyear'];
        var toHide = hide ? true : false;

        for (var idx = vehicleInfoFields.length - 1; idx >= 0; idx--) {
          var field = vehicleInfoFields[idx];
          showHideField(field, toHide);
        }
      }

      /**
       * Function called to Hide Vehicle Fields
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           hide          to hide fields or not
       *
       * @author snehilmodani
       *
       */
      function showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        if (fieldConfig) {
          fieldConfig.hide = toHide;
          if (toHide && !dontDelete) {
            delete currentSale[fieldConfig.code];
          }
        }
      }

      /**
       * Function called to Mark field required
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           required      to mark fields required or not
       *
       * @author snehilmodani
       *
       */
      function markFieldRequired(fieldCode, required) {
        var fieldConfig = getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;

        fieldConfig.required = toRequired;
      }

      /**
       * Function called to Mark field disabled/readonly
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           required      to mark fields required or not
       *
       * @author sagarmulchandani
       *
       */
      function _markFieldDisabled(fieldCode, disabled) {
        var fieldConfig = getConfigObjectFromCode(fieldCode);
        var toDisabled = disabled ? true : false;

        fieldConfig.disabled = toDisabled;
        fieldConfig.readonly = toDisabled;
      }

      /**
       * Function called on change of Plan name
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale.                 Sale
       *
       * @author sanketapte
       *
       */

      function _planNameInitFn(selectedItem, model) {
        if (model.tmPlanId !== 'others') {
          $timeout(function () {
            var tmPlanIdConfig = getConfigObjectFromCode('tmPlanId');
            tmPlanIdConfig.autocompleteConfig.searchText = model.planName;
          }, 400);
        }
        if (!selectedItem || selectedItem === 'others') {
          showHideField('planName', false);
        } else {
          showHideField('planName', true, true);
        }
      }

      function _issuanceDateChangeFn(selectedItem, sale) {
        _checkPolicyDuplicateCheck(sale);
        _checkPolicyDuplicateOnRegistrationNumber(sale);
        if (sale.status === 'ACTIVE' && sale.productCategory === 'LIFE') {
          _disableStartDate(sale);
        }
      }

      /**
       * Function called on change of Policy Status
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale.                 Sale
       *
       * @author snehilmodani
       *
       */

      function _policyStatusChangeFn(selectedItem, sale) {
        if (selectedItem) {
          _checkPolicyDuplicateCheck(sale);
          _checkPolicyDuplicateOnRegistrationNumber(sale);
        }
        if (sale.productCategory === 'LIFE') {
          _setSubStatus(sale);
        }
        _policyStatusInitFn(selectedItem, sale);
      }

      function _policyStatusInitFn(selectedItem, sale) {
        if (selectedItem) {
          showHideField('cancellationDate', true, true);
          showHideField('remarks', true, true);
          markFieldRequired('cancellationDate', false);
          markFieldRequired('remarks', false);

          if (['PENDING_INSURER', 'PENDING_TM'].includes(selectedItem)) {
            markFieldRequired('policyNumber', false);
            markFieldRequired('issuanceDate', false);
            markFieldRequired('covernoteIssuanceDate', false);
            markFieldRequired('covernoteNo', false);
            markFieldRequired('remarks', false);
            markFieldRequired('comments', false);
            markFieldRequired('odRiskStartDate', false);
            markFieldRequired('odRiskEndDate', false);
            markFieldRequired('tpRiskStartDate', false);
            markFieldRequired('tpRiskEndDate', false);
            markFieldRequired('inceptionDate', false);
            markFieldRequired('rtoLocation', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            showHideField('reasonForCancellation', true);
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
          } else if (['CANCELLED', 'junk', 'REFUND'].includes(selectedItem)) {
            markFieldRequired('policyNumber', false);
            markFieldRequired('issuanceDate', false);
            markFieldRequired('covernoteIssuanceDate', false);
            markFieldRequired('covernoteNo', false);
            markFieldRequired('comments', true);
            markFieldRequired('odRiskStartDate', false);
            markFieldRequired('odRiskEndDate', false);
            markFieldRequired('tpRiskStartDate', false);
            markFieldRequired('tpRiskEndDate', false);
            markFieldRequired('inceptionDate', false);
            markFieldRequired('rtoLocation', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
            // Cancellation Date and Cancellation Remarks are
            // mandatory if the Policy Status is 'CANCELLED'
            if (selectedItem === 'CANCELLED') {
              showHideField('cancellationDate', false);
              showHideField('remarks', false);
              markFieldRequired('cancellationDate', true);
              markFieldRequired('remarks', true);
              markFieldRequired('odRiskStartDate', false);
              markFieldRequired('odRiskEndDate', false);
              markFieldRequired('tpRiskStartDate', false);
              markFieldRequired('tpRiskEndDate', false);
              markFieldRequired('inceptionDate', false);
              markFieldRequired('rtoLocation', false);
              markFieldRequired('ncb', false);
              showHideField('reasonForCancellation', false);
              if (saleConfig.broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
                markFieldRequired('comments', false);
              }
            } else {
              showHideField('reasonForCancellation', true);
            }
            if (selectedItem === 'junk') {
              setJunkField(sale, selectedItem);
            } else {
              setJunkField(sale, selectedItem);
            }
          } else if (isPolicyActive(selectedItem)) {
            markFieldRequired('policyNumber', true);
            markFieldRequired('issuanceDate', true);
            markFieldRequired('covernoteIssuanceDate', false);
            markFieldRequired('covernoteNo', false);
            markFieldRequired('remarks', false);
            markFieldRequired('comments', false);
            markFieldRequired('odRiskStartDate', true);
            markFieldRequired('odRiskEndDate', true);
            markFieldRequired('tpRiskStartDate', true);
            markFieldRequired('tpRiskEndDate', true);
            markFieldRequired('inceptionDate', true);
            markFieldRequired('rtoLocation', true);
            showHideField('reasonForCancellation', true);
            setJunkField(sale, selectedItem);
            if (sale.productCategory === 'HEALTH') {
              markFieldRequired('registrationPincode', true);
              markFieldRequired('registrationAddress', true);
              markFieldRequired('serviceTax', true);
              markFieldRequired('grossPremium', true);
              markFieldRequired('netPremium', true);
              markFieldRequired('coverType', true);
            }
            validateNCB(sale);
          } else if (selectedItem === 'COVERNOTE_ISSUED') {
            markFieldRequired('policyNumber', false);
            markFieldRequired('issuanceDate', false);
            markFieldRequired('covernoteIssuanceDate', true);
            markFieldRequired('covernoteNo', true);
            markFieldRequired('remarks', false);
            markFieldRequired('comments', false);
            markFieldRequired('odRiskStartDate', false);
            markFieldRequired('odRiskEndDate', false);
            markFieldRequired('tpRiskStartDate', false);
            markFieldRequired('tpRiskEndDate', false);
            markFieldRequired('inceptionDate', false);
            markFieldRequired('rtoLocation', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            showHideField('reasonForCancellation', true);
            _policyStatusCovernoteIssuedSelectFn(sale);
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
          } else if (selectedItem === 'REFUND') {
            markFieldRequired('policyNumber', false);
            markFieldRequired('issuanceDate', false);
            markFieldRequired('covernoteIssuanceDate', false);
            markFieldRequired('covernoteNo', false);
            markFieldRequired('remarks', true);
            markFieldRequired('odRiskStartDate', false);
            markFieldRequired('odRiskEndDate', false);
            markFieldRequired('tpRiskStartDate', false);
            markFieldRequired('tpRiskEndDate', false);
            markFieldRequired('inceptionDate', false);
            markFieldRequired('rtoLocation', false);
            showHideField('reasonForCancellation', true);
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
          } else if (selectedItem === 'POTENTIAL_DUPLICATE') {
            if (sale.$$state === 'UPDATE') {
              showHideField('allowStatusOveride', false);
            } else {
              showHideField('allowStatusOveride', true);
            }
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
          } else if (selectedItem === 'ENDORSED') {
            validateNCB(sale);
          } else {
            markFieldRequired('policyNumber', true);
            markFieldRequired('issuanceDate', false);
            markFieldRequired('covernoteIssuanceDate', false);
            markFieldRequired('covernoteNo', false);
            markFieldRequired('remarks', false);
            markFieldRequired('comments', false);
            markFieldRequired('odRiskStartDate', false);
            markFieldRequired('odRiskEndDate', false);
            markFieldRequired('tpRiskStartDate', false);
            markFieldRequired('tpRiskEndDate', false);
            markFieldRequired('inceptionDate', false);
            markFieldRequired('rtoLocation', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            showHideField('reasonForCancellation', true);
            setJunkField(sale, selectedItem);
            markFieldRequired('ncb', false);
          }

          if (selectedItem === 'EXPIRED') {
            markFieldRequired('comments', true);
            setJunkField(sale, selectedItem);
          }

          if (sale.productCategory === 'LIFE') {
            var subStatusConfig = getConfigObjectFromCode('subStatus');
            var selectedObj = saleConfig.policyStatuses.find(function (item) {
              return item.code === selectedItem;
            });
            subStatusConfig.selectConfig.options = selectedObj && selectedObj.subStatus;
          }
        }

        if (isPolicyActive(selectedItem)) {
          markFieldRequired('startDate', true);
          markFieldRequired('endDate', true);
          markFieldRequired('odRiskStartDate', true);
          markFieldRequired('odRiskEndDate', true);
          markFieldRequired('tpRiskStartDate', true);
          markFieldRequired('tpRiskEndDate', true);
          if (['TW', 'FW'].includes(sale.productCategory)) {
            markFieldRequired('netOdPremium', true);
            markFieldRequired('netPremium', true);
            markFieldRequired('tpPremium', true);
          } else if (sale.productCategory === 'LIFE') {
            markFieldRequired('netPremium', true);
            markFieldRequired('paymentMode', true);
            _disableStartDate(sale);
          } else if (sale.productCategory === 'HEALTH') {
            markFieldRequired('registrationPincode', true);
            markFieldRequired('registrationAddress', true);
            markFieldRequired('serviceTax', true);
            markFieldRequired('grossPremium', true);
            markFieldRequired('netPremium', true);
            markFieldRequired('coverType', true);
          } else {
            markFieldRequired('netOdPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('tpPremium', false);
            markFieldRequired('paymentMode', false);
          }
          if (!sale.policyProposerEmailNotAvailable) {
            markFieldRequired('policyProposerEmail', true);
          }
          if (!sale.policyProposerMobileNotAvailable) {
            markFieldRequired('policyProposerMobile', true);
          }
          validateNCB(sale);
        } else {
          markFieldRequired('startDate', false);
          markFieldRequired('endDate', false);
          markFieldRequired('netOdPremium', false);
          markFieldRequired('netPremium', false);
          markFieldRequired('tpPremium', false);
          markFieldRequired('paymentMode', false);
          if (!['LIFE', 'HEALTH'].includes(sale.productCategory)) {
            markFieldRequired('policyProposerEmail', false);
            markFieldRequired('policyProposerMobile', false);
          }
        }
      }

      /**
       * Function called on policy status convert note issue
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           sale                  sale
       *
       * @author snehilmodani
       *
       */
      function _policyStatusCovernoteIssuedSelectFn(sale) {
        if (sale && sale.productCategory && sale.insurer && sale.covernoteNo) {
          _checkCovernoteExists(sale).then(function (result) {
            _openDuplicateCovernoteModal(sale, result);
          });
        }
      }

      /**
       * Function called on policy number
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale                  sale
       *
       * @author snehilmodani
       *
       */
      function _policyNumberBlurFn(selectedItem, sale) {
        _checkPolicyDuplicateCheck(sale);
      }

      /**
       * Function called on convert note number
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale                  sale
       *
       * @author snehilmodani
       *
       */
      function _covernoteNumberBlurFn(selectedItem, sale) {
        if (selectedItem && sale.productCategory && sale.insurer && sale.policyStatus === MIS_CONSTANTS.POLICY_STATUS.COVERNOTE_ISSUED) {
          _checkCovernoteExists(sale).then(function (result) {
            _openDuplicateCovernoteModal(sale, result);
          });
        }
      }

      /**
       * Function called on change of Insurer
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale                  sale
       *
       * @author snehilmodani
       *
       */
      function _selectedInsurerChangeFn(selectedItem, sale) {
        if (selectedItem && sale.productCategory) {
          sale.insurer = selectedItem.value;
          getProductUINMasters();
          if (sale.covernoteNo && sale.policyStatus === MIS_CONSTANTS.POLICY_STATUS.COVERNOTE_ISSUED) {
            _checkCovernoteExists(sale).then(function (result) {
              _openDuplicateCovernoteModal(sale, result);
            });
          } else {
            _checkPolicyDuplicateCheck(sale);
            _checkPolicyDuplicateOnRegistrationNumber(sale);
          }
        } else {
          delete sale.insurer;
        }
      }

      function _selectedPreviousODInsurerChangeFn(selectedItem, sale) {
        if (selectedItem) {
          sale.previousODInsurer = selectedItem.value;
        } else {
          delete sale.previousODInsurer;
        }
      }

      function _selectedActiveTPInsurerChangeFn(selectedItem, sale) {
        if (selectedItem) {
          sale.activeTPInsurer = selectedItem.value;
        } else {
          delete sale.activeTPInsurer;
        }
      }

      /**
       * Function for open Duplicate Policy Modal
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           sale          sale
       * @param    {!Number}           _id        _id
       *
       * @author snehilmodani
       *
       */
      function openDuplicatePolicyModal(sale, _id) {
        $mdDialog.show({
          templateUrl: 'dev/mis/templates/duplicate-check.dialog.tpl.html',
          clickOutsideToClose: false,
          controller: 'DuplicateCheckDialogController'
        }).then(function (entryType) {
          if (entryType === 'DUPLICATE') {
            $state.go('mis-detail', { id: _id }, { reload: true });
          }
        });
      }

      function openDuplicatePolicyDetectedWarningModal(sale, _id) {
        $mdDialog.show({
          templateUrl: 'dev/mis/templates/duplicate-detected-warning.dialog.html',
          clickOutsideToClose: false,
          controller: 'DuplicateCheckDialogController'
        }).then(function (entryType) {
          if (entryType === 'DUPLICATE') {
            $state.go('mis-detail', { id: _id }, { reload: true });
          }
        });
      }

      /**
       * Function for open Duplicate Covernote Modal
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           sale          sale
       * @param    {!Number}           _id        _id
       *
       * @author snehilmodani
       *
       */
      function _openDuplicateCovernoteModal(sale, _id) {
        var confirm = $mdDialog.confirm().title('Covernote number already exists!').textContent('The covernote number you have entered already exists in MIS database.\nYou can create an entry with a new covernote number or take the policy with this covernote number in edit mode.').ariaLabel('Covernote number already exists!').ok('Create another entry').cancel('Go to Edit Mode');

        $mdDialog.show(confirm).then(function () {
          if ($state.current.name === 'mis-create') {
            delete sale.covernoteNo;
          } else {
            $state.go('mis-create');
          }
        }, function () {
          $state.go('mis-detail', {
            id: _id
          });
        });
      }

      /**
       * Function for check Cover note Exists
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           sale          sale
       *
       * @author snehilmodani
       *
       */
      function _checkCovernoteExists(sale) {
        var deferred = $q.defer();
        var reqUrl = '/getPolicyInfoForDuplicateCovernote';
        var reqObj = {
          covernoteNo: sale.covernoteNo,
          insurer: sale.insurer,
          productCategory: sale.productCategory
        };

        commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true).then(function (res) {
          if (res.data && res.data.statusCode) {
            if (res.data.result) {
              if (res.data.result._id !== sale.policyDetailsId) {
                deferred.resolve(res.data.result._id);
              }
            }
            deferred.reject(true);
          }
        }).catch(function () {
          deferred.reject(true);
        });
        return deferred.promise;
      }

      function _checkPolicyDuplicateCheck(sale) {
        if (sale) {
          if ((sale.productCategory || (['TW', 'FW'].includes(sale.productCategory) ? sale.vehicleType : undefined)) && sale.policyDetailsId && sale.issuanceDate && (sale.policyNumber || sale.registrationNo) && sale.policyStatus !== 'junk' && sale.recordStatus !== 'junk') {
            _callDuplicatePolicyNoCheckAPI(sale).then(function (result) {
              if (angular.element(document).find('#MIS_DUPLICATE_DIALOG').length <= 0) {
                openDuplicatePolicyModal(sale, result);
              }
            });
          }
        }
      }

      function _checkPolicyDuplicateOnRegistrationNumber(sale) {
        if (sale) {
          if ((sale.productCategory || (['TW', 'FW'].includes(sale.productCategory) ? sale.vehicleType : undefined)) && sale.policyDetailsId && sale.issuanceDate && sale.registrationNo && sale.policyStatus !== 'junk' && sale.recordStatus !== 'junk' && !sale.allowStatusOveride && !['CANCELLED', 'EXPIRED', 'REFUND'].includes(sale.policyStatus)) {
            _callduplicatePolicyRegistrationNoCheck(sale).then(function (result) {
              if (angular.element(document).find('#MIS_DUPLICATE_DETECTED_WARNING').length <= 0) {
                openDuplicatePolicyDetectedWarningModal(sale, result);
              }
            });
          }
        }
      }

      /**
      * Function called to filter Vehicle types based on selected productCategory
      *
      * @private
      *
      * @function
      *
      * @param    {!String}           policyNumber          Policy Number
      * @param    {!Object}           sale                  sale
      *
      * @author snehilmodani
      *
      */
      function _callDuplicatePolicyNoCheckAPI(sale) {
        var deferred = $q.defer();
        var reqUrl = '/getpolicydetailsforduplicatecheck';
        var reqObj = {
          productCategory: sale.productCategory,
          vehicleType: ['TW', 'FW'].includes(sale.productCategory) ? sale.vehicleType : undefined,
          policyDetailsId: sale.policyDetailsId,
          registrationNo: sale.registrationNo,
          policyNumber: sale.policyNumber,
          issuanceDate: sale.issuanceDate ? moment(sale.issuanceDate).format('x') : sale.issuanceDate,
          odRiskStartDate: sale.odRiskStartDate ? moment(sale.odRiskStartDate).format('x') : sale.odRiskStartDate,
          odRiskEndDate: sale.odRiskEndDate ? moment(sale.odRiskEndDate).format('x') : sale.odRiskEndDate,
          policyStatus: sale.policyStatus,
          productName: ['TW', 'FW'].includes(sale.productCategory) ? sale.productName : undefined
        };
        if (assignedVertical === 'CV') {
          reqObj.startDate = sale.startDate ? moment(sale.startDate).format('x') : sale.startDate;
          reqObj.endDate = sale.endDate ? moment(sale.endDate).format('x') : sale.endDate;
          delete reqObj.odRiskStartDate;
          delete reqObj.odRiskEndDate;
          delete reqObj.tpRiskStartDate;
          delete reqObj.tpRiskEndDate;
        } else {
          if (sale.multiyear) {
            reqObj.startDate = sale.startDate ? moment(sale.startDate).format('x') : sale.startDate;
            reqObj.endDate = sale.endDate ? moment(sale.endDate).format('x') : sale.endDate;
            reqObj.tpRiskStartDate = sale.tpRiskStartDate ? moment(sale.tpRiskStartDate).format('x') : sale.tpRiskStartDate;
            reqObj.tpRiskEndDate = sale.tpRiskEndDate ? moment(sale.tpRiskEndDate).format('x') : sale.tpRiskEndDate;
            reqObj.odRiskStartDate = sale.odRiskStartDate ? moment(sale.odRiskStartDate).format('x') : sale.odRiskStartDate;
            reqObj.odRiskEndDate = sale.odRiskEndDate ? moment(sale.odRiskEndDate).format('x') : sale.odRiskEndDate;
          } else {
            if (sale.productName === 'tp') {
              reqObj.startDate = undefined;
              reqObj.endDate = undefined;
              reqObj.tpRiskStartDate = sale.tpRiskStartDate ? moment(sale.tpRiskStartDate).format('x') : sale.tpRiskStartDate;
              reqObj.tpRiskEndDate = sale.tpRiskEndDate ? moment(sale.tpRiskEndDate).format('x') : sale.tpRiskEndDate;
            } else if (sale.productName === 'comprehensive') {
              reqObj.odRiskStartDate = sale.startDate ? moment(sale.startDate).format('x') : sale.startDate;
              reqObj.odRiskEndDate = sale.endDate ? moment(sale.endDate).format('x') : sale.endDate;
              reqObj.tpRiskStartDate = sale.startDate ? moment(sale.startDate).format('x') : sale.startDate;
              reqObj.tpRiskEndDate = sale.endDate ? moment(sale.endDate).format('x') : sale.endDate;
            }
          }
        }

        commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true).then(function (res) {
          if (res.data && res.data.statusCode) {
            if (res.data.result) {
              if (res.data.result._id !== sale.policyDetailsId) {
                deferred.resolve(res.data.result._id);
              }
            }

            deferred.reject(true);
          }
        }).catch(function () {
          deferred.reject(true);
        });

        return deferred.promise;
      }

      function _callduplicatePolicyRegistrationNoCheck(sale) {
        var deferred = $q.defer();
        var reqUrl = '/duplicateRegistrationNumbercheck';
        var reqObj = {
          productCategory: sale.productCategory,
          vehicleType: ['TW', 'FW'].includes(sale.productCategory) ? sale.vehicleType : undefined,
          policyDetailsId: sale.policyDetailsId,
          registrationNo: sale.registrationNo,
          issuanceDate: sale.issuanceDate ? moment(sale.issuanceDate).format('x') : sale.issuanceDate,
          startDate: sale.startDate ? moment(sale.startDate).format('x') : sale.startDate,
          endDate: sale.endDate ? moment(sale.endDate).format('x') : sale.endDate,
          policyStatus: sale.policyStatus,
          productName: ['TW', 'FW'].includes(sale.productCategory) ? sale.productName : undefined
        };
        if (sale.productName === 'od') {
          reqObj.startDate = sale.odRiskStartDate ? moment(sale.odRiskStartDate).format('x') : sale.odRiskStartDate;
          reqObj.endDate = sale.odRiskEndDate ? moment(sale.odRiskEndDate).format('x') : sale.odRiskEndDate;
        } else if (sale.productName === 'tp') {
          reqObj.startDate = sale.tpRiskStartDate ? moment(sale.tpRiskStartDate).format('x') : sale.tpRiskStartDate;
          reqObj.endDate = sale.tpRiskEndDate ? moment(sale.tpRiskEndDate).format('x') : sale.tpRiskEndDate;
        } else if (sale.multiyear) {
          reqObj.startDate = sale.odRiskStartDate ? moment(sale.odRiskStartDate).format('x') : sale.odRiskStartDate;
          _setEndDate(sale, reqObj);
        }
        commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true).then(function (res) {
          if (res.data && res.data.statusCode) {
            if (res.data.result) {
              if (res.data.result._id !== sale.policyDetailsId) {
                deferred.resolve(res.data.result._id);
              }
            }

            deferred.reject(true);
          }
        }).catch(function () {
          deferred.reject(true);
        });

        return deferred.promise;
      }

      /**
       * Function called to filter Vehicle types based on selected productCategory
       *
       * @private
       *
       * @function
       *
       * @param    {!String}           productCategory          Product Category
       *
       * @author snehilmodani
       *
       */
      function _filterVehicleTypes(productCategory) {
        saleConfig.vehicleTypes.splice(0, saleConfig.vehicleTypes.length);
        for (var idx = saleConfig.vehicleTypesAll.length - 1; idx >= 0; idx--) {
          var vehicleType = saleConfig.vehicleTypesAll[idx];
          if (!productCategory) {
            saleConfig.vehicleTypes.push(vehicleType);
          } else if (productCategory === 'FW') {
            if (currentSale.productName === 'od') {
              if (['FW'].includes(vehicleType.verticalCode)) {
                saleConfig.vehicleTypes.push(vehicleType);
              }
            } else if (['FW', 'CV'].includes(vehicleType.verticalCode)) {
              saleConfig.vehicleTypes.push(vehicleType);
            }
          } else if (vehicleType.verticalCode === productCategory) {
            saleConfig.vehicleTypes.push(vehicleType);
          }
        }

        var getLastVehicleIdx = saleConfig.vehicleTypes.length - 1;
        var vehicleTypeConfig = getConfigObjectFromCode('vehicleType');
        vehicleTypeConfig.selectConfig.options = saleConfig.vehicleTypes;
        if (saleConfig.vehicleTypes.length === 1) {
          currentSale[vehicleTypeConfig.code] = saleConfig.vehicleTypes[0][vehicleTypeConfig.selectConfig.valueKey];
          vehicleTypeConfig.initFn(currentSale[vehicleTypeConfig.code], currentSale);
        } else if (currentSale[vehicleTypeConfig.code] === undefined) {
          currentSale[vehicleTypeConfig.code] = saleConfig.vehicleTypes[getLastVehicleIdx][vehicleTypeConfig.selectConfig.valueKey];
          vehicleTypeConfig.initFn(currentSale[vehicleTypeConfig.code], currentSale);
        }
      }

      /**
       * Function called on change of Plan type
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           planType          planType
       *
       * @author sanketapte
       *
       */
      function _filterSubPlanType(planType) {
        saleConfig.subPlanTypes.splice(0, saleConfig.subPlanTypes.length);
        for (var idx = MIS_CONSTANTS.subPlanTypes.length - 1; idx >= 0; idx--) {
          var subPlanType = MIS_CONSTANTS.subPlanTypes[idx];
          if (!planType) {
            saleConfig.subPlanTypes.push(subPlanType);
          } else if (subPlanType.planTypes.includes(planType.toUpperCase())) {
            saleConfig.subPlanTypes.push(subPlanType);
          }
        }

        if (saleConfig.subPlanTypes.length === 1) {
          var subPlanTypeConfig = getConfigObjectFromCode('subPlanType');
          currentSale[subPlanTypeConfig.code] = saleConfig.subPlanTypes[0][subPlanTypeConfig.selectConfig.valueKey];
          subPlanTypeConfig.initFn(currentSale[subPlanTypeConfig.code]);
        }
      }

      function _productCategoryChangeFn(selectedItem, sale) {
        _checkPolicyDuplicateCheck(sale);
        _checkPolicyDuplicateOnRegistrationNumber(sale);
        delete sale.productUIN;
        delete sale.planName;
        delete sale.tmPlanId;

        // delete the search text for plan Name (tmPLanId field)
        var fieldConfig = getConfigObjectFromCode('tmPlanId');
        delete fieldConfig.autocompleteConfig.searchText;

        delete sale.coverType;
        delete sale.planType;
        delete sale.subPlanType;
        delete sale.optionName;
        delete sale.vehicleType;
        delete sale.vehicleSubType;
        _productCategoryInItFn(selectedItem, sale);

        var fieldConfig = getConfigObjectFromCode('insurer');
        delete fieldConfig.autocompleteConfig.searchText;

        if (selectedItem !== 'LIFE') {
          delete currentSale.lifeInsurers;
        } else {
          delete currentSale.insurer;
          _setSubStatus(sale);
        }
        currentSale.members = [];
        currentSale.nominees = [];
      }

      /**
       * Function called on change of Product Category
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function _productCategoryInItFn(selectedItem, sale) {

        if (selectedItem) {
          var showOdPolicyFields = false;
          var odPolicyFields = ['previousODPolicyNumber', 'previousODRiskStartDate', 'previousODRiskEndDate', 'previousODInsurer', 'activeTPPolicyNumber', 'activeTPRiskStartDate', 'activeTPRiskEndDate', 'activeTPInsurer'];
          if (selectedItem === 'FW') {
            getStartDateMinDate(sale);
            showOdPolicyFields = true;
            // TODO: Filter saleConfig.vehicleTypes to include only fws
            showHideField('vehicleType', false);
            markFieldRequired('vehicleType', true);
            showHideField('multiyear', false);
            _filterVehicleTypes('FW');
            showHideField('planName', true);
            showHideField('planType', true);
            showHideField('optionName', true);
            showHideField('coverType', true);
            showHideField('sumInsured', true);
            markFieldRequired('productName', true);
            showHideField('productName', false);

            showHideField('idv', false);
            showHideVehicleFields(false);
            showHideField('odPremium', false);
            showHideField('netOdPremium', false);
            showHideField('tpPremium', false);
            showHideField('basicTpPremium', false);
            markFieldRequired('carrierType', true);
            showHideField('paymentFrequency', true);
            showHideField('benefitPayoutMode', true);
            showHideField('policyTerm', true);
            showHideField('policyPremiumTerm', true);
            showHideField('category', true);
            showHideField('subPlanType', true);
            showHideField('policyPaymentType', true);
            showHideField('tmPlanId', true);
            showHideField('benefitPayoutTerm', true);
            showHideField('annualisedNetPremium', true);
            showHideField('smoker', true);
            showHideField('subStatus', true);
            showHideField('deviationOd', false);
            showHideField('deviationTp', false);
            showHideField('make', true, true);
            showHideNomineeDetails(false);
            showHideEwFields(selectedItem);
            markFieldRequired('policyProposerDob', false);
            markFieldRequired('policyProposerEmail', false);
            markFieldRequired('policyProposerMobile', false);
            showHideField('policyProposerMobileNotAvailable', false);
            showHideField('policyProposerEmailNotAvailable', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            if (isPolicyActive(sale.policyStatus)) {
              markFieldRequired('netPremium', true);
              markFieldRequired('netOdPremium', true);
              markFieldRequired('tpPremium', true);
              markFieldRequired('registrationPincode', false);
              markFieldRequired('registrationAddress', false);
              markFieldRequired('serviceTax', false);
              markFieldRequired('grossPremium', false);
              markFieldRequired('netPremium', false);
              markFieldRequired('coverType', false);
            } else {
              markFieldRequired('netPremium', false);
              markFieldRequired('netOdPremium', false);
              markFieldRequired('tpPremium', false);
            }
            showHideField('gstin', false);
            showHideField('isVehicleFinanced', false);
            showHideField('reasonForNoPACover', false);
            showHideField('inceptionDate', true);
            showHideField('vehicleRegDate', false);
            showHideField('firstTimeTaggedAsIssuedDate', true);
            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
            validateNCB(sale);
            initMinimumDocFields(sale);

            showHideBrokerSpecificField('cpa');
          } else if (selectedItem === 'TW') {
            getStartDateMinDate(sale);
            showOdPolicyFields = true;
            // TODO: Filter saleConfig.vehicleTypes to include only tw and select tw
            showHideField('vehicleSubType', false);
            showHideField('vehicleType', false);
            markFieldRequired('vehicleType', true);
            showHideField('multiyear', false);
            _filterVehicleTypes('TW');
            showHideField('planName', true);
            showHideField('planType', true);
            showHideField('optionName', true);
            showHideField('coverType', true);
            showHideField('sumInsured', true);
            markFieldRequired('productName', true);
            showHideField('productName', false);
            showHideField('idv', false);
            showHideVehicleFields(false);
            showHideField('odPremium', false);
            showHideField('netOdPremium', false);
            showHideField('tpPremium', false);
            showHideField('basicTpPremium', false);
            showHideField('carrierType', true);
            markFieldRequired('carrierType', false);
            showHideField('paymentFrequency', true);
            showHideField('benefitPayoutMode', true);
            showHideField('policyTerm', true);
            showHideField('policyPremiumTerm', true);
            markFieldRequired('policyPremiumTerm', false);
            showHideField('category', true);
            showHideField('subPlanType', true);
            showHideField('policyPaymentType', true);
            showHideField('tmPlanId', true);
            showHideField('benefitPayoutTerm', true);
            showHideField('annualisedNetPremium', true);
            showHideField('smoker', true);
            showHideField('subStatus', true);
            showHideField('deviationOd', false);
            showHideField('deviationTp', false);
            showHideField('make', true, true);
            showHideNomineeDetails(false);
            showHideEwFields(selectedItem);
            markFieldRequired('policyProposerDob', false);
            markFieldRequired('policyProposerEmail', false);
            markFieldRequired('policyProposerMobile', false);
            showHideField('policyProposerMobileNotAvailable', false);
            showHideField('policyProposerEmailNotAvailable', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            if (isPolicyActive(sale.policyStatus)) {
              markFieldRequired('netPremium', true);
              markFieldRequired('netOdPremium', true);
              markFieldRequired('tpPremium', true);
            } else {
              markFieldRequired('netPremium', false);
              markFieldRequired('netOdPremium', false);
              markFieldRequired('tpPremium', false);
            }
            showHideField('gstin', false);
            showHideField('isVehicleFinanced', false);
            showHideField('reasonForNoPACover', false);
            showHideField('inceptionDate', true);
            showHideField('vehicleRegDate', false);
            showHideField('firstTimeTaggedAsIssuedDate', true);
            initMinimumDocFields(sale);
            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
            showHideBrokerSpecificField('cpa');
          } else if (selectedItem === 'LIFE') {
            getStartDateMinDate(sale);
            showOdPolicyFields = false;
            _filterVehicleTypes();
            showHideField('vehicleSubType', true);
            showHideField('vehicleType', true);
            markFieldRequired('vehicleType', false);
            showHideField('planName', false);
            showHideField('planType', false);
            showHideField('optionName', false);
            showHideField('coverType', true);
            showHideField('sumInsured', false);
            markFieldRequired('sumInsured', false);
            markFieldRequired('productName', false);
            showHideField('productName', true);
            showHideField('idv', true);
            showHideVehicleFields(true);
            showHideField('odPremium', true);
            showHideField('netOdPremium', true);
            showHideField('tpPremium', true);
            showHideField('basicTpPremium', true);
            showHideField('carrierType', true);
            markFieldRequired('carrierType', false);
            showHideField('paymentFrequency', false);
            showHideField('benefitPayoutMode', false);
            showHideField('benefitPayoutTerm', false);
            showHideField('policyTerm', false);
            showHideField('policyPremiumTerm', false);
            markFieldRequired('policyPremiumTerm', true);
            showHideField('category', false);
            showHideField('subPlanType', false);
            showHideField('policyPaymentType', false);
            showHideField('tmPlanId', false);
            showHideField('annualisedNetPremium', false);
            showHideField('smoker', false);
            markFieldRequired('planName', true);
            showHideField('seatingCapacity', true);
            showHideField('make', true);
            showHideNomineeDetails(true);
            showHideField('multiyear', true);
            showHideField('startDate', false);
            showHideField('endDate', false);
            showHideField('odRiskStartDate', true);
            showHideField('odRiskEndDate', true);
            showHideField('tpRiskStartDate', true);
            showHideField('tpRiskEndDate', true);
            markFieldRequired('netPremium', false);
            markFieldRequired('netOdPremium', false);
            markFieldRequired('tpPremium', false);
            showHideField('deviationOd', true);
            showHideField('deviationTp', true);
            showHideEwFields(selectedItem);
            showHideField('gstin', true);
            showHideField('isVehicleFinanced', true);
            showHideField('financingInstitute', true);
            showHideField('reasonForNoPACover', true);
            markFieldRequired('policyProposerDob', true);
            markFieldRequired('policyProposerEmail', true);
            markFieldRequired('policyProposerMobile', true);
            showHideField('policyProposerMobileNotAvailable', true);
            showHideField('policyProposerEmailNotAvailable', true);
            showHideField('inceptionDate', true);
            showHideField('vehicleRegDate', true);
            showHideField('firstTimeTaggedAsIssuedDate', false);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            markFieldRequired('ncb', false);
            initMinimumDocFields(sale);
            showHideField('renewalPremiumDueDate', false);
            showHideField('cpa', true);
            if (isPolicyActive(sale.policyStatus) || sale.policyStatus === 'CANCELLED') {
              showHideField('subStatus', false);
            } else {
              showHideField('subStatus', true, false);
            }

            var planTypeConfig = getConfigObjectFromCode('planType');
            planTypeConfig.selectConfig.options = saleConfig.lifePlanTypes;
            if (!currentSale.members) {
              currentSale.members = [];
            }
            if (!currentSale.nominees) {
              currentSale.nominees = [];
            }
            setMemberFields(selectedItem);
            if (isPolicyActive(sale.policyStatus)) {
              markFieldRequired('netPremium', true);
              markFieldRequired('paymentMode', true);
              _disableStartDate(sale);
            } else {
              markFieldRequired('netPremium', false);
              markFieldRequired('paymentMode', false);
            }

            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
          } else if (selectedItem === 'HEALTH') {
            showOdPolicyFields = false;
            getStartDateMinDate(sale);
            _filterVehicleTypes();
            showHideField('vehicleSubType', true);
            showHideField('vehicleType', true);
            markFieldRequired('vehicleType', false);
            showHideField('planName', false);
            showHideField('planType', false);
            showHideField('optionName', true);
            showHideField('coverType', false);
            showHideField('sumInsured', false);
            if (saleConfig.broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
              markFieldRequired('sumInsured', true);
            }
            markFieldRequired('productName', false);
            showHideField('productName', true);
            showHideField('idv', true);
            showHideVehicleFields(true);
            showHideField('odPremium', true);
            showHideField('netOdPremium', true);
            showHideField('tpPremium', true);
            showHideField('basicTpPremium', true);
            showHideField('carrierType', true);
            markFieldRequired('carrierType', false);
            showHideBrokerSpecificField('paymentFrequency');
            showHideField('benefitPayoutMode', true);
            showHideField('benefitPayoutTerm', true);
            showHideField('policyTerm', true);
            showHideField('policyPremiumTerm', true);
            showHideField('category', true);
            showHideField('subPlanType', true);
            showHideField('policyPaymentType', true);
            showHideField('tmPlanId', false);
            showHideBrokerSpecificField('netPremium');
            showHideField('smoker', true);
            markFieldRequired('planName', true);
            showHideField('subStatus', true);
            showHideField('seatingCapacity', true);
            showHideField('make', true);
            showHideNomineeDetails(true);
            showHideField('startDate', false);
            showHideField('endDate', false);
            showHideField('multiyear', true);
            showHideField('odRiskStartDate', true);
            showHideField('odRiskEndDate', true);
            showHideField('tpRiskStartDate', true);
            showHideField('tpRiskEndDate', true);
            markFieldRequired('netPremium', false);
            markFieldRequired('netOdPremium', false);
            markFieldRequired('tpPremium', false);
            showHideEwFields(selectedItem);
            showHideField('deviationOd', true);
            showHideField('deviationTp', true);
            showHideField('gstin', true);
            showHideField('isVehicleFinanced', true);
            showHideField('financingInstitute', true);
            showHideField('reasonForNoPACover', true);
            showHideField('annualisedNetPremium', true);
            markFieldRequired('policyProposerDob', true);
            markFieldRequired('policyProposerEmail', true);
            markFieldRequired('policyProposerMobile', true);
            showHideField('policyProposerMobileNotAvailable', true);
            showHideField('policyProposerEmailNotAvailable', true);
            showHideField('inceptionDate', false);
            showHideField('vehicleRegDate', true);
            showHideField('firstTimeTaggedAsIssuedDate', false);
            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
            showHideField('cpa', true);
            markFieldRequired('ncb', false);
            initMinimumDocFields(sale);
            if (isPolicyActive(sale.policyStatus)) {
              markFieldRequired('registrationPincode', true);
              markFieldRequired('registrationAddress', true);
              markFieldRequired('serviceTax', true);
              markFieldRequired('grossPremium', true);
              markFieldRequired('netPremium', true);
              markFieldRequired('coverType', true);
            } else {
              markFieldRequired('registrationPincode', false);
              markFieldRequired('registrationAddress', false);
              markFieldRequired('serviceTax', false);
              markFieldRequired('grossPremium', false);
              markFieldRequired('netPremium', false);
              markFieldRequired('coverType', false);
            }

            var planTypeConfig = getConfigObjectFromCode('planType');
            planTypeConfig.selectConfig.options = saleConfig.healthPlanTypes;
            if (!currentSale.members) {
              currentSale.members = [];
            }
            if (!currentSale.nominees) {
              currentSale.nominees = [];
            }
            setMemberFields(selectedItem);
          } else if (selectedItem === 'DEVICE_PROTECTION') {
            showOdPolicyFields = false;
            getStartDateMinDate(sale);
            _filterVehicleTypes();
            showHideField('productType', false);
            showHideField('productPaymentMode', false);
            showHideField('productPaymentDetails', false);
            showHideField('serialNumber', false);
            showHideField('merchantId', false);
            showHideField('storeId', false);
            showHideField('manufacturerWarrantyTerm', false);

            showHideField('vehicleSubType', true);
            showHideField('vehicleType', true);
            markFieldRequired('vehicleType', false);
            showHideField('planName', true);
            showHideField('planType', false);
            showHideField('optionName', true);
            showHideField('coverType', true);
            showHideField('sumInsured', false);
            markFieldRequired('productName', false);
            showHideField('productName', true);
            showHideField('idv', true);
            showHideVehicleFields(true);
            showHideField('odPremium', true);
            showHideField('netOdPremium', true);
            showHideField('tpPremium', true);
            showHideField('basicTpPremium', true);
            showHideField('carrierType', true);
            markFieldRequired('carrierType', false);
            showHideField('paymentFrequency', true);
            showHideField('benefitPayoutMode', true);
            showHideField('benefitPayoutTerm', true);
            showHideField('policyTerm', false);
            showHideField('policyPremiumTerm', true);
            showHideField('category', true);
            showHideField('subPlanType', true);
            showHideField('tmPlanId', true);
            showHideField('policyPaymentType', true);
            showHideField('annualisedNetPremium', true);
            showHideField('smoker', true);
            markFieldRequired('planName', false);
            showHideNomineeDetails(true);
            showHideField('ncb', true);
            showHideField('seatingCapacity', true);
            showHideField('make', false);
            showHideEwFields(selectedItem);
            showHideField('gstin', true);
            showHideField('isVehicleFinanced', true);
            showHideField('financingInstitute', true);
            showHideField('reasonForNoPACover', true);
            markFieldRequired('policyProposerDob', false);
            markFieldRequired('policyProposerEmail', false);
            markFieldRequired('policyProposerMobile', false);
            showHideField('policyProposerMobileNotAvailable', false);
            showHideField('policyProposerEmailNotAvailable', false);
            showHideField('inceptionDate', true);
            showHideField('vehicleRegDate', true);
            showHideField('firstTimeTaggedAsIssuedDate', true);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
            markFieldRequired('ncb', false);
            showHideField('proposalDate', true);
            showHideField('premiumReceivedDate', true);
            showHideField('premiumPaidToInsurerDate', true);
            showHideField('proposalFormSubmittedToInsurerDate', true);
            showHideField('renewalPremiumDueDate', true);
            showHideField('renewalPremiumReceivedDate', true);
            showHideField('renewalPaymentMode', true);
            showHideField('renewalPremiumPaidToInsurerDate', true);
            showHideField('cpa', true);
            var planTypeConfig = getConfigObjectFromCode('planType');
            planTypeConfig.selectConfig.options = saleConfig.ewPlanTypes;
          } else {
            // Defualts
            showOdPolicyFields = false;
            getStartDateMinDate(sale);
            if (!['GROUP_ACCIDENT', 'VECTOR_BORNE'].includes(sale.vertical)) {
              _filterVehicleTypes();
            }
            showHideField('vehicleSubType', true);
            showHideField('vehicleType', true);
            markFieldRequired('vehicleType', false);
            showHideField('planName', false);
            showHideField('planType', true);
            showHideField('optionName', true);
            showHideField('coverType', false);
            showHideField('sumInsured', false);
            markFieldRequired('productName', false);
            showHideField('productName', true);
            showHideField('idv', true);
            showHideVehicleFields(true);
            showHideField('odPremium', true);
            showHideField('netOdPremium', true);
            showHideField('tpPremium', true);
            showHideField('basicTpPremium', true);
            showHideField('carrierType', true);
            markFieldRequired('carrierType', false);
            showHideField('paymentFrequency', true);
            showHideField('benefitPayoutMode', true);
            showHideField('benefitPayoutTerm', true);
            showHideField('policyTerm', true);
            showHideField('policyPremiumTerm', true);
            showHideField('category', true);
            showHideField('subPlanType', true);
            showHideField('tmPlanId', true);
            showHideField('policyPaymentType', true);
            showHideField('annualisedNetPremium', true);
            showHideField('smoker', true);
            markFieldRequired('planName', false);
            showHideField('subStatus', true);
            showHideNomineeDetails(false);
            showHideField('multiyear', true);
            showHideField('startDate', false);
            showHideField('endDate', false);
            showHideField('odRiskStartDate', true);
            showHideField('odRiskEndDate', true);
            showHideField('tpRiskStartDate', true);
            showHideField('tpRiskEndDate', true);
            markFieldRequired('netPremium', false);
            markFieldRequired('netOdPremium', false);
            markFieldRequired('tpPremium', false);
            showHideField('deviationOd', true);
            showHideField('deviationTp', true);
            showHideField('make', true);
            showHideEwFields(selectedItem);
            showHideField('gstin', true);
            showHideField('isVehicleFinanced', true);
            showHideField('financingInstitute', true);
            showHideField('reasonForNoPACover', true);
            markFieldRequired('policyProposerDob', false);
            markFieldRequired('policyProposerEmail', false);
            markFieldRequired('policyProposerMobile', false);
            showHideField('policyProposerMobileNotAvailable', false);
            showHideField('policyProposerEmailNotAvailable', false);
            showHideField('inceptionDate', true);
            showHideField('vehicleRegDate', true);
            showHideField('firstTimeTaggedAsIssuedDate', true);
            markFieldRequired('registrationPincode', false);
            markFieldRequired('registrationAddress', false);
            markFieldRequired('serviceTax', false);
            markFieldRequired('grossPremium', false);
            markFieldRequired('netPremium', false);
            markFieldRequired('coverType', false);
            if (sale.policyStatus === 'CANCELLED') {
              showHideField('reasonForCancellation', false);
            } else {
              showHideField('reasonForCancellation', true);
            }
            markFieldRequired('ncb', false);
            showHideField('proposalDate', true);
            showHideField('premiumReceivedDate', true);
            showHideField('premiumPaidToInsurerDate', true);
            showHideField('proposalFormSubmittedToInsurerDate', true);
            showHideField('renewalPremiumDueDate', true);
            showHideField('renewalPremiumReceivedDate', true);
            showHideField('renewalPaymentMode', true);
            showHideField('renewalPremiumPaidToInsurerDate', true);
            showHideField('cpa', true);
          }

          odPolicyFields.forEach(function (ele) {
            showHideField(ele, !showOdPolicyFields);
          });
          if (sale.insurer) {
            if (sale.covernoteNo && sale.policyStatus === MIS_CONSTANTS.POLICY_STATUS.COVERNOTE_ISSUED) {
              _checkCovernoteExists(sale).then(function (result) {
                _openDuplicateCovernoteModal(sale, result);
              });
            }
          }
        }
      }

      function setMemberFields(vertical) {
        switch (vertical) {
          case 'HEALTH':
            memberConfig.memberFields = {
              generalFields: [{
                name: 'First Name',
                code: 'fname',
                type: 'text',
                required: true
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text',
                required: true
              }, {
                name: 'DOB',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Gender',
                code: 'gender',
                type: 'select',
                selectConfig: {
                  options: saleConfig.genderList,
                  valueKey: 'code',
                  textKey: 'name'
                },
                required: true
              }, {
                name: 'Height',
                code: 'height',
                type: 'text'
              }, {
                name: 'Weight',
                code: 'weight',
                type: 'text'
              }, {
                name: 'Pre-existing disease',
                code: 'preExistingDisease',
                type: 'checkbox'
              }]
            };
            memberConfig.memberInitConfig = {
              fname: '',
              lname: '',
              relationship: '',
              dob: undefined,
              height: undefined,
              weight: undefined,
              preExistingDisease: false
            };
            memberConfig.nomineeFields = {
              generalFields: [{
                name: 'First Name',
                code: 'fname',
                type: 'text'
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text'
              }, {
                name: 'Date of Birth',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Relationship with Nominee',
                code: 'relationship',
                type: 'text',
                readonly: true
              }]
            };
            memberConfig.nomineeInitConfig = {
              fname: '',
              lname: '',
              dob: '',
              relationship: ''
            };
            break;
          case 'LIFE':
            memberConfig.memberFields = {
              generalFields: [{
                name: 'First Name',
                code: 'fname',
                type: 'text',
                required: true
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text',
                required: true
              }, {
                name: 'DOB',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Gender',
                code: 'gender',
                type: 'select',
                selectConfig: {
                  options: saleConfig.genderList,
                  valueKey: 'code',
                  textKey: 'name'
                },
                required: true
              }, {
                name: 'Height',
                code: 'height',
                type: 'text'
              }, {
                name: 'Weight',
                code: 'weight',
                type: 'text'
              }, {
                name: 'Pre-existing disease',
                code: 'preExistingDisease',
                type: 'checkbox'
              }]
            };
            memberConfig.memberInitConfig = {
              fname: '',
              lname: '',
              relationship: '',
              dob: undefined,
              height: undefined,
              weight: undefined,
              preExistingDisease: false
            };
            memberConfig.nomineeFields = {
              generalFields: [{
                name: 'Title',
                code: 'title',
                type: 'text'
              }, {
                name: 'First Name',
                code: 'fname',
                type: 'text'
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text'
              }, {
                name: 'Date of Birth',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Marital Status',
                code: 'maritalStatus',
                type: 'text'
              }, {
                name: 'Relationship with Nominee',
                code: 'relationship',
                type: 'text',
                readonly: true
              }]
            };
            memberConfig.nomineeInitConfig = {
              title: '',
              fname: '',
              lname: '',
              dob: '',
              maritalStatus: '',
              relationship: ''
            };
            break;
        }
      }

      /**
      * Function for show/hide all nominee fields
      *
      * @private
      *
      * @function
      *
      * @param    {!Object}           hide
      *
      * @author sagarmulchandani
      *
      */
      function showHideNomineeDetails(hide) {
        for (var idx = policyFields.nomineeDetails.length - 1; idx >= 0; idx--) {
          var config = policyFields.nomineeDetails[idx];
          showHideField(config.code, hide);
        }
      }

      /**
       * @private
       *
       * @function
       *
       * @param    {!Object}           productCategory          Product Category
       *
       * @author sagarmulchandani
       *
       */

      function showHideEwFields(productCategory) {
        if (productCategory === 'DEVICE_PROTECTION') {
          showHideField('applicationNumber', true);
          showHideField('covernoteNo', true);
          showHideField('covernoteIssuanceDate', true);
          showHideField('age', true);
          showHideField('policyProposerDob', true);
          showHideField('policyProposerGender', true);
          showHideField('policyIssueingBranch', true);
          showHideField('prevPolicyNumber', true);
          showHideField('prevInsurer', true);
          showHideField('prevNCB', true);
          showHideField('prevClaim', true);
          showHideField('netPremium', true);
          showHideField('serviceTax', true);
          showHideField('swachhBharatTax', true);
          showHideField('krishiKalyanTax', true);
          showHideField('discountPercentage', true);
          showHideField('paymentStatus', true);
          showHideField('paymentMode', true);
          showHideField('paymentAmount', true);
          showHideField('bankName', true);
          showHideField('paymentAccountNo', true);
          showHideField('chequeDate', true);
          showHideField('chequeNo', true);
          showHideField('deviationNet', true);
          showHideField('deviationOd', true);
          showHideField('deviationTp', true);
          showHideField('deviationFlat', true);
          showHideField('deviationRemarks', true);
          showHideField('deviationApprovedBy', true);
        } else {
          showHideField('applicationNumber', false);
          showHideField('covernoteNo', false);
          showHideField('covernoteIssuanceDate', false);
          showHideField('age', false);
          showHideField('policyProposerDob', false);
          showHideField('policyProposerGender', false);
          showHideField('policyIssueingBranch', false);
          showHideField('prevPolicyNumber', false);
          showHideField('prevInsurer', false);
          showHideField('prevNCB', false);
          showHideField('prevClaim', false);
          showHideField('netPremium', false);
          showHideField('serviceTax', false);
          showHideField('swachhBharatTax', false);
          showHideField('krishiKalyanTax', false);
          showHideField('discountPercentage', false);
          showHideField('paymentStatus', false);
          showHideField('paymentMode', false);
          showHideField('paymentAmount', false);
          showHideField('bankName', false);
          showHideField('paymentAccountNo', false);
          showHideField('chequeDate', false);
          showHideField('chequeNo', false);
          showHideField('deviationNet', false);
          showHideField('deviationOd', false);
          showHideField('deviationTp', false);
          showHideField('deviationFlat', false);
          showHideField('deviationRemarks', false);
          showHideField('deviationApprovedBy', false);

          showHideField('productType', true);
          showHideField('serialNumber', true);
          showHideField('merchantId', true);
          showHideField('storeId', true);
          showHideField('manufacturerWarrantyTerm', true);
          showHideField('productPaymentMode', true);
          showHideField('productPaymentDetails', true);
        }
      }

      function _setSubStatus(sale) {
        if (sale.productCategory === 'LIFE') {
          var subStatusConfig = getConfigObjectFromCode('subStatus');
          var selectedObj = saleConfig.policyStatuses.find(function (item) {
            return item.code === sale.policyStatus;
          });
          subStatusConfig.selectConfig.options = selectedObj && selectedObj.subStatus;

          if (isPolicyActive(sale.policyStatus)) {
            sale.subStatus = 'IN_FORCE';
            showHideField('subStatus', false);
          } else if (sale.policyStatus === 'CANCELLED') {
            sale.subStatus = null;
            showHideField('subStatus', false);
          } else {
            sale.subStatus = sale.policyStatus;
            showHideField('subStatus', true, true);
          }
        }
      }

      function clearCurrentSale() {
        currentSale = {};
        // TODO remove later
        // Reset all set maxDate and minDate for new sale
        _setMinSaleCloseDate();
        _setMaxIssuanceDate();
        _setCovernoteIssuanceDate();
      }

      function getCurrentSale() {
        var newMISData = commonService.getItemSession('mis', 'newMIS');
        if (newMISData) {
          currentSale = _extends({}, currentSale, newMISData);
          currentSale.productCategory = currentSale.vertical;
          currentSale.$$assignedProductCategory = currentSale.vertical;
          currentSale.$$productCategory = currentSale.vertical;
        }

        return currentSale;
      }

      function _initBQPData(sale) {
        if (sale.sourceOfSale) {
          sale.typeOfSale = sale.sourceOfSale.type || null;
          _typeOfSaleInitFn(sale.typeOfSale);
          _setBQPDetails(sale);
        }
      }

      function _setBQPDetails(sale) {
        delete saleConfig.bqpList;
        if (sale.sourceOfSale.status === 'CONFIRMED' && sale.typeOfSale === 'BQP' && sale.bqpInternalId) {
          var bqp = {
            branch: sale.bqpBranch,
            clientId: sale.bqpId,
            internalId: sale.bqpInternalId,
            name: sale.bqpName
          };
          saleConfig.bqpList = [bqp];
          sale.bqp = bqp;
        } else {
          var fieldConfig = getConfigObjectFromCode('bqp');
          delete fieldConfig.autocompleteConfig.searchText;
          delete sale.bqp;
          saleConfig.bqpList = [];
        }
      }

      function getBQPList(sale) {
        var reqObj = {
          dpId: sale.intermediaryLoginId,
          saleCloseDate: moment(sale.saleClosedDate).format('x'),
          vertical: sale.vertical,
          tenant: sale.tenant
        };
        CompliantService.getBQPList(reqObj).then(function (res) {
          res = res.data;
          res.forEach(function (bqp) {
            bqp.label = bqp.internalId + ' ' + bqp.name + ' | ' + bqp.branch + ' (' + bqp.quotaRemaining + ')';
          });
          saleConfig.bqpList = res;
        }).catch(function (err) {
          var errMsg = err.data ? err.data : 'Error. No BQPs found.';
          commonService.showToast(errMsg);
        });
      }

      function getRegistrationResult(selectedItem, sale) {
        var jsonData = {
          vertical: sale.productCategory,
          registrationNumber: selectedItem
        };
        if (selectedItem) {
          commonService.makeHttpCall('/registration-no', jsonData, 'POST', false, false, true).then(function (res) {
            var data = res.data;
            sale.$$selectedVertical = data.vertical;
            sale.engineNo = sale.engineNo ? sale.engineNo : data.engineno;
            sale.chassisNo = sale.chassisNo ? sale.chassisNo : data.chasisno;
            sale.communicationAddress = sale.communicationAddress ? sale.communicationAddress : data.corrAddress;
            sale.policyProposerFName = sale.policyProposerFName ? sale.policyProposerFName : data.ownerFirstName;
            sale.policyProposerLName = sale.policyProposerLName ? sale.policyProposerLName : data.ownerLastName;
            sale.prevPolicyNumber = sale.prevPolicyNumber ? sale.prevPolicyNumber : data.prePolicyNo;
            sale.manufactureYear = sale.manufactureYear ? sale.manufactureYear : data.year;
            sale.rtoCode = sale.rtoCode ? sale.rtoCode : data.rto.rtoCode;
            sale.rtoLocation = sale.rtoLocation ? sale.rtoLocation : data.rto.rtoPlateLntLoc;
            sale.registrationAddress = sale.registrationAddress ? sale.registrationAddress : data.permanentAddress;
            sale.make = sale.make ? sale.make : data.make;
            sale.model = sale.model ? sale.model : data.model;
            sale.variant = sale.variant ? sale.variant : data.displayVariant;
            sale.fuel = sale.fuel ? sale.fuel : data.fuel;
            sale.vehicleName = sale.vehicleName ? sale.vehicleName : data.make + ' ' + data.model + ' ' + data.displayVariant;
            sale.vehicleRegDate = sale.vehicleRegDate ? sale.vehicleRegDate : data.registrationDate;
            sale.makemodel = sale.makemodel ? sale.makemodel : data.make + ' ' + data.model;
            sale.cc = sale.cc ? sale.cc : data.cc;
            if (sale.variant) {
              showHideField('variant', false);
              var modelObject = {
                make: sale.make,
                model: sale.model,
                makemodel: sale.makemodel
              };
              selectedMakemodelChangeFn(modelObject, sale);
            }
            $timeout(function () {
              var makemodelConfig = getConfigObjectFromCode('makemodel');
              makemodelConfig.autocompleteConfig.searchText = sale.makemodel;
              var rtoLocationConfig = getConfigObjectFromCode('rtoLocation');
              rtoLocationConfig.autocompleteConfig.searchText = sale.rtoLocation;
            }, 400);
          });
        }
      }

      function _getVariants(sale) {
        saleConfig.variants.splice(0, saleConfig.variants.length);
        var type = sale.vertical === 'CV' ? 'CV' : FormHelperService.getAssignedVertical() || sale.vertical;
        var createdAt = sale.createdAt ? moment(sale.createdAt).format('x') : moment().format('x');
        var reqObj = {
          make: sale.make,
          model: sale.model,
          type: type,
          subtype: sale.vehicleType,
          createdAt: createdAt
        };
        var reqUrl = '/masters/getvariant';
        var promise = commonService.makeThirdPartyApiCall(TM_URL, reqUrl, reqObj, 'GET', true, true);
        promise.then(function (res) {
          saleConfig.variants.splice(0, saleConfig.variants.length);
          for (var idx = 0; idx < res.data.length; idx++) {
            var data = res.data[idx];
            saleConfig.variants.push(data);
          }
        });
      }

      function clearAutoconfig(code) {
        var fieldConfig = getConfigObjectFromCode(code);
        if (fieldConfig && fieldConfig.autocompleteConfig) {
          delete fieldConfig.autocompleteConfig.searchText;
        }
      }

      function initPotentialDuplicate(sale) {
        if (sale.$$state === 'UPDATE') {
          if (sale.policyStatus === 'POTENTIAL_DUPLICATE') {
            showHideField('allowStatusOveride', false);
          } else {
            showHideField('allowStatusOveride', true, true);
          }
        } else {
          showHideField('allowStatusOveride', true, true);
        }
      }

      function validateNCB(sale) {
        if (sale.productCategory && ['FW', 'CV', 'TW'].includes(sale.productCategory) && sale.policyStatus && ['ACTIVE', 'ENDORSED'].includes(sale.policyStatus) && ['od', 'comprehensive'].includes(sale.productName)) {
          markFieldRequired('ncb', true);
        } else {
          markFieldRequired('ncb', false);
        }
      }

      function isCreationSourceOnlineIssuance(sale) {
        if (sale.creationSource === 'onlineissuance') {
          return true;
        }
        return false;
      }

      function isBusinessTypeRenewal(model) {
        return ['RENEWAL', 'ROLLOVER', 'PORTABILITY'].includes(model.businessType);
      }

      function initMinimumDocFields(sale) {
        if (!isCreationSourceOnlineIssuance(sale) && ['FW', 'HEALTH', 'LIFE', 'TW'].includes(sale.productCategory)) {
          if (['FW', 'HEALTH', 'TW'].includes(sale.productCategory)) {
            showHideField('renewalPremiumDueDate', true);
            if (isBusinessTypeRenewal(sale)) {
              showHideField('renewalPremiumReceivedDate', false);
            } else {
              showHideField('renewalPremiumReceivedDate', true);
            }
          }
          if (isBusinessTypeRenewal(sale)) {
            showHideField('renewalPaymentMode', false);
            showHideField('renewalPremiumPaidToInsurerDate', false);
          } else {
            showHideField('renewalPaymentMode', true);
            showHideField('renewalPremiumPaidToInsurerDate', true);
          }
          showHideField('premiumReceivedDate', false);
          showHideField('premiumPaidToInsurerDate', false);
          showHideField('proposalDate', false);
          showHideField('proposalFormSubmittedToInsurerDate', false);
        } else {
          showHideField('renewalPremiumReceivedDate', true);
          showHideField('premiumReceivedDate', true);
          showHideField('premiumPaidToInsurerDate', true);
          showHideField('proposalDate', true);
          showHideField('renewalPaymentMode', true);
          showHideField('renewalPremiumPaidToInsurerDate', true);
          showHideField('proposalFormSubmittedToInsurerDate', true);
          showHideField('renewalPremiumDueDate', true);
        }
      }

      function _trimWhiteSpace(selectedItem, sale, field) {
        sale[field] = selectedItem.replace(/\ /g, '');
      }

      function _policyTermBlurFn(selectedItem, sale) {
        _autoCalculateEndDate(sale);
      }

      function _autoCalculateEndDate(sale) {
        if (sale && sale.startDate && sale.policyTerm && sale.productCategory === 'LIFE') {
          var riskStartDate = new Date(sale.startDate);
          var newDate = new Date(riskStartDate.setFullYear(riskStartDate.getFullYear() + sale.policyTerm));
          sale.endDate = new Date(riskStartDate.setDate(riskStartDate.getDate() - 1));
        }
      }

      function setDateQCDoneDate(sale) {
        sale.dataQCDoneDate = new Date();
      }

      function setJunkField(sale, selectedItem) {
        if ((sale && sale.recordStatus === 'junk' || selectedItem === 'junk') && sessionStorage.getItem('broker') === CONSTANTS_LIST.BROKER.TURTLEMINT) {
          showHideField('junkedDate', false);
          if (sale && sale.recordStatus !== 'junk' && selectedItem === 'junk') {
            setJunkDate(sale);
          }
        } else {
          showHideField('junkedDate', true);
        }
      }

      function setJunkDate(sale) {
        sale.junkedDate = new Date();
      }

      function initCPA(sale) {
        var cpa = undefined;
        if (['TW', 'CV', 'FW'].includes(sale.productCategory) && sale && sale.liabilityPremiumMap && sale.liabilityPremiumMap.hasOwnProperty(saleConfig.addOns.CPA)) {
          cpa = parseFloat(sale.liabilityPremiumMap[saleConfig.addOns.CPA]);
        }
        return cpa;
      }

      function _cpaInitFn(selectedItem, sale) {
        if (selectedItem) {
          if (sale && sale.liabilityPremiumMap) {
            sale.liabilityPremiumMap[saleConfig.addOns.CPA] = selectedItem;
          } else {
            sale.liabilityPremiumMap = {};
            sale.liabilityPremiumMap[saleConfig.addOns.CPA] = selectedItem;
          }
        } else {
          if (sale && sale.liabilityPremiumMap) {
            sale.liabilityPremiumMap[saleConfig.addOns.CPA] = undefined;
          } else {
            sale.liabilityPremiumMap = {};
            sale.liabilityPremiumMap[saleConfig.addOns.CPA] = undefined;
          }
        }
      }

      function showHideBrokerSpecificField(field) {
        if (sessionStorage.getItem('broker') === CONSTANTS_LIST.BROKER.TURTLEMINT) {
          showHideField(field, false);
        } else {
          showHideField(field, true);
        }
      }

      function _setEndDate(sale, reqObj) {
        if (sale.tpRiskEndDate > sale.odRiskEndDate) {
          reqObj.endDate = sale.tpRiskEndDate ? moment(sale.tpRiskEndDate).format('x') : sale.tpRiskEndDate;
        } else {
          reqObj.endDate = sale.odRiskEndDate ? moment(sale.odRiskEndDate).format('x') : sale.odRiskEndDate;
        }
      }

      function _inceptionDateChangeFn(selectedItem, sale) {
        _setRiskStartDate(sale);
      }

      function _setInceptionAndStartDate(sale) {
        if (sale && sale.startDate) {
          _setInceptionDate(sale);
        } else if (sale && sale.inceptionDate) {
          _setRiskStartDate(sale);
        }
      }

      function _setInceptionDate(sale) {
        if (sale.businessType === 'NEW' && sale.productCategory === "HEALTH") {
          sale.inceptionDate = sale.startDate;
        }
      }

      function _setRiskStartDate(sale) {
        if (sale.businessType === 'NEW' && sale.productCategory === "HEALTH") {
          sale.startDate = sale.inceptionDate;
        }
      }

      function getHealthInsurer() {
        var sessionInsurerList = saleConfig.healthInsurers.map(function (ele) {
          return {
            value: ele.insurerCode,
            label: ele.insurerName
          };
        });
        return sessionInsurerList;
      }

      function getStartDateMinDate(sale) {
        var startDateConfig = getConfigObjectFromCode('startDate');
        if (sale && sale.productCategory === 'LIFE') {
          startDateConfig.dateConfig.minDate = undefined;
        } else {
          startDateConfig.dateConfig.minDate = new Date();
        }
      }
    }
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {

  angular.module('brokerAdmin').provider('AutoSaleService', AutoSaleService);

  AutoSaleService.$inject = [];

  return AutoSaleService;

  // //////////////////////////////////////////////////////

  /**
   * SaleService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function AutoSaleService() {
    AutoSaleService.$inject = ['$q', 'commonService', 'MIS_CONSTANTS', '$filter', '$mdDialog', '$state', '$window', '$timeout', 'FormHelperService', '$mdToast', 'MasterFileService', 'CONSTANTS_LIST', 'ninjaCommonDataService', 'CompliantService', 'misService', 'AuthService', 'SaleService'];

    return {
      $get: AutoSaleService
    };

    // //////////////////////////////////////////////////////

    /**
     * SaleService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.SaleService#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     * @param      {Object}    MIS_CONSTANTS                  MIS Constants
     *
     * @return     {Object}    Instance of the Service
     */
    function AutoSaleService($q, commonService, MIS_CONSTANTS, $filter, $mdDialog, $state, $window, $timeout, FormHelperService, $mdToast, MasterFileService, CONSTANTS_LIST, ninjaCommonDataService, CompliantService, misService, AuthService, SaleService) {

      var currentSale = {};
      var saleConfig = {};
      saleConfig.broker = sessionStorage.getItem('broker');
      saleConfig.productCategories = angular.copy(MIS_CONSTANTS.productCategories);
      saleConfig.policyStatuses = angular.copy(MIS_CONSTANTS.policyStatuses);
      saleConfig.businessTypes = angular.copy(MIS_CONSTANTS.businessTypes);
      saleConfig.insurers = angular.copy(MIS_CONSTANTS.insurers);
      saleConfig.businessVerticals = ['Retail', 'Corporate'];
      saleConfig.channelTypes = angular.copy(MIS_CONSTANTS.channelTypes);
      saleConfig.productNames = angular.copy(MIS_CONSTANTS.productNames);
      saleConfig.productPaymentModeList = angular.copy(MIS_CONSTANTS.productPaymentModeList);
      saleConfig.paymentStatuses = ['Pending', 'Completed'];
      saleConfig.vehicleTypes = [];
      saleConfig.turtlemintTenant = [];
      if (saleConfig.broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _saleConfig$productCa;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_saleConfig$productCa = saleConfig.productCategories).push.apply(_saleConfig$productCa, _toConsumableArray(turtlemintProductCategories));
      }

      ninjaCommonDataService.getNinjaCommonData().then(function (ninjaFiltersList) {
        saleConfig.vehicleTypes = angular.copy(ninjaFiltersList.vehicleType);
        saleConfig.vehicleTypesAll = angular.copy(ninjaFiltersList.vehicleType);
      });
      getTenants();

      var basicDetailsConfig = [{
        name: 'MIS ID',
        code: 'folderNo',
        placeholder: '',
        type: 'text',
        disabled: true,
        required: false
      }, {
        name: 'Business Type',
        code: 'businessType',
        placeholder: '',
        type: 'select',
        required: true,
        selectConfig: {
          options: saleConfig.businessTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Channel Type',
        code: 'channelType',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: channelTypeChangeFn,
        initFn: channelTypeInitFn,
        selectConfig: {
          options: saleConfig.channelTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Policy Issuance Date',
        code: 'issuanceDate',
        placeholder: '',
        type: 'date',
        required: true,
        dateConfig: {
          maxDate: moment().toDate()
        }
      }];

      var preliminaryFields = [{
        name: 'Product Category',
        code: '$$vertical',
        type: 'select',
        selectConfig: {
          options: saleConfig.productCategories,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true,
        initFn: _verticalInitFn,
        changeFn: _verticalChangeFn
      }, {
        name: 'Policy Status',
        code: 'policyStatus',
        type: 'select',
        selectConfig: {
          options: saleConfig.policyStatuses,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true
      }, {
        name: 'Insurer',
        code: 'insurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        required: true,
        autocompleteConfig: {
          displayKey: 'label',
          valueKey: 'value',
          disabled: false,
          noCache: true,
          querySearch: _searchInsurerFn,
          selectedItemChange: _selectedInsurerChangeFn
        }
      }, {
        name: 'Vehicle Type',
        code: 'vehicleType',
        placeholder: '',
        type: 'select',
        required: true,
        hide: true,
        changeFn: _vehicleTypeChangeFn,
        initFn: _vehicleTypeChangeFn,
        selectConfig: {
          options: saleConfig.vehicleTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      var saleFieldsConfig = [{
        name: 'Sale Closed Date',
        code: 'saleClosedDate',
        placeholder: '',
        required: true,
        type: 'date',
        initFn: _saleClosedDateChangeFn,
        changeFn: _saleClosedDateChangeFn
      }, {
        name: 'DP Name',
        code: 'intermediaryName',
        placeholder: 'Select an Intermediary',
        type: 'autocomplete',
        hide: true,
        initFn: selectedIntermediaryInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItem: saleConfig.selectedIntermediary,
          selectedItemChange: selectedIntermediaryChangeFn,
          querySearch: searchIntermediariesFn,
          displayKey: 'dpName',
          valueKey: 'dpName',
          resultTitleKey: 'title',
          resultLabelKey: 'label',
          minLength: 2
        }
      }, {
        name: 'DP Login ID',
        code: 'intermediaryLoginId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'DP No',
        code: 'intermediaryInternalId',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'DP Level',
        code: 'intermediaryLevel',
        placeholder: '',
        type: 'text',
        hide: true,
        readonly: true,
        disabled: false
      }];

      return {
        //VARS
        currentSale: currentSale,
        preliminaryFields: preliminaryFields,
        basicDetailsConfig: basicDetailsConfig,
        saleFieldsConfig: saleFieldsConfig,

        //FUNCTIONS
        autoParsingValidation: autoParsingValidation,
        clearCurrentSale: clearCurrentSale,
        getCurrentSale: getCurrentSale,
        generateFolderNo: generateFolderNo
      };

      function autoParsingValidation(formData) {
        var reqUrl = '/ninja/auto-parsing/valid-mis';
        var reqObj = formData;

        return commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true);
      }

      function getTenants(selectedItem) {
        var returnPromise = $q.defer();
        var reqUrl = '/getmistenants';
        var reqObj = {
          broker: sessionStorage.getItem('broker'),
          channelType: selectedItem
        };
        commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, true).then(function (res) {
          // saleConfig.turtlemintTenant = res.data;
          saleConfig.turtlemintTenant.splice(0, saleConfig.turtlemintTenant.length);
          for (var idx = res.data.length - 1; idx >= 0; idx--) {
            saleConfig.turtlemintTenant.push(res.data[idx]);
          }
          if (saleConfig.turtlemintTenant.length <= 0) {
            _showHideField('tenant', true);
          } else {
            _showHideField('tenant', false);
          }
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }

      function fetchUnitManagersFn() {
        var deferred = $q.defer();
        if (currentSale.tenant && currentSale.channelType && currentSale.saleClosedDate && 'partner' !== currentSale.channelType && currentSale.channelType !== 'strategicalliance') {
          var tp = moment(currentSale.saleClosedDate).format('x');
          saleConfig.unitManagers.splice(0, saleConfig.unitManagers);
          var promise = commonService.makeHttpCall('/getpartnersinfo?tenant=' + currentSale.tenant + '&channel=' + currentSale.channelType + '&saleDate=' + tp, {}, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.status === 'SUCCESS') {
              saleConfig.unitManagers = [];

              for (var idx = 0; idx < res.data.data.length; idx++) {
                var data = res.data.data[idx];
                saleConfig.unitManagers.push(data);
              }

              deferred.resolve(saleConfig.unitManagers);
            } else {
              deferred.reject(true);
            }
          });
        }
        return deferred.promise;
      }

      function _verticalInitFn(selectedItem, model) {
        if (selectedItem) {
          if (selectedItem === 'FW') {
            _showHideField('vehicleType', false);
            _markFieldRequired('vehicleType', true);
          } else if (selectedItem === 'TW') {
            _showHideField('vehicleType', false);
            _markFieldRequired('vehicleType', true);
          } else if (selectedItem === 'LIFE') {
            _showHideField('vehicleType', true);
            _markFieldRequired('vehicleType', false);
          } else if (selectedItem === 'HEALTH') {
            _showHideField('vehicleType', true);
            _markFieldRequired('vehicleType', false);
          } else if (selectedItem === 'DEVICE_PROTECTION') {
            _showHideField('vehicleType', true);
            _markFieldRequired('vehicleType', false);
          } else {
            _showHideField('vehicleType', true);
            _markFieldRequired('vehicleType', false);
          }

          var vehicleTypeList = void 0;
          if (selectedItem === 'TW') {
            model.vehicleType = selectedItem;
            FormHelperService.setAssignedVertical(selectedItem);
            vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical([selectedItem]);
            var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
            vehicleTypeConfig.selectConfig.options = vehicleTypeList;
            _vehicleTypeChangeFn(model.vehicleType, model);
          }

          if (selectedItem === 'FW') {
            FormHelperService.setAssignedVertical('FW');
            _setVehicleType(['FW', 'CV'], model);
            _vehicleTypeChangeFn(model.vehicleType, model);
          } else if (selectedItem === 'CV') {
            FormHelperService.setAssignedVertical('CV');
            if (model.vertical === 'CV') {
              vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(['CV']);
              var _vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
              _vehicleTypeConfig.selectConfig.options = vehicleTypeList;
              model.$$vertical = 'FW';
              FormHelperService.setAssignedVertical('FW');
            }
          }
        }
      }

      function _verticalChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.vehicleType = null; // Reset Vehicle type on verticle change
          var fieldConfig = _getConfigObjectFromCode('insurer');
          delete fieldConfig.autocompleteConfig.searchText;
          delete model.insurer;
          delete model.vehicleSubType;
          delete model.$$assignedVertical;
          model.vertical = model.$$vertical;
        }
        _verticalInitFn(selectedItem, model);
      }

      function _vehicleTypeChangeFn(selectedItem, model) {
        if (selectedItem) {
          for (var idx = saleConfig.vehicleTypes.length - 1; idx >= 0; idx--) {
            var vehicleType = saleConfig.vehicleTypes[idx];
            if (vehicleType.code === selectedItem) {
              var assignedVertical = vehicleType.verticalCode;
              model.$$assignedVertical = assignedVertical;
              FormHelperService.setAssignedVertical(assignedVertical);
              break;
            }
          }
          if (selectedItem === 'CAR') {
            model.vehicleSubType = 'CAR';
          }
        }
      }

      function _setVehicleType(verticalList, model) {
        var vehicleTypeList = void 0;
        vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(verticalList);
        var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
        vehicleTypeConfig.selectConfig.options = vehicleTypeList;
        var selectConfig = vehicleTypeConfig.selectConfig;
        var foundFlag = false;
        for (var idx = 0; idx < selectConfig.options.length; idx++) {
          var option = selectConfig.valueKey ? selectConfig.options[idx][selectConfig.valueKey] : selectConfig.options[idx];
          if (option === model.vehicleType) {
            foundFlag = true;
          }
        }
        if (!foundFlag) {
          model.vehicleType = 'CAR';
        }
      }

      function _showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        if (fieldConfig) {
          fieldConfig.hide = toHide;
          if (toHide && !dontDelete) {
            delete currentSale[fieldConfig.code];
          }
        }
      }

      function _markFieldRequired(fieldCode, required) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;
        fieldConfig.required = toRequired;
      }

      function _searchInsurerFn(searchKey, model, config) {
        var deferred = $q.defer();
        var insurers = [];
        if (model.vertical && model.vertical === 'HEALTH') {
          insurers = angular.copy(SaleService.getHealthInsurer());
        } else {
          insurers = angular.copy(MIS_CONSTANTS.insurers);
          if (model.vertical && model.vertical === 'LIFE') {
            insurers = angular.copy(MIS_CONSTANTS.lifeInsurers);
          }
        }
        if (!insurers || insurers && insurers.length < 1) {
          $timeout(function () {
            delete config.autocompleteConfig.searchText;
          }, 2000);
        }
        if (searchKey) {
          var results = $filter('filter')(insurers, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(insurers);
        }
        return deferred.promise;
      }

      function _selectedInsurerChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.insurer = selectedItem.value;
        } else {
          delete model.insurer;
        }
      }

      function _getConfigObjectFromCode(code) {

        for (var idx = preliminaryFields.length - 1; idx >= 0; idx--) {
          var config = preliminaryFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var _idx = basicDetailsConfig.length - 1; _idx >= 0; _idx--) {
          var _config = basicDetailsConfig[_idx];
          if (_config.code === code) {
            return _config;
          }
        }
        for (var _idx2 = saleFieldsConfig.length - 1; _idx2 >= 0; _idx2--) {
          var _config2 = saleFieldsConfig[_idx2];
          if (_config2.code === code) {
            return _config2;
          }
        }
        return {};
      }

      function _saleClosedDateChangeFn() {
        fetchUnitManagersFn();
      }

      function channelTypeChangeFn(selectedItem, model) {
        getTenants(selectedItem);
        if (selectedItem) {
          clearSalesFields();
          if (selectedItem === 'partner') {
            _showHideField('intermediaryName', false);
            _showHideField('intermediaryLoginId', false);
            _showHideField('intermediaryInternalId', false);
            _showHideField('salesExecutive', false);
            _showHideField('sum', false);
            _showHideField('mp', false);
            _showHideField('tl', false);
            _showHideField('dm', false);
            _showHideField('ssm', false);
            _showHideField('ah', false);
            _showHideField('sah', false);
            _showHideField('srm', false);
            _showHideField('um', false);
            _showHideField('sm', false);
            _showHideField('am', false);
            _showHideField('rm', false);
            _showHideField('zm', false);
            _showHideField('nationalHead', false);
            _showHideField('dpBranchLocation', false);
            _showHideField('dpState', false);
            _showHideField('dpRegion', false);
            _showHideField('intermediaryLevel', false);
            _showHideField('parentIntermediaryName', false);
            _showHideField('parentIntermediaryLoginId', false);
            _showHideField('parentIntermediaryInternalId', false);
            _showHideField('parentIntermediaryLevel', false);

            _markFieldRequired('intermediaryName', true);
            _markFieldRequired('intermediaryLoginId', true);
            _markFieldRequired('intermediaryInternalId', true);

            $timeout(function () {
              var intermediaryName = _getConfigObjectFromCode('intermediaryName');
              intermediaryName.type = 'autocomplete';
              intermediaryName.readonly = false;
            }, 10);
          } else if (selectedItem === 'strategicalliance') {
            _showHideField('salesExecutive', true);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('tl', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);
            _showHideField('rm', true);
            _showHideField('um', false);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', true);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('dpRegion', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);
          } else if (selectedItem === 'website' || selectedItem === 'referral') {
            _showHideField('rm', false);
            _showHideField('um', false);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('salesExecutive', false);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('tl', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', true);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('dpRegion', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);

            if (model.tenant) {
              fetchUnitManagersFn();
            }
          } else {
            _showHideField('salesExecutive', true);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('tl', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);

            _showHideField('rm', true);
            _showHideField('um', true);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', true);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('dpRegion', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);
          }
        }
      }

      function channelTypeInitFn(selectedItem, model) {
        getTenants(selectedItem);

        if (selectedItem) {
          if (selectedItem === 'partner') {
            _showHideField('intermediaryName', false);
            _showHideField('intermediaryLoginId', false);
            _showHideField('intermediaryInternalId', false);
            _showHideField('salesExecutive', false);
            _showHideField('sum', false);
            _showHideField('mp', false);
            _showHideField('tl', false);
            _showHideField('dm', false);
            _showHideField('ssm', false);
            _showHideField('ah', false);
            _showHideField('sah', false);
            _showHideField('srm', false);
            _showHideField('um', false);
            _showHideField('sm', false);
            _showHideField('am', false);
            _showHideField('rm', false);
            _showHideField('zm', false);
            _showHideField('nationalHead', false);
            _showHideField('dpBranchLocation', false);
            _showHideField('dpState', false);
            _showHideField('dpRegion', false);
            _showHideField('intermediaryLevel', false);
            _showHideField('parentIntermediaryName', false);
            _showHideField('parentIntermediaryLoginId', false);
            _showHideField('parentIntermediaryInternalId', false);
            _showHideField('parentIntermediaryLevel', false);

            _markFieldRequired('intermediaryName', true);
            _markFieldRequired('intermediaryLoginId', true);
            _markFieldRequired('intermediaryInternalId', true);

            $timeout(function () {
              var intermediaryName = _getConfigObjectFromCode('intermediaryName');
              var intermediaryLoginId = _getConfigObjectFromCode('intermediaryLoginId');
              var dpNo = _getConfigObjectFromCode('intermediaryInternalId');
              intermediaryName.type = 'autocomplete';
              intermediaryName.readonly = false;
              intermediaryLoginId.readonly = true;
              dpNo.readonly = true;
            }, 10);
          } else if (selectedItem === 'strategicalliance') {
            _showHideField('salesExecutive', true);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('tl', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);
            _showHideField('rm', true);
            _showHideField('um', false);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', false);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('dpRegion', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);
          } else if (selectedItem === 'website' || selectedItem === 'referral') {
            _showHideField('rm', false);
            _showHideField('um', false);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('salesExecutive', false);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', true);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('dpRegion', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);

            if (model.tenant) {
              fetchUnitManagersFn();
            }
          } else {
            _showHideField('salesExecutive', true);
            _showHideField('sum', true);
            _showHideField('mp', true);
            _showHideField('tl', true);
            _showHideField('dm', true);
            _showHideField('ssm', true);
            _showHideField('ah', true);
            _showHideField('sah', true);
            _showHideField('srm', true);

            _showHideField('rm', true);
            _showHideField('um', true);
            _showHideField('intermediaryName', true);
            _showHideField('intermediaryLoginId', true);
            _showHideField('intermediaryInternalId', true);
            _showHideField('sm', true);
            _showHideField('am', true);
            _showHideField('zm', true);
            _showHideField('nationalHead', true);
            _showHideField('dpBranchLocation', true);
            _showHideField('dpState', true);
            _showHideField('intermediaryLevel', true);
            _showHideField('parentIntermediaryName', true);
            _showHideField('parentIntermediaryLoginId', true);
            _showHideField('parentIntermediaryInternalId', true);
            _showHideField('parentIntermediaryLevel', true);

            _markFieldRequired('intermediaryName', false);
            _markFieldRequired('intermediaryLoginId', false);
            _markFieldRequired('intermediaryInternalId', false);
          }
        }
      }

      function clearCurrentSale() {
        currentSale = {};
        // TODO remove later
        // Reset all set maxDate and minDate for new sale
      }

      function getCurrentSale() {
        return currentSale;
      }

      function generateFolderNo() {
        clearCurrentSale();
        var returnPromise = $q.defer();
        var promise = commonService.makeHttpCall('/generatepolicydetailid', {}, 'GET', false, false, true);
        promise.then(function (res) {
          if (res.data && res.data.statusCode && res.data.statusCode === 204) {
            returnPromise.resolve(res);
          }
          if (res.data && res.data.statusCode && res.data.statusCode === 200) {
            currentSale.policyDetailsId = res.data.data;
            currentSale.$$state = 'NEW';
            currentSale.folderNo = res.data.data;
            currentSale.businessVertical = 'Retail';
            currentSale.$$attachedDocuments = [];
            currentSale.tenant = sessionStorage.getItem('tenant');
            currentSale.$$fullAccess = !AuthService.isMISChecker();
            var newMISData = commonService.getItemSession('mis', 'newMIS');
            if (newMISData) {
              currentSale = _extends({}, currentSale, newMISData);
            }
            currentSale.productCategory = currentSale.vertical;
            currentSale.$$assignedProductCategory = currentSale.vertical;
            currentSale.$$productCategory = currentSale.vertical;
          }
          returnPromise.resolve(currentSale);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });
        return returnPromise.promise;
      }

      function selectedIntermediaryInitFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: model.intermediaryLoginId,
            vertical: model.productCategory
          };
          FormHelperService.isACertifedPartner(formData).then(function (res) {
            var isCertified = res.data.data.allowedToDoBusiness;
            if (isCertified === false) {
              clearPartnerData(currentSale);
              commonService.showToast('Entered partner detail is not a certified partner. Please select or add certified partner');
            }
          }).catch(function () /*err*/{
            clearPartnerData(currentSale);
            commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
          });
        } else {
          clearPartnerData(currentSale);
        }
      }

      function selectedIntermediaryChangeFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: selectedItem.dpLoginId,
            vertical: model.productCategory
          };
          FormHelperService.isACertifedPartner(formData).then(function (res) {
            var isCertified = res.data.data.allowedToDoBusiness;
            if (isCertified === true) {
              currentSale.intermediaryInternalId = selectedItem.dpNo;
              currentSale.intermediaryLoginId = selectedItem.dpLoginId;
              currentSale.tlName = selectedItem.teamLeader;
              currentSale.intermediaryName = selectedItem.dpName;
              currentSale.intermediaryEmail = selectedItem.email;
              currentSale.intermediaryMobile = selectedItem.mobile;

              if (selectedItem.salesExec) {
                currentSale.salesExecutive = selectedItem.salesExec.name;
                currentSale.salesExecutiveId = selectedItem.salesExec.id;
              }

              if (selectedItem.um) {
                currentSale.um = selectedItem.um.name;
                currentSale.umId = selectedItem.um.id;
              }

              if (selectedItem.sum) {
                currentSale.sum = selectedItem.sum.name;
                currentSale.sumId = selectedItem.sum.id;
              }

              if (selectedItem.tl) {
                currentSale.tl = selectedItem.tl.name;
                currentSale.tlId = selectedItem.tl.id;
              }

              if (selectedItem.mp) {
                currentSale.mp = selectedItem.mp.name;
                currentSale.mpId = selectedItem.mp.id;
              }

              if (selectedItem.dm) {
                currentSale.dm = selectedItem.dm.name;
                currentSale.dmId = selectedItem.dm.id;
              }

              if (selectedItem.sm) {
                currentSale.sm = selectedItem.sm.name;
                currentSale.smId = selectedItem.sm.id;
              }

              if (selectedItem.ssm) {
                currentSale.ssm = selectedItem.ssm.name;
                currentSale.ssmId = selectedItem.ssm.id;
              }

              if (selectedItem.am) {
                currentSale.am = selectedItem.am.name;
                currentSale.amId = selectedItem.am.id;
              }

              if (selectedItem.ah) {
                currentSale.ah = selectedItem.ah.name;
                currentSale.ahId = selectedItem.ah.id;
              }

              if (selectedItem.sah) {
                currentSale.sah = selectedItem.sah.name;
                currentSale.sahId = selectedItem.sah.id;
              }

              if (selectedItem.rm) {
                currentSale.rm = selectedItem.rm.name;
                currentSale.rmId = selectedItem.rm.id;
              }

              if (selectedItem.srm) {
                currentSale.srm = selectedItem.srm.name;
                currentSale.srmId = selectedItem.srm.id;
              }

              if (selectedItem.zm) {
                currentSale.zm = selectedItem.zm.name;
                currentSale.zmId = selectedItem.zm.id;
              }

              if (selectedItem.nationalHead) {
                currentSale.nationalHead = selectedItem.nationalHead.name;
                currentSale.nationalHeadId = selectedItem.nationalHead.id;
              }
              currentSale.dpBranchLocation = selectedItem.branchLocation;
              currentSale.dpState = selectedItem.state;
              currentSale.dpRegion = selectedItem.region;
              currentSale.intermediaryLevel = selectedItem.intermediaryLevel;
              currentSale.parentIntermediaryName = selectedItem.parentIntermediaryName;
              currentSale.parentIntermediaryLoginId = selectedItem.parentIntermediaryLoginId;
              currentSale.parentIntermediaryInternalId = selectedItem.parentIntermediaryInternalId;
              currentSale.parentIntermediaryLevel = selectedItem.parentIntermediaryLevel;
            } else {
              clearPartnerData(currentSale);
              window.alert('Entered partner detail is not a certified partner. please select or add certified partner');
            }
          }).catch(function () /*err*/{
            clearPartnerData(currentSale);
            commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
          });
        } else {
          clearPartnerData(currentSale);
        }
      }

      function searchIntermediariesFn(searchKey, model) {
        var deferred = $q.defer();

        if (searchKey && currentSale.tenant && currentSale.channelType && currentSale.saleClosedDate && currentSale.channelType !== 'strategicalliance') {
          var tp = moment(currentSale.saleClosedDate).format('x');
          var promise = commonService.makeHttpCall('/getpartnersinfo?query=' + searchKey + '&tenant=' + currentSale.tenant + '&channel=' + currentSale.channelType + '&saleDate=' + tp, {}, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.status === 'SUCCESS') {
              saleConfig.intermediaries = res.data.data || [];
              deferred.resolve(saleConfig.intermediaries);
            } else {
              clearPartnerData(model);
              deferred.reject(true);
            }
          });
        } else {
          clearPartnerData(model);
          deferred.reject(true);
        }

        return deferred.promise;
      }

      function clearPartnerData(model) {
        if (model.intermediaryInternalId) {
          delete model.intermediaryInternalId;
          delete model.intermediaryLoginId;
          delete model.tlName;
          delete model.intermediaryName;
          delete model.salesExecutive;
          delete model.um;
          delete model.sum;
          delete model.tl;
          delete model.mp;
          delete model.dm;
          delete model.sm;
          delete model.ssm;
          delete model.am;
          delete model.ah;
          delete model.sah;
          delete model.rm;
          delete model.srm;
          delete model.zm;
          delete model.nationalHead;
          delete model.dpBranchLocation;
          delete model.dpState;
          delete model.dpRegion;
          delete model.intermediaryLevel;
          delete model.parentIntermediaryName;
          delete model.parentIntermediaryLoginId;
          delete model.parentIntermediaryInternalId;
          delete model.parentIntermediaryLevel;
          delete model.umId;
          delete model.sumId;
          delete model.tlId;
          delete model.mpId;
          delete model.smId;
          delete model.ssmId;
          delete model.amId;
          delete model.ahId;
          delete model.sahId;
          delete model.rmId;
          delete model.srmId;
          delete model.zmId;
          delete model.nationalHeadId;
        }
      }

      function clearSalesFields() {
        var intermediaryNameFieldConfig = _getConfigObjectFromCode('intermediaryName');
        if (intermediaryNameFieldConfig.autocompleteConfig) {
          delete intermediaryNameFieldConfig.autocompleteConfig.searchText;
          delete intermediaryNameFieldConfig.autocompleteConfig.selectedItem;
        }

        delete currentSale.intermediaryName;
        delete currentSale.intermediaryLoginId;
        delete currentSale.intermediaryInternalId;
        var umFieldConfig = _getConfigObjectFromCode('um');
        if (umFieldConfig.autocompleteConfig) {
          delete umFieldConfig.autocompleteConfig.searchText;
        }
        delete currentSale.salesExecutive;
        delete currentSale.um;
        delete currentSale.sum;
        delete currentSale.mp;
        delete currentSale.tl;
        delete currentSale.dm;
        delete currentSale.sm;
        delete currentSale.ssm;
        delete currentSale.am;
        delete currentSale.ah;
        delete currentSale.sah;
        delete currentSale.rm;
        delete currentSale.srm;
        delete currentSale.zm;
        delete currentSale.nationalHead;
        delete currentSale.dpBranchLocation;
        delete currentSale.dpState;
        delete currentSale.dpRegion;
        delete currentSale.intermediaryLevel;
        delete currentSale.parentIntermediaryName;
        delete currentSale.parentIntermediaryLoginId;
        delete currentSale.parentIntermediaryInternalId;
        delete currentSale.parentIntermediaryLevel;
      }
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.mis').provider('MISInsightsService', MISInsightsService);

  MISInsightsService.$inject = [];

  return MISInsightsService;

  // ////////////////////////////////////////////////////// MISInsightsService

  /**
   * MISInsightsService
   *
   * @public
   *
   * @author    beerpratapsingh
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */

  function MISInsightsService() {

    MISInsightsService.$inject = ['$q', 'commonService'];

    return {
      $get: MISInsightsService
    };
    // //////////////////////////////////////////////////////
    /**
     * MISInsightsService
     * @public
     * @memberof   module:tutlemintAdmin.mis#
     * @author     beerpratapsingh
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     *
     * @return     {Object}    Instance of the Service
     */
    function MISInsightsService($q, commonService) {

      return {
        //Functions
        getMISDetails: getMISDetails
      };

      function getMISDetails(jsonData) {
        var returnPromise = $q.defer();
        var reqUrl = '/getmisinsights';
        commonService.makeHttpCall(reqUrl, jsonData, 'POST', false, false, true).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.mis').provider('paymentScheduleService', paymentScheduleService);

  // //////////////////////////////////////////////////////

  /**
   * paymentScheduleService
   *
   * @public
   *
   * @author    sagarmulchandani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function paymentScheduleService() {

    paymentScheduleService.$inject = ['commonService', '$q'];

    return {
      $get: paymentScheduleService
    };

    // //////////////////////////////////////////////////////

    /**
     * paymentScheduleService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin.mis#
     *
     * @author     sagarmulchandani
     *
     * @return     {Object}    Instance of the Service
     */
    function paymentScheduleService(commonService, $q) {
      var schedule = {};
      var scheduleEntry = {};

      var scheduleConfig = [{
        name: 'Payment Type',
        code: 'type',
        type: 'text',
        disabled: true,
        required: true,
        initFn: _typeChangeFn
      }, {
        name: 'Frequency',
        code: 'paymentFrequency',
        type: 'select',
        disabled: true,
        selectConfig: {
          textKey: 'name',
          valueKey: 'code'
        }
      }, {
        name: 'Due Date',
        code: 'paymentDueDate',
        type: 'date',
        disabled: true,
        required: true
      }, {
        name: 'Net Premium',
        code: 'netPremium',
        type: 'number',
        min: 0,
        required: true,
        disabled: true
      }, {
        name: 'Payment Status',
        code: 'paymentStatus',
        type: 'select',
        required: true,
        selectConfig: {
          textKey: 'name',
          valueKey: 'code'
        },
        initFn: paymentStatusChangeFn,
        changeFn: paymentStatusChangeFn
      }, {
        name: 'Paid Date',
        code: 'actualPaymentDate',
        type: 'date'
      }, {
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'select',
        selectConfig: {
          textKey: 'name',
          valueKey: 'code'
        }
      }, {
        name: 'Premium Paid',
        code: 'paidPremium',
        type: 'number',
        min: 0,
        required: true,
        hide: true
      }, {
        name: 'Interest Amount',
        code: 'interestAmount',
        type: 'number',
        min: 0
      }, {
        name: 'Revival Amount',
        code: 'revivalAmount',
        type: 'number',
        min: 0
      }, {
        name: 'Remarks',
        code: 'remarks',
        type: 'textarea'
      }];

      function _typeChangeFn(selectedItem) {
        if (selectedItem) {
          if (selectedItem === 'REGULAR') {
            _showHideField('paymentDueDate', false);
            _showHideField('netPremium', false);
            _showHideField('interestAmount', false);
            _showHideField('revivalAmount', false);
            _showHideField('paymentFrequency', false);
            _showHideField('paidPremium', true, true);
          } else if (selectedItem === 'TOPUP') {
            _showHideField('paymentDueDate', true, true);
            _showHideField('netPremium', true, true);
            _showHideField('interestAmount', true, true);
            _showHideField('revivalAmount', true, true);
            _showHideField('paymentFrequency', true, true);
            _showHideField('paidPremium', false);
          }
        }
      }

      function paymentStatusChangeFn(selectedItem) {
        if (selectedItem) {
          if (selectedItem === 'PAID') {
            _markFieldRequired('paymentMode', true);
            _markFieldRequired('actualPaymentDate', true);
          } else {
            _markFieldRequired('paymentMode', false);
            _markFieldRequired('actualPaymentDate', false);
          }
        }
      }

      /**
       * Function called to Hide Fields
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           hide          to hide fields or not
       *
       * @author sagarmulchandani
       *
       */
      function _showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        if (fieldConfig) {
          fieldConfig.hide = toHide;
          if (toHide && !dontDelete) {
            delete scheduleEntry[fieldConfig.code];
          }
        }
      }

      /**
       * Function called to Mark field required
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           required      to mark fields required or not
       *
       * @author sagarmulchandani
       *
       */
      function _markFieldRequired(fieldCode, required) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;

        fieldConfig.required = toRequired;
      }

      /**
       * Returns config of form field by using code
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           code          Code of the form field
       *
       * @author sagarmulchandani
       *
       * @return   {Object}           Config object of form field
       *
       */
      function _getConfigObjectFromCode(code) {
        for (var idx = scheduleConfig.length - 1; idx >= 0; idx--) {
          var config = scheduleConfig[idx];
          if (config.code === code) {
            return config;
          }
        }
        return {};
      }

      return {
        //Functions
        getScheduleConfig: getScheduleConfig,
        getPaymentSchedule: getPaymentSchedule,
        updatePaymentSchedule: updatePaymentSchedule
      };

      /**
       * getPaymentSchedule function
       *
       * @public
       *
       * @function
       *
       * @author sagarmulchandani
       *
       */
      function getScheduleConfig(entry, lists) {
        scheduleEntry = entry;
        var paymentStatusConfig = _getConfigObjectFromCode('customerPaymentStatus');
        paymentStatusConfig.selectConfig.options = lists.paymentStatusList;
        var paymentFrequencyConfig = _getConfigObjectFromCode('paymentFrequency');
        paymentFrequencyConfig.selectConfig.options = lists.paymentFrequencyList;
        var paymentModeConfig = _getConfigObjectFromCode('paymentMode');
        paymentModeConfig.selectConfig.options = lists.paymentModesList;

        return scheduleConfig;
      }

      // //////////////////////////////////////////////////////
      /**
       * getPaymentSchedule function
       *
       * @public
       *
       * @function
       *
       * @author sagarmulchandani
       *
       */
      function getPaymentSchedule(policyDetailId) {
        var returnPromise = $q.defer();
        var reqUrl = '/schedule/payment/' + policyDetailId;
        commonService.makeHttpCall(reqUrl, {}, 'GET', false).then(function (res) {
          var data = res.data.data;
          schedule = data;
          returnPromise.resolve(schedule);
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }

      /**
       * updatePaymentSchedule function
       *
       * @public
       *
       * @function
       *
       * @author sagarmulchandani
       *
       */
      function updatePaymentSchedule(reqObj) {
        var reqUrl = '/schedule/payment/update';
        var reqObj = {
          data: reqObj
        };
        return commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, false);
      }
    }
  }
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('misHomeCtrl', controller);

  controller.$inject = ['$rootScope', '$scope', '$mdDialog', 'commonService', 'misService', 'MISInsightsService', 'MIS_CONSTANTS', '$state', '$window', 'AuthService', '$q', '$timeout', '$mdSelect', 'GENERAL_CONFIG', '$cookies', 'CONSTANTS_LIST', 'TrackingService', 'ninjaCommonDataService'];

  /**
   * Date Range Modal Controller
   *
   * @author snehilmodani
   *
   * @param    {!Object}           $scope           $scope
   * @param    {!Object}           commonService    commonService
   * @param    {!Object}           misService       misService
   * @param    {!Object}           MIS_CONSTANTS    MIS_CONSTANTS
   * @param    {!Object}           $state           $state
   * @param    {!Object}           $window          $window
   * @param    {!Object}           AuthService      AuthService
   * @param    {!Object}           $q               $q
   * @param    {!Object}           $timeout         $timeout
   * @param    {!Object}           $mdDialog        $mdDialog
   *
   */
  function controller($rootScope, $scope, $mdDialog, commonService, misService, MISInsightsService, MIS_CONSTANTS, $state, $window, AuthService, $q, $timeout, $mdSelect, GENERAL_CONFIG, $cookies, CONSTANTS_LIST, TrackingService, ninjaCommonDataService) {
    var ViewModel = $scope;
    var broker = sessionStorage.getItem('broker');
    var features = commonService.getItemSession('features', 'mis');
    var columnFilters = commonService.getItemSession('records', 'filters') || {};
    var eventPrefix = 'MIS_HOME';

    ninjaCommonDataService.getNinjaCommonData().then(function (ninjaFiltersList) {
      ViewModel.sourceOfSaleStatusList = angular.copy(ninjaFiltersList.sourceOfSaleStatus);
      ViewModel.businessChannelList = angular.copy(ninjaFiltersList.businessChannel);
      ViewModel.callStatusList = angular.copy(ninjaFiltersList.preSaleVerificationStatus);
    });

    var originalParams = {
      //searchkey: undefined,
      limit: 5,
      page: 1,
      filters: []
    };

    var filterNamesMapping = {
      'creation_source': 'selectedCreationSource',
      'channel_type': 'selectedChannelType',
      'product_category': 'selectedProductCategory',
      'insurer': 'selectedInsurer',
      'policy_status': 'selectedPolicyStatus',
      'pre_sale_verification_status': 'selectedCallStatus',
      'source_of_sale_status': 'selectedSourceOfSaleStatus',
      'salesDetail.business_channel': 'selectedBusinessChannel'
    };

    ViewModel.bulkExport = bulkExport;
    ViewModel.navigateToEdit = navigateToEdit;
    ViewModel.productCategoryDisplay = productCategoryDisplay;
    ViewModel.getDetailsForBulkExport = getDetailsForBulkExport;
    ViewModel.getTaskList = getTaskList;
    ViewModel.getFilteredData = getFilteredData;
    ViewModel.hideSearchResultData = hideSearchResultData;
    ViewModel.goTo = goTo;
    ViewModel.applyFilter = applyFilter;
    ViewModel.clearFilter = clearFilter;
    ViewModel.clearCalendarFilter = clearCalendarFilter;
    ViewModel.filterTxtBold = filterTxtBold;
    ViewModel.stopEventPropagation = commonService.stopEventPropagation;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.columnFilters = columnFilters;
    ViewModel.autoParsingStatusList = CONSTANTS_LIST.autoParsingStatuses;
    ViewModel.allowAutoPdfCollumn = broker === 'turtlemint' ? true : false;
    ViewModel.isTurtlemintBroker = broker === 'turtlemint' ? true : false;

    ViewModel.init = init;
    ViewModel.displayPolicyStatus = displayPolicyStatus;
    var pageType = $state.current.pageType;
    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function init() {
      ViewModel.fullAccess = !AuthService.isMISChecker();
      ViewModel.navbarConfig = {
        vertical: 'mis',
        title: 'MIS',
        search: {
          enabled: true,
          searchFn: searchRecords,
          placeholder: 'Search by policy no or registration no (min. 4 char)'
        },
        actions: [{
          type: 'icon',
          class: '',
          action: addNewSale,
          permissionCode: 'mis-create',
          name: 'Add New Sale',
          iconSrc: '/images/common/plus-icon.svg',
          dataAuto: 'new-sale-button'
        }]
      };

      ViewModel.options = {
        pageLimits: [5, 10, 15, 20, 25, 50]
      };
      ViewModel.showSOSStatus = broker === CONSTANTS_LIST.BROKER.TURTLEMINT && features && features.sourceOfSale ? true : false;
      setFilterLists();

      ViewModel.params = angular.copy(originalParams);
      $scope.disableExport = false;
      //ViewModel.newParams = angular.copy(originalParams);
      if (sessionStorage.getItem(pageType + 'localFilters')) {
        ViewModel.params = JSON.parse(sessionStorage.getItem(pageType + 'localFilters'));
        //ViewModel.newParams = JSON.parse(sessionStorage.getItem(pageType + 'localFilters'));
        if (ViewModel.params.filters.length > 0) {
          ViewModel.params.filters.forEach(function (filter) {
            if (filter.values !== undefined) {
              ViewModel[filterNamesMapping[filter.code]] = filter.values;
            }
          });
        }
      }

      ViewModel.chartOptions = {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              stacked: true,
              beginAtZero: true
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      };
      $scope.datasetOverride = [{
        label: 'Bar chart',
        borderWidth: 2,
        backgroundColor: 'rgba(255,86,82,1)',
        borderColor: 'rgba(255,99,132,1)',
        hoverBackgroundColor: 'rgba(255,86,82,0.8)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        type: 'horizontalBar'
      }];

      ViewModel.totalCount = 0;

      getMISMetrics($state.current.pageType);

      if (AuthService.hasPermission('module-mis')) {
        getRecords();
      }

      commonService.removeItemSession('policy');

      //Get Insure List for filter
      commonService.getInsurerList().then(function (insurersList) {
        ViewModel.insurersList = insurersList;
      });

      if ($state.current) {
        var currentPageType = $state.current.pageType;
        ViewModel.hideChart = false;
        ViewModel.hideAllRecords = false;
        if (currentPageType === 'INCOMPLETE') {
          ViewModel.currentNavItem = 'MY INCOMPLETE';
          ViewModel.pageTitle = 'My Incomplete Records';
          ViewModel.hideChart = true;
        } else if (currentPageType === 'UNASSIGNED') {
          ViewModel.currentNavItem = 'UNASSIGNED';
          ViewModel.pageTitle = 'Unassigned Records';
          ViewModel.hideChart = true;
        } else if (currentPageType === 'ALLRECORDS') {
          ViewModel.currentNavItem = 'ALL RECORDS';
          $timeout(function () {
            $('#misNav .search').focus();
          }, 200);
          ViewModel.pageTitle = 'All MIS Records';
          ViewModel.hideChart = !ViewModel.fullAccess;
          ViewModel.hideAllRecords = ViewModel.fullAccess;
        } else if (currentPageType === 'PAYOUTREADYRECORDS') {
          ViewModel.currentNavItem = 'PAYOUT RECORDS';
          ViewModel.pageTitle = 'Payout Records';
          ViewModel.hideChart = true;
        }
      }

      setBrokerFields();
    }

    function searchRecords(searchKey) {
      if (searchKey) {
        searchKey = searchKey.trim();
        if (searchKey.length >= 4) {
          getRecords(searchKey);
          TrackingService.pushToGTMDatalayer('MIS_SEARCH', 'track_event', {});
        } else {
          commonService.showToast('Min. 4 characters required for search');
        }
      }
    }

    function setBrokerFields() {
      ViewModel.showBusinessChannel = false;

      var ewProductCategory = angular.copy(MIS_CONSTANTS.ewProductCategory);
      var dukandaarProductCategory = angular.copy(MIS_CONSTANTS.dukandaarProductCategory);
      if (broker === CONSTANTS_LIST.BROKER.HDFCBANK) {
        ViewModel.productCategoriesList.push(ewProductCategory);
        ViewModel.productCategoriesList.push(dukandaarProductCategory);
        ViewModel.showBusinessChannel = true;
      }
      if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _ViewModel$productCat;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_ViewModel$productCat = ViewModel.productCategoriesList).push.apply(_ViewModel$productCat, _toConsumableArray(turtlemintProductCategories));
      }
    }

    // Make applied filter selection bold
    function filterTxtBold(key) {
      if (ViewModel.params.filters && ViewModel.params.filters[key] && ViewModel.params.filters[key].length > 0) {
        return true;
      }
      return false;
    }

    ViewModel.hideSearchResult = true;
    function hideSearchResultData() {
      ViewModel.hideSearchResult = true;
    }

    /**
     * Function for edit details file
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @param    {!String}           id       Index of file to download
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */

    function navigateToEdit(policy) {
      var id = policy.policyDetailsId ? policy.policyDetailsId : policy._id;
      if (policy.typeOfFlow === 'AUTO_PARSING' && policy.autoParsingStatus === 'PENDING') {
        commonService.showToast('Policy is still under process.');
        return;
      }
      $state.go('mis-detail', { id: id });
    }

    /**
     * Function for Export file details
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */

    function bulkExport(searchKey) {
      //To get misownerid from cookies
      $scope.disableExport = true;
      var getCookies = $cookies.getAll();
      var loginUser = AuthService.getUser();
      var paramsObj = {
        searchString: searchKey,
        page: $scope.params.page,
        limit: $scope.params.limit,
        pageType: $state.current.pageType,
        filters: [],
        misOwnerId: $cookies.get('userId'),
        misownerEmail: loginUser.email,
        broker: broker,
        sort: orderFilter($scope.params.order)
      };

      if (ViewModel.selectedCreationSource || ViewModel.selectedChannelType || ViewModel.selectedProductCategory || ViewModel.selectedInsurer || ViewModel.selectedPolicyStatus || ViewModel.selectedCallStatus || ViewModel.selectedSourceOfSaleStatus || ViewModel.selectedBusinessChannel) {
        paramsObj.filters.push({
          'type': 'multi-select',
          'code': 'creation_source',
          'values': ViewModel.selectedCreationSource
        }, {
          'type': 'multi-select',
          'code': 'channel_type',
          'values': ViewModel.selectedChannelType
        }, {
          'type': 'multi-select',
          'code': 'product_category',
          'values': ViewModel.selectedProductCategory
        }, {
          'type': 'multi-select',
          'code': 'insurer',
          'values': ViewModel.selectedInsurer
        }, {
          'type': 'multi-select',
          'code': 'policy_status',
          'values': ViewModel.selectedPolicyStatus
        }, {
          'type': 'multi-select',
          'code': 'pre_sale_verification_status',
          'values': ViewModel.selectedCallStatus
        }, {
          'type': 'multi-select',
          'code': 'source_of_sale_status',
          'values': ViewModel.selectedSourceOfSaleStatus
        }, {
          'type': 'multi-select',
          'code': 'salesDetail.business_channel',
          'values': ViewModel.selectedBusinessChannel
        });
      }

      if (ViewModel.startDate && ViewModel.endDate && ViewModel.selectedFilter) {
        var dateFrom = moment(ViewModel.startDate).add(2, 'months').endOf('month');
        if (dateFrom.valueOf() < moment(ViewModel.endDate).valueOf()) {
          commonService.showToast('Date range can not be greater than 3 months');
          $scope.disableExport = false;
          return;
        }
        paramsObj.filters.push({
          'type': 'date-range',
          'code': ViewModel.selectedFilter,
          'from': moment(ViewModel.startDate).format('x'),
          'to': moment(ViewModel.endDate).format('x')
        });
      } else {
        var exportError = 'Select dates & filter for export';
        commonService.showToast(exportError);
        $scope.disableExport = false;
        return;
      }

      setInsurerFilter(paramsObj.filters);

      misService.exportMISData('BULK_EXPORT', paramsObj).then(function () {
        $scope.disableExport = false;
      }).catch(function (error) {
        $scope.disableExport = false;
      });
    }

    function setInsurerFilter(filters) {
      var insurerOPS = commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_INSUREROPS) || undefined;
      if (insurerOPS) {
        var insurerIdx = filters.findIndex(function (ele) {
          return ele.code === 'insurer';
        });
        var insurerOPSfilter = {
          type: 'multi-select',
          code: 'insurer',
          values: insurerOPS
        };
        if (insurerIdx !== -1) {
          var insurerFilter = filters[insurerIdx];
          if (insurerFilter.values === undefined || insurerFilter.values === null || insurerFilter.values && insurerFilter.values.length <= 0) {
            filters[insurerIdx] = insurerOPSfilter;
          }
        } else {
          filters.push(insurerOPSfilter);
        }
      }
    }

    /**
     * Function for Order Filter
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @param    {!String}           orderColumn       Index of file to download
     *
     * @author    beerpratapsingh
     *
     * @return    {Array}    Array for Order filter
     *
     */
    function orderFilter(orderColumn) {
      var orderByObj = [];
      if (typeof orderColumn === 'string') {
        var sortObj = {};
        if (orderColumn.indexOf('-') === -1) {
          sortObj[orderColumn] = 'ASC';
        } else {
          orderColumn = orderColumn.replace('-', '');
          sortObj[orderColumn] = 'DESC';
        }

        orderByObj.push(sortObj);
      }

      return orderByObj;
    }

    /**
     * Function for Product Category Display
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @param    {!String}           productCategory       Index of file to download
     *
     * @author    beerpratapsingh
     *
     * @return    {Obj}    Object
     *
     */
    function productCategoryDisplay(productCategory) {
      var productCategoryList = ViewModel.productCategoriesList;
      var productCategoryObj = productCategoryList.find(function (x) {
        return x.value === productCategory;
      });
      if (productCategoryObj) {
        productCategory = productCategoryObj.text;
      }
      return productCategory;
    }

    /**
     * Function for Get Details of BulkExport
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @author    beerpratapsingh
     *
     */
    function getDetailsForBulkExport() {
      $scope.bulkExpStartDate = $scope.setStartDate;
      var startDate = $scope.bulkExpStartDate;
      var endDate = $scope.bulkExpEndDate;
      var startDateLong;
      var endDateLong;

      if ((typeof startDate === 'undefined' ? 'undefined' : _typeof(startDate)) !== undefined && startDate != null && startDate !== 'invalid date') {
        startDateLong = startDate.getTime();
      }

      if ((typeof endDate === 'undefined' ? 'undefined' : _typeof(endDate)) !== undefined && endDate != null && endDate !== 'invalid date') {
        endDateLong = endDate.getTime();
      }

      var policyFilterMapper = {
        'dateField': $scope.bulkExportDateFieldSelected,
        'startDateTimestamp': startDateLong,
        'endDateTimestamp': endDateLong
      };
      var promise = commonService.makeHttpCall('/getpolicydetailsbetweendates', policyFilterMapper, 'POST', false, false, true);
      angular.element('#loaderDiv').show();
      promise.then(function (response) {
        if (angular.equals(response.status, 200)) {
          var headers = response.headers();
          var blob = new Blob([response.data], { type: headers['content-type'] });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'exportData_' + new Date().getTime() + '.csv';
          document.body.appendChild(link);
          link.click();
          $timeout(function () {
            document.body.removeChild(link);
          }, 100);
        }
      }).catch(function (err) {
        $window.console.log(err);
        $window.alert(err.data.message);
      }).finally(function () {
        angular.element('#loaderDiv').hide();
      });
    }

    function isEmpty(value) {
      return value === undefined || value === null || value.hasOwnProperty('length') && value.length === 0;
    }

    /**
     * Get records list
     *
     * @author snehilmodani
     *
     * @alias           getRecords
     *
     * @function
     *
     */
    function getRecords(searchKey) {
      if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT && $state.current.pageType === 'ALLRECORDS' && searchKey === undefined && !(ViewModel.startDate && ViewModel.endDate && ViewModel.selectedFilter)) {
        return true;
      }

      var loadingDeferred = $q.defer();
      $scope.loadingPromise = loadingDeferred.promise;
      $scope.policyList = [];
      ViewModel.params.searchKey = searchKey;
      var currentPageType = $state.current.pageType;

      if (searchKey) {
        ViewModel.startDate = undefined;
        ViewModel.endDate = undefined;
        ViewModel.selectedFilter = undefined;
      }

      var paramsObj = {
        searchString: searchKey,
        page: $scope.params.page,
        limit: $scope.params.limit,
        pageType: $state.current.pageType,
        filters: [],
        sort: orderFilter($scope.params.order)
      };
      if (ViewModel.selectedCreationSource || ViewModel.selectedChannelType || ViewModel.selectedInsurer || ViewModel.selectedProductCategory || ViewModel.selectedPolicyStatus || ViewModel.selectedCallStatus || ViewModel.selectedSourceOfSaleStatus || ViewModel.selectedBusinessChannel) {
        Object.keys(filterNamesMapping).forEach(function (filterCode) {
          paramsObj.filters.push({
            'type': 'multi-select',
            'code': filterCode,
            'values': ViewModel[filterNamesMapping[filterCode]]
          });
        });

        //Check filter value is empty ?
        paramsObj.filters.forEach(function (f) {
          if (isEmpty(f.values)) {
            delete f.values;
          }
        });
      }

      if (ViewModel.startDate && ViewModel.endDate) {
        paramsObj.filters.push({
          'type': 'date-range',
          'code': ViewModel.selectedFilter,
          'from': moment(ViewModel.startDate).format('x'),
          'to': moment(ViewModel.endDate).format('x')
        });
      }

      if (paramsObj) {
        sessionStorage.setItem(currentPageType + 'localFilters', JSON.stringify(paramsObj));
      }

      var promise = commonService.makeHttpCall('/getrecords', paramsObj, 'POST', false, false, false);
      promise.then(function (res) {
        if (res.status === 200) {
          var resData = res.data;
          if (resData.data && resData.data.results && resData.data.results.length > 0) {
            angular.extend($scope.policyList, resData.data.results);
            for (var i = 0; i < $scope.policyList.length; i++) {
              var pm = $scope.policyList[i];
              if ($scope.policyList[i].createdAt) {
                var fromDate = misService.parseAndGetInteger(pm.createdAt);
                var createdAtDate = misService.makeCsvDateFormat(fromDate);
                pm.createdAt = createdAtDate;
              }
            }
            $scope.totalCount = resData.data.totalResultCount;
          } else if (resData) {
            if (resData.policyEntries.length <= 0) {
              commonService.showToast('No search data found.');
            }
            angular.extend($scope.policyList, resData);
            $scope.totalCount = resData.totalResultCount;
            $scope.incompleteCount = resData.totalIncompleteAndUnassignedCount;
            $scope.unassignedCount = resData.totalUnassignedCount;
            $scope.myIncompleteCount = resData.myIncompleteCount;
            $scope.totalAllRecordsCount = resData.totalAllRecordsCount;
          }

          loadingDeferred.resolve(true);
        } else {
          $scope.showErrorMessage = true;
          $scope.errorMessage = res.data.message;
          loadingDeferred.reject(true);
        }
      }).catch(function () {
        $scope.showErrorMessage = true;
        loadingDeferred.reject(true);
      });
    }

    $timeout(function () {
      $('#misNav .search').on('focus', function () {
        goTo('mis-all-list');
      });
    }, 100);

    function getFilteredData() {
      if (ViewModel.startDate && ViewModel.endDate && ViewModel.selectedFilter) {
        getRecords();
      } else {
        ViewModel.selectedFilter = undefined;
      }
    }

    function getTaskList(type) {
      if (type && type === 'filter') {
        ViewModel.params.searchKey = undefined;
      }
      getRecords(ViewModel.params.searchKey);
    }

    function setFilterLists() {
      //Filter Data for Prodct Category
      ViewModel.productCategoriesList = angular.copy(MIS_CONSTANTS.productCategories);

      //Filter Data for Policy Status
      ViewModel.policyStatusList = angular.copy(MIS_CONSTANTS.policyStatuses);

      //Filter Data for Channel Type
      ViewModel.channelTypeList = angular.copy(MIS_CONSTANTS.channelTypes);

      //Filter Data for Creation Source
      ViewModel.creationSourceList = angular.copy(MIS_CONSTANTS.creationSource);

      //Filter Data for Filter By
      ViewModel.filterByList = angular.copy(MIS_CONSTANTS.filterByDate);
    }

    function applyFilter() {
      getRecords();
      $mdSelect.hide();
    }
    function clearFilter(filterCode) {
      ViewModel[filterNamesMapping[filterCode]] = undefined;
      getRecords();
      $mdSelect.hide();
    }

    function clearCalendarFilter() {
      ViewModel.startDate = undefined;
      ViewModel.endDate = undefined;
      getRecords();
    }

    //Add New Sale Tab
    function addNewSale() {
      commonService.setItemSession('mis', 'newMIS', null);
      if (features && features.autoParsing) {
        $mdDialog.show({
          templateUrl: 'dev/mis/templates/dialogs/create-mis.dialog.tpl.html',
          controller: 'CreateMISDialogCtrl',
          scope: $scope,
          preserveScope: true
        }).then(function (res) {
          commonService.setItemSession('mis', 'newMIS', res);
          if (res.autoParsingEnabled) {
            TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_AUTO', 'track_event', res);
            goTo('mis-create-auto');
          } else {
            TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_MANUAL', 'track_event', res);
            commonService.removeItemSession('mis', 'action');
            goTo('mis-create');
          }
        }).catch(function () {
          TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_DIALOG_CANCEL', 'track_event', {});
        });
      } else {
        commonService.removeItemSession('mis', 'action');
        goTo('mis-create');
      }
    }

    /**
     * Function for get pending and unassigned records
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.misHomeCtrl#
     *
     * @param    {!String}           pageType       check page type
     *
     * @author    beerpratapsingh
     *
     */
    function getMISMetrics(pageType) {

      var reqObj = [];
      if (pageType === 'INCOMPLETE') {
        reqObj = [{
          code: 'unassignedRecordCount',
          type: 'KPI'
        }, {
          code: 'ageingPendingRecord',
          type: 'BAR_CHART'
        }];
      } else if (pageType === 'UNASSIGNED') {
        reqObj = [{
          code: 'unassignedRecordCount',
          type: 'KPI'
        },
        //       {
        //   code: 'pendingRecordCount',
        //   type: 'KPI'
        // },
        //       {
        //   code: 'qcErrorPerPendingRecord',
        //   type: 'KPI'
        // },
        {
          code: 'ageingUnassignedRecord',
          type: 'BAR_CHART'
        }];
      }

      MISInsightsService.getMISDetails(reqObj).then(function (response) {
        for (var idx = response.data.length - 1; idx >= 0; idx--) {
          var obj = response.data[idx];
          // if(obj.code === 'pendingRecordCount') {
          //     ViewModel.pCount = obj.data;
          // // } else if(obj.code === 'qcErrorPerPendingRecord') {
          // //   ViewModel.qcerrorCount = obj.data;
          // } else
          if (obj.code === 'ageingPendingRecord') {
            var labelForChart = obj.data.label;
            var modifiedLabels = labelForChart.map(function (ele) {
              if (ele === 'IN_A_DAY') {
                return 'Records in 24 Hours';
              } else if (ele === 'IN_A_WEEK') {
                return 'From 1 to 7 Days';
              } else if (ele === 'MORE_THAN_WEEK') {
                return 'Greater than 7 Days';
              }
              return ele;
            });
            ViewModel.chartData = {
              labels: modifiedLabels,
              data: [obj.data.value]
            };
          } else if (obj.code === 'unassignedRecordCount') {
            ViewModel.unassignpCount = obj.data;
          } else if (obj.code === 'ageingUnassignedRecord') {
            var labelForChart = obj.data.label;
            var modifiedLabels = labelForChart.map(function (ele) {
              if (ele === 'IN_A_DAY') {
                return 'Records in 24 Hours';
              } else if (ele === 'IN_A_WEEK') {
                return 'From 1 to 7 Days';
              } else if (ele === 'MORE_THAN_WEEK') {
                return 'Greater than 7 Days';
              }
              return ele;
            });

            ViewModel.chartData = {
              labels: modifiedLabels,
              data: obj.data.value
            };
          }
        }
      }).catch(function (err) {
        return $window.console.log(err);
      });
    }

    function goTo(page) {
      $state.go(page);
    }

    function displayPolicyStatus(status) {
      switch (status) {
        case 'ACTIVE':
          return 'Issued';
        case 'POTENTIAL_DUPLICATE':
          return 'Potential Duplicate';
        default:
          return status;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('tagSelectorCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'selectedTag', 'fileItem', 'MIS_CONSTANTS'];

  function controller($scope, $mdDialog, selectedTag, fileItem, MIS_CONSTANTS) {

    var ViewModel = $scope;

    ViewModel.selectTag = selectTag;
    ViewModel.cancelModal = cancelModal;
    ViewModel.tags = MIS_CONSTANTS.filetags;

    if (selectedTag && fileItem) {
      ViewModel.selectedTag = selectedTag.code;
    }

    function selectTag() {
      var tag = ViewModel.tags.find(function (ele) {
        return ele.code === ViewModel.selectedTag;
      });
      $mdDialog.hide(tag);
    }

    function cancelModal() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('addNewSaleCtrl', controller);

  controller.$inject = ['$scope', 'commonService', '$state', '$window', 'AuthService', '$mdDialog', '$timeout', 'sale', '$anchorScroll', 'SaleService', '$mdToast', 'MasterFileService', 'TrackingService'];

  function controller($scope, commonService, $state, $window, AuthService, $mdDialog, $timeout, sale, $anchorScroll, SaleService, $mdToast, MasterFileService, TrackingService) {
    var ViewModel = $scope;
    var originalObj = {};
    var mxpData = void 0;

    var documentApiUrl = MasterFileService.getUploadUrl();
    var features = commonService.getItemSession('features', 'mis');
    ViewModel.init = init;
    ViewModel.saveCurrentSale = saveCurrentSale;
    ViewModel.startEditMode = startEditMode;
    ViewModel.stopEditMode = stopEditMode;
    ViewModel.goToQCError = goToQCError;
    ViewModel.hideCommissionFields = hideCommissionFields;
    ViewModel.hideOtherFields = hideOtherFields;
    ViewModel.removeMember = removeMember;
    ViewModel.removeNominee = removeNominee;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.showHideMarketingFields = showHideMarketingFields;
    ViewModel.selectBQP = selectBQP;
    ViewModel.updateBQP = updateBQP;
    ViewModel.cancelBQP = cancelBQP;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function init() {
      var goBack = ViewModel.hasPermission('mis-incomplete-list-read') ? 'mis-incomplete-list' : 'mis-all-list';
      ViewModel.navbarConfig = {
        goBackState: goBack,
        vertical: 'mis',
        mode: 'VIEW',
        subtitle: '(View Mode)'
      };

      if (sale && sale.policyDetailsId) {
        ViewModel.currentSale = sale;
        ViewModel.editMode = false;
        ViewModel.currentSale.$$state = 'UPDATE';

        ViewModel.navbarConfig.status = ViewModel.currentSale.policyStatus === 'POTENTIAL_DUPLICATE' ? 'Potential Duplicate' : '';
        ViewModel.navbarConfig.junkStatus = ViewModel.currentSale.recordStatus === 'junk' ? 'Junk' : '';

        ViewModel.navbarConfig.title = ViewModel.currentSale.policyNumber;
        _setNavbarConfig('VIEW');
        _setFileConfig();
        _initNCB(ViewModel.currentSale);
      } else {
        ViewModel.editMode = true;
        SaleService.clearCurrentSale();
        ViewModel.currentSale = SaleService.getCurrentSale();
        _generateFolderNo();
        ViewModel.navbarConfig.title = 'New Sale';
        _setNavbarConfig('NEW');
      }

      if (ViewModel.currentSale.policyPDFPresent === 'false') {
        ViewModel.currentSale.policyPDFPresent = false;
      }
      _initFormConfigs();
      setOriginalSale(ViewModel.currentSale);
      _initSOS(ViewModel.currentSale);
      SaleService.initPotentialDuplicate(ViewModel.currentSale);
      ViewModel.currentSale.cpa = SaleService.initCPA(ViewModel.currentSale);

      mxpData = {
        policyDetailsId: ViewModel.currentSale.policyDetailsId,
        vertical: ViewModel.currentSale.vertical
      };
    }

    function _setFileConfig() {
      ViewModel.fileConfig = {
        tagOptions: {
          tagging: true
        },
        options: {
          $$viewMode: false,
          viewFileCallBackFn: viewFileCallBackFn,
          closeFileCallBackFn: closeFileCallBackFn,
          uploadCallBackFn: uploadCallBackFn,
          deleteCallBackFn: deleteCallBackFn,
          changeTagCallBackFn: changeTagCallBackFn,
          parseCallBackFn: parseDocument
        },
        eventTrackingData: {
          module: 'MIS',
          mxpData: mxpData
        }
      };
      _setFilePermissions();
      _initFileIdentifiers();
    }

    //Close Dialog Box On Browser Back Button
    $scope.$on('$locationChangeStart', function (event) {
      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }
    });

    function _initFileIdentifiers() {
      var fileUploadIdentifiers = {
        policyDetailsId: ViewModel.currentSale.policyDetailsId,
        clientUid: ViewModel.currentSale.policyDetailsId
      };
      if (ViewModel.currentSale.requestId) {
        fileUploadIdentifiers.requestId = ViewModel.currentSale.requestId;
        fileUploadIdentifiers.clientUid = ViewModel.currentSale.requestId;
      }
      ViewModel.fileConfig.identifiers = fileUploadIdentifiers;
    }

    /**
     * removeMember function
     *
     * @private
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function removeMember(index) {
      ViewModel.currentSale.members.splice(index, 1);
    }

    /**
     * removeNominee function
     *
     * @private
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function removeNominee(index) {
      ViewModel.currentSale.nominees.splice(index, 1);
    }

    function proposerDetailsCopyFn(sale) {
      var selfMember = {
        fname: sale.policyProposerFName || '',
        lname: sale.policyProposerLName || '',
        dob: sale.policyProposerDob || ''
      };
      if (sale) {
        if (sale.members && sale.members.length > 0) {
          var idx = sale.members.findIndex(function (ele) {
            return ele.relationship === 'SELF';
          });
          if (idx !== -1) {
            sale.members[idx] = _extends({}, sale.members[idx], selfMember);
          }
        }
      }
    }

    ViewModel.showMarketingFields = false;
    function showHideMarketingFields() {
      ViewModel.showMarketingFields = ViewModel.showMarketingFields ? false : true;
    }
    /**
     * Hide Commission related fields on UI
     *
     * @public
     *
     * @function
     *
     * @author sanketapte
     *
     */
    function hideCommissionFields() {
      return true;
    }

    /**
     * Hide Other fields on UI
     *
     * @public
     *
     * @function
     *
     * @author sanketapte
     *
     */
    function hideOtherFields() {
      return true;
    }

    /**
     * onEditAction function
     *
     * @private
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function _onEditAction() {
      startEditMode();
    }

    /**
     * on Export Action function
     *
     * @private
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function _singleExport() {
      var data = {
        policyDetailsId: ViewModel.currentSale.policyDetailsId,
        folderNo: ViewModel.currentSale.folderNo,
        policyNumber: ViewModel.currentSale.policyNumber
      };
      SaleService.trackExport(data);
      TrackingService.pushToGTMDatalayer('MIS_SINGLE_EXPORT', 'track_event', data);
    }

    function setOriginalSale(sale) {
      originalObj = {
        policyStatus: sale.policyStatus,
        startDate: sale.startDate,
        productCategory: sale.productCategory,
        paymentFrequency: sale.paymentFrequency,
        netPremium: sale.netPremium,
        policyPremiumTerm: sale.policyPremiumTerm
      };
    }

    /**
     * on Cancel Action function
     *
     * @private
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function _onCancelAction() {
      stopEditMode();
      if ($state.current.name === 'mis-detail') {
        SaleService.getPolicyById($scope.currentSale.policyDetailsId).then(function (result) {
          sale = result;
          delete ViewModel.currentSale;
          $timeout(function () {
            ViewModel.currentSale = sale;
            init();
          }, 10);
        });
      } else {
        $state.go('mis-detail', { 'id': $scope.currentSale.policyDetailsId });
      }
    }

    /**
     * convert Date Fields Before Request function
     *
     * @private
     *
     * @function
     *
     * @param    {Array}           sale       sale
     *
     * @author beerpratapsingh
     *
     */
    function _convertDateFieldsBeforeRequest(sale) {
      var dateFields = ['issuanceDate', 'covernoteIssuanceDate', 'saleClosedDate', 'inceptionDate', 'startDate', 'endDate', 'documentReceiptDate', 'refundDate', 'policySentToCustomerDate', 'createdAt', 'policyProposerDob', 'nomineeDob', 'ppOpendate', 'ppCloseDate', 'cancellationDate', 'odRiskStartDate', 'odRiskEndDate', 'tpRiskStartDate', 'tpRiskEndDate', 'chequeReceivedDate', 'activeTPRiskEndDate', 'activeTPRiskStartDate', 'previousODRiskEndDate', 'previousODRiskStartDate', 'vehicleRegDate', 'firstTimeTaggedAsIssuedDate', 'proposalDate', 'premiumReceivedDate', 'premiumPaidToInsurerDate', 'proposalFormSubmittedToInsurerDate', 'renewalPremiumDueDate', 'renewalPremiumReceivedDate', 'renewalPremiumPaidToInsurerDate', 'junkedDate', 'dataQCDoneDate', 'updatedAt'];
      for (var idx = dateFields.length - 1; idx >= 0; idx--) {
        var dateFieldCode = dateFields[idx];
        var config = SaleService.getConfigObjectFromCode(dateFieldCode);
        if (sale[config.code]) {
          sale[config.code] = parseInt(moment(sale[config.code]).format('x'));
        }
      }
    }

    /**
     * set Navbar Config function
     *
     * @private
     *
     * @function
     *
     * @param    {!String}           mode       mode
     *
     * @author beerpratapsingh
     *
     */
    function _setNavbarConfig(mode) {
      if (mode === 'EDIT') {
        ViewModel.navbarConfig.mode = 'EDIT';
        ViewModel.navbarConfig.subtitle = '(Edit Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'button',
          class: '',
          action: _onCancelAction,
          permissionCode: 'mis-update',
          name: 'Cancel',
          dataAuto: 'cancel-mis-button'
        }, {
          type: 'button',
          class: '',
          action: saveCurrentSale,
          permissionCode: 'mis-update',
          name: 'Save Sale',
          dataAuto: 'save-mis-button'
        }];
      } else if (mode === 'VIEW') {
        ViewModel.navbarConfig.mode = 'VIEW';
        ViewModel.navbarConfig.subtitle = '(View Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'icon',
          class: 'hover-btn',
          action: _onEditAction,
          permissionCode: 'mis-update',
          name: 'Edit',
          iconSrc: '/images/common/edit.svg',
          dataAuto: 'edit-mis-button'
        }, {
          type: 'icon',
          permissionCode: 'mis-singleexport',
          name: 'Export',
          iconSrc: '/images/common/download-icon.svg',
          class: 'hover-btn',
          action: _singleExport,
          dataAuto: 'export-mis-button'
        }];

        if (features && features.lifePaymentSchedule && sale.productCategory === 'LIFE' && sale.policyStatus === 'ACTIVE') {
          var paymentScheduleBtn = {
            type: 'button',
            class: '',
            action: goToPaymentSchedule,
            permissionCode: 'mis-payment-schedule',
            name: 'Payment Schedule',
            dataAuto: 'payment-schedule-button'
          };
          ViewModel.navbarConfig.actions.unshift(paymentScheduleBtn);
        }
      } else if (mode === 'NEW') {
        ViewModel.navbarConfig.mode = 'EDIT';
        ViewModel.navbarConfig.subtitle = '(Edit Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'button',
          class: '',
          action: saveCurrentSale,
          permissionCode: 'mis-update',
          name: 'Save Sale',
          dataAuto: 'save-mis-button'
        }];
      } else if (mode === 'EDIT_BQP') {
        ViewModel.navbarConfig.mode = 'EDIT';
        ViewModel.navbarConfig.subtitle = '(Edit Mode)';
        ViewModel.navbarConfig.actions = [{
          type: 'icon',
          permissionCode: 'mis-singleexport',
          name: 'Export',
          iconSrc: '/images/common/download-icon.svg',
          class: 'hover-btn',
          action: _singleExport,
          dataAuto: 'export-mis-button'
        }];
      }
    }

    /**
     * start Edit Mode function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function startEditMode() {
      SaleService.acquirePolicyLock(ViewModel.currentSale.policyDetailsId).then(function () {
        ViewModel.editMode = true;
        _setNavbarConfig('EDIT');
      });
    }

    /**
     * stop Edit Mode function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function stopEditMode() {
      SaleService.releasePolicyLock(ViewModel.currentSale.policyDetailsId).then(function () {
        ViewModel.editMode = false;
        _setNavbarConfig('VIEW');
        if (ViewModel.currentSale) {
          _initSOS(ViewModel.currentSale);
        }
      });
    }

    /**
     * sgoToPaymentSchedule function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function goToPaymentSchedule() {
      TrackingService.pushToGTMDatalayer('MIS_PAYMENT_SCHEDULE', 'track_event', mxpData);
      $state.go('mis-payment-schedule', { id: ViewModel.currentSale.policyDetailsId });
    }

    /**
     * set Focus function
     *
     * @private
     *
     * @function
     *
     * @param    {!String}           id       Find ID
     *
     * @author beerpratapsingh
     *
     */
    function _setFocus(id) {
      var someElement = $window.document.getElementById(id);
      someElement.focus();
    }

    /**
     * go To QCError function
     *
     * @public
     *
     * @function
     *
     * @param    {obj}           errorObj       errorObj
     *
     * @author beerpratapsingh
     *
     */
    function goToQCError(errorObj) {
      $anchorScroll(errorObj.field);
      if (ViewModel.editMode) {
        _setFocus(errorObj.field);
      }
    }

    /**
     * compare Almost Equal Validity function
     *
     * @private
     *
     * @function
     *
     * @param    {!Number}           a,b,almostValue       Number
     *
     * @author beerpratapsingh
     *
     */
    function _compareAlmostEqualValidity(a, b, almostValue) {
      if (a >= b) {
        if (a - b > almostValue) {
          return false;
        }

        return true;
      } else {
        _compareAlmostEqualValidity(b, a, almostValue);
      }
    }

    /**
     * compare total OD Premium and total TP Premium as per tolerance of (-5 or +5)
     *
     * @private
     *
     * @function
     *
     * @param               netOdPremium,tpPremium,netPremium
     *
     * @author pushpakumarinavik
     *
     */
    function _validateNetPremium(currentSale) {
      var totalNetPremium = void 0,
          error = void 0;
      if (currentSale.productName === 'comprehensive' && currentSale.netOdPremium >= 0 && currentSale.tpPremium >= 0) {
        totalNetPremium = currentSale.netOdPremium + currentSale.tpPremium;
        error = 'Sum of Total OD Premium and Total TP Premium is not equal to Net Premium \n';
      } else if (currentSale.productName === 'tp' && currentSale.tpPremium >= 0) {
        totalNetPremium = currentSale.tpPremium;
        error = 'Total TP Premium is not equal to Net Premium \n';
      } else if (currentSale.productName === 'od' && currentSale.netOdPremium >= 0) {
        totalNetPremium = currentSale.netOdPremium;
        error = 'Total OD Premium is not equal to Net Premium \n';
      }
      var inValid = !(totalNetPremium - 5 <= currentSale.netPremium && currentSale.netPremium <= totalNetPremium + 5);
      return { inValid: inValid, error: error };
    }

    /**
     * save current sale function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function saveCurrentSale() {
      angular.forEach(ViewModel.currentSaleForm.$error.required, function (field) {
        field.$setDirty();
      });

      if (ViewModel.currentSaleForm.$valid) {

        var validity = true;
        var validityMessage = '';
        var goToField = null;
        var cs = ViewModel.currentSale;

        if (ViewModel.currentSale.policyProposerEmailNotAvailable) {
          ViewModel.currentSale.policyProposerEmail = null;
        }

        if (ViewModel.currentSale.policyProposerMobileNotAvailable) {
          ViewModel.currentSale.policyProposerMobile = null;
        }

        // Checking for these validation only at the time of save
        if (SaleService.isPolicyActive(cs.policyStatus) && cs.productName) {
          var validNetPremium = _validateNetPremium(cs);
          if (validNetPremium.inValid) {
            validity = false;
            validityMessage += validNetPremium.error;
            if (!goToField) {
              goToField = 'netOdPremium';
            }
          }
        }

        if (ViewModel.currentSale.netPremium && ViewModel.currentSale.serviceTax && ViewModel.currentSale.grossPremium) {
          if (!_compareAlmostEqualValidity(ViewModel.currentSale.netPremium + ViewModel.currentSale.serviceTax, ViewModel.currentSale.grossPremium, 3)) {
            validity = false;
            validityMessage += 'Sum of Net Premium and Service Tax should be equal to Gross Premium.\n';
            if (!goToField) {
              goToField = 'netPremium';
            }
          }
        }

        if (ViewModel.currentSale.saleClosedDate && ViewModel.currentSale.issuanceDate) {
          var dateComparator = moment(ViewModel.currentSale.saleClosedDate).isAfter(ViewModel.currentSale.issuanceDate, 'day');
          if (dateComparator) {
            validity = false;
            validityMessage += 'Sale Closed Date can not be after Policy Issuance Date.\n';
            if (!goToField) {
              goToField = 'saleClosedDate';
            }
          }
        }

        if (ViewModel.currentSale.productCategory === 'LIFE' && ViewModel.currentSale.policyStatus === 'ACTIVE') {
          if (ViewModel.currentSale.$$attachedGroupedDocuments) {
            if (!ViewModel.currentSale.$$attachedGroupedDocuments.Policy && !ViewModel.currentSale.applicationNumber) {
              validity = false;
              validityMessage += 'Application Number is required.\n';
              if (!goToField) {
                goToField = 'applicationNumber';
              }
            }
          } else if (!ViewModel.currentSale.applicationNumber) {
            validity = false;
            validityMessage += 'Application Number is required.\n';
            if (!goToField) {
              goToField = 'applicationNumber';
            }
          }

          //Check if PPT > PT
          if (ViewModel.currentSale.policyPremiumTerm > ViewModel.currentSale.policyTerm) {
            validity = false;
            validityMessage += 'Policy premium term cannot be greater than policy term.\n';
          }
        }

        //Check RTO Location Valid
        if (cs.policyStatus === 'ACTIVE' && (cs.productCategory === 'TW' || cs.productCategory === 'FW')) {
          if (cs.rtoLocation === null || cs.rtoLocation === undefined) {
            _clearAutoconfig('rtoLocation');
            validity = false;
            validityMessage += 'RTO Location is required. Please select a current value\n';
          }
        }

        //Check Registration Number validity
        if (['TW' /*, 'CV', 'FW'*/].includes(ViewModel.currentSale.vehicleType) && ViewModel.currentSale.$$selectedVertical) {
          if (ViewModel.currentSale.$$selectedVertical !== ViewModel.currentSale.$$assignedVertical) {
            validity = false;
            validityMessage += 'The \'Vehicle Type\' selected by you does not match with vehicle type as per your \'Registration Number\'.';

            if (!goToField) {
              goToField = 'registrationNumber';
            }
          }
        }

        if (SaleService.isPolicyActive(cs.policyStatus) && cs.productCategory === 'HEALTH') {
          if (!cs.members || cs.members && cs.members.length < 1) {
            validity = false;
            validityMessage += 'Please add at least 1 member.';
          }
        }

        SaleService.addressCopyFn(ViewModel.currentSale);
        var emptyCheckList = ['registrationAddress', 'registrationCity', 'registrationPincode', 'registrationState', 'communicationAddress', 'communicationCity', 'communicationPincode', 'communicationState'];
        emptyCheckList.forEach(function (field) {
          return checkEmptyVal(field, ViewModel.currentSale);
        });

        if (validity) {
          var saleCopy = angular.copy(ViewModel.currentSale);
          _convertDateFieldsBeforeRequest(saleCopy);

          if (ViewModel.currentSaleForm.tlName && ViewModel.currentSaleForm.tlName.$$rawModelValue) {
            if (!saleCopy.tlName) {
              saleCopy.tlName = ViewModel.currentSaleForm.tlName.$$rawModelValue;
            } else if (saleCopy.tlName !== ViewModel.currentSaleForm.tlName.$$rawModelValue) {
              saleCopy.tlName = ViewModel.currentSaleForm.tlName.$$rawModelValue;
            }
          }

          if (ViewModel.currentSaleForm.salesExecutive && ViewModel.currentSaleForm.salesExecutive.$$rawModelValue) {
            if (!saleCopy.salesExecutive) {
              saleCopy.salesExecutive = ViewModel.currentSaleForm.salesExecutive.$$rawModelValue;
            } else if (saleCopy.salesExecutive !== ViewModel.currentSaleForm.salesExecutive.$$rawModelValue) {
              saleCopy.salesExecutive = ViewModel.currentSaleForm.salesExecutive.$$rawModelValue;
            }
          }

          if (saleCopy.productCategory === 'TW' || saleCopy.productCategory === 'FW') {
            if (saleCopy.multiyear) {
              if (saleCopy.productName === 'tp') {
                saleCopy.startDate = saleCopy.tpRiskStartDate;
                saleCopy.endDate = saleCopy.tpRiskEndDate;
              } else if (saleCopy.productName === 'od') {
                saleCopy.startDate = saleCopy.odRiskStartDate;
                saleCopy.endDate = saleCopy.odRiskEndDate;
              } else {
                saleCopy.startDate = saleCopy.odRiskStartDate;
                setEndDate(saleCopy);
              }
            } else {
              if (saleCopy.productName === 'od') {
                saleCopy.startDate = saleCopy.odRiskStartDate;
                saleCopy.endDate = saleCopy.odRiskEndDate;
              } else if (saleCopy.productName === 'tp') {
                saleCopy.startDate = saleCopy.tpRiskStartDate;
                saleCopy.endDate = saleCopy.tpRiskEndDate;
              } else {
                saleCopy.odRiskStartDate = saleCopy.startDate;
                saleCopy.odRiskEndDate = saleCopy.endDate;
                saleCopy.tpRiskStartDate = saleCopy.startDate;
                saleCopy.tpRiskEndDate = saleCopy.endDate;
              }
            }
          } else {
            delete saleCopy.multiyear;
            delete saleCopy.odRiskStartDate;
            delete saleCopy.odRiskEndDate;
            delete saleCopy.tpRiskStartDate;
            delete saleCopy.tpRiskEndDate;
          }
          if (saleCopy.members && saleCopy.members.length > 0) {
            proposerDetailsCopyFn(saleCopy);
            saleCopy.members.forEach(function (member) {
              if (member.dob) {
                member.dob = moment(member.dob).format('x');
              }
            });
          }

          if (features && features.lifePaymentSchedule && saleCopy.productCategory === 'LIFE') {
            checkUpdatedPaymentSchedule(saleCopy);
          } else {
            saleAddUpdate(saleCopy);
          }
        } else {
          if (goToField) {
            $anchorScroll(goToField);
          }

          $window.alert(validityMessage);
        }
      } else {
        for (var key in ViewModel.currentSaleForm.$error) {
          var errorArray = ViewModel.currentSaleForm.$error[key];
          for (var idx = 0; idx < errorArray.length; idx++) {
            var error = errorArray[idx];
            if (!error.$dirty) {
              ViewModel.currentSaleForm[error.$name].$setDirty();
            }
            $anchorScroll(error.$name);
            break;
          }
        }
      }
    }

    function _clearAutoconfig(code) {
      SaleService.clearAutoconfig(code);
    }

    function saleAddUpdate(saleCopy) {
      delete saleCopy.$$state;
      SaleService.addUpdate(saleCopy).then(function () {
        $mdToast.show($mdToast.simple().textContent('Sale updated!').action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
          if (response === 'ok') {
            $window.console.log('You clicked the \'DISMISS\' action.');
          }
        });
        stopEditMode();
        if ($state.current.name === 'mis-detail') {
          SaleService.getPolicyById($scope.currentSale.policyDetailsId).then(function (result) {
            sale = result;
            delete ViewModel.currentSale;
            $timeout(function () {
              init();
            }, 10);
          });
        } else {
          $state.go('mis-detail', { 'id': $scope.currentSale.policyDetailsId });
        }
      }).catch(function (err) {
        if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && err.policyDetailsId) {
          SaleService.openDuplicatePolicyModal(saleCopy, err.policyDetailsId);
        } else {
          var errMsg = err ? err : 'Policy Creation Failed!';
          $window.alert(errMsg);
        }
      });
    }

    function checkUpdatedPaymentSchedule(saleCopy) {
      var modelConfig = {
        valid: true,
        statement: 'Payment Schedule will get updated due to change in Policy Status / Risk Start Date. Are you sure you want to continue?'
      };
      if (originalObj.policyStatus === 'ACTIVE' && originalObj.policyStatus !== saleCopy.policyStatus || originalObj.startDate && originalObj.startDate !== saleCopy.startDate) {
        misSaveConfirmationModel(modelConfig, saleCopy);
      } else if (saleCopy.policyStatus === 'ACTIVE') {
        if (originalObj.policyPremiumTerm && originalObj.policyPremiumTerm !== saleCopy.policyPremiumTerm || originalObj.paymentFrequency && originalObj.paymentFrequency !== saleCopy.paymentFrequency) {
          modelConfig.valid = false;
          modelConfig.statement = 'Payment Schedule will get updated due to change in Premium Payment Term  / Payment Frequency. Are you sure you want to continue? If Yes, please select an Applicable From Date.';
          modelConfig.type = 'select';
          misSaveConfirmationModel(modelConfig, saleCopy);
        } else if (originalObj.netPremium && originalObj.netPremium !== saleCopy.netPremium) {
          modelConfig.valid = false;
          modelConfig.statement = 'Payment Schedule will get updated due to change in Net Premium. Are you sure you want to continue? If Yes, please select an Applicable From Date';
          modelConfig.type = 'date';
          misSaveConfirmationModel(modelConfig, saleCopy);
        } else {
          saleAddUpdate(saleCopy);
        }
      } else {
        saleAddUpdate(saleCopy);
      }
    }

    function misSaveConfirmationModel(modelConfig, saleCopy) {
      $mdDialog.show({
        templateUrl: 'dev/mis/templates/mis-save-confirmation.dialog.tpl.html',
        clickOutsideToClose: false,
        controller: 'misSaveConfirmModelController',
        locals: {
          modelConfig: modelConfig,
          sale: saleCopy,
          $scope: ViewModel
        }
      }).then(function (res) {
        saleCopy = res;
        saleAddUpdate(saleCopy);
      });
    }

    //Set null if field is empty
    function checkEmptyVal(field, model) {
      model[field] = model[field] || null;
    }

    /**
     * init form configs function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _initFormConfigs() {
      ViewModel.sourceOfSaleFields = SaleService.sourceOfSaleFields;
      ViewModel.basicDetailsConfig = SaleService.basicDetailsConfig;
      ViewModel.saleFieldsConfig = SaleService.saleFieldsConfig;
      ViewModel.policyFields = SaleService.policyFields;
      ViewModel.paymentFields = SaleService.paymentFields;
      ViewModel.marketingFields = SaleService.marketingFields;
      ViewModel.preSaleVerification = SaleService.preSaleVerification;
      ViewModel.otherFields = SaleService.otherFields;
      ViewModel.commissionFields = SaleService.commissionFields;
      ViewModel.memberConfig = SaleService.memberConfig;
      ViewModel.minDoc = SaleService.minDoc;
    }

    /**
     * Set permissions for file  function
     *
     * @private
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function _setFilePermissions() {
      var filePermissions = {
        view: ViewModel.hasPermission('mis-file-view'),
        upload: ViewModel.hasPermission('mis-file-upload'),
        download: ViewModel.hasPermission('mis-file-download'),
        delete: ViewModel.hasPermission('mis-file-delete'),
        changeTag: ViewModel.hasPermission('mis-file-changetag'),
        parse: ViewModel.hasPermission('mis-file-parse') && false
      };
      filePermissions.options = filePermissions.view || filePermissions.download || filePermissions.delete || filePermissions.changeTag;
      ViewModel.fileConfig.options.filePermissions = filePermissions;
    }

    /**
     * Function to generate folder no and policyDetailsId from BE
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _generateFolderNo() {
      var promise = commonService.makeHttpCall('/generatepolicydetailid', {}, 'GET', false, false, true);
      promise.then(function (res) {
        if (res.data && res.data.statusCode && res.data.statusCode === 204) {
          ViewModel.showErrorMessage = true;
          ViewModel.errorMessage = res.data.message;
        }
        if (res.data && res.data.statusCode && res.data.statusCode === 200) {
          ViewModel.currentSale.policyDetailsId = res.data.data;
          ViewModel.currentSale.$$state = 'NEW';
          ViewModel.currentSale.folderNo = res.data.data;
          ViewModel.currentSale.businessVertical = 'Retail';
          ViewModel.currentSale.$$attachedDocuments = [];
          ViewModel.currentSale.$$fullAccess = !AuthService.isMISChecker();
          _setFileConfig();
        }
      });
    }

    /**
     * Function for delete file tag
     *
     * @public
     *
     * @function
     *
     * @param    {!Number}           index       Index of document to send for parsing
     *
     * @author snehilmodani
     *
     */
    function parseDocument(file) {
      var reqObj = {
        fileId: file.processInfo.pid
      };
      TrackingService.pushToGTMDatalayer('MIS_FILE_PARSE', 'track_event', _extends({}, mxpData, {
        fileId: file.processInfo.pid,
        tag: file.tag
      }));
      var promise = commonService.makeThirdPartyApiCall(documentApiUrl, '/misfileparse', reqObj, 'POST', false, false, false);
      promise.then(function (res) {
        if (res.data.statusCode === 200) {
          file.parsed = true;
          $state.go('mis-detail', {
            id: res.data.id,
            policyNumber: res.data.displayPolicyNumber
          });
        } else {
          $window.alert(res.data.message);
        }
      }).catch(function () {
        file.parsed = false;
      });
    }

    function viewFileCallBackFn() {
      ViewModel.currentSale.$$viewMode = true;
    }

    function closeFileCallBackFn() {
      ViewModel.currentSale.$$viewMode = false;
    }

    function uploadCallBackFn(newFile) {
      if (newFile.tag === 'POLICY') {
        ViewModel.currentSale.policyPDFPresent = true;
        ViewModel.currentSale.policyUrl = newFile.processInfo.pid;
        if (ViewModel.currentSale.$$state !== 'NEW') {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, true, newFile.processInfo.pid);
        }
      }
    }

    function deleteCallBackFn(deletedFile) {
      var documentCount = ViewModel.currentSale.$$attachedDocuments.filter(function (ele) {
        return ele.tag === deletedFile.tag;
      }).length;
      if (deletedFile.tag === 'POLICY' && documentCount === 0) {
        ViewModel.currentSale.policyPDFPresent = false;
        delete ViewModel.currentSale.policyUrl;
        if (ViewModel.currentSale.$$state !== 'NEW') {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, false, deletedFile.processInfo.pid);
        }
      }
    }

    function changeTagCallBackFn(fileItem, oldTag) {
      var getPolicyPDF = ViewModel.currentSale.$$attachedDocuments;
      var policyPdf = false;
      if (fileItem.tag === 'POLICY') {
        policyPdf = true;
        ViewModel.currentSale.policyUrl = fileItem.processInfo.pid;
        SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, policyPdf, fileItem.processInfo.pid);
      } else if (oldTag === 'POLICY' && fileItem.tag !== 'POLICY') {
        var count = getPolicyPDF.filter(function (ele) {
          return ele.tag === 'POLICY';
        }).length;
        if (count > 1) {
          policyPdf = true;
        } else {
          delete ViewModel.currentSale.policyUrl;
        }
        if (!policyPdf) {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, policyPdf, fileItem.processInfo.pid);
        }
      }
      ViewModel.currentSale.policyPDFPresent = policyPdf;
    }

    function selectBQP(val) {
      if (val) {
        _setNavbarConfig('EDIT_BQP');
        SaleService.getBQPList(ViewModel.currentSale);
      } else {
        _setNavbarConfig('VIEW');
      }
      ViewModel.editMode = !val;
      ViewModel.showReviewFields = val;
      ViewModel.editModeSOS = val;
    }

    function updateBQP(sale) {
      if (sale.bqp) {
        var req = {
          policyDetailsId: sale.policyDetailsId,
          brokerQualifiedPerson: sale.bqp
        };

        SaleService.saveBQPDetails(req).then(function (res) {
          if (res.status === 200) {
            $state.reload();
          }
        }).catch(function (err) {
          var errMsg = err.data ? err.data : 'Error. No BQPs found.';
          commonService.showToast(errMsg);
        });
      } else {
        commonService.showToast('Please select a BQP');
      }
    }

    function cancelBQP() {
      selectBQP(false);
      $state.reload();
    }

    function _initSOS(sale) {
      ViewModel.showSourceOfSaleFields = features && features.sourceOfSale && sale.sourceOfSale;
      ViewModel.showReviewFields = ViewModel.showSourceOfSaleFields && sale.sourceOfSale.status === 'CONFIRMED';
      ViewModel.editModeSOS = AuthService.hasPermission('mis-update') && !ViewModel.editMode && sale.sourceOfSale && sale.sourceOfSale.status !== 'CONFIRMED';
    }

    function _initNCB(sale) {
      if (sale.ncb === undefined && ['onlineissuance', 'pi-central', 'pi-branch', 'opsupload', 'opsmanual'].includes(sale.creationSource)) {
        ViewModel.currentSale.ncb = 0;
      }
    }

    function setEndDate(saleCopy) {
      if (saleCopy.tpRiskEndDate > saleCopy.odRiskEndDate) {
        saleCopy.endDate = saleCopy.tpRiskEndDate;
      } else {
        saleCopy.endDate = saleCopy.odRiskEndDate;
      }
    }
  }
})();
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('addNewAutoSaleCtrl', controller);

  controller.$inject = ['$scope', 'commonService', '$state', '$window', 'AuthService', '$mdDialog', '$timeout', 'sale', '$anchorScroll', 'SaleService', '$mdToast', 'MasterFileService', 'TrackingService', 'AutoSaleService'];

  function controller($scope, commonService, $state, $window, AuthService, $mdDialog, $timeout, sale, $anchorScroll, SaleService, $mdToast, MasterFileService, TrackingService, AutoSaleService) {
    var ViewModel = $scope;
    var originalObj = {};
    var mxpData = void 0;
    var eventPrefix = 'MIS_AUTO_PARSE_FORM';

    var documentApiUrl = MasterFileService.getUploadUrl();
    var features = commonService.getItemSession('features', 'mis');

    ViewModel.init = init;
    ViewModel.saveCurrentSale = saveCurrentSale;
    ViewModel.startEditMode = startEditMode;
    ViewModel.stopEditMode = stopEditMode;
    ViewModel.goToQCError = goToQCError;
    ViewModel.hideOtherFields = hideOtherFields;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.selectBQP = selectBQP;
    ViewModel.updateBQP = updateBQP;
    ViewModel.cancelBQP = cancelBQP;

    var readOnlyActions = [{
      type: 'button',
      name: 'Close',
      action: _onCancelAction,
      permissionCode: 'mis-detail',
      disabled: false
    }];

    var editActions = [{
      type: 'button',
      action: changeMode,
      permissionCode: 'mis-update',
      name: 'Switch to Manual mode',
      dataAuto: 'manual-mis-button'
    }, {
      type: 'button',
      action: saveCurrentSale,
      permissionCode: 'mis-update',
      name: 'Parse & Save Sale',
      dataAuto: 'save-mis-button'
    }, {
      type: 'button',
      name: 'Close',
      action: _onCancelAction,
      permissionCode: 'mis-update',
      disabled: false
    }];

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function init() {
      ViewModel.currentSale = sale;
      var goBack = ViewModel.hasPermission('mis-incomplete-list-read') ? 'mis-incomplete-list' : 'mis-all-list';
      ViewModel.editMode = AuthService.hasPermission('mis-create');
      ViewModel.navbarConfig = {
        goBackState: goBack,
        vertical: 'mis',
        mode: 'EDIT',
        title: ViewModel.currentSale.policyDetailsId
      };

      if (ViewModel.editMode) {
        _setNavbarConfig('EDIT');
      } else {
        _setNavbarConfig('READ_ONLY');
      }

      _initFormConfigs();
      _setFileConfig();

      mxpData = {
        policyDetailsId: ViewModel.currentSale.policyDetailsId,
        vertical: ViewModel.currentSale.vertical
      };
    }

    function _setFileConfig() {
      ViewModel.fileConfig = {
        tagOptions: {
          tagging: true
        },
        options: {
          $$viewMode: false,
          viewFileCallBackFn: viewFileCallBackFn,
          closeFileCallBackFn: closeFileCallBackFn,
          uploadCallBackFn: uploadCallBackFn,
          deleteCallBackFn: deleteCallBackFn,
          changeTagCallBackFn: changeTagCallBackFn,
          parseCallBackFn: parseDocument,
          preUploadValidator: preUploadValidator
        },
        eventTrackingData: {
          module: 'MIS',
          mxpData: mxpData
        }
      };
      _setFilePermissions(ViewModel.editMode);
      _initFileIdentifiers();
    }

    //Close Dialog Box On Browser Back Button
    $scope.$on('$locationChangeStart', function (event) {
      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }
    });

    function _initFileIdentifiers() {
      var fileUploadIdentifiers = {
        policyDetailsId: ViewModel.currentSale.policyDetailsId,
        clientUid: ViewModel.currentSale.policyDetailsId
      };
      if (ViewModel.currentSale.requestId) {
        fileUploadIdentifiers.requestId = ViewModel.currentSale.requestId;
        fileUploadIdentifiers.clientUid = ViewModel.currentSale.requestId;
      }
      ViewModel.fileConfig.identifiers = fileUploadIdentifiers;
    }

    function proposerDetailsCopyFn(sale) {
      var selfMember = {
        fname: sale.policyProposerFName || '',
        lname: sale.policyProposerLName || '',
        dob: sale.policyProposerDob || ''
      };
      if (sale) {
        if (sale.members && sale.members.length > 0) {
          var idx = sale.members.findIndex(function (ele) {
            return ele.relationship === 'SELF';
          });
          if (idx !== -1) {
            sale.members[idx] = _extends({}, sale.members[idx], selfMember);
          }
        }
      }
    }

    /**
     * Hide Other fields on UI
     *
     * @public
     *
     * @function
     *
     * @author sanketapte
     *
     */
    function hideOtherFields() {
      return true;
    }

    /**
     * on Cancel Action function
     *
     * @private
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function _onCancelAction() {
      commonService.removeItemSession('mis', 'action');
      TrackingService.pushToGTMDatalayer(eventPrefix + '_CANCEL', 'track_event', mxpData);
      goTo(ViewModel.navbarConfig.goBackState);
    }

    /**
     * convert Date Fields Before Request function
     *
     * @private
     *
     * @function
     *
     * @param    {Array}           sale       sale
     *
     * @author beerpratapsingh
     *
     */
    function _convertDateFieldsBeforeRequest(sale) {
      var dateFields = ['issuanceDate', 'covernoteIssuanceDate', 'saleClosedDate', 'inceptionDate', 'startDate', 'endDate', 'documentReceiptDate', 'refundDate', 'policySentToCustomerDate', 'createdAt', 'policyProposerDob', 'nomineeDob', 'ppOpendate', 'ppCloseDate', 'cancellationDate', 'odRiskStartDate', 'odRiskEndDate', 'tpRiskStartDate', 'tpRiskEndDate', 'chequeReceivedDate', 'activeTPRiskEndDate', 'activeTPRiskStartDate', 'previousODRiskEndDate', 'previousODRiskStartDate', 'vehicleRegDate', 'firstTimeTaggedAsIssuedDate'];
      for (var idx = dateFields.length - 1; idx >= 0; idx--) {
        var dateFieldCode = dateFields[idx];
        var config = SaleService.getConfigObjectFromCode(dateFieldCode);
        if (sale[config.code]) {
          sale[config.code] = parseInt(moment(sale[config.code]).format('x'));
        }
      }
    }

    /**
     * set Navbar Config function
     *
     * @private
     *
     * @function
     *
     * @param    {!String}           mode       mode
     *
     * @author beerpratapsingh
     *
     */

    function _setNavbarConfig(mode) {
      switch (mode) {
        case 'EDIT':
          ViewModel.navbarConfig.actions = angular.copy(editActions);
          ViewModel.navbarConfig.subtitle = '(Edit Mode)';
          break;
        case 'READ_ONLY':
          ViewModel.navbarConfig.actions = angular.copy(readOnlyActions);
          ViewModel.navbarConfig.subtitle = '(View Mode)';
          break;
        default:
          ViewModel.navbarConfig.actions = readOnlyActions;
          ViewModel.navbarConfig.subtitle = '(View Mode)';
      }
    }

    /**
     * start Edit Mode function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function startEditMode() {
      SaleService.acquirePolicyLock(ViewModel.currentSale.policyDetailsId).then(function () {
        ViewModel.editMode = true;
        _setNavbarConfig('EDIT');
      });
    }

    /**
     * stop Edit Mode function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function stopEditMode() {
      SaleService.releasePolicyLock(ViewModel.currentSale.policyDetailsId).then(function () {
        ViewModel.editMode = false;
        _setNavbarConfig('VIEW');
        if (ViewModel.currentSale) {
          _initSOS(ViewModel.currentSale);
        }
      });
    }

    /**
     * set Focus function
     *
     * @private
     *
     * @function
     *
     * @param    {!String}           id       Find ID
     *
     * @author beerpratapsingh
     *
     */
    function _setFocus(id) {
      var someElement = $window.document.getElementById(id);
      someElement.focus();
    }

    /**
     * go To QCError function
     *
     * @public
     *
     * @function
     *
     * @param    {obj}           errorObj       errorObj
     *
     * @author beerpratapsingh
     *
     */
    function goToQCError(errorObj) {
      $anchorScroll(errorObj.field);
      if (ViewModel.editMode) {
        _setFocus(errorObj.field);
      }
    }

    /**
     * compare Almost Equal Validity function
     *
     * @private
     *
     * @function
     *
     * @param    {!Number}           a,b,almostValue       Number
     *
     * @author beerpratapsingh
     *
     */
    // function _compareAlmostEqualValidity(a, b, almostValue) {
    //   if(a >= b) {
    //     if((a - b) > almostValue) {
    //       return false;
    //     }

    //     return true;
    //   } else {
    //     _compareAlmostEqualValidity(b, a, almostValue);
    //   }
    // }

    /**
     * compare total OD Premium and total TP Premium as per tolerance of (-5 or +5)
     *
     * @private
     *
     * @function
     *
     * @param               netOdPremium,tpPremium,netPremium
     *
     * @author pushpakumarinavik
     *
     */
    // function _validateNetPremium(currentSale){
    //   let totalNetPremium, error;
    //   if (currentSale.productName === 'comprehensive' && currentSale.netOdPremium >= 0 && currentSale.tpPremium>=0) {
    //     totalNetPremium = currentSale.netOdPremium + currentSale.tpPremium;
    //     error = 'Sum of Total OD Premium and Total TP Premium is not equal to Net Premium \n';
    //   } else if (currentSale.productName === 'tp' && currentSale.tpPremium>=0) {
    //     totalNetPremium = currentSale.tpPremium;
    //     error = 'Total TP Premium is not equal to Net Premium \n';
    //   } else if (currentSale.productName === 'od' && currentSale.netOdPremium>=0) {
    //     totalNetPremium = currentSale.netOdPremium;
    //     error = 'Total OD Premium is not equal to Net Premium \n';
    //   }
    //   let inValid = (!((totalNetPremium - 5) <= currentSale.netPremium && currentSale.netPremium <= (totalNetPremium + 5)));
    //   return {inValid, error};
    // }

    /**
     * save current sale function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */

    function _findFormValidity(currentSale) {
      delete currentSale.$$policyNotUploaded;

      currentSale.$$policyNotUploaded = true;
      if (_findNoOfDocsByTag('POLICY') > 0) {
        currentSale.$$policyNotUploaded = false;
      }

      return !currentSale.$$policyNotUploaded;
    }

    function _findNoOfDocsByTag(tagCode) {
      var count = ViewModel.currentSale.$$attachedDocuments.filter(function (ele) {
        return ele.tag === tagCode;
      }).length;
      return count;
    }

    function _displayValidityError(currentSale) {
      var errorMsg = '';

      if (currentSale.$$policyNotUploaded) {
        errorMsg += ' Please upload Policy PDF document.';
      }

      commonService.showToast(errorMsg);
    }

    function saveCurrentSale() {

      if (!ViewModel.currentSaleForm.$dirty) {
        commonService.showToast('No changes to save');
        return;
      }

      if (ViewModel.currentSaleForm.$invalid) {
        angular.forEach(ViewModel.currentSaleForm.$error.required, function (field) {
          field.$setDirty();
          $anchorScroll(field.$name);
        });
        commonService.showToast('Please fill the mandatory fields.');
        return;
      }

      var formValidity = _findFormValidity(ViewModel.currentSale);
      if (!formValidity) {
        _displayValidityError(ViewModel.currentSale);
        return;
      }

      if (ViewModel.currentSaleForm.$valid) {

        var validity = true;
        var validityMessage = '';
        var goToField = null;
        var cs = ViewModel.currentSale;

        if (ViewModel.currentSale.policyProposerEmailNotAvailable) {
          ViewModel.currentSale.policyProposerEmail = null;
        }

        if (ViewModel.currentSale.policyProposerMobileNotAvailable) {
          ViewModel.currentSale.policyProposerMobile = null;
        }

        // Checking for these validation only at the time of save
        // if (SaleService.isPolicyActive(cs.policyStatus) && cs.productName) {
        //   let validNetPremium = _validateNetPremium(cs);
        //   if (validNetPremium.inValid) {
        //     validity = false;
        //     validityMessage += validNetPremium.error;
        //     if(!goToField) {
        //       goToField = 'netOdPremium';
        //     }
        //   }
        // }

        // if(ViewModel.currentSale.netPremium && ViewModel.currentSale.serviceTax && ViewModel.currentSale.grossPremium) {
        //   if(!_compareAlmostEqualValidity((ViewModel.currentSale.netPremium + ViewModel.currentSale.serviceTax), ViewModel.currentSale.grossPremium, 3)) {
        //     validity = false;
        //     validityMessage += 'Sum of Net Premium and Service Tax should be equal to Gross Premium.\n';
        //     if(!goToField) {
        //       goToField = 'netPremium';
        //     }

        //   }
        // }

        if (ViewModel.currentSale.saleClosedDate && ViewModel.currentSale.issuanceDate) {
          var dateComparator = moment(ViewModel.currentSale.saleClosedDate).isAfter(ViewModel.currentSale.issuanceDate, 'day');
          if (dateComparator) {
            validity = false;
            validityMessage += 'Sale Closed Date can not be after Policy Issuance Date.\n';
            if (!goToField) {
              goToField = 'saleClosedDate';
            }
          }
        }

        if (ViewModel.currentSale.productCategory === 'LIFE' && ViewModel.currentSale.policyStatus === 'ACTIVE') {
          if (ViewModel.currentSale.$$attachedGroupedDocuments) {
            if (!ViewModel.currentSale.$$attachedGroupedDocuments.Policy && !ViewModel.currentSale.applicationNumber) {
              validity = false;
              validityMessage += 'Application Number is required.\n';
              if (!goToField) {
                goToField = 'applicationNumber';
              }
            }
          } else if (!ViewModel.currentSale.applicationNumber) {
            validity = false;
            validityMessage += 'Application Number is required.\n';
            if (!goToField) {
              goToField = 'applicationNumber';
            }
          }

          //   //Check if PPT > PT
          //   if (ViewModel.currentSale.policyPremiumTerm > ViewModel.currentSale.policyTerm) {
          //     validity = false;
          //     validityMessage += 'Policy premium term cannot be greater than policy term.\n';
          //   }
        }

        // //Check RTO Location Valid
        // if (cs.policyStatus === 'ACTIVE' && (cs.productCategory === 'TW' || cs.productCategory === 'FW')) {
        //   if (cs.rtoLocation === null || cs.rtoLocation === undefined) {
        //     _clearAutoconfig('rtoLocation');
        //     validity = false;
        //     validityMessage += 'RTO Location is required. Please select a current value\n';
        //   }
        // }

        // //Check Registration Number validity
        // if(['TW' /*, 'CV', 'FW'*/].includes(ViewModel.currentSale.vehicleType) && ViewModel.currentSale.$$selectedVertical){
        //   if(ViewModel.currentSale.$$selectedVertical !== ViewModel.currentSale.$$assignedVertical){
        //     validity = false;
        //     validityMessage += 'The \'Vehicle Type\' selected by you does not match with vehicle type as per your \'Registration Number\'.';

        //     if(!goToField) {
        //       goToField = 'registrationNumber';
        //     }
        //   }
        // }

        if (SaleService.isPolicyActive(cs.policyStatus) && cs.productCategory === 'HEALTH') {
          if (!cs.members || cs.members && cs.members.length < 1) {
            validity = false;
            validityMessage += 'Please add at least 1 member.';
          }
        }

        SaleService.addressCopyFn(ViewModel.currentSale);
        var emptyCheckList = ['registrationAddress', 'registrationCity', 'registrationPincode', 'registrationState', 'communicationAddress', 'communicationCity', 'communicationPincode', 'communicationState'];
        emptyCheckList.forEach(function (field) {
          return checkEmptyVal(field, ViewModel.currentSale);
        });

        if (validity) {

          var saleCopy = angular.copy(ViewModel.currentSale);
          _convertDateFieldsBeforeRequest(saleCopy);

          if (ViewModel.currentSaleForm.tlName && ViewModel.currentSaleForm.tlName.$$rawModelValue) {
            if (!saleCopy.tlName) {
              saleCopy.tlName = ViewModel.currentSaleForm.tlName.$$rawModelValue;
            } else if (saleCopy.tlName !== ViewModel.currentSaleForm.tlName.$$rawModelValue) {
              saleCopy.tlName = ViewModel.currentSaleForm.tlName.$$rawModelValue;
            }
          }

          if (ViewModel.currentSaleForm.salesExecutive && ViewModel.currentSaleForm.salesExecutive.$$rawModelValue) {
            if (!saleCopy.salesExecutive) {
              saleCopy.salesExecutive = ViewModel.currentSaleForm.salesExecutive.$$rawModelValue;
            } else if (saleCopy.salesExecutive !== ViewModel.currentSaleForm.salesExecutive.$$rawModelValue) {
              saleCopy.salesExecutive = ViewModel.currentSaleForm.salesExecutive.$$rawModelValue;
            }
          }

          if (saleCopy.productCategory === 'TW' || saleCopy.productCategory === 'FW' && saleCopy.vehicleType === 'CAR') {
            if (saleCopy.multiyear) {
              saleCopy.startDate = saleCopy.odRiskStartDate;
              saleCopy.endDate = saleCopy.odRiskEndDate;
            } else {
              if (saleCopy.productName === 'od') {
                saleCopy.startDate = saleCopy.odRiskStartDate;
                saleCopy.endDate = saleCopy.odRiskEndDate;
              } else if (saleCopy.productName === 'tp') {
                saleCopy.tpRiskStartDate = saleCopy.startDate;
                saleCopy.tpRiskEndDate = saleCopy.endDate;
              } else {
                saleCopy.odRiskStartDate = saleCopy.startDate;
                saleCopy.odRiskEndDate = saleCopy.endDate;
                saleCopy.tpRiskStartDate = saleCopy.startDate;
                saleCopy.tpRiskEndDate = saleCopy.endDate;
              }
            }
          } else {
            delete saleCopy.multiyear;
            delete saleCopy.odRiskStartDate;
            delete saleCopy.odRiskEndDate;
            delete saleCopy.tpRiskStartDate;
            delete saleCopy.tpRiskEndDate;
          }
          if (saleCopy.members && saleCopy.members.length > 0) {
            proposerDetailsCopyFn(saleCopy);
            saleCopy.members.forEach(function (member) {
              if (member.dob) {
                member.dob = moment(member.dob).format('x');
              }
            });
          }

          if (features && features.lifePaymentSchedule && saleCopy.productCategory === 'LIFE') {
            checkUpdatedPaymentSchedule(saleCopy);
          } else {
            saleAddUpdate(saleCopy);
          }
        } else {
          if (goToField) {
            $anchorScroll(goToField);
          }

          $window.alert(validityMessage);
        }
      } else {
        for (var key in ViewModel.currentSaleForm.$error) {
          var errorArray = ViewModel.currentSaleForm.$error[key];
          for (var idx = 0; idx < errorArray.length; idx++) {
            var error = errorArray[idx];
            if (!error.$dirty) {
              ViewModel.currentSaleForm[error.$name].$setDirty();
            }
            $anchorScroll(error.$name);
            break;
          }
        }
      }
    }

    // function _clearAutoconfig(code) {
    //   SaleService.clearAutoconfig(code);
    // }

    function saleAddUpdate(saleCopy) {
      delete saleCopy.$$state;
      if (saleCopy && saleCopy.autoParsingEnabled) {
        saleCopy.autoParsingStatus = 'PENDING';
      }
      SaleService.addUpdate(saleCopy).then(function () {
        $mdToast.show($mdToast.simple().textContent('Sale updated!').action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
          if (response === 'ok') {
            $window.console.log('You clicked the \'DISMISS\' action.');
          }
        });
        stopEditMode();
        if ($state.current.name === 'mis-detail') {
          SaleService.getPolicyById($scope.currentSale.policyDetailsId).then(function (result) {
            sale = result;
            delete ViewModel.currentSale;
            $timeout(function () {
              init();
            }, 10);
          });
        } else {
          $state.go('mis-incomplete-list');
        }
      }).catch(function (err) {
        if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && err.policyDetailsId) {
          SaleService.openDuplicatePolicyModal(saleCopy, err.policyDetailsId);
        } else {
          var errMsg = err ? err : 'Policy Creation Failed!';
          $window.alert(errMsg);
        }
      });
    }

    function checkUpdatedPaymentSchedule(saleCopy) {
      var modelConfig = {
        valid: true,
        statement: 'Payment Schedule will get updated due to change in Policy Status / Risk Start Date. Are you sure you want to continue?'
      };
      if (originalObj.policyStatus === 'ACTIVE' && originalObj.policyStatus !== saleCopy.policyStatus || originalObj.startDate && originalObj.startDate !== saleCopy.startDate) {
        misSaveConfirmationModel(modelConfig, saleCopy);
      } else if (saleCopy.policyStatus === 'ACTIVE') {
        if (originalObj.policyPremiumTerm && originalObj.policyPremiumTerm !== saleCopy.policyPremiumTerm || originalObj.paymentFrequency && originalObj.paymentFrequency !== saleCopy.paymentFrequency) {
          modelConfig.valid = false;
          modelConfig.statement = 'Payment Schedule will get updated due to change in Premium Payment Term  / Payment Frequency. Are you sure you want to continue? If Yes, please select an Applicable From Date.';
          modelConfig.type = 'select';
          misSaveConfirmationModel(modelConfig, saleCopy);
        } else if (originalObj.netPremium && originalObj.netPremium !== saleCopy.netPremium) {
          modelConfig.valid = false;
          modelConfig.statement = 'Payment Schedule will get updated due to change in Net Premium. Are you sure you want to continue? If Yes, please select an Applicable From Date';
          modelConfig.type = 'date';
          misSaveConfirmationModel(modelConfig, saleCopy);
        } else {
          saleAddUpdate(saleCopy);
        }
      } else {
        saleAddUpdate(saleCopy);
      }
    }

    function misSaveConfirmationModel(modelConfig, saleCopy) {
      $mdDialog.show({
        templateUrl: 'dev/mis/templates/mis-save-confirmation.dialog.tpl.html',
        clickOutsideToClose: false,
        controller: 'misSaveConfirmModelController',
        locals: {
          modelConfig: modelConfig,
          sale: saleCopy,
          $scope: ViewModel
        }
      }).then(function (res) {
        saleCopy = res;
        saleAddUpdate(saleCopy);
      });
    }

    //Set null if field is empty
    function checkEmptyVal(field, model) {
      model[field] = model[field] || null;
    }

    /**
     * init form configs function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function _initFormConfigs() {
      ViewModel.preliminaryFields = AutoSaleService.preliminaryFields;
      ViewModel.basicDetailsConfig = AutoSaleService.basicDetailsConfig;
      ViewModel.saleFieldsConfig = AutoSaleService.saleFieldsConfig;
    }

    /**
     * Set permissions for file  function
     *
     * @private
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function _setFilePermissions(editMode) {
      var filePermissions = {
        view: ViewModel.hasPermission('mis-file-view'),
        upload: ViewModel.hasPermission('mis-file-upload'),
        download: ViewModel.hasPermission('mis-file-download'),
        delete: editMode && ViewModel.hasPermission('mis-file-delete'),
        changeTag: ViewModel.hasPermission('mis-file-changetag'),
        parse: ViewModel.hasPermission('mis-file-parse') && false
      };
      filePermissions.options = filePermissions.view || filePermissions.download || filePermissions.delete || filePermissions.changeTag;
      ViewModel.fileConfig.options.filePermissions = filePermissions;
    }

    /**
     * Function for delete file tag
     *
     * @public
     *
     * @function
     *
     * @param    {!Number}           index       Index of document to send for parsing
     *
     * @author snehilmodani
     *
     */
    function parseDocument(file) {
      var reqObj = {
        fileId: file.processInfo.pid
      };
      TrackingService.pushToGTMDatalayer('MIS_FILE_PARSE', 'track_event', _extends({}, mxpData, {
        fileId: file.processInfo.pid,
        tag: file.tag
      }));
      var promise = commonService.makeThirdPartyApiCall(documentApiUrl, '/misfileparse', reqObj, 'POST', false, false, false);
      promise.then(function (res) {
        if (res.data.statusCode === 200) {
          file.parsed = true;
          $state.go('mis-detail', {
            id: res.data.id,
            policyNumber: res.data.displayPolicyNumber
          });
        } else {
          $window.alert(res.data.message);
        }
      }).catch(function () {
        file.parsed = false;
      });
    }

    function viewFileCallBackFn() {
      ViewModel.currentSale.$$viewMode = true;
    }

    function closeFileCallBackFn() {
      ViewModel.currentSale.$$viewMode = false;
    }

    function uploadCallBackFn(newFile) {
      if (newFile.tag === 'POLICY') {
        ViewModel.currentSale.policyPDFPresent = true;
        ViewModel.currentSale.policyUrl = newFile.processInfo.pid;
        if (ViewModel.currentSale.$$state !== 'NEW') {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, true, newFile.processInfo.pid);
        }
      }
    }

    function deleteCallBackFn(deletedFile) {
      var documentCount = ViewModel.currentSale.$$attachedDocuments.filter(function (ele) {
        return ele.tag === deletedFile.tag;
      }).length;
      if (deletedFile.tag === 'POLICY' && documentCount === 0) {
        ViewModel.currentSale.policyPDFPresent = false;
        delete ViewModel.currentSale.policyUrl;
        if (ViewModel.currentSale.$$state !== 'NEW') {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, false, deletedFile.processInfo.pid);
        }
      }
    }

    function changeTagCallBackFn(fileItem, oldTag) {
      var getPolicyPDF = ViewModel.currentSale.$$attachedDocuments;
      var policyPdf = false;
      if (fileItem.tag === 'POLICY') {
        policyPdf = true;
        ViewModel.currentSale.policyUrl = fileItem.processInfo.pid;
        SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, policyPdf, fileItem.processInfo.pid);
      } else if (oldTag === 'POLICY' && fileItem.tag !== 'POLICY') {
        var count = getPolicyPDF.filter(function (ele) {
          return ele.tag === 'POLICY';
        }).length;
        if (count > 1) {
          policyPdf = true;
        } else {
          delete ViewModel.currentSale.policyUrl;
        }
        if (!policyPdf) {
          SaleService.updatePolicyUrl(ViewModel.currentSale.policyDetailsId, policyPdf, fileItem.processInfo.pid);
        }
      }
      ViewModel.currentSale.policyPDFPresent = policyPdf;
    }

    function selectBQP(val) {
      if (val) {
        _setNavbarConfig('EDIT_BQP');
        SaleService.getBQPList(ViewModel.currentSale);
      } else {
        _setNavbarConfig('VIEW');
      }
      ViewModel.editMode = !val;
      ViewModel.showReviewFields = val;
      ViewModel.editModeSOS = val;
    }

    function updateBQP(sale) {
      if (sale.bqp) {
        var req = {
          policyDetailsId: sale.policyDetailsId,
          brokerQualifiedPerson: sale.bqp
        };

        SaleService.saveBQPDetails(req).then(function (res) {
          if (res.status === 200) {
            $state.reload();
          }
        }).catch(function (err) {
          var errMsg = err.data ? err.data : 'Error. No BQPs found.';
          commonService.showToast(errMsg);
        });
      } else {
        commonService.showToast('Please select a BQP');
      }
    }

    function cancelBQP() {
      selectBQP(false);
      $state.reload();
    }

    function _initSOS(sale) {
      ViewModel.showSourceOfSaleFields = features && features.sourceOfSale && sale.sourceOfSale;
      ViewModel.showReviewFields = ViewModel.showSourceOfSaleFields && sale.sourceOfSale.status === 'CONFIRMED';
      ViewModel.editModeSOS = AuthService.hasPermission('mis-update') && !ViewModel.editMode && sale.sourceOfSale && sale.sourceOfSale.status !== 'CONFIRMED';
    }

    function changeMode() {
      var newMISData = commonService.getItemSession('mis', 'newMIS');
      newMISData.autoParsingEnabled = false;
      newMISData.typeOfFlow = 'MANUAL_FORM';
      commonService.setItemSession('mis', 'newMIS', newMISData);
      goTo('mis-create');
    }

    function preUploadValidator(data) {
      var tag = data.formDataObj.tag;
      var fileType = data.file.type;
      var policyDocumentCount = _findNoOfDocsByTag('POLICY');

      if ('POLICY' === tag && 'application/pdf' !== fileType) {
        commonService.showToast('Error! Upload PDF document of policy copy or choose Manual Mode');
        TrackingService.pushToGTMDatalayer(eventPrefix + '_POLICY_PDF_ERROR', 'track_event', mxpData);
        return false;
      }

      if (policyDocumentCount > 0 && tag === 'POLICY') {
        commonService.showToast('Error! Only 1 policy copy can be uploaded. Delete existing policy copy to upload another file.');
        TrackingService.pushToGTMDatalayer(eventPrefix + '_POLICY_COUNT_ERROR', 'track_event', mxpData);
        return false;
      }

      return true;
    }

    function goTo(page) {
      $state.go(page);
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.mis').controller('CreateMISDialogCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'AutoSaleService', 'commonService'];

  function controller($scope, $mdDialog, AutoSaleService, commonService) {
    var ViewModel = $scope;
    $scope.cancel = $mdDialog.cancel;
    $scope.autoParseCheck = autoParseCheck;

    init();

    function init() {
      ViewModel.form = {};
      ViewModel.editMode = true;
      ViewModel.preliminaryFields = AutoSaleService.preliminaryFields;
    }

    function autoParseCheck(form) {
      if (!ViewModel.createMISForm.$dirty) {
        commonService.showToast('Please fill the mandatory fields');
        return;
      }
      if (ViewModel.createMISForm.$invalid) {
        angular.forEach(ViewModel.createMISForm.$error.required, function (field) {
          field.$setDirty();
        });
        commonService.showToast('Please fill the mandatory fields');
        return;
      }
      var reqObj = {
        insurer: form.insurer,
        policyStatus: form.policyStatus
      };
      if (form.vertical === 'FW') {
        reqObj.productCategory = form.$$assignedVertical;
      } else {
        reqObj.productCategory = form.$$vertical;
      }
      AutoSaleService.autoParsingValidation(reqObj).then(function (res) {
        form.autoParsingEnabled = res ? true : false;
        form.typeOfFlow = res ? 'AUTO_PARSING' : 'MANUAL_FORM';
        $mdDialog.hide(form);
      }).catch(function () {
        $mdDialog.hide(form);
      });
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.mis').controller('bulkExportCtrl', controller);

  controller.$inject = ['$q', '$scope', '$mdDialog', 'SaleService', '$window'];

  /**
   *
   * Bulk Export Dialog Controller
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @param     {Object}    $q                          Angular Q instance
   * @param     {Object}    $scope                      Angular scope
   * @param     {Object}    $mdDialog                   mdDialog instance
   * @param     {Object}    SaleService                 SaleService
   *
   */
  function controller($q, $scope, $mdDialog, SaleService, $window) {

    var ViewModel = $scope;

    ViewModel.closeDialog = closeDialog;
    ViewModel.bulkExport = bulkExport;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.editMode = true;
      ViewModel.exportConfig = {
        fileName: 'bulk_data',
        dateField: 'issuanceDate'
      };

      var bulkExpDateFields = [{
        'displayText': 'Policy issuance date',
        'value': 'issuanceDate'
      }, {
        'displayText': 'Sale closed date',
        'value': 'saleClosedDate'
      }, {
        'displayText': 'Record creation date',
        'value': 'createdAt'
      }, {
        'displayText': 'Risk start date',
        'value': 'startDate'
      }];

      ViewModel.bulkExportConfig = [{
        name: 'File Name',
        code: 'fileName',
        type: 'text'
      }, {
        name: 'Filter By',
        code: 'filterBy',
        type: 'select',
        selectConfig: {
          options: bulkExpDateFields,
          valueKey: 'value',
          textKey: 'displayText'
        }
      }, {
        name: 'From Date',
        code: 'startDate',
        type: 'date'
      }, {
        name: 'To Date',
        code: 'endDate',
        type: 'date'
      }];
    }

    /**
     * Function for Export file details
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */
    function bulkExport() {

      var deferred = $q.defer();
      ViewModel.editMode = false;

      SaleService.bulkExport(ViewModel.exportConfig).then(function (response) {
        $mdDialog.hide(response);
        deferred.resolve(response);
      }).catch(function (err) {
        $window.alert('No records found');
        deferred.reject(err);
      }).finally(function () {
        ViewModel.editMode = true;
      });

      return deferred.promise;
    }

    /**
     * Close the Current Dialog
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     */
    function closeDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('PaymentScheduleCtrl', controller);

  controller.$inject = ['$scope', '$state', 'AuthService', '$mdDialog', 'schedule', 'ninjaCommonData', '$stateParams', 'TrackingService'];

  function controller($scope, $state, AuthService, $mdDialog, schedule, ninjaCommonData, $stateParams, TrackingService) {
    var ViewModel = $scope;
    ViewModel.paymentModesList = angular.copy(ninjaCommonData.paymentModes);
    ViewModel.customerPaymentStatus = angular.copy(ninjaCommonData.customerPaymentStatus);
    ViewModel.paymentFrequencyList = angular.copy(ninjaCommonData.paymentFrequency);
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.editScheduleEntry = editScheduleEntry;
    ViewModel.addTopUp = addTopUp;
    ViewModel.show = {
      regular: true,
      topup: false
    };

    var basicDetailsConfig = [{
      name: 'MIS ID',
      code: 'policyDetailId',
      type: 'text',
      disabled: true
    }, {
      name: 'Payment Frequency',
      code: 'paymentFrequency',
      type: 'select',
      disabled: true,
      selectConfig: {
        options: ViewModel.paymentFrequencyList,
        valueKey: 'code',
        textKey: 'name'
      }
    }, {
      name: 'Premium Paying Term',
      code: 'premiumPayingTerm',
      type: 'text',
      disabled: true
    }, {
      name: 'Policy Term',
      code: 'policyTerm',
      type: 'text',
      disabled: true
    }, {
      name: 'Net Premium',
      code: 'netPremium',
      type: 'number',
      min: 0,
      disabled: true
    }];

    init();

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function init() {
      ViewModel.policyDetailId = $stateParams.id;
      ViewModel.navbarConfig = {
        goBackState: 'mis-all-list',
        vertical: 'mis',
        mode: 'VIEW'
      };
      ViewModel.navbarConfig.title = 'Payment Schedule for ' + ViewModel.policyDetailId;
      ViewModel.navbarConfig.actions = [{
        type: 'button',
        action: closePaymentSchedule,
        name: 'Close',
        dataAuto: 'close-paymentschedule-button'
      }];

      setPaymentSchedule(schedule);
    }

    /**
     * closePaymentSchedule function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function closePaymentSchedule() {
      $state.go('mis-detail', { 'id': ViewModel.policyDetailId });
    }

    /**
     * getPaymentSchedule function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function setPaymentSchedule(schedule) {
      ViewModel.basicDetails = schedule.basicDetail;
      ViewModel.basicDetails.policyDetailId = schedule.misId;
      ViewModel.regularPayment = schedule.regular.sort(function (a, b) {
        return b.paymentNumber - a.paymentNumber;
      });
      ViewModel.topUpPayment = schedule.topup.sort(function (a, b) {
        return b.paymentNumber - a.paymentNumber;
      });
      ViewModel.basicDetailsConfig = basicDetailsConfig;
    }

    /**
     * addTopUp function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function addTopUp() {
      var newTopUp = {
        type: 'TOPUP',
        recordStatus: 'VALID',
        policyDetailId: ViewModel.policyDetailId,
        paymentFrequency: '4',
        dpId: '',
        newEntry: true
      };

      editScheduleEntry(newTopUp);
    }

    /**
     * editScheduleEntry function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function editScheduleEntry(entry, paymentList) {
      var lists = {
        paymentStatusList: getPaymentStatusList(entry, paymentList),
        paymentFrequencyList: ViewModel.paymentFrequencyList,
        paymentModesList: ViewModel.paymentModesList
      };
      $mdDialog.show({
        templateUrl: 'dev/mis/templates/schedule-entry.dialog.tpl.html',
        clickOutsideToClose: false,
        controller: 'ScheduleEntryModalController',
        locals: {
          scheduleEntry: angular.copy(entry),
          lists: lists
        }
      }).then(function (res) {
        var updatedEntry = res;
        if (updatedEntry && updatedEntry.type === 'REGULAR') {
          var idx = ViewModel.regularPayment.findIndex(function (ele) {
            return ele.paymentNumber === updatedEntry.paymentNumber;
          });
          ViewModel.regularPayment[idx] = updatedEntry;
        } else if (updatedEntry && updatedEntry.type === 'TOPUP') {
          var _idx = ViewModel.topUpPayment.findIndex(function (ele) {
            return ele.paymentNumber === updatedEntry.paymentNumber;
          });
          ViewModel.topUpPayment[_idx] = updatedEntry;
        }
        var mxpData = {
          policyDetailId: ViewModel.policyDetailId,
          type: updatedEntry.type
        };
        TrackingService.pushToGTMDatalayer('MIS_PAYMENT_SCHEDULE_UPDATE', 'track_event', mxpData);
      });
    }

    /**
     * set Payment Status List  function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function getPaymentStatusList(entry, paymentList) {
      var entryType = entry.type;
      var paymentNumber = entry.paymentNumber;
      var paymentStatusList = [];
      var prevEntry = void 0,
          nextEntry = void 0;
      paymentList = paymentList.sort(function (a, b) {
        return b.paymentNumber - a.paymentNumber;
      });
      var maxPaymentNumber = paymentList[0].paymentNumber;
      var idx = paymentList.findIndex(function (ele) {
        return ele.paymentNumber === paymentNumber;
      });

      var map = {};
      var paidEntry = 'PAID';
      var unpaidEntry = 'UNPAID';
      var futureEntry = 'FUTURE_PAYMENT';
      ViewModel.customerPaymentStatus.forEach(function (ele) {
        map[ele.code] = ele;
      });

      if (entryType === 'REGULAR') {
        if (paymentNumber === 1) {
          paymentStatusList.push(map[paidEntry]);
        } else {
          prevEntry = paymentList[idx + 1];
          nextEntry = paymentList[idx - 1];
          prevEntry = prevEntry && prevEntry.customerPaymentStatus;
          nextEntry = nextEntry && nextEntry.customerPaymentStatus;

          if (prevEntry && prevEntry === paidEntry) {
            paymentStatusList.push(map[paidEntry]);
          }

          if (nextEntry && (nextEntry === unpaidEntry || nextEntry === futureEntry)) {
            paymentStatusList.push(map[unpaidEntry]);
          }

          if (paymentNumber !== 1 && paymentNumber === maxPaymentNumber) {
            paymentStatusList.push(map[unpaidEntry]);
            paymentStatusList.push(map[futureEntry]);
          }
        }
      } else {
        paymentStatusList.push(map[paidEntry]);
      }
      return paymentStatusList;
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('ScheduleEntryModalController', ScheduleEntryModalController);

  ScheduleEntryModalController.$inject = ['$scope', '$mdDialog', 'scheduleEntry', 'lists', 'AuthService', 'paymentScheduleService', 'commonService'];

  function ScheduleEntryModalController($scope, $mdDialog, scheduleEntry, lists, AuthService, paymentScheduleService, commonService) {

    $scope.scheduleEntry = scheduleEntry;
    $scope.entryType = scheduleEntry.type;
    $scope.editMode = AuthService.hasPermission(scheduleEntry.type === 'REGULAR' ? 'mis-payment-regular-update' : 'mis-payment-topup-update');

    $scope.cancel = cancel;
    $scope.save = save;
    init();

    function init() {
      $scope.scheduleConfig = paymentScheduleService.getScheduleConfig($scope.scheduleEntry, lists);
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function save(scheduleEntry) {
      if ($scope.scheduleEntryForm.$dirty) {
        angular.forEach($scope.scheduleEntryForm.$error.required, function (field) {
          field.$setDirty();
        });

        if ($scope.scheduleEntryForm.$valid) {
          var entryType = scheduleEntry.type;
          var reqObj = {
            misId: scheduleEntry.policyDetailId
          };
          if (!scheduleEntry.newEntry) {
            if (entryType === 'REGULAR') {
              scheduleEntry.paidPremium = scheduleEntry.netPremium;
              reqObj.schedule = {};
              reqObj.schedule[scheduleEntry.paymentNumber] = scheduleEntry;
            } else {
              reqObj.topup = {};
              reqObj.topup[scheduleEntry.paymentNumber] = scheduleEntry;
            }
            paymentScheduleService.updatePaymentSchedule(reqObj).then(function (res) {
              var data = res.data;
              if (data && data.statusCode && data.statusCode === 200) {
                var entry = data.paymentSchedules && data.paymentSchedules[0];
                $mdDialog.hide(entry);
              } else {
                commonService.showToast(data.message);
              }
            }).catch(function () {
              commonService.showToast('Schedule entry update failed.');
            });
          }
        } else {
          commonService.showToast('Enter all required fields');
        }
      } else {
        commonService.showToast('No changes to save');
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.mis').controller('misSaveConfirmModelController', controller);

  /**
   *
   * mis save confirmation dialog controller
   *
   */
  controller.$inject = ['$scope', '$mdDialog', 'modelConfig', 'sale', 'commonService'];

  function controller($scope, $mdDialog, modelConfig, sale, commonService) {
    var ViewModel = $scope;
    var today = moment().toDate();
    var isPaidStartSame = sale.paidTillDate !== sale.startDate ? true : false;
    var maxCheck = sale.premiumEndDate && moment(sale.premiumEndDate).isBefore(today, 'days');
    var paidTillDatePlusOne = sale.paidTillDate && moment(sale.paidTillDate).add(1, 'days');

    var minDateVal = sale.paidTillDate ? isPaidStartSame ? paidTillDatePlusOne : sale.paidTillDate : sale.startDate;
    var maxDateVal = maxCheck ? sale.premiumEndDate : today;
    maxDateVal = isPaidStartSame ? moment(maxDateVal).subtract(1, 'days') : maxDateVal;

    ViewModel.anniversaryDateList = [];
    var applicableFromDateConfig = {
      date: {
        name: 'Applicable from Date',
        code: 'applicableFromDate',
        placeholder: '',
        type: 'date',
        initFn: _applicableFromDateFn,
        changeFn: _applicableFromDateChangeFn,
        dateConfig: {
          maxDate: moment(maxDateVal).toDate(),
          minDate: moment(minDateVal).toDate()
        }
      },
      select: {
        name: 'Applicable from Date',
        code: 'applicableFromDate',
        type: 'select',
        initFn: _applicableFromDateFn,
        changeFn: _applicableFromDateFn,
        required: true,
        selectConfig: {
          options: ViewModel.anniversaryDateList,
          valueKey: 'code',
          textKey: 'name'
        }
      }
    };

    $scope.modelConfig = modelConfig;
    $scope.modelConfig.config = [];
    $scope.sale = sale;

    if ($scope.modelConfig.type === 'select') {
      getAnniversaryList(sale.policyDetailsId);
    }

    var item = applicableFromDateConfig[$scope.modelConfig.type];
    if ($scope.modelConfig.type) {
      $scope.modelConfig.config.push(item);
    }
    $scope.cancel = function () {
      $mdDialog.cancel();
    };
    $scope.save = function (sale) {
      $mdDialog.hide(sale);
      // saleAddUpdate(saleCopy);
      // $mdDialog.cancel();
    };

    function _applicableFromDateChangeFn(selectedItem, model) {
      model.applicableFromDate = parseInt(moment(selectedItem).format('x'));
      _applicableFromDateFn(selectedItem);
    }

    function _applicableFromDateFn(selectedItem) {
      if (selectedItem) {
        $scope.modelConfig.valid = true;
      } else {
        $scope.modelConfig.valid = false;
      }
    }

    function getAnniversaryList() {
      var reqUrl = '/schedule/payment/dates/anniversary?startDate=' + sale.startDate + '&premiumPayingTerm=' + sale.policyPremiumTerm;
      commonService.makeHttpCall(reqUrl, {}, 'GET', false, false, true).then(function (res) {
        res = res.data.anniversaryDates;
        res.forEach(function (ele) {
          var obj = {
            code: ele,
            name: moment(ele).format('DD-MMM-YYYY')
          };
          ViewModel.anniversaryDateList.push(obj);
        });
        var config = applicableFromDateConfig.select;
        config.selectConfig.options = ViewModel.anniversaryDateList;
      });
    }
  }
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').controller('PolicyIssuanceListCtrl', controller);

  controller.$inject = ['$rootScope', '$scope', '$state', '$mdDialog', '$mdSelect', 'CONSTANTS_LIST', 'AuthService', '$q', 'PolicyIssuanceService', 'MIS_CONSTANTS', '$timeout', 'pageType', 'PartnerService', 'commonService', 'DecentralizationService', 'TrackingService', '$mdBottomSheet'];

  /**
   *
   * PolicyIssuance List Controller
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @param     {Object}    $scope                       Angular scope
   * @param     {Object}    $state                       Angular State service
   * @param     {Object}    commonService                commonService instance
   *
   */
  function controller($rootScope, $scope, $state, $mdDialog, $mdSelect, CONSTANTS_LIST, AuthService, $q, PolicyIssuanceService, MIS_CONSTANTS, $timeout, pageType, PartnerService, commonService, DecentralizationService, TrackingService, $mdBottomSheet) {

    var ViewModel = $scope;
    var broker = sessionStorage.getItem('broker');
    var decentralization = commonService.getItemLocalStorage('decentralization', 'decentralisationEnable') || undefined;
    var misFeatures = commonService.getItemSession('features', 'mis');
    var piFeature = void 0;
    if (pageType === 'ISSUANCE') {
      piFeature = commonService.getItemSession('features', 'centralIssuance');
    } else {
      piFeature = commonService.getItemSession('features', 'branchIssuance');
    }
    var source = 'ONLINE';
    var eventPrefix = pageType === 'ISSUANCE' ? 'CI' : 'BI';
    var originalParams = {
      searchkey: undefined,
      page: 1,
      limit: commonService.isMobile() ? 15 : 50,
      filter: {
        vertical: [],
        status: [],
        inspectionRequired: [],
        inspectionStatus: [],
        inspectionSubStatus: [],
        piBranchLocation: [],
        paymentMode: [],
        adminOwner: [],
        sourceOfSaleStatus: [],
        sourceOfSaleType: [],
        policyType: [],
        operationsLocations: []
      }
    };

    var originalColumns = {
      'registrationNumber': {
        name: 'Registration No.',
        code: 'registrationNumber',
        selected: true
      },
      'createdAt': {
        name: 'Created At',
        code: 'createdAt',
        selected: true
      },
      'updatedAt': {
        name: 'Updated At',
        code: 'updatedAt',
        selected: true
      },
      'insurer': {
        name: 'Insurer',
        code: 'insurer',
        selected: true
      },
      'vertical': {
        name: 'Vertical',
        code: 'vertical',
        selected: true
      },
      'paymentMode': {
        name: 'Payment mode',
        code: 'paymentMode',
        selected: true
      },
      'customerName': {
        name: 'Customer name',
        code: 'customerName',
        selected: true
      },
      'owner': {
        name: 'Owner',
        code: 'Owner',
        selected: true
      },
      'issuanceStatus': {
        name: 'Issuance status',
        code: 'issuanceStatus',
        selected: true
      },
      'inspectionStatus': {
        name: 'Inspection status',
        code: 'inspectionStatus',
        selected: true
      },
      'inspectionSubStatus': {
        name: 'Inspection subStatus',
        code: 'inspectionSubStatus',
        selected: true
      },
      'piBranchLocation': {
        name: 'PI branch location',
        code: 'piBranchLocation',
        selected: pageType === 'BRANCH_ISSUANCE',
        disabled: pageType !== 'BRANCH_ISSUANCE'
      },
      'preSaleVer': {
        name: 'Pre sale verification',
        code: 'preSaleVer',
        selected: true
      },
      'inspection': {
        name: 'Inspection',
        code: 'inspection',
        selected: true
      },
      'policyType': {
        name: 'Product Type',
        code: 'policyType',
        selected: true
      }
    };

    if (showDpDetails()) {
      originalColumns.dpDetails = {
        name: 'DP details',
        code: 'dpDetails',
        selected: true
      };
    }

    if (pageType === 'ISSUANCE' && decentralization && decentralization.CENTRAL_ISSUANCE) {
      originalColumns.operationsLocations = {
        name: 'Ops Branch',
        code: 'operationsLocations',
        selected: true
      };
    }

    ViewModel.getPolicyIssuance = getPolicyIssuance;
    ViewModel.filterLists = {};
    ViewModel.loadMorePolicyIssuance = loadMorePolicyIssuance;
    ViewModel.onPaginate = onPaginate;
    ViewModel.onPageSelect = onPaginate;
    ViewModel.filterChange = filterChange;
    ViewModel.clearFilter = clearFilter;
    ViewModel.applyFilter = applyFilter;
    ViewModel.clearSelectedList = clearSelectedList;
    ViewModel.goToEdit = goToEdit;
    ViewModel.dpDetails = dpDetails;
    ViewModel.pageType = pageType;
    ViewModel.goTo = goTo;
    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.openDialog = openDialog;
    ViewModel.filterTxtBold = filterTxtBold;
    ViewModel.stopEventPropagation = commonService.stopEventPropagation;
    ViewModel.piFeature = piFeature;
    ViewModel.showActionForBI = showActionForBI;
    ViewModel.callPartner = callPartner;
    ViewModel.openFilterSheet = openFilterSheet;
    ViewModel.closeFilterSheet = closeFilterSheet;
    ViewModel.applyMobileFilter = applyMobileFilter;
    ViewModel.clearFilterSheet = clearFilterSheet;
    ViewModel.loadMore = {
      show: false,
      loading: false
    };
    ViewModel.filterLists.autoParsingStatusList = [{
      code: 'PENDING',
      name: 'Processing'
    }, {
      code: 'SUCCESS',
      name: 'Success'
    }, {
      code: 'FAILURE',
      name: 'Failure'
    }, {
      code: 'SUCCESS_DUPLICATE',
      name: 'Success (Duplicate)'
    }];
    ViewModel.restrictedSalesUser = {
      value: AuthService.isRestrictedSalesAccess(),
      message: 'Policy Issuance is currently unavailable for non Unit Manager Sales users. We are working on the fix. Thanks for your patience.'
    };
    commonService.removeItemSession('pi', 'isAccessReadOnly');
    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author snehilmodani
     *
     */
    function init() {
      if (!ViewModel.hasPermission('ci-list-read')) {
        ViewModel.goTo('bi-list');
      }

      var pageTitle = pageType === 'ISSUANCE' ? 'Issuance' : 'Branch Issuance';
      ViewModel.cardTitle = pageType === 'ISSUANCE' ? 'Issuance List' : 'Branch Issuance List';

      if (pageType === 'BRANCH_ISSUANCE') {
        source = 'BRANCH';
        ViewModel.showSOSStatus = broker === CONSTANTS_LIST.BROKER.TURTLEMINT && misFeatures && misFeatures.sourceOfSale ? true : false;
      }

      ViewModel.navbarConfig = {
        vertical: 'issuance',
        title: pageTitle,
        class: 'no-box-shadow',
        search: {
          enabled: true,
          searchFn: searchIssuance,
          placeholder: 'Search by Reg Number, Customer Name, DP Name, DP Phone Number'
        }
      };

      ViewModel.params = angular.copy(originalParams);
      ViewModel.newParams = angular.copy(originalParams);
      ViewModel.selectedColumnList = angular.copy(originalColumns);

      if (pageType === 'ISSUANCE') {
        ViewModel.params.filter = PolicyIssuanceService.issuanceDefaultFilter;
        ViewModel.newParams.filter = PolicyIssuanceService.issuanceDefaultFilter;
        if (decentralization && decentralization.CENTRAL_ISSUANCE) {
          var opsBranchList = commonService.getItemSession('ninjaFilters', 'opsBranchList');
          if (opsBranchList) {
            ViewModel.filterLists.opsBranchList = opsBranchList;
          } else {
            DecentralizationService.getNewBranchList().then(function (res) {
              ViewModel.filterLists.opsBranchList = res.data;
              commonService.setItemSession('ninjaFilters', 'opsBranchList', ViewModel.opsBranchList);
            });
          }
        }
      }

      ViewModel.options = {
        pageLimits: ['50', '100', '150', '200', '250', '500', '1000']
      };

      ViewModel.totalCount = 0;
      ViewModel.selectedInspectionStatus = [];

      ViewModel.filterLists.verticalList = angular.copy(MIS_CONSTANTS.productCategoriesIssuance);
      if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _ViewModel$filterList;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_ViewModel$filterList = ViewModel.filterLists.verticalList).push.apply(_ViewModel$filterList, _toConsumableArray(turtlemintProductCategories));
      }

      //Get Insure List for filter
      commonService.getInsurerList().then(function (insurersList) {
        ViewModel.filterLists.insurersList = insurersList;
      });

      if (sessionStorage.getItem(pageType + 'localFilters')) {
        ViewModel.params = JSON.parse(sessionStorage.getItem(pageType + 'localFilters'));
        ViewModel.newParams = JSON.parse(sessionStorage.getItem(pageType + 'localFilters'));
      }

      if (sessionStorage.getItem(pageType + 'localColumns')) {
        ViewModel.selectedColumnList = JSON.parse(sessionStorage.getItem(pageType + 'localColumns'));
      }

      ViewModel.filterLists.inspectionRequiredList = [{
        text: 'Required',
        value: true
      }, {
        text: 'Not Required',
        value: false
      }];

      getPolicyIssuance().then(function () {
        return PolicyIssuanceService.getAllFilters();
      }).then(function (res) {
        ViewModel.filterLists.issuanceTypes = res.issuancetype;
        ViewModel.filterLists.issuanceStatusList = res.issuancestatus;
        ViewModel.filterLists.inspectionStatusList = res.inspectionstatus;
        ViewModel.filterLists.paymentModeList = res.paymentmode;
        ViewModel.filterLists.inspectionSubStatusList = res.inspectionSubStatus;
        ViewModel.filterLists.sourceOfSaleStatusList = PolicyIssuanceService.saleConfig.sourceOfSaleStatus;
      });
    }

    ViewModel.filterLists.policyTypeList = [].concat(_toConsumableArray(MIS_CONSTANTS.productNames), _toConsumableArray(MIS_CONSTANTS.lifePlanTypes));
    // Make applied filter selection bold
    function filterTxtBold(key) {
      if (ViewModel.params.filter) {
        if (ViewModel.params.filter[key]) {
          if (ViewModel.params.filter[key].length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    function showDpDetails() {
      return AuthService.canShowPartnerDetails();
    }

    PolicyIssuanceService.getStatusList('adminOwner' + source, source).then(function (res) {
      ViewModel.filterLists.adminOwner = res;
    });

    PartnerService.getCities().then(function (res) {
      ViewModel.filterLists.cities = res;
    });

    if (ViewModel.pageType === 'BRANCH_ISSUANCE') {
      ViewModel.navbarConfig.actions = [{
        type: 'icon',
        class: 'pi-add-branch',
        action: _createBranchIssuance,
        name: 'Add Branch Issuance',
        permissionCode: 'bi-create',
        iconSrc: '/images/common/plus-icon.svg',
        dataAuto: 'new-branch-button'
      }];
    }

    function _createBranchIssuance() {
      commonService.setItemSession('pi', 'newBI', null);
      if (piFeature && piFeature.autoParsing) {
        TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_BI', 'track_event', {});
        $mdDialog.show({
          templateUrl: 'dev/policy-issuance/templates/dialogs/create-bi.dialog.tpl.html',
          controller: 'CreateBranchDialogCtrl',
          scope: $scope,
          preserveScope: true
        }).then(function (res) {
          commonService.setItemSession('pi', 'newBI', res);
          if (res.autoParsingEnabled) {
            TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_AUTO', 'track_event', res);
            goTo('bi-create-auto');
          } else {
            TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_MANUAL', 'track_event', res);
            goTo('bi-create');
          }
        }).catch(function () {
          TrackingService.pushToGTMDatalayer(eventPrefix + '_LIST_CREATE_DIALOG_CANCEL', 'track_event', {});
        });
      } else {
        goTo('bi-create');
      }
    }

    function goTo(page) {
      $state.go(page);
    }

    function filterChange() {
      if (!ViewModel.params) {
        ViewModel.params = angular.copy(originalParams);
      }

      if (ViewModel.params) {
        var sessionFilters = angular.copy(ViewModel.params);
        delete sessionFilters.searchKey;
        sessionStorage.setItem(pageType + 'localFilters', JSON.stringify(sessionFilters));
      }

      ViewModel.params.page = 1;
      getPolicyIssuance();
    }

    function applyFilter(filterName, selectedValues) {
      ViewModel.params.filter[filterName] = selectedValues;
      filterChange();
      $mdSelect.hide();
    }

    function clearFilter(filterName) {
      commonService.removeItemSession(pageType + 'localFilters');
      if (filterName) {
        ViewModel.params.filter[filterName] = [];
        clearSelectedList(filterName);
      } else {
        ViewModel.params = angular.copy(originalParams);
        ViewModel.newParams = ViewModel.params;
      }

      filterChange();
      $mdSelect.hide();
    }

    function clearSelectedList(filterName) {
      if (!ViewModel.newParams) {
        ViewModel.newParams = {};
      }

      if (!ViewModel.newParams.filter) {
        ViewModel.newParams.filter = {};
      }

      ViewModel.newParams.filter[filterName] = ViewModel.params.filter[filterName] || [];
      if (filterName === 'adminOwner') {
        ViewModel.searchOwner = '';
      }
    }

    function openDialog(ev) {
      $mdDialog.show({
        // scope: $scope,
        controller: 'selectedColumnDailog',
        templateUrl: 'dev/policy-issuance/templates/dialogs/selected-column-dialog.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        // preserveScope : true,
        resolve: {
          selectedColumn: function selectedColumn() {
            return ViewModel.selectedColumnList;
          }
        }
      }).then(function (res) {
        ViewModel.selectedColumnList = res;
        sessionStorage.setItem(pageType + 'localColumns', JSON.stringify(ViewModel.selectedColumnList));
      });
    }

    /**
     * Function for searching PolicyIssuances
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.policyIssuance.PolicyIssuanceListCtrl#
     *
     * @author    snehilmodani
     *
     * @return    {Promise}    Promise for search results.
     *
     */
    function searchIssuance(searchKey) {
      if (searchKey && searchKey.length >= 2) {
        ViewModel.params.searchKey = searchKey;
      } else {
        delete ViewModel.params.searchKey;
      }
      TrackingService.pushToGTMDatalayer(eventPrefix + '_SEARCH', 'track_event', {});
      return getPolicyIssuance();
    }

    var timeoutOngoing = false;

    function onPaginate(page, limit) {
      if (timeoutOngoing) {
        return;
      }

      timeoutOngoing = true;
      $timeout(function () {
        ViewModel.params.page = page;
        ViewModel.params.limit = limit;
        getPolicyIssuance();
        timeoutOngoing = false;
      }, 100);
    }

    function getPolicyIssuanceRecords() {
      var loadingDeferred = $q.defer();
      ViewModel.loadMore.loading = true;
      PolicyIssuanceService.getList(ViewModel.params, ViewModel.params.source).then(function (response) {
        var data = response.data;
        if (data && data.length >= ViewModel.params.limit) {
          ViewModel.loadMore.show = true;
        } else {
          ViewModel.loadMore.show = false;
        }
        ViewModel.loadMore.loading = false;
        loadingDeferred.resolve(response);
      }).catch(function () {
        ViewModel.loadMore.show = false;
        ViewModel.loadMore.loading = false;
        loadingDeferred.reject(true);
      });
      return loadingDeferred.promise;
    }

    function getPolicyIssuance() {
      var loadingDeferred = $q.defer();
      ViewModel.issuanceList = [];
      ViewModel.loadingPromise = loadingDeferred.promise;

      var newParams = angular.copy(originalParams);
      if (pageType === 'ISSUANCE') {
        newParams.source = 'ONLINE';
        ViewModel.params.source = 'ONLINE';
        ViewModel.pageTitle = 'Issuance';
      } else if (pageType === 'BRANCH_ISSUANCE') {
        newParams.source = 'BRANCH';
        ViewModel.params.source = 'BRANCH';
        ViewModel.pageTitle = 'Report a sale';
      }

      if (ViewModel.restrictedSalesUser.value) {
        return Promise.resolve(true);
      }
      getPolicyIssuanceRecords().then(function (response) {
        ViewModel.totalCount = response.count;
        ViewModel.issuanceList = response.data;
        if (pageType === 'ISSUANCE') {
          ViewModel.totalIssuanceCount = response.count;
        } else if (pageType === 'BRANCH_ISSUANCE') {
          ViewModel.totalBranchIssuanceCount = response.count;
        }
        if (ViewModel.params.searchKey && ViewModel.params.searchKey.length > 2 && ViewModel.totalCount <= 0) {
          commonService.showToast('No search data found.');
        }

        newParams.source = pageType === 'ISSUANCE' ? 'BRANCH' : 'ONLINE';
        return PolicyIssuanceService.getListCount(newParams);
      }).then(function (res) {
        if (pageType === 'ISSUANCE') {
          ViewModel.totalBranchIssuanceCount = res.count;
        } else if (pageType === 'BRANCH_ISSUANCE') {
          ViewModel.totalIssuanceCount = res.count;
        }
        loadingDeferred.resolve(true);
      }).catch(function () {
        loadingDeferred.reject(true);
      });

      return loadingDeferred.promise;
    }

    function loadMorePolicyIssuance() {
      ViewModel.params.page = ViewModel.params.page + 1;
      getPolicyIssuanceRecords().then(function (response) {
        ViewModel.totalCount = response.count;
        if (ViewModel.issuanceList && ViewModel.issuanceList.length > 0) {
          var _ViewModel$issuanceLi;

          (_ViewModel$issuanceLi = ViewModel.issuanceList).push.apply(_ViewModel$issuanceLi, _toConsumableArray(response.data));
        } else {
          ViewModel.issuanceList = response.data;
        }
      });
    }

    function showActionForBI(issuance) {
      if (issuance.sourceOfSale && issuance.sourceOfSale.status === 'PENDING') {
        if (issuance.typeOfFlow === 'AUTO_PARSING' && ['SUCCESS', 'SUCCESS_DUPLICATE', 'PENDING'].includes(issuance.autoParsingStatus)) {
          return false;
        }
        return true;
      }
      return false;
    }

    function goToEdit(issuance) {
      if (pageType === 'BRANCH_ISSUANCE' && issuance.typeOfFlow === 'AUTO_PARSING' && issuance.autoParsingStatus === 'PENDING') {
        commonService.showToast('Policy is still under process.');
        return;
      }

      var currentOwner = issuance.adminOwner && issuance.adminOwner.email;
      var currentUser = AuthService.getUser();
      var status = issuance.status;
      var nextState = null;
      var permissionPopup = null;
      var reqUrl = '/actionable';
      var body = {
        policyIssuanceId: issuance.policyIssuanceId,
        actionable: false
      };
      var mxpData = {
        policyIssuanceId: issuance.policyIssuanceId,
        requestId: issuance.requestId,
        vertical: issuance.vertical
      };
      if (pageType === 'ISSUANCE') {
        nextState = 'ci-detail';
        permissionPopup = ViewModel.hasPermission('ci-update') || ViewModel.hasPermission('ci-restrictedupdate');
      } else if (pageType === 'BRANCH_ISSUANCE') {
        nextState = 'bi-detail';
        permissionPopup = ViewModel.hasPermission('bi-update') || ViewModel.hasPermission('bi-restrictedupdate');
      }

      if (currentOwner !== currentUser.email) {
        if (permissionPopup && issuance.source === 'BRANCH' && issuance.typeOfFlow === 'AUTO_PARSING' && issuance.autoParsingStatus !== 'SUCCESS' && issuance.autoParsingStatus !== 'PENDING') {
          openOwnerPopup(issuance);
        } else if (permissionPopup && status !== 'ISSUED' && status !== 'CANCELLED' && status !== 'CANCELLED_REFUND_AWAITED') {
          openOwnerPopup(issuance);
        } else if (pageType === 'BRANCH_ISSUANCE' && !ViewModel.hasPermission('bi-update') || pageType === 'ISSUANCE' && !ViewModel.hasPermission('ci-update')) {
          TrackingService.pushToGTMDatalayer(eventPrefix + '_VIEW', 'track_event', mxpData);
          commonService.setItemSession('pi', 'isAccessReadOnly', true);
          $state.go(nextState, {
            id: issuance.policyIssuanceId
          });
        } else {
          if (pageType === 'ISSUANCE') {
            commonService.makeHttpCall(reqUrl, body, 'POST', false).then(function () {
              $state.go(nextState, {
                id: issuance.policyIssuanceId
              });
            });
          } else {
            $state.go(nextState, {
              id: issuance.policyIssuanceId
            });
          }
        }
      } else {
        if (permissionPopup) {
          if (pageType === 'ISSUANCE') {
            commonService.makeHttpCall(reqUrl, body, 'POST', false);
            $state.go(nextState, {
              id: issuance.policyIssuanceId
            });
          } else {
            $state.go(nextState, {
              id: issuance.policyIssuanceId
            });
          }
        } else {
          TrackingService.pushToGTMDatalayer(eventPrefix + '_VIEW', 'track_event', mxpData);
          commonService.setItemSession('pi', 'isAccessReadOnly', true);
          $state.go(nextState, {
            id: issuance.policyIssuanceId
          });
        }
      }
    }

    function openOwnerPopup(issuance) {
      $mdDialog.show({
        templateUrl: 'dev/policy-issuance/templates/dialogs/owner.html',
        clickOutsideToClose: true,
        locals: {
          lead: issuance
        },
        controller: OwnerController
      });
      OwnerController.$inject = ['$scope', '$mdDialog', 'lead', 'leadService', 'AuthService', '$state', '$window', 'CommentService'];

      function OwnerController($scope, $mdDialog, lead, leadService, AuthService, $state, $window, CommentService) {
        var loadingDeferred = $q.defer();
        var mxpData = {
          policyIssuanceId: lead.policyIssuanceId,
          requestId: lead.requestId,
          vertical: lead.vertical
        };
        $scope.lead = lead;
        $scope.setOwner = function () {
          var reqUrl = '/actionable';
          var body = {
            policyIssuanceId: issuance.policyIssuanceId,
            actionable: false
          };
          if (pageType === 'ISSUANCE') {
            commonService.makeHttpCall(reqUrl, body, 'POST', false).then(function () {
              addMember(lead).then(function () {
                PolicyIssuanceService.savePolicyIssuanceOwner(lead).then(function (res) {
                  $scope.data = res;
                  loadingDeferred.resolve(true);
                  if (pageType === 'BRANCH_ISSUANCE') {
                    $state.go('bi-detail', {
                      id: lead.policyIssuanceId
                    });
                  } else {
                    $state.go('ci-detail', {
                      id: lead.policyIssuanceId
                    });
                  }
                  TrackingService.pushToGTMDatalayer(eventPrefix + '_OWN', 'track_event', mxpData);
                }).catch(function () {
                  loadingDeferred.reject(true);
                });
              }).catch(function (err) {
                $window.console.log('addMember', err);
              });
            });
          } else {
            PolicyIssuanceService.savePolicyIssuanceOwner(lead).then(function (res) {
              $scope.data = res;
              loadingDeferred.resolve(true);
              if (pageType === 'BRANCH_ISSUANCE') {
                $state.go('bi-detail', {
                  id: lead.policyIssuanceId
                });
              } else {
                $state.go('ci-detail', {
                  id: lead.policyIssuanceId
                });
              }
              TrackingService.pushToGTMDatalayer(eventPrefix + '_OWN', 'track_event', mxpData);
            }).catch(function () {
              loadingDeferred.reject(true);
            });
          }

          $mdDialog.cancel();
        };

        function addMember(lead) {
          var deferred = $q.defer();
          var currentUser = AuthService.getUser();
          if (AuthService.hasPermission('ci-chat') && lead.partnerId) {
            CommentService.addNewMember(lead, currentUser).then(function (res) {
              deferred.resolve(res);
            }).catch(function (err) {
              deferred.reject(err);
            });
          } else {
            deferred.resolve(true);
          }

          return deferred.promise;
        }

        $scope.readOnly = function () {
          var currentUser = AuthService.getUser();
          var issuanceUser = lead.adminOwner;
          if (currentUser !== issuanceUser) {
            commonService.setItemSession('pi', 'isAccessReadOnly', true);
            TrackingService.pushToGTMDatalayer(eventPrefix + '_VIEW', 'track_event', mxpData);
          }
          if (pageType === 'BRANCH_ISSUANCE') {
            $state.go('bi-detail', {
              id: lead.policyIssuanceId
            });
          } else {
            $state.go('ci-detail', {
              id: lead.policyIssuanceId
            });
          }
          $mdDialog.cancel();
        };
        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }

    function callPartner($event, issuance) {
      $event.stopPropagation();
      var eventData = {
        policyIssuanceId: issuance.policyIssuanceId,
        requestId: issuance.requestId,
        vertical: issuance.vertical,
        partnerName: issuance.partnerName,
        partnerMobile: issuance.partnerMobile,
        partnerEmail: issuance.partnerEmail,
        partnerDpNo: issuance.partnerDpNo,
        partnerId: issuance.partnerId
      };
      if (issuance.adminOwner && issuance.adminOwner.email) {
        eventData.adminOwnerEmail = issuance.adminOwner.email;
      }
      if (issuance.partnerMobile) {
        TrackingService.pushToGTMDatalayer(eventPrefix + '_CARD_CALL_CLICK', 'track_event', eventData);
        window.location.href = 'tel:+91-' + issuance.partnerMobile;
      }
    }

    function openFilterSheet() {
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_MOBILE', 'track_event', {});
      initFilterForm();
      $mdBottomSheet.show({
        templateUrl: 'dev/policy-issuance/templates/pi-filter-mob.bottomsheet.tpl.html',
        scope: ViewModel,
        preserveScope: true,
        clickOutsideToClose: false
      }).then(function () {}).catch(function () {});
    }

    function applyMobileFilter() {
      if (!ViewModel.issuanceFilterForm.$dirty) {
        commonService.showToast('No changes to save.');
        return;
      }
      filterChange();
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_MOBILE_APPLY', 'track_event', ViewModel.params);
      $mdBottomSheet.cancel();
    }

    function closeFilterSheet() {
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_MOBILE_CLOSE', 'track_event', {});
      $mdBottomSheet.cancel();
    }

    function clearFilterSheet() {
      clearFilter();
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_MOBILE_CLEAR_ALL', 'track_event', ViewModel.params);
      $mdBottomSheet.cancel();
    }

    // DP Details Modal
    function dpDetails($event, issuance) {
      $event.stopPropagation();
      $mdDialog.show({
        templateUrl: 'dev/policy-issuance/templates/dialogs/dp-Dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          lead: issuance
        },
        controller: dpDetailsController
      });
      dpDetailsController.$inject = ['$scope', '$mdDialog', 'lead'];

      function dpDetailsController($scope, $mdDialog, lead) {
        $scope.lead = lead;
        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }

    function initFilterForm() {
      ViewModel.appliedFilters = ViewModel.params.filter;
      ViewModel.filterForm = [{
        name: 'Insurer',
        code: 'insurer',
        type: 'select',
        placeholder: 'Select Insurer',
        selectConfig: {
          options: ViewModel.filterLists.insurersList,
          valueKey: 'insurerCode',
          textKey: 'insurerName',
          multiple: true
        }
      }, {
        name: 'Product Category',
        code: 'vertical',
        type: 'select',
        placeholder: 'Select Product Category',
        selectConfig: {
          options: ViewModel.filterLists.verticalList,
          valueKey: 'value',
          textKey: 'text',
          multiple: true
        }
      }, {
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'select',
        placeholder: 'Select Payment Mode',
        selectConfig: {
          options: ViewModel.filterLists.paymentModeList,
          valueKey: 'value',
          textKey: 'text',
          multiple: true
        }
      }, {
        name: 'Issuance Status',
        code: 'status',
        type: 'select',
        placeholder: 'Select Issuance Status',
        selectConfig: {
          options: ViewModel.filterLists.issuanceStatusList,
          valueKey: 'value',
          textKey: 'text',
          multiple: true
        }
      }, {
        name: 'Inspection Status',
        code: 'inspectionStatus',
        type: 'select',
        placeholder: 'Select Inspection Status',
        selectConfig: {
          options: ViewModel.filterLists.inspectionStatusList,
          valueKey: 'value',
          textKey: 'text',
          multiple: true
        }
      }, {
        name: 'Inspection',
        code: 'inspectionRequired',
        type: 'select',
        selectConfig: {
          options: ViewModel.filterLists.inspectionRequiredList,
          valueKey: 'value',
          textKey: 'text',
          multiple: true
        }
      }];
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance').controller('selectedColumnDailog', controller);
  controller.$inject = ['$q', '$scope', '$mdDialog', '$mdToast', '$window', 'selectedColumn'];

  /**
   *
   * Selected Column Dialog Controller
   *
   */
  function controller($q, $scope, $mdDialog, $mdToast, $window, selectedColumn) {

    var ViewModel = $scope;
    ViewModel.toggleCheckboxes = toggleCheckboxes;
    ViewModel.applySelectedColumn = applySelectedColumn;
    ViewModel.selectedColumn = JSON.parse(JSON.stringify(selectedColumn));

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      toggleCheckboxes();
    }

    function closeDialog() {
      $mdDialog.hide(ViewModel.selectedColumn);
    }

    ViewModel.selected = [];
    function toggleCheckboxes() {
      ViewModel.count = 0;
      ViewModel.totalSelectedColumn = Object.keys(ViewModel.selectedColumn).length;
      for (var i in ViewModel.selectedColumn) {
        if (ViewModel.selectedColumn[i].selected) {
          ViewModel.count++;
        }
      }
    }

    function applySelectedColumn() {
      if (ViewModel.count >= 1) {
        closeDialog();
      } else {
        $mdToast.show($mdToast.simple().textContent('Please select at least one checkbox').action('Close').position('right').hideDelay(3500));
      }
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance').controller('CreateBranchDialogCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'AutoPolicyIssuanceService', 'commonService'];

  function controller($scope, $mdDialog, AutoPolicyIssuanceService, commonService) {

    var ViewModel = $scope;
    $scope.cancel = $mdDialog.cancel;
    $scope.autoParseCheck = autoParseCheck;

    init();

    function init() {
      ViewModel.form = {};
      ViewModel.editMode = true;
      ViewModel.preliminaryFields = AutoPolicyIssuanceService.preliminaryFields;
    }

    function autoParseCheck(form) {
      if (!ViewModel.createBranchForm.$dirty) {
        commonService.showToast('Please fill the mandatory fields');
        return;
      }
      if (ViewModel.createBranchForm.$invalid) {
        angular.forEach(ViewModel.createBranchForm.$error.required, function (field) {
          field.$setDirty();
        });
        commonService.showToast('Please fill the mandatory fields');
        return;
      }
      var reqObj = {
        insurer: form.insurer,
        policyIssuanceStatus: form.policyIssuanceStatus
      };
      if (form.vertical === 'FW') {
        reqObj.vertical = form.$$assignedVertical;
      } else {
        reqObj.vertical = form.$$vertical;
      }
      AutoPolicyIssuanceService.autoParsingValidation(reqObj).then(function (res) {
        form.autoParsingEnabled = res ? true : false;
        form.typeOfFlow = res ? 'AUTO_PARSING' : 'MANUAL_FORM';
        $mdDialog.hide(form);
      }).catch(function () /*err*/{
        $mdDialog.hide(form);
      });
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').controller('TransferOpsBranchCtrl', controller);
  controller.$inject = ['$scope', '$mdDialog', 'referenceId', 'module', 'onTransfer', 'DecentralizationService', 'commonService'];

  /**
   *
   * Selected Column Dialog Controller
   *
   */
  function controller($scope, $mdDialog, referenceId, module, onTransfer, DecentralizationService, commonService) {

    var ViewModel = $scope;

    init();

    function init() {
      ViewModel.branchOpsForm = {
        referenceId: referenceId,
        module: module
      };
      ViewModel.editMode = true;
      ViewModel.transferOpsBranchFields = DecentralizationService.transferOpsBranchFields;
      DecentralizationService.initValues();
    }

    ViewModel.transferBranch = transferBranch;
    ViewModel.discard = discard;

    function transferBranch() {
      var reqObj = {
        referenceId: ViewModel.branchOpsForm.referenceId,
        module: ViewModel.branchOpsForm.module,
        newOperationLocations: [ViewModel.branchOpsForm.newBranch],
        reason: ViewModel.branchOpsForm.comments
      };
      // if(ViewModel.transferOpsBranchForm.$dirty) {
      if (ViewModel.transferOpsBranchForm.$error) {
        angular.forEach(ViewModel.transferOpsBranchForm.$error.required, function (field) {
          field.$setDirty();
        });
        if (ViewModel.transferOpsBranchForm.$valid) {
          DecentralizationService.transferBranch(reqObj).then(function (res) {
            onTransfer(res);
          }).catch(function () {});
          $mdDialog.cancel();
        } else {
          commonService.showToast('Please fill required fields');
        }
      }
      // } else{
      //   commonService.showToast('No changes');
      // }
    }

    function discard() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance').controller('ViewTransferLogCtrl', controller);
  controller.$inject = ['$scope', '$mdDialog', 'logs'];

  /**
   *
   * Selected Column Dialog Controller
   *
   */
  function controller($scope, $mdDialog, logs) {

    var ViewModel = $scope;

    ViewModel.close = close;

    init();

    function init() {
      ViewModel.logsList = logs;
    }

    function close() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').controller('PolicyIssuanceAddUpdateCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'PolicyIssuanceService', 'issuance', '$mdToast', '$window', '$state', '$anchorScroll', 'pageType', 'AuthService', 'commonService', '$timeout', 'DecentralizationService', 'CONSTANTS_LIST', 'TrackingService', '$mdBottomSheet'];

  /**
   *
   * Policy Issuance Add Update Controller
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @param     {Object}    $scope                       Angular scope
   *
   */
  function controller($scope, $mdDialog, PolicyIssuanceService, issuance, $mdToast, $window, $state, $anchorScroll, pageType, AuthService, commonService, $timeout, DecentralizationService, CONSTANTS_LIST, TrackingService, $mdBottomSheet) {

    var ViewModel = $scope;

    ViewModel.showDocuments = false;
    ViewModel.addUpdateInspection = addUpdateInspection;
    ViewModel.showInspectionSubStatusLogs = showInspectionSubStatusLogs;
    ViewModel.showPartnerDetails = showPartnerDetails;
    ViewModel.showHidePolicyFields = showHidePolicyFields;
    ViewModel.removeMember = removeMember;
    ViewModel.removeNominee = removeNominee;
    ViewModel.pageType = pageType;
    ViewModel.showAddonMap = showAddonMap;
    ViewModel.selectBQP = selectBQP;
    ViewModel.updateBQP = updateBQP;
    ViewModel.cancelBQP = cancelBQP;
    ViewModel.viewTransferLog = viewTransferLog;
    ViewModel.transferOpsBranch = transferOpsBranch;
    ViewModel.showPreSaleVerification = showPreSaleVerification;
    ViewModel.isAccessReadOnly = commonService.getItemSession('pi', 'isAccessReadOnly');
    ViewModel.isPreSale = AuthService.hasPermission('pi-presaleupdate');
    ViewModel.isPreSale = AuthService.hasPermission(pageType === 'BRANCH_ISSUANCE' ? 'bi-restrictedupdate' : 'ci-restrictedupdate');
    ViewModel.comment = {
      show: false,
      edit: false
    };
    ViewModel.isMobile = commonService.isMobile();
    ViewModel.toggleShowDocuments = toggleShowDocuments;
    ViewModel.openSectionNav = openSectionNav;
    var roles = AuthService.getRoles();
    var isSalesUser = roles.split(',').includes(CONSTANTS_LIST.ROLES.SALES) ? true : false;

    var misFeatures = commonService.getItemSession('features', 'mis');
    var moduleName = pageType === 'ISSUANCE' ? 'CENTRAL_ISSUANCE' : 'BRANCH_ISSUANCE';
    var eventPrefix = void 0,
        mxpData = void 0;

    var navClicked = false;
    $scope.$on('duScrollspy:becameActive', function () {
      if (navClicked && angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
      }
      navClicked = true;
    });

    $scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }

      if (angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
        event.preventDefault();
      }
    });

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author Sumanta Parida
     *
     */
    function getPermissionSave() {
      if (pageType === 'BRANCH_ISSUANCE') {
        return AuthService.hasPermission('bi-update') ? 'bi-update' : 'bi-restrictedupdate' || 'bi-update';
      }
      return AuthService.hasPermission('ci-update') ? 'ci-update' : 'ci-restrictedupdate' || 'ci-update';
    }

    var editActions = [{
      type: 'button',
      class: '',
      action: _saveCurrentIssuance,
      permissionCode: getPermissionSave(),
      disabled: ViewModel.isAccessReadOnly,
      name: 'Save Issuance',
      dataAuto: 'save-pi-button'
    }, {
      type: 'button',
      class: '',
      action: _onCancelAction,
      permissionCode: getPermissionSave(),
      disabled: false,
      name: 'Close',
      dataAuto: 'close-pi-button'
    }];

    var readOnlyActions = [{
      type: 'button',
      class: '',
      action: _onCancelAction,
      permissionCode: 'ci-detail-read',
      disabled: false,
      name: 'Close',
      dataAuto: 'close-pi-button'
    }];

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.currentIssuance = issuance;
      ViewModel.currentIssuance.$$vertical = issuance.vertical;
      _checkForAutoParsingForm(ViewModel.currentIssuance);
      _initSOSfields(ViewModel.currentIssuance);

      if (ViewModel.currentIssuance.inspectionDetails) {
        ViewModel.inspectionDetailLength = ViewModel.currentIssuance.inspectionDetails.length;
      }

      ViewModel.editMode = isEditMode(ViewModel.currentIssuance, ViewModel.isAccessReadOnly);
      ViewModel.editModePreSale = getEditPermissionPreSale(ViewModel.currentIssuance, ViewModel.isAccessReadOnly, ViewModel.showPreSaleVerification);
      ViewModel.editModeSOS = ViewModel.showSourceOfSaleFields && !ViewModel.isAccessReadOnly && !ViewModel.editMode;
      if (['ISSUED', 'CANCELLED', 'CANCELLED_REFUND_AWAITED'].includes(ViewModel.currentIssuance.policyIssuanceStatus)) {
        ViewModel.editModeSOS = false;
      }
      ViewModel.navbarConfig = {
        goBackState: 'ci-list',
        vertical: 'issuance',
        mode: 'EDIT',
        title: ViewModel.currentIssuance.requestId
      };

      if (pageType === 'BRANCH_ISSUANCE') {
        ViewModel.navbarConfig.goBackState = 'bi-list';
        ViewModel.memberEditMode = ViewModel.editMode;
        ViewModel.addonEditMode = ViewModel.editMode;
      } else {
        ViewModel.memberEditMode = ViewModel.currentIssuance.vertical === 'LIFE' ? ViewModel.editMode : false;
        ViewModel.addonEditMode = false;
        if (!AuthService.hasPermission('ci-update') && AuthService.hasPermission('ci-detail-read')) {
          ViewModel.editMode = false;
        }
      }
      eventPrefix = ViewModel.currentIssuance.source === 'ONLINE' ? 'CI' : 'BI';
      mxpData = {
        policyIssuanceId: ViewModel.currentIssuance.policyIssuanceId,
        requestId: ViewModel.currentIssuance.requestId || undefined,
        vertical: ViewModel.currentIssuance.vertical
      };
      _setFileConfig();

      if (ViewModel.editMode || ViewModel.isPreSale) {
        setNavbarCconfig('EDIT');

        if (ViewModel.currentIssuance.partnerId !== null) {
          updateNavbarConfig('COMMENTS');
        } else {
          updateNavbarConfig('EMAIL');
        }

        // if (AuthService.isSalesUser() && ViewModel.currentIssuance.assignedToSales) {
        //   updateNavbarConfig('TRANSFER_TO_OPS_USERS');
        // }

        updateNavbarConfig('RESULTS_LINK');
        updateNavbarConfig('MOVE_TO_BRANCH');
      } else {
        setNavbarCconfig('READ_ONLY');
        updateNavbarConfig('COMMENTS');
      }
      _initDecentralisation(ViewModel.currentIssuance);
      ViewModel.currentIssuance.$$showPolicyFields = false;
      _initFormConfigs();
    }

    function _setFileConfig() {
      ViewModel.fileConfig = {
        identifiers: {
          policyIssuanceId: ViewModel.currentIssuance.policyIssuanceId,
          requestId: PolicyIssuanceService.findRequestId(ViewModel.currentIssuance),
          clientUid: PolicyIssuanceService.findRequestId(ViewModel.currentIssuance),
          leadId: ViewModel.currentIssuance.leadId,
          vertical: ViewModel.currentIssuance.vertical
        },
        tagOptions: {
          tagging: true
        },
        options: {
          $$viewMode: false,
          uploadCallBackFn: uploadCallBackFn,
          deleteCallBackFn: deleteCallBackFn,
          changeTagCallBackFn: changeTagCallBackFn

        },
        eventTrackingData: {
          module: eventPrefix,
          mxpData: mxpData
        }
      };
      setFilePermissions(ViewModel.editMode, pageType);
    }

    function _checkForAutoParsingForm(issuance) {
      if ($state.current.name === 'bi-create' && issuance.autoParsingEnabled && issuance.typeOfFlow === 'AUTO_PARSING') {
        commonService.showToast('This is an Auto Parsing flow entry.');
        $state.go('bi-create-auto');
      }
    }

    function _initSOSfields(issuance) {
      ViewModel.showSourceOfSaleFields = misFeatures && misFeatures.sourceOfSale && issuance.sourceOfSale && issuance.policyDetailsId;
      ViewModel.showReviewFields = ViewModel.showSourceOfSaleFields && issuance.sourceOfSale.status === 'CONFIRMED';
    }

    function _initDecentralisation(issuance) {
      var decentralisationEnable = commonService.getItemLocalStorage('decentralization', 'decentralisationEnable');
      if (decentralisationEnable) {
        if (issuance.source === 'ONLINE') {
          issuance.$$decentralization = decentralisationEnable.CENTRAL_ISSUANCE;
        } else if (issuance.source === 'BRANCH') {
          issuance.$$decentralization = decentralisationEnable.BRANCH_ISSUANCE;
        } else {
          issuance.$$decentralization = false;
        }
      } else {
        issuance.$$decentralization = false;
      }
    }

    /**
     * Function to check Permission isEditMode
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}       issuance             Object
     * @param    {!Boolean}      isAccessReadOnly     Boolean
     *
     * @author sumantaparida
     *
     */
    function isEditMode(issuance, isAccessReadOnly) {
      if (issuance.source === 'BRANCH' && issuance.typeOfFlow === 'AUTO_PARSING' && issuance.autoParsingStatus !== 'SUCCESS' && issuance.autoParsingStatus !== 'PENDING' && !isAccessReadOnly) {
        ViewModel.comment.edit = true;
        return true;
      }

      if ($state.current.name === 'bi-create') {
        ViewModel.comment.edit = true;
        return true;
      }
      var editMode = issuance.policyIssuanceStatus !== 'CANCELLED' && issuance.policyIssuanceStatus !== 'ISSUED' && issuance.policyIssuanceStatus !== 'CANCELLED_REFUND_AWAITED' && !isAccessReadOnly;
      if (!ViewModel.isPreSale) {
        ViewModel.comment.edit = editMode;
        return editMode;
      } else {
        editMode = false;
      }
      return editMode;
    }

    /**
     * Function to check Permission preSaleVerification
     *
     * @public
     *
     * @function
     *
     * @param    {!Object}           group         Group Object
     *
     * @author sumantaparida
     *
     */
    function getEditPermissionPreSale(issuance, isAccessReadOnly, showPreSaleVerification) {
      if (showPreSaleVerification) {
        var editMode = AuthService.hasPermission('pi-presaleupdate') && issuance.policyIssuanceStatus !== 'ISSUED' && !isAccessReadOnly;
        ViewModel.comment.edit = editMode ? editMode : ViewModel.comment.edit;
        return editMode;
      }
      return false;
    }

    function showPreSaleVerification(issuance) {
      var view = issuance.vertical === 'HEALTH' || issuance.vertical === 'TERM' || issuance.vertical === 'LIFE';
      return view;
    }

    function setFilePermissions(editMode, source) {
      var filePermissions = {};
      if (source === 'BRANCH_ISSUANCE') {
        filePermissions = {
          view: AuthService.hasPermission('bi-file-view'),
          download: AuthService.hasPermission('bi-file-download'),
          upload: AuthService.hasPermission('bi-file-upload'),
          delete: editMode && AuthService.hasPermission('bi-file-delete'),
          changeTag: AuthService.hasPermission('bi-file-changetag')
        };
      } else {
        filePermissions = {
          view: AuthService.hasPermission('ci-file-view'),
          download: AuthService.hasPermission('ci-file-download'),
          upload: AuthService.hasPermission('ci-file-upload'),
          delete: editMode && AuthService.hasPermission('ci-file-delete'),
          changeTag: AuthService.hasPermission('ci-file-changetag')
        };
      }

      filePermissions.options = filePermissions.view || filePermissions.download || filePermissions.upload || filePermissions.delete || filePermissions.changeTag;
      ViewModel.fileConfig.options.filePermissions = filePermissions;
    }

    function removeMember(index) {
      ViewModel.currentIssuance.members.splice(index, 1);
      ViewModel.currentIssuanceForm.$setDirty();
    }

    function removeNominee(index) {
      ViewModel.currentIssuance.nominees.splice(index, 1);
      ViewModel.currentIssuanceForm.$setDirty();
    }

    function showPartnerDetails() {
      var showPartnerDetail = ViewModel.currentIssuance.partnerId ? true : false;
      if (showPartnerDetail) {
        showPartnerDetail = AuthService.canShowPartnerDetails();
      }
      return showPartnerDetail;
    }

    function showAddonMap() {
      if (ViewModel.currentIssuance.addonPremiumMap && Object.keys(ViewModel.currentIssuance.addonPremiumMap).length > 0) {
        return true;
      }
      return false;
    }

    function showHidePolicyFields() {
      ViewModel.currentIssuance.$$showPolicyFields = !ViewModel.currentIssuance.$$showPolicyFields;
    }

    function _onCancelAction() {
      $state.go(ViewModel.navbarConfig.goBackState);
    }

    function setNavbarCconfig(mode) {
      switch (mode) {
        case 'EDIT':
          ViewModel.navbarConfig.actions = angular.copy(editActions);
          ViewModel.navbarConfig.subtitle = '(Edit Mode)';
          break;
        case 'READ_ONLY':
          ViewModel.navbarConfig.actions = readOnlyActions;
          ViewModel.navbarConfig.subtitle = '(View Mode)';
          break;
        default:
          $window.console.log('No Navbar case matched');
      }
    }

    function updateNavbarConfig(code) {
      switch (code) {
        case 'COMMENTS':
          if (ViewModel.currentIssuance.source !== 'BRANCH') {
            ViewModel.comment.show = true && !!issuance.partnerId && (AuthService.hasPermission('ci-chat') || AuthService.hasPermission('chat-unrestricted'));
            ViewModel.commentSource = 'ADMIN_POLICY_ISSUANCE';
          }
          break;
        case 'EMAIL':
          if (ViewModel.currentIssuance.source !== 'BRANCH') {
            ViewModel.navbarConfig.actions.unshift({
              type: 'button',
              class: 'md-primary md-raised',
              action: _onEmailClient,
              permissionCode: 'ci-email',
              name: 'Email',
              dataAuto: 'email-pi-button'
            });
          }
          break;
        case 'RESULTS_LINK':
          if (ViewModel.currentIssuance.source !== 'BRANCH') {
            ViewModel.navbarConfig.actions.unshift({
              type: 'button',
              class: 'md-primary md-raised',
              action: _onResultRedirect,
              permissionCode: 'ci-results',
              name: ViewModel.currentIssuance.vertical === 'LIFE' ? 'View Checkout Stage' : 'View Quotes',
              dataAuto: 'result-pi-button'
            });
          }
          break;
        case 'MOVE_TO_BRANCH':
          if (ViewModel.currentIssuance.quoteType === 'partner') {
            ViewModel.navbarConfig.actions.unshift({
              type: 'button',
              class: 'md-primary md-raised',
              action: _onMoveToBranchClick,
              permissionCode: 'ci-results',
              name: ViewModel.currentIssuance.source !== 'BRANCH' ? 'Move To Branch' : 'Move To Central',
              dataAuto: 'moveBranch-pi-button'
            });
          }
          break;
        case 'TRANSFER_TO_OPS_USERS':
          if (ViewModel.currentIssuance.source !== 'BRANCH') {
            ViewModel.navbarConfig.actions.unshift({
              type: 'button',
              class: 'md-primary md-raised',
              action: _transferToOPSUsersDialog,
              permissionCode: getPermissionSave(),
              name: 'Transfer to OPS Users'
            });
          }
          break;
        default:
          $window.console.log('No update Navbar case matched');
      }
    }

    function _onMoveToBranchClick() {
      $mdDialog.show({
        controller: 'moveToBranchCtrl',
        templateUrl: 'dev/policy-issuance/templates/move-to-branch.dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          policyIssuance: ViewModel.currentIssuance
        }
      }).then(function () {
        var stateToGo = ViewModel.currentIssuance.source !== 'BRANCH' ? 'ci-list' : 'bi-list';
        TrackingService.pushToGTMDatalayer('CI_MOVE_BRANCH', 'track_event', mxpData);
        $timeout(function () {
          $state.go(stateToGo);
        }, 1000);
      }).catch(function (err) {
        $window.console.log('Rejected promise of email modal', err);
      });
    }

    function _onResultRedirect() {
      PolicyIssuanceService.getRedirectUrl(ViewModel.currentIssuance, 'quotes').then(function (result) {
        $window.open(result, '_blank');
      });
      TrackingService.pushToGTMDatalayer('CI_RESULTS', 'track_event', mxpData);
    }

    function _onEmailClient() {
      $mdDialog.show({
        controller: 'EmailClientController',
        templateUrl: 'dev/policy-issuance/templates/email-client.dialog.tpl.html',
        clickOutsideToClose: true,
        resolve: {
          policyIssuanceId: function policyIssuanceId() {
            return ViewModel.currentIssuance.policyIssuanceId;
          }
        }
      }).then(function () {
        $mdToast.show($mdToast.simple().textContent('Email sent.').action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
          if (response === 'ok') {
            $window.console.log('You clicked the \'DISMISS\' action.');
          }
        });
        TrackingService.pushToGTMDatalayer('CI_EMAIL', 'track_event', mxpData);
      }).catch(function (err) {
        $window.console.log('Rejected promise of email modal', err);
      });
    }

    function _transferToOPSUsersDialog() {
      if (!ViewModel.currentIssuanceForm.$dirty) {
        commonService.showToast('You have unsaved changes. Please Save or Discard your changes before transferring issuance to OPS user.');
        return;
      }
      TrackingService.pushToGTMDatalayer(eventPrefix + '_TRANSFER_TO_OPS_USER_CLICK', 'track_event', mxpData);
      $mdDialog.show({
        templateUrl: 'dev/policy-issuance/templates/dialogs/pi-transfer-to-ops-user.dialog.tpl.html',
        clickOutsideToClose: false,
        controller: TransferToOPSUsersDialogCtrl
      }).then(function () {
        // let reqUrl = `/api/policyissuance/transfer-to-ops/${ViewModel.currentIssuance.policyIssuanceId}`;
        // commonService.makeHttpCall(reqUrl, {}, 'POST', false)
        // .then( () => {
        //   TrackingService.pushToGTMDatalayer(`${eventPrefix}_TRANSFER_TO_OPS_USER_SUCCESS`, 'track_event', mxpData);
        //   let stateToGo = ViewModel.currentIssuance.source !== 'BRANCH' ? 'ci-list' : 'bi-list';
        //   $state.go(stateToGo, {reload: true});
        // })
        // .catch((err) => {
        //   console.log('err ==>', err);
        //   TrackingService.pushToGTMDatalayer(`${eventPrefix}_TRANSFER_TO_OPS_USER_ERROR`, 'track_event', mxpData);
        // });
      }).catch(function () {
        TrackingService.pushToGTMDatalayer(eventPrefix + '_TRANSFER_TO_OPS_USER_CLOSE', 'track_event', mxpData);
      });

      TransferToOPSUsersDialogCtrl.$inject = ['$scope', '$mdDialog'];
      function TransferToOPSUsersDialogCtrl($scope, $mdDialog) {
        $scope.save = function () {
          $mdDialog.hide();
        };
        $scope.cancel = $mdDialog.cancel;
      }
    }

    function findNoOfDocsByTag(tagCode) {
      var count = 0;
      if (ViewModel.currentIssuance.$$attachedDocuments) {
        for (var idx = ViewModel.currentIssuance.$$attachedDocuments.length - 1; idx >= 0; idx--) {
          var file = ViewModel.currentIssuance.$$attachedDocuments[idx];
          if (file.tag === tagCode) {
            count++;
          }
        }
      }

      return count;
    }

    function _findFormValidity() {

      delete ViewModel.currentIssuance.$$paymentProofNotUploaded;
      delete ViewModel.currentIssuance.$$policyNotUploaded;
      delete ViewModel.currentIssuance.$$statusNotPossible;
      // delete ViewModel.currentIssuance.$$membersRequired;
      delete ViewModel.currentIssuance.$$salesError;
      delete ViewModel.currentIssuance.$$selectedVerticalError;

      if (ViewModel.currentIssuance.inspectionRequired === 'REQUIRED') {
        if (ViewModel.currentIssuance.inspectionStatus !== 'RECOMMENDED' && ViewModel.currentIssuance.inspectionStatus !== 'COMPLETED_RESPONSE_AWAITED') {
          if (ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified || ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER') {
            if (ViewModel.currentIssuance.vertical !== 'HEALTH' && ViewModel.currentIssuance.vertical !== 'TERM') {
              if (ViewModel.currentIssuance.vertical !== 'LIFE' && (ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED' || ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED_REFUND_AWAITED')) {
                ViewModel.currentIssuance.$$statusNotPossible = true;
              }
            }

            if (ViewModel.currentIssuance.vertical !== 'LIFE' && ViewModel.currentIssuance.vertical !== 'HEALTH' && ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && (ViewModel.currentIssuance.policyIssuanceStatus === 'PENDING' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER')) {
              ViewModel.currentIssuance.$$statusNotPossible = true;
            }

            if ((ViewModel.currentIssuance.inspectionStatus === 'PENDING' || ViewModel.currentIssuance.inspectionStatus === 'SCHEDULED') && ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified && (ViewModel.currentIssuance.policyIssuanceStatus === 'PENDING' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER')) {
              if (ViewModel.currentIssuance.vertical !== 'LIFE') {
                ViewModel.currentIssuance.$$statusNotPossible = true;
              }
            }

            if (ViewModel.currentIssuance.inspectionStatus === 'NOT_RECOMMENDED' && ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified && ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER' && ViewModel.currentIssuance.vertical !== 'LIFE') {
              ViewModel.currentIssuance.$$statusNotPossible = true;
            }

            if (!(ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus === 'CANCELLED_REFUND_AWAITED') {
              ViewModel.currentIssuance.$$statusNotPossible = true;
            }
          } else {
            if (ViewModel.currentIssuance.inspectionStatus === 'NOT_RECOMMENDED' && ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED') {
              if (ViewModel.currentIssuance.vertical !== 'LIFE' && ViewModel.currentIssuance.vertical !== 'HEALTH') {
                ViewModel.currentIssuance.$$statusNotPossible = true;
              }
            }

            if (!(ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus === 'CANCELLED_REFUND_AWAITED') {
              ViewModel.currentIssuance.$$statusNotPossible = true;
            }

            if (ViewModel.currentIssuance.inspectionStatus === 'PENDING' && ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED') {
              if (ViewModel.currentIssuance.vertical !== 'HEALTH' && ViewModel.currentIssuance.vertical !== 'TERM') {
                ViewModel.currentIssuance.$$statusNotPossible = true;
              }
            }
          }
        } else if (ViewModel.currentIssuance.inspectionStatus === 'COMPLETED_RESPONSE_AWAITED') {
          if (ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER' || ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED') {
            if (ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED_REFUND_AWAITED' && ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED' && ViewModel.currentIssuance.policyIssuanceStatus !== 'PENDING') {
              ViewModel.currentIssuance.$$statusNotPossible = true;
            }
          }

          if (!(ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus === 'CANCELLED_REFUND_AWAITED') {
            ViewModel.currentIssuance.$$statusNotPossible = true;
          }
        } else {
          if (!(ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus === 'CANCELLED_REFUND_AWAITED') {
            ViewModel.currentIssuance.$$statusNotPossible = true;
          }

          if ((ViewModel.currentIssuance.paymentStatus === 'COMPLETED' || ViewModel.currentIssuance.paymentStatus === 'PENDING') && !ViewModel.currentIssuance.paymentVerified && (ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER')) {
            ViewModel.currentIssuance.$$statusNotPossible = true;
          }
        }
      } else if (ViewModel.currentIssuance.inspectionRequired === 'NOT_REQUIRED') {
        if (ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && !ViewModel.currentIssuance.paymentVerified && (ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER')) {
          ViewModel.currentIssuance.$$statusNotPossible = true;
        }

        if (ViewModel.currentIssuance.paymentStatus === 'PENDING' && (ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED' || ViewModel.currentIssuance.policyIssuanceStatus === 'WAITING_FOR_INSURER')) {
          ViewModel.currentIssuance.$$statusNotPossible = true;
        }

        if (!(ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus === 'CANCELLED_REFUND_AWAITED') {
          ViewModel.currentIssuance.$$statusNotPossible = true;
        }
      }

      if ((ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && (ViewModel.currentIssuance.vertical === 'LIFE' || ViewModel.currentIssuance.vertical === 'HEALTH') || ViewModel.currentIssuance.paymentStatus === 'COMPLETED' && ViewModel.currentIssuance.paymentVerified) && ViewModel.currentIssuance.policyIssuanceStatus !== 'CANCELLED') {
        if (ViewModel.currentIssuance.paymentMode === 'ONLINE' || ViewModel.currentIssuance.paymentMode === 'CASH_PAID_AT_INSURER') {
          if (findNoOfDocsByTag('PAYMENT_PROOF') > 0 || ViewModel.currentIssuance.txnNo) {
            ViewModel.currentIssuance.$$paymentProofNotUploaded = false;
          } else {
            ViewModel.currentIssuance.$$paymentProofNotUploaded = true;
          }
        }
      }

      if (ViewModel.currentIssuance.policyIssuanceStatus === 'ISSUED') {
        var policyNotUploaded = true;
        if (findNoOfDocsByTag('POLICY') > 0) {
          policyNotUploaded = policyNotUploaded && false;
        }

        if ((ViewModel.currentIssuance.vertical === 'TERM' || ViewModel.currentIssuance.vertical === 'LIFE') && ViewModel.currentIssuance.applicationNumber) {
          policyNotUploaded = policyNotUploaded && false;

          if (ViewModel.currentIssuance.applicationStatus !== 'COMPLETED') {
            ViewModel.currentIssuance.$$statusNotPossible = true;
          }
        }
        // if (ViewModel.currentIssuance.source === 'BRANCH' && ViewModel.currentIssuance.vertical === 'HEALTH') {
        //   if (!ViewModel.currentIssuance.members || (ViewModel.currentIssuance.members && ViewModel.currentIssuance.members.length < 1) ) {
        //     ViewModel.currentIssuance.$$membersRequired = true;
        //   }
        // }

        if ((ViewModel.currentIssuance.vertical === 'TERM' || ViewModel.currentIssuance.vertical === 'LIFE') && isSalesUser) {
          ViewModel.currentIssuance.$$salesError = true;
        }

        ViewModel.currentIssuance.$$policyNotUploaded = policyNotUploaded;
      }

      // TODO: Currently disabling the validation for 'MOTOR' vertical as data from 'ZoopOne' is inconsistent. Will be enabled once data confidence improves.
      if (ViewModel.currentIssuance.source === 'BRANCH' && ['TW' /*, 'CV', 'FW'*/].includes(ViewModel.currentIssuance.vertical) && ViewModel.currentIssuance.$$selectedVertical) {
        if (ViewModel.currentIssuance.$$selectedVertical !== ViewModel.currentIssuance.$$assignedVertical) {
          ViewModel.currentIssuance.$$selectedVerticalError = true;
        }
      }

      return ViewModel.currentIssuanceForm.$valid && !ViewModel.currentIssuance.$$inspectionErrorMessage && !ViewModel.currentIssuance.$$paymentProofNotUploaded && !ViewModel.currentIssuance.$$policyNotUploaded && !ViewModel.currentIssuance.$$statusNotPossible &&
      // !ViewModel.currentIssuance.$$membersRequired &&
      !ViewModel.currentIssuance.$$salesError && !ViewModel.currentIssuance.$$selectedVerticalError && !ViewModel.currentIssuance.$$proposerMobileMaxError;
    }

    function _saveCurrentIssuance() {
      if (ViewModel.currentIssuanceForm.$dirty) {
        var requiredList = ['insurer'];
        if (ViewModel.currentIssuance.source === 'BRANCH' || ViewModel.currentIssuance.source !== 'BRANCH' && ViewModel.currentIssuance.partnerId !== null) {
          requiredList.push('partnerName');
        }
        angular.forEach(ViewModel.currentIssuanceForm.$error.required, function (field) {
          field.$setDirty();
        });

        var validity = ViewModel.isPreSale ? true : _findFormValidity();
        var addCall = false;

        if (!ViewModel.isPreSale) {
          requiredList.forEach(function (field) {
            if (ViewModel.currentIssuance[field] === null || ViewModel.currentIssuance[field] === undefined) {
              _clearAutoconfig(field);
              validity = false;
            }
          });
        }

        if ($state.current.name === 'bi-create') {
          addCall = true;
        }

        if (validity) {
          if (ViewModel.currentIssuance.typeOfFlow && ViewModel.currentIssuance.typeOfFlow === 'AUTO_PARSING') {
            ViewModel.currentIssuance.typeOfFlow = 'MANUAL_FORM';
          }

          PolicyIssuanceService.update(ViewModel.currentIssuance).then(function (res) {
            PolicyIssuanceService.getById(ViewModel.currentIssuance.policyIssuanceId).then(function (res) {
              issuance = res;
              ViewModel.showSourceOfSaleFields = false;
              delete ViewModel.currentIssuance;
              $timeout(function () {
                ViewModel.currentIssuanceForm.$dirty = false;
                ViewModel.currentIssuance = issuance;
                ViewModel.currentIssuance.$$vertical = issuance.vertical;
                resetNavbarConfig(issuance);
                _initSOSfields(issuance);
                _initDecentralisation(issuance);
              }, 10);
            });

            if (addCall && pageType === 'BRANCH_ISSUANCE') {
              $state.go('bi-detail', {
                id: res.policyIssuanceId
              });
            }
            $mdToast.show($mdToast.simple().textContent('Issuance updated!').hideDelay(3000).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
              if (response === 'ok') {
                $window.console.log('You clicked the \'DISMISS\' action.');
              }
            });
          }).catch(function (err) {
            var msg = 'Issuance update failed!';
            if (err && err.message) {
              msg = err.message;
            }
            $mdToast.show($mdToast.simple().textContent(msg).hideDelay(20000).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right'));
          });
        } else {
          ViewModel.currentIssuance.$$showPolicyFields = true;
          for (var key in ViewModel.currentIssuanceForm.$error) {
            var errorArray = ViewModel.currentIssuanceForm.$error[key];
            for (var idx = 0; idx < errorArray.length; idx++) {
              var error = errorArray[idx];
              if (!error.$dirty) {
                ViewModel.currentIssuanceForm[error.$name].$setDirty();
              }
              $anchorScroll(error.$name);
              break;
            }
          }

          var errorMsg = '';
          if (ViewModel.currentIssuance.$$salesError) {
            errorMsg += 'Sales user can\'t issue a life policy.';
          }

          if (ViewModel.currentIssuance.$$inspectionErrorMessage) {
            errorMsg += 'Please fill required inspection details.';
          }

          if (ViewModel.currentIssuance.$$paymentProofNotUploaded) {
            errorMsg += ' Please enter transaction no. or upload requisite payment proof.';
          }

          if (ViewModel.currentIssuance.$$policyNotUploaded) {
            if (ViewModel.currentIssuance.vertical === 'TERM' || ViewModel.currentIssuance.vertical === 'LIFE') {
              errorMsg += ' Please enter Application No.';
            } else {
              errorMsg += ' Please upload policy document.';
            }
          }

          // if (ViewModel.currentIssuance.$$membersRequired) {
          //   errorMsg += 'Please add at least 1 member.';
          // }

          if (ViewModel.currentIssuance.$$selectedVerticalError) {
            errorMsg = 'The \'Vehicle Type\' selected by you does not match with vehicle type as per your \'Registration Number\'.';
          }

          if (ViewModel.currentIssuance.$$proposerMobileMaxError) {
            errorMsg = 'You have exceeded the maximum permissible limit for active policies on this number. Please enter another number to continue.';
          }

          errorMsg += ' Could not save. Please try again after filling required details.';

          if (ViewModel.currentIssuance.$$statusNotPossible) {
            if (ViewModel.currentIssuance.vertical === 'HEALTH' || ViewModel.currentIssuance.vertical === 'TERM' || ViewModel.currentIssuance.vertical === 'LIFE') {
              errorMsg = ' Request can\'t be saved. Please check inspection, payment, issuance and application status.';
            } else {
              errorMsg = ' Request can\'t be saved. Please check inspection, payment and issuance status.';
            }
          }

          $mdToast.show($mdToast.simple().textContent(errorMsg).hideDelay(20000).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right'));
        }
      } else {
        $mdToast.show($mdToast.simple().textContent('No changes to save.').hideDelay(6000).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right'));
      }
    }

    function _clearAutoconfig(code) {
      PolicyIssuanceService.clearAutoconfig(code);
    }

    function resetNavbarConfig(issuance) {
      ViewModel.editMode = isEditMode(issuance, ViewModel.isAccessReadOnly);
      if (ViewModel.editMode || ViewModel.isPreSale) {
        setNavbarCconfig('EDIT');

        if (ViewModel.currentIssuance.partnerId !== null) {
          updateNavbarConfig('COMMENTS');
        } else {
          updateNavbarConfig('EMAIL');
        }

        updateNavbarConfig('RESULTS_LINK');
        updateNavbarConfig('MOVE_TO_BRANCH');
      } else {
        setNavbarCconfig('READ_ONLY');
        updateNavbarConfig('COMMENTS');
      }
    }

    function selectBQP(val) {
      if (ViewModel.currentIssuanceForm.$dirty) {
        var discard = {
          title: 'Discard Changes',
          action: bqpModelDiscardFn
        };
        _saveChangesModel(discard);
      } else {
        setNavbarCconfig('READ_ONLY');
        PolicyIssuanceService.getBQPList(ViewModel.currentIssuance);
        ViewModel.editMode = !val;
        ViewModel.showReviewFields = val;
        ViewModel.editModeSOS = val;
      }
    }

    function bqpModelDiscardFn() {
      ViewModel.currentIssuanceForm.$dirty = false;
      $state.reload();
      $mdDialog.cancel();
    }

    function _saveChangesModel(discard) {
      $mdDialog.show({
        templateUrl: 'dev/policy-issuance/templates/dialogs/pi-save-confirmation.dialog.tpl.html',
        clickOutsideToClose: true,
        controller: piSaveDialogCtrl,
        locals: {
          onSave: _saveCurrentIssuance,
          discard: discard
        }
      });
      piSaveDialogCtrl.$inject = ['$scope', '$mdDialog', 'onSave', 'discard'];

      function piSaveDialogCtrl($scope, $mdDialog, onSave, discard) {

        $scope.discard = discard;

        $scope.save = function () {
          onSave();
          $mdDialog.cancel();
        };
      }
    }

    function updateBQP() {
      if (ViewModel.currentIssuanceForm.$dirty) {
        PolicyIssuanceService.saveBQPDetails(ViewModel.currentIssuance);
      } else {
        commonService.showToast('No changes to update');
      }
    }

    function cancelBQP() {
      setNavbarCconfig('EDIT');
      $state.reload();
    }

    function transferOpsBranch() {
      if (ViewModel.currentIssuanceForm.$dirty) {
        var discard = {
          title: 'Cancel',
          action: cancelWarningTransferBranch
        };
        _saveChangesModel(discard);
      } else {
        DecentralizationService.openTransferOpsDialog(ViewModel.currentIssuance.policyIssuanceId, moduleName, onTransfer);
      }
    }

    function cancelWarningTransferBranch() {
      $mdDialog.cancel();
    }

    function onTransfer() /*res*/{
      var stateToGo = ViewModel.currentIssuance.source !== 'BRANCH' ? 'ci-list' : 'bi-list';
      $timeout(function () {
        $state.go(stateToGo, { reload: true });
      }, 300);
    }

    function viewTransferLog(currentIssuance) {
      DecentralizationService.getTransferLog(currentIssuance.policyIssuanceId, moduleName).then(function (res) {
        DecentralizationService.openTransferLogDialog(res.data);
      }).catch(function () {
        commonService.showToast('Failed to load transfer logs.');
      });
    }

    function uploadCallBackFn(newFile) {
      if (newFile.tag === 'POLICY') {
        ViewModel.currentIssuance.fileId = newFile.processInfo.pid;
        ViewModel.currentIssuance.policyUrl = newFile.processInfo.pid;
        PolicyIssuanceService.syncFileMIS(ViewModel.currentIssuance.policyIssuanceId, ViewModel.currentIssuance.fileId);
      }
    }

    function deleteCallBackFn(deletedFile) {
      var documentCount = ViewModel.currentIssuance.$$attachedDocuments.filter(function (ele) {
        return ele.tag === deletedFile.tag;
      }).length;
      if (deletedFile.tag === 'POLICY' && documentCount === 0) {
        PolicyIssuanceService.syncFileMIS(ViewModel.currentIssuance.policyIssuanceId, ViewModel.currentIssuance.fileId);
      }
    }

    function changeTagCallBackFn(fileItem /*, oldTag*/) {
      if (fileItem.tag === 'POLICY') {
        PolicyIssuanceService.syncFileMIS(ViewModel.currentIssuance.policyIssuanceId, ViewModel.currentIssuance.fileId);
      }
    }

    /**
     * init form configs function
     *
     * @private
     *
     * @function
     *
     * @author snehilmodani
     *
     */

    function _initFormConfigs() {
      ViewModel.sourceOfSaleFields = PolicyIssuanceService.sourceOfSaleFields;
      ViewModel.opsBranchFields = PolicyIssuanceService.opsBranchFields;
      ViewModel.generalFields = PolicyIssuanceService.generalFields;
      ViewModel.inspectionConfig = PolicyIssuanceService.inspectionConfig;
      ViewModel.inspectionStatusConfig = PolicyIssuanceService.inspectionStatusConfig;
      ViewModel.paymentFields = PolicyIssuanceService.paymentFields;
      ViewModel.paymentStatusFormConfig = PolicyIssuanceService.paymentStatusFormConfig;
      ViewModel.issuanceStatusFields = PolicyIssuanceService.issuanceStatusFields;
      ViewModel.policyFields = PolicyIssuanceService.policyFields;
      ViewModel.memberConfig = PolicyIssuanceService.memberConfig;
      ViewModel.deviationFields = PolicyIssuanceService.deviationFields;
    }

    function showInspectionSubStatusLogs(policyIssuanceId) {
      PolicyIssuanceService.getInspectionSubStatusTrack(policyIssuanceId).then(function (res) {
        $mdDialog.show({
          controller: 'InspectionSubStatusCtrl',
          templateUrl: 'dev/policy-issuance/templates/dialogs/inspection-sub-status-dialog.tpl.html',
          clickOutsideToClose: true,
          locals: {
            inspectionSubStatusLogs: res
          }
        });
      }).catch(function (err) {
        $window.console.log('error occured', err);
        var errMsg = 'Some error occured while fetching Inspection Substatus logs.';
        if (err.status === 400) {
          errMsg = 'Inspection Substatus Details for one year old policy is not available.';
        } else if (err.status === 404) {
          errMsg = 'There are no Inspection Substatus logs for this policy.';
        }
        commonService.showToast(errMsg);
      });
    }

    function toggleShowDocuments() {
      ViewModel.showDocuments = !ViewModel.showDocuments;
      var action = ViewModel.showDocuments ? 'FILES' : 'FORM';
      TrackingService.pushToGTMDatalayer(eventPrefix + '_DETAIL_GOTO_' + action, 'track_event', {});
    }

    function openSectionNav() {
      TrackingService.pushToGTMDatalayer(eventPrefix + '_SECTION_NAV_OPEN', 'track_event', {});
      $mdBottomSheet.show({
        templateUrl: 'dev/policy-issuance/templates/pi-section-nav-mob.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true
      }).then(function () {}).catch(function () {});
    }

    function addUpdateInspection(_policyIssuanceId, index, currentInspection, inspectionObj, cancelPressed) {
      var sampleInspection = {};
      if (!inspectionObj) {
        if (index > 0) {
          sampleInspection = angular.copy(ViewModel.currentIssuance.inspectionDetails[index - 1]);
          // delete the fields
          delete sampleInspection.createdAt;
          delete sampleInspection.createdById;
          delete sampleInspection.fromTime;
          delete sampleInspection.id;
          delete sampleInspection.toTime;
          delete sampleInspection.updatedAt;
          delete sampleInspection.updatedById;
          delete sampleInspection.preferredDate;
          inspectionObj = sampleInspection;
        }
      }

      if (!ViewModel.editMode) {
        $mdToast.show($mdToast.simple().textContent('Cannot update read-only entry.').hideDelay(3000).action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
          if (response === 'ok') {
            $window.console.log('You clicked the \'DISMISS\' action.');
          }
        });
        return;
      }

      if (currentInspection.inspectionMode === 'automate' && cancelPressed) {
        var confirm = $mdDialog.confirm().title('').textContent('This action will cancel the existing inspection request (If survey not yet done) and switch the inspection to manual mode. Do you want to proceed?').ok('OK').cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          PolicyIssuanceService.cancelAutomate(currentInspection);
        });
        return;
      }

      $mdDialog.show({
        controller: 'InspectionAddUpdateCtrl',
        templateUrl: 'dev/policy-issuance/templates/inspection-add-update.dialog.tpl.html',
        clickOutsideToClose: false,
        resolve: {
          currentInspection: function currentInspection() {
            if (inspectionObj) {
              return inspectionObj;
            }

            return {
              policyIssuanceId: _policyIssuanceId
            };
          },
          policyIssuanceId: function policyIssuanceId() {
            return _policyIssuanceId;
          },
          attemptNo: function attemptNo() {
            return index + 1;
          },
          readOnly: function readOnly() {
            return currentInspection.inspectionMode === 'automate' ? true : false;
          }
        }
      }).then(function (res) {
        ViewModel.currentIssuance.inspectionDetails[index] = res;
      });
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance').controller('InspectionAddUpdateCtrl', controller);

  controller.$inject = ['$q', '$scope', '$mdDialog', 'PolicyIssuanceService', '$window', 'currentInspection', 'policyIssuanceId', 'attemptNo', 'readOnly', '$anchorScroll'];

  /**
   *
   * Bulk Export Dialog Controller
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @param     {Object}    $q                          Angular Q instance
   * @param     {Object}    $scope                      Angular scope
   * @param     {Object}    $mdDialog                   mdDialog instance
   * @param     {Object}    PolicyIssuanceService       PolicyIssuanceService
   *
   */
  function controller($q, $scope, $mdDialog, PolicyIssuanceService, $window, currentInspection, policyIssuanceId, attemptNo, readOnly, $anchorScroll) {

    var ViewModel = $scope;

    ViewModel.readOnly = readOnly;
    ViewModel.cancelDialog = cancelDialog;
    ViewModel.saveInspection = saveInspection;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.editMode = readOnly ? false : true;
      ViewModel.currentInspection = angular.copy(currentInspection);
      if (ViewModel.currentInspection && ViewModel.currentInspection.preferredDate) {
        ViewModel.currentInspection.preferredDate = moment(ViewModel.currentInspection.preferredDate).toString();
      }

      ViewModel.attemptNo = attemptNo;

      var _timeOptions = ['9:00AM', '10:00AM', '11:00AM', '12:00PM', '1:00PM', '2:00PM', '3:00PM', '4:00PM', '5:00PM', '6:00PM', '7:00PM', '8:00PM', 'To Be Confirmed'];
      ViewModel.inspectionAddUpdateConfig = [{
        name: 'Address for Inspection',
        code: 'address',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Contact Number',
        code: 'mobile',
        type: 'text',
        minLength: 10,
        maxLength: 10,
        pattern: '[1-9]{1}[0-9]{9}'
      }, {
        name: 'Date',
        code: 'preferredDate',
        type: 'date'
      }, {
        name: 'From Time',
        code: 'fromTime',
        type: 'select',
        selectConfig: {
          options: _timeOptions
        }
      }, {
        name: 'To Time',
        code: 'toTime',
        type: 'select',
        selectConfig: {
          options: _timeOptions
        }
      }, {
        name: 'Inspection No',
        code: 'inspectionNumber',
        type: 'text'
      }, {
        name: 'Agency Name',
        code: 'agencyName',
        type: 'text'
      }, {
        name: 'Agency Contact No.',
        code: 'agencyContact',
        type: 'text',
        minLength: 10,
        maxLength: 10,
        pattern: '[1-9]{1}[0-9]{9}'
      }, {
        name: 'Remarks',
        code: 'remarks',
        type: 'text'
      }];
    }

    /**
     * Function for Export file details
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */
    function saveInspection() {

      var deferred = $q.defer();

      if (ViewModel.currentInspectionForm.$valid) {
        ViewModel.editMode = false;
        PolicyIssuanceService.saveInspection(policyIssuanceId, ViewModel.currentInspection).then(function () {
          angular.extend(currentInspection, ViewModel.currentInspection);
          $mdDialog.hide(currentInspection);
          deferred.resolve(currentInspection);
          if (currentInspection && currentInspection.preferredDate) {
            currentInspection.preferredDate = moment(currentInspection.preferredDate).toString();
          }
        }).catch(function (err) {
          $window.alert('No records found');
          deferred.reject(err);
        }).finally(function () {
          ViewModel.editMode = true;
        });
      } else {
        for (var key in ViewModel.currentInspectionForm.$error) {
          var errorArray = ViewModel.currentInspectionForm.$error[key];
          for (var idx = 0; idx < errorArray.length; idx++) {
            var error = errorArray[idx];
            if (!error.$dirty) {
              ViewModel.currentInspectionForm[error.$name].$setDirty();
            }
            $anchorScroll(error.$name);
            break;
          }
        }
      }

      return deferred.promise;
    }

    /**
     * Close the Current Dialog
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     */
    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.policyIssuance').controller('EmailClientController', controller);

  controller.$inject = ['$q', '$scope', '$mdDialog', '$mdToast', '$window', 'PolicyIssuanceService', 'policyIssuanceId'];

  /**
   *
   * Bulk Export Dialog Controller
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @param     {Object}    $q                          Angular Q instance
   * @param     {Object}    $scope                      Angular scope
   * @param     {Object}    $mdDialog                   mdDialog instance
   *
   */
  function controller($q, $scope, $mdDialog, $mdToast, $window, PolicyIssuanceService, policyIssuanceId) {

    var ViewModel = $scope;

    ViewModel.cancelDialog = cancelDialog;
    ViewModel.sendEmail = sendEmail;

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.editMode = true;
      ViewModel.formObj = {
        message: ''
      };

      ViewModel.messageConfig = {
        name: 'Message',
        code: 'message',
        type: 'textarea',
        placeholder: 'Sample email text',
        required: true,
        textareaConfig: {
          rows: 3
        },
        hideLabel: true
      };
    }

    /**
     * Function for Export file details
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */
    function sendEmail() {
      if (ViewModel.clientMessageForm.$valid) {
        ViewModel.editMode = false;

        PolicyIssuanceService.sendEmailToClient(policyIssuanceId, ViewModel.formObj.message).then(function () {
          $mdDialog.hide(ViewModel.formObj.message);
        }).catch(function () {
          $mdToast.show($mdToast.simple().textContent('Error in sending email. Please try again').action('DISMISS').highlightAction(true).highlightClass('md-accent').position('bottom right')).then(function (response) {
            if (response === 'ok') {
              $window.console.log('You clicked the \'DISMISS\' action.');
            }
          });
        });
      }
    }

    /**
     * Close the Current Dialog
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     */
    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').controller('moveToBranchCtrl', moveToBranchCtrl);

  moveToBranchCtrl.$inject = ['$q', '$scope', '$mdDialog', 'policyIssuance', '$mdToast', '$window', 'PolicyIssuanceService'];

  function moveToBranchCtrl($q, $scope, $mdDialog, policyIssuance, $mdToast, $window, PolicyIssuanceService) {

    var ViewModel = $scope;
    ViewModel.cancelDialog = cancelDialog;
    ViewModel.save = save;
    ViewModel.checkFormValid = checkFormValid;
    ViewModel.targetSource = policyIssuance.source === 'BRANCH' ? 'Branch' : 'Central';
    ViewModel.targetDestination = policyIssuance.source !== 'BRANCH' ? 'Branch' : 'Central';

    init();

    /*=============================================
    =            Implementation                  =
    =============================================*/

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.editMode = true;
      ViewModel.modelObj = {
        message: '',
        issuanceReason: [],
        OPSEmail: ''
      };
      if (ViewModel.targetSource === 'Branch') {
        ViewModel.modelObj.reason = '';
      }

      ViewModel.checkFields = [{
        name: 'To deliver better TAT to the customer',
        value: 'To deliver better TAT to the customer',
        check: false
      }, {
        name: 'To deliver lower price to the customer',
        value: 'To deliver lower price to the customer',
        check: false
      }, {
        name: 'To deliver better service to the customer (issuance & post sales)',
        value: 'To deliver better service to the customer (issuance & post sales)',
        check: false
      }, {
        name: 'Others',
        value: '',
        check: false
      }];
    }

    function checkFormValid() {
      if (ViewModel.targetSource === 'Central') {
        var checkList = ViewModel.checkFields.filter(function (ele) {
          return ele.check;
        });
        var others = ViewModel.checkFields[ViewModel.checkFields.length - 1];
        return !(checkList.length && (others.check && others.value || !others.check) && ViewModel.modelObj.OPSEmail);
      } else if (ViewModel.targetSource === 'Branch') {
        return !ViewModel.modelObj.reason;
      }
    }

    /**
     * Function for Export file details
     *
     * @public
     *
     * @memberof  module:turtlemintAdmin.mis.bulkExportCtrl#
     *
     * @author    beerpratapsingh
     *
     * @return    {Promise}    Promise for search results.
     *
     */
    function save() {
      if (ViewModel.targetSource === 'Central') {
        ViewModel.checkFields.forEach(function (checkField) {
          {
            if (checkField.check && checkField.value !== '') {
              ViewModel.modelObj.issuanceReason.push(checkField.value);
            }
          }
        });
      }
      if (ViewModel.targetSource === 'Branch') {
        ViewModel.modelObj.issuanceReason = [].concat(_toConsumableArray(ViewModel.modelObj.issuanceReason), [ViewModel.modelObj.reason]);
      }

      var policyIssuanceObj = angular.copy(policyIssuance);
      policyIssuanceObj.source = policyIssuance.source === 'BRANCH' ? 'ONLINE' : 'BRANCH';
      policyIssuanceObj.sourceChangeReasons = ViewModel.modelObj.issuanceReason;
      if (ViewModel.targetSource === 'Central') {
        policyIssuanceObj.opsemail = ViewModel.modelObj.OPSEmail;
      }
      PolicyIssuanceService.update(policyIssuanceObj, { cascadeUpdate: true }).then(function (res) {
        var data = res;
        var action = data.source === 'ONLINE' ? true : false;
        return PolicyIssuanceService.makeActionable(data.policyIssuanceId, action);
      }).then(function () {
        $mdDialog.hide(true);
      }).catch(function () {});
    }

    function cancelDialog() {
      $mdDialog.cancel();
    }
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').provider('PolicyIssuanceService', PolicyIssuanceService);

  PolicyIssuanceService.$inject = [];

  return PolicyIssuanceService;

  // //////////////////////////////////////////////////////

  /**
   * PolicyIssuanceService
   *
   * @public
   *
   * @author    snehilmodani
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function PolicyIssuanceService() {

    PolicyIssuanceService.$inject = ['$q', 'commonService', 'MIS_CONSTANTS', '$filter', 'FormHelperService', '$timeout', 'UtilService', '$window', 'PartnerService', 'ninjaCommonDataService', 'CompliantService', '$state', 'MasterFileService', 'GENERAL_CONFIG', 'CONSTANTS_LIST'];

    return {
      $get: PolicyIssuanceService
    };

    // //////////////////////////////////////////////////////

    /**
     * PolicyIssuanceService
     *
     * @public
     *
     * @memberof   module:turtlemintAdmin.policyIssuance.PolicyIssuanceService#
     *
     * @author     snehilmodani
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    commonService                  common Service
     * @param      {Object}    MIS_CONSTANTS                  MIS Constants
     *
     * @return     {Object}    Instance of the Service
     */
    function PolicyIssuanceService($q, commonService, MIS_CONSTANTS, $filter, FormHelperService, $timeout, UtilService, $window, PartnerService, ninjaCommonDataService, CompliantService, $state, MasterFileService, GENERAL_CONFIG, CONSTANTS_LIST) {

      var currentIssuance = {};

      var saleConfig = {};

      var BEStatusLists = {};
      var TM_URL = commonService.getUrlTM();

      //Get Ninja CommonData List
      ninjaCommonDataService.getNinjaCommonData().then(function (ninjaFiltersList) {
        saleConfig.vehicleTypes = angular.copy(ninjaFiltersList.vehicleType);
        saleConfig.paymentFrequencyTypes = angular.copy(ninjaFiltersList.paymentFrequency);
        var paymentFrequencyConfig = _getConfigObjectFromCode('premiumPaymentFrequency');
        paymentFrequencyConfig.selectConfig.options = saleConfig.paymentFrequencyTypes;
        saleConfig.captureReason = angular.copy(ninjaFiltersList.reason);
        saleConfig.sourceOfSaleStatus = angular.copy(ninjaFiltersList.sourceOfSaleStatus);
        saleConfig.sourceOfSaleType = angular.copy(ninjaFiltersList.sourceOfSaleType);
        var typeOfSaleConfig = _getConfigObjectFromCode('typeOfSale');
        typeOfSaleConfig.selectConfig.options = saleConfig.sourceOfSaleType;
        var reasonConfig = _getConfigObjectFromCode('reason');
        reasonConfig.selectConfig.options = saleConfig.captureReason;
      });

      saleConfig.productNames = angular.copy(MIS_CONSTANTS.productNames);

      saleConfig.lifePlanTypes = angular.copy(MIS_CONSTANTS.lifePlanTypes);
      saleConfig.subPlanTypes = angular.copy(MIS_CONSTANTS.subPlanTypes);
      saleConfig.paymentTypes = angular.copy(MIS_CONSTANTS.paymentTypes);
      saleConfig.benefitPayoutTypes = angular.copy(MIS_CONSTANTS.benefitPayoutTypes);
      saleConfig.productCategories = angular.copy(MIS_CONSTANTS.productCategories);
      saleConfig.lifeCategories = angular.copy(MIS_CONSTANTS.lifeCategories);
      saleConfig.healthPlanTypes = angular.copy(MIS_CONSTANTS.healthPlanTypes);

      saleConfig.businessTypes = angular.copy(MIS_CONSTANTS.businessTypes);
      saleConfig.carrierTypes = angular.copy(MIS_CONSTANTS.carrierTypes);
      saleConfig.tenant = MIS_CONSTANTS.tenants;
      saleConfig.genderList = angular.copy(MIS_CONSTANTS.genderList);
      saleConfig.paymentModeRadioConf = angular.copy(MIS_CONSTANTS.paymentModeRadioConf);
      saleConfig.inspectionPreferenceList = angular.copy(MIS_CONSTANTS.inspectionPreferenceList);
      saleConfig.variants = [];
      saleConfig.cities = [];
      saleConfig.planNames = [];
      saleConfig.applicationStatusList = [];
      saleConfig.bqpList = [];
      var broker = $window.sessionStorage.getItem('broker');
      var memberConfig = {};
      var isIdfc = commonService.isIdfcBroker();
      if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _saleConfig$productCa;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_saleConfig$productCa = saleConfig.productCategories).push.apply(_saleConfig$productCa, _toConsumableArray(turtlemintProductCategories));
      }
      var issuanceDefaultFilter = {
        vertical: [],
        status: ['PENDING', 'WAITING_FOR_INSURER'],
        inspectionRequired: [],
        inspectionStatus: ['PENDING', 'SCHEDULED', 'COMPLETED_RESPONSE_AWAITED', 'RECOMMENDED'],
        piBranchLocation: [],
        paymentMode: [],
        adminOwner: [],
        sourceOfSaleStatus: [],
        sourceOfSaleType: [],
        policyType: []
      };

      getTenants().then(function (res) {
        saleConfig.tenant.splice(0, saleConfig.tenant.length);
        if (res && res.length > 0) {
          for (var idx = res.length - 1; idx >= 0; idx--) {
            saleConfig.tenant.push(res[idx]);
          }
        }
      });

      var sourceOfSaleFields = [{
        name: 'Type of Sale',
        code: 'typeOfSale',
        placeholder: '',
        type: 'select',
        disabled: true,
        selectConfig: {
          optionType: 'OBJECT'
        },
        changeFn: _typeOfSaleChangeFn,
        initFn: _typeOfSaleInitFn,
        required: true
      }, {
        name: 'BQP',
        code: 'bqp',
        placeholder: 'Select BQP',
        hide: false,
        type: 'autocomplete',
        initFn: _selectedBQPInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedBQPChangeFn,
          querySearch: _searchBQPFn,
          displayKey: 'name',
          valueKey: 'clientId',
          resultTitleKey: 'title',
          resultLabelKey: 'label'
        },
        required: true
      }];

      var opsBranchFields = [{
        name: 'Ops Branch',
        code: 'opsBranch',
        placeholder: 'Ops Branch',
        type: 'text',
        disabled: true,
        required: true,
        hide: true
      }];

      var generalFields = [{
        name: 'Request Id',
        code: 'requestId',
        placeholder: '',
        type: 'text',
        disabled: true,
        required: true
      }, {
        name: 'Product Category',
        code: '$$vertical',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: saleConfig.productCategories,
          valueKey: 'value',
          textKey: 'text'
        },
        changeFn: _verticalChangeFn,
        initFn: _verticalInitFn,
        required: true
      }, {
        name: 'Registration Number',
        code: 'registrationNumber',
        // Validation - pattern
        type: 'text',
        hide: true,
        required: true,
        blurFn: getRegistrationResult
      }, {
        name: 'Category',
        code: 'category',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: saleConfig.lifeCategories,
          valueKey: 'value',
          textKey: 'text'
        },
        changeFn: _categoryChangeFn,
        initFn: _categoryChangeFn,
        required: true,
        hide: true
      }, {
        name: 'Tenant',
        code: 'tenant',
        placeholder: '',
        type: 'select',
        required: true,
        // hide: true,
        changeFn: _tenantChangeFn,
        initFn: _tenantChangeFn,
        selectConfig: {
          options: saleConfig.tenant,
          valueKey: '_id',
          textKey: '_id'
        }
      }, {
        name: 'Policy Detail Id',
        code: 'policyDetailsId',
        placeholder: '',
        type: 'text',
        disabled: true,
        hide: true
      }, {
        name: 'Insurer',
        code: 'insurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        initFn: _selectedInsurerInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        },
        required: true
      }, {
        name: 'Business Type',
        code: 'businessType',
        placeholder: '',
        type: 'select',
        required: true,
        selectConfig: {
          options: saleConfig.businessTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Sale Close Date',
        code: 'saleCloseDate',
        type: 'date',
        required: true,
        initFn: _saleCloseDateChangeFn,
        changeFn: _saleCloseDateChangeFn
      }, {
        name: 'Product name',
        code: 'policyType',
        placeholder: '',
        type: 'select',
        changeFn: _planTypeChangeFn,
        initFn: _planTypeInitFn,
        required: true,
        selectConfig: {
          options: saleConfig.productNames,
          valueKey: 'value',
          textKey: 'text'
        },
        hide: true
      }, {
        name: 'PI Type',
        code: 'piType',
        type: 'text',
        disabled: true,
        hide: true
      }, {
        name: 'Why did you choose to issue this policy at branch?',
        code: 'reason',
        placeholder: '',
        type: 'select',
        changeFn: _captureReasonChangeFn,
        initFn: _captureReasonInitFn,
        selectConfig: {
          options: saleConfig.captureReason,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Insurer Remarks',
        code: 'insurerRemarks',
        type: 'textarea',
        hide: true,
        readonly: true,
        className: 'maxHeight',
        textareaConfig: {
          rows: 2
        }
      }, {
        name: 'Reason Options',
        code: 'reasonOptions',
        placeholder: '',
        type: 'text',
        hide: true
      }, {
        name: 'Quote Type',
        code: 'quoteType',
        placeholder: '',
        type: 'text',
        disabled: true
      }, {
        name: 'Remarks',
        code: 'comment',
        type: 'text'
      }];

      function _captureReasonChangeFn(selectedItem, model) {
        if (selectedItem) {
          _captureReasonInitFn(selectedItem, model);
          delete model.reasonOptions;
        }
      }

      function _captureReasonInitFn(selectedItem, model) {
        var showReasonOption = false;
        if (selectedItem) {
          _showHideField('reasonOptions', true, true);
          var selectedObj = saleConfig.captureReason.find(function (ele) {
            if (ele.code === selectedItem) {
              return ele;
            }
          });
          var reasonOptions = selectedObj.reasonOptions;
          if (reasonOptions && reasonOptions.type !== '') {
            var reasonOptionsConfig = _getConfigObjectFromCode('reasonOptions');
            delete reasonOptionsConfig.type;
            delete reasonOptionsConfig.min;
            delete reasonOptionsConfig.max;
            delete reasonOptionsConfig.minLength;
            delete reasonOptionsConfig.selectConfig;
            delete reasonOptionsConfig.step;
            reasonOptionsConfig.type = reasonOptions.type;
            reasonOptionsConfig.name = selectedObj.reasonOptions.type !== '' ? selectedObj.name : '';
            if (reasonOptions.type === 'select') {
              reasonOptionsConfig.selectConfig = {
                textKey: 'name',
                valueKey: 'code',
                options: reasonOptions.options
              };
            } else if (reasonOptionsConfig.type === 'number') {
              model.reasonOptions = parseInt(model.reasonOptions);
              reasonOptionsConfig.name = reasonOptionsConfig.name + '(%)';
              reasonOptionsConfig.min = 1;
              reasonOptionsConfig.max = 100;
              reasonOptionsConfig.step = 1;
              reasonOptionsConfig.placeholder = 'Discount in %';
            } else if (reasonOptionsConfig.type === 'textarea') {
              reasonOptionsConfig.minLength = 15;
              reasonOptionsConfig.placeholder = 'Enter reason min 15 characters';
            }
            showReasonOption = true;
          } else {
            showReasonOption = false;
          }
        } else {
          showReasonOption = false;
        }
        _showHideField('reasonOptions', !showReasonOption);
        _markFieldRequired('reasonOptions', showReasonOption);
      }

      /**
       * Function called on change of Tenants
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale                  sale
       *
       * @author sumantaparida
       *
       */
      function _tenantChangeFn(selectedItem) {
        if (selectedItem === 'turtlemint') {
          _markFieldRequired('partnerName', true);
        } else {
          _markFieldRequired('partnerName', false);
        }
      }

      /**
       * Function called on change of Insurer
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale                  sale
       *
       * @author snehilmodani
       *
       */
      function _selectedInsurerChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.insurer = selectedItem.value;
          getProductUINMasters(model);
        } else {
          delete model.insurer;
        }
      }

      function _selectedPreviousODInsurerChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.previousODInsurer = selectedItem.value;
        } else {
          delete model.previousODInsurer;
        }
      }

      function _selectedActiveTPInsurerChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.activeTPInsurer = selectedItem.value;
        } else {
          delete model.activeTPInsurer;
        }
      }

      function _categoryChangeFn(selectedItem, model) {
        _grossPremiumChangeFn(model.grossPremium, model);
      }

      function _selectedInsurerInitFn(selectedItem, model) {
        if (selectedItem) {
          model.insurer = selectedItem;
          getProductUINMasters(model);
        } else {
          delete model.insurer;
        }
      }

      function _selectedPreviousODInsurerInitFn(selectedItem, model) {
        if (selectedItem) {
          model.previousODInsurer = selectedItem;
        } else {
          delete model.previousODInsurer;
        }
      }
      function _selectedActiveTPInsurerInitFn(selectedItem, model) {
        if (selectedItem) {
          model.activeTPInsurer = selectedItem;
        } else {
          delete model.activeTPInsurer;
        }
      }

      function _saleCloseDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      var inspectionConfig = [{
        name: 'Mode of Inspection',
        code: 'inspectionPreference',
        type: 'select',
        hide: true,
        selectConfig: {
          options: saleConfig.inspectionPreferenceList,
          valueKey: 'code',
          textKey: 'text'
        }
      }, {
        name: 'Inspection Required',
        code: 'inspectionRequired',
        type: 'radio',
        class: '1',
        inspectionMode: 'editMode',
        changeFn: _inspectionConfigChangeFn,
        initFn: _inspectionConfigChangeFn,
        radioConfig: {
          options: [{
            value: 'REQUIRED',
            text: 'Required'
          }, {
            value: 'NOT_REQUIRED',
            text: 'Not Required'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: '',
        code: 'statusChangeHistory',
        placeholder: 'Remarks',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Reasons for Inspection',
        code: '$$inspectionReasons',
        placeholder: 'Reasons for Inspection',
        hide: true,
        readonly: true,
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }];

      var inspectionStatusConfig = [{
        name: 'Inspection Status',
        code: 'inspectionStatus',
        type: 'select',
        changeFn: _inspectionStatusChangeFn,
        initFn: _inspectionStatusInitFn,
        selectConfig: {
          options: [{
            value: 'DONE',
            text: 'Done'
          }, {
            value: 'NOT_DONE',
            text: 'Not Done'
          }, {
            value: 'PENDING',
            text: 'Pending'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Inspection subStatus',
        code: 'inspectionSubStatus',
        type: 'select',
        changeFn: _inspectionSubStatusChangeFn,
        selectConfig: {
          options: [],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Reasons for Inspection sub Status change',
        code: 'inspectionSubStatusReasons',
        type: 'textarea',
        maxLength: 140,
        textareaConfig: {
          rows: 3
        }
      }];

      function _inspectionStatusInitFn(selectedItem, model) {
        checkInspectionError(model);

        if (selectedItem === 'RECOMMENDED') {
          if (!model.paymentMode) {
            var paymentModeFieldConfig = _getConfigObjectFromCode('paymentMode');
            paymentModeFieldConfig.required = true;
          }
        }
        _paymentModeChangeFn(model.paymentMode, model);
      }

      function _inspectionStatusChangeFn(selectedItem, model) {
        _inspectionStatusInitFn(selectedItem, model);
        if (selectedItem === 'RECOMMENDED') {
          if (model.source === 'ONLINE') {
            var reqUrl = '/actionable';
            var body = {
              policyIssuanceId: model.policyIssuanceId,
              actionable: true
            };
            commonService.makeHttpCall(reqUrl, body, 'POST', false).then(function (res) {
              model.actionable = res.data.actionable;
              return res;
            });
          }
        }
      }

      function _inspectionSubStatusChangeFn(selectedItem, model) {
        if (selectedItem) {
          delete model.inspectionSubStatusReasons;
        }
      }

      function makeActionable(policyIssuanceId) {
        var actionable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var reqUrl = '/actionable';
        var body = {
          policyIssuanceId: policyIssuanceId,
          actionable: actionable
        };
        return commonService.makeHttpCall(reqUrl, body, 'POST', false).then(function (res) {
          return res;
        });
      }

      function cancelAutomate(inspectObj) {
        var body = {};
        var reqUrl = '/v0/inspection/cancel';
        body.status = 'cancelled';
        body.remarks = inspectObj.inspectionRemarks;
        body.refNumber = inspectObj.inspectionNumber;
        body.insurerReferenceNumber = inspectObj.referenceNumber;
        body.inspectionMode = 'manual';
        body.inspectionProvider = '';
        body.notifyOps = true;
        body.policyIssuanceId = inspectObj.policyIssuanceId;
        commonService.makeThirdPartyApiCall(TM_URL, reqUrl, body, 'POST', false).then(function (response) {
          if (response) {
            window.location.reload();
          }
        });
      }

      function checkInspectionFilled(inspectionObj) {
        var checkFields = ['address', 'mobile', 'preferredDate', 'fromTime', 'toTime', 'inspectionNumber', 'agencyName', 'agencyContact'];
        for (var idx = checkFields.length - 1; idx >= 0; idx--) {
          var fieldName = checkFields[idx];
          if (!inspectionObj[fieldName]) {
            return false;
          }
        }

        return true;
      }

      var paymentFields = [{
        name: 'Amount Paid',
        code: '$$grossPremium',
        placeholder: '',
        changeFn: _$$grossPremiumChangeFn,
        initFn: _$$grossPremiumChangeFn,
        type: 'number'
      }, {
        name: 'Premium',
        code: 'amountPaid',
        placeholder: '',
        type: 'number'
      }, {
        name: 'Balance Recovery',
        code: 'balanceRecovery',
        placeholder: '',
        type: 'select',
        selectConfig: {
          options: [{
            text: 'Not Required',
            code: 'NOT_REQUIRED'
          }, {
            text: 'Amount collected from partner',
            code: 'COLLECTED_FROM_PARTNER'
          }, {
            text: 'Amount paid by ' + broker + ' employee',
            code: 'COLLECTED_BY_TM_EMPLOYEE'
          }, {
            text: 'Amount paid by ' + broker,
            code: 'PAID_BY_TM'
          }],
          valueKey: 'code',
          textKey: 'text'
        }
      }, {
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'radio',
        // readonly: true,
        // disabled: true,
        changeFn: _paymentModeChangeFn,
        initFn: _paymentModeChangeFn,
        radioConfig: {
          options: saleConfig.paymentModeRadioConf,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Payment Details Shared',
        code: 'paymentDetailsShared',
        type: 'checkbox',
        changeFn: _paymentDetailsSharedChangeFn,
        initFn: _paymentDetailsSharedChangeFn
      }, {
        name: 'Payment Gateway Id',
        code: 'gatewayTxnNo',
        placeholder: 'Enter Payment Link',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'Payment Link',
        code: 'paymentLink',
        placeholder: 'Enter Payment Link',
        type: 'text'
      }, {
        name: 'NEFT Details',
        code: 'neftDetails',
        placeholder: '',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Cheque Details',
        code: 'chequeDetails',
        placeholder: '',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Transaction Number',
        code: 'txnNo',
        placeholder: '',
        type: 'text'
      }, {
        name: 'Transaction ID',
        code: 'transactionId',
        placeholder: '',
        type: 'text',
        hide: true
      }];

      function _$$grossPremiumChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.grossPremium = selectedItem;
        }
      }

      function _paymentModeChangeFn(selectedItem, model) {
        var chequeDetailsFieldConfig = _getConfigObjectFromCode('chequeDetails');
        var neftDetailsFieldConfig = _getConfigObjectFromCode('neftDetails');
        var paymentLinkFieldConfig = _getConfigObjectFromCode('paymentLink');
        if (model.paymentStatus === 'COMPLETED' || model.inspectionRequired === 'NOT_REQUIRED') {
          switch (selectedItem) {
            case 'CHEQUE':
              chequeDetailsFieldConfig.required = true;
              neftDetailsFieldConfig.required = false;
              paymentLinkFieldConfig.required = false;
              break;
            case 'NEFT':
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = true;
              paymentLinkFieldConfig.required = false;
              break;
            case 'ONLINE':
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = false;
              paymentLinkFieldConfig.required = false;
              break;
            default:
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = false;
              paymentLinkFieldConfig.required = false;
          }
        } else {
          chequeDetailsFieldConfig.required = false;
          neftDetailsFieldConfig.required = false;
          paymentLinkFieldConfig.required = false;
        }
      }

      var paymentStatusFormConfig = [{
        name: 'Payment Status',
        code: 'paymentStatus',
        placeholder: '',
        changeFn: _paymentStatusChangeFn,
        initFn: _paymentStatusInitFn,
        type: 'select',
        selectConfig: {
          options: [{
            text: 'Payment Completed',
            value: 'PAYMENT_COMPLETE'
          }, {
            text: 'Payment Pending',
            value: 'PAYMENT_PENDING'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Payment Remarks',
        code: 'paymentRemarks',
        type: 'text',
        required: true,
        hide: true
      }, {
        name: 'Payment Complete Date',
        code: 'paymentCompleteDate',
        type: 'date',
        hide: true,
        required: true
      }, {
        name: 'Payment verified',
        code: 'paymentVerified',
        type: 'checkbox',
        hide: true,
        initFn: _paymentVerifiedInitFn,
        changeFn: _paymentVerifiedInitFn
      }, {
        name: 'Application Status',
        code: 'applicationStatus',
        type: 'select',
        selectConfig: {
          options: saleConfig.applicationStatusList,
          valueKey: 'value',
          textKey: 'text'
        },
        hide: true
      }, {
        name: 'Application URL',
        code: 'applicationUrl',
        type: 'text',
        hide: true,
        changeFn: _applicationUrlInitFn,
        initFn: _applicationUrlInitFn
      }];

      function _paymentStatusChangeFn(selectedItem, model) {
        if (selectedItem !== 'COMPLETED') {
          delete model.paymentVerified;
          delete model.paymentCompleteDate;
        }

        _paymentStatusInitFn(selectedItem, model);
      }

      function _paymentStatusInitFn(selectedItem, model) {
        if (selectedItem === 'COMPLETED') {
          _markFieldRequired('paymentMode', true);
          _showHideField('paymentVerified', false);
          _showHideField('paymentCompleteDate', false);
        } else {
          _showHideField('paymentVerified', true);
          _showHideField('paymentCompleteDate', true);
        }

        if (selectedItem === 'REJECTED') {
          _markFieldRequired('paymentMode', false);
          _showHideField('paymentRemarks', false);

          _hideIssuanceStatusFields();
        } else {
          _showIssuanceStatusFields();
          _showHideField('paymentRemarks', true);
          _issuanceStatusChangeFn(model.policyIssuanceStatus, model);
        }

        _showHideField('paymentDetailsShared', false);

        _paymentModeChangeFn(model.paymentMode, model);
        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          _applicationUrlInitFn(model.applicationUrl, model);
          _showHideField('applicationNumber', false);
          _applicationNumberInitFn(model.applicationNumber, model);
        } else {
          _showHideField('applicationNumber', true);
        }
      }

      function _showIssuanceStatusFields() {
        _showHideIssuanceStatusFields(false);
      }

      function _hideIssuanceStatusFields() {
        _showHideIssuanceStatusFields(true);
      }

      function _showHideIssuanceStatusFields(hide, preserve) {
        for (var idx = issuanceStatusFields.length - 1; idx >= 0; idx--) {
          var fieldConfig = issuanceStatusFields[idx];
          _showHideField(fieldConfig.code, hide, preserve);
        }
      }

      function _paymentDetailsSharedChangeFn(selectedItem, model) {
        if (selectedItem) {
          _showHideField('chequeDetails', true);
          _showHideField('neftDetails', true);
          _showHideField('paymentLink', true);
        } else {
          _showHideField('chequeDetails', false);
          _showHideField('neftDetails', false);
          _showHideField('paymentLink', false);
          _paymentStatusChangeFn(model.paymentStatus, model);
        }
      }

      function _paymentVerifiedInitFn(selectedItem, model) {
        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          _applicationUrlInitFn(model.applicationUrl, model);
          _applicationNumberInitFn(model.applicationNumber, model);
        }
      }

      function _applicationUrlInitFn(selectedItem, model) {
        if (model.paymentStatus === 'COMPLETED' && model.paymentVerified) {
          _markFieldRequired('applicationUrl', true);
        } else {
          _markFieldRequired('applicationUrl', false);
        }
      }

      function _applicationNumberInitFn(selectedItem, model) {
        if (model.source === 'ONLINE') {
          if (model.paymentStatus === 'COMPLETED' && model.paymentVerified) {
            _markFieldRequired('applicationNumber', true);
          } else {
            _markFieldRequired('applicationNumber', false);
          }
        } else if (model.source === 'BRANCH') {
          _markFieldRequired('applicationNumber', true);
        }
      }

      var issuanceStatusFields = [{
        name: 'Issuance Date',
        code: 'policyIssuanceDate',
        type: 'date',
        hide: true,
        required: true,
        // dateConfig: {
        //   minDate: new Date(),
        //   maxDate: new Date()
        // },
        initFn: _issuanceDateChangeFn,
        changeFn: _issuanceDateChangeFn
      }, {
        name: 'Issuance Status',
        code: 'policyIssuanceStatus',
        placeholder: '',
        changeFn: _issuanceStatusChangeFn,
        initFn: _issuanceStatusChangeFn,
        type: 'select',
        selectConfig: {
          options: [{
            text: 'Payment Completed',
            value: 'PAYMENT_COMPLETE'
          }, {
            text: 'Payment Pending',
            value: 'PAYMENT_PENDING'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Cancellation Reason',
        code: 'cancellationReason',
        placeholder: 'Reasons for Cancellation',
        required: true,
        hide: true,
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Policy Number',
        code: 'policyNumber',
        required: true,
        hide: true,
        type: 'text'
      }, {
        name: 'Application No.',
        code: 'applicationNumber',
        hide: true,
        type: 'text',
        initFn: _applicationNumberInitFn,
        changeFn: _applicationNumberInitFn
      }, {
        name: 'Covernote No/ Proposal No',
        code: isIdfc ? 'proposalNumber' : 'coverNoteNumber',
        type: 'text'
      }, {
        name: 'Covernote Issuance Date',
        code: 'coverNoteIssuanceDate',
        type: 'date'
      }];

      var deviationFields = [{
        name: 'Deviation on Net (%)',
        code: 'deviationNet',
        type: 'number',
        min: 0,
        max: 100,
        initFn: _deviationNetChangeFn,
        changeFn: _deviationNetChangeFn,
        blurFn: _deviationNetBlurFn
      }, {
        name: 'Deviation on OD (%)',
        code: 'deviationOd',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Deviation on TP (%)',
        code: 'deviationTp',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Deviation Flat',
        code: 'deviationFlat',
        type: 'number'
      }, {
        name: 'Deviation Remarks',
        code: 'deviationRemarks',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }];

      function _issuanceDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _issuanceStatusChangeFn(selectedItem, model) {
        if (selectedItem === 'CANCELLED' || selectedItem === 'CANCELLED_REFUND_AWAITED') {
          _showHideField('cancellationReason', false);
        } else {
          _showHideField('cancellationReason', true);
        }

        if (selectedItem === 'ISSUED') {
          _showHideField('policyIssuanceDate', false);
          _showHideField('policyNumber', false);
          _markFieldRequired('city', true);
          _markFieldRequired('state', true);
          _markFieldRequired('pincode', true);
          _markFieldRequired('insurer', true);
          _markFieldRequired('inceptionDate', true);
          if (model.multiyear) {
            _markFieldRequired('policyStartDate', true);
            _markFieldRequired('policyEndDate', true);
            _markFieldRequired('odRiskStartDate', true);
            _markFieldRequired('odRiskEndDate', true);
            _markFieldRequired('tpRiskStartDate', true);
            _markFieldRequired('tpRiskEndDate', true);
          } else {
            _markFieldRequired('policyStartDate', true);
            _markFieldRequired('policyEndDate', true);
            _markFieldRequired('odRiskStartDate', true);
            _markFieldRequired('odRiskEndDate', true);
            _markFieldRequired('tpRiskStartDate', true);
            _markFieldRequired('tpRiskEndDate', true);
          }

          if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
            _showHideField('applicationNumber', false);
          } else {
            _showHideField('applicationNumber', true);
          }

          if (model.vertical === 'FW' || model.vertical === 'TW') {
            _markFieldRequired('rtoLocation', true);
            _markFieldRequired('odPremium', true);
            _markFieldRequired('netOdPremium', true);
            _markFieldRequired('basicTpPremium', true);
            _markFieldRequired('tpPremium', true);
            if (model.source === 'ONLINE') {
              _markFieldRequired('fuel', true);
            } else {
              _markFieldRequired('fuel', false);
            }
            _markFieldRequired('manufactureYear', true);
            if (model.businessType !== 'NEW') {
              _markFieldRequired('registrationNumber', true);
            }
            _validateNetPremium(model);
          } else {
            _markFieldRequired('rtoLocation', false);
            _markFieldRequired('registrationNumber', false);
            _markFieldRequired('odPremium', false);
            _markFieldRequired('netOdPremium', false);
            _markFieldRequired('basicTpPremium', false);
            _markFieldRequired('tpPremium', false);
            _markFieldRequired('fuel', false);
            _markFieldRequired('manufactureYear', false);
          }
        } else {
          _showHideField('policyIssuanceDate', true);
          _showHideField('policyNumber', true);
          _markFieldRequired('city', false);
          _markFieldRequired('state', false);
          _markFieldRequired('pincode', false);
          _markFieldRequired('policyStartDate', false);
          _markFieldRequired('policyEndDate', false);
          _markFieldRequired('odRiskStartDate', false);
          _markFieldRequired('odRiskEndDate', false);
          _markFieldRequired('tpRiskStartDate', false);
          _markFieldRequired('tpRiskEndDate', false);
          _markFieldRequired('inceptionDate', false);
        }
      }

      function _deviationNetChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.deviationOd = '';
          model.deviationTp = '';
          _markFieldDisabled('deviationOd', true);
          _markFieldDisabled('deviationTp', true);
        } else {
          _markFieldDisabled('deviationOd', false);
          _markFieldDisabled('deviationTp', false);
        }
      }

      function _deviationNetBlurFn(selectedItem, model) {
        if (selectedItem) {
          if (['TW', 'CV', 'FW'].includes(model.vertical)) {
            commonService.showToast('Deviation can be entered either on Net or on OD/TP');
          }
        }
      }

      var policyFields = {};

      policyFields.proposerFields = [{
        name: 'Proposer Title',
        code: 'proposerTitle',
        // required: true,
        type: 'select',
        selectConfig: {
          options: [{
            name: 'Mr',
            code: 'mr'
          }, {
            name: 'Ms',
            code: 'ms'
          }, {
            name: 'Mrs',
            code: 'mrs'
          }],
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Proposer First Name',
        code: 'proposerFName',
        required: true,
        type: 'text'
      }, {
        name: 'Proposer Last Name',
        code: 'proposerLName',
        required: true,
        type: 'text'
      }, {
        name: 'Proposer Phone',
        code: 'proposerMobile',
        type: 'text',
        blurFn: _proposerMobileBlurFunc
      }, {
        name: 'Proposer Email',
        code: 'proposerEmail',
        type: 'email'
      }, {
        name: 'Proposer Address',
        code: 'proposerAddress',
        placeholder: 'Address',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Pincode',
        code: 'pincode',
        type: 'text',
        maxLength: 6,
        minLength: 6,
        changeFn: _pincodeChangeFn
      }, {
        name: 'City',
        code: 'city',
        placeholder: 'City Name',
        type: 'text'
      }, {
        name: 'State',
        code: 'state',
        placeholder: 'State Name',
        type: 'text'
      }, {
        name: 'Gender',
        code: 'proposerGender',
        type: 'select',
        selectConfig: {
          options: saleConfig.genderList,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Date of Birth',
        code: 'proposerDob',
        type: 'date'
      }, {
        name: 'GSTIN',
        code: 'gstin',
        type: 'text'
      }, {
        name: 'Reason for no PA cover',
        code: 'reasonForNoPACover',
        type: 'text'
      }, {
        name: 'Buying For',
        code: 'selfProposer',
        type: 'radio',
        disabled: false,
        hide: true,
        radioConfig: {
          options: [{
            value: 'YES',
            text: 'Self'
          }, {
            value: 'NO',
            text: 'Someone else'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }];

      function _proposerMobileBlurFunc(selectedItem, model) {
        if (selectedItem) {
          model.$$proposerMobileMaxError = false;
          if (commonService.checkMobileValidity(selectedItem) && (model.vertical === 'TW' || model.vertical === 'FW' || model.vertical === 'CV')) {
            var domain = GENERAL_CONFIG.ACTIVE_POLICIY_HOST_URL;
            var api = 'api/isMobileNumberAllowed';
            var params = {
              mobileNumber: selectedItem
            };
            commonService.makeThirdPartyApiCall(domain, api, params, 'GET', true).then(function (data) {
              if (data.data.isMobileNumberAllowed === false) {
                commonService.showToast('You have exceeded the maximum permissible limit for active policies on this number. Please enter another number to continue.');
                model.$$proposerMobileMaxError = true;
              }
            }).catch(function () {
              commonService.showToast('Please enter valid number');
            });
          }
        }
      }

      function _pincodeChangeFn(selectedItem, model) {
        if (selectedItem) {
          commonService.setCityState(selectedItem, 'city', 'state', model);
        }
      }

      policyFields.vehicleFields = [{
        name: 'Vehicle Type',
        code: 'vehicleType',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: _vehicleTypeChangeFn,
        initFn: _vehicleTypeChangeFn,
        selectConfig: {
          options: saleConfig.vehicleTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Vehicle Subtype',
        code: 'vehicleSubType',
        placeholder: '',
        type: 'select',
        required: true,
        hide: true,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Is Vehicle financed',
        code: 'isVehicleFinanced',
        type: 'checkbox',
        initFn: _isVehicleFinancedInitFn,
        changeFn: _isVehicleFinancedChangeFn
      }, {
        name: 'Financing Institute',
        code: 'financingInstitute',
        type: 'text',
        hide: true
      }, {
        name: 'Carrier Type',
        code: 'carrierType',
        placeholder: '',
        type: 'select',
        required: true,
        hide: true,
        selectConfig: {
          options: saleConfig.carrierTypes,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'GVW in kgs',
        code: 'grossVehicleWeight',
        placeholder: '',
        type: 'number',
        required: true,
        hide: true,
        min: 1
      }, {
        name: 'Seating Capacity',
        code: 'seatingCapacity',
        placeholder: '',
        type: 'number',
        required: true,
        hide: true,
        min: 1
      }, {
        name: 'RTO Code',
        code: 'rtoCode',
        type: 'text',
        hide: true,
        readonly: true
      }, {
        name: 'RTO Location',
        code: 'rtoLocation',
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: FormHelperService.selectedRTOChangeFn,
          querySearch: FormHelperService.searchRTOFn,
          displayKey: 'rtoPlateLntLoc',
          minLength: 1
        }
      }, {
        name: 'Make Model',
        code: 'makemodel',
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: selectedMakemodelChangeFn,
          querySearch: searchMakeModel,
          displayKey: 'makemodel',
          minLength: 1
        }
      }, {
        name: 'Variant',
        code: 'variant',
        type: 'select',
        changeFn: onSelectVariantFn,
        selectConfig: {
          options: saleConfig.variants,
          valueKey: 'displayVariant',
          textKey: 'displayVariant'
        }
      }, {
        name: 'Cubic Capacity',
        code: 'cc',
        type: 'number'
      }, {
        name: 'Fuel Type',
        code: 'fuel',
        type: 'text'
      }, {
        name: 'Manufacture Year',
        code: 'manufactureYear',
        min: 0,
        max: 3000,
        type: 'number'
      }, {
        name: 'Engine No',
        code: 'engineNo',
        type: 'text'
      }, {
        name: 'Chassis No',
        code: 'chassisNo',
        type: 'text'
      }, {
        name: 'IDV',
        code: 'idv',
        type: 'number',
        min: 0
      }, {
        name: 'Electrical Accessories',
        code: 'electricalValue',
        type: 'number',
        min: 0
      }, {
        name: 'Non Electrical Accessories',
        code: 'nonElecValue',
        type: 'number',
        min: 0
      }, {
        name: 'Bi Fuel Kit Value',
        code: 'biFuelKit',
        type: 'text',
        min: 0
      }, {
        name: 'Vehicle Registration Date',
        code: 'vehicleRegDate',
        type: 'date'
      }, {
        name: 'NCB',
        code: 'ncb',
        type: 'number',
        min: 0,
        max: 100
      }, {
        name: 'Deductible',
        code: 'deductible',
        type: 'number',
        min: 0
      }];

      function searchMakeModel(searchKey, model /*, config*/) {
        var promise = $q.defer();
        FormHelperService.searchMakemodelFn(searchKey, model.vehicleType, model.createdAt).then(function (res) {
          // if (!res || (res && res.length < 1)) {
          //   $timeout(() => {
          //     if (config) {
          //       _clearMakeModel(model);
          //     }
          //   }, 2000);
          // }
          promise.resolve(res);
        }).catch(function (err) {
          _clearMakeModel(model);
          promise.reject(err);
        });
        return promise.promise;
      }

      function _clearMakeModel(model) {
        delete model.make;
        delete model.model;
        delete model.variant;
        delete model.makemodel;
        _showHideField('variant', true);
      }

      function getRegistrationResult(selectedItem, model) {
        var jsonData = {
          vertical: model.vertical,
          registrationNumber: selectedItem
        };
        if (selectedItem && model.source === 'BRANCH') {
          commonService.makeHttpCall('/registration-no', jsonData, 'POST', false, false, true).then(function (res) {
            var data = res.data;
            model.$$selectedVertical = data.vertical;
            model.engineNo = model.engineNo ? model.engineNo : data.engineno;
            model.chassisNo = model.chassisNo ? model.chassisNo : data.chasisno;
            model.proposerAddress = model.proposerAddress ? model.proposerAddress : data.corrAddress;
            model.proposerFName = model.proposerFName ? model.proposerFName : data.ownerFirstName;
            model.proposerLName = model.proposerLName ? model.proposerLName : data.ownerLastName;
            model.prevPolicyNumber = model.prevPolicyNumber ? model.prevPolicyNumber : data.prePolicyNo;
            model.manufactureYear = model.manufactureYear ? model.manufactureYear : data.year;
            model.rtoCode = model.rtoCode ? model.rtoCode : data.rto.rtoCode;
            model.rtoLocation = model.rtoLocation ? model.rtoLocation : data.rto.rtoPlateLntLoc;
            model.registrationAddress = model.registrationAddress ? model.registrationAddress : data.permanentAddress;
            model.make = model.make ? model.make : data.make;
            model.model = model.model ? model.model : data.model;
            model.variant = model.variant ? model.variant : data.displayVariant;
            model.fuel = model.fuel ? model.fuel : data.fuel;
            model.vehicleName = model.vehicleName ? model.vehicleName : data.make + ' ' + data.model + ' ' + data.displayVariant;
            model.vehicleRegDate = model.vehicleRegDate ? model.vehicleRegDate : data.registrationDate;
            model.makemodel = model.makemodel ? model.makemodel : data.make + ' ' + data.model;
            model.cc = model.cc ? model.cc : data.cc;
            if (model.vehicleRegDate) {
              _markFieldDisabled('vehicleRegDate', true);
            }
            if (model.variant) {
              _showHideField('variant', false);
              _getVariants(model);
            }
            $timeout(function () {
              var makemodelConfig = _getConfigObjectFromCode('makemodel');
              makemodelConfig.autocompleteConfig.searchText = model.makemodel;
              var rtoLocationConfig = _getConfigObjectFromCode('rtoLocation');
              rtoLocationConfig.autocompleteConfig.searchText = model.rtoLocation;
            }, 400);
          });
        }
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function _vehicleTypeChangeFn(selectedItem, model) {
        if (selectedItem) {
          _showHideField('makemodel', false);
          for (var idx = saleConfig.vehicleTypes.length - 1; idx >= 0; idx--) {
            var vehicleType = saleConfig.vehicleTypes[idx];
            if (vehicleType.code === selectedItem) {
              var assignedVertical = vehicleType.verticalCode;
              currentIssuance.$$assignedVertical = assignedVertical;
              FormHelperService.setAssignedVertical(assignedVertical, model);
              break;
            }
          }

          if (selectedItem === 'TW') {
            _showHideField('carrierType', true);
            _showHideField('electricalValue', true);
            _showHideField('nonElecValue', true);
            _showHideField('biFuelKit', true);
            _showHideField('vehicleSubType', false);
            _showHideField('grossVehicleWeight', true);
            _showHideField('seatingCapacity', true);
            if (model.policyType === 'od') {
              _showHideField('multiyear', true);
              _planTypeInitFn(model.policyType, model);
            } else {
              _showHideField('multiyear', false);
            }
            _multiyearInitFn(model.multiyear, model);
          } else if (selectedItem === 'CAR') {
            _showHideField('carrierType', true);
            _showHideField('vehicleSubType', false);
            _showHideField('grossVehicleWeight', true);
            _showHideField('seatingCapacity', true);

            _showHideField('electricalValue', false);
            _showHideField('nonElecValue', false);
            _showHideField('biFuelKit', false);
            _showHideField('multiyear', false);
            if (model.policyType === 'od') {
              _showHideField('multiyear', true);
              _planTypeInitFn(model.policyType, model);
            } else {
              _showHideField('multiyear', false);
            }
            _multiyearInitFn(model.multiyear, model);
            model.vehicleSubType = 'CAR';
          } else if (selectedItem === 'MISCD') {
            _showHideField('carrierType', true);
            _showHideField('vehicleSubType', false);
            _showHideField('grossVehicleWeight', true);
            _showHideField('seatingCapacity', true);
            _showHideField('electricalValue', false);
            _showHideField('nonElecValue', false);
            _showHideField('biFuelKit', false);
            _showHideField('multiyear', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
          } else if (selectedItem === 'GCV') {
            _showHideField('carrierType', false);
            _showHideField('vehicleSubType', false);
            _showHideField('grossVehicleWeight', false);
            _showHideField('seatingCapacity', true);
            _showHideField('multiyear', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
            _showHideField('electricalValue', false);
            _showHideField('nonElecValue', false);
            _showHideField('biFuelKit', false);
          } else {
            _showHideField('carrierType', false);
            _showHideField('vehicleSubType', false);
            _showHideField('grossVehicleWeight', true);
            _showHideField('seatingCapacity', false);
            _showHideField('multiyear', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
          }
          _setVehicleSubType(model);
        }
      }

      function _setVehicleType(verticalList, model) {
        var vehicleTypeList = void 0;
        vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(verticalList);
        var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
        vehicleTypeConfig.selectConfig.options = vehicleTypeList;

        var selectConfig = vehicleTypeConfig.selectConfig;
        var foundFlag = false;
        for (var idx = 0; idx < selectConfig.options.length; idx++) {
          var option = selectConfig.valueKey ? selectConfig.options[idx][selectConfig.valueKey] : selectConfig.options[idx];
          if (option === model.vehicleType) {
            foundFlag = true;
          }
        }
        if (!foundFlag) {
          model.vehicleType = 'CAR';
        }
      }

      function _setVehicleSubType(model) {
        var vehicleTypeObj = saleConfig.vehicleTypes && saleConfig.vehicleTypes.find(function (ele) {
          if (ele.code === model.vehicleType) {
            return ele;
          }
        });
        var vehicleSubTypeConfig = _getConfigObjectFromCode('vehicleSubType');
        vehicleSubTypeConfig.selectConfig.options = vehicleTypeObj.vehicleSubType;
      }

      policyFields.commonFields = [{
        name: 'Filed Claim?',
        code: 'filedClaim',
        type: 'checkbox'
      }, {
        name: 'Multi-year?',
        code: 'multiyear',
        type: 'checkbox',
        hide: true,
        initFn: _multiyearInitFn,
        changeFn: _multiyearChangeFn
      }, {
        name: 'Previous Insurer',
        code: 'prevInsurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: FormHelperService.selectedPreviousInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }, {
        name: 'Previous Policy Number',
        code: 'prevPolicyNumber',
        type: 'text'
      }, {
        name: 'Previous Policy Type',
        code: 'prevPolicyType',
        placeholder: '',
        type: 'select',
        required: false,
        selectConfig: {
          options: saleConfig.productNames,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Prev Policy Expiry Date',
        code: 'policyExpDate',
        type: 'date'
      }, {
        name: 'Inception Date',
        code: 'inceptionDate',
        type: 'date'
      }, {
        name: 'Risk Start Date',
        code: 'policyStartDate',
        type: 'date',
        // dateConfig: {
        //   minDate: new Date()
        // },
        initFn: _policyStartDateChangeFn,
        changeFn: _policyStartDateChangeFn
      }, {
        name: 'Risk End Date',
        code: 'policyEndDate',
        type: 'date',
        initFn: _policyEndDateChangeFn,
        changeFn: _policyEndDateChangeFn
      }, {
        name: 'OD Risk Start Date',
        code: 'odRiskStartDate',
        type: 'date',
        hide: true,
        initFn: _odRiskStartDateChangeFn,
        changeFn: _odRiskStartDateChangeFn
      }, {
        name: 'OD Risk End Date',
        code: 'odRiskEndDate',
        type: 'date',
        hide: true,
        // dateConfig: {
        //   minDate: new Date()
        // },
        initFn: _odRiskEndDateChangeFn,
        changeFn: _odRiskEndDateChangeFn
      }, {
        name: 'TP Risk Start Date',
        code: 'tpRiskStartDate',
        type: 'date',
        hide: true,
        initFn: _tpRiskStartDateChangeFn,
        changeFn: _tpRiskStartDateChangeFn
      }, {
        name: 'TP Risk End Date',
        code: 'tpRiskEndDate',
        type: 'date',
        hide: true,
        initFn: _tpRiskEndDateChangeFn,
        changeFn: _tpRiskEndDateChangeFn
      }, {
        name: 'Sum insured',
        code: 'sumInsured',
        type: 'number',
        min: 0,
        hide: true,
        required: true
      }, {
        name: 'Policy Term',
        code: 'policyTerm',
        type: 'number',
        max: 100,
        min: 0,
        hide: true,
        required: true,
        initFn: _policyTermFn,
        blurFn: _policyTermFn
      }, {
        name: 'Income',
        code: 'income',
        type: 'number',
        min: 0
      }, {
        name: 'Marital Status',
        code: 'maritalStatus',
        type: 'text'
      }, {
        name: 'Plan name',
        code: 'tmPlanId',
        placeholder: 'Plan Name',
        initFn: _planNameInitFn,
        hide: true,
        required: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItem: saleConfig.selectedPlan,
          selectedItemChange: selectedPlanNameChangeFn,
          querySearch: searchPlanNames,
          displayKey: 'planName',
          valueKey: 'tmPlanId'
        }
      }, {
        name: 'Plan name (custom)',
        code: 'planName',
        placeholder: 'Plan name',
        type: 'text',
        readonly: false,
        hide: true
      }];

      function _policyStartDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _policyEndDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _odRiskStartDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
        if (model.policyType === 'od') {
          _activeTpRiskEndDateChangeFn(model.activeTPRiskEndDate, model);
        }
      }

      function _odRiskEndDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _tpRiskStartDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _tpRiskEndDateChangeFn(selectedItem, model) {
        dateChangeValidation(model);
      }

      function _isVehicleFinancedInitFn(selectedItem) {
        if (selectedItem) {
          _showHideField('financingInstitute', false);
        } else {
          _showHideField('financingInstitute', true);
        }
      }

      function _isVehicleFinancedChangeFn(selectedItem, model) {
        _isVehicleFinancedInitFn(selectedItem, model);
      }

      function _multiyearInitFn(selectedItem, model) {
        if (selectedItem === undefined || selectedItem === null) {
          model.multiyear = false;
        }
        if (selectedItem) {
          _showHideField('odRiskStartDate', false);
          _showHideField('odRiskEndDate', false);
          _showHideField('tpRiskStartDate', false);
          _showHideField('tpRiskEndDate', false);
          _showHideField('policyStartDate', true, true);
          _showHideField('policyEndDate', true, true);
        } else {
          if (model.policyType === 'od') {
            _showHideField('policyStartDate', true, true);
            _showHideField('policyEndDate', true, true);
            _showHideField('odRiskStartDate', false);
            _showHideField('odRiskEndDate', false);
          } else {
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('odRiskStartDate', true, true);
            _showHideField('odRiskEndDate', true, true);
          }
          _showHideField('tpRiskStartDate', true, true);
          _showHideField('tpRiskEndDate', true, true);
        }
      }

      function _multiyearChangeFn(selectedItem, model) {
        _multiyearInitFn(selectedItem, model);
      }
      function _premiumPaymentFn(selectedItem, model) {
        _checkValidPPT(model);
      }

      function _policyTermFn(selectedItem, model) {
        _checkValidPPT(model);
      }

      function _checkValidPPT(model) {
        if (model.vertical === 'LIFE' && model.policyIssuanceStatus === 'ISSUED' && model.premiumPaymentTerm > model.policyTerm) {
          commonService.showToast('Premium payment term cannot be greater than policy term.');
          model.policyTerm = null;
        }
      }

      policyFields.cvSpecificFields = [{
        name: 'Horse IDV',
        code: 'horseIdv',
        type: 'number',
        min: 0
      }, {
        name: 'CV Vehicle Class',
        code: 'cvVehicleClass',
        type: 'text'
      }, {
        name: 'IMT 23',
        code: 'imt23',
        placeholder: '',
        type: 'checkbox'
      }, {
        name: 'With Trailer',
        code: 'withTrailer',
        placeholder: '',
        type: 'checkbox'
      }, {
        name: 'Trailer IDV',
        code: 'trailerIDV',
        type: 'number',
        min: 0
      }, {
        name: 'Insurer Trailer IDV',
        code: 'insTrailerIDV',
        type: 'number',
        min: 0
      }, {
        name: 'CV Vehicle Type',
        code: 'cvVehicleType',
        type: 'text'
      }, {
        name: 'CV Wheels',
        code: 'cvWheels',
        type: 'number',
        min: 0
      }, {
        name: 'CV Detarrif %',
        code: 'cvDetariff',
        type: 'number',
        min: 0,
        max: 100
      }];

      policyFields.healthFields = [{
        name: 'Medical History',
        code: 'medicalHistory',
        placeholder: '',
        type: 'radio',
        radioConfig: {
          options: [{
            value: 'YES',
            text: 'Yes'
          }, {
            value: 'NO',
            text: 'No'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Deductible',
        code: 'deductible',
        type: 'number',
        min: 0
      }];

      policyFields.lifeFields = [{
        name: 'Sub-plan type',
        code: 'subPlanType',
        placeholder: 'Sub-plan type',
        type: 'select',
        selectConfig: {
          options: saleConfig.subPlanTypes,
          valueKey: 'code',
          textKey: 'text'
        }
      }, {
        name: 'Option name',
        code: 'subPlanName',
        placeholder: 'Sub-plan Name',
        type: 'text'
      }, {
        name: 'Premium Payment Frequency',
        code: 'premiumPaymentFrequency',
        type: 'select',
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        },
        required: true,
        initFn: _paymentFrequencyChangeFn,
        changeFn: _paymentFrequencyChangeFn
      }, {
        name: 'Premium Payment Term',
        code: 'premiumPaymentTerm',
        type: 'text',
        required: true,
        initFn: _premiumPaymentFn,
        changeFn: _premiumPaymentFn
      }, {
        name: 'Payment Type',
        code: 'paymentType',
        type: 'select',
        selectConfig: {
          options: saleConfig.paymentTypes,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true
      }, {
        name: 'Benefit Payout Term',
        code: 'payoutTerm',
        type: 'text',
        required: false
      }, {
        name: 'Benefit Payout Mode',
        code: 'payoutMode',
        type: 'select',
        selectConfig: {
          options: saleConfig.benefitPayoutTypes,
          valueKey: 'value',
          textKey: 'text'
        },
        required: false
      }, {
        name: 'Smoker',
        code: 'smoker',
        // type: 'checkbox'
        type: 'radio',
        radioConfig: {
          options: [{
            value: 'YES',
            text: 'Yes'
          }, {
            value: 'NO',
            text: 'No'
          }],
          valueKey: 'value',
          textKey: 'text'
        }
      }];

      function _paymentFrequencyChangeFn(selectedItem, model) {
        if (selectedItem) {
          if (selectedItem === 'SINGLE') {
            model.premiumPaymentTerm = 1;
            _markFieldDisabled('premiumPaymentTerm', true);
          } else {
            _markFieldDisabled('premiumPaymentTerm', false);
          }
        }
      }

      policyFields.partnerFields = [{
        name: 'Partner Name',
        code: 'partnerName',
        placeholder: 'Select a partner',
        type: 'autocomplete',
        hide: false,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItem: saleConfig.selectedIntermediary,
          selectedItemChange: _selectedIntermediaryChangeFn,
          querySearch: searchIntermediariesFn,
          displayKey: 'dpName',
          valueKey: 'dpName',
          resultTitleKey: 'label',
          resultLabelKey: 'label',
          minLength: 2
        }
      }, {
        name: 'Partner Email',
        code: 'partnerEmail',
        type: 'text',
        readonly: true
      }, {
        name: 'Partner Mobile',
        code: 'partnerMobile',
        type: 'text',
        readonly: true
      }, {
        name: 'DP Number',
        code: 'partnerDpNo',
        type: 'text',
        required: true,
        readonly: true
      }, {
        name: 'Posp Type',
        code: 'pospType',
        type: 'text',
        readonly: true
      }, {
        name: 'Partner Id',
        code: 'partnerId',
        type: 'text',
        readonly: true
      }, {
        name: 'L1 Relationship Manager',
        code: 'L1RelationshipManager',
        type: 'text',
        readonly: true,
        hide: true
      }, {
        name: 'Relationship Manager',
        code: 'relationshipManager',
        type: 'text',
        readonly: true
      }, {
        name: 'Area Manager',
        code: 'areaManager',
        type: 'text',
        readonly: true
      }, {
        name: 'PI Branch Location',
        code: 'piBranchLocation',
        type: 'select',
        selectConfig: {
          options: saleConfig.cities,
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      policyFields.mandatoryFields = [{
        name: 'Owner',
        code: 'owner',
        type: 'text',
        readonly: true,
        hide: true
      }];

      policyFields.insurerPremiumFields = [{
        name: 'Basic OD Premium',
        code: 'odPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Total OD Premium',
        code: 'netOdPremium',
        type: 'number',
        initFn: _netOdPremiumInitFn,
        blurFn: _netOdPremiumBlurFn,
        min: 0
      }, {
        name: 'Basic TP Premium',
        code: 'basicTpPremium',
        type: 'number',
        min: 0
      }, {
        name: 'Total TP Premium',
        code: 'tpPremium',
        type: 'number',
        initFn: _tpPremiumInitFn,
        blurFn: _tpPremiumBlurFn,
        min: 0
      }, {
        name: 'Net Premium',
        code: 'netPremium',
        type: 'number',
        required: true,
        initFn: _netPremiumInitFn,
        blurFn: _netPremiumBlurFn,
        min: 0
      }, {
        name: 'Annualized Net Premium',
        code: 'annualisedNetPremium',
        type: 'number',
        min: 0,
        hide: true
      }, {
        name: 'Service Tax / GST',
        code: 'serviceTax',
        type: 'number',
        min: 0
      }, {
        name: 'Gross Premium',
        code: 'grossPremium',
        type: 'number',
        changeFn: _grossPremiumChangeFn,
        initFn: _grossPremiumChangeFn,
        required: true,
        min: 0
      }, {
        name: 'De Tariff',
        code: 'deTariff',
        type: 'number',
        hide: true,
        min: 0
      }];

      policyFields.odPolicyFields = [{
        name: 'Previous OD Policy Number',
        code: 'previousODPolicyNumber',
        type: 'text',
        hide: true
      }, {
        name: 'Previous OD Risk Start Date',
        code: 'previousODRiskStartDate',
        type: 'date',
        hide: true
      }, {
        name: 'Previous OD Risk End Date',
        code: 'previousODRiskEndDate',
        type: 'date',
        hide: true
      }, {
        name: 'Previous OD Insurer',
        code: 'previousODInsurer',
        hide: true,
        type: 'autocomplete',
        initFn: _selectedPreviousODInsurerInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedPreviousODInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }, {
        name: 'Active TP Policy Number',
        code: 'activeTPPolicyNumber',
        type: 'text',
        hide: true
      }, {
        name: 'Active TP Risk Start Date',
        code: 'activeTPRiskStartDate',
        type: 'date',
        hide: true
      }, {
        name: 'Active TP Risk End Date',
        code: 'activeTPRiskEndDate',
        type: 'date',
        hide: true,
        initFn: _activeTpRiskEndDateChangeFn,
        changeFn: _activeTpRiskEndDateChangeFn
      }, {
        name: 'Active TP Insurer',
        code: 'activeTPInsurer',
        hide: true,
        type: 'autocomplete',
        initFn: _selectedActiveTPInsurerInitFn,
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedActiveTPInsurerChangeFn,
          querySearch: searchInsurerFn,
          displayKey: 'label',
          valueKey: 'value'
        }
      }];

      function _netOdPremiumInitFn(selectedItem, model) {
        _validateNetPremium(model);
      }
      function _netOdPremiumBlurFn(selectedItem, model) {
        _validateNetPremium(model);
      }

      function _tpPremiumInitFn(selectedItem, model) {
        _validateNetPremium(model);
      }
      function _tpPremiumBlurFn(selectedItem, model) {
        _validateNetPremium(model);
      }

      function _netPremiumInitFn(selectedItem, model) {
        _validateNetPremium(model);
      }

      function _netPremiumBlurFn(selectedItem, model) {
        _validateNetPremium(model);
      }

      function _activeTpRiskEndDateChangeFn(selectedItem, model) {
        if (model.odRiskStartDate && moment(model.odRiskStartDate).isAfter(model.activeTPRiskEndDate, 'day')) {
          model.activeTPRiskEndDate = undefined;
          commonService.showToast('Active TP Risk End Date cannot be before OD Risk Start Date');
        }
      }

      function _validateNetPremium(model) {
        if (model.source === 'BRANCH' && model.netPremium >= 0 && (model.vertical === 'TW' || model.vertical === 'FW') && model.policyIssuanceStatus === 'ISSUED' && model.policyType) {
          var totalNetPremium = void 0,
              error = void 0;

          if (model.policyType === 'comprehensive') {
            totalNetPremium = model.netOdPremium + model.tpPremium;
            error = 'Sum of Total OD Premium and Total TP Premium is not equal to Net Premium';
          } else if (model.policyType === 'tp') {
            totalNetPremium = model.tpPremium;
            error = 'Total TP Premium is not equal to Net Premium';
          } else if (model.policyType === 'od') {
            totalNetPremium = model.netOdPremium;
            error = 'Total OD Premium is not equal to Net Premium';
          }

          if (!(totalNetPremium - 5 <= model.netPremium && model.netPremium <= totalNetPremium + 5)) {
            model.netPremium = undefined;
            commonService.showToast(error);
          }
        }
      }
      function _grossPremiumChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.$$grossPremium = selectedItem;
        }
        if (model.category === 'investment' || model.category === 'child' || model.category === 'pension' || model.category === 'retirement') {
          if (model.policyType === 'TRADITIONAL' || model.policyType === 'ULIP') {
            _markFieldRequired('grossPremium', false);
          }
        }
      }

      return {
        // Vars
        sourceOfSaleFields: sourceOfSaleFields,
        opsBranchFields: opsBranchFields,
        generalFields: generalFields,
        inspectionConfig: inspectionConfig,
        inspectionStatusConfig: inspectionStatusConfig,
        paymentFields: paymentFields,
        paymentStatusFormConfig: paymentStatusFormConfig,
        issuanceStatusFields: issuanceStatusFields,
        policyFields: policyFields,
        // preSaleVerification,
        issuanceDefaultFilter: issuanceDefaultFilter,
        memberConfig: memberConfig,
        deviationFields: deviationFields,
        saleConfig: saleConfig,

        // Api Fns
        getList: getList,
        getListCount: getListCount,
        getBranchIssuance: getBranchIssuance,
        handleUploadedDocuments: handleUploadedDocuments,
        changeExistingTag: changeExistingTag,
        findRequestId: findRequestId,
        getDefaultBranchIssuance: getDefaultBranchIssuance,
        getAddonsList: getAddonsList,
        getById: getById,
        saveInspection: saveInspection,
        getStatusList: getStatusList,
        getAllFilters: getAllFilters,
        update: update,
        getInspectionSubStatusTrack: getInspectionSubStatusTrack,
        sendEmailToClient: sendEmailToClient,
        syncFileMIS: syncFileMIS,
        getRedirectUrl: getRedirectUrl,
        cancelAutomate: cancelAutomate,
        savePolicyIssuanceOwner: savePolicyIssuanceOwner,
        makeActionable: makeActionable,
        getBQPList: getBQPList,
        saveBQPDetails: saveBQPDetails,
        clearAutoconfig: clearAutoconfig,
        getTenants: getTenants
      };

      // //////////////////////////////////////////////////////


      /**
       * Get Sale by MISConstaint
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     sumantaparida
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function issuanceTypePaymentMode(res) {
        var paymentModeConfig = _getConfigObjectFromCode('paymentMode');
        var paymentModeOptions = paymentModeConfig.radioConfig.options;
        paymentModeOptions.splice(0, paymentModeOptions.length);
        for (var i = 0; i < MIS_CONSTANTS.paymentModeRadioConf.length; i++) {
          var item = MIS_CONSTANTS.paymentModeRadioConf[i];
          paymentModeOptions.push(item);
        }
        if (res.source === 'BRANCH') {
          var optionData = {
            text: 'Cash paid at insurer',
            value: 'CASH_PAID_AT_INSURER'
          };
          paymentModeOptions.push(optionData);
        }
      }

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     sumantaparida
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getTenants() {
        var returnPromise = $q.defer();
        PartnerService.getTenants().then(function (res) {
          returnPromise.resolve(res.data.data);
        });
        return returnPromise.promise;
      }

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getStatusList(LIST_TYPE, source) {
        var returnPromise = $q.defer();
        var source = source ? source : '';
        var reqUrl = '/inspectionStatus';
        if (LIST_TYPE === 'inspection') {
          reqUrl = '/inspectionStatus';
        } else if (LIST_TYPE === 'payment') {
          reqUrl = '/paymentStatus';
        } else if (LIST_TYPE === 'paymentMode') {
          reqUrl = '/paymentModes';
        } else if (LIST_TYPE === 'adminOwner' + source) {
          reqUrl = '/owners/issuance?source=' + source;
        }
        // else if (LIST_TYPE === 'preSaleVerificationStatus') {
        //   reqUrl = '/presaleVerificationStatus';
        // }
        else if (LIST_TYPE === 'issuance') {
            reqUrl = '/policyIssuanceStatus';
          } else if (LIST_TYPE === 'issuanceType') {
            reqUrl = '/policyIssuanceType';
          } else if (LIST_TYPE === 'applicationstatus') {
            reqUrl = '/applicationstatus';
          } else if (LIST_TYPE === 'inspectionSubStatus') {
            reqUrl = '/inspectionSubStatus';
          }

        if (BEStatusLists[LIST_TYPE]) {
          returnPromise.resolve(BEStatusLists[LIST_TYPE]);
          return returnPromise.promise;
        }

        commonService.makeHttpCall(reqUrl, {}, 'GET', false).then(function (res) {
          BEStatusLists[LIST_TYPE] = res.data;
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getAllFilters() {
        var returnPromise = $q.defer();
        var reqUrl = '/issuance/filters';
        commonService.makeHttpCall(reqUrl, {}, 'GET', false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });
        return returnPromise.promise;
      }

      function getAddonsList(vertical) {
        var returnPromise = $q.defer();
        var reqUrl = '/addons';
        var reqObj = {
          vertical: vertical.toUpperCase()
        };

        commonService.makeThirdPartyApiCall(TM_URL, reqUrl, reqObj, 'GET', false, false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getList(options, source) {
        var returnPromise = $q.defer();
        var reqUrl = '/policyissuance';

        if (!source) {
          source = 'ONLINE';
        }

        options.source = source;

        if (!options) {
          options = {
            searchKey: 'some',
            skip: 0,
            limit: 10,
            filter: {
              vertical: ['car'],
              status: ['PENDING'],
              type: []
            }
          };
        }

        commonService.makeHttpCall(reqUrl, options, 'POST', false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      /**
       * Function called on change of Plan type
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           planType          planType
       *
       * @author sanketapte
       *
       */
      function _filterSubPlanType(planType) {
        saleConfig.subPlanTypes.splice(0, saleConfig.subPlanTypes.length);
        for (var idx = MIS_CONSTANTS.subPlanTypes.length - 1; idx >= 0; idx--) {
          var subPlanType = MIS_CONSTANTS.subPlanTypes[idx];
          if (!planType) {
            saleConfig.subPlanTypes.push(subPlanType);
          } else if (subPlanType.planTypes.includes(planType)) {
            saleConfig.subPlanTypes.push(subPlanType);
          }
        }

        if (saleConfig.subPlanTypes.length === 1) {
          var subPlanTypeConfig = _getConfigObjectFromCode('subPlanType');
          currentIssuance[subPlanTypeConfig.code] = saleConfig.subPlanTypes[0][subPlanTypeConfig.selectConfig.valueKey];
          subPlanTypeConfig.initFn(currentIssuance[subPlanTypeConfig.code]);
        }
      }

      function _planTypeChangeFn(selectedItem, model) {
        if (selectedItem) {
          delete model.vehicleSubType;
        }
        _planTypeInitFn(selectedItem, model);
      }

      /**
       * Function called on change of Plan type
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale.                 Sale
       *
       * @author sanketapte
       *
       */
      function _planTypeInitFn(selectedItem, model) {
        getProductUINMasters(model);
        _filterSubPlanType(selectedItem);
        var showOdPolicyFields = false;
        var odPolicyFields = ['previousODPolicyNumber', 'previousODRiskStartDate', 'previousODRiskEndDate', 'previousODInsurer', 'activeTPPolicyNumber', 'activeTPRiskStartDate', 'activeTPRiskEndDate', 'activeTPInsurer'];
        if (model.vertical === 'LIFE') {
          _grossPremiumChangeFn(model.grossPremium, model);
        } else if (['TW', 'FW'].includes(model.vertical)) {
          if (['tp', 'comprehensive'].includes(selectedItem) && model.vertical !== 'TW') {
            var vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(['FW', 'CV']);
            var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
            vehicleTypeConfig.selectConfig.options = vehicleTypeList;
          }
          if (['tp'].includes(selectedItem)) {
            showOdPolicyFields = false;
            _showHideField('tpPremium', false);
            _showHideField('basicTpPremium', false);
            _showHideField('odPremium', true);
            _showHideField('netOdPremium', true);
            _showHideField('multiyear', false);
            if (model.multiyear) {
              _showHideField('policyStartDate', true);
              _showHideField('policyEndDate', true);
              _showHideField('odRiskStartDate', false);
              _showHideField('odRiskEndDate', false);
            } else {
              _showHideField('policyStartDate', false);
              _showHideField('policyEndDate', false);
              _showHideField('odRiskStartDate', true, true);
              _showHideField('odRiskEndDate', true, true);
            }
            if (model.vertical !== 'TW') {
              _setVehicleType(['FW', 'CV'], model);
              _setVehicleSubType(model);
            }
          } else if (['od'].includes(selectedItem)) {
            showOdPolicyFields = true;
            _showHideField('policyStartDate', true);
            _showHideField('policyEndDate', true);
            _showHideField('odRiskStartDate', false);
            _showHideField('odRiskEndDate', false);
            _showHideField('odPremium', false);
            _showHideField('netOdPremium', false);
            _showHideField('tpPremium', true);
            _showHideField('basicTpPremium', true);
            _showHideField('multiyear', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);

            if (model.vertical === 'FW') {
              FormHelperService.setAssignedVertical('FW', model);
              _setVehicleType(['FW', 'CV'], model);
              _setVehicleSubType(model);
              _showHideField('carrierType', true);
              _showHideField('seatingCapacity', true);
              _showHideField('grossVehicleWeight', true);
            }
          } else {
            showOdPolicyFields = false;
            _showHideField('odPremium', false);
            _showHideField('netOdPremium', false);
            _showHideField('tpPremium', false);
            _showHideField('basicTpPremium', false);
            _showHideField('multiyear', false);
            if (model.multiyear) {
              _showHideField('policyStartDate', true);
              _showHideField('policyEndDate', true);
              _showHideField('odRiskStartDate', false);
              _showHideField('odRiskEndDate', false);
              _showHideField('tpPremium', false);
              _showHideField('basicTpPremium', false);
            } else {
              _showHideField('policyStartDate', false);
              _showHideField('policyEndDate', false);
              _showHideField('odRiskStartDate', true, true);
              _showHideField('odRiskEndDate', true, true);
            }
            if (model.vertical === 'FW') {
              _setVehicleType(['FW', 'CV'], model);
              _setVehicleSubType(model);
            }
          }
          odPolicyFields.forEach(function (ele) {
            _showHideField(ele, !showOdPolicyFields);
          });

          if (model.vertical === 'FW' && model.vehicleType === 'CAR') {
            model.vehicleSubType = 'CAR';
          }
        }
      }

      /**
       * Function called on change of Plan name
       *
       * @private
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       * @param    {!Object}           sale.                 Sale
       *
       * @author sanketapte
       *
       */

      function getProductUINMasters(currentIssuance) {
        if (currentIssuance.$$vertical === 'LIFE' || currentIssuance.$$vertical === 'TERM' || currentIssuance.$$vertical === 'HEALTH') {
          var returnPromise = $q.defer();
          var reqUrl = '/getplannames';
          var policyTypeText = currentIssuance.policyType;
          if (policyTypeText) {
            policyTypeText = policyTypeText.toLowerCase();
          }
          var reqObj = {
            insurer: currentIssuance.insurer,
            productCategory: currentIssuance.vertical
          };
          if (currentIssuance.$$vertical === 'LIFE') {
            reqObj.plantype = policyTypeText;
          }
          commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, true).then(function (res) {
            returnPromise.resolve(res.data);
            // saleConfig.turtlemintTenant = res.data;
            saleConfig.planNames.splice(0, saleConfig.planNames.length);
            for (var idx = res.data.length - 1; idx >= 0; idx--) {
              saleConfig.planNames.push(res.data[idx]);
            }
          }).catch(function (err) {
            returnPromise.reject(err);
          });
        }
        //return returnPromise.promise;
      }

      function _planNameInitFn(selectedItem, model) {
        $timeout(function () {
          var tmPlanIdConfig = _getConfigObjectFromCode('tmPlanId');
          tmPlanIdConfig.autocompleteConfig.searchText = model.planName;
        }, 400);
        if (!selectedItem || selectedItem === 'others') {
          _showHideField('planName', false);
        } else {
          _showHideField('planName', true, true);
        }
      }

      /**
       * Get Sale by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getListCount(options) {
        var returnPromise = $q.defer();
        var reqUrl = '/policyIssuanceCount';

        commonService.makeHttpCall(reqUrl, options, 'POST', false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      /**
       * Get Branch Issuance by Id
       *
       * @public
       *
       * @memberof   module:turtlemintAdmin.PolicyIssuance#
       *
       * @author     snehilmodani
       *
       * @param      {Object}  employee  Employee to be added (from the controller)
       * @param      {String}  storeId   Store ID (from the controller)
       * @return     {Object}  Promise, which resolves to boolean
       */
      function getBranchIssuance(options) {
        return getList(options, 'BRANCH');
      }

      /**
       * Function for Change Existing Tag
       *
       * @public
       *
       * @function
       *
       * @param    {Object}         tag      Change Selected tag
       *
       * @author snehilmodani
       *
       */
      function changeExistingTag(tag, fileItem) {
        var returnPromise = $q.defer();
        var reqObj = angular.copy(fileItem);
        var fileId = fileItem.processInfo.pid;
        reqObj.tag = tag.code;
        MasterFileService.updateFile(fileId, reqObj).then(function () {
          fileItem.tag = tag.code;
          fileItem.tagName = tag.name;
          returnPromise.resolve(fileItem);
        }).catch(function (err) {
          returnPromise.reject(err);
        });

        return returnPromise.promise;
      }

      /**
       * Function to handle uploaded documents
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           attachedDocuments       Attched Documents
       *
       * @author snehilmodani
       *
       */
      function handleUploadedDocuments(attachedDocuments, policyIssuance) {
        var attachmentGroupedDocs = {};

        if (attachedDocuments) {
          policyIssuance.$$attachedDocuments = attachedDocuments;
        }

        if (!policyIssuance.$$attachedDocuments) {
          policyIssuance.$$attachedDocuments = [];
        }
        for (var idx = 0; idx < policyIssuance.$$attachedDocuments.length; idx++) {
          var doc = policyIssuance.$$attachedDocuments[idx];
          var foundTag = UtilService.findTag(doc.tag) || {
            code: 'OTHER',
            name: 'Other'
          };
          doc.tagName = foundTag.name;

          if (!attachmentGroupedDocs[foundTag.code]) {
            attachmentGroupedDocs[foundTag.code] = [];
          }

          attachmentGroupedDocs[foundTag.code].push(doc);
        }

        policyIssuance.$$attachedGroupedDocuments = attachmentGroupedDocs;

        return policyIssuance;
      }

      function sendEmailToClient(policyIssuanceId, message) {
        var returnPromise = $q.defer();
        var reqType = 'POST';
        var reqUrl = '/policyIssuanceEmail';

        var reqObj = {
          policyIssuanceId: policyIssuanceId,
          message: message
        };

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function checkInspectionError(currentIssuance) {
        delete currentIssuance.$$inspectionErrorMessage;
        delete currentIssuance.$$latestInspectionIdx;

        if (currentIssuance.$$vertical === 'fw' || currentIssuance.$$vertical === 'cv') {
          if (currentIssuance.inspectionRequired === 'REQUIRED' && currentIssuance.inspectionStatus !== 'PENDING') {
            if (currentIssuance.inspectionDetails && currentIssuance.inspectionDetails.length > 0) {
              var latestInspectionIdx = currentIssuance.inspectionDetails.length - 1;
              var latestInspection = currentIssuance.inspectionDetails[latestInspectionIdx];
              if (!checkInspectionFilled(latestInspection)) {
                currentIssuance.$$inspectionErrorMessage = 'Please fill required details in the latest inspection.';
                currentIssuance.$$latestInspectionIdx = latestInspectionIdx;
              } else {
                $window.console.log('inspection details all filled');
              }
            }
          }
        }
      }

      function saveInspection(issuanceId, inspectionObj) {
        var returnPromise = $q.defer();
        var reqType = 'POST';
        var reqUrl = '/inspection';
        if (inspectionObj.id) {
          reqType = 'PUT';
          reqUrl = '/inspection/' + inspectionObj.id;
        }

        var reqObj = angular.copy(inspectionObj);

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function (res) {
          angular.extend(inspectionObj, res.data);
          returnPromise.resolve(inspectionObj);
          $timeout(function () {
            checkInspectionError(currentIssuance);
          }, 1000);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getInspectionSubStatusTrack(policyIssuanceId) {
        var returnPromise = $q.defer();
        var reqType = 'GET';
        var reqUrl = '/getSubStatusTrack/' + policyIssuanceId;
        commonService.makeHttpCall(reqUrl, {}, reqType, false).then(function (res) {
          returnPromise.resolve(res.data);
        }).catch(function (err) {
          returnPromise.reject(err);
        });
        return returnPromise.promise;
      }

      function savePolicyIssuanceOwner(issuanceObj) {
        var returnPromise = $q.defer();
        var reqType = 'PUT';
        var reqUrl = '/savePolicyIssuanceOwner';
        if (issuanceObj.policyIssuanceId) {
          reqType = 'PUT';
          reqUrl = '/savePolicyIssuanceOwner/' + issuanceObj.policyIssuanceId;
        }
        var reqObj = angular.copy(issuanceObj);

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function (res) {
          angular.extend(issuanceObj, res.data);
          returnPromise.resolve(issuanceObj);
          $timeout(function () {
            checkInspectionError(currentIssuance);
          }, 1000);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function update(issuanceObj, postParams) {
        var returnPromise = $q.defer();
        var reqType = 'PUT';
        var reqUrl = '/policyIssuance/' + issuanceObj.policyIssuanceId;

        if (!issuanceObj.policyIssuanceId) {
          reqType = 'POST';
          reqUrl = '/policyIssuance';
        }

        var reqObj = angular.copy(issuanceObj);

        if (reqObj.inspectionRequired === 'REQUIRED') {
          reqObj.inspectionRequired = true;
        } else if (reqObj.inspectionRequired === 'NOT_REQUIRED') {
          reqObj.inspectionRequired = false;
        }

        if (reqObj.medicalHistory === 'YES') {
          reqObj.medicalHistory = true;
        } else if (reqObj.medicalHistory === 'NO') {
          reqObj.medicalHistory = false;
        }

        if (reqObj.smoker === 'YES') {
          reqObj.smoker = true;
        } else if (reqObj.smoker === 'NO') {
          reqObj.smoker = false;
        }

        if (reqObj.proposerDob) {
          reqObj.proposerDob = parseInt(moment(reqObj.proposerDob).format('x'));
        }

        if (reqObj.source) {
          reqObj.source = reqObj.source.toUpperCase();
        }

        if (reqObj.selfProposer) {
          reqObj.selfProposer = reqObj.selfProposer === 'YES' ? true : false;
        }

        if (reqObj.vertical === 'HEALTH' && reqObj.members) {
          reqObj.noOfInsuredMembers = reqObj.members.length;
        }

        if (reqObj.vertical === 'LIFE') {
          reqObj.insurerRemarks = _formatInsurerRemakrks(reqObj.insurerRemarks, false);
        }

        if (reqObj.members && reqObj.members.length > 0) {
          reqObj.members.forEach(function (member) {
            if (member.hasHistoryOfConviction) {
              member.hasHistoryOfConviction = member.hasHistoryOfConviction === 'YES' ? true : false;
            }
            if (member.isNri) {
              member.isNri = member.isNri === 'YES' ? true : false;
            }
            if (member.isHandicapped) {
              member.isHandicapped = member.isHandicapped === 'YES' ? true : false;
            }
            if (member.isPoliticallyExposed) {
              member.isPoliticallyExposed = member.isPoliticallyExposed === 'YES' ? true : false;
            }
            if (member.residentOfJammuAndKashmir) {
              member.residentOfJammuAndKashmir = member.residentOfJammuAndKashmir === 'YES' ? true : false;
            }
            if (member.isAadhaarCardAvailable) {
              member.isAadhaarCardAvailable = member.isAadhaarCardAvailable === 'YES' ? true : false;
            }
          });
        }

        if (reqObj.vertical === 'TW' || reqObj.vertical === 'FW' && reqObj.vehicleType === 'CAR') {
          if (reqObj.multiyear) {
            reqObj.policyStartDate = reqObj.odRiskStartDate;
            reqObj.policyEndDate = reqObj.odRiskEndDate;
          } else {
            if (reqObj.policyType && reqObj.policyType === 'od') {
              reqObj.policyStartDate = reqObj.odRiskStartDate;
              reqObj.policyEndDate = reqObj.odRiskEndDate;
              delete reqObj.tpRiskStartDate;
              delete reqObj.tpRiskEndDate;
            } else {
              reqObj.odRiskStartDate = reqObj.policyStartDate;
              reqObj.odRiskEndDate = reqObj.policyEndDate;
              reqObj.tpRiskStartDate = reqObj.policyStartDate;
              reqObj.tpRiskEndDate = reqObj.policyEndDate;
            }
          }
        } else {
          delete reqObj.multiyear;
          delete reqObj.odRiskStartDate;
          delete reqObj.odRiskEndDate;
          delete reqObj.tpRiskStartDate;
          delete reqObj.tpRiskEndDate;
        }

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false, false, false, postParams).then(function (res) {
          currentIssuance = res.data;
          currentIssuance.$$vertical = currentIssuance.vertical;
          returnPromise.resolve(currentIssuance);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function checkForValuesIssuance(currentIssuance) {
        if (!currentIssuance.inspectionMode) {
          currentIssuance.inspectionMode = 'manual';
        }
        if (currentIssuance.inspectionRequired === true) {
          currentIssuance.inspectionRequired = 'REQUIRED';
        } else if (currentIssuance.inspectionRequired === false) {
          currentIssuance.inspectionRequired = 'NOT_REQUIRED';
        }

        if (currentIssuance.medicalHistory !== null) {
          if (currentIssuance.medicalHistory === true) {
            currentIssuance.medicalHistory = 'YES';
          } else if (currentIssuance.medicalHistory === false) {
            currentIssuance.medicalHistory = 'NO';
          }
        }

        if (currentIssuance.smoker !== null) {
          if (currentIssuance.smoker) {
            currentIssuance.smoker = 'YES';
          } else {
            currentIssuance.smoker = 'NO';
          }
        }

        if (currentIssuance.hasOwnProperty('selfProposer') && currentIssuance.selfProposer !== undefined && currentIssuance.selfProposer !== null) {
          if (currentIssuance.selfProposer) {
            currentIssuance.selfProposer = 'YES';
          } else {
            currentIssuance.selfProposer = 'NO';
          }
        }

        if (currentIssuance.members === null) {
          currentIssuance.members = [];
        }
        if (!currentIssuance.nominees || currentIssuance.nominees === null) {
          currentIssuance.nominees = [];
        }

        if (currentIssuance.members && currentIssuance.members.length > 0) {
          currentIssuance.members.forEach(function (member) {
            if (member.hasHistoryOfConviction !== null && member.hasHistoryOfConviction !== undefined) {
              member.hasHistoryOfConviction = member.hasHistoryOfConviction === true ? 'YES' : 'NO';
            }
            if (member.isNri !== null && member.isNri !== undefined) {
              member.isNri = member.isNri === true ? 'YES' : 'NO';
            }
            if (member.isHandicapped !== null && member.isHandicapped !== undefined) {
              member.isHandicapped = member.isHandicapped === true ? 'YES' : 'NO';
            }
            if (member.isPoliticallyExposed !== null && member.isPoliticallyExposed !== undefined) {
              member.isPoliticallyExposed = member.isPoliticallyExposed === true ? 'YES' : 'NO';
            }
            if (member.residentOfJammuAndKashmir !== null && member.residentOfJammuAndKashmir !== undefined) {
              member.residentOfJammuAndKashmir = member.residentOfJammuAndKashmir === true ? 'YES' : 'NO';
            }
            if (member.isAadhaarCardAvailable !== null && member.isAadhaarCardAvailable !== undefined) {
              member.isAadhaarCardAvailable = member.isAadhaarCardAvailable === true ? 'YES' : 'NO';
            }
          });
        }

        if (currentIssuance.policyDetailsId) {
          _showHideField('policyDetailsId', false);
          currentIssuance.typeOfSale = currentIssuance.sourceOfSale && currentIssuance.sourceOfSale.type || null;
          if (currentIssuance.sourceOfSale) {
            _setBQPDetails(currentIssuance);
          }
          _typeOfSaleInitFn(currentIssuance.typeOfSale, currentIssuance);
        } else {
          _showHideField('policyDetailsId', true);
        }

        if (currentIssuance.proposerDob) {
          currentIssuance.proposerDob = moment(currentIssuance.proposerDob).toISOString();
        }

        if (currentIssuance.saleCloseDate) {
          currentIssuance.saleCloseDate = moment(currentIssuance.saleCloseDate).toISOString();
        }

        if (currentIssuance.inspectionReasons && currentIssuance.inspectionReasons.length > 0) {
          currentIssuance.$$inspectionReasons = '';
          for (var idx = 0; idx < currentIssuance.inspectionReasons.length; idx++) {
            var reason = currentIssuance.inspectionReasons[idx];
            currentIssuance.$$inspectionReasons = currentIssuance.$$inspectionReasons + reason + '\n';
          }
        }

        activateForm(currentIssuance.$$vertical, currentIssuance.source);

        if (currentIssuance.partnerId && currentIssuance.tenant) {
          var saleCloseDate = moment(currentIssuance.saleCloseDate).format('x');
          var reqUrl = '/getpartnersinfo';
          var reqObj = {
            query: currentIssuance.partnerName,
            tenant: currentIssuance.tenant,
            channel: 'partner',
            saleDate: saleCloseDate
          };
          var promise = commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, false);
          promise.then(function (res) {
            if (res.data && res.data.status === 'SUCCESS') {
              var partnerList = res.data.data || [];
              if (partnerList && partnerList.length > 0) {
                var partnerObj = partnerList.find(function (ele) {
                  return ele.dpLoginId === currentIssuance.partnerId;
                });
                if (partnerObj) {
                  var formData = {
                    partnerId: partnerObj.dpLoginId,
                    vertical: currentIssuance.vertical
                  };
                  FormHelperService.isACertifedPartner(formData).then(function (res) {
                    var isCertified = res.data.data.allowedToDoBusiness;
                    if (isCertified === true) {
                      currentIssuance.pospType = partnerObj.dpType;
                      if (partnerObj.intermediaryLevel === 'partner_level_2') {
                        if (partnerObj.supervisor) {
                          currentIssuance.relationshipManager = partnerObj.supervisor.name;
                        }
                        if (partnerObj.parentIntermediaryName) {
                          currentIssuance.L1RelationshipManager = partnerObj.parentIntermediaryName;
                          _showHideField('L1RelationshipManager', false);
                        } else {
                          _showHideField('L1RelationshipManager', true);
                        }
                      } else {
                        if (partnerObj.supervisor) {
                          currentIssuance.relationshipManager = partnerObj.supervisor.name;
                        } else if (partnerObj.parentIntermediaryName) {
                          currentIssuance.relationshipManager = partnerObj.parentIntermediaryName;
                        }
                        _showHideField('L1RelationshipManager', true);
                      }

                      if (partnerObj.am) {
                        currentIssuance.areaManager = partnerObj.am.name;
                      }
                    } else {
                      clearPartnerData(currentIssuance);
                      window.alert('Entered partner detail is not a Certified partner. Please select a Certified partner');
                    }
                  }).catch(function () /*err*/{
                    commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
                  });
                }
              }
            }
          });
        }

        $timeout(function () {
          var paymentStatusConfig = _getConfigObjectFromCode('paymentStatus');
          var inspectionStatusConfig = _getConfigObjectFromCode('inspectionStatus');
          var issuanceStatusConfig = _getConfigObjectFromCode('policyIssuanceStatus');
          var applicationStatusConfig = _getConfigObjectFromCode('applicationStatus');
          var inspectionSubStatusConfig = _getConfigObjectFromCode('inspectionSubStatus');
          getStatusList('payment').then(function (paymentRes) {
            paymentStatusConfig.selectConfig.options = paymentRes;
            return getStatusList('inspection');
          }).then(function (inspectionRes) {
            inspectionStatusConfig.selectConfig.options = inspectionRes;
            return getStatusList('inspectionSubStatus');
          }).then(function (inspectionSubStatusRes) {
            inspectionSubStatusConfig.selectConfig.options = inspectionSubStatusRes;
            return getStatusList('issuance');
          }).then(function (issuanceRes) {
            issuanceStatusConfig.selectConfig.options = issuanceRes;
            return getStatusList('applicationstatus');
          }).then(function (applicationstatusRes) {
            saleConfig.applicationStatusList.splice(0, saleConfig.applicationStatusList.length);
            for (var idx = 0; idx < applicationstatusRes.length; idx++) {
              var status = applicationstatusRes[idx];
              saleConfig.applicationStatusList.push(status);
            }
            applicationStatusConfig.selectConfig.options = applicationstatusRes;
            return PartnerService.getCities();
          }).then(function (res) {
            saleConfig.cities.splice(0, saleConfig.cities.length);
            for (var idx = 0; idx < res.length; idx++) {
              var city = res[idx];
              city.code = city.name.toLowerCase();
              saleConfig.cities.push(city);
            }
          });
        }, 100);
      }

      function findRequestId(currentIssuance) {
        if (currentIssuance.$$vertical === 'health') {
          var requestId = currentIssuance.requestId.split('-')[0];
          return requestId;
        }

        return currentIssuance.requestId;
      }

      function getDefaultBranchIssuance() {
        var returnPromise = $q.defer();
        var reqUrl = '/branchIssuanceDefaults';

        commonService.makeHttpCall(reqUrl, {}, 'GET', false).then(function (res) {
          res = res.data;
          var responseObj = {
            partnerId: ' ',
            $$attachedDocuments: [],
            members: [],
            nominees: []
          };

          angular.extend(res, responseObj);

          currentIssuance = res;
          currentIssuance.$$vertical = currentIssuance.vertical;
          var newBIData = commonService.getItemSession('pi', 'newBI');
          if (newBIData) {
            currentIssuance = _extends({}, currentIssuance, newBIData);
          }
          returnPromise.resolve(currentIssuance);

          checkForValuesIssuance(currentIssuance);
          issuanceTypePaymentMode(currentIssuance);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function _formatInsurerRemakrks(insurerRemarks, modify) {
        if (insurerRemarks) {
          return modify ? ('' + insurerRemarks).replace(/\|/g, '\n\n') : ('' + insurerRemarks).replace(/\n\n/g, '|');
        }
      }

      function _typeOfSaleChangeFn(selectedItem) {
        if (selectedItem === 'BQP') {
          _showHideField('bqp', false);
        } else {
          _showHideField('bqp', true);
        }
      }

      function _typeOfSaleInitFn(selectedItem) {
        _typeOfSaleChangeFn(selectedItem);
      }

      function _selectedBQPChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.bqp = selectedItem;
          model.bqpId = selectedItem.clientId;
          model.bqpBranch = selectedItem.branch;
          model.bqpInternalId = selectedItem.internalId;
          model.bqpName = selectedItem.name;
        } else {
          delete model.bqp;
          delete model.bqpId;
          delete model.bqpBranch;
          delete model.bqpInternalId;
          delete model.bqpName;
        }
      }

      function _searchBQPFn(searchKey, model, config) {
        var deferred = $q.defer();
        var bqpList = angular.copy(saleConfig.bqpList) || [];
        if (bqpList.length < 1) {
          $timeout(function () {
            if (config) {
              _clearDataBQP(model);
            }
          }, 1000);
        }
        if (searchKey) {
          var results = $filter('filter')(bqpList, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(bqpList);
        }
        return deferred.promise;
      }

      function _clearDataBQP(model) {
        if (model.bqp) {
          delete model.bqp;
          delete model.bqpId;
          delete model.bqpBranch;
          delete model.bqpInternalId;
          delete model.bqpName;
        }
      }

      function _selectedBQPInitFn(selectedItem, model) {
        if (selectedItem) {
          model.bqp = selectedItem;
        } else {
          _clearDataBQP(model);
        }
      }

      function _verticalChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.vehicleType = null; // Reset Vehicle type on verticle change
          var fieldConfig = _getConfigObjectFromCode('insurer');
          delete fieldConfig.autocompleteConfig.searchText;
          delete model.insurer;
          delete model.vehicleSubType;
          model.vertical = model.$$vertical;
          model.nominees = [];
        }

        delete model.tmPlanId;
        delete model.productUIN;
        delete model.planName;
        // delete the search text for plan Name (tmPlanId field)
        var fieldConfig = _getConfigObjectFromCode('tmPlanId');
        delete fieldConfig.autocompleteConfig.searchText;

        _verticalInitFn(selectedItem, model);
      }

      function _verticalInitFn(selectedItem, currentIssuance) {
        var showOdPolicyFields = false;
        var odPolicyFields = ['previousODPolicyNumber', 'previousODRiskStartDate', 'previousODRiskEndDate', 'previousODInsurer', 'activeTPPolicyNumber', 'activeTPRiskStartDate', 'activeTPRiskEndDate', 'activeTPInsurer'];
        if (selectedItem) {
          if (selectedItem === 'FW' || selectedItem === 'CV') {
            showOdPolicyFields = true;
            _showHideField('deTariff', false);
            _showHideField('policyType', false);
            _showHideField('inspectionPreference', false);
          } else {
            showOdPolicyFields = false;
            _showHideField('deTariff', true);
            _showHideField('inspectionPreference', true);
          }

          if (selectedItem === 'LIFE' || selectedItem === 'TERM') {
            currentIssuance.insurerRemarks = _formatInsurerRemakrks(currentIssuance.insurerRemarks, true);
            showOdPolicyFields = false;
            _showHideField('filedClaim', true);
            _showHideField('prevInsurer', true);
            _showHideField('prevPolicyType', true);
            _showHideField('prevPolicyNumber', true);
            _showHideField('policyExpDate', true);
            _showHideField('odPremium', true);
            _showHideField('netOdPremium', true);
            _showHideField('tpPremium', true);
            _showHideField('basicTpPremium', true);
            _showHideField('subPlanType', false);
            _showHideField('paymentType', false);
            _showHideField('payoutMode', false);
            _showHideField('payoutTerm', false);
            _showHideField('category', false);
            _showHideField('applicationStatus', false);
            _showHideField('applicationUrl', false);
            _showHideField('selfProposer', false);
            _showHideField('multiyear', true);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('applicationNumber', false);
            _applicationNumberInitFn(currentIssuance.applicationNumber, currentIssuance);
            _showHideField('deviationOd', true);
            _showHideField('deviationTp', true);
            _showHideField('gstin', true);
            _showHideField('reasonForNoPACover', true);
            _showHideField('isVehicleFinanced', true);
            _showHideField('financingInstitute', true);
            _showHideField('quoteType', true);
            _showHideField('inceptionDate', true);
            _showHideField('insurerRemarks', false);
            _showHideField('transactionId', true);
            _showHideField('registrationNumber', true);
            currentIssuance.$$vertical = 'LIFE';
          } else if (selectedItem === 'HEALTH') {
            showOdPolicyFields = false;
            _showHideField('prevInsurer', true);
            _showHideField('prevPolicyType', true);
            _showHideField('prevPolicyNumber', true);
            _showHideField('policyExpDate', true);
            _showHideField('odPremium', true);
            _showHideField('netOdPremium', true);
            _showHideField('tpPremium', true);
            _showHideField('basicTpPremium', true);
            _showHideField('subPlanType', true);
            _showHideField('planName', false);
            _showHideField('paymentType', true);
            _showHideField('payoutMode', true);
            _showHideField('payoutTerm', true);
            _showHideField('category', true);
            _showHideField('selfProposer', true);
            _showHideField('multiyear', true);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('applicationNumber', true);
            _showHideField('deviationOd', true);
            _showHideField('deviationTp', true);
            _showHideField('gstin', true);
            _showHideField('reasonForNoPACover', true);
            _showHideField('isVehicleFinanced', true);
            _showHideField('financingInstitute', true);
            _showHideField('quoteType', true);
            _showHideField('insurerRemarks', true);
            _showHideField('transactionId', false);
            _showHideField('registrationNumber', true);
            if (currentIssuance.source === 'ONLINE') {
              _showHideField('inceptionDate', true);
            } else {
              _showHideField('inceptionDate', false);
            }
          } else if (selectedItem === 'FW' || selectedItem === 'CV' || selectedItem === 'TW') {
            showOdPolicyFields = true;
            _showHideField('odPremium', false);
            _showHideField('netOdPremium', false);
            _showHideField('tpPremium', false);
            _showHideField('basicTpPremium', false);
            _showHideField('category', true);
            _showHideField('selfProposer', true);
            _showHideField('applicationNumber', true);
            _showHideField('deviationOd', false);
            _showHideField('deviationTp', false);
            _showHideField('gstin', false);
            _showHideField('isVehicleFinanced', false);
            _showHideField('reasonForNoPACover', false);
            _showHideField('inceptionDate', true);
            _showHideField('insurerRemarks', true);
            _showHideField('transactionId', true);
            _showHideField('registrationNumber', false);
            if (currentIssuance.quoteType && currentIssuance.quoteType !== null) {
              _showHideField('quoteType', false);
            }
          } else {
            showOdPolicyFields = false;
            _showHideField('category', true);
            _showHideField('selfProposer', true);
            _showHideField('applicationNumber', true);
            _showHideField('multiyear', true);
            _showHideField('odRiskStartDate', true);
            _showHideField('odRiskEndDate', true);
            _showHideField('tpRiskStartDate', true);
            _showHideField('tpRiskEndDate', true);
            _showHideField('policyStartDate', false);
            _showHideField('policyEndDate', false);
            _showHideField('deviationOd', true);
            _showHideField('deviationTp', true);
            _showHideField('gstin', true);
            _showHideField('reasonForNoPACover', true);
            _showHideField('isVehicleFinanced', true);
            _showHideField('financingInstitute', true);
            _showHideField('quoteType', true);
            _showHideField('inceptionDate', true);
            _showHideField('insurerRemarks', true);
            _showHideField('transactionId', true);
            _showHideField('registrationNumber', true);
          }

          if (selectedItem === 'HEALTH' || selectedItem === 'LIFE' || selectedItem === 'TERM') {
            showOdPolicyFields = false;
            _showHideField('sumInsured', false);
            _showHideField('policyTerm', false);
            _showHideField('income', false);
            _showHideField('maritalStatus', false);

            _showHideField('tmPlanId', false);
            _showHideField('planName', false);
            _markFieldRequired('planName', true);
            setMemberFields(selectedItem);

            _markFieldRequired('proposerMobile', true);
            _markFieldRequired('proposerEmail', true);
            _markFieldRequired('proposerDob', true);
          } else {
            showOdPolicyFields = false;
            _showHideField('sumInsured', true);
            _showHideField('policyTerm', true);
            _showHideField('income', true);
            _showHideField('maritalStatus', true);

            _showHideField('tmPlanId', true);
            _showHideField('planName', true);
            _markFieldRequired('planName', false);

            _showHideField('subPlanType', true);
            _showHideField('paymentType', true);
            _showHideField('payoutTerm', true);
            _showHideField('payoutMode', true);

            _showHideField('applicationStatus', true);
            _showHideField('applicationUrl', true);
            _markFieldRequired('proposerMobile', true);
            _markFieldRequired('proposerEmail', false);
            _markFieldRequired('proposerDob', false);
            _showHideField('insurerRemarks', true);

            if (currentIssuance.source === 'BRANCH') {
              _showHideField('businessType', false, true);
            }
          }
          odPolicyFields.forEach(function (ele) {
            if (['FW', 'TW'].includes(currentIssuance.vertical)) {
              _showHideField(ele, !showOdPolicyFields, true);
            } else {
              _showHideField(ele, !showOdPolicyFields, false);
            }
          });

          var vehicleTypeList = void 0;
          if (selectedItem === 'TW') {
            currentIssuance.vehicleType = 'TW';
            FormHelperService.setAssignedVertical('TW', currentIssuance);
            vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(['TW']);
            var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
            vehicleTypeConfig.selectConfig.options = vehicleTypeList;

            _vehicleTypeChangeFn(currentIssuance.vehicleType, currentIssuance);
          }
          if (selectedItem === 'FW') {
            FormHelperService.setAssignedVertical('FW', currentIssuance);
            _setVehicleType(['FW', 'CV'], currentIssuance);
            _vehicleTypeChangeFn(currentIssuance.vehicleType, currentIssuance);
          } else if (selectedItem === 'CV') {
            FormHelperService.setAssignedVertical('CV', currentIssuance);
            if (currentIssuance.vertical === 'CV') {

              vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(['CV']);
              var _vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
              _vehicleTypeConfig.selectConfig.options = vehicleTypeList;

              currentIssuance.$$vertical = 'FW';

              FormHelperService.setAssignedVertical('FW', currentIssuance);
            }
          }

          if (currentIssuance.source === 'ONLINE') {
            if (currentIssuance.$$vertical === 'HEALTH') {
              _markFieldDisabled('city', true);
              _markFieldDisabled('state', true);
              _markFieldDisabled('pincode', true);
              _markFieldDisabled('proposerGender', true);
              _markFieldDisabled('proposerDob', true);
            } else {
              _markFieldDisabled('city', false);
              _markFieldDisabled('state', false);
              _markFieldDisabled('pincode', false);
              _markFieldDisabled('proposerGender', false);
              _markFieldDisabled('proposerDob', false);
            }
          }
          _markFieldDisabled('basicTpPremium', false);
        } else {
          _showHideField('policyType', true);
        }

        // policy type in life vertical and product name in case of fw/tw/cv
        var policyTypeField = _getConfigObjectFromCode('policyType');
        if (selectedItem === 'LIFE' || selectedItem === 'TERM') {
          _showHideField('policyType', false);
          policyTypeField.selectConfig.options = saleConfig.lifePlanTypes;
          policyTypeField.selectConfig.valueKey = 'code';
          policyTypeField.name = 'Policy Type';
        } else if (selectedItem === 'TW' || selectedItem === 'FW' || selectedItem === 'CV') {
          _showHideField('policyType', false);
          policyTypeField.selectConfig.options = saleConfig.productNames;
          policyTypeField.selectConfig.valueKey = 'value';
          policyTypeField.name = 'Product Name';
        } else {
          _showHideField('policyType', true);
        }
      }

      function activateForm(vertical, policyIssuanceSource) {
        if (policyIssuanceSource === 'ONLINE') {
          _markFieldDisabled('$$vertical', true);
          _markFieldDisabled('tenant', false); // sumanta
          _markFieldDisabled('insurer', true);
          // _markFieldDisabled('partnerName', true);
          _markFieldDisabled('$$grossPremium', true);
          if (vertical === 'HEALTH' || vertical === 'LIFE' || vertical === 'TERM') {
            _markFieldDisabled('city', true);
            _markFieldDisabled('state', true);
            _markFieldDisabled('pincode', true);
            _markFieldDisabled('proposerGender', true);
            _markFieldDisabled('proposerDob', true);
          } else {
            _markFieldDisabled('city', false);
            _markFieldDisabled('state', false);
            _markFieldDisabled('pincode', false);
            _markFieldDisabled('proposerGender', false);
            _markFieldDisabled('proposerDob', false);
          }

          if (vertical === 'LIFE') {
            _markFieldDisabled('grossPremium', false);
          } else {
            _markFieldDisabled('grossPremium', true);
          }

          _markFieldDisabled('rtoLocation', true);
          _markFieldDisabled('makemodel', true);
          _markFieldDisabled('variant', true);
          _markFieldDisabled('cc', true);
          _markFieldDisabled('fuel', true);
          _markFieldDisabled('vehicleRegDate', true);
          _markFieldDisabled('manufactureYear', true);
          _markFieldDisabled('idv', true);
          _markFieldDisabled('electricalValue', true);
          _markFieldDisabled('nonElecValue', true);
          _markFieldDisabled('biFuelKit', true);
          _markFieldDisabled('ncb', true);
          _markFieldDisabled('deductible', true);

          _markFieldDisabled('filedClaim', true);

          _markFieldDisabled('horseIdv', true);
          _markFieldDisabled('cvVehicleClass', true);
          _markFieldDisabled('imt23', true);
          _markFieldDisabled('withTrailer', true);
          _markFieldDisabled('trailerIDV', true);
          _markFieldDisabled('insTrailerIDV', true);
          _markFieldDisabled('cvVehicleType', true);
          _markFieldDisabled('cvWheels', true);
          _markFieldDisabled('cvDetariff', true);

          _markFieldDisabled('planName', true);
          _markFieldDisabled('medicalHistory', true);
          _markFieldDisabled('sumInsured', true);
          _markFieldDisabled('income', true);
          _markFieldDisabled('maritalStatus', true);
          _markFieldDisabled('deductible', true);
          _markFieldDisabled('policyType', true);
          _markFieldDisabled('subPlanName', true);
          _markFieldDisabled('policyTerm', true);
          _markFieldDisabled('premiumPaymentTerm', true);
          _markFieldDisabled('payoutTerm', true);
          _markFieldDisabled('payoutMode', true);
          _markFieldDisabled('smoker', true);

          _markFieldDisabled('odPremium', true);
          _markFieldDisabled('netOdPremium', true);
          _markFieldDisabled('tpPremium', true);
          _markFieldDisabled('basicTpPremium', true);
          _markFieldDisabled('netPremium', true);
          _markFieldDisabled('serviceTax', true);
          _markFieldDisabled('deTariff', true);
          _showHideField('businessType', false);
          _showHideField('reason', true);
          _showHideField('reasonOptions', true);
          _markFieldDisabled('reasonForNoPACover', true);
          _showHideField('comment', false);
        } else {
          // CHANGES FOR BRANCH ISSUANCE
          _showHideField('businessType', false, true);
          _showHideField('reason', false);
          _markFieldDisabled('reasonForNoPACover', false);
          _showHideField('comment', true);
        }
      }

      function getById(id) {
        var returnPromise = $q.defer();
        var reqUrl = '/policyIssuance/' + id;

        commonService.makeHttpCall(reqUrl, {}, 'GET', false).then(function (res) {
          currentIssuance = res.data;
          currentIssuance.opsBranch = 'abc';
          currentIssuance.$$vertical = currentIssuance.vertical;

          var requestId = findRequestId(currentIssuance);
          getDocuments(requestId);

          if (currentIssuance.partnerId) {
            _showHideField('tenant', false);
          } else {
            _showHideField('tenant', true);
          }

          if (currentIssuance.piType && currentIssuance.piType !== null) {
            _showHideField('piType', false);
          }

          if (currentIssuance.$$vertical === 'FW') {
            if (currentIssuance.vehicleType === 'TWO_WHEELER') {
              FormHelperService.setAssignedVertical('TW', currentIssuance);
            } else {
              FormHelperService.setAssignedVertical('FW', currentIssuance);
            }
          } else if (currentIssuance.$$vertical === 'CV') {
            FormHelperService.setAssignedVertical('CV', currentIssuance);
          }

          if (['TW', 'FW'].includes(currentIssuance.vertical) && currentIssuance.make && currentIssuance.model) {
            currentIssuance.makemodel = currentIssuance.make + ' ' + currentIssuance.model;
            var reqObj = {
              make: currentIssuance.make,
              model: currentIssuance.model
            };
            selectedMakemodelChangeFn(reqObj, currentIssuance);
          }
          issuanceTypePaymentMode(currentIssuance);

          if (currentIssuance.gatewayTxnNo) {
            var paymentModeFieldConfig = _getConfigObjectFromCode('paymentMode');
            var chequeDetailsFieldConfig = _getConfigObjectFromCode('chequeDetails');
            var neftDetailsFieldConfig = _getConfigObjectFromCode('neftDetails');
            var paymentLinkFieldConfig = _getConfigObjectFromCode('paymentLink');
            var paymentStatusConfig = _getConfigObjectFromCode('paymentStatus');
            var paymentDetailsSharedConfig = _getConfigObjectFromCode('paymentDetailsShared');
            paymentStatusConfig.disabled = true;
            chequeDetailsFieldConfig.disabled = true;
            neftDetailsFieldConfig.disabled = true;
            paymentLinkFieldConfig.disabled = true;
            paymentModeFieldConfig.readonly = true;
            paymentModeFieldConfig.disabled = true;
            paymentDetailsSharedConfig.disabled = true;

            _showHideField('gatewayTxnNo');
          } else {
            _showHideField('gatewayTxnNo', true);
          }
          checkForValuesIssuance(currentIssuance);
          returnPromise.resolve(currentIssuance);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getDocuments(requestId) {
        var reqObj = {
          requestId: requestId
        };
        MasterFileService.getDocuments(reqObj).then(function (list) {
          currentIssuance.$$attachedDocuments = list;
          handleUploadedDocuments(currentIssuance.$$attachedDocuments, currentIssuance);
        }).catch(function () {
          currentIssuance.$$attachedDocuments = [];
        });
      }

      function syncFileMIS(policyIssuanceId, fileId) {
        var returnPromise = $q.defer();
        var reqType = 'POST';
        var reqUrl = '/updatepolicyurl';

        var reqObj = {
          policyIssuanceId: policyIssuanceId,
          policyUrl: fileId
        };
        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function () /*res*/{
          returnPromise.resolve(currentIssuance);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function getRedirectUrl(policyIssuance, stage) {
        var returnPromise = $q.defer();
        var reqType = 'GET';
        var reqUrl = '/url';

        var reqObj = {
          requestId: policyIssuance.requestId,
          vertical: policyIssuance.vertical,
          stage: stage // quotes/checkout
        };

        commonService.makeHttpCall(reqUrl, reqObj, reqType, false).then(function (res) {
          var data = res.data;
          returnPromise.resolve(data.proUrl);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });

        return returnPromise.promise;
      }

      function _inspectionConfigChangeFn(selectedItem, model) {
        if (selectedItem === 'REQUIRED') {
          _showHideField('$$inspectionReasons', false, true);
          _showHideField('inspectionStatus', false, true);
          _showHideField('inspectionSubStatus', false, true);
          _showHideField('inspectionSubStatusReasons', false, true);
        } else {
          _showHideField('$$inspectionReasons', true, true);
          _showHideField('inspectionStatus', true, true);
          _showHideField('inspectionSubStatus', true, true);
          _showHideField('inspectionSubStatusReasons', true, true);
          _paymentModeChangeFn(model.paymentMode, model);
        }

        checkInspectionError(model);
      }

      /**
       * Function to search Insurers
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchInsurerFn(searchKey, model, config) {
        var deferred = $q.defer();
        var insurers = [];
        insurers = angular.copy(MIS_CONSTANTS.insurers);
        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          insurers = angular.copy(MIS_CONSTANTS.lifeInsurers);
        }
        if (!insurers || insurers && insurers.length < 1) {
          $timeout(function () {
            delete config.autocompleteConfig.searchText;
          }, 2000);
        }

        if (searchKey) {
          var results = $filter('filter')(insurers, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(insurers);
        }

        return deferred.promise;
      }

      /**
       * Function called on change of Sales Executive
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedMakemodelChangeFn(selectedItem, model) {
        if (selectedItem) {
          if (model.vertical === 'FW') {
            _vehicleTypeChangeFn(model.vehicleType, model);
          }

          _showHideField('variant', false);
          model.make = selectedItem.make;
          model.model = selectedItem.model;
          _getVariants(model);
        } else {
          _showHideField('variant', true);
          delete model.make;
          delete model.model;
          delete model.variant;
          delete model.makemodel;
        }
      }

      function _getVariants(model) {
        saleConfig.variants.splice(0, saleConfig.variants.length);
        var type = model.vertical === 'CV' ? 'CV' : FormHelperService.getAssignedVertical() || model.vertical;
        var createdAt = model.createdAt ? moment(model.createdAt).format('x') : moment().format('x');
        var reqObj = {
          make: model.make,
          model: model.model,
          type: type,
          subtype: model.vehicleType,
          createdAt: createdAt
        };
        var reqUrl = '/masters/getvariant';
        var promise = commonService.makeThirdPartyApiCall(TM_URL, reqUrl, reqObj, 'GET', true, true);
        promise.then(function (res) {
          saleConfig.variants.splice(0, saleConfig.variants.length);
          for (var idx = 0; idx < res.data.length; idx++) {
            var data = res.data[idx];
            saleConfig.variants.push(data);
          }
        });
      }

      /**
       * Function called on selection of Variant
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function onSelectVariantFn(selectedItem, sale) {
        if (selectedItem) {
          for (var idx = saleConfig.variants.length - 1; idx >= 0; idx--) {
            var variant = saleConfig.variants[idx];
            if (variant.displayVariant === selectedItem) {
              sale.fuel = variant.fuel;
              sale.cc = typeof variant.cc === 'string' ? parseInt(variant.cc) : variant.cc;
              sale.vehicleName = variant.make + ' ' + variant.model + ' ' + variant.displayVariant;
              sale.make = variant.make;
              sale.model = variant.model;
              sale.vehicleId = variant._id;
              break;
            }
          }
        } else {
          delete sale.variant;
        }
      }

      /**
       * Function called on change of Intermediary
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function _selectedIntermediaryChangeFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: selectedItem.dpLoginId,
            vertical: model.vertical
          };
          FormHelperService.isACertifedPartner(formData).then(function (res) {
            var isCertified = res.data.data.allowedToDoBusiness;
            if (isCertified === true) {
              model.partnerId = selectedItem.dpLoginId;
              model.pospType = selectedItem.dpType;
              model.partnerDpNo = selectedItem.dpNo;
              model.partnerName = selectedItem.dpName;
              model.partnerEmail = selectedItem.email;
              model.partnerMobile = selectedItem.mobile;
              model.partnerBranchLocation = selectedItem.branchCode;
              _showHideField('L1RelationshipManager', true);

              if (selectedItem.intermediaryLevel === 'partner_level_2') {
                if (selectedItem.supervisor) {
                  model.relationshipManager = selectedItem.supervisor.name;
                }
                if (selectedItem.parentIntermediaryName) {
                  model.L1RelationshipManager = selectedItem.parentIntermediaryName;
                  _showHideField('L1RelationshipManager', false);
                } else {
                  _showHideField('L1RelationshipManager', true);
                }
              } else {
                if (selectedItem.supervisor) {
                  model.relationshipManager = selectedItem.supervisor.name;
                } else if (selectedItem.parentIntermediaryName) {
                  model.relationshipManager = selectedItem.parentIntermediaryName;
                }
              }
              if (selectedItem.am) {
                model.areaManager = selectedItem.am.name;
              }
              model.piBranchLocation = selectedItem.branchLocation;
            } else {
              clearPartnerData(model);
              window.alert('Entered partner detail is not a Certified partner. Please select a Certified partner');
            }
          }).catch(function () /*err*/{
            commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
          });
        } else {
          clearPartnerData(model);
        }
      }

      function dateChangeValidation(model) {
        var today = new Date();
        var saleCloseDate = model.saleCloseDate;
        if (saleCloseDate && moment(saleCloseDate).isAfter(today, 'day')) {
          model.saleCloseDate = undefined;
        }

        var policyIssuanceDate = model.policyIssuanceDate;

        var minPolicyIssuanceDate = model.saleCloseDate ? new Date(model.saleCloseDate) : new Date();
        if (policyIssuanceDate && moment(policyIssuanceDate).isBefore(minPolicyIssuanceDate, 'day')) {
          model.policyIssuanceDate = undefined;
          commonService.showToast('Policy Issuance Date cannot be before Sale Closed Date');
        }

        var policyStartDate = model.policyStartDate;
        var minPolicyStartDate = model.saleCloseDate ? new Date(model.saleCloseDate) : new Date();
        if (policyStartDate && moment(policyStartDate).isBefore(minPolicyStartDate, 'day')) {
          model.policyStartDate = undefined;
          commonService.showToast('Risk Start Date cannot be before Sale Closed Date');
        }

        var odRiskStartDate = model.odRiskStartDate;
        if (odRiskStartDate && moment(odRiskStartDate).isBefore(minPolicyStartDate, 'day')) {
          model.odRiskStartDate = undefined;
          commonService.showToast('Od Risk Start Date cannot be before Sale Closed Date');
        }

        var tpRiskStartDate = model.tpRiskStartDate;
        if (tpRiskStartDate && moment(tpRiskStartDate).isBefore(minPolicyStartDate, 'day')) {
          model.tpRiskStartDate = undefined;
          commonService.showToast('TP Risk Start Date cannot be before Sale Closed Date');
        }

        var policyEndDate = model.policyEndDate;
        var minPolicyEndDate = model.policyStartDate ? new Date(model.policyStartDate) : new Date();
        if (policyEndDate && moment(policyEndDate).isBefore(minPolicyEndDate, 'day')) {
          model.policyEndDate = undefined;
          commonService.showToast('Risk End Date cannot be before Risk Start Date');
        }

        var odRiskEndDate = model.odRiskEndDate;
        var minOdRiskEndDate = model.odRiskStartDate ? new Date(model.odRiskStartDate) : new Date();
        if (odRiskEndDate && moment(odRiskEndDate).isBefore(minOdRiskEndDate, 'day')) {
          model.odRiskEndDate = undefined;
          commonService.showToast('OD Risk End Date cannot be before OD Risk Start Date');
        }

        var tpRiskEndDate = model.tpRiskEndDate;
        var minTpRiskEndDate = model.tpRiskStartDate ? new Date(model.tpRiskStartDate) : new Date();
        if (tpRiskEndDate && moment(tpRiskEndDate).isBefore(minTpRiskEndDate, 'day')) {
          model.tpRiskEndDate = undefined;
          commonService.showToast('TP Risk End Date cannot be before TP Risk Start Date');
        }
      }

      /**
       * Function to search Intermediaries
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchIntermediariesFn(searchKey, model /*, config*/) {
        var deferred = $q.defer();
        if (searchKey && currentIssuance.tenant && currentIssuance.saleCloseDate) {
          var tp = moment(currentIssuance.saleCloseDate).format('x');
          var promise = commonService.makeHttpCall('/getpartnersinfo?query=' + searchKey + '&tenant=' + currentIssuance.tenant + '&channel=partner&saleDate=' + tp, {}, 'GET', false, false, false);
          promise.then(function (res /*, status, headers, config*/) {
            if (res.data && res.data.status === 'SUCCESS') {
              saleConfig.intermediaries = res.data.data || [];

              deferred.resolve(saleConfig.intermediaries);
            } else {
              clearPartnerData(model);
              deferred.reject(true);
            }
          });
        } else {
          clearPartnerData(model);
          deferred.reject(true);
        }
        return deferred.promise;
      }

      function clearPartnerData(model) {
        if (model.pospType) {
          model.partnerId = ' ';
          delete model.pospType;
          delete model.partnerDpNo;
          delete model.partnerName;
          delete model.partnerEmail;
          delete model.partnerMobile;
          delete model.relationshipManager;
          delete model.areaManager;
          delete model.L1RelationshipManager;
          delete model.piBranchLocation;
          delete model.partnerBranchLocation;
          _showHideField('L1RelationshipManager', true);
        }
      }

      /**
       * Function to search plan names
       *
       * @public
       *
       * @function
       *
       * @param    {!String}           searchKey          searchkey
       *
       * @author snehilmodani
       *
       * @return   {Promise}           Promise of searching
       *
       */
      function searchPlanNames(searchKey, model) {
        var deferred = $q.defer();
        var planNames = angular.copy(saleConfig.planNames);
        if (searchKey) {
          var results = $filter('filter')(planNames, searchKey);
          // if (!results || (results && results.length <1)) {
          //   $timeout(() => {
          //     if(config){
          //       _clearPlanNames(model);
          //     }
          //   }, 2000);
          // }
          deferred.resolve(results);
        } else {
          _clearPlanNames(model);
          deferred.resolve(planNames);
        }
        return deferred.promise;
      }

      function _clearPlanNames(model) {
        delete model.planName;
        delete model.tmPlanId;
        delete model.productUIN;
      }

      /**
       * Function called on change of planName
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           selectedItem          Selected Item
       *
       * @author snehilmodani
       *
       */
      function selectedPlanNameChangeFn(selectedItem, model) {
        if (selectedItem) {
          var planName = _getConfigObjectFromCode('planName');
          if (selectedItem.tmPlanId === 'others') {
            delete currentIssuance.planName;
            delete currentIssuance.productUIN;
            planName.hide = false;
            currentIssuance.tmPlanId = selectedItem.tmPlanId;
          } else {
            planName.hide = true;
            currentIssuance.planName = selectedItem.planName;
            currentIssuance.tmPlanId = selectedItem.tmPlanId;
            currentIssuance.productUIN = selectedItem.productUIN;
          }
        } else if (!model.tmPlanId) {
          delete currentIssuance.tmPlanId;
          delete currentIssuance.planName;
          delete currentIssuance.productUIN;
        }
        // TODO: This code is just a temporary fix to allow 'planName' to be editable for TERM and LIFE.
        if (selectedItem.tmPlanId === 'others') {
          _markFieldDisabled('planName', false);
        } else {
          _markFieldDisabled('planName', true);
        }
        //©
      }

      /**
       * Function called to Hide Vehicle Fields
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           hide          to hide fields or not
       *
       * @author snehilmodani
       *
       */
      function _showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        if (fieldConfig) {
          fieldConfig.hide = toHide;
          if (toHide && !dontDelete) {
            delete currentIssuance[fieldConfig.code];
          }
        }
      }

      /**
       * Function called to Mark field required
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           required      to mark fields required or not
       *
       * @author snehilmodani
       *
       */
      function _markFieldRequired(fieldCode, required) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;

        fieldConfig.required = toRequired;
      }

      /**
       * Function called to Mark field disabled/readonly
       *
       * @public
       *
       * @function
       *
       * @param    {!String}            fieldCode     Field Code
       * @param    {!Boolean}           required      to mark fields required or not
       *
       * @author snehilmodani
       *
       */
      function _markFieldDisabled(fieldCode, disabled) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toDisabled = disabled ? true : false;

        fieldConfig.disabled = toDisabled;
        fieldConfig.readonly = toDisabled;
      }

      /**
       * Returns config of form field by using code (TODO: change from array approach)
       *
       * @public
       *
       * @function
       *
       * @param    {!Object}           code          Code of the form field
       *
       * @author snehilmodani
       *
       * @return   {Object}           Config object of form field
       *
       */
      function _getConfigObjectFromCode(code) {
        for (var idx = sourceOfSaleFields.length - 1; idx >= 0; idx--) {
          var config = sourceOfSaleFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = opsBranchFields.length - 1; idx >= 0; idx--) {
          var config = opsBranchFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = generalFields.length - 1; idx >= 0; idx--) {
          var config = generalFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = inspectionConfig.length - 1; idx >= 0; idx--) {
          var config = inspectionConfig[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = paymentFields.length - 1; idx >= 0; idx--) {
          var config = paymentFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.proposerFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.proposerFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.vehicleFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.vehicleFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.healthFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.healthFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.lifeFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.lifeFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.commonFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.commonFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.partnerFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.partnerFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.mandatoryFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.mandatoryFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.insurerPremiumFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.insurerPremiumFields[idx];
          if (config.code === code) {
            return config;
          }
        }
        for (var idx = policyFields.odPolicyFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.odPolicyFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = policyFields.cvSpecificFields.length - 1; idx >= 0; idx--) {
          var config = policyFields.cvSpecificFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = paymentStatusFormConfig.length - 1; idx >= 0; idx--) {
          var config = paymentStatusFormConfig[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = issuanceStatusFields.length - 1; idx >= 0; idx--) {
          var config = issuanceStatusFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = deviationFields.length - 1; idx >= 0; idx--) {
          var config = deviationFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var idx = inspectionStatusConfig.length - 1; idx >= 0; idx--) {
          var config = inspectionStatusConfig[idx];
          if (config.code === code) {
            return config;
          }
        }
        return {};
      }

      function setMemberFields(vertical) {
        switch (vertical) {
          case 'HEALTH':
            memberConfig.memberFields = {
              generalFields: [{
                name: 'First Name',
                code: 'fname',
                type: 'text',
                required: true
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text',
                required: true
              }, {
                name: 'DOB',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Gender',
                code: 'gender',
                type: 'select',
                selectConfig: {
                  options: saleConfig.genderList,
                  valueKey: 'code',
                  textKey: 'name'
                },
                required: true
              }, {
                name: 'Height',
                code: 'height',
                type: 'text'
              }, {
                name: 'Weight',
                code: 'weight',
                type: 'text'
              }, {
                name: 'Pre-existing disease',
                code: 'preExistingDisease',
                type: 'checkbox'
              }]
            };
            memberConfig.memberInitConfig = {
              fname: '',
              lname: '',
              relationship: '',
              dob: '',
              height: undefined,
              weight: undefined,
              preExistingDisease: false
            };
            memberConfig.nomineeFields = {
              generalFields: [{
                name: 'First Name',
                code: 'fname',
                type: 'text'
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text'
              }, {
                name: 'Date of Birth',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Relationship with Nominee',
                code: 'relationship',
                type: 'text',
                readonly: true
              }]
            };
            memberConfig.nomineeInitConfig = {
              fname: '',
              lname: '',
              dob: '',
              relationship: ''
            };
            break;
          case 'LIFE':
            memberConfig.memberFields = {
              generalFields: [{
                name: 'Title',
                code: 'title',
                type: 'text'
              }, {
                name: 'First Name',
                code: 'fname',
                type: 'text'
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text'
              }, {
                name: 'Date of Birth',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Place of Birth',
                code: 'placeOfBirth',
                type: 'text'
              }, {
                name: 'Marital Status',
                code: 'maritalStatus',
                type: 'text'
              }, {
                name: 'Spouse\'s First Name',
                code: 'spouseFName',
                type: 'text'
              }, {
                name: 'Spouse\'s Last Name',
                code: 'spouseLName',
                type: 'text'
              }, {
                name: 'Father\'s First Name',
                code: 'fatherFName',
                type: 'text'
              }, {
                name: 'Father\'s Last Name',
                code: 'fatherLName',
                type: 'text'
              }, {
                name: 'Mother\'s First Name',
                code: 'motherFName',
                type: 'text'
              }, {
                name: 'Mother\'s Last Name',
                code: 'motherLName',
                type: 'text'
              }, {
                name: 'Qualification',
                code: 'qualification',
                type: 'text'
              }, {
                name: 'Occupation',
                code: 'occupation',
                type: 'text'
              }, {
                name: 'Annual Income',
                code: 'annualIncome',
                type: 'text'
              }, {
                name: 'PAN',
                code: 'panNumber',
                type: 'text'
              }, {
                name: 'Do you have aadhar card?',
                code: 'isAadhaarCardAvailable',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }
              }, {
                name: 'Aadhar Card',
                code: 'aadhaarNumber',
                type: 'text'
              }, {
                name: 'Are you NRI?',
                code: 'isNri',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }
              }, {
                name: 'Are you a resident of Jammu & Kashmir?',
                code: 'residentOfJammuAndKashmir',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }
              }, {
                name: 'Are you a politically exposed person?',
                code: 'isPoliticallyExposed',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }
              }, {
                name: 'Do you have any history of conviction / acquittal under any criminal proceedings in India or abroad?',
                code: 'hasHistoryOfConviction',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }
              }, {
                name: 'Is the policy primarily to protect a Physically Disabled / Handicapped person?',
                code: 'isHandicapped',
                type: 'radio',
                radioConfig: {
                  options: [{
                    value: 'YES',
                    text: 'Yes'
                  }, {
                    value: 'NO',
                    text: 'No'
                  }],
                  valueKey: 'value',
                  textKey: 'text'
                }

              }],
              registeredAddress: [{
                name: 'Address Line 1',
                code: 'address1',
                type: 'text'
              }, {
                name: 'Address Line 2',
                code: 'address2',
                type: 'text'
              }, {
                name: 'Pincode',
                code: 'pinCode',
                type: 'text',
                minLength: 6,
                maxLength: 6,
                changeFn: _memberPincodeChangeFn
              }, {
                name: 'City',
                code: 'city',
                type: 'text'
              }, {
                name: 'State',
                code: 'state',
                type: 'text'
              }],
              correspondenceAddress: [{
                name: 'Address Line 1',
                code: 'address1',
                type: 'text'
              }, {
                name: 'Address Line 2',
                code: 'address2',
                type: 'text'
              }, {
                name: 'Pincode',
                code: 'pinCode',
                type: 'text',
                maxLength: 6,
                minLength: 6,
                changeFn: _memberPincodeChangeFn
              }, {
                name: 'City',
                code: 'city',
                type: 'text'
              }, {
                name: 'State',
                code: 'state',
                type: 'text'
              }]
            };
            memberConfig.memberInitConfig = {
              title: '',
              fname: '',
              lname: '',
              dob: '',
              placeOfBirth: '',
              maritalStatus: '',
              spouseFName: '',
              spouseLName: '',
              fatherFName: '',
              fatherLName: '',
              motherFName: '',
              motherLName: '',
              qualification: '',
              occupation: '',
              annualIncome: '',
              panNumber: '',
              isAadhaarCardAvailable: 'NO',
              aadhaarNumber: '',
              isNri: 'NO',
              residentOfJammuAndKashmir: 'NO',
              isPoliticallyExposed: 'NO',
              hasHistoryOfConviction: 'NO',
              registeredAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                pinCode: ''
              },
              correspondenceAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                pinCode: ''
              }
            };
            memberConfig.nomineeFields = {
              generalFields: [{
                name: 'Title',
                code: 'title',
                type: 'text'
              }, {
                name: 'First Name',
                code: 'fname',
                type: 'text'
              }, {
                name: 'Last Name',
                code: 'lname',
                type: 'text'
              }, {
                name: 'Date of Birth',
                code: 'dob',
                type: 'date'
              }, {
                name: 'Marital Status',
                code: 'maritalStatus',
                type: 'text'
              }, {
                name: 'Relationship with Nominee',
                code: 'relationship',
                type: 'text',
                readonly: true
              }]
            };
            memberConfig.nomineeInitConfig = {
              title: '',
              fname: '',
              lname: '',
              dob: '',
              maritalStatus: '',
              relationship: ''
            };
            break;
        }
      }

      function _memberPincodeChangeFn(selectedItem, model) {
        if (selectedItem) {
          commonService.setCityState(selectedItem, 'city', 'state', model);
        }
      }

      function _setBQPDetails(currentIssuance) {
        delete saleConfig.bqpList;
        if (currentIssuance.sourceOfSale.type === 'BQP' && currentIssuance.sourceOfSale.status === 'CONFIRMED' && currentIssuance.bqpInternalId && currentIssuance.bqpInternalId !== null) {
          var bqp = {
            branch: currentIssuance.bqpBranch,
            clientId: currentIssuance.bqpId,
            internalId: currentIssuance.bqpInternalId,
            name: currentIssuance.bqpName
          };
          saleConfig.bqpList = [bqp];
          currentIssuance.bqp = bqp;
        } else {
          var fieldConfig = _getConfigObjectFromCode('bqp');
          delete fieldConfig.autocompleteConfig.searchText;
          delete currentIssuance.bqp;
          saleConfig.bqpList = [];
        }
        _selectedBQPChangeFn(currentIssuance.bqp, currentIssuance);
      }

      function getBQPList(currentIssuance) {
        var reqObj = {
          dpId: currentIssuance.partnerId,
          saleCloseDate: moment(currentIssuance.saleCloseDate).format('x'),
          vertical: currentIssuance.vertical,
          tenant: currentIssuance.tenant
        };
        CompliantService.getBQPList(reqObj).then(function (res) {
          res = res.data;
          res.forEach(function (bqp) {
            bqp.label = bqp.internalId + ' ' + bqp.name + ' | ' + bqp.branch + ' (' + bqp.quotaRemaining + ')';
          });
          saleConfig.bqpList = res;
        }).catch(function (err) {
          var errMsg = err.data ? err.data : 'Error. No BQPs found.';
          commonService.showToast(errMsg);
        });
      }

      function saveBQPDetails(currentIssuance) {
        var reqObj = {
          policyDetailsId: currentIssuance.policyDetailsId,
          brokerQualifiedPerson: currentIssuance.bqp
        };
        CompliantService.saveBQPDetails(reqObj).then(function (res) {
          if (res.status === 200) {
            $state.reload();
          }
        }).catch(function (err) {
          commonService.showToast(err.data);
        });
      }

      function clearAutoconfig(code) {
        var fieldConfig = _getConfigObjectFromCode(code);
        if (fieldConfig && fieldConfig.autocompleteConfig) {
          delete fieldConfig.autocompleteConfig.searchText;
        }
      }
    }
  }
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

(function () {

  angular.module('brokerAdmin.policyIssuance').provider('AutoPolicyIssuanceService', AutoPolicyIssuanceService);

  AutoPolicyIssuanceService.$inject = [];

  return AutoPolicyIssuanceService;

  function AutoPolicyIssuanceService() {
    Service.$inject = ['commonService', 'PolicyIssuanceService', 'ninjaCommonDataService', 'MIS_CONSTANTS', '$q', 'FormHelperService', 'PartnerService', '$timeout', '$filter', 'CONSTANTS_LIST', '$window'];

    return {
      $get: Service
    };

    function Service(commonService, PolicyIssuanceService, ninjaCommonDataService, MIS_CONSTANTS, $q, FormHelperService, PartnerService, $timeout, $filter, CONSTANTS_LIST, $window) {
      var currentIssuance = {};
      var saleConfig = {};
      var broker = $window.sessionStorage.getItem('broker');
      saleConfig.productCategories = angular.copy(MIS_CONSTANTS.productCategories);
      saleConfig.insurers = angular.copy(MIS_CONSTANTS.insurers);
      saleConfig.paymentModeRadioConf = angular.copy(MIS_CONSTANTS.paymentModeRadioConf);
      saleConfig.businessTypes = angular.copy(MIS_CONSTANTS.businessTypes);
      saleConfig.paymentModeRadioConf.push({
        text: 'Cash paid at insurer',
        value: 'CASH_PAID_AT_INSURER'
      });
      saleConfig.tenant = [];
      saleConfig.cities = [];
      saleConfig.issuanceList = [];
      saleConfig.paymentStatusList = [];
      saleConfig.vehicleTypes = [];
      saleConfig.planNames = [];
      if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT) {
        var _saleConfig$productCa;

        var turtlemintProductCategories = angular.copy(MIS_CONSTANTS.turtlemintProductCategories);
        (_saleConfig$productCa = saleConfig.productCategories).push.apply(_saleConfig$productCa, _toConsumableArray(turtlemintProductCategories));
      }

      ninjaCommonDataService.getNinjaCommonData().then(function (ninjaFiltersList) {
        saleConfig.vehicleTypes = angular.copy(ninjaFiltersList.vehicleType);
        _initFormLists();
      });

      var generalFields = [{
        name: 'Request Id',
        code: 'requestId',
        type: 'text',
        disabled: true,
        required: true
      }, {
        name: 'Tenant',
        code: 'tenant',
        type: 'select',
        required: true,
        initFn: _tenantChangeFn,
        changeFn: _tenantChangeFn,
        selectConfig: {
          options: saleConfig.tenant,
          valueKey: '_id',
          textKey: '_id'
        }
      }, {
        name: 'Business Type',
        code: 'businessType',
        placeholder: '',
        type: 'select',
        required: true,
        selectConfig: {
          options: saleConfig.businessTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }, {
        name: 'Sale Close Date',
        code: 'saleCloseDate',
        type: 'date',
        required: true,
        initFn: _saleCloseDateChangeFn,
        changeFn: _saleCloseDateChangeFn
      }, {
        name: 'Vehicle Subtype',
        code: 'vehicleSubType',
        type: 'select',
        required: true,
        hide: true,
        selectConfig: {
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      var preliminaryFields = [{
        name: 'Product Category',
        code: '$$vertical',
        type: 'select',
        selectConfig: {
          options: saleConfig.productCategories,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true,
        initFn: _verticalInitFn,
        changeFn: _verticalChangeFn
      }, {
        name: 'Issuance Status',
        code: 'policyIssuanceStatus',
        type: 'select',
        selectConfig: {
          options: saleConfig.issuanceList,
          valueKey: 'value',
          textKey: 'text'
        },
        required: true,
        initFn: _issuanceStatusChangeFn,
        changeFn: _issuanceStatusChangeFn
      }, {
        name: 'Insurer',
        code: 'insurer',
        placeholder: 'Select an insurer',
        type: 'autocomplete',
        required: true,
        initFn: _selectedInsurerInitFn,
        autocompleteConfig: {
          displayKey: 'label',
          valueKey: 'value',
          disabled: false,
          noCache: true,
          querySearch: _searchInsurerFn,
          selectedItemChange: _selectedInsurerChangeFn
        }
      }, {
        name: 'Vehicle Type',
        code: 'vehicleType',
        placeholder: '',
        type: 'select',
        required: true,
        hide: true,
        changeFn: _vehicleTypeChangeFn,
        initFn: _vehicleTypeChangeFn,
        selectConfig: {
          options: saleConfig.vehicleTypes,
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      var partnerFields = [{
        name: 'Partner Name',
        code: 'partnerName',
        placeholder: 'Select a partner',
        type: 'autocomplete',
        hide: false,
        autocompleteConfig: {
          disabled: false,
          noCache: false,
          selectedItemChange: _selectedIntermediaryChangeFn,
          querySearch: _searchIntermediariesFn,
          displayKey: 'dpName',
          valueKey: 'dpName',
          resultTitleKey: 'label',
          resultLabelKey: 'label',
          minLength: 2
        }
      }, {
        name: 'Partner Email',
        code: 'partnerEmail',
        type: 'text',
        readonly: true
      }, {
        name: 'Partner Mobile',
        code: 'partnerMobile',
        type: 'text',
        readonly: true
      }, {
        name: 'DP Number',
        code: 'partnerDpNo',
        type: 'text',
        required: true,
        readonly: true
      }, {
        name: 'Posp Type',
        code: 'pospType',
        type: 'text',
        readonly: true
      }, {
        name: 'Partner Id',
        code: 'partnerId',
        type: 'text',
        readonly: true
      }, {
        name: 'L1 Relationship Manager',
        code: 'L1RelationshipManager',
        type: 'text',
        readonly: true,
        hide: true
      }, {
        name: 'Relationship Manager',
        code: 'relationshipManager',
        type: 'text',
        readonly: true
      }, {
        name: 'Area Manager',
        code: 'areaManager',
        type: 'text',
        readonly: true
      }, {
        name: 'PI Branch Location',
        code: 'piBranchLocation',
        type: 'select',
        selectConfig: {
          options: saleConfig.cities,
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      var policyFields = [{
        name: 'Proposer Phone',
        code: 'proposerMobile',
        type: 'text',
        required: true,
        hide: true
      }, {
        name: 'Proposer Email',
        code: 'proposerEmail',
        type: 'email',
        required: true,
        hide: true
      }, {
        name: 'Date of Birth',
        code: 'proposerDob',
        type: 'date',
        required: true,
        hide: true

      }, {
        name: 'Inception Date',
        code: 'inceptionDate',
        type: 'date',
        required: true,
        hide: true
      }, {
        name: 'Plan name',
        code: 'tmPlanId',
        placeholder: 'Plan Name',
        initFn: _planNameInitFn,
        hide: true,
        required: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItem: saleConfig.selectedPlan,
          selectedItemChange: selectedPlanNameChangeFn,
          querySearch: searchPlanNames,
          displayKey: 'planName',
          valueKey: 'tmPlanId'
        }
      }, {
        name: 'Plan name (custom)',
        code: 'planName',
        placeholder: 'Plan name',
        type: 'text',
        readonly: false,
        hide: true
      }, {
        name: 'Issuance Date',
        code: 'policyIssuanceDate',
        type: 'date',
        hide: true,
        required: true,
        initFn: _issuanceDateChangeFn,
        changeFn: _issuanceDateChangeFn
      }, {
        name: 'Payment Status',
        code: 'paymentStatus',
        type: 'select',
        initFn: _paymentStatusInitFn,
        changeFn: _paymentStatusChangeFn,
        selectConfig: {
          options: saleConfig.paymentStatusList,
          valueKey: 'value',
          textKey: 'text'
        }
      }, {
        name: 'Payment Complete Date',
        code: 'paymentCompleteDate',
        type: 'date',
        hide: true,
        required: true
      }, {
        name: 'Payment verified',
        code: 'paymentVerified',
        type: 'checkbox',
        required: true,
        hide: true,
        initFn: _paymentVerifiedInitFn,
        changeFn: _paymentVerifiedInitFn
      }, {
        name: 'Payment Mode',
        code: 'paymentMode',
        type: 'radio',
        radioConfig: {
          options: saleConfig.paymentModeRadioConf,
          valueKey: 'value',
          textKey: 'text'
        },
        initFn: _paymentModeChangeFn,
        changeFn: _paymentModeChangeFn
      }, {
        name: 'Payment Details Shared',
        code: 'paymentDetailsShared',
        type: 'checkbox',
        changeFn: _paymentDetailsSharedChangeFn,
        initFn: _paymentDetailsSharedChangeFn
      }, {
        name: 'Cheque Details',
        code: 'chequeDetails',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'NEFT Details',
        code: 'neftDetails',
        type: 'textarea',
        textareaConfig: {
          rows: 3
        }
      }, {
        name: 'Payment Link',
        code: 'paymentLink',
        type: 'text',
        placeholder: 'Enter Payment Link'
      }, {
        name: 'Transaction Number',
        code: 'txnNo',
        type: 'text'
      }, {
        name: 'Payment Remarks',
        code: 'paymentRemarks',
        type: 'text',
        required: true,
        hide: true
      }, {
        name: 'Application No.',
        code: 'applicationNumber',
        type: 'text',
        hide: true,
        initFn: _applicationNumberInitFn,
        changeFn: _applicationNumberInitFn
      }, {
        name: 'Application URL',
        code: 'applicationUrl',
        type: 'text',
        hide: true,
        changeFn: _applicationUrlInitFn,
        initFn: _applicationUrlInitFn
      }];

      return {
        //VARS
        currentIssuance: currentIssuance,
        generalFields: generalFields,
        preliminaryFields: preliminaryFields,
        partnerFields: partnerFields,
        policyFields: policyFields,

        //FUNCTIONS
        getIssuance: getIssuance,
        autoParsingValidation: autoParsingValidation,
        update: update
      };

      // PUBLIC FUNCTIONS
      function getIssuance() {
        var returnPromise = $q.defer();
        PolicyIssuanceService.getDefaultBranchIssuance().then(function (res) {
          currentIssuance = res;
          currentIssuance.paymentStatus = 'COMPLETED';
          returnPromise.resolve(currentIssuance);
        }).catch(function (err) {
          returnPromise.reject(err.data);
        });
        _initFormLists();
        return returnPromise.promise;
      }

      function autoParsingValidation(formData) {
        var reqUrl = '/ninja/auto-parsing/valid';
        var reqObj = formData;

        return commonService.makeHttpCall(reqUrl, reqObj, 'POST', false, false, true);
      }

      function update(currentIssuance) {
        var returnPromise = $q.defer();
        if (currentIssuance.vertical === 'HEALTH') {
          currentIssuance.inspectionRequired = 'NOT_REQUIRED';
        }
        PolicyIssuanceService.update(currentIssuance).then(function (currentIssuance) {
          returnPromise.resolve(currentIssuance);
        }).catch(function (err) {
          return returnPromise.reject(err);
        });
        return returnPromise.promise;
      }

      // PRIVATE FUNCTIONS
      function _initFormLists() {
        PolicyIssuanceService.getTenants().then(function (res) {
          _updateSaleConfig(res, 'tenant');
          return PolicyIssuanceService.getStatusList('payment');
        }).then(function (paymentRes) {
          _updateSaleConfig(paymentRes, 'paymentStatusList');
          return PolicyIssuanceService.getStatusList('issuance');
        }).then(function (issuanceRes) {
          _updateSaleConfig(issuanceRes, 'issuanceList');
          return PartnerService.getCities();
        }).then(function (res) {
          saleConfig.cities.splice(0, saleConfig.cities.length);
          for (var idx = 0; idx < res.length; idx++) {
            var city = res[idx];
            city.code = city.name.toLowerCase();
            saleConfig.cities.push(city);
          }
        });
      }

      function _dateChangeValidation(model) {
        var policyIssuanceDate = model.policyIssuanceDate;
        var minPolicyIssuanceDate = model.saleCloseDate ? new Date(model.saleCloseDate) : new Date();
        if (policyIssuanceDate && moment(policyIssuanceDate).isBefore(minPolicyIssuanceDate, 'day')) {
          model.policyIssuanceDate = undefined;
          commonService.showToast('Policy Issuance Date cannot be before Sale Closed Date');
        }
      }

      function _verticalInitFn(selectedItem, model) {
        if (selectedItem) {
          if (selectedItem === 'LIFE' || selectedItem === 'TERM') {
            _showHideField('vehicleType', true);
            _showHideField('applicationUrl', false);
            _showHideField('applicationNumber', false);
            _showHideField('vehicleSubType', true);
            _showHideField('proposerMobile', true);
            _showHideField('proposerEmail', true);
            _showHideField('proposerDob', true);
            _showHideField('inceptionDate', true);
            _showHideField('tmPlanId', true);
            _showHideField('planName', true);
            model.$$vertical = 'LIFE';
          } else if (selectedItem === 'HEALTH') {
            _showHideField('vehicleType', true);
            _showHideField('vehicleSubType', true);
            _showHideField('applicationUrl', true);
            _showHideField('applicationNumber', true);
            _showHideField('proposerMobile', false);
            _showHideField('proposerEmail', false);
            _showHideField('proposerDob', false);
            _showHideField('inceptionDate', false);
            _showHideField('tmPlanId', false);
            _showHideField('planName', false);
            _markFieldRequired('planName', true);
            _markFieldRequired('proposerMobile', true);
            _markFieldRequired('proposerEmail', true);
            _markFieldRequired('proposerDob', true);
            _markFieldRequired('inceptionDate', true);
          } else if (selectedItem === 'FW' || selectedItem === 'CV' || selectedItem === 'TW') {
            _showHideField('vehicleType', false);
            _showHideField('applicationUrl', true);
            _showHideField('applicationNumber', true);
            _showHideField('proposerMobile', true);
            _showHideField('proposerEmail', true);
            _showHideField('proposerDob', true);
            _showHideField('inceptionDate', true);
            _showHideField('tmPlanId', true);
            _showHideField('planName', true);
          } else {
            _showHideField('vehicleType', true);
            _showHideField('vehicleSubType', true);
            _showHideField('applicationUrl', true);
            _showHideField('applicationNumber', true);
            _showHideField('proposerMobile', true);
            _showHideField('proposerEmail', true);
            _showHideField('proposerDob', true);
            _showHideField('inceptionDate', true);
            _showHideField('tmPlanId', true);
            _showHideField('planName', true);
          }

          var vehicleTypeList = void 0;
          if (selectedItem === 'TW') {
            model.vehicleType = selectedItem;
            FormHelperService.setAssignedVertical(selectedItem);
            vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical([selectedItem]);
            var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
            vehicleTypeConfig.selectConfig.options = vehicleTypeList;
            _vehicleTypeChangeFn(model.vehicleType, model);
          }

          if (selectedItem === 'FW') {
            FormHelperService.setAssignedVertical('FW');
            _setVehicleType(['FW', 'CV'], model);
            _vehicleTypeChangeFn(model.vehicleType, model);
          } else if (selectedItem === 'CV') {
            FormHelperService.setAssignedVertical('CV');
            if (model.vertical === 'CV') {
              vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(['CV']);
              var _vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
              _vehicleTypeConfig.selectConfig.options = vehicleTypeList;
              model.$$vertical = 'FW';
              FormHelperService.setAssignedVertical('FW');
            }
          }
        }
      }

      function _verticalChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.vehicleType = null; // Reset Vehicle type on verticle change
          var fieldConfig = _getConfigObjectFromCode('insurer');
          delete fieldConfig.autocompleteConfig.searchText;
          delete model.insurer;
          delete model.vehicleSubType;
          delete model.$$assignedVertical;
          model.vertical = model.$$vertical;
        }
        _verticalInitFn(selectedItem, model);
      }

      function _issuanceStatusChangeFn(selectedItem, model) {
        if (selectedItem === 'ISSUED') {
          _showHideField('policyIssuanceDate', false);
          _markFieldRequired('paymentStatus', true);
          if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
            _showHideField('applicationNumber', false);
          } else {
            _showHideField('applicationNumber', true);
          }
        } else {
          _showHideField('policyIssuanceDate', true);
          _markFieldRequired('paymentStatus', false);
        }
      }

      function _setVehicleType(verticalList, model) {
        var vehicleTypeList = void 0;
        vehicleTypeList = ninjaCommonDataService.getVehicleTypeListByVertical(verticalList);
        var vehicleTypeConfig = _getConfigObjectFromCode('vehicleType');
        vehicleTypeConfig.selectConfig.options = vehicleTypeList;
        var selectConfig = vehicleTypeConfig.selectConfig;
        var foundFlag = false;
        for (var idx = 0; idx < selectConfig.options.length; idx++) {
          var option = selectConfig.valueKey ? selectConfig.options[idx][selectConfig.valueKey] : selectConfig.options[idx];
          if (option === model.vehicleType) {
            foundFlag = true;
          }
        }
        if (!foundFlag) {
          model.vehicleType = 'CAR';
        }
      }

      function _vehicleTypeChangeFn(selectedItem, model) {
        if (selectedItem) {
          for (var idx = saleConfig.vehicleTypes.length - 1; idx >= 0; idx--) {
            var vehicleType = saleConfig.vehicleTypes[idx];
            if (vehicleType.code === selectedItem) {
              var assignedVertical = vehicleType.verticalCode;
              model.$$assignedVertical = assignedVertical;
              FormHelperService.setAssignedVertical(assignedVertical);
              break;
            }
          }
          if (selectedItem === 'CAR') {
            model.vehicleSubType = 'CAR';
          }
          _showHideField('vehicleSubType', false);
          _setVehicleSubType(model);
        }
      }

      function _setVehicleSubType(model) {
        var vehicleTypeObj = saleConfig.vehicleTypes && saleConfig.vehicleTypes.find(function (ele) {
          if (ele.code === model.vehicleType) {
            return ele;
          }
        });
        var vehicleSubTypeConfig = _getConfigObjectFromCode('vehicleSubType');
        vehicleSubTypeConfig.selectConfig.options = vehicleTypeObj.vehicleSubType;
      }

      function _tenantChangeFn(selectedItem) {
        if (selectedItem === 'turtlemint') {
          _markFieldRequired('partnerName', true);
        } else {
          _markFieldRequired('partnerName', false);
        }
      }

      function _saleCloseDateChangeFn(selectedItem, model) {
        _dateChangeValidation(model);
      }

      function _searchInsurerFn(searchKey, model, config) {
        var deferred = $q.defer();
        var insurers = [];
        insurers = angular.copy(MIS_CONSTANTS.insurers);
        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          insurers = angular.copy(MIS_CONSTANTS.lifeInsurers);
        }
        if (!insurers || insurers && insurers.length < 1) {
          $timeout(function () {
            delete config.autocompleteConfig.searchText;
          }, 2000);
        }

        if (searchKey) {
          var results = $filter('filter')(insurers, searchKey);
          deferred.resolve(results);
        } else {
          deferred.resolve(insurers);
        }

        return deferred.promise;
      }

      function _selectedInsurerChangeFn(selectedItem, model) {
        if (selectedItem) {
          model.insurer = selectedItem.value;
          getProductUINMasters(model);
        } else {
          delete model.insurer;
        }
      }

      function _planNameInitFn(selectedItem, model) {
        $timeout(function () {
          var tmPlanIdConfig = _getConfigObjectFromCode('tmPlanId');
          tmPlanIdConfig.autocompleteConfig.searchText = model.planName;
        }, 400);
        if (!selectedItem || selectedItem === 'others') {
          _showHideField('planName', false);
        } else {
          _showHideField('planName', true, true);
        }
      }

      function selectedPlanNameChangeFn(selectedItem, model) {
        if (selectedItem) {
          var planName = _getConfigObjectFromCode('planName');
          if (selectedItem.tmPlanId === 'others') {
            delete currentIssuance.planName;
            delete currentIssuance.productUIN;
            planName.hide = false;
            currentIssuance.tmPlanId = selectedItem.tmPlanId;
          } else {
            planName.hide = true;
            currentIssuance.planName = selectedItem.planName;
            currentIssuance.tmPlanId = selectedItem.tmPlanId;
            currentIssuance.productUIN = selectedItem.productUIN;
          }
        } else if (!model.tmPlanId) {
          delete currentIssuance.tmPlanId;
          delete currentIssuance.planName;
          delete currentIssuance.productUIN;
        }
        // TODO: This code is just a temporary fix to allow 'planName' to be editable for TERM and LIFE.
        if (selectedItem.tmPlanId === 'others') {
          _markFieldDisabled('planName', false);
        } else {
          _markFieldDisabled('planName', true);
        }
        //©
      }

      function searchPlanNames(searchKey, model) {
        var deferred = $q.defer();
        var planNames = angular.copy(saleConfig.planNames);
        if (searchKey) {
          var results = $filter('filter')(planNames, searchKey);
          deferred.resolve(results);
        } else {
          _clearPlanNames(model);
          deferred.resolve(planNames);
        }
        return deferred.promise;
      }

      function _clearPlanNames(model) {
        delete model.planName;
        delete model.tmPlanId;
        delete model.productUIN;
      }

      function getProductUINMasters(currentIssuance) {
        if (currentIssuance.$$vertical === 'LIFE' || currentIssuance.$$vertical === 'TERM' || currentIssuance.$$vertical === 'HEALTH') {
          var returnPromise = $q.defer();
          var reqUrl = '/getplannames';
          var policyTypeText = currentIssuance.policyType;
          if (policyTypeText) {
            policyTypeText = policyTypeText.toLowerCase();
          }
          var reqObj = {
            insurer: currentIssuance.insurer,
            productCategory: currentIssuance.vertical
          };
          if (currentIssuance.$$vertical === 'LIFE') {
            reqObj.plantype = policyTypeText;
          }
          commonService.makeHttpCall(reqUrl, reqObj, 'GET', false, false, true).then(function (res) {
            returnPromise.resolve(res.data);
            // saleConfig.turtlemintTenant = res.data;
            saleConfig.planNames.splice(0, saleConfig.planNames.length);
            for (var idx = res.data.length - 1; idx >= 0; idx--) {
              saleConfig.planNames.push(res.data[idx]);
            }
          }).catch(function (err) {
            returnPromise.reject(err);
          });
        }
        //return returnPromise.promise;
      }

      function _selectedInsurerInitFn(selectedItem, model) {
        if (selectedItem) {
          model.insurer = selectedItem;
          getProductUINMasters(model);
        } else {
          delete model.insurer;
        }
      }

      function _searchIntermediariesFn(searchKey, model) {
        var deferred = $q.defer();
        if (searchKey && model.tenant && model.saleCloseDate) {
          var reqObj = {
            channel: 'partner',
            tenant: model.tenant,
            saleDate: moment(model.saleCloseDate).format('x'),
            query: searchKey
          };
          var promise = commonService.makeHttpCall('/getpartnersinfo', reqObj, 'GET', false, false, false);

          promise.then(function (res) {
            if (res.data && res.data.status === 'SUCCESS') {
              saleConfig.intermediaries = res.data.data || [];

              deferred.resolve(saleConfig.intermediaries);
            } else {
              _clearPartnerData(model);
              deferred.reject(true);
            }
          });
        } else {
          _clearPartnerData(model);
          deferred.reject(true);
        }
        return deferred.promise;
      }

      function _clearPartnerData(model) {
        if (model.pospType) {
          model.partnerId = ' ';
          delete model.pospType;
          delete model.partnerDpNo;
          delete model.partnerName;
          delete model.partnerEmail;
          delete model.partnerMobile;
          delete model.relationshipManager;
          delete model.areaManager;
          delete model.L1RelationshipManager;
          delete model.piBranchLocation;
          delete model.partnerBranchLocation;
          _showHideField('L1RelationshipManager', true);
        }
      }

      function _selectedIntermediaryChangeFn(selectedItem, model) {
        if (selectedItem) {
          var formData = {
            partnerId: selectedItem.dpLoginId,
            vertical: model.vertical
          };
          FormHelperService.isACertifedPartner(formData).then(function (res) {
            var isCertified = res.data.data.allowedToDoBusiness;
            if (isCertified === true) {
              model.partnerId = selectedItem.dpLoginId;
              model.pospType = selectedItem.dpType;
              model.partnerDpNo = selectedItem.dpNo;
              model.partnerName = selectedItem.dpName;
              model.partnerEmail = selectedItem.email;
              model.partnerMobile = selectedItem.mobile;
              model.partnerBranchLocation = selectedItem.branchCode;
              _showHideField('L1RelationshipManager', true);

              if (selectedItem.intermediaryLevel === 'partner_level_2') {
                if (selectedItem.supervisor) {
                  model.relationshipManager = selectedItem.supervisor.name;
                }
                if (selectedItem.parentIntermediaryName) {
                  model.L1RelationshipManager = selectedItem.parentIntermediaryName;
                  _showHideField('L1RelationshipManager', false);
                } else {
                  _showHideField('L1RelationshipManager', true);
                }
              } else {
                if (selectedItem.supervisor) {
                  model.relationshipManager = selectedItem.supervisor.name;
                } else if (selectedItem.parentIntermediaryName) {
                  model.relationshipManager = selectedItem.parentIntermediaryName;
                }
              }
              if (selectedItem.am) {
                model.areaManager = selectedItem.am.name;
              }
              model.piBranchLocation = selectedItem.branchLocation;
            } else {
              _clearPartnerData(model);
              commonService.showToast('Entered partner detail is not a Certified partner. Please select a Certified partner');
            }
          }).catch(function () /*err*/{
            commonService.showToast('Could not verify if Partner is certified. Please select a Certified partner');
          });
        } else {
          _clearPartnerData(model);
        }
      }

      function _issuanceDateChangeFn(selectedItem, model) {
        _dateChangeValidation(model);
      }

      function _paymentStatusInitFn(selectedItem, model) {
        if (selectedItem === 'COMPLETED') {
          _markFieldRequired('paymentMode', true);
          _showHideField('paymentVerified', false);
          _showHideField('paymentCompleteDate', false);
        } else {
          _markFieldRequired('paymentMode', false);
          _showHideField('paymentVerified', true);
          _showHideField('paymentCompleteDate', true);
        }

        if (selectedItem === 'REJECTED') {
          _showHideField('paymentRemarks', false);
        } else {
          _showHideField('paymentRemarks', true);
        }
        _showHideField('paymentDetailsShared', false);
        _paymentModeChangeFn(model.paymentMode, model);

        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          _applicationUrlInitFn(model.applicationUrl, model);
          _showHideField('applicationNumber', false);
          _applicationNumberInitFn(model.applicationNumber, model);
        } else {
          _showHideField('applicationNumber', true);
        }
      }

      function _paymentStatusChangeFn(selectedItem, model) {
        if (selectedItem !== 'COMPLETED') {
          delete model.paymentVerified;
          delete model.paymentCompleteDate;
        }
        _paymentStatusInitFn(selectedItem, model);
      }

      function _paymentVerifiedInitFn(selectedItem, model) {
        if (model.vertical === 'LIFE' || model.vertical === 'TERM') {
          _applicationUrlInitFn(model.applicationUrl, model);
          _applicationNumberInitFn(model.applicationNumber, model);
        }
      }

      function _paymentModeChangeFn(selectedItem, model) {
        var chequeDetailsFieldConfig = _getConfigObjectFromCode('chequeDetails');
        var neftDetailsFieldConfig = _getConfigObjectFromCode('neftDetails');

        if (model.paymentStatus === 'COMPLETED') {
          switch (selectedItem) {
            case 'CHEQUE':
              chequeDetailsFieldConfig.required = true;
              neftDetailsFieldConfig.required = false;
              break;
            case 'NEFT':
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = true;
              break;
            case 'ONLINE':
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = false;
              break;
            default:
              chequeDetailsFieldConfig.required = false;
              neftDetailsFieldConfig.required = false;
          }
        } else {
          chequeDetailsFieldConfig.required = false;
          neftDetailsFieldConfig.required = false;
        }
      }

      function _paymentDetailsSharedChangeFn(selectedItem, model) {
        if (selectedItem) {
          _showHideField('chequeDetails', true);
          _showHideField('neftDetails', true);
          _showHideField('paymentLink', true);
        } else {
          _showHideField('chequeDetails', false);
          _showHideField('neftDetails', false);
          _showHideField('paymentLink', false);
          _paymentStatusChangeFn(model.paymentStatus, model);
        }
      }

      function _applicationNumberInitFn(selectedItem, model) {
        if (model.source === 'BRANCH') {
          _markFieldRequired('applicationNumber', true);
        }
      }

      function _applicationUrlInitFn(selectedItem, model) {
        if (model.paymentStatus === 'COMPLETED' && model.paymentVerified) {
          _markFieldRequired('applicationUrl', true);
        } else {
          _markFieldRequired('applicationUrl', false);
        }
      }

      // HELPER FUNCTIONS
      function _updateSaleConfig(list, key) {
        saleConfig[key].splice(0, saleConfig[key].length);
        for (var idx = list.length - 1; idx >= 0; idx--) {
          saleConfig[key].push(list[idx]);
        }
      }

      function _getConfigObjectFromCode(code) {
        for (var idx = generalFields.length - 1; idx >= 0; idx--) {
          var config = generalFields[idx];
          if (config.code === code) {
            return config;
          }
        }

        for (var _idx = preliminaryFields.length - 1; _idx >= 0; _idx--) {
          var _config = preliminaryFields[_idx];
          if (_config.code === code) {
            return _config;
          }
        }

        for (var _idx2 = partnerFields.length - 1; _idx2 >= 0; _idx2--) {
          var _config2 = partnerFields[_idx2];
          if (_config2.code === code) {
            return _config2;
          }
        }
        for (var _idx3 = policyFields.length - 1; _idx3 >= 0; _idx3--) {
          var _config3 = policyFields[_idx3];
          if (_config3.code === code) {
            return _config3;
          }
        }

        return {};
      }

      function _markFieldRequired(fieldCode, required) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toRequired = required ? true : false;
        fieldConfig.required = toRequired;
      }

      function _showHideField(fieldCode, hide, dontDelete) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toHide = hide ? true : false;
        if (fieldConfig) {
          fieldConfig.hide = toHide;
          if (toHide && !dontDelete) {
            delete currentIssuance[fieldConfig.code];
          }
        }
      }

      function _markFieldDisabled(fieldCode, disabled) {
        var fieldConfig = _getConfigObjectFromCode(fieldCode);
        var toDisabled = disabled ? true : false;

        fieldConfig.disabled = toDisabled;
        fieldConfig.readonly = toDisabled;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.policyIssuance').controller('PolicyIssuanceAutoCreateCtrl', controller);

  controller.$inject = ['$scope', 'AutoPolicyIssuanceService', 'AuthService', 'PolicyIssuanceService', 'issuance', '$state', 'commonService', '$anchorScroll', 'TrackingService', '$mdToast', '$mdBottomSheet'];

  function controller($scope, AutoPolicyIssuanceService, AuthService, PolicyIssuanceService, issuance, $state, commonService, $anchorScroll, TrackingService, $mdToast, $mdBottomSheet) {
    //Implementation
    var ViewModel = $scope;
    var mxpData = void 0;
    var eventPrefix = 'AUTO_PARSE_FORM';
    ViewModel.currentIssuance = {};
    ViewModel.editMode = AuthService.hasPermission('bi-create');
    ViewModel.goTo = goTo;
    ViewModel.showDocuments = false;
    ViewModel.isMobile = commonService.isMobile();
    ViewModel.toggleShowDocuments = toggleShowDocuments;
    ViewModel.openSectionNav = openSectionNav;

    var navClicked = false;
    $scope.$on('duScrollspy:becameActive', function () {
      if (navClicked && angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
      }
      navClicked = true;
    });

    $scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
      if (angular.element(document).find('md-dialog').length > 0) {
        $mdDialog.hide();
        event.preventDefault();
      }

      if (angular.element(document).find('md-bottom-sheet').length > 0) {
        $mdBottomSheet.cancel();
        event.preventDefault();
      }
    });

    var readOnlyActions = [{
      type: 'button',
      name: 'Close',
      action: _onCancelAction,
      permissionCode: 'bi-detail-read',
      disabled: false
    }];

    var editActions = [{
      type: 'button',
      name: 'Save Issuance',
      action: _saveCurrentIssuance,
      permissionCode: 'bi-create',
      disabled: !ViewModel.editMode
    }, {
      type: 'button',
      name: 'Close',
      action: _onCancelAction,
      permissionCode: 'bi-create',
      disabled: false
    }];

    init();

    function init() {
      ViewModel.currentIssuance = issuance;
      ViewModel.editMode = AuthService.hasPermission('bi-create');
      ViewModel.navbarConfig = {
        goBackState: 'bi-list',
        vertical: 'issuance',
        mode: 'EDIT',
        title: ViewModel.currentIssuance.requestId
      };
      if (ViewModel.editMode) {
        _setNavbarConfig('EDIT');
      } else {
        _setNavbarConfig('READ_ONLY');
      }
      mxpData = {
        policyIssuanceId: ViewModel.currentIssuance.policyIssuanceId,
        requestId: ViewModel.currentIssuance.requestId || undefined,
        vertical: ViewModel.currentIssuance.vertical
      };
      _initFormConfigs();
      _setFileConfig();
    }

    function _setNavbarConfig(mode) {
      switch (mode) {
        case 'EDIT':
          ViewModel.navbarConfig.actions = angular.copy(editActions);
          ViewModel.navbarConfig.subtitle = '(Edit Mode)';
          _initManualModeAction();
          break;
        case 'READ_ONLY':
          ViewModel.navbarConfig.actions = angular.copy(readOnlyActions);
          ViewModel.navbarConfig.subtitle = '(View Mode)';
          break;
        default:
          ViewModel.navbarConfig.actions = readOnlyActions;
          ViewModel.navbarConfig.subtitle = '(View Mode)';
      }
    }

    function _initManualModeAction() {
      if (['HEALTH'].includes(ViewModel.currentIssuance.$$vertical)) {
        var manualModeAction = {
          type: 'button',
          name: 'Manual Mode',
          action: _goToManualMode,
          permissionCode: 'bi-create',
          disabled: !ViewModel.editMode
        };
        ViewModel.navbarConfig.actions.unshift(manualModeAction);
      }
    }

    function _initFormConfigs() {
      ViewModel.preliminaryFields = AutoPolicyIssuanceService.preliminaryFields;
      ViewModel.generalFields = AutoPolicyIssuanceService.generalFields;
      ViewModel.partnerFields = AutoPolicyIssuanceService.partnerFields;
      ViewModel.policyFields = AutoPolicyIssuanceService.policyFields;
    }

    function _setFileConfig() {
      ViewModel.fileConfig = {
        identifiers: {
          policyIssuanceId: ViewModel.currentIssuance.policyIssuanceId,
          requestId: PolicyIssuanceService.findRequestId(ViewModel.currentIssuance),
          clientUid: PolicyIssuanceService.findRequestId(ViewModel.currentIssuance),
          leadId: ViewModel.currentIssuance.leadId,
          vertical: ViewModel.currentIssuance.vertical
        },
        tagOptions: {
          tagging: true
        },
        options: {
          $$viewMode: false,
          preUploadValidator: preUploadValidator,
          uploadCallBackFn: uploadCallBackFn,
          deleteCallBackFn: deleteCallBackFn,
          changeTagCallBackFn: changeTagCallBackFn
        },
        eventTrackingData: {
          module: eventPrefix,
          mxpData: mxpData
        }
      };
      _setFilePermissions(ViewModel.editMode);
    }

    function _setFilePermissions(editMode) {
      var filePermissions = {
        view: AuthService.hasPermission('bi-file-view'),
        download: AuthService.hasPermission('bi-file-download'),
        upload: AuthService.hasPermission('bi-file-upload'),
        delete: editMode && AuthService.hasPermission('bi-file-delete'),
        changeTag: AuthService.hasPermission('bi-file-changetag')
      };

      filePermissions.options = filePermissions.view || filePermissions.download || filePermissions.upload || filePermissions.delete || filePermissions.changeTag;
      ViewModel.fileConfig.options.filePermissions = filePermissions;
    }

    function preUploadValidator(data) {
      var tag = data.formDataObj.tag;
      var fileType = data.file.type;
      var policyDocumentCount = _findNoOfDocsByTag('POLICY');

      if ('POLICY' === tag && 'application/pdf' !== fileType) {
        commonService.showToast('Error! Upload PDF document of policy copy or choose Manual Mode');
        TrackingService.pushToGTMDatalayer(eventPrefix + '_POLICY_PDF_ERROR', 'track_event', mxpData);
        return false;
      }

      if (policyDocumentCount > 0 && tag === 'POLICY') {
        commonService.showToast('Error! Only 1 policy copy can be uploaded. Delete existing policy copy to upload another file.');
        TrackingService.pushToGTMDatalayer(eventPrefix + '_POLICY_COUNT_ERROR', 'track_event', mxpData);
        return false;
      }

      return true;
    }

    function uploadCallBackFn(newFile) {
      if (newFile.tag === 'POLICY') {
        ViewModel.currentIssuance.fileId = newFile.processInfo.pid;
        ViewModel.currentIssuance.policyUrl = newFile.processInfo.pid;
        PolicyIssuanceService.syncFileMIS(ViewModel.currentIssuance.policyIssuanceId, ViewModel.currentIssuance.fileId);
      }
    }

    function deleteCallBackFn(deletedFile) {
      var documentCount = _findNoOfDocsByTag(deletedFile.tag);
      if (deletedFile.tag === 'POLICY' && documentCount === 0) {
        delete ViewModel.currentIssuance.fileId;
      }
    }

    function changeTagCallBackFn(fileItem, oldTag) {
      if (fileItem.tag === 'POLICY') {
        ViewModel.currentIssuance.fileId = fileItem.processInfo.pid;
      } else if (oldTag === 'POLICY' && fileItem.tag !== 'POLICY') {
        var count = _findNoOfDocsByTag('POLICY');
        if (count < 1) {
          delete ViewModel.currentIssuance.fileId;
        }
      }
    }

    function _onCancelAction() {
      TrackingService.pushToGTMDatalayer(eventPrefix + '_CANCEL', 'track_event', mxpData);
      goTo(ViewModel.navbarConfig.goBackState);
    }

    function _goToManualMode() {
      var newBIData = commonService.getItemSession('pi', 'newBI');
      newBIData.autoParsingEnabled = false;
      commonService.setItemSession('pi', 'newBI', newBIData);
      _goToAddBranchIssuance();
    }

    function _goToAddBranchIssuance() {
      TrackingService.pushToGTMDatalayer(eventPrefix + '_MANUAL_MODE', 'track_event', mxpData);
      goTo('bi-create');
    }

    function goTo(page) {
      $state.go(page);
    }

    function _saveCurrentIssuance() {
      if (!ViewModel.currentIssuanceForm.$dirty) {
        commonService.showToast('No changes to save');
        return;
      }

      if (ViewModel.currentIssuanceForm.$invalid) {
        angular.forEach(ViewModel.currentIssuanceForm.$error.required, function (field) {
          field.$setDirty();
          $anchorScroll(field.$name);
        });
        commonService.showToast('Please fill the mandatory fields.');
        return;
      }

      var formValidity = _findFormValidity(ViewModel.currentIssuance);
      if (!formValidity) {
        _displayValidityError(ViewModel.currentIssuance);
        return;
      }

      AutoPolicyIssuanceService.update(ViewModel.currentIssuance).then(function () /*currentIssuance*/{
        var msg = 'Policy Issuance entry saved successfully. We will notify you if anything required from your side.';
        $mdToast.show($mdToast.simple().textContent(msg).action('X').position('bottom right').toastClass('toast-autoparse-success').hideDelay(10000));
        TrackingService.pushToGTMDatalayer(eventPrefix + '_SAVE_SUCCESS', 'track_event', mxpData);
        goTo('bi-list');
      }).catch(function () /*err*/{
        commonService.showToast('Issuance update failed!');
      });
    }

    function _findFormValidity(currentIssuance) {
      delete currentIssuance.$$policyNotUploaded;
      delete currentIssuance.$$statusNotPossible;
      delete currentIssuance.$$paymentProofNotUploaded;

      currentIssuance.$$policyNotUploaded = true;
      if (_findNoOfDocsByTag('POLICY') > 0) {
        currentIssuance.$$policyNotUploaded = false;
      }

      if (currentIssuance.policyIssuanceStatus === 'ISSUED' && currentIssuance.paymentStatus !== 'COMPLETED') {
        ViewModel.currentIssuance.$$statusNotPossible = true;
      }

      if (currentIssuance.policyIssuanceStatus === 'ISSUED' && currentIssuance.paymentStatus === 'COMPLETED' && !currentIssuance.paymentVerified) {
        currentIssuance.$$statusNotPossible = true;
      }

      if ((currentIssuance.paymentStatus === 'COMPLETED' && (currentIssuance.vertical === 'LIFE' || currentIssuance.vertical === 'HEALTH') || currentIssuance.paymentStatus === 'COMPLETED' && currentIssuance.paymentVerified) && currentIssuance.policyIssuanceStatus !== 'CANCELLED') {
        if (['ONLINE', 'CASH_PAID_AT_INSURER'].includes(currentIssuance.paymentMode)) {
          if (_findNoOfDocsByTag('PAYMENT_PROOF') > 0 || currentIssuance.txnNo) {
            currentIssuance.$$paymentProofNotUploaded = false;
          } else {
            currentIssuance.$$paymentProofNotUploaded = true;
          }
        }
      }

      return ViewModel.currentIssuanceForm.$valid && !currentIssuance.$$paymentProofNotUploaded && !currentIssuance.$$policyNotUploaded && !currentIssuance.$$statusNotPossible;
    }

    function _displayValidityError(currentIssuance) {
      var errorMsg = '';

      if (ViewModel.currentIssuance.$$policyNotUploaded) {
        errorMsg += ' Please upload Policy PDF document.';
      }

      if (currentIssuance.$$paymentProofNotUploaded) {
        errorMsg += ' Please enter transaction no. or upload requisite payment proof.';
      }

      if (ViewModel.currentIssuance.$$statusNotPossible) {
        errorMsg = ' Request can\'t be saved. Please check payment and issuance status.';
      }

      commonService.showToast(errorMsg);
    }

    function _findNoOfDocsByTag(tagCode) {
      var count = ViewModel.currentIssuance.$$attachedDocuments.filter(function (ele) {
        return ele.tag === tagCode;
      }).length;
      return count;
    }

    function toggleShowDocuments() {
      ViewModel.showDocuments = !ViewModel.showDocuments;
      var action = ViewModel.showDocuments ? 'FILES' : 'FORM';
      TrackingService.pushToGTMDatalayer(eventPrefix + '_DETAIL_GOTO_' + action, 'track_event', {});
    }

    function openSectionNav() {
      TrackingService.pushToGTMDatalayer(eventPrefix + '_SECTION_NAV_OPEN', 'track_event', {});
      $mdBottomSheet.show({
        templateUrl: 'dev/policy-issuance/templates/pi-auto-section-nav-mob.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true
      }).then(function () {}).catch(function () {});
    }

    //Implementation
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin.policyIssuance').controller('InspectionSubStatusCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'inspectionSubStatusLogs'];

  function controller($scope, $mdDialog, inspectionSubStatusLogs) {
    var ViewModel = $scope;
    ViewModel.cancelDialog = $mdDialog.cancel;
    init();

    function init() {
      ViewModel.inspectionSubStatusLogs = angular.copy(inspectionSubStatusLogs);
      if (inspectionSubStatusLogs && Array.isArray(inspectionSubStatusLogs) && inspectionSubStatusLogs.length > 0) {
        ViewModel.showTable = true;
      } else {
        ViewModel.showTable = false;
      }
    }
  }
})();
'use strict';

(function () {

	var renewalConstants = {};

	var pageTabs = [{ 'value': 'NO_RENEWAL_QUOTE', 'label': 'NO RENEWAL QUOTE', 'uri': 'renewals-noquote' }, { 'value': 'RENEWALS_READY', 'label': 'RENEWALS READY', 'uri': 'renewals-ready' }, { 'value': 'ALL_RENEWALS', 'label': 'ALL RENEWALS', 'uri': 'renewals-all' }];

	renewalConstants.pageTabs = pageTabs;

	var tableColumns = [{ 'value': 'policyNumber', 'label': 'Policy No' }, { 'value': '', 'label': 'DP Name' }, { 'value': '', 'label': 'DP Number' }, { 'value': 'proposerDetails.fName', 'label': 'Customer Name' }, { 'value': 'policyRisk.registrationNo', 'label': 'Registration No' }, { 'value': 'productCategory', 'label': 'Category' }, { 'value': 'productName', 'label': 'Product Name' }, { 'value': 'insurer', 'label': 'Insurer' }, { 'value': 'status', 'label': 'Status' }, { 'value': 'daysLeft', 'label': 'Days Left' }, { 'value': 'renewalQuote.premiumAmount', 'label': 'Renewal Premium' }, { 'value': 'renewalQuote.ncb', 'label': 'NCB %' }, { 'value': '', 'label': 'Renewal Notice' }, { 'value': 'channelType', 'label': 'Channels' }];

	renewalConstants.tableColumns = tableColumns;

	// Renewal Statuses
	var renewalStatuses = [{ 'value': 'NO_RENEWAL_QUOTE', 'label': 'No Renewal Quote', 'shortname': 'No Renewal Quote' }, { 'value': 'RENEWAL_READY', 'label': 'Renewal Ready', 'shortname': 'Ready' }, { 'value': 'RENEWAL_FAILURE', 'label': 'Renewal Failure', 'shortname': 'Failure' }, { 'value': 'RENEWAL_PENDING_VERIFICATION', 'label': 'Success (Pending)', 'shortname': 'Success (Pending)' }, { 'value': 'RENEWAL_SUCCESS', 'label': 'Renewal Success', 'shortname': 'Success' }];

	renewalConstants.renewalStatuses = renewalStatuses;

	renewalConstants.categories = [{ 'value': 'TW', 'text': 'TW' }, { 'value': 'FW', 'text': 'Motor' }, { 'value': 'HEALTH', 'text': 'Health' }, { 'value': 'TERM', 'text': 'Life' }, { 'value': 'CPM', 'text': 'CPM' }, { 'value': 'HOUSEHOLDER', 'text': 'Householder\'s' }, { 'value': 'LIABILITY', 'text': 'Liability' }, { 'value': 'PERSONAL_ACCIDENT', 'text': 'PA' }, { 'value': 'SFSP', 'text': 'Standard Fire & Special Perils' }, { 'value': 'TRAVEL', 'text': 'Travel' }, { 'value': 'WC', 'text': 'WC' }, { 'value': 'BURGLARY', 'text': 'Burglary' }, { 'value': 'FIRE', 'text': 'Fire' }, { 'value': 'MI', 'text': 'Money Insurance' },
	// { 'value': 'car', 'text': 'Pvt Car' },
	// { 'value': 'gcv', 'text': 'GCV' },
	// { 'value': 'pcv', 'text': 'PCV' },
	{ 'value': 'MISCD', 'text': 'MISC D' }];

	angular.module('brokerAdmin.Renewals').constant('RENEWAL_CONSTANTS', renewalConstants);
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('NotificationPanelCtrl', NotificationPanelCtrl).directive('notificationPanel', notificationPanel);

  function notificationPanel() {
    var directive = {
      restrict: 'E',
      templateUrl: 'dev/notifications/directives/templates/notification-panel.tpl.html',
      replace: true,
      controller: NotificationPanelCtrl,
      scope: {
        initCallback: '=',
        onSelect: '=',
        close: '='
      }
    };
    return directive;
  }

  NotificationPanelCtrl.$inject = ['CommentService', '$scope', '$state', 'commonService', 'CONSTANTS_LIST', '$stateParams', '$window', 'AuthService', '$timeout', '$q', 'PolicyIssuanceService'];
  function NotificationPanelCtrl(CommentService, $scope, $state, commonService, CONSTANTS_LIST, $stateParams, $window, AuthService, $timeout, $q, PolicyIssuanceService) {

    $scope.currentUserId = commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL);
    $scope.currentChatId = $stateParams.id || localStorage.getItem('conversationId');
    var limit = 10;
    var skip;
    /* Disabling infinite scroll for new conversations api. */
    $scope.scroll = {
      busy: true
    };

    $scope.init = init;
    $scope.getMoreConversation = getMoreConversation;
    $scope.goToConversation = goToConversation;
    $scope.searchConversations = searchConversations;
    $scope.hasPermission = AuthService.hasPermission;
    $scope.hasViewPermission = AuthService.hasViewPermission;
    $scope.config = {
      mode: 'conversations'
    };
    var currentState = $state.current.name;

    init();

    function init() {
      $scope.config = {
        mode: 'conversations'
      };
      $scope.conversations = [];
      $scope.currentUserId = commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL);
      /* Disabling infinite scroll for new conversations api. */
      $scope.scroll = {
        busy: true
      };
      $scope.search = {
        searchKey: '',
        userId: $scope.currentUserId,
        limit: limit
      };
      getConversation();

      if ($scope.close) {
        $scope.showClose = true;
      } else {
        $scope.showClose = false;
      }
    }

    function getConversation() {
      CommentService.fetchAllConversations($scope.currentUserId).then(function (res) {
        res = res.map(setConversation);
        if ($scope.conversations.length === 0) {
          if (res.length > 0) {
            $scope.conversations = res;
            setDefaultConversation($scope.conversations);
          } else {
            if (currentState === CONSTANTS_LIST.CONVERSATION_STATE) {
              $state.transitionTo('/');
            }
          }
        } else {
          if (res.length > 0) {
            res.forEach(function (convo) {
              $scope.conversations.push(convo);
            });
          }
        }
        $scope.notificationCount = getUnreadNotificationCount($scope.conversations);
        $scope.conversations = $scope.conversations.sort(function (convo1, convo2) {
          return +new Date(convo2.lastUnreadComment.createdAt) - +new Date(convo1.lastUnreadComment.createdAt);
        });
      });
    }

    function getUnreadNotificationCount(conversation) {
      var commentCount = 0;
      conversation.forEach(function (element) {
        if (element.unreadCommentCount) {
          commentCount++;
        }
      });
      $scope.$emit('NOTIFICATION_UPDATE', {
        commentCount: commentCount
      });
      return commentCount;
    }

    function transformConversation(convo) {
      var conversation = {
        metaInfo: {
          leadStatus: convo.leadStatus,
          leadStage: convo.leadStage,
          regNo: convo.registrationNumber,
          policyNum: convo.policyNum,
          policyType: convo.policyType,
          planName: convo.planName,
          subPlanName: convo.subPlanName,
          planType: convo.planType,
          insurerName: convo.insurer,
          insurer: convo.insurer,
          customerId: convo.customerId,
          registrationNumber: convo.registrationNumber,
          policyIssuanceStatus: convo.policyIssuanceStatus,
          policyIssuanceId: convo.policyIssuanceId,
          paymentStatus: convo.paymentStatus,
          inspectionStatus: convo.inspectionStatus,
          vertical: convo.vertical,
          reference: convo.requestId,
          partnerName: convo.dp.name,
          partnerDpNo: convo.dp.dpId,
          partnerMobile: convo.dp.mobileNumber,
          partnerEmail: convo.dp.emailId,
          partnerDpId: convo.dp.id,
          model: convo.model,
          make: convo.make,
          leadId: convo.leadId,
          requestId: convo.requestId,
          queue: convo.queue,
          category: convo.category,
          proposerFName: convo.proposerFName,
          proposerLName: convo.proposerLName,
          proposerMobile: convo.proposerMobile,
          proposerEmail: convo.proposerEmail,
          proposerGender: convo.proposerGender,
          proposerDob: convo.proposerDob,
          tenant: convo.tenant
        },
        reference: convo.requestId,
        createdAt: convo.createdOn,
        updatedAt: convo.lastModifiedOn,
        _id: convo._id,
        unreadCommentCount: convo.unreadChatCount,
        lastUnreadComment: {
          _id: convo.latestChat._id,
          createdAt: convo.latestChat.timestamp,
          type: convo.latestChat.documents ? 'file' : 'text',
          content: _extends({}, !convo.latestChat.documents && { text: convo.latestChat.text }, convo.latestChat.documents && { file: { name: convo.latestChat.documents[0].documentName } }),
          sender: {
            id: convo.latestChat.senderDetails && convo.latestChat.senderDetails.id,
            name: convo.latestChat.senderDetails && convo.latestChat.senderDetails.name,
            type: convo.latestChat.senderDetails && convo.latestChat.senderDetails.type,
            email: convo.latestChat.senderDetails && convo.latestChat.senderDetails.id
          }
        }
      };
      return conversation;
    }

    function setConversation(convo) {
      convo = transformConversation(convo);
      if (convo.metaInfo) {
        if (convo.metaInfo.vertical) {
          convo.metaInfo.vertical = convo.metaInfo.vertical.toUpperCase();
        }
        setActiveConversations(convo);
        convo.queue = convo.metaInfo.policyIssuanceId ? 'I' : 'Q';
        convo.chatQueue = convo.queue === 'I' ? 'ISSUANCE' : 'QIS';
        convo.chatStatus = '';
        if (convo.chatQueue === 'ISSUANCE') {
          getIssuanceStatus(convo.metaInfo.policyIssuanceStatus).then(function (res) {
            convo.chatStatus = '| ' + res;
          });
        } else {
          var status = getStatusQIS(convo.metaInfo.leadStatus);
          convo.chatStatus = '| ' + status;
        }
        convo.verticalIcon = getVerticalIcon(convo.metaInfo.vertical);
        convo.referenceId = getReferenceId(convo.metaInfo);
      }
      convo.timeElapsed = checkElapsedTime(convo.createdAt, true);
      convo.lastMsgTime = checkElapsedTime(convo.updatedAt, false);
      convo.createdAt = new Date(convo.createdAt).getTime();
      if (convo.lastUnreadComment && convo.lastUnreadComment.sender) {
        if (convo.lastUnreadComment.sender.type === 'auto') {
          convo.lastUnreadComment.senderType = 'auto';
        } else if (convo.lastUnreadComment.sender.id !== $scope.currentUserId) {
          convo.lastUnreadComment.senderType = 'other';
        } else {
          convo.lastUnreadComment.senderType = 'self';
        }
        convo.lastMsgTime = checkElapsedTime(convo.lastUnreadComment.createdAt, false);
      }
      return convo;
    }

    function setActiveConversations(convo) {
      if (currentState === CONSTANTS_LIST.CONVERSATION_STATE) {
        $scope.conversations.forEach(function (convo) {
          convo.active = false;
        });
        if (convo.reference === $scope.currentChatId) {
          convo.active = true;
        }
      }
    }

    function getIssuanceStatus(status) {
      var newStatus;
      var deferred = $q.defer();
      PolicyIssuanceService.getStatusList('issuance').then(function (res) {
        var issuanceStatusList = res;
        newStatus = issuanceStatusList.find(function (ele) {
          if (ele.value === status) {
            return true;
          }
        });
        newStatus = newStatus.text;
        deferred.resolve(newStatus);
      });
      return deferred.promise;
    }

    function getStatusQIS(status) {
      var leadStatus = CONSTANTS_LIST.QIS_STATUS_MAP[status];
      return leadStatus;
    }

    function getVerticalIcon(vertical) {
      var verticalIcon = '';
      switch (vertical) {
        case 'FW':case 'CAR':case 'CV':
          verticalIcon = '/images/notifications/motor-id.svg';
          break;
        case 'TW':
          verticalIcon = '/images/notifications/tw-id.svg';
          break;
        case 'HEALTH':
          verticalIcon = '/images/notifications/health-id.svg';
          break;
        case 'TERM':case 'LIFE':
          verticalIcon = '/images/notifications/term-id.svg';
          break;
      }
      return verticalIcon;
    }

    function getReferenceId(meta) {
      var referenceId = '';
      switch (meta.vertical) {
        case 'CAR':case 'CV':case 'TW':case 'FW':
          if (meta.make) {
            referenceId = referenceId.concat('' + meta.make);
          }
          if (meta.model) {
            if (meta.make) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.model);
          }
          if (meta.registrationNumber) {
            if (meta.make || meta.model) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.registrationNumber);
          }
          if (meta.regNo && !meta.registrationNumber) {
            if (meta.make || meta.model) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.regNo);
          }
          break;
        case 'HEALTH':
          if (meta.policyType) {
            referenceId = referenceId.concat('' + meta.policyType);
          }
          if (meta.planName) {
            if (meta.policyType) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.planName);
          }
          break;
        case 'LIFE':
          if (meta.policyType) {
            referenceId = referenceId.concat('' + meta.policyType);
          }
          if (meta.planName) {
            if (meta.policyType) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.planName);
          }
          break;
        case 'TERM':
          if (meta.policyType) {
            referenceId = referenceId.concat('' + meta.policyType);
          }
          if (meta.planName) {
            if (meta.policyType) {
              referenceId = referenceId.concat(' | ');
            }
            referenceId = referenceId.concat('' + meta.planName);
          }
          break;
      }
      return referenceId;
    }

    function checkElapsedTime(date, suffix) {
      var now = moment(new Date());
      if (date) {
        return moment(date).from(now, suffix);
      } else {
        return null;
      }
    }

    function setDefaultConversation(conversations) {
      var firstChat;
      if (!localStorage.getItem('conversationId')) {
        firstChat = conversations[0];
        localStorage.setItem('conversationId', firstChat.reference);
        localStorage.setItem(firstChat.reference, btoa($window.unescape(encodeURIComponent(JSON.stringify(firstChat.metaInfo)))));
      }
      if (skip === 0 && typeof $scope.initCallback === 'function') {
        $scope.initCallback(firstChat);
      }
    }

    function getMoreConversation(mode) {
      skip = $scope.conversations.length;
      // $window.console.log('convo more', skip);
      if (mode === 'conversations') {
        getConversation();
      } else if (mode === 'search') {
        searchConversations($scope.search, skip);
      }
    }

    function goToConversation(conversation) {
      $scope.currentChatId = conversation.reference;
      conversation.unreadCommentCount = 0;
      setActiveConversations(conversation);
      $scope.notificationCount = getUnreadNotificationCount($scope.conversations);

      $timeout(function () {
        $scope.onSelect(conversation);
      }, 30);
    }

    function searchConversations(searchObj, skip) {
      if ($scope.config.mode !== 'search') {
        $scope.config.mode = 'search';
      }
      $scope.search.skip = skip;
      if (skip === 0) {
        $scope.conversations = [];
      }
      if (searchObj.searchKey.length >= 3) {
        CommentService.searchConversations(searchObj).then(function (res) {
          res = res.map(setConversation);
          if ($scope.conversations.length === 0) {
            if (res.length > 0) {
              $scope.conversations = res;
            }
          } else {
            if (res.length > 0) {
              res.forEach(function (convo) {
                $scope.conversations.push(convo);
              });
            }
          }
          // $window.console.log('search res',$scope.scroll,skip, res);
        }).catch(function (err) {
          $window.console.log('search err', err);
        });
      }
    }

    $scope.$on('CONVO_READ', function () /*event, data*/{
      init();
    });
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('chatPanelCtrl', chatPanelCtrl).directive('chatPanel', chatPanel);

  function chatPanel() {
    var directive = {
      restrict: 'E',
      templateUrl: 'dev/notifications/directives/templates/chat-panel.tpl.html',
      replace: true,
      controller: chatPanelCtrl,
      scope: {
        reference: '=',
        conversation: '=',
        editMode: '=',
        closeChat: '=',
        config: '=',
        source: '=',
        msgText: '=',
        sendCallback: '='
      }
    };
    return directive;
  }

  chatPanelCtrl.$inject = ['$scope', 'commonService', 'CONSTANTS_LIST', 'GENERAL_CONFIG', 'PolicyIssuanceService', '$q', 'AuthService'];
  function chatPanelCtrl($scope, commonService, CONSTANTS_LIST, GENERAL_CONFIG, PolicyIssuanceService, $q, AuthService) {

    $scope.content = {};
    $scope.comments = [];
    $scope.allowedExtentions = ['jpg', 'png', 'pdf', 'jpeg'];
    $scope.currentUserId = commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL);
    $scope.tagConfig = {
      tagging: false,
      tag: {
        name: 'Comment Admin File',
        code: 'COMMENT_DOCUMENT_ADMIN'
      }
    };
    var commentQueue;
    var convoMeta;
    setConvoMeta();
    $scope.chatId = 'CHAT_WINDOW_' + $scope.reference;
    $scope.getConversationTitle = getConversationTitle;
    $scope.hasPermission = AuthService.hasPermission;
    $scope.hasViewPermission = AuthService.hasViewPermission;
    $scope.salesComment = $scope.hasPermission('chat-unrestricted');

    init();

    function init() {
      setConvoMeta();
      $scope.chatId = 'CHAT_WINDOW_' + $scope.reference;
      $scope.editMode = $scope.hasPermission('chat-unrestricted') || $scope.editMode || false;
      $scope.senderRole = $scope.hasPermission('chat-unrestricted') ? 'sales' : 'admin';

      $scope.messageConfig = {
        name: 'Message',
        code: 'text',
        type: 'textarea',
        placeholder: 'Send a message...',
        hideLabel: true
      };

      openHelpCentreChat($scope.conversation);

      if ($scope.conversation.policyIssuanceId) {
        commentQueue = 'ISSUANCE';
        convoMeta.inspectionStatus = $scope.conversation.inspectionStatus;
        convoMeta.insurer = $scope.conversation.insurer;
        convoMeta.paymentStatus = $scope.conversation.paymentStatus;
        convoMeta.policyIssuanceId = $scope.conversation.policyIssuanceId;
        convoMeta.policyIssuanceStatus = $scope.conversation.policyIssuanceStatus;
        convoMeta.registrationNumber = $scope.conversation.registrationNumber;
        convoMeta.subPlanName = $scope.conversation.subPlanName;
      } else {
        commentQueue = 'QIS';
        convoMeta.customerId = $scope.conversation.customerId;
        convoMeta.insurerName = $scope.conversation.insurerName;
        convoMeta.leadStatus = $scope.conversation.leadStatus;
        convoMeta.planType = $scope.conversation.planType;
        convoMeta.policyNum = $scope.conversation.policyNum;
        convoMeta.regNo = $scope.conversation.regNo;
        convoMeta.leadStage = $scope.conversation.leadStage;
      }
      $scope.commentQueue = commentQueue;

      if ($scope.closeChat) {
        $scope.showClose = true;
      } else {
        $scope.showClose = false;
      }

      if ($scope.msgText !== '') {
        $scope.content.text = $scope.msgText;
      }

      $scope.fileUploadIdentifiers = {
        policyIssuanceId: $scope.conversation.policyIssuanceId,
        requestId: $scope.reference,
        clientUid: $scope.reference,
        leadId: $scope.conversation.leadId,
        vertical: $scope.conversation.vertical
      };
      getConversationTitle();
    }

    function setConvoMeta() {
      convoMeta = {
        category: $scope.conversation.category,
        leadId: $scope.conversation.leadId,
        make: $scope.conversation.make,
        model: $scope.conversation.model,
        partnerDpNo: $scope.conversation.partnerDpNo,
        partnerName: $scope.conversation.partnerName,
        partnerMobile: $scope.conversation.partnerMobile,
        partnerEmail: $scope.conversation.partnerEmail,
        proposerFName: $scope.conversation.proposerFName,
        proposerLName: $scope.conversation.proposerLName,
        proposerMobile: $scope.conversation.proposerMobile,
        proposerEmail: $scope.conversation.proposerEmail,
        proposerGender: $scope.conversation.proposerGender,
        proposerDob: $scope.conversation.proposerDob,
        policyType: $scope.conversation.policyType,
        planName: $scope.conversation.planName,
        requestId: $scope.conversation.requestId,
        reference: $scope.reference,
        vertical: $scope.conversation.vertical,
        tenant: $scope.conversation.tenant,
        broker: $scope.conversation.broker
      };
    }

    function openHelpCentreChat(conversation) {
      var broker = commonService.getPlainCookie('broker');
      var tenant = sessionStorage.getItem('tenant');
      var requestId = conversation.requestId;

      var opsDetails = {
        name: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_FULLNAME) || commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_USERNAME),
        email: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL),
        isSalesUser: AuthService.isSalesUser(),
        isOwner: $scope.editMode,
        tenant: tenant,
        broker: broker
      };

      var data = {
        dpId: conversation.partnerId || conversation.partnerDpId,
        source: $scope.conversation.policyIssuanceId ? 'ADMIN_POLICY_ISSUANCE' : 'ADMIN_QIS',
        vertical: conversation.vertical,
        insurer: conversation.insurer,
        policyIssuanceStatus: conversation.policyIssuanceStatus,
        policyIssuanceId: conversation.policyIssuanceId,
        leadStatus: conversation.leadStatus,
        leadStage: conversation.leadStage
      };

      $scope.iframeSource = GENERAL_CONFIG.HELP_CENTER_BASE_URL + '/ops/chat/' + requestId + '?data=' + encodeURIComponent(JSON.stringify(data)) + '&opsDetails=' + encodeURIComponent(JSON.stringify(opsDetails));
    }

    function getConversationTitle() {
      $scope.chatTitle = '';
      $scope.chatQueue = '';
      $scope.chatStatus = '';
      if ($scope.conversation) {
        if ($scope.conversation.vertical) {
          $scope.conversation.vertical = $scope.conversation.vertical.toUpperCase();
          var referenceId = getReferenceId();
          $scope.chatTitle = $scope.conversation.partnerName ? '' + $scope.conversation.partnerName + referenceId + ' ' : 'Request' + referenceId;
        }
        if (commentQueue === 'ISSUANCE') {
          $scope.chatStatus = 'Issuance Status: ';
          getIssuanceStatus($scope.conversation.policyIssuanceStatus).then(function (res) {
            $scope.chatStatus = $scope.chatStatus.concat('' + res);
          });
        } else if (commentQueue === 'QIS') {
          var status = getStatusQIS($scope.conversation.leadStatus);
          $scope.chatStatus = 'Quote Status: ' + status;
        }
      }
    }

    function getReferenceId() {
      // TODO: Move this logic to backend
      var ref = '';
      switch ($scope.conversation.vertical) {
        case 'CAR':case 'CV':case 'TW':case 'FW':
          if ($scope.conversation.make) {
            ref = ref.concat(' | ' + $scope.conversation.make);
          }
          if ($scope.conversation.model) {
            ref = ref.concat(' | ' + $scope.conversation.model);
          }
          if ($scope.conversation.registrationNumber) {
            ref = ref.concat(' | ' + $scope.conversation.registrationNumber);
          }
          if ($scope.conversation.regNo && !$scope.conversation.registrationNumber) {
            ref = ref.concat(' | ' + $scope.conversation.regNo);
          }

          break;
        case 'HEALTH':
          if ($scope.conversation.policyType) {
            ref = ref.concat(' | ' + $scope.conversation.policyType);
          }
          if ($scope.conversation.planName) {
            ref = ref.concat(' | ' + $scope.conversation.planName);
          }
          break;
        case 'LIFE':
          if ($scope.conversation.policyType) {
            ref = ref.concat(' | ' + $scope.conversation.policyType);
          }
          if ($scope.conversation.planName) {
            ref = ref.concat(' | ' + $scope.conversation.planName);
          }
          break;
        case 'TERM':
          if ($scope.conversation.policyType) {
            ref = ref.concat(' | ' + $scope.conversation.policyType);
          }
          if ($scope.conversation.planName) {
            ref = ref.concat(' | ' + $scope.conversation.planName);
          }
          break;
      }
      return ref;
    }

    function getIssuanceStatus(status) {
      var newStatus;
      var deferred = $q.defer();
      PolicyIssuanceService.getStatusList('issuance').then(function (res) {
        var issuanceStatusList = res;
        newStatus = issuanceStatusList.find(function (ele) {
          if (ele.value === status) {
            return true;
          }
        });
        newStatus = newStatus.text;
        deferred.resolve(newStatus);
      });
      return deferred.promise;
    }

    function getStatusQIS(status) {
      var leadStatus = CONSTANTS_LIST.QIS_STATUS_MAP[status];
      return leadStatus;
    }

    $scope.$watch('reference', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        init();
      }
    }, true);
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.notifications').controller('ConversationCtrl', controller);

  controller.$inject = ['$scope', '$state', 'AuthService', '$stateParams', '$window'];
  function controller($scope, $state, AuthService, $stateParams, $window) {
    var ViewModel = $scope;
    var commentQueue;
    ViewModel.noRequestId = $stateParams.noRequestId;
    ViewModel.editMode = true;
    ViewModel.currentChat = {
      show: false
    };

    ViewModel.initaliseCallback = initaliseCallback;
    ViewModel.onChatSelection = onChatSelection;
    ViewModel.closeConversation = closeConversation;
    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.tenant = sessionStorage.getItem('tenant');

    init();

    function init() {
      $scope.commentSource = 'CONVERSATION_PAGE';
      setConversation();

      $scope.chatConfig = {
        windowType: 'CONVERSATION',
        actions: [{
          type: 'button',
          class: 'chatRecordBtn',
          name: 'Show Record',
          action: _showRecord
        }, {
          type: 'icon',
          class: '',
          action: _closeChatPage,
          name: 'Close',
          iconSrc: '/images/common/close.svg'
        }]
      };
    }

    function setConversation() {
      ViewModel.currentChat.show = false;
      if ($stateParams.id !== undefined) {
        ViewModel.currentChat.id = $stateParams.id;
        localStorage.setItem('conversationId', $stateParams.id);
      } else {
        ViewModel.currentChat.id = localStorage.getItem('conversationId');
      }

      if ($stateParams.metaInfo !== undefined) {
        ViewModel.currentChat.meta = $stateParams.metaInfo;
        localStorage.setItem(ViewModel.currentChat.id, btoa($window.unescape(encodeURIComponent(JSON.stringify($stateParams.metaInfo)))));
      } else {
        var meta;
        var metaSession = localStorage.getItem(ViewModel.currentChat.id);
        if (metaSession) {
          try {
            meta = decodeURIComponent($window.escape(atob(metaSession)));
          } catch (err) {
            meta = metaSession;
            ViewModel.currentChat.meta = JSON.parse(meta);
          }
        }
        ViewModel.currentChat.meta = JSON.parse(meta);
      }

      if ($scope.currentChat.meta) {
        if ($scope.currentChat.meta.policyIssuanceId) {
          commentQueue = 'ISSUANCE';
          $scope.commentSource = 'ADMIN_POLICY_ISSUANCE';
          $scope.closeLink = 'ci-list';
          ViewModel.editMode = ViewModel.currentChat.meta.policyIssuanceStatus !== 'CANCELLED' && ViewModel.currentChat.meta.policyIssuanceStatus !== 'ISSUED' && ViewModel.currentChat.meta.policyIssuanceStatus !== 'CANCELLED_REFUND_AWAITED' && ViewModel.editMode;
        } else {
          commentQueue = 'QIS';
          $scope.closeLink = 'qis-list';
          $scope.commentSource = 'ADMIN_QIS';
        }
        ViewModel.currentChat.show = true;
      }
      $scope.$applyAsync();
    }

    function initaliseCallback(conversation) {
      onChatSelection(conversation);
    }

    function onChatSelection(conversation) {
      ViewModel.currentChat.show = false;
      ViewModel.currentChat.id = conversation.reference;
      ViewModel.currentChat.meta = conversation.metaInfo;
      ViewModel.currentChat.show = true;
      localStorage.setItem('conversationId', ViewModel.currentChat.id);
      localStorage.setItem(ViewModel.currentChat.id, btoa($window.unescape(encodeURIComponent(JSON.stringify(ViewModel.currentChat.meta)))));
      $scope.$applyAsync();
    }

    function _showRecord() {
      switch (commentQueue) {
        case 'ISSUANCE':
          setIssuanceLink($scope.currentChat.meta);
          break;
        case 'QIS':
          setQISLink($scope.currentChat.meta);
          break;
      }
    }

    function setIssuanceLink(meta) {
      $state.go('ci-detail', {
        id: meta.policyIssuanceId
      });
    }

    function setQISLink(meta) {
      var id = meta.qisLeadId;
      if (meta.requestId) {
        id = meta.requestId;
      }
      if (meta.leadStage === 'profile') {
        $state.go('qis-assign-tag', {
          id: id,
          tenant: ViewModel.tenant
        });
      } else {
        $state.go('qis-result', {
          id: id,
          tenant: ViewModel.tenant
        });
      }
    }

    function _closeChatPage() {
      $state.go($scope.closeLink);
    }

    function closeConversation() {
      // console.log('onClose of conversation');
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin').controller('chatPopupBtnCtrl', chatPopupBtnCtrl).directive('chatPopupBtn', chatPopupBtn);

  function chatPopupBtn() {
    var directive = {
      restrict: 'E',
      templateUrl: 'dev/notifications/directives/templates/chat-popup-btn.tpl.html',
      replace: true,
      controller: chatPopupBtnCtrl,
      scope: {
        reference: '=',
        editMode: '=',
        conversation: '=',
        source: '='
      }
    };
    return directive;
  }

  chatPopupBtnCtrl.$inject = ['$scope', '$mdDialog', 'commonService', 'CONSTANTS_LIST', 'AuthService'];
  function chatPopupBtnCtrl($scope, $mdDialog, commonService, CONSTANTS_LIST, AuthService) {

    $scope.hasPermission = AuthService.hasPermission;
    $scope.chatId = 'CHAT_BTN_' + $scope.reference;
    $scope.newMsg = false;
    $scope.currentUserId = commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL);

    $scope.showCommentPopup = function () {
      $scope.newMsg = false;
      $mdDialog.show({
        controller: 'ChatPopupDialogCtrl',
        templateUrl: 'dev/notifications/templates/dialogs/chat-popup.tpl.html',
        clickOutsideToClose: true,
        locals: {
          reference: $scope.reference,
          editMode: $scope.editMode,
          conversation: $scope.conversation,
          source: $scope.source,
          windowType: 'CHAT'
        }
      });
    };
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.notifications').controller('ChatPopupDialogCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'reference', 'editMode', 'conversation', 'source', 'windowType', '$rootScope'];
  function controller($scope, $mdDialog, reference, editMode, conversation, source, windowType, $rootScope) {
    var ViewModel = $scope;
    $scope.conversation = conversation;
    $scope.reference = reference;
    $scope.editMode = editMode;
    $scope.source = source;

    $scope.chatConfig = {
      actions: [{
        type: 'icon',
        class: '',
        action: closePopUp,
        name: 'Close',
        iconSrc: '/images/common/close.svg'
      }]
    };

    $scope.chatConfig.windowType = windowType;
    ViewModel.closePopUp = closePopUp;
    function closePopUp() {
      $mdDialog.cancel();
    }

    $rootScope.$on('$stateChangeSuccess', function () {
      $mdDialog.hide();
    });
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin.Upload').controller('UploadCtrl', controller);

  controller.$inject = ['$scope', 'AuthService', 'commonService', 'GENERAL_CONFIG', '$http', '$window', '$timeout', 'ninjaCommonData'];
  function controller($scope, AuthService, commonService, GENERAL_CONFIG, $http, $window, $timeout, ninjaCommonData) {
    var ViewModel = $scope;

    ViewModel.hasViewPermission = AuthService.hasViewPermission;
    ViewModel.hasPermission = AuthService.hasPermission;
    ViewModel.uploadSubmit = uploadSubmit;
    ViewModel.downloadNinjaUser = downloadNinjaUser;
    ViewModel.downloadFile = downloadFile;
    ViewModel.submitUploadedInternalFile = submitUploadedInternalFile;
    ViewModel.downloadDecentralizationFile = downloadDecentralizationFile;
    ViewModel.misImportSubmit = misImportSubmit;

    var uploadType = [{
      text: 'Ninja User',
      value: 'NINJA_USER'
    }];

    var exportType = angular.copy(ninjaCommonData.csvFileNameForDecentralization);

    ViewModel.uploadFormConfig = [{
      name: 'Type of Document',
      code: 'documentType',
      type: 'radio',
      radioConfig: {
        options: uploadType,
        valueKey: 'value',
        textKey: 'text'
      }
    }];

    ViewModel.decentralizationFormConfig = [{
      name: 'Type of Document',
      code: 'mappingType',
      type: 'select',
      placeholder: 'Type of Document',
      selectConfig: {
        options: exportType,
        optionType: 'OBJECT'
      }
    }];

    init();

    function init() {
      ViewModel.upload = {
        documentType: 'NINJA_USER'
      };
      ViewModel.mapping = {
        mappingType: 'operationsLocationMaster'
      };
    }

    function uploadSubmit() {
      if ($scope.myFile) {
        var file = $scope.myFile;

        var fd = new FormData();
        fd.append('csvFile', file);

        switch (ViewModel.upload.documentType) {
          case 'NINJA_USER':
            var url = '/upload/ninja';
            uploadFileToUrl(url, fd).then(function () {
              commonService.showToast('Uploaded successfully.');
            }).catch(function () {
              commonService.showToast('Failed');
            });
            break;
        }
      } else {
        commonService.showToast('Please upload file');
      }
    }

    function uploadFileToUrl(url, formData) {
      var domain = GENERAL_CONFIG.serviceURL;
      return $http({
        url: domain + url,
        data: formData,
        headers: {
          'Content-Type': undefined
        },
        method: 'POST'
      });
    }

    function submitUploadedInternalFile() {
      if ($scope.zipFile && $scope.mappingFile) {
        var fd = new FormData();
        fd.append('zip-file', $scope.zipFile);
        fd.append('mapping-file', $scope.mappingFile);

        var url = '/v1/documents/upload';
        uploadFileToUrl(url, fd).then(function () {
          commonService.showToast('Uploaded successfully.');
        }).catch(function () {
          commonService.showToast('Failed');
        });
      } else {
        commonService.showToast('Please upload both files');
      }
    }

    function downloadNinjaUser() {
      var reqUrl = GENERAL_CONFIG.serviceURL + '/ninjauser/';
      downloadFile(reqUrl);
    }

    function downloadFile(reqUrl) {
      var reqObj = {
        url: reqUrl,
        headers: {},
        method: 'GET',
        responseType: 'blob'
      };
      return $http(reqObj).then(function (response) {
        if (angular.equals(response.status, 200)) {
          var headers = response.headers();
          var contentDisposition = headers['content-disposition'];
          var fileName = contentDisposition ? contentDisposition.split('filename=')[1] : true;
          var contentType = '';
          if (headers['content-type'] === 'application/octet-stream') {
            contentType = 'application/pdf';
          } else {
            contentType = headers['content-type'];
          }
          var blob = new Blob([response.data], {
            type: contentType
          });

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          $timeout(function () {
            document.body.removeChild(link);
          }, 100);
        }
      }).catch(function (err) {
        $window.console.log(err);
      });
    }

    function downloadDecentralizationFile() {
      var reqUrl = GENERAL_CONFIG.serviceURL + '/common/collection/download?collectionName=' + ViewModel.mapping.mappingType;
      downloadFile(reqUrl);
    }

    function misImportSubmit() {
      if ($scope.misImportFile) {
        var fd = new FormData();
        fd.append('csvFile', $scope.misImportFile);
        fd.append('updateType', 'all');

        var url = '/updatepolicydetailsbycsv';
        uploadFileToUrl(url, fd).then(function (res) {
          if (res.data.statusCode === 200) {
            setTimeout(function () {
              commonService.showToast('Upload is in progress. Please check the data after 15 min');
            }, 3000);
          } else {
            commonService.showToast(res.data.message);
          }
        }).catch(function (error) {
          commonService.showToast(error.data.message);
        });
      } else {
        commonService.showToast('Please upload file');
      }
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin.Upload').directive('ninjaFileUpload', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        var model = $parse(attrs.ninjaFileUpload);
        var modelSetter = model.assign;

        element.bind('change', function () {
          scope.$apply(function () {
            modelSetter(scope, element[0].files[0]);
          });
        });
      }
    };
  }]);
})();
'use strict';

(function () {

  angular.module('brokerAdmin.lifeOps', [])

  // Config blocks
  .config(RouterConfig);

  // Run blocks

  // /////////////////////////////////////

  /*=====================================
  =            Config Blocks            =
  =====================================*/

  RouterConfig.$inject = ['$stateProvider'];

  /**
   * Configure the turtlemintAdmin. module's routes
   *
   * @public
   *
   * @memberof   turtlemintAdmin.policyIssuance
   *
   * @author     Gurudev Murkar
   *
   * @class
   * @param      {Object}  $stateProvider      ui-router's stateProvider which is used to create
   *                                           application states/routes
   */
  function RouterConfig($stateProvider) {

    $stateProvider.state('lo-list', {
      url: '/life-ops',
      parent: 'parent-state',
      templateUrl: 'dev/life-ops/templates/life-ops.tpl.html',
      controller: 'LifeOpsCtrl',
      controllerAs: 'ViewModel',
      authenticate: true
    });
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.lifeOps').controller('LifeOpsCtrl', controller);

  controller.$inject = ['$scope', 'LifeOpsService', 'commonService', 'AuthService', 'GENERAL_CONFIG'];

  /**
  * Life Ops Controller
  *
  * @author Gurudev Murkar
  *
  * @param    {!Object}           $scope           $scope
  * @param    {!Object}           LifeOpsService   LifeOpsService
  * @param    {!Object}           $q               $q
  * @param    {!Object}           $timeout         $timeout
  *
  */
  function controller($scope, LifeOpsService, commonservice, AuthService, GENERAL_CONFIG) {
    var ViewModel = $scope;
    var domain = GENERAL_CONFIG.iframeDomain;

    ViewModel.broker = sessionStorage.getItem('broker');
    ViewModel.domain = domain;
    ViewModel.getDetailsList = getDetailsList;
    ViewModel.fileStatus = {
      status: null,
      message: null
    };
    ViewModel.getHistory = getHistory;

    // This object is for pagination purpose too
    ViewModel.history = {
      list: [],
      fromRecord: 1,
      pageLimit: 5,
      pageNumber: 1,
      toRecord: 0,
      totalResultCount: 0,
      pageLimits: [5, 10, 20, 50]
    };

    init();
    function init() {
      //This object is for navigation bar
      ViewModel.navbarConfig = {
        vertical: 'life-ops',
        title: 'LIFE OPS',
        class: '',
        search: {
          enabled: false
        }
      };
      getHistory();
    }

    // download file
    ViewModel.downloadFile = function (id, name) {
      downloadFileAPI(id, name);
    };

    function downloadFileAPI(id, name) {
      commonservice.makeThirdPartyApiCall(domain, '/api/tm-life/v0/downloadfile', {
        pId: id,
        filename: name
      }, 'GET', true).then(function () /*res*/{
        window.console.log('Download successfull');
      }).catch(function () {
        window.console.error('FETCH HISTROY -- ERROR');
      });
    }

    // Get history

    function getHistory() {
      var _ViewModel$history = ViewModel.history,
          list = _ViewModel$history.list,
          payloadObj = _objectWithoutProperties(_ViewModel$history, ['list']);

      commonservice.makeThirdPartyApiCall(domain, '/api/tm-life/v0/getfileuploadhistory', payloadObj, 'POST', true).then(function (res) {
        var responseData = res.data;
        ViewModel.history = _extends({}, ViewModel.history, {
          list: responseData.fileUploadHistoryEntries || [],
          fromRecord: responseData.fromRecord || 1,
          pageLimit: responseData.pageLimit || 5,
          pageNumber: responseData.pageNumber || 1,
          toRecord: responseData.toRecord || 0,
          totalResultCount: responseData.totalResultCount || 0
        });
      }).catch(function () {
        window.console.error('FETCH HISTROY -- ERROR');
      });
    }

    // This is giving static value,based on it background color of status bar will  change
    ViewModel.active = true;
    function setFileUploadStatus() {
      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      ViewModel.fileStatus = {
        status: status,
        message: message
      };
    }

    //For download sample file format
    ViewModel.file = {
      fileId: 'A123',
      fileName: 'login_Issuance summery-24-FEB-20.xlsx'
    };

    //Select rows per page
    function getDetailsList(page, limit) {

      ViewModel.fileDetails = ViewModel.fileDetailsObject.slice(page, limit);
      ViewModel.totalCount = Object.keys(ViewModel.fileDetailsObject).length;
    }

    ViewModel.uploadFile = function () {
      // If anyprevious status of file upload then make it blank
      setFileUploadStatus();

      // If file is blank then show error
      if (!ViewModel.myFile) {
        commonservice.showToast('Please select file first');
        return;
      }

      var file = ViewModel.myFile;

      var value = file.name;
      var fileDisplay = file.name + ' --- ' + (file.size > 999999 ? (file.size / 1000000).toFixed(2) + ' MB' : (file.size / 1000).toFixed(2) + ' KB');
      var validFormats = ['csv'];
      var ext = value.substr(value.lastIndexOf('.') + 1);
      ViewModel.uploadBarStatus = value ? true : false;

      // If file is valid with expected format
      if (validFormats.indexOf(ext) !== -1) {

        // Set status as LOADING
        setFileUploadStatus('LOADING');

        var url = '/api/tm-life/v0/processissuancecsv/';
        var currentUser = AuthService.getUser();
        var fd = new FormData();
        fd.append('uploadedFile', file);
        fd.append('userId', currentUser.fullName);
        fd.append('currentTimestamp', Date.now());
        fd.append('filename', file.name);
        fd.append('fieExtension', ext);

        LifeOpsService.uploadFileToUrl(fd, url).then(function (response) {

          var resData = response.data.currentBackwardFeedFileUpload;

          if (resData.fileStatus === 'ERROR') {
            setFileUploadStatus('ERROR', resData.errorReason);
            return;
          }

          setFileUploadStatus('SUCCESS', fileDisplay);

          ViewModel.fileDetails = [_extends({}, response.data)];

          // fetch history
          getHistory();
        }).catch(function (error) {
          var errorMsg = error.data ? error.data.errorCode + ' - ' + (error.data.error || error.data.errorMessage) : error;
          // in case of error
          setFileUploadStatus('ERROR', errorMsg);
        });
      } else {
        commonservice.showToast('INVALID FILE FORMAT');
      }
    };
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin.lifeOps').provider('LifeOpsService', LifeOpsService);

  // //////////////////////////////////////////////////////

  /**
   * LifeOpsService
   *
   * @public
   *
   * @author    beerpratap
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */
  function LifeOpsService() {

    LifeOpsService.$inject = ['$q', 'commonService'];

    return {
      $get: LifeOpsService
    };

    // //////////////////////////////////////////////////////

    /**
     * LifeOpsService
     *
     * @public
     *
     * @memberof   module:tutlemintAdmin
     *
     * @author     beerpratap
     *
     * @class
     * @param      {Object}    $q                             Angular's $q promise library
     * @param      {Object}    $http                          Angular http service
     *
     * @return     {Object}    Instance of the Service
     */
    function LifeOpsService($q, commonService) {

      return {
        //Functions
        uploadFileToUrl: uploadFileToUrl
      };

      // //////////////////////////////////////////////////////


      function uploadFileToUrl(jsonData, url) {
        return commonService.makeMultiPartDataApiCall('', url, jsonData, 'POST', true, {});
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.mis').controller('DuplicateCheckDialogController', controller);

  /**
   * Duplicate Check Dialog controller
   */
  controller.$inject = ['$scope', '$mdDialog'];

  function controller($scope, $mdDialog) {
    var ViewModel = $scope;
    ViewModel.editEntry = editEntry;

    function editEntry(entryType) {
      $mdDialog.hide(entryType);
    }
    //
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('tmNomineeController', tmNomineeController).directive('tmNominee', tmNomineeDirective);

  function tmNomineeDirective() {
    var directive = {
      restrict: 'E',
      templateUrl: 'dev/directives/templates/nominee.tpl.html',
      controller: tmNomineeController,
      scope: {
        list: '=',
        editable: '=',
        removeMember: '=',
        memberList: '=',
        memberInitConfig: '='
      }
    };
    return directive;
  }

  tmNomineeController.$inject = ['$scope'];

  /**
   * tmNomineeController
   *
   * @author sagarmulchandani
   *
   */
  function tmNomineeController($scope) {

    var ViewModel = $scope;
    var memberObj = void 0;
    ViewModel.memberMap = {};
    ViewModel.addUpdateMember = addUpdateMember;

    _init();

    /**
     * init uploader function
     *
     * @private
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function _init() {
      ViewModel.memberConfig = ViewModel.memberList.generalFields;

      if (ViewModel.memberInitConfig) {
        memberObj = ViewModel.memberInitConfig;
      }
    }

    function addUpdateMember() {
      ViewModel.list.push(memberObj);
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  var brokerAdmin = angular.module('brokerAdmin');
  brokerAdmin.service('leadService', leadService);
  leadService.$inject = ['GENERAL_CONFIG', 'commonService', 'CONSTANTS_LIST', 'MasterFileService', 'AuthService', '$cookies'];

  function leadService(GENERAL_CONFIG, commonService, CONSTANTS_LIST, MasterFileService, AuthService, $cookies) {

    function getLeads(jsonData) {
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL, '/getqisleads', jsonData, 'POST', false);
    }

    function getLeadById(leadId, tenant) {
      var jsonData = {
        leadId: leadId,
        tenant: tenant
      };
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/getleadbyid', jsonData, 'GET', true);
    }

    function getLeadByQisId(leadId, tenant) {
      var jsonData = {
        qisLeadId: leadId,
        tenant: tenant
      };
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/getleadbyqisleadid', jsonData, 'GET', true);
    }

    function getLeadByRequestId(requestId, tenant) {
      var jsonData = {
        requestId: requestId,
        tenant: tenant
      };
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/getleadbyreqid', jsonData, 'GET');
    }

    function updateLead(leadObj) {
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/saveLead', leadObj, 'POST', null, {
        qisLeadId: leadObj.qisLeadId
      });
    }

    function setTag(leadObj) {
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/saveLead', leadObj, 'POST', null, {
        qisLeadId: leadObj.qisLeadId
      });
    }

    function getFileName(url) {
      return url.substring(url.lastIndexOf('/') + 1, url.length);
    }

    function rejectQisLead(requestId, tenant) {
      var jsonData = {
        'status': CONSTANTS_LIST.REJECTED,
        'requestId': requestId,
        'tenant': tenant
      };
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/rejectqis', jsonData, 'POST');
    }

    function sendQuote(leadId, tenant, reqId) {
      var jsonData = {
        leadId: leadId,
        tenant: tenant,
        requestId: reqId
      };
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL + '/mintpro', '/sendQuote', jsonData, 'GET');
    }

    function searchLeads(jsonData) {
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL, '/search/lead', jsonData, 'GET');
    }

    function getAllFiles(jsonData) {
      return MasterFileService.getDocuments(jsonData);
    }

    function updateFile(id, file) {
      return MasterFileService.updateFile(id, file);
    }

    function ownerList() {
      return commonService.makeThirdPartyApiCall(GENERAL_CONFIG.serviceURL, '/owners/qis', {}, 'GET');
    }

    function showCommentBtn() {
      return AuthService.hasPermission('qis-chat') || AuthService.hasPermission('chat-unrestricted');
    }

    function generateQuoteApi(file, lead) {
      var url = GENERAL_CONFIG.serviceURL;
      var quoteData = {
        fileid: file.processInfo.pid,
        request_id: lead.requestId,
        tag: file.tag,
        vertical: lead.vertical,
        prev_claim: lead.filedClaimLastYear,
        regno: lead.regNo
      };
      return commonService.makeThirdPartyApiCall(url, '/qis/generate_quote/', quoteData, 'POST', false);
    }

    function getIframeUrl() {
      var domainName = commonService.getBaseDomain();
      var url = '//pro.' + domainName;
      return url;
    }

    function setReadOnly(readOnly) {
      $cookies.remove('qisReadOnly', {
        domain: GENERAL_CONFIG.cookieDomain
      });
      commonService.setItemSession('qis', 'isAccessReadOnly', readOnly);
      commonService.setPlainCookie('qisReadOnly', readOnly, GENERAL_CONFIG.cookieDomain, 1);
    }

    function createQuoteLead(formData) {
      var url = '/ninja/lead';
      return commonService.makeHttpCall(url, formData, 'POST', false);
    }

    function removeQisCookies() {
      $cookies.remove(CONSTANTS_LIST.COOKIE_REQUEST_ID, {
        'domain': GENERAL_CONFIG.cookieDomain
      });
      $cookies.remove(CONSTANTS_LIST.COOKIE_QIS_LEAD_ID, {
        'domain': GENERAL_CONFIG.cookieDomain
      });
      $cookies.remove('fromQISResult', {
        'domain': GENERAL_CONFIG.cookieDomain
      });
    }

    return {
      getFileName: getFileName,
      getLeadById: getLeadById,
      getLeadByQisId: getLeadByQisId,
      getLeadByRequestId: getLeadByRequestId,
      getLeads: getLeads,
      rejectQisLead: rejectQisLead,
      searchLeads: searchLeads,
      sendQuote: sendQuote,
      setTag: setTag,
      updateLead: updateLead,
      getAllFiles: getAllFiles,
      updateFile: updateFile,
      ownerList: ownerList,
      showCommentBtn: showCommentBtn,
      generateQuoteApi: generateQuoteApi,
      getIframeUrl: getIframeUrl,
      setReadOnly: setReadOnly,
      createQuoteLead: createQuoteLead,
      removeQisCookies: removeQisCookies
    };
  }
})();
'use strict';

(function () {

  angular.module('brokerAdmin').provider('QuotesService', QuotesService);

  QuotesService.$inject = [];

  return QuotesService;

  // //////////////////////////////////////////////////////

  /**
   * QuotesService
   *
   * @public
   *
   * @class
   *
   * @return    {Object} Instance of the Service (Provider)
   *
   */

  function QuotesService() {

    QuotesService.$inject = ['commonService', 'AuthService', '$q', '$filter', 'TrackingService'];

    return {
      $get: QuotesService
    };

    function QuotesService(commonService, AuthService, $q, $filter, TrackingService) {
      var dataConfig = {
        verticals: [{
          code: 'TW',
          name: 'TW'
        }, {
          code: 'FW',
          name: 'FW'
        }, {
          code: 'CV',
          name: 'CV'
        }, {
          code: 'HEALTH',
          name: 'Health'
        }, {
          code: 'LIFE',
          name: 'Life'
        }]
      };

      var generalFields = [{
        name: 'Digital Partner',
        code: 'dpLoginId',
        placeholder: 'Select Digital Partner',
        hide: false,
        required: true,
        type: 'autocomplete',
        autocompleteConfig: {
          disabled: false,
          noCache: true,
          selectedItemChange: _selectedPartnerFn,
          querySearch: _searchPartnerFn,
          displayKey: 'label',
          valueKey: 'dpLoginId',
          resultTitleKey: 'label',
          resultLabelKey: 'label'
        }
      }, {
        name: 'Vertical',
        code: 'vertical',
        placeholder: '',
        type: 'select',
        required: true,
        changeFn: _verticalChangeFn,
        selectConfig: {
          options: dataConfig.verticals,
          valueKey: 'code',
          textKey: 'name'
        }
      }];

      return {
        generalFields: generalFields,
        initPartnerList: initPartnerList
      };

      function initPartnerList() {
        getPartnerLists();
      }

      function getPartnerLists() {
        var user = AuthService.getUser();
        var url = '/mintpro/v1/partners/reportees';
        var reqObj = {
          email: user.email
        };

        commonService.makeHttpCall(url, reqObj, 'GET', false).then(function (res) {
          var reportees = res.data.data.reportees;
          if (reportees && reportees.length > 0) {
            reportees.forEach(function (partner) {
              partner.label = '(' + partner.dpNo + ') ' + partner.name;
            });
          }
          dataConfig.partners = reportees;
        }).catch(function () {
          commonService.showToast('Some error occured. Couldn\'t get list of DP');
        });
      }

      function _selectedPartnerFn(selectedItem, model) {
        if (selectedItem) {
          model.dpLoginId = selectedItem.id;
          TrackingService.pushToGTMDatalayer('CREATE_QUOTE_DP_SELECT', 'track_event', model);
        } else {
          delete model.dpLoginId;
        }
      }

      function _searchPartnerFn(searchKey) {
        var promise = $q.defer();
        var partners = angular.copy(dataConfig.partners) || [];
        if (searchKey && partners.length > 0) {
          var results = $filter('filter')(partners, searchKey);
          promise.resolve(results);
        } else {
          promise.resolve(partners);
        }
        return promise.promise;
      }

      function _verticalChangeFn(selectedItem, model) {
        if (selectedItem) {
          TrackingService.pushToGTMDatalayer('CREATE_QUOTE_VERTICAL_SELECT', 'track_event', model);
        }
      }
    }
  }
})();
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

//jshint: esversion:6
(function () {
  angular.module('brokerAdmin.qis').controller('QisListCtrl', controller);

  controller.$inject = ['$scope', '$mdSelect', 'commonService', 'leadService', 'PartnerService', 'CONSTANTS_LIST', 'GENERAL_CONFIG', '$cookies', 'AuthService', '$mdDialog', '$state', 'TrackingService', 'pageType', '$mdBottomSheet', 'ninjaCommonDataService'];

  function controller($scope, $mdSelect, commonService, leadService, PartnerService, CONSTANTS_LIST, GENERAL_CONFIG, $cookies, AuthService, $mdDialog, $state, TrackingService, pageType, $mdBottomSheet, ninjaCommonDataService) {
    var ViewModel = $scope;
    var broker = sessionStorage.getItem('broker');
    ViewModel.pageType = pageType;
    ViewModel.hasPermission = AuthService.hasPermission;
    var filterKey = pageType + '_FILTERS' + (commonService.isMobile() ? '_MOBILE' : '');

    ninjaCommonDataService.getNinjaCommonData().then(function (ninjaCommonData) {
      ViewModel.qisFilters = {
        statusList: angular.copy(ninjaCommonData.status.qis),
        verticalList: angular.copy(ninjaCommonData.vertical.qis),
        CONSTANTS_LIST: angular.copy(CONSTANTS_LIST)
      };
    });
    ViewModel.goTo = goTo;
    ViewModel.getQisLeads = getQisLeads;
    ViewModel.orderQisLead = orderQisLead;
    ViewModel.saveLeadDetailsAndStateChange = saveLeadDetailsAndStateChange;
    ViewModel.applyFilter = applyFilter;
    ViewModel.clearFilter = clearFilter;
    ViewModel.clearSearchBranch = clearSearchBranch;
    ViewModel.clearSearchOwner = clearSearchOwner;
    ViewModel.orderFilter = orderFilter;
    ViewModel.stopEventPropagation = commonService.stopEventPropagation;
    ViewModel.displayInitials = displayInitials;
    ViewModel.openFilterSheet = openFilterSheet;
    ViewModel.loadMoreLeads = loadMoreLeads;
    ViewModel.filterLeadStatus = filterLeadStatus;
    ViewModel.closeFilterSheet = closeFilterSheet;
    ViewModel.clearFilterSheet = clearFilterSheet;
    ViewModel.applyMobileFilter = applyMobileFilter;
    ViewModel.filterCheck = filterCheck;
    ViewModel.isFilterChecked = isFilterChecked;
    ViewModel.createQuote = createQuote;
    ViewModel.cardClick = cardClick;
    ViewModel.callPartner = callPartner;
    ViewModel.isSalesUser = AuthService.isSalesUser();
    ViewModel.loadMore = {
      show: true,
      loading: false
    };
    ViewModel.query = {
      limit: 15,
      page: 1,
      totalCount: 0,
      filters: {
        vertical: [],
        leadStatus: [],
        leadStage: [],
        adminOwner: [],
        qisBranchLocation: [],
        createDate: []
      }
    };
    ViewModel.dateFilter = {
      from: null,
      to: null
    };
    ViewModel.paginationOption = [5, 10, 15, 20, 25, 50];
    ViewModel.restrictedSalesUser = {
      value: AuthService.isRestrictedSalesAccess(),
      message: 'Quotes is currently unavailable for non Unit Manager Sales users. We are working on the fix. Thanks for your patience.'
    };
    init();

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratap
     *
     */
    function init() {
      ViewModel.navbarConfig = {
        vertical: 'qis',
        title: 'QIS',
        class: 'no-box-shadow',
        search: {
          enabled: true,
          searchFn: searchRecords,
          placeholder: 'Search by Registration number, Request Id, POSP name'
        }
      };
      initFilters();
      getOwnerList();
      getBranchLocation();
      getQisLeads();
    }

    function initFilters() {
      setFiltersBasedOnPageType(pageType);
      if (commonService.getItemSession('qis', filterKey)) {
        ViewModel.query.filters = commonService.getItemSession('qis', filterKey);
      }
    }

    function setFiltersBasedOnPageType(pageType) {
      switch (pageType) {
        case CONSTANTS_LIST.PAGE_TYPE.QUOTE_REQUEST:
          initQuoteRequestFilters();
          break;
        case CONSTANTS_LIST.PAGE_TYPE.QUOTES:
          initQuotesFilters();
          break;

      }
    }

    function initQuoteRequestFilters() {
      if (commonService.isMobile()) {
        initMobileQuoteRequestFilters();
      } else {
        initDesktopQuoteRequestFilters();
      }
    }

    function initMobileQuoteRequestFilters() {
      ViewModel.query.filters.leadStatus = ['' + CONSTANTS_LIST.NEW_QUOTE];
    }

    function initDesktopQuoteRequestFilters() {
      ViewModel.query.filters.leadStatus = ['' + CONSTANTS_LIST.NEW_QUOTE, '' + CONSTANTS_LIST.OPEN_QUOTE, '' + CONSTANTS_LIST.REOPENED_QUOTE];
    }

    function initQuotesFilters() {
      ViewModel.query.filters.leadStatus = ['' + CONSTANTS_LIST.ACTIVE];
      ViewModel.query.filters.leadStage = ['' + CONSTANTS_LIST.QUOTE, '' + CONSTANTS_LIST.CHECKOUT];
    }

    function searchRecords(searchKey) {
      if (searchKey) {
        searchKey = searchKey.trim();
        if (searchKey.length >= 4) {
          getQisLeads(searchKey);
          TrackingService.pushToGTMDatalayer(pageType + '_SEARCH', 'track_event', { search: searchKey });
        } else {
          commonService.showToast('Min. 4 characters required for search');
        }
      } else {
        getQisLeads();
      }
    }

    function filterLeadStatus(leadStatus) {
      ViewModel.query.filters.leadStatus = [leadStatus];
      applyFilter();
    }

    function applyFilter() {
      if (ViewModel.query.filters.leadStatus || ViewModel.query.filters.vertical || ViewModel.query.filters.adminOwner || ViewModel.query.filters.qisBranchLocation || ViewModel.query.filters.leadStage || ViewModel.query.filters.createDate) {
        ViewModel.query.page = 1;
        commonService.setItemSession('qis', filterKey, ViewModel.query.filters);
      }
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_APPLY', 'track_event', { filterData: ViewModel.query });
      getQisLeads();
      $mdSelect.hide();
    }

    function applyMobileFilter() {
      if (ViewModel.dateFilter.from && ViewModel.dateFilter.to) {
        ViewModel.query.filters.createDate = [moment(ViewModel.dateFilter.from).format('x'), moment(ViewModel.dateFilter.to).format('x')];
      } else if ((ViewModel.dateFilter.from || ViewModel.dateFilter.to) && (!ViewModel.dateFilter.from || !ViewModel.dateFilter.to)) {
        commonService.showToast('From and To dates are required for date Range');
        ViewModel.query.filters.createDate = [];
        return;
      } else if (!ViewModel.dateFilter.from && !ViewModel.dateFilter.to) {
        ViewModel.query.filters.createDate = [];
      }
      applyFilter();
      closeFilterSheet();
    }

    function clearFilter(filterName) {
      ViewModel.query.filters[filterName] = [];
      applyFilter();
      $mdSelect.hide();
    }

    function clearSearchBranch() {
      ViewModel.searchBranchList = '';
    }

    function clearSearchOwner() {
      ViewModel.searchOwnerList = '';
    }

    function getOwnerList() {
      var promise = leadService.ownerList();
      promise.then(function (response) {
        ViewModel.adminOwnerList = response.data;
      });
    }

    function removeDuplicateValue(myArray) {
      var newArray = [];
      angular.forEach(myArray, function (value) {
        var exists = false;
        angular.forEach(newArray, function (val2) {
          if (angular.equals(value.branch, val2.branch)) {
            exists = true;
          }
        });
        if (exists === false && value.branch !== '') {
          newArray.push(value);
        }
      });
      return newArray;
    }

    function getBranchLocation() {
      var branchList = {
        name: 'Not Available',
        branch: 'NA'
      };
      PartnerService.getBranchMaster().then(function (res) {
        ViewModel.qisBranchLocationList = removeDuplicateValue(res);
        ViewModel.qisBranchLocationList.unshift(branchList);
      });
    }

    function getQisLeads(searchKey) {
      if (ViewModel.restrictedSalesUser.value) {
        return;
      }
      ViewModel.policyList = [];
      var jsonData = _getFilterValue(searchKey);
      var promise = getLeads(jsonData);
      promise.then(function (response) {
        var policyList = response.data && response.data || [];
        ViewModel.query.totalCount = policyList.count;
        ViewModel.policyList = transformData(policyList.data);
        if (ViewModel.policyList && ViewModel.policyList.length >= ViewModel.query.limit) {
          ViewModel.loadMore.show = true;
        } else {
          ViewModel.loadMore.show = false;
        }
      }).catch(function (err) {
        var msg = 'No data found.';
        if ([401, 403].includes(err.status)) {
          msg = 'Authorization failed. Re-login to continue';
        }
        commonService.showToast(msg);
        ViewModel.loadMore.show = false;
      });
    }

    function getLeads(jsonData) {
      return leadService.getLeads(jsonData);
    }

    function orderQisLead() {
      getQisLeads();
    }

    function _getFilterValue(searchKey) {
      ViewModel.query.searchKey = searchKey;
      var jsonData = {
        searchTerm: searchKey ? searchKey : undefined,
        broker: broker,
        isQisInitiated: true,
        skip: (ViewModel.query.page - 1) * ViewModel.query.limit,
        limit: ViewModel.query.limit,
        page: ViewModel.query.page,
        filters: ViewModel.query.filters,
        sort: orderFilter(ViewModel.query.order)
      };
      return jsonData;
    }

    function saveLeadDetailsAndStateChange(lead) {
      var currentOwner = lead.owner && lead.owner.email;
      var currentUser = AuthService.getUser();
      if (currentOwner !== currentUser.email) {
        if (AuthService.hasPermission('qis-update')) {
          openOwnerPopup(lead);
        } else {
          leadService.setReadOnly(true);
          setCookiesForTurtlemint(lead);
          var mxpData = {
            requestId: lead.requestId,
            leadId: lead.leadId,
            vertical: lead.vertical
          };
          TrackingService.pushToGTMDatalayer('QIS_VIEW', 'track_event', mxpData);
        }
      } else {
        leadService.setReadOnly(false);
        setCookiesForTurtlemint(lead);
      }
    }

    function cardClick(lead) {
      if (pageType === CONSTANTS_LIST.PAGE_TYPE.QUOTE_REQUEST) {
        saveLeadDetailsAndStateChange(lead);
      } else if (pageType === CONSTANTS_LIST.PAGE_TYPE.QUOTES) {
        _quotesRedirect(lead);
      }
    }

    function setCookiesForTurtlemint(lead) {
      leadService.removeQisCookies();
      var dealerUserName = lead && lead.partnerId;
      var pospUserName = lead && lead.pospPartnerId;
      var tenant = lead && lead.tenant;
      commonService.setItemSession('qis', CONSTANTS_LIST.SESSION_LEAD, lead);
      commonService.setItemSession('qis', 'pageType', pageType);
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_DEALER_USER_NAME, dealerUserName, GENERAL_CONFIG.cookieDomain, 1);
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_POSP_USER_NAME, pospUserName, GENERAL_CONFIG.cookieDomain, 1);
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_TENANT, tenant, GENERAL_CONFIG.cookieDomain, 1);
      if (lead.requestId) {
        commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_REQUEST_ID, lead.requestId, GENERAL_CONFIG.cookieDomain, 1);
      }
      if (lead.qisLeadId) {
        commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_QIS_LEAD_ID, lead.qisLeadId, GENERAL_CONFIG.cookieDomain, 1);
      }
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_FILED_CLAIM_LAST_YEAR, '' + lead.filedClaimLastYear, GENERAL_CONFIG.cookieDomain, 1);
      commonService.setPlainCookie('newQIS', true, GENERAL_CONFIG.cookieDomain, 1);
      if (pageType === CONSTANTS_LIST.PAGE_TYPE.QUOTE_REQUEST) {
        decideTheNextPage(lead);
      }
    }

    function decideTheNextPage(lead) {
      var id = lead.requestId;
      if (lead.leadStage === 'profile') {
        $state.go('qis-assign-tag', {
          id: id,
          tenant: lead.tenant
        });
      } else {
        $state.go('qis-result', {
          id: id,
          tenant: lead.tenant
        });
      }
    }

    function openOwnerPopup(lead) {
      $mdDialog.show({
        templateUrl: 'dev/qis/templates/owner-dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          lead: lead
        },
        controller: 'QisOwnerDialogCtrl'
      }).then(function (res) {
        if (res) {
          setCookiesForTurtlemint(lead);
        }
      });
    }

    function transformData(lead) {
      return lead.map(function (ele) {
        if (ele.vertical === 'commercial-vehicle') {
          ele.vertical = 'cv';
        }
        ele.regNo = ele.regNo ? ele.regNo.toUpperCase() : '';
        var name = ele.owner && ele.owner.name;
        ele.ownerName = name ? name : '';
        ele.elapsedTime = moment(ele.modifiedDate).fromNow();
        return ele;
      });
    }

    function orderFilter(orderColumn) {
      var orderByObj = [];
      if (typeof orderColumn === 'string') {
        var sortObj = {};
        if (orderColumn.indexOf('-') === -1) {
          sortObj[orderColumn] = 'ASC';
        } else {
          orderColumn = orderColumn.replace('-', '');
          sortObj[orderColumn] = 'DESC';
        }

        orderByObj.push(sortObj);
      }
      return orderByObj;
    }

    function goTo(page) {
      TrackingService.pushToGTMDatalayer(pageType + '_TAB_CLICK', 'track_event', {});
      $state.go(page);
    }

    function callPartner(lead) {
      if (!ViewModel.isSalesUser) {
        cardClick(lead);
        return;
      }
      var eventData = {
        leadId: lead.leadId,
        requestId: lead.requestId,
        vertical: lead.vertical,
        leadStatus: lead.leadStatus,
        leadStage: lead.leadStage,
        partnerId: lead.partnerId,
        partnerDpNo: lead.partnerDpNo
      };
      if (lead.owner) {
        eventData.ownerEmail = lead.owner.email;
      }
      if (lead.partnerMobile) {
        TrackingService.pushToGTMDatalayer(pageType + '_CALL_PARTNER_CLICK', 'track_event', eventData);
        window.location.href = 'tel:+91-' + lead.partnerMobile;
      }
    }

    function displayInitials(name) {
      var initials = 'DP';
      if (name) {
        initials = name.split(' ').map(function (n) {
          return n[0];
        }).join('');
      }
      return initials;
    }

    function openFilterSheet() {
      if (ViewModel.query.filters.createDate && ViewModel.query.filters.createDate.length > 0) {
        ViewModel.dateFilter.from = ViewModel.query.filters.createDate[0] ? moment(parseInt(ViewModel.query.filters.createDate[0])) : null;
        ViewModel.dateFilter.to = ViewModel.query.filters.createDate[1] ? moment(parseInt(ViewModel.query.filters.createDate[1])) : null;
      }
      TrackingService.pushToGTMDatalayer(pageType + '_FILTER_MOBILE', 'track_event', {});
      $mdBottomSheet.show({
        templateUrl: 'dev/qis/templates/qis-filter-mob.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false
      }).then(function () {}).catch(function () {});
    }

    function loadMoreLeads() {
      ViewModel.query.page = ViewModel.query.page + 1;
      var jsonData = _getFilterValue();
      var promise = getLeads(jsonData);
      ViewModel.loadMore.loading = true;
      promise.then(function (response) {
        var _ViewModel$policyList;

        var data = response.data && response.data || [];
        var transformedData = transformData(data.data);
        (_ViewModel$policyList = ViewModel.policyList).push.apply(_ViewModel$policyList, _toConsumableArray(transformedData));
        ViewModel.loadMore.loading = false;

        if (transformedData && transformedData.length >= ViewModel.query.limit) {
          ViewModel.loadMore.show = true;
        } else {
          ViewModel.loadMore.show = false;
        }
      }).catch(function () {
        commonService.showToast('No data found.');
        ViewModel.loadMore.show = false;
      });
    }

    function closeFilterSheet() {
      TrackingService.pushToGTMDatalayer('QUOTES_FILTER_MOBILE_CLOSE', 'track_event', {});
      $mdBottomSheet.cancel();
    }

    function clearFilterSheet() {
      _clearDateFilter();
      ViewModel.query.filters.vertical = [];
      applyMobileFilter();
    }

    function _clearDateFilter() {
      ViewModel.dateFilter = {
        from: undefined,
        to: undefined
      };
    }

    function filterCheck(selectedValue, filterList) {
      var idx = filterList.indexOf(selectedValue);
      if (idx > -1) {
        filterList.splice(idx, 1);
      } else {
        filterList.push(selectedValue);
      }
    }

    function isFilterChecked(selectedValue, filterList) {
      return filterList.includes(selectedValue);
    }

    function createQuote() {
      TrackingService.pushToGTMDatalayer('QUOTES_PAGE_CREATE_QUOTE_CLICK', 'track_event', {});
      $state.go('quotes-form');
    }

    function _quotesRedirect(lead) {
      if (['FW', 'CV', 'TW'].includes(lead.vertical) && lead.leadStage === CONSTANTS_LIST.QUOTE) {
        leadService.setReadOnly(true);
        setCookiesForTurtlemint(lead);
        $state.go('qis-result', {
          id: lead.requestId,
          tenant: lead.tenant
        });
      }
    }
  }
})();
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

(function () {
  angular.module('brokerAdmin.qis').controller('QisAssignTagCtrl', controller);

  controller.$inject = ['$scope', '$state', 'lead', 'AuthService', 'commonService', 'MasterFileService', 'leadService', 'UtilService', 'CONSTANTS_LIST', 'MIS_CONSTANTS', '$sce', '$mdDialog', 'TrackingService', '$mdBottomSheet'];

  function controller($scope, $state, lead, AuthService, commonService, MasterFileService, leadService, UtilService, CONSTANTS_LIST, MIS_CONSTANTS, $sce, $mdDialog, TrackingService, $mdBottomSheet) {
    var ViewModel = $scope;
    var extensions = {
      pdf: ['pdf'],
      image: ['jpg', 'png', 'jpeg']
    };
    var broker = sessionStorage.getItem('broker');
    var isAccessReadOnly = commonService.getItemSession('qis', 'isAccessReadOnly');
    var fromPageType = commonService.getItemSession('qis', 'pageType');
    var mxpData = void 0;
    ViewModel.isApp = commonService.isApp();
    ViewModel.downloadFile = downloadFile;
    ViewModel.setCurrentFile = setCurrentFile;
    ViewModel.setTagAndClose = setTagAndClose;
    ViewModel.getOptionName = getOptionName;
    ViewModel.openTagList = openTagList;
    ViewModel.toggleFileActions = toggleFileActions;
    ViewModel.toggleFiles = toggleFiles;

    ViewModel.comment = {
      showBtn: leadService.showCommentBtn(),
      source: 'ADMIN_QIS',
      showPopUp: showCommentPopup
    };
    ViewModel.entryOptions = {
      isAllTagged: false,
      currentSelectedTag: 'Tag this file',
      showTagOption: false
    };
    ViewModel.policyTags = angular.copy(MIS_CONSTANTS.filetags);
    ViewModel.options = {
      zoom: {
        step: 0.25,
        value: 1,
        min: 0.75
      },
      rotate: {
        value: 90
      },
      controls: {
        fit: 'width'
      }
    };

    init();

    function init() {
      ViewModel.lead = lead;
      ViewModel.editMode = isAccessReadOnly ? false : AuthService.hasPermission('qis-update');
      ViewModel.navbarConfig = {
        goBackState: fromPageType === CONSTANTS_LIST.PAGE_TYPE.QUOTES ? 'quotes-list' : 'qis-list',
        vertical: 'qis',
        mode: 'EDIT',
        title: ViewModel.lead.partnerName,
        subtitle: ViewModel.lead.requestId
      };
      if (ViewModel.editMode) {
        _setNavActions('EDIT');
      } else {
        _setNavActions('READ');
      }
      _getLeadFiles();
      mxpData = {
        requestId: ViewModel.lead.requestId,
        leadId: ViewModel.lead.leadId,
        vertical: ViewModel.lead.vertical
      };
      ViewModel.showFileActions = false;
    }

    function _setNavActions(mode) {
      switch (mode) {
        case 'EDIT':
          ViewModel.navbarConfig.actions = [{
            type: 'button',
            class: '',
            action: _continueToReview,
            permissionCode: 'qis-update',
            disabled: !ViewModel.editMode,
            name: 'Continue'
          }, {
            type: 'button',
            class: '',
            action: _declineRequestModel,
            permissionCode: 'qis-update',
            disabled: !ViewModel.editMode,
            name: 'Decline Request'
          }];
          break;

        case 'READ':
          ViewModel.navbarConfig.actions = [{
            type: 'button',
            class: '',
            action: _closeRequestPage,
            name: 'Close'
          }];
          break;
      }
    }

    function _closeRequestPage() {
      $state.go('qis-list');
    }

    function _continueToReview() {
      if (ViewModel.leadImages.length > 0) {
        if (ViewModel.entryOptions.isAllTagged) {
          TrackingService.pushToGTMDatalayer('QIS_ASSIGN_CONTINUE', 'track_event', mxpData);
          $state.go('qis-review', { id: ViewModel.lead.requestId, tenant: ViewModel.lead.tenant });
        } else {
          commonService.showToast('Tag files to continue');
        }
      } else {
        commonService.showToast('No files found. Decline request or check lead');
      }
    }

    function _declineRequestModel() {
      $mdDialog.show({
        templateUrl: 'dev/qis/templates/qis-decline.dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          lead: ViewModel.lead
        },
        controller: 'QisDeclineController'
      });
    }

    function downloadFile(file) {
      return MasterFileService.downloadFile(file.processInfo.pid);
    }

    function _getLeadFiles() {
      leadService.getAllFiles({ requestId: ViewModel.lead.requestId }).then(function (files) {
        var leadImages = files || [];
        ViewModel.leadImages = leadImages.map(function (ele) {
          var isImage = UtilService.fileCanBePreviewed(ele, extensions.image);
          var isPDF = UtilService.fileCanBePreviewed(ele, extensions.pdf);

          if (isImage) {
            ele.type = 'image';
            ele.displayUrl = _getDisplayLink(ele.processInfo.pid);
            ele.appImageUrl = MasterFileService.getAppImageViewUrl(ele.displayUrl);
          } else if (isPDF) {
            ele.type = 'pdf';
            var url = _getDisplayLink(ele.processInfo.pid);
            ele.displayUrl = $sce.trustAsResourceUrl(url);
          }
          return ele;
        });
        setCurrentFile(0);
        enableCountineBtn();
        if (ViewModel.entryOptions.isAllTagged) {
          $state.go('qis-review', { id: ViewModel.lead.requestId, tenant: ViewModel.lead.tenant });
        }
      }).catch(function () {
        return commonService.showToast('Failed to load files. Try again');
      });
    }

    function setCurrentFile(idx) {
      ViewModel.currentFile = ViewModel.leadImages[idx];
      ViewModel.defaultComment = ViewModel.currentFile.fileName + ' rejected. Take photo again and upload';
      $mdBottomSheet.hide(idx);
    }

    function _getDisplayLink(id) {
      return MasterFileService.getViewFileUrl(id);
    }

    function enableCountineBtn() {
      var images = ViewModel.leadImages || [];
      for (var i = 0; i < images.length; i++) {
        if (images[i].tag) {
          ViewModel.entryOptions.isAllTagged = true;
        } else {
          ViewModel.entryOptions.isAllTagged = false;
          break;
        }
      }
      $scope.$applyAsync();
    }

    function getOptionName(value) {
      if (!value) {
        return 'untagged';
      }
      for (var options in ViewModel.policyTags) {
        if (ViewModel.policyTags[options].code === value) {
          return ViewModel.policyTags[options].name;
        }
      }
      return 'untagged';
    }

    function setTagAndClose(selectedTag) {
      var currentFile = ViewModel.currentFile;
      currentFile.tag = selectedTag.code;
      leadService.updateFile(ViewModel.currentFile.processInfo.pid, ViewModel.currentFile).then(function () {
        ViewModel.entryOptions.showTagOption = false;
        enableCountineBtn();
        if (broker === CONSTANTS_LIST.BROKER.TURTLEMINT && ['POLICY', 'PREVIOUS_POLICY'].includes(ViewModel.currentFile.tag)) {
          leadService.generateQuoteApi(ViewModel.currentFile, ViewModel.lead);
          TrackingService.pushToGTMDatalayer('QIS_ASSIGN_TAG', 'track_event', _extends({}, mxpData, { tag: ViewModel.currentFile.tag }));
        }
      });
    }

    function openTagList(show) {
      ViewModel.entryOptions.showTagOption = show;
    }

    function showCommentPopup() {
      ViewModel.msgText = ViewModel.defaultComment;
      $mdDialog.show({
        controller: 'ChatPopupDialogCtrl',
        templateUrl: 'dev/notifications/templates/dialogs/chat-popup.tpl.html',
        clickOutsideToClose: true,
        scope: $scope,
        preserveScope: true,
        locals: {
          reference: ViewModel.lead.requestId,
          editMode: ViewModel.editMode,
          conversation: ViewModel.lead,
          source: ViewModel.comment.source,
          windowType: 'REJECT'
        }
      });
    }

    function toggleFileActions() {
      ViewModel.showFileActions = !ViewModel.showFileActions;
    }

    function toggleFiles() {
      TrackingService.pushToGTMDatalayer('QIS_ASSIGN_FILE_TOGGLE_OPEN', 'track_event', mxpData);
      $mdBottomSheet.show({
        templateUrl: 'dev/qis/templates/select-document-mobile.bottomsheet.tpl.html',
        scope: $scope,
        preserveScope: true
      }).then(function () {
        TrackingService.pushToGTMDatalayer('QIS_ASSIGN_FILE_TOGGLE_CLOSE', 'track_event', mxpData);
      }).catch(function () {
        TrackingService.pushToGTMDatalayer('QIS_ASSIGN_FILE_TOGGLE_CLOSE', 'track_event', mxpData);
      });
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.qis').controller('QisReviewCtrl', controller);

  controller.$inject = ['$scope', 'commonService', 'GENERAL_CONFIG', 'CONSTANTS_LIST', 'AuthService', '$sce', 'leadService', '$mdDialog', 'lead', 'TrackingService'];

  function controller($scope, commonService, GENERAL_CONFIG, CONSTANTS_LIST, AuthService, $sce, leadService, $mdDialog, lead, TrackingService) {
    var ViewModel = $scope;
    var iframeDomain = leadService.getIframeUrl();
    var isAccessReadOnly = commonService.getItemSession('qis', 'isAccessReadOnly');
    var fromPageType = commonService.getItemSession('qis', 'pageType');
    ViewModel.editMode = isAccessReadOnly ? false : AuthService.hasPermission('qis-update');
    ViewModel.toggleShowDocuments = toggleShowDocuments;
    var mxpData = {};
    init();

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
    */
    function init() {
      ViewModel.navbarConfig = {
        goBackState: fromPageType === CONSTANTS_LIST.PAGE_TYPE.QUOTES ? 'quotes-list' : 'qis-list',
        vertical: 'qis',
        mode: 'EDIT',
        title: lead.partnerName,
        subtitle: lead.requestId,
        actions: [{
          type: 'button',
          class: '',
          action: _declineRequestModel,
          name: 'Decline request',
          dataAuto: 'decline-qis-button',
          disabled: !ViewModel.editMode
        }]
      };

      if (commonService.isMobile()) {
        var fileToggle = {
          type: 'button',
          class: '',
          action: toggleShowDocuments,
          name: 'Toggle Files/Profile'
        };
        ViewModel.navbarConfig.actions.unshift(fileToggle);
      }

      mxpData = {
        requestId: lead.requestId,
        leadId: lead.leadId,
        vertical: lead.vertical
      };

      ViewModel.leadFiles = {
        documents: [],
        identifiers: {},
        source: 'ADMIN_QIS_REVIEW',
        tagOptions: {
          tagging: true
        },
        options: {
          $$viewMode: false,
          filePermissions: {
            view: true,
            download: true,
            options: true,
            changeTag: ViewModel.editMode
          }
        },
        eventTrackingData: {
          module: 'QIS',
          mxpData: mxpData
        }
      };
      intializeLead();
      getLeadFiles(lead.requestId);
      ViewModel.showDocuments = false;
    }

    function intializeLead() {
      ViewModel.lead = lead;
      var url = void 0;
      ViewModel.verticalForGetPremiumApi = ViewModel.lead.vertical;
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_QIS_CUSTOMER_MOBILE, ViewModel.lead.mobile, GENERAL_CONFIG.cookieDomain, null);
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_QIS_CUSTOMER_EMAIL, ViewModel.lead.email, GENERAL_CONFIG.cookieDomain, null);
      if (ViewModel.lead.vertical === 'CV') {
        url = iframeDomain + CONSTANTS_LIST.VERTICAL_CV.IFRAME_URL;
      }
      if (ViewModel.lead.vertical === 'FW') {
        url = iframeDomain + CONSTANTS_LIST.VERTICAL_CAR.IFRAME_URL;
      }
      ViewModel.iframeSource = $sce.trustAsResourceUrl(url);
      ViewModel.initializeChatBtn = true;
    }

    function getLeadFiles(requestId) {
      leadService.getAllFiles({ requestId: requestId }).then(function (files) {
        ViewModel.leadFiles.documents = files;
      });
    }

    function _declineRequestModel() {
      $mdDialog.show({
        templateUrl: 'dev/qis/templates/qis-decline.dialog.tpl.html',
        clickOutsideToClose: true,
        locals: {
          lead: ViewModel.lead
        },
        controller: 'QisDeclineController'
      });
    }

    function toggleShowDocuments() {
      ViewModel.showDocuments = !ViewModel.showDocuments;
      var action = ViewModel.showDocuments ? 'FILES' : 'FORM';
      TrackingService.pushToGTMDatalayer('QIS_REVIEW_GOTO_' + action, 'track_event', mxpData);
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.qis').controller('QisResultCtrl', controller);

  controller.$inject = ['$scope', '$sce', 'commonService', 'leadService', '$timeout', '$stateParams', 'AuthService', 'lead', 'CONSTANTS_LIST'];

  function controller($scope, $sce, commonService, leadService, $timeout, $stateParams, AuthService, lead, CONSTANTS_LIST) {
    var ViewModel = $scope;
    var iframeDomain = leadService.getIframeUrl();
    var requestId = $stateParams.id;
    var isAccessReadOnly = commonService.getItemSession('qis', 'isAccessReadOnly');
    var fromPageType = commonService.getItemSession('qis', 'pageType');
    ViewModel.showCommentBtn = leadService.showCommentBtn();
    init();

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author beerpratapsingh
     *
     */
    function init() {
      ViewModel.navbarConfig = {
        goBackState: fromPageType === CONSTANTS_LIST.PAGE_TYPE.QUOTES ? 'quotes-list' : 'qis-list',
        vertical: 'qis',
        mode: 'EDIT',
        title: lead.partnerName,
        subtitle: requestId
      };
      ViewModel.editMode = isAccessReadOnly ? false : AuthService.hasPermission('qis-update');
      initLead();
    }

    function initActions() {
      ViewModel.navbarConfig.actions = [{
        type: 'directive',
        class: '',
        directiveConfig: {
          name: 'Copy url',
          code: 'copyUrl',
          copyTxt: ViewModel.url,
          onCopySuccess: onCopySuccess
        },
        permissionCode: 'qis-update',
        dataAuto: 'qis-copy-url-button'
      }];
    }

    function initLead() {
      ViewModel.lead = lead;
      var url = void 0;
      if (ViewModel.lead.vertical === 'FW') {
        url = iframeDomain + '/car-insurance/results/' + requestId;
      } else if (ViewModel.lead.vertical === 'CV') {
        url = iframeDomain + '/commercial-vehicle-insurance/results/' + requestId;
      } else if (ViewModel.lead.vertical === 'TW') {
        url = iframeDomain + '/two-wheeler-insurance/results/' + requestId;
      }
      setUrl(url);
    }

    function setUrl(url) {
      ViewModel.url = $sce.trustAsResourceUrl(url) || '';
      ViewModel.$applyAsync();
      initActions();
    }

    function onCopySuccess() {
      ViewModel.copied = true;
      commonService.showToast('URL copied to clipboard');
      $timeout(function () {
        ViewModel.copied = false;
      }, 3000);
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {

		angular.module('brokerAdmin.qis').controller('QisDeclineController', controller);

		controller.$inject = ['$scope', '$mdDialog', 'lead', 'leadService', 'commonService', 'GENERAL_CONFIG', 'CONSTANTS_LIST', '$state', '$window', 'CommentService', 'AuthService', 'TrackingService'];

		function controller($scope, $mdDialog, lead, leadService, commonService, GENERAL_CONFIG, CONSTANTS_LIST, $state, $window, CommentService, AuthService, TrackingService) {
				var ViewModel = $scope;
				ViewModel.data = {};
				ViewModel.senderRole = AuthService.hasPermission('chat-unrestricted') ? 'NINJA-RM' : 'NINJA-OPS';
				ViewModel.decline = decline;
				ViewModel.cancel = cancel;
				ViewModel.broker = sessionStorage.getItem('broker');

				function decline() {
						if (!ViewModel.data.declineComment) {
								return;
						}

						CommentService.sendRejectComment(lead, ViewModel.data.declineComment, ViewModel.senderRole).then(function () {
								var opsDetails = {
										name: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_FULLNAME) || commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_USERNAME),
										email: commonService.getItemLocalStorage('user', CONSTANTS_LIST.LOCAL_STORAGE_EMAIL)
								};
								var addCommentsRequest = {
										sender: ViewModel.senderRole,
										text: ViewModel.data.declineComment,
										senderDetails: {
												id: opsDetails.email,
												name: opsDetails.name,
												email: opsDetails.email,
												type: 'admin'
										},
										timestamp: new Date().getTime(),
										origin: 'NINJA-FE',
										source: 'ADMIN_QIS_REJECT',
										requestId: lead.requestId
								};

								commonService.makeThirdPartyApiCall(GENERAL_CONFIG.mintproAPI, '/broker/comments?broker=' + ViewModel.broker + '&tenant=' + lead.tenant, addCommentsRequest, 'POST', false).then(function () {
										leadService.rejectQisLead(lead.requestId, lead.tenant).then(function () {
												var mxpData = {
														requestId: lead.requestId,
														leadId: lead.leadId,
														vertical: lead.vertical
												};
												TrackingService.pushToGTMDatalayer('QIS_DECLINE', 'track_event', mxpData);
												$mdDialog.hide();
												$state.go('qis-list');
										});
								}).catch(function (err) {
										return $window.console.error(err);
								});
						}).catch(function (err) {
								return $window.console.error(err);
						});
				}

				function cancel() {
						$mdDialog.cancel();
				}
		}
})();
'use strict';

//jshint esversion:6
(function () {

  angular.module('brokerAdmin.qis').controller('QisOwnerDialogCtrl', controller);

  controller.$inject = ['$scope', '$mdDialog', 'lead', 'leadService', 'AuthService', '$window', 'CommentService', '$q', 'commonService', 'TrackingService'];

  function controller($scope, $mdDialog, lead, leadService, AuthService, $window, CommentService, $q, commonService, TrackingService) {
    var ViewModel = $scope;
    ViewModel.lead = lead;
    ViewModel.setOwner = setOwner;
    ViewModel.cancel = cancel;
    ViewModel.viewOnly = viewOnly;
    var mxpData = {
      requestId: ViewModel.lead.requestId,
      leadId: ViewModel.lead.leadId,
      vertical: ViewModel.lead.vertical
    };

    function setOwner() {
      var currentUser = AuthService.getUser();
      ViewModel.lead.owner = {
        name: currentUser.fullName || currentUser.username,
        email: currentUser.email
      };
      addMember(ViewModel.lead).then(function () {
        leadService.updateLead(ViewModel.lead).then(function () {
          leadService.setReadOnly(false);
          TrackingService.pushToGTMDatalayer('QIS_OWN', 'track_event', mxpData);
          $mdDialog.hide(true);
        }).catch(function (err) {
          return $window.console.error(err);
        });
      }).catch(function (err) {
        return $window.console.log('addMember', err);
      });
    }

    function addMember(lead) {
      var deferred = $q.defer();
      var currentUser = AuthService.getUser();
      if (AuthService.hasPermission('qis-chat')) {
        CommentService.addNewMember(lead, currentUser).then(function (res) {
          deferred.resolve(res);
        }).catch(function (err) {
          deferred.reject(err);
        });
      } else {
        deferred.resolve(true);
      }
      return deferred.promise;
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function viewOnly() {
      var currentUser = AuthService.getUser();
      var qisUser = ViewModel.lead.owner;
      if (currentUser !== qisUser) {
        leadService.setReadOnly(true);
      }
      TrackingService.pushToGTMDatalayer('QIS_VIEW', 'track_event', mxpData);
      $mdDialog.hide(true);
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.qis').controller('QuotesFormCtrl', controller);

  controller.$inject = ['$scope', 'QuotesService', 'commonService', '$anchorScroll', 'leadService', '$state', 'CONSTANTS_LIST', 'GENERAL_CONFIG', 'AuthService', 'TrackingService'];

  function controller($scope, QuotesService, commonService, $anchorScroll, leadService, $state, CONSTANTS_LIST, GENERAL_CONFIG, AuthService, TrackingService) {
    var ViewModel = $scope;
    var tenant = sessionStorage.getItem('tenant');
    ViewModel.quoteForm = {};
    ViewModel.submitForm = submitForm;
    ViewModel.hasPermission = AuthService.hasPermission;

    _init();

    function _init() {
      ViewModel.navbarConfig = {
        goBackState: 'quotes-list',
        vertical: 'qis',
        mode: 'EDIT',
        title: 'Create New Quote'
      };

      _initFormConfigs();
    }

    function _initFormConfigs() {
      QuotesService.initPartnerList();
      ViewModel.generalFields = QuotesService.generalFields;
    }

    function submitForm() {
      if (ViewModel.createQuoteForm.$dirty) {
        if (ViewModel.createQuoteForm.$valid) {
          _createQuote(ViewModel.quoteForm);
        } else {
          for (var key in ViewModel.createQuoteForm.$error) {
            var errorArray = ViewModel.createQuoteForm.$error[key];
            for (var idx = 0; idx < errorArray.length; idx++) {
              var error = errorArray[idx];
              if (!error.$dirty) {
                ViewModel.createQuoteForm[error.$name].$setDirty();
              }
              $anchorScroll(error.$name);
              break;
            }
          }
          commonService.showToast('Select required fields');
        }
      } else {
        commonService.showToast('Please fill data to create lead');
      }
    }

    function _createQuote(form) {
      leadService.removeQisCookies();
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_DEALER_USER_NAME, form.dpLoginId, GENERAL_CONFIG.cookieDomain, 1);
      commonService.setPlainCookie(CONSTANTS_LIST.COOKIE_TENANT, tenant, GENERAL_CONFIG.cookieDomain, 1);
      TrackingService.pushToGTMDatalayer('CREATE_QUOTE_CLICK', 'track_event', form);
      $state.go('quotes-profile', {
        vertical: form.vertical
      });
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.qis').controller('QuotesProfileCtrl', controller);

  controller.$inject = ['$scope', '$sce', 'leadService', '$stateParams', 'CONSTANTS_LIST', '$mdDialog'];

  function controller($scope, $sce, leadService, $stateParams, CONSTANTS_LIST, $mdDialog) {
    var ViewModel = $scope;
    var iframeDomain = leadService.getIframeUrl();
    var vertical = $stateParams.vertical;
    _init();

    /**
     * init function
     *
     * @public
     *
     * @function
     *
     * @author sagarmulchandani
     *
     */
    function _init() {
      ViewModel.navbarConfig = {
        goBackState: 'quotes-form',
        vertical: 'qis',
        mode: 'EDIT',
        title: 'Create Quote'
      };
      _initFrame();
    }

    function _initActions() {
      ViewModel.navbarConfig.actions = [{
        type: 'button',
        class: '',
        action: _onClose,
        name: 'Close',
        permissionCode: 'quotes-create'
      }];
    }

    function _initFrame() {
      var url = void 0;
      if (vertical === 'FW') {
        url = '' + iframeDomain + CONSTANTS_LIST.VERTICAL_CAR.IFRAME_URL;
      } else if (vertical === 'CV') {
        url = '' + iframeDomain + CONSTANTS_LIST.VERTICAL_CV.IFRAME_URL;
      } else if (vertical === 'TW') {
        url = '' + iframeDomain + CONSTANTS_LIST.VERTICAL_TW.IFRAME_URL;
      } else if (vertical === 'HEALTH') {
        url = '' + iframeDomain + CONSTANTS_LIST.VERTICAL_HEALTH.IFRAME_URL;
      } else if (vertical === 'LIFE') {
        url = '' + iframeDomain + CONSTANTS_LIST.VERTICAL_LIFE.IFRAME_URL;
      }
      _setUrl(url);
    }

    function _setUrl(url) {
      ViewModel.url = $sce.trustAsResourceUrl(url) || '';
      ViewModel.$applyAsync();
      _initActions();
    }

    function _onClose() {
      $mdDialog.show({
        templateUrl: 'dev/qis/templates/quotes-profile-confirm.dialog.tpl.html',
        clickOutsideToClose: false,
        controller: CloseConfirmationController
      });
      CloseConfirmationController.$inject = ['$scope', '$mdDialog', '$state'];

      function CloseConfirmationController($scope, $mdDialog, $state) {
        $scope.confirm = function () {
          $state.go('quotes-list');
          $mdDialog.hide();
        };
        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }
  }
})();
'use strict';

(function () {
  angular.module('brokerAdmin').controller('PDFViewController', PDFViewController).directive('pdfViewer', pdfViewer);

  function pdfViewer() {
    var directive = {
      restrict: 'E',
      replace: true,
      templateUrl: 'dev/directives/templates/pdf-viewer.tpl.html',
      controller: PDFViewController,
      scope: {
        url: '='
      }
    };
    return directive;
  }

  PDFViewController.$inject = ['$scope', 'commonService'];

  /**
   * PDFViewController
   *
   * @author sagarmulchandani
   *
   */
  function PDFViewController($scope, commonService) {

    var ViewModel = $scope;
    var mobileViewerLink = 'https://mozilla.github.io/pdf.js/web/viewer.html?file=';
    ViewModel.isApp = commonService.isApp();

    _init();

    function _init() {
      ViewModel.pdfLink = angular.copy(ViewModel.url);
      if (commonService.isApp()) {
        ViewModel.pdfLink = mobileViewerLink + ViewModel.url;
      }
    }
  }
})();
'use strict';

//jshint esversion:6
(function () {
  angular.module('brokerAdmin.Auth').controller('PrivacyPolicyCtrl', controller);

  controller.$inject = ['$scope', '$state', 'AuthService'];

  function controller($scope, $state, AuthService) {

    var ViewModel = $scope;
    var actionText = AuthService.isLoggedIn() ? 'Home' : 'Login';
    init();

    function init() {
      ViewModel.navbarConfig = {
        vertical: 'qis',
        title: 'Privacy Policy',
        actions: [{
          type: 'button',
          class: '',
          action: _goToLogin,
          disabled: false,
          name: actionText
        }]
      };
    }

    function _goToLogin() {
      $state.go('login');
    }
  }
})();